import { Bool, getArrayValueFromString } from '@/ponychart/trait'
import { listTraits } from '@/ponychart/trait/main'
import {
    TraitId,
    QuerySelectorTag,
    Trait,
    querySelectorTagToChartTrait,
    ChartType,
} from 'ponychart'
import {
    GlobalState,
    LocalState,
    getBlockState,
    getChartState,
    TraitSearch,
} from '@/ponychart/state'
import { ColumnContainer, RowContainer } from '@/ponychart/element'
import { PageBlockElement, KpiCardWrapper } from '@/ponychart/pageBlock'
import { nToS } from '@/ponychart/utils/functions'
import { SourceMemory } from '@/ponychart/memoize'
import {
    Page,
    PageMode,
    PageStructure,
    UNIQUE_PAGE_STRUCTURE_SET,
} from '@/ponychart/page/types'
import { seedPage, seedPageBlock } from '@/ponychart/page/utils'
import { SizePropagatorHelpers } from '@/ponychart/element/size'

import {
    buildChildrenForRepetitiveKpiCardDesign,
    buildDefaultChildrenForNonRepetitiveDesign,
    buildVerticalChildrenForNonRepetitiveDesign,
} from './utils'
import {
    structureAsRow,
    structureChartCount,
    structureChartTypes,
} from './config'

const memoryInstance = SourceMemory.getInstance()

abstract class Structure {
    constructor(
        protected globalState: GlobalState,
        protected traitSearch: TraitSearch
    ) {}

    get structure() {
        return this.globalState.pageStructure
    }

    get chartCount() {
        return structureChartCount(this.structure)
    }

    get chartTypes() {
        return structureChartTypes(this.structure)
    }

    get asRow() {
        return structureAsRow(this.structure)
    }

    get hasAtLeastOneChart(): boolean {
        for (const querySelectorTag of this.chartCountArray) {
            const traitId = querySelectorTagToChartTrait(querySelectorTag)
            const chartType = this.traitSearch.getTraitStringValue(
                traitId,
                querySelectorTag
            )
            if (
                chartType &&
                ![ChartType.NONE, ChartType.TIME_PERIOD_INDICATION].includes(
                    chartType as ChartType
                )
            ) {
                return true
            }
        }
        return false
    }

    get chartCountArray(): number[] {
        const output: number[] = []
        for (let i = 1; i <= this.chartCount; i++) output.push(i)
        return output
    }

    get alignVertically() {
        return (
            (this.traitSearch.getTraitStringValue(TraitId.ALIGN_VERTICALLY, {
                querySelectorTag: 0,
                explicitDeviceType: true,
            }) || Bool.FALSE) !== Bool.FALSE
        )
    }

    abstract buildPonychartElement(): ColumnContainer

    buildPage(): Page {
        // When first building a structure
        // Structure Traits are created, then the
        // Dependency traits are added in the listTraits() function
        // Tested, required from the listPages() function
        const traits: Trait[] = listTraits(this.structure, this.traitSearch, {
            applyCrossDeviceValues: true,
        })
        return {
            ...seedPage(this.structure),
            navigation: this.globalState.navigation,
            pageBlocks: [
                {
                    ...seedPageBlock(this.structure),
                    traits,
                    twbIdx: this.globalState.twbIdx,
                },
            ],
        }
    }
}

class UniqueStructure extends Structure {
    icon = ''
    tags = []
    constructor(globalState: GlobalState, traitSearch: TraitSearch) {
        super(globalState, traitSearch)
        if (!UNIQUE_PAGE_STRUCTURE_SET.has(this.structure))
            throw new Error(
                'UniqueStructure should not be set up as a non-UNIQUE_PAGE_STRUCTURE'
            )
    }

    buildPonychartElement() {
        this.traitSearch.syncWith(this.globalState)
        const cardHeight =
            this.traitSearch.getTraitNumberValue(TraitId.CARD_HEIGHT, 0) || 400

        const children = this.alignVertically
            ? buildVerticalChildrenForNonRepetitiveDesign(
                  this.globalState,
                  this.traitSearch,
                  this.chartCount as QuerySelectorTag,
                  cardHeight
              )
            : buildDefaultChildrenForNonRepetitiveDesign(
                  this.globalState,
                  this.traitSearch,
                  this.chartTypes,
                  cardHeight,
                  { asRow: this.asRow }
              )

        const isRowContainer = !this.asRow && !this.alignVertically
        const height = isRowContainer
            ? Math.max(
                  ...children.map((child) => (child.length - 1) * cardHeight)
              )
            : children.length * cardHeight
        const containerState = new LocalState()
            .setIsFlex(true)
            .addStyles({ height: nToS(height) })
        const main = isRowContainer
            ? new RowContainer(this.globalState, containerState, { children })
            : new ColumnContainer(this.globalState, containerState, {
                  children,
              })

        const pageBlockState = getBlockState(this.globalState, this.traitSearch)
        const pageBlock = new PageBlockElement(
            this.globalState,
            pageBlockState,
            this.traitSearch,
            main,
            { asRow: isRowContainer, innerHeight: height }
        )
        SizePropagatorHelpers.propagateSizeDown(pageBlock, {
            width: SizePropagatorHelpers.getDefaultWidth(
                this.globalState,
                this.traitSearch
            ),
            height,
        })
        pageBlock.mount()
        return pageBlock
    }
}

class KpiCardStructure extends Structure {
    constructor(globalState: GlobalState, traitSearch: TraitSearch) {
        super(globalState, traitSearch)
        if (UNIQUE_PAGE_STRUCTURE_SET.has(this.structure))
            throw new Error(
                'KpiCardStructure should not be set up as a UNIQUE_PAGE_STRUCTURE'
            )
    }

    buildPonychartElement() {
        this.traitSearch.syncWith(this.globalState)
        const allowedForDimension =
            this.structure === PageStructure.SIMPLE &&
            this.traitSearch.getTraitStringValue(TraitId.PAGE_MODE, 0) ===
                PageMode.DIMENSION
        const measureIds = getArrayValueFromString(
            this.traitSearch.getTraitStringValue(
                allowedForDimension ? TraitId.DIMENSION : TraitId.MEASURE,
                0
            ) || '',
            {
                allowNone: false,
                sortable: true,
                allowMultiple: true,
                twbIdx: this.globalState.twbIdx,
                items: allowedForDimension
                    ? memoryInstance.listDimensions(this.globalState.twbIdx)
                    : memoryInstance.listMeasures(this.globalState.twbIdx),
            }
        ) as string[]
        const cardHeight =
            this.traitSearch.getTraitNumberValue(TraitId.CARD_HEIGHT, 0) || 100
        const columnCount = Number(
            this.traitSearch.getTraitValue(TraitId.COLUMN_COUNT, 0) || 4
        )
        const rowCount = Math.ceil(measureIds.length / columnCount)
        const totalHeight = cardHeight * rowCount
        const flexState = new LocalState().setIsFlex(true)
        const chartState = getChartState(this.globalState, this.traitSearch)
        const main = new ColumnContainer(this.globalState, flexState)
        const mainKpiCardPayload = KpiCardWrapper.buildKpiCardLocalStatePayload(
            this.globalState,
            this.traitSearch,
            {
                height: cardHeight,
            }
        )
        const hasColors =
            this.traitSearch.getTraitArrayValue(TraitId.MEASURE_COLOR, 0)
                .length > 0

        let count = 0
        for (let i = 0; i < rowCount; i++) {
            const row = new RowContainer(this.globalState, flexState)
            for (let j = 0; j < columnCount; j++) {
                if (count < measureIds.length) {
                    row.push(
                        new KpiCardWrapper(
                            this.globalState,
                            this.traitSearch,
                            !allowedForDimension && hasColors
                                ? KpiCardWrapper.buildKpiCardLocalStatePayload(
                                      this.globalState,
                                      this.traitSearch,
                                      {
                                          measure: measureIds[count],
                                          querySelectorTag: 0,
                                          height: cardHeight,
                                      }
                                  )
                                : mainKpiCardPayload,
                            buildChildrenForRepetitiveKpiCardDesign(
                                this.globalState,
                                chartState,
                                this.traitSearch,
                                this.chartTypes,
                                {
                                    measureOrDimension: measureIds[count],
                                    asRow: this.asRow,
                                    asMeasure: !allowedForDimension,
                                }
                            ),
                            cardHeight,
                            { asRow: !this.asRow }
                        )
                    )
                } else {
                    row.push(new ColumnContainer(this.globalState, flexState))
                }
                count++
            }
            main.push(row)
        }
        // TODO: better calc the height
        const pageBlockState = getBlockState(this.globalState, this.traitSearch)

        const pageBlock = new PageBlockElement(
            this.globalState,
            pageBlockState,
            this.traitSearch,
            main,
            { asRow: false, innerHeight: totalHeight }
        )
        SizePropagatorHelpers.propagateSizeDown(pageBlock, {
            width: SizePropagatorHelpers.getDefaultWidth(
                this.globalState,
                this.traitSearch
            ),
            height: totalHeight,
        })
        pageBlock.mount()
        return pageBlock
    }
}

export class PageStructureFactory {
    buildStructure(globalState: GlobalState, traitSearch: TraitSearch) {
        listTraits(globalState.pageStructure, traitSearch, {
            applyCrossDeviceValues: true,
        })
        if (UNIQUE_PAGE_STRUCTURE_SET.has(globalState.pageStructure)) {
            return new UniqueStructure(globalState, traitSearch)
        } else {
            return new KpiCardStructure(globalState, traitSearch)
        }
    }

    buildPage(globalState: GlobalState, traitSearch: TraitSearch) {
        return this.buildStructure(globalState, traitSearch).buildPage()
    }
}
