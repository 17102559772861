
import DatasourcePill from '@/components/utils/DatasourcePill.vue'
import GradSquare from '@/components/utils/GradSquare.vue'
import MeasureColorBtn from '@/components/Measures/MeasureColorBtn.vue'

import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { GlobalMixins } from '@/mixins'

import {
    Measure,
    MeasureFilter,
    Format as _Format,
    SourceMemory,
    MeasureHelpers,
    Column,
} from '@/ponychart'

const memoryInstance = SourceMemory.getInstance()

@Component({
    components: {
        GradSquare,
        MeasureColorBtn,
        DatasourcePill,
    },
})
export default class MeasureRowComponent extends mixins(GlobalMixins) {
    @Prop({ type: Object, required: true })
    readonly measure!: Measure
    @Prop({ type: String, required: false })
    readonly error?: string
    @Prop({ type: Number, required: true })
    readonly idx!: number
    @Prop({ type: Array, required: true })
    readonly columns!: Column[]
    @Prop({ type: Boolean, required: true })
    readonly disabled!: boolean
    @Prop({ type: Object, required: true })
    readonly measureNames!: { [k: string]: string }
    @Prop({ type: Array, required: true })
    readonly usedMeasureIds!: string[]
    @Prop({ type: Array, required: true })
    readonly mainFormats!: _Format[]
    @Prop({ type: Array, required: true })
    readonly twbDatasources!: { id: string; alias: string; color: string }[]

    tmt?: number = undefined

    get hasFilter() {
        return (
            this.measure.filters.filter((f) => f.columnId && f.value).length > 0
        )
    }
    get measureClass() {
        return this.error
            ? 'red lighten-4'
            : this.idx % 2 == 0
            ? 'blue-grey lighten-5'
            : 'white'
    }

    get aggregationItems() {
        if (this.measure.id1) {
            const value = this.measure.agg
            return [
                {
                    value,
                    text: `${this.$t(`aggregations.${value}`)} (${value})`,
                },
            ]
        }
        return MeasureHelpers.aggregationsFromType(this.measure.type).map(
            (value) => ({
                value,
                text: `${this.$t(`aggregations.${value}`)} (${value})`,
            })
        )
    }

    // get aggregationAlias() {
    //     const x =
    //         this.measure?.id1 && this.measureNames?.[this.measure?.id1]
    //             ? this.measureNames[this.measure.id1]
    //             : '?'
    //     const y =
    //         this.measure?.id2 && this.measureNames?.[this.measure?.id2]
    //             ? this.measureNames[this.measure.id2]
    //             : '?'
    //     return String(
    //         this.$t('aggregations.' + (this.measure.agg || 'CUSTOM'), { x, y })
    //     )
    // }

    setAlias(alias: string) {
        this.$emit('type')
        clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.measure.alias = alias
                this.$emit('set')
            },
            1500,
            this
        )
    }
    getFilterAlias(filter: MeasureFilter) {
        const alias = filter.columnId
            ? memoryInstance.getColumn(filter.columnId).alias
            : undefined
        return this.$t('aggregations.' + filter.operator, {
            x: alias,
            y: filter.value,
        })
    }
}
