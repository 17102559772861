<template lang="pug">
component.ma-0.pa-0(
    :style='style',
    :is='component',
    :align='align',
    :justify='align'
)
    component.ma-0.pa-0(
        no-gutters,
        v-if='showMove',
        :is='antiComponent',
        :justify='align'
    )
        v-btn.white.card-shadow.handle.grabbing(icon, outlined)
            v-icon(color='primary') mdi-arrow-all
    component.ma-0.pa-0(
        no-gutters,
        v-if='showDelete',
        :class='className',
        :is='antiComponent',
        :justify='align'
    )
        v-btn.white.card-shadow(
            icon,
            outlined,
            @click='$emit("delete")',
            :loading='loadingDelete',
            :disabled='disabledDelete'
        )
            v-icon(color='error') mdi-delete-outline
</template>

<script>
export default {
    name: 'buttonsMoveEdit',
    props: {
        showDelete: {
            type: Boolean,
            default: true,
        },
        align: {
            type: String,
            default: 'end',
        },
        disabledDelete: {
            type: Boolean,
            default: false,
        },
        loadingDelete: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: true,
        },
        showMove: {
            type: Boolean,
            default: true,
        },
        isRow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        component() {
            return this.isRow ? 'v-row' : 'v-col'
        },
        antiComponent() {
            return this.isRow ? 'v-col' : 'v-row'
        },
        className() {
            return this.showMove && !this.isRow ? 'mt-3' : 'ml-3'
        },
        style() {
            const style = {
                position: 'absolute',
                top: this.isRow ? '-20px' : '-18px',
                'z-index': '2',
            }
            style[this.left ? 'left' : 'right'] = this.isRow ? '-20px' : '-18px'
            return style
        },
    },
}
</script>
<style scoped>
.grabbing {
    cursor: grab;
}
.card-shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>