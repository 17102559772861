
import {
    Page as _Page,
    buildHeader,
    ColumnContainer,
    combineHtmlAndCss,
    PonychartComponent,
    sToN,
    divideBy,
    PonychartElement,
    SidebarPayload,
    PageBlock as _PageBlock,
    RegisterMemory,
    sidebarFactory,
    isBigSize,
    RowContainer,
    GlobalOptions,
    GlobalState,
    LocalState,
} from '@/ponychart'
import { TraitId, DeviceType, Trait, QuerySelectorTag } from 'ponychart'

import PageBlock from '@/components/Pages/PageBlock.vue'

import Component, { mixins } from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'

import { GlobalMixins, Pages, PageHeaderEventListeners } from '@/mixins'
import { DynamicParameter } from '@/ponychart/dynamicParameter/model'
import { SIDEBAR_HEIGHT, SIDEBAR_WIDTH } from '@/ponychart/header/config'
import { SimpleTraitSearch, TraitSearch } from '@/ponychart/state/traits'

const registerInstance: RegisterMemory = RegisterMemory.getInstance()

@Component({
    components: {
        PageBlock,
    },
})
export default class PageComponent extends mixins(
    GlobalMixins,
    Pages,
    PageHeaderEventListeners
) {
    @Prop({ type: Object, required: true })
    readonly page!: _Page
    @Prop({ type: Array, required: true })
    readonly sourceTraits!: Trait[]
    @Prop({ type: Object, required: true })
    readonly globalOptions!: GlobalOptions
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
    @Prop({ type: Object, default: () => ({ sidebar: '' }) })
    readonly sidebar!: SidebarPayload
    @Prop({ type: Boolean, default: false }) readonly event!: boolean
    @Prop({ type: Number, default: 200 }) readonly height!: number
    @Prop({ type: Number, default: 200 }) readonly width!: number
    @Prop({ type: Boolean, default: false }) readonly isDiv!: boolean
    @Prop({ type: Array, default: () => [] }) readonly hovered!: string[]
    @Prop({ type: Boolean, default: true }) readonly hasHeader!: boolean
    @Prop({ type: Boolean, default: true }) readonly isSquare!: boolean
    // @Prop({ type: Object, default: () => ({}) }) readonly grayFilter!: any
    @Prop({ type: Number, required: true }) readonly sourceId!: number
    @Prop({ type: Boolean, default: true }) readonly headerCache!: boolean
    @Prop({ type: Boolean, default: false }) readonly listenForEvents!: boolean
    @Prop({ type: Number, required: false, default: -1 })
    readonly registerContextIndex!: number

    header = ''
    pristineHeader = true
    heightSize = 200
    heights = {}
    usedTwbIndexes: { [k: string]: number | undefined } = {}
    twbIndexes: number[] = []

    html = ''
    sidebarHtml = ''
    headerHeight = '0'
    tmt: number | null = null

    refreshTwbIndexes() {
        const twbIndexes: number[] = []
        for (const pageBlock of this.pageBlocks) {
            const idx = this.usedTwbIndexes[pageBlock.id]
            if (idx === undefined) continue
            twbIndexes.push(idx)
        }
        this.twbIndexes = twbIndexes
    }

    get grayFilter() {
        return this.globalOptions.grayFilter || {}
    }

    get pageWidth() {
        const state = new GlobalState(this.enrichedOptions)
        return this.sidebar.sidebar === 'lateral' && !state.hasMobileHeader
            ? `calc(100% - ${divideBy(SIDEBAR_WIDTH, state.reduceSize || 1)})`
            : '100%'
    }

    get mainStyle() {
        const style: any = {
            height: '100%',
            width: this.isDesktop ? '100%' : this.isTablet ? '70%' : '50%',
            overflow: 'hidden',
            position: 'relative',
            background: '#eee',
            display: 'flex',
        }
        if (this.isMobile) {
            style.marginLeft = '25%!important'
            style.marginRight = '25%!important'
        }
        if (this.isTablet) {
            style.marginLeft = '15%!important'
            style.marginRight = '15%!important'
        }
        return style
    }
    get deviceType() {
        return this.globalOptions.deviceType || DeviceType.DESKTOP
    }
    get isDesktop() {
        return this.deviceType === DeviceType.DESKTOP
    }
    get isTablet() {
        return this.deviceType === DeviceType.TABLET
    }
    get isMobile() {
        return this.deviceType === DeviceType.MOBILE
    }
    get colors() {
        return this.globalOptions.colors
    }
    get logoUrl() {
        return this.globalOptions.logoUrl
    }
    get reduceSize() {
        return this.globalOptions.reduceSize || 1
    }
    get pageBlocks(): _PageBlock[] {
        return this.page.pageBlocks || []
    }
    get enrichedOptions(): GlobalOptions {
        return {
            ...this.globalOptions,
            sidebarPayload: this.sidebar,
            pageTitle: this.page.alias,
        }
    }
    get dateAggregationLevel() {
        return new SimpleTraitSearch(this.sourceTraits).getTraitStringValue(
            TraitId.SOURCE_DATE_AGGREGATION_LEVEL
        )
    }
    get sidebarWrapperComponent() {
        return this.sidebar.sidebar === 'top' ? 'v-col' : 'v-row'
    }
    get lateralSidebar() {
        return this.sidebar.sidebar === 'lateral'
    }
    get topSidebar() {
        return this.sidebar.sidebar === 'top'
    }

    mounted() {
        if (this.registerContextIndex !== -1)
            registerInstance.registerPageContext(
                this.registerContextIndex,
                this
            )
    }
    beforeDestroy() {
        registerInstance.unregisterPageContext(this.registerContextIndex)
    }
    hover(pageBlockId: string, value: boolean) {
        this.$emit('hover', { id: pageBlockId, value })
    }
    chartClicked(e: any) {
        this.$emit('chartClicked', e)
    }
    onPageBlockBuilt({
        id,
        height,
        hasAtLeastOneChart,
        twbIdx,
    }: {
        id: string
        height: number
        hasAtLeastOneChart: boolean
        twbIdx: number
    }) {
        this.heights[id] = height

        const usedTwbIdx = hasAtLeastOneChart ? twbIdx : undefined

        if (usedTwbIdx !== this.usedTwbIndexes[id]) {
            this.usedTwbIndexes[id] = usedTwbIdx
            this.refreshTwbIndexes()
            this.buildHeaderTimeout({
                reduceSize: this.reduceSize,
                onlyContainer: false,
                deviceType: this.deviceType,
                timeout: 10,
            })
        } else {
            this.refreshHeight()
        }
    }
    refreshHeight() {
        const traitSearch = new SimpleTraitSearch(this.sourceTraits)
        const payload = {
            deviceType: this.deviceType,
            querySelectorTag: 0 as QuerySelectorTag,
        }
        if (!this.isSquare) {
            // Header Height
            let initHeight = sToN(this.headerHeight)
            // Top PageMargin
            initHeight +=
                traitSearch.getTraitNumberRequiredValue(
                    TraitId.PAGE_MARGIN_X,
                    payload
                ) / 2
            // Sidebar if on top
            if (this.sidebar.sidebar === 'top') initHeight += SIDEBAR_HEIGHT

            this.heightSize =
                this.pageBlocks.reduce(
                    (acc, p) => acc + this.heights[p.id],
                    initHeight
                ) / this.reduceSize
        }
        this.$emit('setHeight', this.heightSize)
    }
    // Called from Pages.vue to generate the component tree
    // right before downloading the .twb
    build(
        opts: {
            reduceSize: number
            onlyContainer: boolean
            calledFromParentComponent?: boolean
            deviceType: DeviceType
            keys: ('css' | 'html' | 'styles' | 'components' | 'parameters')[]
        } = {
            reduceSize: -1,
            onlyContainer: false,
            calledFromParentComponent: false,
            deviceType: DeviceType.DESKTOP,
            keys: ['css', 'html', 'styles', 'components', 'parameters'],
        }
    ): {
        css?: Set<string>
        html?: string
        styles?: any
        components?: PonychartComponent[]
        parameters?: DynamicParameter[]
    } {
        if (opts.reduceSize === -1) opts.reduceSize = this.reduceSize
        const children: PonychartElement[] = []
        if (
            this.pristineHeader ||
            !this.headerCache ||
            opts.calledFromParentComponent
        ) {
            const header = this.buildHeader({
                reduceSize: opts?.reduceSize || -1,
                onlyContainer: opts?.onlyContainer || false,
                calledFromParentComponent: !!opts.calledFromParentComponent,
                deviceType: opts.deviceType,
            })
            children.push(header)
            if (!opts.calledFromParentComponent) this.pristineHeader = false
        }
        const globalState = new GlobalState(this.enrichedOptions)
        const localState = new LocalState().setStyles({
            background: globalState.colors.light_background,
        })
        const column = new ColumnContainer(globalState, localState, {
            children,
        })
        const processedParameters: DynamicParameter[] = []
        for (const pageBlock of this.pageBlocks) {
            const blocks: any = this.$refs[`blocks-${pageBlock.id}`]
            if (blocks?.length) {
                for (const child of blocks) {
                    column.push(
                        child.pageBlockFactory({
                            reduceSize: opts.reduceSize,
                            onlyContainer: opts.onlyContainer,
                            processedParameters,
                            deviceType: opts.deviceType,
                        })
                    )
                }
            }
        }

        function exportComponent(
            component: PonychartElement,
            keys: ('css' | 'html' | 'styles' | 'components' | 'parameters')[]
        ): {
            css?: Set<string>
            html: string
            styles: any
            components?: PonychartComponent[]
            parameters?: DynamicParameter[]
        } {
            const { css, html, styles, components, parameters } =
                component.compile(keys)
            return {
                css,
                html: combineHtmlAndCss(component.id, css || new Set(), html),
                styles,
                components,
                parameters,
            }
        }

        if (this.topSidebar && !globalState.hasMobileHeader) {
            const topBar = this.buildSidebar({
                calledFromParentComponent: opts.calledFromParentComponent,
                reduceSize: opts.reduceSize,
                deviceType: opts.deviceType,
            })
            if (topBar) column.unshift(topBar)
        } else if (this.lateralSidebar && !globalState.hasMobileHeader) {
            const lateralBar = this.buildSidebar({
                calledFromParentComponent: opts.calledFromParentComponent,
                reduceSize: opts.reduceSize,
                deviceType: opts.deviceType,
            })
            if (lateralBar) {
                const row = new RowContainer(globalState, localState, {
                    children: [lateralBar, column],
                })
                return exportComponent(row, opts.keys)
            }
        }

        return exportComponent(column, opts.keys)
    }
    buildSidebar(opts: {
        reduceSize: number
        calledFromParentComponent?: boolean
        deviceType: DeviceType
    }) {
        const globalState = new GlobalState({
            ...this.enrichedOptions,
            reduceSize: opts.reduceSize,
            deviceType: opts.deviceType,
        })
        const traitSearch = TraitSearch.createInstance(
            this.sourceTraits,
            [],
            globalState
        )
        const sidebarComponent = sidebarFactory(globalState, traitSearch)

        if (!sidebarComponent) {
            if (!opts.calledFromParentComponent) this.sidebarHtml = ''
            return
        }
        const html = sidebarComponent.compileAllHtml()
        const css = sidebarComponent.compileAllCss()
        if (!opts.calledFromParentComponent)
            this.sidebarHtml =
                html + `<style>${[...Array.from(css)].join(' ')}</style>`
        return sidebarComponent
    }
    buildHeaderTimeout(opts: {
        reduceSize: number
        onlyContainer: boolean
        calledFromParentComponent?: boolean
        deviceType: DeviceType
        timeout: number
    }) {
        if (this.tmt !== null) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.buildHeader(opts)
                this.refreshHeight()
            },
            opts.timeout,
            this
        )
    }
    buildHeader(opts: {
        reduceSize: number
        onlyContainer: boolean
        calledFromParentComponent?: boolean
        deviceType: DeviceType
    }): PonychartElement {
        if (!opts.calledFromParentComponent) this.buildSidebar(opts)
        const globalState = new GlobalState({
            ...this.enrichedOptions,
            ...opts,
            pageId: this.page?.id,
            pageBlockId: 'header',
            twbIndexes: this.twbIndexes,
        })
        const traitSearch = TraitSearch.createInstance(
            this.sourceTraits,
            [],
            globalState
        )
        const header = buildHeader(globalState, traitSearch)
        this.headerHeight = header.localState.rawStyles.height
        const html = header.compileAllHtml()
        const css = header.compileAllCss()
        if (!opts.calledFromParentComponent)
            this.header =
                html + `<style>${[...Array.from(css)].join(' ')}</style>`
        if (
            isBigSize(this.reduceSize) &&
            !opts.calledFromParentComponent &&
            this.listenForEvents
        ) {
            this.$nextTick().then(() => {
                this.attachPageButtonEvent()
                this.attachChangeSelectEvent()
            })
        }

        return header
    }

    // @Watch('page.template', { immediate: true })
    // onPageTemplate(template: _Page['template']) {
    //     if (template && this.page.isTemplate) {
    //         this.getTemplatePage()
    //     }
    // }
    @Watch('height', { immediate: true })
    onSizeChange(height: number) {
        this.heightSize = height
    }
    @Watch('event', { immediate: true })
    oneventChange() {
        this.buildHeaderTimeout({
            reduceSize: this.reduceSize,
            onlyContainer: false,
            deviceType: this.deviceType,
            timeout: 10,
        })
    }
    @Watch('pageBlocks.length')
    onPageBlocksLengthChange() {
        this.refreshTwbIndexes()
        this.buildHeaderTimeout({
            reduceSize: this.reduceSize,
            onlyContainer: false,
            deviceType: this.deviceType,
            timeout: 10,
        })
    }

    @Watch('page.alias')
    onPageAliasChange() {
        this.buildHeaderTimeout({
            reduceSize: this.reduceSize,
            onlyContainer: false,
            deviceType: this.deviceType,
            timeout: 10,
        })
    }
}
