<template lang="pug">
div(ref='main_container', :class='prefix + "main_container"')
    div(ref='main_circle', :class='prefix + "main_circle"')
    div(
        :key='i',
        v-for='(lang, i) in langs',
        :ref='"small_circle_" + i',
        @click.prevent='setLanguage(i)'
    )
        span.tooltiptext
</template>

<script>
import { SourceMemory } from '@/ponychart'
import { mapMutations } from 'vuex'
export default {
    name: 'languageComponent',
    props: {
        langs: {
            type: Array,
            default: () => ['es', 'fr', 'en'],
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        texts: {
            es: 'Español',
            fr: 'Français',
            nl: 'Dutch',
            de: 'Deutsch',
            en: 'English',
        },
    }),
    async mounted() {
        await this.$nextTick()
        this.$refs.main_circle.classList.add('circle_' + this.classLang)
        this.langs
            .filter((l) => l != this.classLang)
            .forEach((l, i) => {
                ;['circle_' + l, 'circle_' + i, 'small_circle'].forEach(
                    (myClass) => {
                        this.$refs['small_circle_' + i][0].classList.add(
                            myClass
                        )
                    }
                )
                this.$refs['small_circle_' + i][0].firstChild.innerHTML =
                    this.texts[l]
            })
    },
    methods: {
        ...mapMutations(['SET']),
        setLanguage(i) {
            const smallCircle = this.$refs['small_circle_' + String(i)][0]
            const mainCircle = this.$refs.main_circle
            const oldLang = this.getLangFromClass(mainCircle)
            const newLang = this.getLangFromClass(smallCircle)
            smallCircle.classList.remove('circle_' + newLang)
            smallCircle.classList.add('circle_' + oldLang)
            smallCircle.firstChild.innerHTML = this.texts[oldLang]
            mainCircle.classList.remove('circle_' + oldLang)
            mainCircle.classList.add('circle_' + newLang)
            if (this.$route.params.lang) {
                if (newLang != this.$route.params.lang) {
                    this.$router.push({
                        params: { ...this.$route.params, lang: newLang },
                    })
                    // this.goTo(`/${newLang}${this.$route.fullPath.substr(3)}`)
                }
            } else {
                this.SET({ user: { lang: newLang } })
                const memory = SourceMemory.getInstance()
                memory.setLang(newLang)
                this.$emit('switchLang', newLang)
            }
        },
        getLangFromClass(el) {
            if (el && el.className) {
                const classes = el.className
                    .split(' ')
                    .filter((cl) => cl.startsWith('circle_'))
                    .map((cl) => cl.replace('circle_', ''))
                    .filter((cl) => isNaN(cl))
                return classes.length == 1
                    ? classes[0]
                    : this.$store.getters.lang
            } else {
                return this.$store.getters.lang
            }
        },
    },
    computed: {
        classLang() {
            return this.getLangFromClass(this.$refs.main_circle)
        },
        prefix() {
            return this.small ? 'bar_' : ''
        },
    },
    watch: {
        lang(val) {
            for (const i in this.langs.filter((l) => l != this.classLang)) {
                const el = this.$refs['small_circle_' + String(i)][0]
                const l = this.getLangFromClass(el)
                if (l == val) {
                    // this should not be reached... but just in case...
                    this.setLanguage(i)
                }
            }
        },
    },
}
</script>

<style>
.circle_en {
    background-image: url(~@/assets/en.jpg) !important;
}
.circle_fr {
    background-image: url(~@/assets/fr.svg) !important;
}
.circle_de {
    background-image: url(~@/assets/de.svg) !important;
}
.circle_es {
    background-image: url(~@/assets/es.svg) !important;
}

.main_container:hover .circle_1 {
    transform: translate(0, -65px);
}
.main_container:hover .circle_0 {
    transform: translate(0, -100px);
}
.main_circle {
    z-index: 100000;
    height: 70px;
    width: 70px;
    left: 10px;
    top: 40px;
    border-radius: 40px;
    position: absolute;
    -webkit-box-shadow: 0px 0px 2px 1px #999;
    -moz-box-shadow: 0px 0px 2px 1px #999; /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 0px 2px 1px #999;
    background-size: auto 100%;
    animation: transl 0.1s linear;
    /*Set our animation play state to paused initially */
    animation-play-state: paused;
}
.main_container {
    z-index: 100000;
    height: 120px;
    width: 120px;
    position: fixed;
    background: transparent;
    bottom: 10px;
    left: 10px;
}
.main_circle:hover {
    /* Toggle our animation play state to running when we are hovering over our sticker */
    animation-play-state: running;
}
.small_circle {
    height: 30px;
    width: 30px;
    left: 30px;
    bottom: 20px;
    border-radius: 30px;
    cursor: pointer;
    position: absolute;
    transition: all 0.5s ease;
    background-size: auto 100%;
    -webkit-box-shadow: 0px 0px 2px 1px #999;
    -moz-box-shadow: 0px 0px 2px 1px #999; /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 0px 2px 1px #999;
}
.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    opacity: 0.7;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
}
.small_circle:hover .tooltiptext {
    visibility: visible;
}
</style>
