import {
    ChartType,
    CHART_TYPE_TRAITS,
    CHART_TYPES,
    QuerySelectorTag,
    TraitId,
    DeviceType,
    Trait,
} from 'ponychart'
import { t } from '@/ponychart/i18n'
import { GlobalState, TraitSearch } from '@/ponychart/state'
import { GlobalOptions } from '@/ponychart/element/types'
import { SourceMemory } from '@/ponychart/memoize/sourceMemory'
import { PageStructureFactory } from '@/ponychart/structure/model'
import { structureChartCount } from '@/ponychart/structure/config'

import { seedPage } from './utils'
import { listForbiddenCharts } from './forbiddenCharts'
import {
    ChartTypeMap,
    ListPagePayload,
    Page,
    PageMode,
    PageStructure,
    REPETITIVE_PAGE_STRUCTURE,
    StructureType,
    UNIQUE_PAGE_STRUCTURE,
} from './types'

import { cloneDeep } from 'lodash'

const memoryInstance = SourceMemory.getInstance()

function combine([head, ...[headTail, ...tailTail]]: string[][]): string[] {
    if (!headTail) return head
    const combined = headTail.reduce((acc: string[], x: string) => {
        return acc.concat(head.map((h: string) => `${h}_${x}`))
    }, [])
    return combine([combined, ...tailTail])
}

export function emptyPage(alias: string, navigation: boolean): Page {
    const page = seedPage(PageStructure.SIMPLE)
    return {
        ...page,
        alias,
        pageBlocks: [],
        navigation,
        pristine: false,
    }
}

// Still used for random.ts: Not used any
export function listPages(
    globalOptions: GlobalOptions,
    charts: Partial<ChartTypeMap>,
    opts: ListPagePayload
): { pages: Page[]; next: number | null; length: number } {
    const wishedCharts = cloneDeep(opts?.wishedCharts || [])
    const startAt = opts?.startAt || 0
    const limit = opts?.limit || 10
    const chartTraits = Object.entries(charts)
        .filter((entry) => !!entry[1])
        .map(([q, chartType]: any) => ({
            id: CHART_TYPE_TRAITS[Number(q) - 1],
            value: chartType,
            querySelectorTags: [0, Number(q)] as QuerySelectorTag[],
            twbIdx: globalOptions.twbIdx || 0,
        }))
    const globalState = new GlobalState({
        ...globalOptions,
        multipleCharts: false,
    })
    const twbIdx = globalOptions.twbIdx || 0
    const traitSearch = TraitSearch.createInstance(
        memoryInstance.allAvailableSourceTraits.map((trait) => {
            const traitCopy = { ...trait }
            delete traitCopy.options // Options are dropped so they can be recreated
            return traitCopy
        }),
        [
            {
                id: TraitId.MEASURE,
                value: opts.measure || '',
                querySelectorTags: [0],
                twbIdx,
            },
            {
                id: TraitId.PAGE_MODE,
                value: PageMode.MEASURE,
                querySelectorTags: [0],
                twbIdx,
            },
            // { id: TraitId.COLUMN_COUNT, value: 3, querySelectorTags: [0], twbIdx },
            ...chartTraits,
        ],
        globalState
    )

    const factory = new PageStructureFactory()
    const structureTemplate = factory.buildStructure(globalState, traitSearch)
    const chartCount = structureTemplate.chartCount

    const toCombine = []
    const forbiddenChartTypes = listForbiddenCharts('my version')
    const allowedCharts = cloneDeep(opts?.allowedCharts || CHART_TYPES).filter(
        (chartType: ChartType) => !forbiddenChartTypes.has(chartType)
    )
    const hasGeo = memoryInstance.getHasGeo(twbIdx)
    const allCharts = allowedCharts
        .filter((ct) => ct !== ChartType.MAP || hasGeo)
        .filter((ct) => ct !== ChartType.NONE)

    for (let i = 1; i <= chartCount; i++) {
        toCombine.push(allCharts)
    }

    const combinations = combine(toCombine)
        .map((c) => c.split('_'))
        .filter(
            (combination) =>
                !wishedCharts.length ||
                wishedCharts.every((wishedChart: ChartType) =>
                    combination.includes(wishedChart)
                )
        ) as ChartType[][]

    const startingCount = opts?.startingCount || 0
    let count = startingCount
    const disabled = !!opts?.disabled
    const output = []
    for (const combination of combinations) {
        if (count >= startAt && count < startAt + limit) {
            const localChart = { ...charts }
            let subCount = 0
            for (let q = 1; q <= chartCount; q++) {
                if (!localChart[q]) localChart[q] = {}
                localChart[q] = combination[subCount]
                subCount++
            }
            // Pass down chartTypes to state
            for (const q in localChart)
                traitSearch.pushTrait(
                    {
                        id: CHART_TYPE_TRAITS[Number(q) - 1],
                        value: localChart[q],
                        querySelectorTags: [0, Number(q)] as any,
                    },
                    { attributesToUpdate: ['*'] }
                )
            output.push({ ...structureTemplate.buildPage(), disabled })
        }
        count++
    }

    if (!opts?.allowedCharts?.length || disabled) {
        return {
            pages: output,
            next: count < startAt + limit ? null : startAt + limit,
            length: Math.ceil(count / limit),
        }
    }

    const { pages, next, length } = listPages(globalOptions, charts, {
        ...opts,
        allowedCharts: CHART_TYPES.filter((ct) => !allowedCharts.includes(ct)),
        startingCount: count,
        disabled: true,
    })
    for (const p of pages) {
        output.push(p)
    }

    return {
        pages: output,
        next,
        length,
    }
}

export function listPageStructures(
    globalOptions: GlobalOptions,
    sourceTraits: Trait[],
    opts: {
        wishedStructureTypes?: StructureType[]
        limit?: number
        startAt?: number
        measure: string
        wishedChartCount?: number | null
    }
): { pages: Page[]; next: number | null; length: number } {
    const startAt = opts?.startAt || 0
    const limit = opts?.limit || 10
    const wishedStructureTypes = opts?.wishedStructureTypes || []
    const twbIdx = globalOptions.twbIdx || 0
    const chartTraits = CHART_TYPE_TRAITS.map(
        (traitId: TraitId, i: number) => ({
            id: traitId,
            value: ChartType.KPI,
            querySelectorTags: [0, Number(i + 1)] as QuerySelectorTag[],
            twbIdx,
        })
    )
    const globalState = new GlobalState({
        ...globalOptions,
        multipleCharts: false,
    })
    const traitSearch = TraitSearch.createInstance(sourceTraits, [
        {
            id: TraitId.MEASURE,
            value: opts.measure || '',
            querySelectorTags: [0],
            twbIdx,
        },
        {
            id: TraitId.PAGE_MODE,
            value: PageMode.MEASURE,
            querySelectorTags: [0],
            twbIdx,
        },
        // { id: TraitId.COLUMN_COUNT, value: 3, querySelectorTags: [0], twbIdx },
        ...chartTraits,
    ])

    const factory = new PageStructureFactory()

    const pages: Page[] = []
    const hasAll = wishedStructureTypes.includes(StructureType.ALL)
    if (hasAll || wishedStructureTypes.includes(StructureType.REPETITIVE)) {
        for (const structure of REPETITIVE_PAGE_STRUCTURE) {
            if (
                opts.wishedChartCount &&
                opts.wishedChartCount !== structureChartCount(structure)
            )
                continue
            globalState.setPageStructure(structure)
            pages.push(factory.buildPage(globalState, traitSearch))
        }
    }
    if (hasAll || wishedStructureTypes.includes(StructureType.UNIQUE)) {
        for (const structure of UNIQUE_PAGE_STRUCTURE) {
            if (
                opts.wishedChartCount &&
                opts.wishedChartCount !== structureChartCount(structure)
            )
                continue
            globalState.setPageStructure(structure)
            pages.push(factory.buildPage(globalState, traitSearch))
        }
    }
    const output: Page[] = []
    let count = 0
    for (const page of pages) {
        if (count >= startAt && count < startAt + limit) {
            output.push(page)
        }
        count++
    }
    return {
        pages: output,
        next: count < startAt + limit ? null : startAt + limit,
        length: Math.ceil(count / limit),
    }
}

/**
 * Function to return a string representation of the charts on a given page
 *
 * @param page a Page object
 * @returns string
 */
export function pageCharts(page: Page): string {
    const TRAIT_MAP = CHART_TYPE_TRAITS.reduce(
        (acc, traitId, i) => ({ ...acc, [traitId]: i + 1 }),
        {}
    )
    const traitMap: Partial<{ [q in TraitId]: ChartType }> = {}
    for (const trait of (page.pageBlocks || [])[0].traits) {
        if (TRAIT_MAP[trait.id]) {
            traitMap[trait.id] = trait.value as ChartType
        }
    }
    return Object.entries(traitMap)
        .filter((entry) => !!entry[1] && entry[1] !== ChartType.NONE)
        .sort((a, b) => TRAIT_MAP[a[0]] - TRAIT_MAP[b[0]])
        .map((entry) => t(`charts.${entry[1]}`))
        .join(' + ')
}
