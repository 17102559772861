<template lang="pug">
v-dialog(v-model='dialog', hide-overlay, persistent, :width='width')
    v-card.borderRadius(:width='width')
        v-card-title.headline.grey.lighten-2.pa-8(primary-title='')
            v-row(justify='space-between')
                v-icon(color='error') warning
                span.text-no-wrap.red--text {{ title }}
                v-spacer
                v-progress-circular(
                    :rotate='-90',
                    :width='5',
                    :size='30',
                    :value='100 - (seconds * 100) / 30',
                    color='error'
                ) 
                    span(style='font-size: 10px') {{ seconds }}
        v-card-text
            v-row.my-3(wrap, justify='center', v-if='mode === "idle"')
                v-flex(xs5='')
                    v-card-text(style='text-align: justify') {{ $t("disconnect.par_1") }}
                    v-card-text(style='text-align: justify') {{ $t("disconnect.par_2") }}
                v-flex(xs4, offset-xs1='')
                    img(src='@/assets/defense.png', alt='')
            ul.my-3(wrap, v-else)
                li.mb-1 {{ disconnectedIn }}
                li(v-if='ip') {{ $t("ip", { ip }) }}
        v-divider
        v-card-actions
            v-spacer
            v-btn(color='primary', outlined, @click='safeAction') {{ safeBtn }}
            v-btn(color='error', text, @click='unsafeAction')
                span {{ unsafeBtn }}
                v-icon(right) {{ unsafeIcon }}
</template>

<script>
import add from 'date-fns/add'

export default {
    name: 'disconnectComponent',
    props: {
        dialog: { type: Boolean, required: true },
        mode: { type: String, default: 'idle' },
        ip: { type: String },
    },
    data() {
        return {
            tmt: null,
            seconds: 60,
            future: null,
        }
    },
    computed: {
        width() {
            return this.mode === 'idle' ? 650 : 750
        },
        disconnectedIn() {
            return this.$t('disconnect.you_are', { x: this.seconds })
        },
        title() {
            if (this.mode === 'idle') return this.disconnectedIn
            if (this.mode === 'logout')
                return this.$t('someoneIsDisconnectingYou')
            if (this.mode === 'login') return this.$t('youAreAlreadyLoggedIn')
            return ''
        },
        safeBtn() {
            if (this.mode === 'idle') return this.$t('disconnect.btn_1')
            if (this.mode === 'login') return this.$t('signout')
            if (this.mode === 'logout') return this.$t('signout')
            return ''
        },
        unsafeBtn() {
            if (this.mode === 'idle') return this.$t('disconnect.btn_2')
            if (this.mode === 'logout')
                return this.$t('stayAndDisconnectOthers')
            if (this.mode === 'login') return this.$t('disconnectOthers')
            return ''
        },
        unsafeIcon() {
            if (this.mode === 'idle') return 'exit_to_app'
            else return 'warning'
        },
    },
    methods: {
        safeAction() {
            if (this.mode === 'idle') {
                this.stay()
            } else if (this.mode === 'login') {
                this.leave()
            } else if (this.mode === 'logout') {
                this.leave()
            }
        },
        unsafeAction() {
            if (this.mode === 'idle') {
                this.leave()
            } else if (this.mode === 'login') {
                this.removeCountDown()
                this.$emit('forceLogin')
                this.$emit('dialog', false)
            } else if (this.mode === 'logout') {
                this.removeCountDown()
                this.$emit('forceLogin')
                this.$emit('dialog', false)
            }
        },
        stay() {
            this.$emit('dialog', false)
            this.removeCountDown()
        },
        leave() {
            this.$emit('dialog', false)
            this.removeCountDown()
            this.$store.dispatch('cognito/signOut').then(() => {
                if(!window.location.href.includes("/demo/")) this.goTo(`/${this.lang}/login?redirect=${this.$route.path}`)
            })
        },
        removeCountDown() {
            clearInterval(this.tmt)
            this.seconds = 0
        },
        countDown() {
            // this.$emit('dialog', true)
            this.future = add(new Date(), { seconds: 30 })
            this.tmt = setInterval(
                () => {
                    // Get todays date and time
                    const now = new Date()
                    const distance = this.future - now
                    this.seconds = Math.floor((distance % (1000 * 30)) / 1000)
                    // If the count down is finished, write some text
                    if (distance < 0) {
                        this.leave()
                    }
                },
                125,
                this
            )
        },
    },
    watch: {
        dialog(dialog) {
            if (dialog) {
                this.countDown()
            }
        },
        isAppIdle: {
            handler: function (val, old) {
                if (val && !!this.isLoggedIn && old !== null) {
                    this.$emit('dialog', true)
                }
            },
            immediate: true,
        },
    },
}
</script>
<style scoped>
img {
    max-width: 100%;
    max-height: 100%;
}
</style>

<i18n>{
  "en": {
    "someoneIsDisconnectingYou": "You are trying to connect from another session",
    "youAreAlreadyLoggedIn": "You are already logged in from another session",
    "stayAndDisconnectOthers": "Stay in this session (disconnect others)",
    "disconnect": "Disconnect",
    "disconnectOthers": "Login & disconnect other sessions",
    "ip": "IP adress of the other session: {ip}"
  },
  "es": {
    "someoneIsDisconnectingYou": "Está intentando conectarse desde otra sesión",
    "youAreAlreadyLoggedIn": "Ya ha iniciado sesión desde otra sesión",
    "stayAndDisconnectOthers": "Permanecer en esta sesión (desconectar a los demás)",
    "disconnect": "Desconectar",
    "disconnectOthers": "Iniciar sesión y desconectar otras sesiones",
    "ip": "Dirección IP de la otra sesión: {ip}"
  },
  "fr": {
    "someoneIsDisconnectingYou": "Vous essayez de vous connecter à partir d'une autre session",
    "youAreAlreadyLoggedIn": "Vous êtes déjà connecté depuis une autre session",
    "stayAndDisconnectOthers": "Restez dans cette session (déconnectez les autres)",
    "disconnect": "Déconnecter",
    "disconnectOthers": "Connectez-vous et déconnectez les autres sessions",
    "ip": "Adresse IP de l'autre session: {ip}"
  }
}</i18n>
