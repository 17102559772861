import { CustomTrait } from './model'
import {
    TraitId,
    Trait,
    CHART_TYPE_TRAITS_SET,
    DefaultTraitOptions,
    SpecificTraitOptions,
} from 'ponychart'
import { TraitSearch } from '@/ponychart/state/traits'

import * as Traits from './traits'

type TraitConstructor = {
    new (
        defaultOptions: DefaultTraitOptions,
        options: SpecificTraitOptions
    ): CustomTrait
}
type IdTraitConstructor = {
    new (
        traitId: TraitId,
        defaultOptions: DefaultTraitOptions,
        options: SpecificTraitOptions
    ): CustomTrait
}

type StateTraitConstructor = {
    new (
        defaultOptions: DefaultTraitOptions,
        options: SpecificTraitOptions,
        traitSearch: TraitSearch
    ): CustomTrait
}

type IdCustomTraitConstructor = {
    new (
        traitId: TraitId,
        defaultOptions: DefaultTraitOptions,
        options: SpecificTraitOptions,
        traitSearch: TraitSearch
    ): CustomTrait
}

const SIMPLE_TRAIT_MAP: Partial<{ [t in TraitId]: TraitConstructor }> = {
    [TraitId.BLOCK_HAS_TIME_INDICATION]: Traits.BlockHasTimeIndicationTrait,
    [TraitId.BANDS]: Traits.BandsTrait,
    [TraitId.BAND_WIDTH]: Traits.BandWidthTrait,
    [TraitId.INCLUDE_PAGE_BLOCK_HEADER]: Traits.IncludePageBlockHeaderTrait,
    [TraitId.BORDER]: Traits.BorderTrait,
    [TraitId.CARD_HEIGHT]: Traits.CardHeightTrait,
    [TraitId.BLOCK_TITLE]: Traits.BlockTitleTrait,
    [TraitId.CHART_SUBTYPE]: Traits.ChartSubtypeTrait,
    [TraitId.COLOR]: Traits.ColorTrait,
    [TraitId.MEASURE_COLOR]: Traits.MeasureColorTrait,
    [TraitId.COLUMN_COUNT]: Traits.ColumnCountTrait,
    [TraitId.ALIGN_VERTICALLY]: Traits.AlignVerticallyTrait,
    [TraitId.FONT]: Traits.FontTrait,
    [TraitId.HEADER_DESIGN]: Traits.HeaderDesignTrait,
    [TraitId.HEADER_HEIGHT]: Traits.HeaderHeightTrait,
    [TraitId.LABEL_SIZE]: Traits.LabelSizeTrait,
    [TraitId.MARGIN]: Traits.MarginTrait,
    [TraitId.PADDING]: Traits.PaddingTrait,
    [TraitId.PAGE_MARGIN_X]: Traits.PageMarginXTrait,
    [TraitId.PAGE_MODE]: Traits.PageModeTrait,
    [TraitId.SIDEBAR]: Traits.SidebarTrait,
    [TraitId.SIDEBAR_DESIGN]: Traits.SidebarDesignTrait,
    [TraitId.SOURCE_DATE_AGGREGATION_LEVEL]:
        Traits.SourceDateAggregationLevelTrait,
    [TraitId.TIME_SELECTOR_ORIENTATION]: Traits.TimeSelectorOrientationTrait,
    [TraitId.TITLE_HEIGHT]: Traits.TitleHeightTrait,
    [TraitId.WITH_COLORS]: Traits.HeaderWithColorsTrait,
    [TraitId.INCLUDE_CARD_TITLE]: Traits.IncludeCardTitleTrait,
    [TraitId.WITH_LOGO]: Traits.HeaderWithLogoTrait,
    [TraitId.WITH_TIME_PERIOD]: Traits.HeaderWithTimePeriodTrait,
    [TraitId.DENSIFY_HEADER_FILTERS]: Traits.DensifyHeaderFiltersTrait,
    [TraitId.CARD_HAS_BORDERS]: Traits.CardHasBordersTrait,
}

const STATE_TRAIT_MAP: Partial<{ [t in TraitId]: StateTraitConstructor }> = {
    [TraitId.COMPARISON_PERIODS]: Traits.ComparisonPeriodTrait,
    [TraitId.COMPARISON_PERIOD_COUNT]: Traits.ComparisonPeriodCountTrait,
    [TraitId.DATE_AGGREGATION_LEVEL]: Traits.DateAggregationTrait,
    [TraitId.TOOLTIP]: Traits.TooltipTrait,
    [TraitId.LABEL]: Traits.LabelTrait,
    [TraitId.DIMENSION]: Traits.DimensionTrait,
    [TraitId.DIMENSION_2]: Traits.DimensionTrait, // TODO: double check
    [TraitId.MEASURE]: Traits.MeasureTrait,
    [TraitId.MEASURE_2]: Traits.MeasureTrait, // TODO: double check
    [TraitId.TIME_SELECTOR_TYPE]: Traits.TimeSelectorTypeTrait,
    [TraitId.CARD_TITLE]: Traits.CardTitleTrait,
}

const ID_TRAIT_MAP: Partial<{ [t in TraitId]: IdCustomTraitConstructor }> = {
    [TraitId.GEO_CITY]: Traits.GeoTrait,
    [TraitId.GEO_REGION]: Traits.GeoTrait,
    [TraitId.GEO_COUNTRY]: Traits.GeoTrait,
}

const SIMPLE_ID_TRAIT_MAP: Partial<{ [t in TraitId]: IdTraitConstructor }> = {
    [TraitId.INCLUDES_CHART_1]: Traits.IncludesChartTrait,
    [TraitId.INCLUDES_CHART_2]: Traits.IncludesChartTrait,
    [TraitId.INCLUDES_CHART_3]: Traits.IncludesChartTrait,
    [TraitId.INCLUDES_CHART_4]: Traits.IncludesChartTrait,
    [TraitId.INCLUDES_CHART_5]: Traits.IncludesChartTrait,
}

export function traitFactory(
    trait: Trait,
    traitSearch: TraitSearch
): CustomTrait {
    const traitId = trait.id
    if (CHART_TYPE_TRAITS_SET.has(traitId)) {
        return new Traits.MultipleChartSelectionTrait(
            trait,
            {
                ...trait.options,
                maxCount: traitSearch.multipleCharts ? 4 : 1,
            },
            traitSearch
        )
    }
    const SimpleTraitMapConstructor = SIMPLE_TRAIT_MAP[traitId]
    if (SimpleTraitMapConstructor !== undefined)
        return new SimpleTraitMapConstructor(trait, trait.options || {})

    const SimpleIdTraitMapConstructor = SIMPLE_ID_TRAIT_MAP[traitId]
    if (SimpleIdTraitMapConstructor !== undefined)
        return new SimpleIdTraitMapConstructor(
            traitId,
            trait,
            trait.options || {}
        )

    const StateTraitMapConstructor = STATE_TRAIT_MAP[traitId]
    if (StateTraitMapConstructor !== undefined)
        return new StateTraitMapConstructor(
            trait,
            trait.options || {},
            traitSearch
        )

    const IdTRaitMapConstructor = ID_TRAIT_MAP[traitId]
    if (IdTRaitMapConstructor !== undefined)
        return new IdTRaitMapConstructor(
            traitId,
            trait,
            trait.options || {},
            traitSearch
        )

    throw new Error(`Could not build CustomTrait for traitId ${traitId}`)
}
