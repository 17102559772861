export interface ArrayBuilderOpts {
    sorted?: boolean
    arrayLength?: number
    arrayMaxValue?: number
    arrayMinValue?: number
}

export interface MatrixBuilderOpts {
    matrixLength?: number
    randomAverageMode?: boolean
}

export function buildNumericArray(opts: ArrayBuilderOpts = {}) {
    const arrayLength = opts?.arrayLength || 10
    const sorted = opts?.sorted || false
    const arrayMaxValue = opts?.arrayMaxValue || 1
    const arrayMinValue = opts?.arrayMinValue || 0

    const values: number[] = []
    for (let i = 0; i < arrayLength; i++) {
        values.push(
            Math.random() * (arrayMaxValue - arrayMinValue) + arrayMinValue
        )
    }
    return sorted ? values.sort((a, b) => b - a) : values
}


export function buildNumericMatrix(opts: ArrayBuilderOpts & MatrixBuilderOpts = {}) {
    const matrixLength = opts?.matrixLength || 10
    const values: number[][] = []
    const randomWeights: number[] = []

    for (let i = 0; i < matrixLength; i++) {
        // Set array max value randomly
        values.push(buildNumericArray({
            ...opts,
        }))
        randomWeights.push(Math.random())
    }

    const totalWeight = randomWeights.reduce((a, b) => a + b, 0)

    const output = []
    for (const i in values) {
        output.push(
            values[i].map(
                (value: number) => value * randomWeights[i] / totalWeight
            )
        )
    }
    return output
}

export function cumulateMatrix(numericMatrix: number[][]) {
    const data = [...numericMatrix.map(row => [...row])]
    let previousRow = data[0]
    for (let i = 1; i < data.length; i++) {
        data[i] = data[i].map(
            (cell: number, j: number) => cell + previousRow[j]
        )
        previousRow = data[i]
    }
    const maxAxisArray = Math.max(...data[data.length - 1])
    return data.map((row) => (row.map((v: number) => v / maxAxisArray)))
}

export function sumMatrixToArray(numericMatrix: number[][]) {
    const output = []
    for (const i in numericMatrix[0]) {
        let val = 0
        for (const j in numericMatrix) {
            val += numericMatrix[j][i]
        }
        output.push(val)
    }
    const maxValue = Math.max(...output)
    return output.map(v => v / maxValue)
}

export function buildNumericCumulatedMatrix(opts: ArrayBuilderOpts & MatrixBuilderOpts = {}) {
    const output = buildNumericMatrix(opts)
    let previousRow = output[0]
    for (let i = 1; i < output.length; i++) {
        output[i] = output[i].map(
            (cell: number, j: number) => cell + previousRow[j]
        )
        previousRow = output[i]
    }
    const maxAxisArray = Math.max(...output[output.length - 1])
    return output.map((row) => (row.map((v: number) => v / maxAxisArray)))
}