<template lang="pug">
.d-flex.flex-column.align-start
    .title(v-if='sources.length > 0') {{ $t("previousUploads") }}:
    v-expansion-panels
        v-expansion-panel.ma-4.py-2(
            v-for='source in sources',
            :key='source.id',
            style='border-radius: 10px'
        )
            v-expansion-panel-header
                v-row(align='start', justify='center')
                    v-icon(large) file_upload

                    router-link.mx-4(
                        :to='`/dashboards/${source.id}/${source.step}`',
                        style='margin: auto'
                    ) Upload&nbsp;# {{ source.id }}

                    span(
                        style='margin: auto; cursor: pointer',
                        v-if='!editing.includes(source.id)',
                        @click='editing.push(source.id)'
                    ) {{ source.caption }}
                    v-text-field(
                        v-else,
                        :label='$t("rename")',
                        v-model='source.caption',
                        @blur='leaveEditing(source.id)',
                        @change='leaveEditingTimeout(source.id)',
                        @input='leaveEditingTimeout(source.id)',
                        dense,
                        hide-details,
                        outlined
                    )
                    v-btn.ml-1(
                        small,
                        text,
                        icon,
                        v-if='!editing.includes(source.id)',
                        @click='editing.push(source.id)'
                    ) 
                        v-icon(small) edit
                    v-spacer
            v-expansion-panel-content
                v-row
                    v-col(justify='start', align='start')
                        ul
                            li
                                b {{ $t("createdAt") }}:&nbsp;
                                span {{ format(source.createdAt) }}
                            li
                                b {{ $t("filesize") }}:&nbsp;
                                span {{ bytesToSize(source.filesize) }}
                    v-divider(vertical)

                    v-col(justify='start', align='start')
                        ul 
                            li(v-if='scheduledForDeletion(source)')
                                b {{ $t("ttl") }}:&nbsp;
                                span {{ format(source.ttl) }}
                            li 
                                b {{ $t("version") }}:&nbsp;
                                span {{ source.version }}
                            li 
                                v-icon history
                                router-link.ml-1(
                                    :to='`/downloads/${source.id}/results`'
                                ) {{ $t("download") }}
                    v-divider(vertical)

                    v-row
                        v-spacer
                        v-col(align='end', justify='end')
                            v-switch(
                                v-if='isSubscribed',
                                :input-value='scheduledForDeletion(source)',
                                :label='$t("ttl")',
                                @change='(e) => handleDeletetion(source, e)'
                            )
                            v-btn(
                                icon,
                                color='red',
                                @click='deleteSource(source.id)',
                                :loading='loadings.includes(source.id)'
                            )
                                v-icon delete
    .d-flex.flex-row.align-center.mb-4(style='width: 100%')
        v-spacer
        v-btn(
            @click='listSources(keys[keys.length - 1])',
            outlined,
            v-if='currentKey',
            rounded,
            :loading='loading'
        ) {{ $t("loadMore") }}
        v-spacer
</template>
<script>
import SourceService from '@/services/sourceService'
import { bytesToSize } from '@/utils'
import { formatRelative } from 'date-fns'
import { es, fr } from 'date-fns/locale'

export default {
    name: 'sourcePagination',
    props: {
        itemsPerPage: {
            type: Number,
            default: 10,
        },
    },
    data: () => ({
        loading: false,
        page: 1,
        currentKey: null,
        keys: [],
        sources: [],
        loadings: [],
        editing: [],
        tmts: {},
        locales: { es, fr },
    }),
    watch: {
        page: {
            handler: function (page) {
                if (page === 1) {
                    this.listSources()
                } else if (page <= this.keys.length + 1) {
                    this.listSources(this.keys[page - 2])
                }
            },
            immediate: true,
        },
    },
    computed: {
        isSubscribed() {
            return !!this.$store.getters.subscription
        },
        pageCount() {
            return this.keys.length + 1
        },
        headers() {
            return [
                'id',
                'filename',
                'version',
                'filesize',
                'createdAt',
                'ttl',
            ].map((value) => ({ text: this.$t(value), value, sortable: false }))
        },
    },
    methods: {
        leaveEditing(sourceId) {
            this.editing = this.editing.filter((id) => id != sourceId)
        },
        leaveEditingTimeout(sourceId) {
            clearTimeout(this.tmts[sourceId])
            this.tmts[sourceId] = setTimeout(
                () => {
                    this.leaveEditing(sourceId)
                    this.handleSave(sourceId)
                },
                3000,
                this
            )
        },
        async handleSave(sourceId) {
            const source = this.sources.find((s) => s.id == sourceId)
            if (!source) return
            await SourceService.saveSource(sourceId, source, { noResult: true })
        },
        async deleteSource(sourceId) {
            this.loadings.push(sourceId)
            try {
                await SourceService.deleteSource(sourceId)
                this.sources = this.sources.filter((s) => s.id !== sourceId)
            } catch (e) {
                this.showToast()
            } finally {
                this.loadings = this.loadings.filter((id) => id !== sourceId)
            }
        },
        scheduledForDeletion(source) {
            return new Date(source?.ttl) > new Date()
        },
        async handleDeletetion(source, e) {
            const newSource = !e
                ? await SourceService.deleteTtl(source.id)
                : await SourceService.updateTtl(
                      source.id,
                      new Date((Date.now() + 12096e5) / 1000).getTime() // 14 days in milliseconds
                  )
            this.sources = this.sources.map((s) =>
                s.id === newSource.id ? newSource : s
            )
        },
        fileName(source) {
            if (source.fileExtension)
                return `${source.filename}.${source.fileExtension}`
            return source.filename
        },
        async listSources(key) {
            this.loading = true
            const { sources, lastKey } = await SourceService.listSources(
                this.itemsPerPage,
                key
            ).catch(() => {
                this.showToast({})
                return { sources: [] }
            })

            this.sources.push(
                ...sources.map((s) => ({
                    ...s,
                    caption: s.caption || this.fileName(s),
                }))
            )
            if (lastKey && !this.keys.includes(lastKey)) this.keys.push(lastKey)
            this.currentKey = lastKey
            this.loading = false
        },
        bytesToSize,
        ttlExists(d) {
            return new Date(d) > new Date()
        },
        format(d) {
            const locale = this.locales[this.$i18n.locale]
                ? { locale: this.locales[this.$i18n.locale] }
                : undefined
            return formatRelative(new Date(d), new Date(), locale)
        },
    },
}
</script>

<i18n>{
  "fr": {
    "filename": "Fichier uploadé",
    "version": "Version Tableau",
    "createdAt": "Créé",
    "id": "ID",
    "filesize": "Taille",
    "ttl": "Suppression planifiée",
    "previousUploads": "Chargements précédents",
    "loadMore": "Charger plus",
    "rename": "Changer de légende pour mieux s'en souvenir",
    "download": "Historique des téléchargements"
  },
  "en": {
    "download": "Download history",
    "rename": "Change caption to better remember",
    "filename": "Uploaded file",
    "version": "Tableau Version",
    "createdAt": "Created",
    "id": "ID",
    "filesize": "Size",
    "ttl": "Planned deletion",
    "previousUploads": "Previous uploads",
    "loadMore": "Load more"
  },
  "es": {
    "filename": "Archivo subido",
    "version": "Versión de Tableau",
    "createdAt": "Creado",
    "id": "ID",
    "filesize": "Tamaño",
    "ttl": "Eliminación programada",
    "previousUploads": "Subidas anteriores",
    "loadMore": "Cargar más",
    "rename": "Cambia el título para recordar mejor",
    "download": "Historial de descargas"
  }
}</i18n>