import { DataType } from "@/ponychart/types";



export enum MeasureAggregation {
    SUM = "SUM",
    AVG = "AVG",
    COUNTD = "COUNTD",
    MIN = "MIN",
    MAX = "MAX",
}

export enum ComplexAggregation {
    POND_AVG = 'POND_AVG',
    INVERSE = 'INVERSE',
    DIV = 'DIV',
    MULTI = 'MULTI',
    ADD = 'ADD',
    SUB = 'SUB'
}

export enum FilterOperator {
    EQUALS = 'EQUALS',
    NOT_EQUALS = 'NOT_EQUALS',
    MORE = 'MORE',
    LESS = 'LESS',
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS',
    ISNULL = 'ISNULL',
    NOT_ISNULL = 'NOT_ISNULL',
}


export enum MeasurePopupType {
    COMPLEX = 'complex',
    SIMPLE = 'simple',
    NOT_SET = 'not_set',
}

export enum MeasurePopupMode {
    ADD = 'add',
    EDIT = 'edit',
}

export interface MeasureFormat {
    id: string;
    edited: boolean;
    format: string;
    initFormat: string;
    suffix: string;
    text: string;
    value: string
}

export interface MeasureFilter {
    columnId: string | undefined
    operator: FilterOperator
    value: string | number | undefined
}

export interface Measure {
    agg: MeasureAggregation | ComplexAggregation
    alias: string;
    filterOperation: 'AND' | 'OR' | undefined
    filters: MeasureFilter[]
    formatId: string
    id: string
    id1: string | undefined
    id2: string | undefined
    columnId: string
    pristine: boolean | undefined
    reversed: boolean | undefined
    type: DataType
    color: string
    twbIdx: number
    error?: string
}