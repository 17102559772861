<template lang="pug">
.wrapper
    v-progress-linear(
        style='position: fixed; z-index: 4; top: 0; width: 100vw',
        v-if='$store.state.loading',
        indeterminate,
        height='10',
        color='purple darken-2'
    )
    language(v-if='notSignUp')
        .wave
</template>

<script>
import Language from '@/components/Home/Language'
export default {
    name: 'backgroundComponent',
    components: {
        Language,
    },
    computed: {
        notSignUp() {
            return this.$route.path.indexOf('signup') == -1
        },
    },
}
</script>

<style scoped>
.wrapper {
    background: #3498db;
    background: -webkit-linear-gradient(to right top, #8e44ad 0%, #3498db 100%);
    background: -moz-linear-gradient(to right top, #8e44ad 0%, #3498db 100%);
    background: -o-linear-gradient(to right top, #8e44ad 0%, #3498db 100%);
    background: linear-gradient(to right top, #8e44ad 0%, #3498db 100%);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.wrapper.form-success .container h1 {
    transform: translateY(85px);
}
.wave {
    position: absolute;
    width: 100%;
    /*height from the svg file*/
    height: 140px;
    bottom: 0;
    left: 0;
    opacity: 0.6;
    background-image: url('../../assets/wave.svg');
    background-repeat: repeat-x;
    transform: scale(2, 2);
}

.wave:before {
    content: '';
    width: 100%;
    height: 140px;
    background-image: url('../../assets/wave.svg');
    background-repeat: repeat-x;
    transform: scale(2, 2) translateX(100px);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
}

.wave:after {
    content: '';
    width: 100%;
    height: 140px;
    background-image: url('../../assets/wave.svg');
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    transform: scale(2, 2) translateX(-100px);
    left: 0;
    opacity: 0.6;
}
</style>
