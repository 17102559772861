<template lang="pug">
v-tooltip(bottom)
  template(v-slot:activator='{ on }')
    .grad-square(
      v-on='on',
      slot='activator',
      :class='[!reversed && "open", disabled && "disabled"]',
      @click='$emit("click")'
    )
  span(v-html='$t("creation.color")')
</template>

<script>
export default {
  name: 'GradSquare',
  props: {
    reversed: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped>
.grad-square {
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 2.5px;
  background-image: -webkit-linear-gradient(to top, #c4c6c8 0, #a50a1f 100%);
  background-image: -moz-linear-gradient(to top, #c4c6c8 0, #a50a1f 100%);
  background-image: -ms-linear-gradient(to top, #c4c6c8 0, #a50a1f 100%);
  background-image: -o-linear-gradient(to top, #c4c6c8 0, #a50a1f 100%);
  background-image: linear-gradient(to top, #c4c6c8 0, #a50a1f 100%);
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}
.grad-square.open {
  cursor: pointer;
  background-image: -webkit-linear-gradient(to top, #c4c6c8 0, #254670 100%);
  background-image: -moz-linear-gradient(to top, #c4c6c8 0, #254670 100%);
  background-image: -ms-linear-gradient(to top, #c4c6c8 0, #254670 100%);
  background-image: -o-linear-gradient(to top, #c4c6c8 0, #254670 100%);
  background-image: linear-gradient(to top, #c4c6c8 0, #254670 100%);
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}
.grad-square.disabled {
  background-image: -webkit-linear-gradient(to top, #fff 0, #3f3f3f 100%);
  background-image: -moz-linear-gradient(to top, #fff 0, #3f3f3f 100%);
  background-image: -ms-linear-gradient(to top, #fff 0, #3f3f3f 100%);
  background-image: -o-linear-gradient(to top, #fff 0, #3f3f3f 100%);
  background-image: linear-gradient(to top, #fff 0, #3f3f3f 100%);
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}
.grad-square.disabled.open {
  background-image: -webkit-linear-gradient(to top, #fff 0, #3f3f3f 100%);
  background-image: -moz-linear-gradient(to top, #fff 0, #3f3f3f 100%);
  background-image: -ms-linear-gradient(to top, #fff 0, #3f3f3f 100%);
  background-image: -o-linear-gradient(to top, #fff 0, #3f3f3f 100%);
  background-image: linear-gradient(to top, #fff 0, #3f3f3f 100%);
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}
</style>