
import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { GlobalMixins } from '@/mixins'
import {
    buildRandomPage,
    DashboardPreference,
    disableAllAccessRights,
    enableAllAccessRights,
    GlobalOptions,
    makeRawAccessRights,
    Page as _Page,
    SidebarPayload,
} from '@/ponychart'
import {
    TraitId,
    Trait,
    AccessRights,
    RawAccessRights,
    DEFAULT_ACCESS_RIGHTS,
    DEFAULT_RAW_ACCESS_RIGHTS,
    STYLING_TRAIT_IDS,
    HEADER_TRAIT_IDS,
} from 'ponychart'

import AccessRightsService from '@/services/accessRightsService'
import DashboardPreferenceService from '@/services/dashboardPreferenceService'

import Page from '@/components/Pages/Page.vue'
import { cloneDeep } from 'lodash'
import { SimpleTraitSearch } from '@/ponychart/state/traits'

@Component({ components: { Page } })
export default class AccessRightsComponent extends mixins(GlobalMixins) {
    tmt: { company?: number; accessRights?: number } = {
        company: undefined,
        accessRights: undefined,
    }
    role: string | null = null
    accessRights: AccessRights = cloneDeep(DEFAULT_ACCESS_RIGHTS)
    rawAccessRights: RawAccessRights = cloneDeep(DEFAULT_RAW_ACCESS_RIGHTS)
    loading = false
    allSelected = false
    stylingSelected = false
    headerSelected = false
    noSelected = false
    noStylingSelected = false
    noHeaderSelected = false
    dashboardPreferences: DashboardPreference[] = []
    page: _Page | null = null
    defaultSourceTraits: Trait[] = []

    get disabled() {
        let count = 0
        for (const dashboardPreference of this.dashboardPreferences) {
            if (!dashboardPreference.isHiddenFor.includes(this.role as string))
                count += 1
        }
        return count <= 1
    }

    get accessRoles(): string[] {
        return this.$store.state.company.accessRoles || []
    }
    set accessRoles(accessRoles: string[]) {
        this.$store.commit('SET', { company: { accessRoles } })
        this.saveCompany()
    }

    get display2() {
        return (
            this.page &&
            this.dashboardPreferences.length > 0 &&
            this.accessRoles.length > 0
        )
    }

    get display3() {
        return (
            this.page &&
            this.dashboardPreferences.length > 0 &&
            this.role &&
            this.accessRoles.includes(this.role)
        )
    }

    // get iconAll() {
    //     if (this.allSelected) return 'mdi-checkbox-marked'
    //     if (this.noSelected) return 'mdi-checkbox-blank-outline'
    //     return 'mdi-minus-box'
    // }

    // get iconHeader() {
    //     if (this.headerSelected) return 'mdi-checkbox-marked'
    //     if (this.noHeaderSelected) return 'mdi-checkbox-blank-outline'
    //     return 'mdi-minus-box'
    // }

    // get iconStyling() {
    //     if (this.stylingSelected) return 'mdi-checkbox-marked'
    //     if (this.noStylingSelected) return 'mdi-checkbox-blank-outline'
    //     return 'mdi-minus-box'
    // }

    dashboardPreferenceClicked(i: number) {
        const dashboardPreference = this.dashboardPreferences[i]
        if (!this.role) return
        if (dashboardPreference.isHiddenFor.includes(this.role)) {
            dashboardPreference.isHiddenFor =
                dashboardPreference.isHiddenFor.filter(
                    (r: string) => r !== this.role
                )
        } else {
            dashboardPreference.isHiddenFor.push(this.role)
        }
        DashboardPreferenceService.updateDashboardPreference(
            dashboardPreference.id,
            {
                isHiddenFor: dashboardPreference.isHiddenFor.filter(
                    (r: string) => this.accessRoles.includes(r)
                ),
            }
        )
    }
    preferenceSourceOptions(
        dashboardPreference: DashboardPreference
    ): GlobalOptions {
        return {
            logoUrl: dashboardPreference.logo?.url,
            colors: dashboardPreference.colors,
        }
    }
    preferenceSidebar(
        dashboardPreference: DashboardPreference
    ): SidebarPayload {
        const traitSearch = new SimpleTraitSearch(dashboardPreference?.traits)
        const sidebar = traitSearch.getTraitStringRequiredValue(
            TraitId.SIDEBAR,
            {querySelectorTag: 0}
        ) as any
        return {
            sidebar,
            index: 0,
        }
    }

    async mounted() {
        await this.getAccessRights()
        await this.listDashboardPreferences()
        this.refreshAll()
        this.page = buildRandomPage([])
        await this.$nextTick()
        this.makeRawAccessRights()
        this.$emit('set', { loadingStep: false })
    }
    changeAllSelected() {
        const value = this.allSelected
        if (!value) {
            this.enableAll()
        } else {
            this.disableAll()
        }
        this.updateAccessRights()
    }
    changeHeaderSelected() {
        const value = this.headerSelected
        if (!value) {
            this.enableAll(STYLING_TRAIT_IDS)
        } else {
            this.disableAll(STYLING_TRAIT_IDS)
        }
        this.updateAccessRights()
    }
    changeStylingSelected() {
        const value = this.stylingSelected
        if (!value) {
            this.enableAll(HEADER_TRAIT_IDS)
        } else {
            this.disableAll(HEADER_TRAIT_IDS)
        }
        this.updateAccessRights()
    }
    async refreshAll(step = 1) {
        if (step < 0) return
        for (const key in this.rawAccessRights) {
            if (typeof this.rawAccessRights[key] === 'boolean') {
                this.rawAccessRights[key] = !this.rawAccessRights[key]
            } else {
                for (const subkey in this.rawAccessRights[key]) {
                    this.rawAccessRights[key][subkey] =
                        !this.rawAccessRights[key][subkey]
                }
            }
        }
        await this.$nextTick()
        this.refreshAll(step - 1)
    }
    enableAll(forbiddenKeys: TraitId[] = []) {
        if (!this.role) return
        enableAllAccessRights(this.accessRights, this.role, forbiddenKeys)
        this.makeRawAccessRights()
    }
    disableAll(forbiddenKeys: TraitId[] = []) {
        if (!this.role) return
        disableAllAccessRights(this.accessRights, this.role, forbiddenKeys)
        this.makeRawAccessRights()
    }
    changeAccessRole(root: string, subpath?: string) {
        if (!this.role) return
        const roles: string[] = subpath
            ? this.accessRights[root][subpath]
            : this.accessRights[root]
        const idx = roles.indexOf(this.role)
        if (idx === -1) roles.push(this.role)
        else roles.splice(idx, 1)
        this.updateAccessRights()
        this.makeRawAccessRights()
    }
    async listDashboardPreferences() {
        this.dashboardPreferences =
            await DashboardPreferenceService.listDashboardPreferences({
                asAdmin: true,
            })
    }

    async getAccessRights() {
        this.accessRights = await AccessRightsService.getAccessRights()
        // this.makeRawAccessRights()
        if (
            (!this.role || !this.accessRoles.includes(this.role)) &&
            this.accessRoles.length > 0
        ) {
            // await this.$nextTick()
            this.role = this.accessRoles[0]
        }
        this.makeRawAccessRights()
    }

    async updateAccessRights(timeout = 2000) {
        if (this.tmt.accessRights) clearTimeout(this.tmt.accessRights)
        this.tmt.accessRights = setTimeout(
            () => AccessRightsService.updateAccessRights(this.accessRights),
            timeout,
            this
        )
    }

    saveCompany() {
        if (this.tmt.company) clearTimeout(this.tmt.company)
        this.tmt.company = setTimeout(
            () => this.$store.dispatch('saveCompany'),
            2000,
            this
        )
    }

    makeRawAccessRights() {
        // const output = {}
        if (!this.role) return
        const {
            allSelected,
            stylingSelected,
            headerSelected,
            noSelected,
            noStylingSelected,
            noHeaderSelected,
        } = makeRawAccessRights(
            this.accessRights,
            this.rawAccessRights,
            this.role
        )
        this.allSelected = allSelected
        this.stylingSelected = stylingSelected
        this.headerSelected = headerSelected
        this.noSelected = noSelected
        this.noStylingSelected = noStylingSelected
        this.noHeaderSelected = noHeaderSelected
    }

    @Watch('role', { immediate: true })
    onRoleChange() {
        this.makeRawAccessRights()
    }
}
