<template lang="pug">
v-btn(
    outlined,
    rounded,
    @click='$store.dispatch("install/addToHomeScreen"), setLoading()',
    :loading='loading',
    v-if='addToHomeScreen || loading'
) 
    v-icon(color='primary', left) install_desktop
    span {{ $t("title") }}
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'installComponent',
    computed: {
        ...mapState('install', ['addToHomeScreen']),
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        setLoading() {
            this.loading = true
            setTimeout(() => (this.loading = false), 700)
        },
    },
}
</script>
<i18n>{
  "fr": {
    "title": "Télécharger l'application"
  },
  "en": {
    "title": "Download application"
  },
  "es": {
    "title": "Descargar applicacion"
  },
  "de": {
    "title": "Anwendung herunterladen"
  }
}</i18n>