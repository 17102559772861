<template lang="pug">
v-card(:width='width')
    v-color-picker.ma-2(
        show-swatches,
        :value='value',
        swatches-max-height='300px',
        :swatches='swatches',
        @input='input'
    )
</template>

<script>
import {
    TABLEAU_COLOR_VALUES,
    TABLEAU_LIGHT_VALUES,
    TABLEAU_BRIGHT_VALUES,
    TABLEAU_BLIND_VALUES,
} from '@/ponychart'
export default {
    props: {
        value: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            required: false,
            default: '300',
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data: () => ({
        swatches: [
            TABLEAU_BRIGHT_VALUES,
            TABLEAU_COLOR_VALUES,
            TABLEAU_LIGHT_VALUES,
            TABLEAU_BLIND_VALUES,
        ],
    }),
    methods: {
        input(e) {
            if (typeof e === 'string') {
                this.$emit('change', e)
            } else {
                this.$emit('change', e.hex)
            }
        },
    },
}
</script>

