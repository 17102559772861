import { pick } from "lodash"


const PAGE_KEYS = [
    "id",
    "title",
    "alias",
    "navigation",
    "type",
    "isTemplatePristine",
    "order"
]

const MEASURE_KEYS = [
    "agg",
    "alias",
    "filterOperation",
    "filters",
    "formatId",
    "id",
    "id1",
    "id2",
    "columnId",
    "pristine",
    "reversed",
    "type",
    "color",
    "twbIdx"
]

const DIMENSION_KEYS = [
    "id",
    "calculations",
    "alias",
    "columnId",
    "pristine",
    "type",
    "twbIdx"
]

function _mask(payload: any, keys: string[]) {
    return pick(payload, Object.keys(payload).filter(k => keys.includes(k)))
}

export function pageMask(page: any) {
    return _mask(page, PAGE_KEYS)
}

export function dimensionMask(dimension: any) {
    return _mask(dimension, DIMENSION_KEYS)
}

export function measureMask(measure: any) {
    return _mask(measure, MEASURE_KEYS)
}