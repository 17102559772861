<template lang="pug">
v-chip(
    v-if='twbDatasources.length > 0',
    :color='twbDatasources[twbIdx].color',
    :small='small',
    :close='close',
    :x-small='xSmall',
    pill,
    dark,
    outlined,
    @click:close='$emit("click:close")'
) {{ datasourceAlias }}
</template>

<script>
export default {
    name: 'datasourcePill',
    props: {
        twbDatasources: {
            type: Array,
            required: true,
        },
        twbIdx: {
            type: Number,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        xSmall: {
            type: Boolean,
            default: false,
        },
        textLimit: {
            type: Number,
            default: 10,
        },
        close: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        datasourceAlias() {
            const datasource = this.twbDatasources[this.twbIdx]
            const alias = datasource.alias || datasource.id
            if (alias.length < this.textLimit + 3) return alias
            return alias.substring(0, this.textLimit) + '...'
        },
    },
}
</script>