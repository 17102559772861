import { Container, PonychartElement } from "@/ponychart/element/model"
import { ChartType, CHART_TYPES, CHART_TYPE_TRAITS, querySelectorTagToChartTrait, TraitId } from "ponychart";
import { Logo } from "@/ponychart/header/model"
import type { GlobalState, LocalState, TraitSearch } from "@/ponychart/state";
import { isBigSize } from "@/ponychart/utils/functions";
import { SVGChart } from "@/ponychart/svg/model";

import { Donut, Pie } from "./donutChart";
// import { DoubleBar } from "./doubleChart";
import { TimeIndication, KpiChart } from "./kpiChart";
import { AreaChart } from "./areaChart";
import { LineChart } from "./lineChart";
import { BarChart, LollipopChart } from "./lollipopChart";
import { MapChart } from "./mapChart";
import { TimeBarChart, YoYTimeBarChart } from "./timeBarChart";


type ElementConstructor = {
  new(globalState: GlobalState, localState: LocalState, traitSearch: TraitSearch): PonychartElement;
}
export class NoneChart extends Container {
  constructor(globalState: GlobalState, localState: LocalState, _traitSearch: TraitSearch) {
    super(
      ChartType.NONE,
      globalState,
      localState,
    )
  }

  compileHtml(): string {
    return new SVGChart(
      ChartType.NONE,
      this.globalState.ratios?.[this.localState.mainQuerySelectorTag]
    ).svg({
      onlyContainer: this.globalState.onlyContainer,
      style: this.styles,
      classes: this.classes,
    })
  }
}


const CHART_MAP: Partial<{ [k in ChartType]: ElementConstructor }> = {
  [ChartType.LOLLIPOP]: LollipopChart,
  [ChartType.BAR]: BarChart,
  [ChartType.PIE]: Pie,
  [ChartType.TIME_BAR]: TimeBarChart,
  [ChartType.YOY_TIME_BAR]: YoYTimeBarChart,
  // [ChartType.RUNNING]: RunningChart,
  [ChartType.DONUT]: Donut,
  // [ChartType.DOUBLE_BAR]: DoubleBar,
  [ChartType.LINE]: LineChart,
  [ChartType.AREA]: AreaChart,
  [ChartType.KPI]: KpiChart,
  [ChartType.MAP]: MapChart,
  [ChartType.LOGO]: Logo,
  [ChartType.TIME_PERIOD_INDICATION]: TimeIndication,
  [ChartType.NONE]: NoneChart,
};

export class ChartFactory {

  constructor(private globalState: GlobalState) { }

  build(localState: LocalState, traitSearch: TraitSearch): PonychartElement[] {
    const chartTypes: ChartType[] = []
    try {
      chartTypes.push(
        ...traitSearch.getTraitArrayValue(
          querySelectorTagToChartTrait(localState.mainQuerySelectorTag), 
          localState.mainQuerySelectorTag
        ) as ChartType[]
      )
      chartTypes.sort((a: ChartType, b: ChartType) => CHART_TYPES.indexOf(a) - CHART_TYPES.indexOf(b))
    } catch (e){
      throw new Error(`Could not find chartTypes`)
    }
    try{
      const charts: PonychartElement[] = []
      // console.log(chartTypes, traitSearch.traits.find(t => t.id === TraitId.CHART_1))
      for(const i in chartTypes){
        const chartType = chartTypes[i]
        if (chartType=== ChartType.NONE) continue
        const chartState = localState.copy()
            .addClasses(chartTypes.length > 1 ? [`x-chart-index-${i}`, `x-chart-max-count-${chartTypes.length}`]: [])
            .addClasses(Number(i) === 0 ? ['x-chart-display']: ['x-chart-hidden'])
            .addClasses(isBigSize(this.globalState.reduceSize) ? ["x-chart"] : [])
            .addAttribute('chart-index', i)
            .addAttribute('charts', chartTypes.join(";"))
        const Constructor = CHART_MAP[chartType]
        if (!Constructor) throw new Error(`Chart Type ${chartType} not implemented`)
        charts.push(new Constructor(this.globalState, chartState, traitSearch))
      }
      return charts
    } catch (e) {
      throw new Error(`Could not build charts ${JSON.stringify(chartTypes)}`)
    }
  }
}