import { t } from '@/ponychart/i18n'
import { ChartType } from 'ponychart'
import {
    cardIcon,
    column4Icon,
    row2Icon,
    row3Icon,
    row4Icon,
    attrsToString,
    memoizedInvertColor,
} from '@/ponychart/utils'

import {
    CLS_COL,
    CLS_DA,
    CLS_FLEX,
    CLS_ROW,
    CLS_INLINE,
    colAttr,
    rowAttr,
} from './config'
import {
    attrUpdated,
    // colStyle, rowStyle, styleDouble
} from './utils'
import { FlexType } from './types'

// const popupNames = new Set(['Donut'])
// const CHART_TYPE = ChartType.FLEX

export function flexHook() {
    return (model: any) => {
        console.log('flexHook')
        if (!model?.parent) {
            if (model.getStyle) {
                const style = model.getStyle() || {}
                const unstylable = new Set(
                    (model.get('unstylable') || []).filter((s: string) => !!s)
                )
                unstylable.add('width')
                model.set({ unstylable: Array.from(unstylable) })
                model.setStyle({
                    ...style,
                    width: 'auto',
                })
            }
            // TODO: set width as unstylable for main ?
            return
        }

        // try {
        //   if (popupNames.has(model?.getName())) {
        //     // Need to open a popup with model
        //     ctx.popup(model)
        //   }
        // } catch (e) {
        //   console.log(e)
        // }

        const parent = model.parent()
        if (parent) {
            const { title, flex } = parent.getAttributes()
            // Avoid using Array.from() on IE
            let unstylable = new Set(
                (model.get('unstylable') || []).filter((s: string) => !!s)
            )
            const style = model.getStyle() || {}
            let newStyle: any = {}
            if (flex === FlexType.FLEX) {
                model.addClass(FlexType.FLEX)
                model.removeClass(FlexType.INLINE)
                unstylable = new Set([...unstylable, 'width', 'height'])
                newStyle = { ...style, width: 'auto', height: 'auto' }
            } else {
                model.addClass(FlexType.INLINE)
                model.removeClass(FlexType.FLEX)
                if (title === CLS_ROW) {
                    unstylable.add('height')
                    newStyle.height = 'auto'
                }
                if (title === CLS_COL) {
                    unstylable.add('width')
                    newStyle.width = 'auto'
                }
            }
            model.set({ unstylable: Array.from(unstylable) })
            model.setStyle({
                ...style,
                ...newStyle,
            })
        }
    }
}

export function flex(opts: any = {}, isHeader = false) {
    // const builder = new Builder(CHART_TYPE, () => '', opts)
    return function _flex(editor: any) {
        // const opts = builder.options
        const bm = editor.BlockManager
        const labelRow = t('flex.row') || 'Horizontal Container'
        const labelColumn = t('flex.column') || 'Vertical Container'
        const category = t('flex.template')
        const defaults = {
            flexboxBlock: {},
        }

        const style = `border: 0px solid ${opts.colors?.border || '#FFF'};`

        const styledColAttr = {
            ...colAttr,
            style: `background-color: ${
                opts.colors?.background || '#FFF'
            }; border: ${opts.border} solid ${
                opts.colors?.border || '#FFF'
            }; height: 300px; margin: ${opts.margin}`,
        }

        const privateCls = [
            `.${CLS_ROW}`,
            `.${CLS_COL}`,
            `.${CLS_DA}`,
            `.${CLS_FLEX}`,
            `.${CLS_INLINE}`,
        ]
        editor.on(
            'selector:add',
            (selector: any) =>
                privateCls.indexOf(selector.getFullName()) >= 0 &&
                selector.set('private', 1)
        )

        const attrsRow = attrsToString({
            ...rowAttr,
            style,
            'data-gjs-custom-name': 'Row',
        })
        const attrsColumn = attrsToString({
            ...colAttr,
            style,
            'data-gjs-custom-name': 'Column',
        })
        const attrsStyledColumn = attrsToString(styledColAttr)

        editor.DomComponents.addType(colAttr.title, {
            isComponent: (el: any) => {
                return el.tagName === 'DIV'
            },
            view: {
                tagName: 'div',
                attributes: {},
            },
            model: {
                init() {
                    ;(this as any).listenTo(
                        this,
                        'change:attributes',
                        this.attrUpdated
                    )
                },
                attrUpdated(e: any) {
                    if (!e?.changed?.attributes) return
                    const flex = e.changed.attributes.flex
                    const components = (this as any).get('components')
                    attrUpdated(components, flex, 'column')
                },
                defaults: {
                    tagName: 'div',
                    type: colAttr.title,
                    removable: true,
                    draggable: true,
                    droppable: true,
                    unstylable: ['font-size'],
                    components: [],
                    copyable: true,
                    traits: colAttr['data-gjs-traits'],
                    attributes: { title: colAttr.title },
                },
            },
        })
        editor.DomComponents.addType(rowAttr.title, {
            isComponent: (el: any) => {
                return el.tagName === 'DIV'
            },
            view: {
                tagName: 'div',
                attributes: {},
            },
            model: {
                init() {
                    ;(this as any).listenTo(
                        this,
                        'change:attributes',
                        this.attrUpdated
                    )
                },
                attrUpdated(e: any) {
                    if (!e?.changed?.attributes) return
                    const flex = e.changed.attributes.flex
                    const components = (this as any).get('components')
                    attrUpdated(components, flex, 'row')
                },
                defaults: {
                    tagName: 'div',
                    type: rowAttr.title,
                    removable: true,
                    draggable: true,
                    droppable: true,
                    unstylable: ['font-size'],
                    copyable: true,
                    traits: colAttr['data-gjs-traits'],
                    attributes: { title: rowAttr.title },
                    classes: rowAttr.class,
                },
            },
        })
        bm.add('1row', {
            ...{
                label: `1 ${labelRow}`,
                category,
                attributes: {
                    class: 'gjs-fonts gjs-f-b1',
                    title: ChartType.ROW,
                },
                content: `<div ${attrsRow}></div>`,
                //     content: `<div ${attrsRow}></div>
                //   <style>${rowStyle(true).join('\n')}</style>`,
            },
            ...defaults.flexboxBlock,
        })
        if (!isHeader) {
            bm.add('2row', {
                ...{
                    label: `<br>${row2Icon}<br><br><span>2 ${labelRow}s</span>`,
                    category,
                    attributes: {
                        class: 'gjs-fonts fas fa-equals',
                        title: ChartType.COLUMN,
                    },
                    content: `
              <div ${attrsColumn}>
                <div ${attrsRow}></div>
                <div ${attrsRow}></div>
              </div>`,
                },
                ...defaults.flexboxBlock,
            })
            bm.add('3row', {
                ...{
                    label: `<br>${row3Icon}<br><br><span>3 ${labelRow}s</span>`,
                    category,
                    attributes: {
                        class: 'gjs-fonts gjs-f-bars',
                        title: ChartType.COLUMN,
                    },
                    content: `
                <div ${attrsColumn}>
                  <div ${attrsRow}></div>
                  <div ${attrsRow}></div>
                  <div ${attrsRow}></div>
                </div>`,
                },
                ...defaults.flexboxBlock,
            })
            bm.add('4row', {
                ...{
                    label: `<br>${row4Icon}<br><br><span>4 ${labelRow}s</span>`,
                    category,
                    attributes: {
                        class: 'gjs-fonts gjs-f-bars',
                        title: ChartType.COLUMN,
                    },
                    content: `
                <div ${attrsColumn}>
                  <div ${attrsRow}></div>
                  <div ${attrsRow}></div>
                  <div ${attrsRow}></div>
                  <div ${attrsRow}></div>
                </div>`,
                },
                ...defaults.flexboxBlock,
            })
        }

        bm.add('1column', {
            ...{
                label: `1 ${labelColumn}`,
                category,
                attributes: {
                    class: 'gjs-fonts gjs-f-b1',
                    title: ChartType.COLUMN,
                },
                content: `
            <div ${attrsColumn}></div>`,
            },
            ...defaults.flexboxBlock,
        })
        if (!isHeader) {
            bm.add('2column', {
                ...{
                    label: `2 ${labelColumn}s`,
                    category,
                    attributes: {
                        class: 'gjs-fonts gjs-f-b2',
                        title: ChartType.ROW,
                    },
                    content: `
            <div ${attrsRow}>
              <div ${attrsColumn}></div>
              <div ${attrsColumn}></div>
            </div>`,
                },
                ...defaults.flexboxBlock,
            })

            bm.add('3column', {
                ...{
                    label: `3 ${labelColumn}s`,
                    category,
                    attributes: {
                        class: 'gjs-fonts gjs-f-b3',
                        title: ChartType.ROW,
                    },
                    content: `
            <div ${attrsRow}>
              <div ${attrsColumn}></div>
              <div ${attrsColumn}></div>
              <div ${attrsColumn}></div>
            </div>`,
                },
                ...defaults.flexboxBlock,
            })

            bm.add('4column', {
                ...{
                    label: `<br>${column4Icon}<br><br><span>4 ${labelColumn}s</span>`,
                    category,
                    attributes: {
                        class: 'gjs-fonts gjs-f-b4',
                        title: ChartType.ROW,
                    },
                    content: `
            <div ${attrsRow}>
              <div ${attrsColumn}></div>
              <div ${attrsColumn}></div>
              <div ${attrsColumn}></div>
              <div ${attrsColumn}></div>
            </div>`,
                },
                ...defaults.flexboxBlock,
            })
            bm.add('card', {
                label: `<br>${cardIcon}<br><br><span>${t(
                    'flex.kpiCard',
                    opts.lang
                )}</span>`,
                category,
                attributes: { class: 'gjs-fonts' },
                content: `<div ${attrsStyledColumn}>
        <div class="gjs-fonts gjs-f-text" style="padding: 10px; background-color: ${
            opts.colors?.title || '#FFF'
        }; color: ${
                    opts.colors?.invertTitle || '#000'
                }">Insert your text here</div>
      </div>`,
            })
            bm.add('title', {
                label: t('flex.title', opts.lang),
                category,
                attributes: {
                    class: 'gjs-fonts gjs-f-text',
                    title: ChartType.TITLE,
                },
                content: {
                    type: 'text',
                    content: 'Insert your text here',
                    style: {
                        padding: '10px',
                        'background-color': opts.colors?.title || '#FFF',
                        color: opts.colors?.invertTitle || '#000',
                        flex: '1 1 0px',
                    },
                    activeOnRender: 1,
                    traits: [],
                },
            })
        }
        bm.add('text', {
            label: t('flex.text', opts.lang),
            category,
            attributes: {
                class: 'gjs-fonts gjs-f-text',
                title: ChartType.TEXT,
            },
            content: {
                type: 'text',
                content: 'Insert your text here',
                style: {
                    padding: '10px',
                    'background-color': opts.colors?.background || '#FFF',
                    color: memoizedInvertColor(
                        opts.colors?.background || '#FFF'
                    ),
                    flex: '1 1 0px',
                },
                activeOnRender: 1,
                traits: [],
            },
        })
    }
}
