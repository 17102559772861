
interface Subscription {
    status: string;
    current_period_end: number;
    current_period_start: number;
}


function isFutureDate(value: number) {
    return new Date().getTime() <= new Date(value).getTime()
}

export function findSubscription(subscriptions: Subscription[]) {
    for (const subscription of subscriptions || []) {
        // if((subscription as any).status === "active") return subscription
        const s = subscription as any
        if (
            s.status === 'active' &&
            isFutureDate(s.current_period_end * 1000) &&
            !isFutureDate(s.current_period_start * 1000)
        )
            return subscription
    }
}

