import { Store } from './utils'
import UserService from '@/services/userService'
import { GlobalMixins } from '@/mixins/global'
import { SourceMemory } from '@/ponychart'
import { findSubscription } from '@/ponychart/utils/subscription'
const globalMixins = new GlobalMixins()
export default {
    getUser({ commit }: Store) {
        return UserService.getCurrentUser().then((user) => {
            commit('SET', {
                user,
            })
            return user
        })
    },
    async saveCompany({ state }: Store) {
        return await UserService.saveCompany(
            state.company.name,
            state.company.stripeEmail,
            state.company.accessRoles
        )
    },
    saveProfile({ state, dispatch }: Store) {
        const promises = [
            UserService.updateUser(state.user.id, state.user).catch((err) => {
                const e = err?.response?.data || {}
                // dispatch('makeError', e)
                globalMixins.showToast(e)
                //TODO: be
                throw new Error(err)
            }),
        ]
        if (state.user?.role === 'admin') {
            promises.push(dispatch('saveCompany'))
        }
        return Promise.all(promises)
    },
    getYou({ state, commit }: Store) {
        if (!state.getting.user) {
            commit('SET', { getting: { user: true } })
            return UserService.getMyself()
                .then((data) => {
                    commit('SET', {
                        getting: { user: false },
                        user: data.user,
                        company: data.company,
                        subscriptions: data.subscriptions,
                        colors: data.colors,
                        logos: data.logos,
                        dashboardPreferences: data.dashboardPreferences,
                    })
                    const memory = SourceMemory.getInstance()
                    const sub = findSubscription(data.subscriptions)
                    memory.setIsSubscribed(!!sub)
                    memory.setLang(data.user.lang)
                    return null
                })
                .catch((err) => {
                    // dispatch('makeError', err)
                    globalMixins.showToast(err)
                    commit('SET', {
                        getting: { user: false },
                    })
                    throw new Error()
                })
        } else {
            return Promise.resolve(null)
        }
    },
}
