import { TraitId } from "ponychart"
import { GeoTraitId, ParameterTraitId } from "@/ponychart/element/types"
import { SourceMemoryMethods } from "@/ponychart/memoize/sourceMemory"
import { ChartContextDynamicAttributeType, ChartContextGeoAttributeTypes } from "./types"

export const PARAMETER_TRAIT_ID_TO_ATTRIBUTE: Partial<{ [key in ParameterTraitId]: ChartContextDynamicAttributeType }> = {
    [TraitId.MEASURE]: "measureContext",
    [TraitId.MEASURE_2]: "measure2Context",
    [TraitId.DIMENSION]: "dimensionContext",
    [TraitId.DIMENSION_2]: "dimension2Context",
    [TraitId.DATE_AGGREGATION_LEVEL]: "dateContext",
}
export const PARAMETER_TRAIT_ID_TO_GET_ACTION: Partial<{ [key in ParameterTraitId]: SourceMemoryMethods }> = {
    [TraitId.MEASURE]: "getMeasure",
    [TraitId.MEASURE_2]: "getMeasure",
    [TraitId.DIMENSION]: "getDimension",
    [TraitId.DIMENSION_2]: "getDimension",
}

export const GEO_TRAIT_ID_TO_ATTRIBUTE: { [key in GeoTraitId]: ChartContextGeoAttributeTypes } = {
    [TraitId.GEO_COUNTRY]: "geoCountryContext",
    [TraitId.GEO_REGION]: "geoRegionContext",
    [TraitId.GEO_CITY]: "geoCityContext",
}