<template lang="pug">
v-row(align='center', justify='center')
    v-dialog(v-model='show', width='500')
        v-card
            v-card-title 
                span {{ $t("profile_page.change") }}
                v-spacer
                v-btn(@click='show = false', icon)
                    v-icon close
            v-card-text
                v-text-field(
                    dense,
                    outlined,
                    :append-icon='showOldPassword ? "mdi-eye" : "mdi-eye-off"',
                    :type='showOldPassword ? "text" : "password"',
                    v-model='oldPassword',
                    @input='type',
                    @click:append='showOldPassword = !showOldPassword',
                    :disabled='loading',
                    :error-messages='oldPasswordErrors',
                    :success='!typing && !!oldPassword && oldPasswordErrors.length === 0',
                    :label='$t("signup_page.old")'
                )
                v-text-field(
                    dense,
                    outlined,
                    :append-icon='showNewPassword ? "mdi-eye" : "mdi-eye-off"',
                    :type='showNewPassword ? "text" : "password"',
                    v-model='newPassword',
                    @input='type',
                    @click:append='showNewPassword = !showNewPassword',
                    :disabled='loading',
                    :error-messages='newPasswordErrors',
                    :success='!typing && !!newPassword && newPasswordErrors.length === 0',
                    :label='$t("signup_page.new")'
                )
                v-text-field(
                    dense,
                    outlined,
                    :append-icon='showConfirmationPassword ? "mdi-eye" : "mdi-eye-off"',
                    :type='showConfirmationPassword ? "text" : "password"',
                    v-model='confirmationPassword',
                    @input='type',
                    @click:append='showConfirmationPassword = !showConfirmationPassword',
                    :disabled='loading',
                    :error-messages='confirmationPasswordErrors',
                    :success='!typing && !!confirmationPassword && confirmationPasswordErrors.length === 0',
                    :label='$t("signup_page.confirmation")'
                )
            v-card-actions
                v-spacer
                v-btn(
                    @click='show = false',
                    color='accent',
                    depressed,
                    outlined,
                    :disabled='loading'
                ) {{ $t("meeting.cancel") }}
                v-btn(
                    @click='changePasswordAndClose',
                    :loading='loading',
                    :disabled='changePasswordDisabled',
                    color='primary',
                    depressed
                ) {{ $t("profile_page.change") }}
    v-col(sm='9', xs='12')
        v-row(
            align='center',
            :justify='isSmall ? "space-around" : "space-between"'
        )
            h1.primary--text.my-8
                span {{ $t("hello") }} {{ firstName }}
                v-icon.primary--text.ml-1 sentiment_satisfied_alt

            div
                v-btn(
                    :disabled='disabled',
                    rounded,
                    outlined,
                    primary,
                    :loading='$store.state.loading',
                    @click.prevent='save'
                ) {{ $t("save") }}
                div(:style='buttonStyle')
        v-card.pa-4(elevation='2')
            v-row.ma-0.pa-0
                v-col(cols='6')
                    v-text-field.px-1(
                        :label='$t("firstname")',
                        required,
                        outlined,
                        :error='error.firstName',
                        @input='pristine = false',
                        v-model='firstName',
                        max='50'
                    )
                    v-text-field.px-1(
                        :label='$t("email")',
                        v-model='email',
                        @input='pristine = false',
                        :error='error.email',
                        required,
                        outlined
                    )
                    v-chip.primary.ma-2(
                        style='cursor: pointer',
                        :color='chipColor',
                        @click='TOGGLE_EVENT("payment")'
                    )
                        v-icon(color='white') insert_chart_outlined
                        h4(style='color: white') {{ subscriptionText }}
                v-col.ml-0.pl-0(cols='6')
                    v-text-field.px-1(
                        :label='$t("lastname")',
                        required,
                        outlined,
                        @input='pristine = false',
                        v-model='lastName',
                        max='50'
                    )
                    v-text-field.px-1(
                        max='50',
                        :label='$t("company")',
                        @input='pristine = false',
                        :disabled='!isAdmin',
                        :error='error.company',
                        required,
                        outlined,
                        v-model='companyName'
                    )
                    v-btn.mt-2(
                        outlined,
                        color='primary',
                        dense,
                        @click='show = true',
                        small
                    ) {{ $t("profile_page.change") }}
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import { Profile } from '@/mixins'

export default {
    name: 'userComponent',
    mixins: [Profile],
    data: () => ({
        show: false,
        loading: false,
        oldPassword: '',
        showOldPassword: false,
        newPassword: '',
        showNewPassword: false,
        confirmationPassword: '',
        showConfirmationPassword: false,
        typing: false,
        tmt: null,
        error: {
            email: false,
            company: false,
            firstName: false,
            saved: false,
        },
    }),
    methods: {
        ...mapMutations(['SET', 'TOGGLE_EVENT']),
        ...mapActions(['saveProfile']),
        type() {
            clearTimeout(this.tmt)
            this.typing = true
            this.tmt = setTimeout(() => (this.typing = false), 800)
        },
        async save() {
            this.SET({ loading: true })
            await this.saveProfile()
                .then(() => {
                    this.pristine = true
                    this.showToast({
                        type: 'success',
                        code: 'saved',
                        timeout: 2000,
                    })
                    this.error.saved = false
                })
                .catch(() => {
                    this.error.saved = true
                })
            this.SET({ loading: false })
        },
        async changePasswordAndClose() {
            this.loading = true
            await this.$store
                .dispatch('cognito/changePassword', {
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                })
                .then(() => {
                    this.show = false
                    this.showToast({
                        code: 'RESET_PASSWORD_SUCCESS',
                        type: 'info',
                        timeout: 3000,
                    })
                })
                .catch((err) => {
                    console.log(err)
                    if (this.$te('errors.' + err.code)) {
                        this.showToast({ code: err.code, message: err.message })
                    } else {
                        this.showToast({ message: err.message })
                    }
                })

            this.loading = false
        },
    },
    computed: {
        ...mapGetters(['dashboardCount', 'subscription']),
        newPasswordErrors() {
            const output = []
            if (this.newPassword.length === 0 || this.typing) return []
            if (this.newPassword.length < 8)
                output.push(this.$t('errors.PASSWORD_LENGTH'))
            if (
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                    this.newPassword
                )
            )
                output.push(this.$t('errors.PASSWORD_GUIDE'))
            return output
        },
        oldPasswordErrors() {
            const output = []
            if (this.oldPassword.length === 0 || this.typing) return []
            if (this.oldPassword.length < 8)
                output.push(this.$t('errors.PASSWORD_LENGTH'))
            if (
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                    this.oldPassword
                )
            )
                output.push(this.$t('errors.PASSWORD_GUIDE'))
            return output
        },
        confirmationPasswordErrors() {
            return this.confirmationPassword.length === 0 ||
                this.confirmationPassword === this.newPassword ||
                this.typing
                ? []
                : [this.$t('errors.PASSWORD_MATCH')]
        },
        buttonStyle() {
            return this.style(this.color)
        },
        subscriptionText() {
            if (!this.subscription) return 'Not subscribed'
            if (this.subscription?.status === 'canceled')
                return 'Subscription will end soon'
            return 'Subscribed'
        },
        chipColor() {
            if (!this.subscription) return 'red'
            if (this.subscription?.status === 'canceled') return 'orange'
            return 'primary'
        },
        color() {
            return this.disabled ? 'red' : this.pristine ? 'green' : 'orange'
        },
        isSmall() {
            return this.$vuetify.breakpoint.name == 'xs'
        },
        changePasswordDisabled() {
            return (
                this.typing ||
                !this.confirmationPassword ||
                !this.oldPassword ||
                this.confirmationPasswordErrors.length > 0 ||
                this.newPasswordErrors.length > 0
            )
        },
        disabled() {
            return Object.values(this.error).reduce(
                (acc, val) => acc || val,
                false
            )
        },
        user() {
            return this.$store.state.user
        },
        firstName: {
            get() {
                return this.user?.firstName || ''
            },
            set(firstName) {
                this.error.firstName = !firstName
                this.SET({ user: { firstName } })
            },
        },
        lastName: {
            get() {
                return this.user?.lastName || ''
            },
            set(lastName) {
                this.SET({ user: { lastName } })
            },
        },
        email: {
            get() {
                return this.user?.email || ''
            },
            set(email) {
                this.error.email = this.emailInvalid(email)
                this.SET({ user: { email } })
            },
        },
        companyName: {
            get() {
                return this.$store.state.company?.name || ''
            },
            set(name) {
                this.error.company = !name
                this.SET({ company: { name } })
            },
        },
    },
    async mounted() {
        await this.$nextTick()
        this.$emit('set', { loadingStep: false })
    },
    watch: {
        show(val) {
            if (!val) {
                this.confirmationPassword = ''
                this.oldPassword = ''
                this.newPassword = ''
                this.showConfirmationPassword = false
                this.showNewPassword = false
                this.showOldPassword = false
            }
        },
    },
}
</script>
