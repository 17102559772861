<template lang="pug">
v-container
    v-data-table.elevation-1.mt-4(
        :headers='headers',
        :items='users',
        :loading='loading',
        :sort-by='[]',
        :sort-desc='[]',
        multi-sort,
        :no-data-text='$t("noResults")'
    )
        template(v-slot:top='')
            v-toolbar(flat='')
                v-toolbar-title {{ $t("users") }}

                v-divider.mx-4(inset, vertical)
                span.ml-1 {{ $tc("licencesLeft", licencesLeft, { count: licencesLeft }) }}
                v-spacer
                v-dialog(
                    v-model='dialogEdit',
                    max-width='600',
                    :persistent='loadings.length > 0'
                )
                    template(v-slot:activator='{ on, attrs }')
                        v-btn.mb-2(
                            color='accent',
                            dark='',
                            v-bind='attrs',
                            v-on='on'
                        )
                            v-icon(left) add
                            span {{ $t("newUser") }}
                    v-card
                        v-card-title
                            span.text-h5 {{ formTitle }}

                            v-spacer
                            v-btn(
                                icon,
                                :disabled='loadings.length > 0',
                                @click='dialogEdit = false'
                            )
                                v-icon close
                        v-card-text(v-if='editedIndex < 0')
                            span {{ $t("profile_page.invite") }}
                        v-card-text
                            v-container
                                v-row
                                    v-col(cols='12', sm='6')
                                        v-text-field(
                                            dense,
                                            outlined,
                                            v-model='editedUser.firstName',
                                            :label='$t("firstname")'
                                        )
                                    v-col(cols='12', sm='6')
                                        v-text-field(
                                            dense,
                                            outlined,
                                            v-model='editedUser.lastName',
                                            :label='$t("lastname")'
                                        )
                                    v-col(cols='12', sm='6')
                                        v-text-field(
                                            dense,
                                            outlined,
                                            v-model='editedUser.email',
                                            :error-messages='emailErrors',
                                            :label='$t("email")'
                                        )
                                    v-col(cols='12', sm='6')
                                        v-select(
                                            v-model='editedUser.lang',
                                            dense,
                                            outlined,
                                            :items='langs',
                                            :label='$t("profile_page.lang")'
                                        )
                                    v-col(cols='12', sm='6')
                                        v-select(
                                            v-model='editedUser.role',
                                            :disabled='editedUser.id == userId',
                                            dense,
                                            outlined,
                                            :items='roles',
                                            :label='$t("role")'
                                        )
                                    v-col(
                                        cols='12',
                                        sm='6',
                                        v-if='companyAccessRoles.length > 0 && isAdmin'
                                    )
                                        v-select(
                                            v-model='editedUser.accessRole',
                                            dense,
                                            outlined,
                                            :items='companyAccessRoles',
                                            :label='$t("accessRoles")'
                                        )
                                    v-col.ma-0(
                                        cols='12',
                                        sm='6',
                                        v-if='isAdmin'
                                    )
                                        v-switch.ma-0(
                                            v-model='editedUser.licenced',
                                            :disabled='disabledLicence',
                                            dense,
                                            :label='$t("licenced")'
                                        )
                            v-alert(
                                type='warning',
                                outlined,
                                v-if='licencedUsers >= maxUsers && editedIndex === -1'
                            ) {{ $t("noLicenceLeft") }}
                        v-card-actions
                            v-spacer
                            v-btn(
                                color='blue darken-1',
                                text='',
                                @click='closeEdit',
                                :disabled='loadings.length > 0'
                            )
                                | {{ $t("meeting.cancel") }}
                            v-btn(
                                color='primary',
                                depressed,
                                primary,
                                :disabled='(!editedUser.firstName && !editedUser.lastName) || !editedUser.email || emailErrors.length > 0',
                                @click='save',
                                :loading='loadings.length > 0'
                            )
                                | {{ $t(editedIndex >= 0 ? "save" : "profile_page.send") }}
                v-dialog(
                    v-model='dialogDelete',
                    max-width='700',
                    :persistent='loadings.length > 0'
                )
                    v-card
                        v-card-title.text-h5
                            span {{ $t("profile_page.delete_user_warning") }}
                            v-spacer
                            v-btn(
                                icon,
                                :disabled='loadings.length > 0',
                                @click='dialogDelete = false'
                            )
                                v-icon close
                        v-card-text
                            v-alert(border='left', type='error', outlined) {{ $t("profile_page.no_access") }}
                        v-card-actions
                            v-spacer
                            v-btn(
                                color='accent',
                                text='',
                                @click='closeDelete',
                                :disabled='loadings.length > 0'
                            ) {{ $t("meeting.cancel") }}
                            v-btn(
                                color='primary',
                                depressed='',
                                @click='deleteUserConfirm',
                                :loading='loadings.length > 0'
                            ) {{ $t("profile_page.delete") }}
        template(v-slot:item.role='{ item }')
            span {{ $t(item.role) }}
        template(v-slot:item.licenced='{ item }')
            v-chip(small, :color='item.licenced ? "green" : "orange"', dark)
                v-icon(x-small, left) {{ item.licenced ? "check" : "close" }}
                span {{ $t(item.licenced ? "licenced" : "notLicenced") }}
        template(v-slot:item.actions='{ item }')
            v-icon.mr-2(small, @click='editUser(item)') mdi-pencil
            v-icon(small, @click='deleteUser(item)', v-if='item.id !== userId') mdi-delete
</template>

<script>
import UserService from '@/services/userService'
export default {
    name: 'usersComponent',
    props: {
        maxUsers: {
            type: Number,
            default: 1,
        },
    },
    data: () => ({
        users: [],
        dialogEdit: false,
        dialogDelete: false,
        loading: false,
        loadings: [],
        editedIndex: -1,
        typing: false,
        tmt: null,
        editedUser: {
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            lang: '',
            role: 'basic',
            accessRole: '',
            licenced: false,
        },
        defaultUser: {
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            lang: '',
            role: 'basic',
            accessRole: '',
            licenced: false,
        },
    }),
    watch: {
        dialogEdit(val) {
            val || this.closeEdit()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    computed: {
        disabledLicence() {
            if (this.editedUser.licenced) return false
            // If way too many users, then true
            if (this.maxUsers < this.licencedUsers) return true

            // If exactly at max and editing mode, then false
            if (this.maxUsers === this.licencedUsers && this.editedIndex !== -1)
                return true

            // If exactly at max and adding mode, then true
            if (this.maxUsers === this.licencedUsers && this.editedIndex === -1)
                return false

            return false
        },
        licencedUsers() {
            return this.users.reduce(
                (acc, user) => acc + (user.licenced ? 1 : 0),
                0
            )
        },
        licencesLeft() {
            return Math.max(0, this.maxUsers - this.licencedUsers)
        },
        companyAccessRoles() {
            return this.$store.state.company?.accessRoles || []
        },
        emailErrors() {
            return !this.email || !this.emailInvalid(this.editedUser.email)
                ? []
                : [this.$t('errors.BAD_EMAIL_FORMAT')]
        },
        userId() {
            return this.$store.state?.user?.id
        },
        headers() {
            return [
                'firstName',
                'lastName',
                'email',
                'role',
                'licenced',
                'actions',
            ].map((value) => ({
                value,
                sortable: value !== 'actions',
                text:
                    value === 'actions'
                        ? ''
                        : typeof value === 'string'
                        ? this.$t(value.toLowerCase())
                        : value,
            }))
        },
        formTitle() {
            return this.editedIndex === -1
                ? this.$t('newUser')
                : `${this.$t('edit')} ${this.$t('users').toLowerCase()}`
        },
        langs() {
            return ['es', 'fr', 'en'].map((value) => ({
                value,
                text: this.$t('profile_page.' + value),
            }))
        },
        roles() {
            return ['admin', 'basic'].map((value) => ({
                value,
                text: this.$t(value),
            }))
        },
    },
    methods: {
        type() {
            clearTimeout(this.tmt)
            this.typing = true
            this.tmt = setTimeout(() => (this.typing = false), 800)
        },
        async listUsers() {
            this.loading = true
            this.users = await UserService.listUsers()
            this.loading = false
        },
        editUser(user) {
            this.editedIndex = this.users.indexOf(user)
            this.editedUser = Object.assign({}, user)
            this.dialogEdit = true
        },
        deleteUser(user) {
            this.editedIndex = this.users.indexOf(user)
            this.editedUser = Object.assign({}, user)
            this.dialogDelete = true
        },
        async deleteUserConfirm() {
            const editedIndex = this.editedIndex
            this.loadings.push(editedIndex)
            try {
                await UserService.deleteUser(this.editedUser.id)

                this.users.splice(this.editedIndex, 1)
                this.loadings = this.loadings.filter((l) => l !== editedIndex)
                this.closeDelete()
                this.showToast({
                    type: 'info',
                    code: 'USER_DELETED',
                })
            } catch (e) {
                this.showToast({})
                this.loadings = this.loadings.filter((l) => l !== editedIndex)
            }
        },
        closeEdit() {
            this.dialogEdit = false
            this.$nextTick(() => {
                this.editedUser = Object.assign({}, this.defaultUser)
                if (this.licencedUsers < this.maxUsers)
                    this.editedUser.licenced = true
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedUser = Object.assign({}, this.defaultUser)
                if (this.licencedUsers < this.maxUsers)
                    this.editedUser.licenced = true
                this.editedIndex = -1
            })
        },
        async save() {
            const editedIndex = this.editedIndex
            this.loadings.push(editedIndex)
            try {
                if (editedIndex > -1) {
                    const data = await UserService.updateUser(
                        this.editedUser.id,
                        this.editedUser
                    )
                    Object.assign(this.users[editedIndex], data.user)

                    if (this.editedUser.id == this.$store.state.user.id) {
                        this.$store.commit('SET', { user: data.user })
                    }

                    this.showToast({
                        code: 'saved',
                        type: 'info',
                    })
                } else {
                    const user = await UserService.createUser(this.editedUser)

                    this.users.push(user)

                    this.showToast({
                        code: 'WAITING_NOT_IN_SYSTEM',
                        type: 'info',
                    })
                }
                this.loadings = this.loadings.filter((l) => l !== editedIndex)
                this.closeEdit()
            } catch (e) {
                console.log(e?.response?.data)
                const code = e?.response?.data?.code
                const message = e?.response?.data?.message
                this.loadings = this.loadings.filter((l) => l !== editedIndex)
                this.showToast({ code, message })
            }
        },
    },
    async mounted() {
        await this.listUsers()
        this.defaultUser.lang = this.lang
        this.editedUser.lang = this.lang
        this.$emit('set', { loadingStep: false })
    },
}
</script>

<i18n>{
  "en": {
    "licenced": "Licenced",
    "licencesLeft": "{count} licence left | {count} licences left",
    "notLicenced": "No licence",
    "noLicenceLeft": "This user can be invited to the app but will not be able to use it since you don't have licences left. You can remove a licence from someone or change for a higher plan."
  },
  "es": {
    "licenced": "Con licencia",
    "noLicenceLeft": "Se puede invitar a este usuario a la aplicación, pero no podrá usarla porque no te quedan licencias. Puedes quitarle una licencia a alguien o cambiar por un plan superior.",
    "licencesLeft": "Quedan {count} licencia | Quedan {count} licencias",
    "notLicenced": "Sin licencia"
  },
  "fr": {
    "licenced": "Sous licence",
    "noLicenceLeft": "Cet utilisateur peut être invité à accéder à l'application mais il ne pourra pas l'utiliser car il ne vous reste plus de licences. Vous pouvez retirer une licence à quelqu'un ou changer pour un plan supérieur.",
    "licencesLeft": "{count} licence restante | {count} licences restantes",
    "notLicenced": "Pas de licence"
  }
}</i18n>
