// import { FlexType } from './types'
// import { SpreadTrait } from '@/ponychart/trait'
// import type { State } from '../state'

const stylePrefix = 'da'
export const CLS_ROW = `${stylePrefix}-row`
export const CLS_COL = `${stylePrefix}-col`
export const CLS_DA = `${stylePrefix}`
export const CLS_FLEX = `${stylePrefix}-flex`
export const CLS_INLINE = `${stylePrefix}-inline`

export const DEFAULT_COLORS = {
    sidebar_primary: "#f3ebf3",
    sidebar_secondary: "#b07aa1",
    background: "#fbf7fb",
    light_background: "#e6e6e6",
    border: "#d4a6c8",
    title: "#b07aa1",
    curves: "#b07aa1",
}

const resizerBtm = {
    tl: 0,
    tc: 0,
    tr: 0,
    cl: 0,
    cr: 0,
    bl: 0,
    br: 0,
    minDim: 1,
    unitWidth: 'px',
    unitHeight: 'px',
    keyWidth: 'flex-basis',
    // keyHeight: 'min-height'
}

export const CONTAINER_CLASS = [CLS_ROW, CLS_COL, CLS_DA]

const STYLES = `.da-flex{
    flex-wrap: nowrap;
    align-items: stretch;
    display: flex;
    justify-content: flex-start safe;
}
.da-inline{
  display: block;
}
.da-row.da-inline {
  display: flex;
}
.da-row.da-inline > .da {
  flex: 0 1 auto;
}
.da-row > .da {
  height: 100% !important;
}
.da-col > .da {
  width: 100% !important;
}
.da{
  flex: 1 1 0px;
  min-height: 10px;
  min-width: 10px;
}
.da-col{
  flex-direction: column;
}
.da-row{
  flex-direction: row
}`

export const CLASSES: {
    selector: string
    classNames: string[]
    style: { [k: string]: string }
    forbiddenForSizedMode?: boolean
}[] = [
        {
            selector: `.${CLS_DA}`,
            classNames: [CLS_DA],
            style: {
                'flex': '1 1 0px'
            }
        },
        {
            selector: `.${CLS_DA}`,
            classNames: [CLS_DA],
            style: {
                'flex': '1 1 0px',
                'min-height': '75px',
                'min-width': '75px'
            },
            forbiddenForSizedMode: true,
        },
        {
            selector: `.${CLS_FLEX}`,
            classNames: [CLS_FLEX],
            style: {
                'flex-wrap': 'nowrap',
                'align-items': 'stretch',
                'display': 'flex',
                'justify-content': 'flex-start safe'
            }
        },
        {
            selector: `.${CLS_INLINE}`,
            classNames: [CLS_INLINE],
            style: {
                'display': 'block'
            }
        },
        {
            selector: `${CLS_DA}.${CLS_INLINE}`,
            classNames: [CLS_DA, CLS_INLINE],
            style: {
                display: "flex"
            },
        },
        {
            selector: `.${CLS_ROW}.${CLS_INLINE} > .${CLS_DA}`,
            classNames: [CLS_DA],
            style: {
                flex: '0 1 auto'
            }
        },
        {
            selector: `.${CLS_ROW} > .${CLS_DA}`,
            classNames: [CLS_DA],
            style: {
                height: "100% !important"
            }
        },
        {
            selector: `.${CLS_COL} > .${CLS_DA}`,
            classNames: [CLS_DA],
            style: {
                width: "100% !important"
            }
        },
        {
            selector: `.${CLS_COL}`,
            classNames: [CLS_COL],
            style: {
                "flex-direction": "column"
            }
        },
        {
            selector: `.${CLS_ROW}`,
            classNames: [CLS_ROW],
            style: {
                "flex-direction": "row"
            }
        }
    ]

// const FLEX_TRAIT = new SpreadTrait(new State({}), { value: FlexType.FLEX }).toDict()
// const INLINE_TRAIT = new SpreadTrait(new State({}), { value: FlexType.INLINE }).toDict()

export const rowAttr = {
    class: [CLS_ROW, CLS_DA, CLS_FLEX].join(' '),
    title: CLS_ROW,
    'data-gjs-type': CLS_ROW,
    // 'data-gjs-traits': [FLEX_TRAIT],
    'data-gjs-resizable': resizerBtm,
    'data-gjs-unstylable': ['font-size', 'color'],
}

export const colAttr = {
    class: [CLS_COL, CLS_DA, CLS_FLEX].join(' '),
    title: 'column',
    'data-gjs-type': 'column',
    // 'data-gjs-traits': [INLINE_TRAIT],
    'data-gjs-resizable': resizerBtm,
    'data-gjs-unstylable': ['font-size', 'color'],
    'data-gjs-stylable-require': ['flex-basis'],
}

export const TYPE_MAP = {
    column: 'width',
    row: 'height',
}
