import {
    ChartType,
    TraitId,
    DeviceType,
    QuerySelectorTag,
    CHART_TYPE_TRAITS,
} from 'ponychart'
import { SelectorId, SidebarPayload } from '@/ponychart/header/types'
import { PageStructure } from '@/ponychart/page/types'

export type ParameterTraitId =
    | TraitId.DATE_AGGREGATION_LEVEL
    | TraitId.MEASURE
    | TraitId.MEASURE_2
    | TraitId.DIMENSION
    | TraitId.DIMENSION_2
    | 'charts'

export type GeoTraitId =
    | TraitId.GEO_COUNTRY
    | TraitId.GEO_REGION
    | TraitId.GEO_CITY

export const PARAMETER_TRAIT_IDS: ParameterTraitId[] = [
    TraitId.DATE_AGGREGATION_LEVEL,
    TraitId.MEASURE,
    TraitId.MEASURE_2,
    TraitId.DIMENSION,
    TraitId.DIMENSION_2,
    'charts',
]

export interface ClassType {
    name: string
    private: number
}

export enum FlexType {
    FLEX = 'da-flex',
    INLINE = 'da-inline',
}
export interface StyleType {
    selectors: (string | ClassType)[]
    style: { [k: string]: string }
}

// A component attributes for GrapesJS
export type ComponentAttributes = {
    type: string
    'stylable-require': string[]
    unstylable: string[]
    resizable: any
    classes: ClassType[]
    attributes: { [k: string]: string }
    'custom-name': string
}

export const SELECTORS_WITH_PARAMETER_MAP: Partial<{
    [s in SelectorId]: ParameterTraitId
}> = {
    [SelectorId.MEASURE_SELECTOR]: TraitId.MEASURE,
    [SelectorId.MEASURE_SELECTOR_2]: TraitId.MEASURE_2,
    [SelectorId.DIMENSION_SELECTOR]: TraitId.DIMENSION,
    [SelectorId.DIMENSION_SELECTOR_2]: TraitId.DIMENSION_2,
    [SelectorId.DATE_AGGREGATION_SELECTOR]: TraitId.DATE_AGGREGATION_LEVEL,
    [SelectorId.CHART_SELECTOR]: 'charts',
}

export type ComponentType = ChartType | SelectorId

// Options related to interactivity with GrapesJs
export interface GrapesJsOptions {
    unstylable?: string[]
    resizable?: boolean
    draggable?: boolean
    copyable?: boolean
    removable?: boolean
    droppable?: boolean
    noBlock?: boolean
}

export interface SourceColors {
    curves: string
    border: string
    background: string
    title: string
    light_background: string
    sidebar_primary: string
    sidebar_secondary: string
}

export enum ParameterTitlePosition {
    TITLE = 'title',
    RIGHT = 'right',
}

export enum ParameterTitleOrientation {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}

export interface GlobalOptions {
    colors: SourceColors
    sidebarPayload?: SidebarPayload
    logoUrl?: string
    navigation?: boolean
    pageId?: string | number
    pageBlockId?: string
    grayFilter?: {
        pageBlockId: string
        querySelectorTag: QuerySelectorTag
    }
    ratios?: Partial<{
        [q in QuerySelectorTag]: { height: number; width: number }
    }>
    reduceSize?: number
    onlyContainer?: boolean
    deviceType?: DeviceType
    twbIdx?: number
    disableQuerySelectorClass?: boolean
    pageBlockIndex?: number
    pageTitle?: string
    twbIndexes?: number[]
    pageStructure?: PageStructure
    multipleCharts?: boolean
    hidePageBlockHeader?: boolean
}
export interface LocalOptions {
    isFlex?: boolean
    isCol?: boolean
    classes?: string[]
    querySelectorTags?: QuerySelectorTag[]
    styles?: {
        [k: string]: string
    }
    attributes?: { [k: string]: string } // TODO: investigate if it wouldn't be better in memory usage to move it to PonychartElement?
    isTitle?: boolean
    isPageBlockHeader?: boolean
    isCard?: boolean
    isPageBlock?: boolean
}

// Once compiled to a grapesJs compatible format, the PonychartElement is summed up by this interface
export interface PonychartComponent {
    id: string
    type: ComponentType
    classes: string[]
    attrs: { [k: string]: any }
    components?: PonychartComponent[]
    loc: {
        q: QuerySelectorTag[]
        pId: number | string
        blockId: string
        twbIdx: number
    }
}
