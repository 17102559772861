import { render, staticRenderFns } from "./Pages.vue?vue&type=template&id=dbc51f38&scoped=true&lang=pug&"
import script from "./Pages.vue?vue&type=script&lang=ts&"
export * from "./Pages.vue?vue&type=script&lang=ts&"
import style0 from "./Pages.vue?vue&type=style&index=0&id=dbc51f38&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbc51f38",
  null
  
)

/* custom blocks */
import block0 from "./Pages.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports