import { Column } from '@/ponychart/column/types'
import { Dimension } from '@/ponychart/dimension/types'
import { Measure } from '@/ponychart/measure/types'
import { DateAggregationLevel } from '@/ponychart/trait/types'
import { SourceMemory } from '@/ponychart/memoize/sourceMemory'
import { DynamicParameter } from '@/ponychart/dynamicParameter/model'
import { GeoTraitId, ParameterTraitId } from '@/ponychart/element/types'
import { QuerySelectorTag, TraitId } from 'ponychart'

import { ChartContextOpts } from './types'
import {
    GEO_TRAIT_ID_TO_ATTRIBUTE,
    PARAMETER_TRAIT_ID_TO_ATTRIBUTE,
    PARAMETER_TRAIT_ID_TO_GET_ACTION,
} from './config'
import { PonychartElement } from '../element'
import { t } from '../i18n'

const memoryInstance = SourceMemory.getInstance()

export class ChartContext {
    // Equivalent to WorksheetContext on the backend, retrieves chart-related data
    // such as Measure, Dimension, & DateAggregationLevel so it can be passed to title element for instance
    // Not used for tracking of Dynamic Parameter passed to backend
    // It basically enables "rotating" texts inside title elements when dynamic parameters are used (rotating dimension/measure/date etc etc)
    measureContext?: Measure | DynamicParameter
    measure2Context?: Measure | DynamicParameter
    dimensionContext?: Dimension | DynamicParameter
    dimension2Context?: Dimension | DynamicParameter
    dateContext?: DateAggregationLevel | DynamicParameter
    geoCountryContext?: Column
    geoRegionContext?: Column
    geoCityContext?: Column
    querySelectorTag: QuerySelectorTag
    private constructor(opts: ChartContextOpts) {
        this.measureContext = opts.measureContext
        this.measure2Context = opts.measure2Context
        this.dimensionContext = opts.dimensionContext
        this.dimension2Context = opts.dimension2Context
        this.dateContext = opts.dateContext
        this.geoCountryContext = opts.geoCountryContext
        this.geoRegionContext = opts.geoRegionContext
        this.geoCityContext = opts.geoCityContext
        this.querySelectorTag = opts.querySelectorTag
    }

    toDb() {
        const output: any = {
            q: this.querySelectorTag,
        }
        if (this.geoCountryContext?.id)
            output.geoCountry = this.geoCountryContext.id
        if (this.geoRegionContext?.id)
            output.geoRegion = this.geoRegionContext.id
        if (this.geoCityContext?.id) output.geoCity = this.geoCityContext.id
        if (this.measureContext !== undefined)
            output.measure =
                this.measureContext instanceof DynamicParameter
                    ? this.measureContext.ids
                    : [this.measureContext.id]
        if (this.dimensionContext !== undefined)
            output.dimension =
                this.dimensionContext instanceof DynamicParameter
                    ? this.dimensionContext.ids
                    : [this.dimensionContext.id]
        if (this.dateContext !== undefined)
            output.date =
                this.dateContext instanceof DynamicParameter
                    ? this.dateContext.ids
                    : [this.dateContext]
        return output
    }

    static measureArray(contextes: ChartContext[]): string[][] {
        const measures: string[][] = []
        for (const context of contextes) {
            if (context.measureContext instanceof DynamicParameter) {
                measures.push(
                    context.measureContext.ids.map(
                        (id: string) =>
                            memoryInstance.getMeasure(id)?.alias || '?'
                    )
                )
            } else if (context.measureContext) {
                measures.push([context.measureContext.alias])
            }
        }
        if (measures.length === 0) measures.push(['?'])
        return measures
    }

    static dimensionArray(contextes: ChartContext[]): string[][] {
        const dimensions: string[][] = []
        for (const context of contextes) {
            if (context.dimensionContext instanceof DynamicParameter) {
                dimensions.push(
                    context.dimensionContext.ids.map(
                        (id: string) =>
                            memoryInstance.getDimension(id)?.alias || '?'
                    )
                )
            } else if (context.dimensionContext) {
                dimensions.push([context.dimensionContext.alias])
            }
        }
        if (dimensions.length === 0) dimensions.push(['?'])
        return dimensions
    }

    static dateAggregationLevelArray(contextes: ChartContext[]): string[][] {
        const dates: string[][] = []
        for (const context of contextes) {
            if (context.dateContext instanceof DynamicParameter) {
                dates.push(
                    context.dateContext.ids.map((id: string) => t(`date.${id}`))
                )
            } else if (context.dateContext) {
                dates.push([t(`date.${context.dateContext}`)])
            }
        }

        if (dates.length === 0) dates.push(['?'])
        return dates
    }

    static fromElement(element: PonychartElement) {
        const chartContextOpts: ChartContextOpts = {
            querySelectorTag: element.localState.mainQuerySelectorTag,
            twbIdx: element.globalState.twbIdx,
        }
        for (const key in PARAMETER_TRAIT_ID_TO_ATTRIBUTE) {
            const traitId = key as ParameterTraitId
            const attributeType = PARAMETER_TRAIT_ID_TO_ATTRIBUTE[traitId]
            if (!attributeType) continue
            const traitValue = element.getStringAttribute(traitId as TraitId)
            if (!traitValue || element.globalState.twbIdx === undefined)
                continue
            const values = Array.isArray(traitValue)
                ? traitValue
                : traitValue.split(';').filter((v) => !!v)
            if (values?.length <= 1) {
                const sourceMemoryMethod =
                    PARAMETER_TRAIT_ID_TO_GET_ACTION[traitId]
                if (sourceMemoryMethod !== undefined) {
                    chartContextOpts[attributeType] = memoryInstance[
                        sourceMemoryMethod
                    ](traitValue) as any
                } else {
                    chartContextOpts[attributeType] = traitValue as any
                }
            } else {
                chartContextOpts[attributeType] = new DynamicParameter(
                    traitId,
                    {
                        ids: values,
                        pageBlockId: element.globalState.pageBlockId,
                        pageId: Number(element.globalState.pageId),
                        querySelectorTag:
                            element.localState.mainQuerySelectorTag,
                        twbIdx: element.globalState.twbIdx,
                    }
                )
            }
        }
        for (const key in GEO_TRAIT_ID_TO_ATTRIBUTE) {
            const traitId = key as GeoTraitId
            const attributeType = GEO_TRAIT_ID_TO_ATTRIBUTE[traitId]
            const traitValue = element.traitSearch.getTraitStringValue(traitId)
            if (!traitValue) continue
            const column = memoryInstance.getColumn(traitValue)
            chartContextOpts[attributeType] = column
        }
        return new ChartContext(chartContextOpts)
    }

    get parameters() {
        const parameters: DynamicParameter[] = []
        if (this.measureContext instanceof DynamicParameter)
            parameters.push(this.measureContext)
        if (this.measure2Context instanceof DynamicParameter)
            parameters.push(this.measure2Context)
        if (this.dimensionContext instanceof DynamicParameter)
            parameters.push(this.dimensionContext)
        if (this.dimension2Context instanceof DynamicParameter)
            parameters.push(this.dimension2Context)
        if (this.dateContext instanceof DynamicParameter)
            parameters.push(this.dateContext)
        return parameters
    }
}
