import { querySelectorClass } from '@/ponychart'
import { Vue, Component } from 'vue-property-decorator'
import { debounce } from 'lodash'
import { MAX_QUERY_SELECTOR_TAG, QuerySelectorTag } from 'ponychart'

// Used to attach JS events to a component to display animated background when user hovers over it
@Component
export class PageBlockEventListeners extends Vue {
    get classes() {
        const classes = []
        for (let i = -1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
            classes.push(
                querySelectorClass((this as any).pageBlockId, [i as any])
            )
        }
        return classes
    }

    private getElements(i: number) {
        const cls = this.classes[i + 1]
        return Array.from(
            document.getElementsByClassName(
                cls
            ) as HTMLCollectionOf<HTMLElement>
        )
    }

    attachIframeEvent() {
        window.addEventListener('message', this.iFrameEvent.bind(this))
    }
    iFrameEvent(message: MessageEvent) {
        if (message.data.type == 'click') {
            const el: HTMLElement = document.querySelector(
                '.section'
            ) as HTMLElement
            el.click()
        }
    }
    removeIframeEvent() {
        window.removeEventListener('message', this.iFrameEvent.bind(this))
    }
    makeEmits() {
        const output = {}
        for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
            output[`emit${i}`] = (event: any) => {
                event.stopPropagation()
                return debounce(() => {
                    this.$emit('chartClicked', { querySelectorTag: i })
                }, 250)()
            }
        }
        return output
    }
    emits: any = this.makeEmits()
    getEmit(i: QuerySelectorTag) {
        return this.emits[`emit${i}`]
    }
    colors: any = this.makeColors()
    makeColors() {
        const output = {}
        for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
            output[`color${i}`] = () => {
                for (const element of this.getElements(i)) {
                    // Set style
                    element.style.backgroundColor = '#673ab7'
                }
            }
        }
        return output
    }
    getColor(i: QuerySelectorTag) {
        return this.colors[`color${i}`]
    }
    decolors: any = this.makeDecolors()
    makeDecolors() {
        const output = {}
        for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
            output[`decolor${i}`] = () => {
                for (const element of this.getElements(i)) {
                    // Set style
                    element.style.removeProperty('background-color')
                }
            }
        }
        return output
    }
    getDecolor(i: QuerySelectorTag) {
        return this.decolors[`decolor${i}`]
    }

    cleanEvents() {
        if (!(this as any).listenForEvents) return
        // this.removeIframeEvent()
        // this.cleanPageButtonEvent()

        for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
            const cls = this.classes[i + 1]
            const elements = document.getElementsByClassName(cls)
            for (const element of elements) {
                element.removeEventListener(
                    'click',
                    this.getEmit(i as QuerySelectorTag)
                )
                element.removeEventListener(
                    'mouseover',
                    this.getColor(i as QuerySelectorTag)
                )
                element.removeEventListener(
                    'mouseout',
                    this.getDecolor(i as QuerySelectorTag)
                )
            }
        }
    }
    attachEvents() {
        if (!(this as any).listenForEvents) return

        this.cleanEvents()
        // this.attachIframeEvent()
        // this.attachPageButtonEvent()

        for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
            const cls = this.classes[i + 1]
            const elements = document.getElementsByClassName(cls)
            for (const element of elements) {
                element.addEventListener(
                    'click',
                    this.getEmit(i as QuerySelectorTag)
                )
                element.addEventListener(
                    'mouseover',
                    this.getColor(i as QuerySelectorTag)
                )
                element.addEventListener(
                    'mouseout',
                    this.getDecolor(i as QuerySelectorTag)
                )
            }
        }
    }

    beforeDestroy() {
        this.cleanEvents()
    }
    destroyed() {
        this.attachEvents()
    }
}
