<template lang="pug">
v-select(
    :label='label || $t("whichDatasource")',
    v-model='selectedTwbDatasources',
    :multiple='multiple',
    :items='twbDatasources',
    :dense='dense',
    outlined,
    item-value='id',
    item-text='alias',
    hide-details,
    chips
)
    template(v-slot:item='{ item }')
        v-checkbox(
            :input-value='selectedTwbDatasources.includes(item.id)',
            v-if='multiple'
        )
        v-chip.mx-1.mb-1(:color='item.color', filter, dark, outlined) {{ item.alias }}
    template(v-slot:selection='{ item }')
        datasource-pill(
            :twb-datasources='[item]',
            :twb-idx='0',
            :text-limit='40',
            :close='multiple',
            @click:close='selectedTwbDatasources = selectedTwbDatasources.filter((t) => t != item.id)'
        )
</template>

<script>
import DatasourcePill from '@/components/utils/DatasourcePill'
export default {
    name: 'twbDatasourceSelect',
    components: {
        DatasourcePill,
    },
    props: {
        value: {
            required: true,
        },
        twbDatasources: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        label: {
            default: null,
        },
    },
    data: () => ({
        selectedTwbDatasources: [],
    }),
    watch: {
        value: {
            handler: function (value) {
                if (this.multiple) {
                    for (const t of value) {
                        if (!this.selectedTwbDatasources.includes(t)) {
                            this.selectedTwbDatasources.push(t)
                        }
                    }
                    this.selectedTwbDatasources =
                        this.selectedTwbDatasources.filter((t) =>
                            value.includes(t)
                        )
                } else {
                    if (value !== this.selectedTwbDatasources)
                        this.selectedTwbDatasources = value
                }
            },
            immediate: true,
        },
        selectedTwbDatasources: {
            handler: function (value) {
                const isIdentical = this.multiple
                    ? value.every((t) => this.value.includes(t)) &&
                      this.value.every((t) => value.includes(t))
                    : value === this.value

                if (!isIdentical) {
                    if (this.multiple && !Array.isArray(value)) {
                        return
                    } else if (!this.multiple && Array.isArray(value)) {
                        return
                    }
                    this.$emit('input', value)
                }
            },
            immediate: true,
        },
    },
}
</script>
