import {
    ChartType,
    CHART_TYPES_SET,
    NON_DEFAULT_QUERY_SELECTOR_TAGS,
    QuerySelectorTag,
    TraitId,
} from 'ponychart'

import { PonychartElement } from '@/ponychart/element/model'
import { DynamicParameter } from '@/ponychart/dynamicParameter/model'
import { ChartContext } from '@/ponychart/chartContext/model'
import { ELEMENT_WIDTH } from '@/ponychart/header'

export function guessAppropriateColumnCount(wrapperWidth: number): number {
    let i = 1
    const distances = []
    while ((i - 1) * ELEMENT_WIDTH < wrapperWidth) {
        distances.push(Math.abs(i * ELEMENT_WIDTH - wrapperWidth))
        i++
    }
    return distances.indexOf(Math.min(...distances)) + 1
}

export function setAllCardId(element: PonychartElement) {
    element.localState = element.localState.copy().setIsCard(true)
    setAllId(element, element.id, 'cardId')
}

export function findAllContextes(
    element: PonychartElement,
    contextes: ChartContext[] = []
) {
    if (CHART_TYPES_SET.has(element.componentType as ChartType)) {
        contextes.push(ChartContext.fromElement(element))
    }
    for (const child of element.children) {
        findAllContextes(child, contextes)
    }
}

export function findAllParameters(
    element: PonychartElement
): DynamicParameter[] {
    const parameters: DynamicParameter[] = []
    for (const traitId of DynamicParameter.PARAMETER_TYPES) {
        const traitValue = element.getStringAttribute(traitId as TraitId)
        if (!traitValue || element.globalState.twbIdx === undefined) continue
        const values = Array.isArray(traitValue)
            ? traitValue
            : traitValue.split(';')
        if (values?.length <= 1) continue
        parameters.push(
            new DynamicParameter(traitId, {
                ids: values,
                pageBlockId: element.globalState.pageBlockId,
                pageId: Number(element.globalState.pageId),
                querySelectorTag: element.localState.mainQuerySelectorTag,
                twbIdx: element.globalState.twbIdx,
            })
        )
    }
    for (const child of element.children) {
        for (const parameter of findAllParameters(child)) {
            parameters.push(parameter)
        }
    }
    const output: DynamicParameter[] = []
    for (const parameter of parameters) {
        let found = false
        for (const outputParameter of output) {
            if (outputParameter.eq(parameter)) {
                outputParameter.join(parameter)
                found = true
                continue
            }
        }
        if (!found) output.push(parameter)
    }
    return output
}

export function applyParametersToFirstPageBlockHeaderFound(
    element: PonychartElement,
    parameters: DynamicParameter[]
) {
    if (
        element.localState.isPageBlockHeader ||
        element.localState.isPageBlock
    ) {
        // Set parameters on element (only if is PageBlockHeader...)
        element.setParameters(parameters)
        // Set again the onMountHook to rebuild the pageblock
        element.onMountHook()
        return true
    }
    for (const child of element.children) {
        const applied = applyParametersToFirstPageBlockHeaderFound(
            child,
            parameters
        )
        if (applied) return true
    }
    return false
}

export function applyContextesToFirstTitleFound(
    element: PonychartElement,
    contextes: ChartContext[]
) {
    if (element.localState.isTitle) {
        // Set parameters on element (only if is Title of a KPI Card...)
        element.setContextes(contextes)
        // Set again the onMountHook to rebuild the pageblock
        element.onMountHook()
        return true
    }
    for (const child of element.children) {
        const applied = applyContextesToFirstTitleFound(child, contextes)
        if (applied) return true
    }
    return false
}

export function processContextes(
    element: PonychartElement,
    contextes: ChartContext[] = []
): ChartContext[] {
    if (!element.localState.isCard) return contextes

    findAllContextes(element, contextes)

    applyContextesToFirstTitleFound(element, contextes)

    return contextes
}

function parameterBelongsToCard(
    element: PonychartElement,
    parameter: DynamicParameter
) {
    if (parameter.isSameContextAs(element)) return true
    for (const child of element.children) {
        const found = parameterBelongsToCard(child, parameter)
        if (found) return true
    }
    return false
}

export function applyLocalParameters(
    element: PonychartElement,
    parameters: DynamicParameter[]
) {
    const lookInKpiCard = element.localState.isCard

    if (lookInKpiCard) {
        let i = parameters.length
        while (i--) {
            if (!parameterBelongsToCard(element, parameters[i])) continue
            element.pushParameters(parameters[i])
            parameters.splice(i, 1)
        }
    }
    for (const child of element.children) {
        applyLocalParameters(child, parameters)
    }
}

export function processParameters(
    element: PonychartElement,
    placedParameters: DynamicParameter[]
): DynamicParameter[] {
    const lookInPageBlock = element.localState.isPageBlock
    if (lookInPageBlock) {
        const parameters = findAllParameters(element)
        const parametersToAdd = parameters.filter(
            (p) =>
                !placedParameters.some((placedParameter) =>
                    p.eq(placedParameter)
                )
        )
        applyParametersToFirstPageBlockHeaderFound(element, parametersToAdd)
        return [...placedParameters, ...parametersToAdd]
    }
    return []
}

export function setAllId(element: PonychartElement, id: any, method: 'cardId') {
    element[method] = id
    for (const child of element.children) {
        setAllId(child, id, method)
    }
}
