<template lang="pug">
v-col.ma-0
    .body-2(v-html='paragraph1')
    br
    .body-2(v-html='paragraph2')
</template>

<script>
import { MAXIMUM, MINIMUM } from '@/ponychart'
export default {
    name: 'descriptionComponent',
    props: {
        step: {
            type: String,
            required: true,
        },
        headerStep: {
            type: Number,
            default: 1,
        },
        hasPages: {
            type: Boolean,
            required: true,
        },
        isDemoMode: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        key() {
            if (this.step === 'pages' && this.hasPages) return 'pages_true'
            else if (this.step === 'pages') return 'pages_false'
            else if (this.step === 'header-design')
                return `header-design_${this.headerStep}`
            else return this.step
        },
        key2() {
            return this.key + '_bis'
        },
        paragraph1() {
            return this.$t(`doc.${this.key}`, {
                maximumDimensions: this.isDemoMode
                    ? MAXIMUM.demoDimensions
                    : MAXIMUM.dimensions,
                minimumDimensions: MINIMUM.dimensions,
                maximumMeasures: this.isDemoMode
                    ? MAXIMUM.demoMeasures
                    : MAXIMUM.measures,
                minimumMeasures: MINIMUM.measures,
            }).replace('[x]', MAXIMUM.filters)
        },
        paragraph2() {
            return this.$te(`doc.${this.key2}`)
                ? this.$t(`doc.${this.key2}`)
                : ''
        },
    },
}
</script>
