import Vue from 'vue'
import Vuex from 'vuex'
import { state } from './state'
import mutations from './mutations'
import { actions } from './actions/index'
import { getters } from './getters'
import install from "./install"

Vue.use(Vuex)


export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: { install }
})
