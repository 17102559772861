import axios from 'axios'

export default {
  async saveColors(colorsToSave: any[]) {
    const {
      data: { colors }
    } = await axios({
      url: `/v1/colors`,
      method: 'post',
      data: { colors: colorsToSave }
    })
    return colors
  },
  async getColors() {
    const {
      data: { colors }
    } = await axios({
      url: `/v1/colors`
    })
    return colors
  }
}
