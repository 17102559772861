import { Lang } from '@/ponychart'
import axios from 'axios'

export default {
    toCustomerPortal: async function (returnUrl: string) {
        const { data: { url } } = await axios.post(`/v1/customer-portal?returnUrl=${returnUrl}`)
        return url
    },
    getSession: async function (sessionId: string) {
        const {
            data: { session },
        } = await axios(`/v1/checkout/${sessionId}`)
        return session
    },
    listProducts: async function (deep = false) {
        const {
            data: { products },
        } = await axios(`/v1/products`, {
            params: {
                deep: deep ? 'true' : 'false',
            },
        })
        return products.sort((a: any, b: any) => Number(a.metadata.users) - Number(b.metadata.users))
    },
    listSubscriptions: async function (deep = false) {
        const {
            data: { subscriptions },
        } = await axios(`/v1/subscriptions`, {
            params: {
                deep: deep ? 'true' : 'false',
            },
        })
        return subscriptions
    },
    unsubscribe: async function (subscriptionId: string, reason: string) {
        const { data } = await axios.delete(
            `/v1/subscriptions/${subscriptionId}`,
            {
                data: { reason },
            }
        )
        return data
    },
    createCheckoutSession: async function (
        priceId: string,
        lang: Lang,
        successUrl: string,
        cancelUrl: string
    ) {
        const { data } = await axios({
            url: `/v1/checkout`,
            method: 'post',
            data: {
                priceId,
                lang,
                successUrl,
                cancelUrl,
                mode: 'subscription',
            },
        })
        return data
    },
}
