import { SVGChart } from '@/ponychart/svg/model'
import {
    ColorLegend,
    dynamicDimensionAlias,
    dynamicMeasureAlias,
    MeasureColorLocation,
    OverBarMode,
} from '@/ponychart/trait'
import { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import { ChartType, TraitId } from 'ponychart'

import {
    getColorForElement,
    getColorValues,
    isBigSize,
    svgArrayFromNumeric,
    TABLEAU_COLORS,
} from '@/ponychart/utils'
import { PonychartElement } from '@/ponychart/element/model'
import { RandomMemory, SourceMemory } from '@/ponychart/memoize'
import { initTooltip } from './utils'

const sourceMemory = SourceMemory.getInstance()

const INCREMENT = 8 as const

function _buildSvg(element: PonychartElement, chartType: ChartType) {
    if (element.globalState.onlyContainer)
        return SVGChart.onlyContainer(element.id, element.classes)

    const svgChart = new SVGChart(chartType, element.ratios)

    const isLollipop = chartType === ChartType.LOLLIPOP
    const reduceSize = element.globalState.reduceSize

    const measure = element.getStringRequiredAttribute(TraitId.MEASURE)

    const dimension = element.getStringRequiredAttribute(TraitId.DIMENSION)
    const color = element.getStringRequiredAttribute(TraitId.COLOR)

    const memory = RandomMemory.getInstance()

    const { id: tooltipId, html: tooltipHtml } = initTooltip(element, {
        measureId: measure,
        dimensionId: dimension,
    })

    const chartSubType =
        chartType === ChartType.BAR
            ? element.getStringRequiredAttribute(TraitId.CHART_SUBTYPE)
            : undefined
    const hasDoubleBar = chartSubType === OverBarMode.OVER_BAR

    const { numericValues, colors } = memory.getSorted(measure, dimension)
    const evoValues = memory.getEvoValues(measure, dimension)

    const values = svgArrayFromNumeric(numericValues, {
        ratio: isLollipop ? 0.3 : 0.5,
        percentOfScreen: 0.7,
        width: svgChart.height,
        height: svgChart.width,
    })
    // const evoValues = SVGChart.getEvoValues(values)
    const colorValues =
        color === ColorLegend.DIMENSION
            ? colors
            : getColorValues(
                  element.getStringRequiredAttribute(
                      TraitId.COLOR
                  ) as ColorLegend,
                  {
                      curveColor: getColorForElement(
                          element,
                          sourceMemory,
                          MeasureColorLocation.CURVES,
                          { measure }
                      ),
                      values: numericValues,
                      evoValues,
                  }
              )

    for (const i in values) {
        const { x, y, value, height } = values[i]
        const evo = evoValues[i]
        const isNegative = evo < 0
        if (hasDoubleBar && !isLollipop) {
            const heightOver = height * 0.2
            svgChart.rectangle(
                { x, y: y + height - heightOver },
                { x: x + value * (1 - evo), y: y + height * 2 + heightOver },
                isLollipop ? TABLEAU_COLORS.grey : colorValues[i],
                {
                    opacity: 0.6,
                    tooltipId,
                    thisMeasure: value,
                    lastMeasure: value * (1 - evo),
                }
            )
        }
        svgChart.rectangle(
            { x, y: y + height },
            { x: x + value, y: y + height * 2 },
            isLollipop ? TABLEAU_COLORS.grey : colorValues[i],
            {
                transparent: !isLollipop,
                tooltipId,
                thisMeasure: value,
                lastMeasure: value * (1 - evo),
            }
        )
        let xAxisTextDelta = height
        if (isLollipop) {
            xAxisTextDelta = INCREMENT
            svgChart.circle(
                { x: x + value - height * 1.5, y: y - height / 2 },
                height * 2,
                colorValues[i],
                {
                    transparent: true,
                    tooltipId,
                    thisMeasure: value,
                    lastMeasure: value * (1 - evo),
                }
            )
        }
        if (reduceSize === 1) {
            svgChart.evolution(
                { x: x + value + xAxisTextDelta, y },
                isNegative ? 'bottom' : 'top'
            )
        }
    }
    const { x, y, increment } = values[0]
    const start = { x, y }
    const end = {
        x: svgChart.width - 2 * x,
        y: svgChart.height - 2 * y,
    }
    const c = svgChart
        // X Axis
        .line(start, { x: end.x, y: start.y })
        // Y Axis
        .line(start, { x: start.x, y: end.y })
        // Axis arrow
        .triangle({ x: end.x, y: start.y }, increment / 5, 'right', '#000')

    if (reduceSize === 1) {
        c.text(`Measure: ${dynamicMeasureAlias(element.traitSearch)}`, {
            x: svgChart.width / 2,
            y: (-4 * increment) / 3,
        }).text(
            `Dimension: ${dynamicDimensionAlias(element.traitSearch)}`,
            { x: start.x / 2, y: (end.y - start.y) / 3 },
            { rotate: -90 }
        )
    }
    return (
        svgChart.rawSvg(element.id, {
            viewBox: {
                width: element.ratios.width,
                height: element.ratios.width,
            },
            preserveAspectRatio: {
                x: 'Mid',
                y: 'Min',
                suffix: 'slice',
            },
            classes: element.classes,
        }) + tooltipHtml
    )
}

export class BarChart extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.BAR, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return _buildSvg(this, ChartType.BAR)
    }
}

export class LollipopChart extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.LOLLIPOP, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return _buildSvg(this, ChartType.LOLLIPOP)
    }
}
