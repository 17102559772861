import { DeviceType, Trait, TraitId } from "ponychart";
import { CustomTrait } from "./model";
import { mainTag } from "./utils";

function tabletColumnCount(value: string | number) {
    value = Number(value);
    if (value <= 2) return value
    return value - 1
}
function mobileColumnCount(value: string | number) {
    value = Number(value);
    if (value <= 1) return value
    if (value >= 4) return Math.floor(value / 2)
    return value - 1
}
function identity(value: number | string) {
    return value
}

const DESKTOP_MAP: Partial<{ [t in TraitId]: Partial<{ [d in DeviceType]: (value: string | number) => string | number }> }> = {
    [TraitId.COLUMN_COUNT]: {
        [DeviceType.TABLET]: tabletColumnCount,
        [DeviceType.MOBILE]: mobileColumnCount,
    },
    [TraitId.CARD_HEIGHT]: {
        [DeviceType.TABLET]: identity,
        [DeviceType.MOBILE]: identity,
    },
};

const TABLET_MAP: Partial<{ [t in TraitId]: Partial<{ [d in DeviceType]: (value: string | number) => string | number }> }> = {
    [TraitId.MEASURE]: {
        [DeviceType.MOBILE]: identity
    },
    [TraitId.COLUMN_COUNT]: {
        [DeviceType.MOBILE]: tabletColumnCount
    },
    [TraitId.CARD_HEIGHT]: {
        [DeviceType.MOBILE]: identity
    },
    [TraitId.MEASURE]: {
        [DeviceType.MOBILE]: identity
    },
    [TraitId.DIMENSION]: {
        [DeviceType.MOBILE]: identity
    },
    [TraitId.INCLUDES_CHART_1]: {
        [DeviceType.MOBILE]: identity
    },
    [TraitId.INCLUDES_CHART_2]: {
        [DeviceType.MOBILE]: identity
    },
    [TraitId.INCLUDES_CHART_3]: {
        [DeviceType.MOBILE]: identity
    },
    [TraitId.INCLUDES_CHART_4]: {
        [DeviceType.MOBILE]: identity
    },
    [TraitId.ALIGN_VERTICALLY]: {
        [DeviceType.MOBILE]: identity
    }
}

export function applyCrossDeviceValues(traits: (Trait | CustomTrait)[], modifiedTrait: Trait) {
    const modifiedTraitDeviceType = modifiedTrait.deviceType || DeviceType.DESKTOP
    const desktopSimilar = modifiedTraitDeviceType === DeviceType.DESKTOP && DESKTOP_MAP[modifiedTrait.id]
    const tabletSimilar = modifiedTraitDeviceType === DeviceType.TABLET && TABLET_MAP[modifiedTrait.id]

    if (!desktopSimilar && !tabletSimilar) return

    for (const trait of traits) {
        const traitId = trait.id
        if (traitId !== modifiedTrait.id) continue
        if (!trait.pristine) continue
        if (mainTag(trait.querySelectorTags || [0]) !== mainTag(modifiedTrait.querySelectorTags || [0])) continue

        const deviceType = trait.deviceType || DeviceType.DESKTOP
        if (desktopSimilar && DESKTOP_MAP?.[traitId]?.[deviceType]) {
            const value = DESKTOP_MAP?.[traitId]?.[deviceType]?.(modifiedTrait.value) || ''
            if ((trait as CustomTrait).isInstanciated) {
                (trait as CustomTrait).setValue(value)
            } else {
                trait.value = value
            }
        }
        if (tabletSimilar && TABLET_MAP?.[traitId]?.[deviceType]) {
            const value = TABLET_MAP?.[traitId]?.[deviceType]?.(modifiedTrait.value) || ''
            if ((trait as CustomTrait).isInstanciated) {
                (trait as CustomTrait).setValue(value)
            } else {
                trait.value = value
            }
        }
    }
}

