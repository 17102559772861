import { TraitId, Trait, SOURCE_TRAIT_IDS, serverTraitFactory } from 'ponychart'
import { Measure } from '@/ponychart/measure/types'
import { Dimension } from '@/ponychart/dimension/types'
import { Format } from '@/ponychart/format/types'
import { Column } from '@/ponychart/column/types'
import { Lang } from '@/ponychart/i18n/types'
import {
    defaultDimensionArray,
    defaultMeasureArray,
} from '@/ponychart/trait/config'
import { Page } from '@/ponychart/page/types'
import { GeoMode } from '@/ponychart/trait/types'
import { getArrayValueFromString } from '@/ponychart/trait/utils'
import { DataType, IDate, IFilter } from '@/ponychart/types'
import { memoizedInvertColor } from '@/ponychart/utils/colors'

export interface Source {
    id?: number
    stepIdx?: number
    version?: string
    steps?: { [k: string]: boolean }
    step?: string
    measures?: Measure[]
    dimensions?: Dimension[]
    formats?: Format[]
    columns: Column[]
    pages?: Page[]
    filters?: IFilter[]
    logo?: { id: string; url?: string }
    lockDeviceType?: TraitId[]
    traits?: Trait[]
    colors?: Colors
    dates?: IDate[]
    twbDatasources: { id: string; alias: string; color: string }[]
}

interface Colors {
    curves: string
    border: string
    background: string
    title: string
    light_background: string
    sidebar_primary: string
    sidebar_secondary: string
}

export type SourceMemoryMethods = 'getMeasure' | 'getDimension' | 'getColumn'

export class SourceMemory {
    private static instance: SourceMemory
    private _measures: Measure[] = defaultMeasureArray({ length: 6 })
    private _dimensions: Dimension[] = defaultDimensionArray({ length: 6 })
    private _formats: Format[] = []
    private _columns: Column[] = []
    private _pages: Page[] = []
    private _filters: IFilter[] = []
    private _dates: IDate[] = []
    private _isSubscribed = false
    private _lang: Lang = Lang.EN
    private _dateAggregationLevel: 'day' | 'month' = 'month'
    private _columnMap: { [id: string]: Column } = {}
    private _sourceTraits: Trait[] = []
    private _allAvailableSourceTraits: Trait[] = []
    private _twbDatasources: { id: string; alias: string }[] = []
    private _font = 'Arial'
    private _colors?: Colors

    private constructor() {
        this._dateAggregationLevel = 'month'
    }

    public clean() {
        this.setSource({ columns: [], twbDatasources: [] })
    }

    public static getInstance(): SourceMemory {
        if (!SourceMemory.instance) SourceMemory.instance = new SourceMemory()
        return SourceMemory.instance
    }

    setSource(source: Source) {
        this.setMeasures(source?.measures || [])
        this.setDimensions(source?.dimensions || [])
        this.setFormats(source?.formats || [])
        this.setColumns(source.columns || []) //
        this.setFilters(source?.filters || [])
        this.setDates(source?.dates || [])
        this.setPages(source?.pages || [])
        this.setTwbDatasources(source?.twbDatasources || [])
        this.setSourceTraits(source?.traits || [])
        this.setColors(source?.colors)
    }

    get colors() {
        return this._colors
    }

    get isDarkLabel() {
        return (
            memoizedInvertColor(this.colors?.background || '#FFFFFF') == '#FFF'
        )
    }

    get isDarkTitle() {
        return memoizedInvertColor(this.colors?.title || '#FFFFFF') == '#FFF'
    }

    setColors(colors?: Colors) {
        this._colors = colors
    }

    setPages(pages: Page[]): void {
        this._pages = pages
    }

    setTwbDatasources(
        twbDatasources: { id: string; alias: string; color: string }[]
    ): void {
        this._twbDatasources = twbDatasources
    }

    twbDatasourceAlias(idx: number) {
        return this.twbDatasources[idx].alias
    }

    get twbDatasources() {
        return this._twbDatasources
    }

    get pages(): Page[] {
        return this._pages
    }

    get fontFamily() {
        return this._font
    }

    get sourceTraits(): Trait[] {
        return this._sourceTraits
    }

    get allAvailableSourceTraits(): Trait[] {
        if (this._allAvailableSourceTraits.length === 0) {
            for (const traitId of SOURCE_TRAIT_IDS) {
                for (const trait of serverTraitFactory(traitId, {
                    noType: false,
                })) {
                    this._allAvailableSourceTraits.push(trait)
                }
            }
        }
        return this._allAvailableSourceTraits
    }

    setSourceTraits(traits: Trait[]): void {
        this._sourceTraits = traits
        const value = this._sourceTraits.find(
            (t) => t.id === TraitId.FONT
        )?.value
        this._font = value ? String(value) : 'Arial'
    }

    get usedDimensionIds(): string[] {
        const usedDimensionIds: Set<string> = new Set()
        for (const page of this._pages) {
            for (const pageBlock of page.pageBlocks || []) {
                for (const trait of pageBlock.traits) {
                    if (
                        [TraitId.DIMENSION, TraitId.DIMENSION_2].includes(
                            trait.id as TraitId
                        )
                    ) {
                        for (const dimensionId of getArrayValueFromString(
                            String(trait.value),
                            {
                                items: trait.options?.items || [],
                                twbIdx: trait.twbIdx,
                            }
                        )) {
                            usedDimensionIds.add(dimensionId as string)
                        }
                    }
                }
            }
        }
        return Array.from(usedDimensionIds)
    }

    get usedMeasureIds(): string[] {
        const usedMeasureIds: Set<string> = new Set()
        for (const page of this._pages) {
            for (const pageBlock of page.pageBlocks || []) {
                for (const trait of pageBlock.traits) {
                    if (
                        [TraitId.MEASURE, TraitId.MEASURE_2].includes(
                            trait.id as TraitId
                        )
                    ) {
                        for (const measureId of getArrayValueFromString(
                            String(trait.value),
                            {
                                items: trait.options?.items || [],
                                twbIdx: trait.twbIdx,
                            }
                        )) {
                            usedMeasureIds.add(measureId as string)
                        }
                    }
                }
            }
        }
        return Array.from(usedMeasureIds)
    }

    setMeasures(measures: Measure[]): void {
        this._measures = measures
    }

    get measures(): Measure[] {
        return this._measures
    }

    get fakeMeasureIds(): string[] {
        return this.fakeMeasures.map((m) => m.id)
    }

    get fakeMeasures(): { id: string; alias: string; twbIdx: number }[] {
        return this._measures.length > 0
            ? this._measures
            : defaultMeasureArray({ length: 6 })
    }

    get measureNames(): { [k: string]: string } {
        return this.measures.reduce(
            (acc, m: Measure) => ({
                ...acc,
                [m.id]: m.alias,
            }),
            {}
        )
    }

    getMeasure(measureId: string) {
        for (const measure of this.measures) {
            if (measure.id === measureId) return measure
        }
        return undefined
    }

    listMeasures(twbIdx: number) {
        return this._measures.filter((m) => m.twbIdx === twbIdx)
    }

    setDimensions(dimensions: Dimension[]): void {
        this._dimensions = dimensions
    }

    listDimensions(twbIdx: number): Dimension[] {
        return this._dimensions.filter((d) => d.twbIdx === twbIdx)
    }

    get dimensions(): Dimension[] {
        return this._dimensions
    }

    get fakeDimensions(): { id: string; alias: string; twbIdx: number }[] {
        return this._dimensions.length > 0
            ? this._dimensions
            : defaultDimensionArray({ length: 6 })
    }

    get fakeDimensionIds(): string[] {
        return this.fakeDimensions.map((d) => d.id)
    }

    get dimensionNames(): { [k: string]: string } {
        return this.dimensions.reduce(
            (acc, d: Dimension) => ({
                ...acc,
                [d.id]: d.alias,
            }),
            {}
        )
    }

    getDimension(dimensionId: string) {
        for (const dimension of this.dimensions) {
            if (dimension.id === dimensionId) return dimension
        }
        return undefined
    }

    get fakeColumns(): Column[] {
        return [
            ...this.fakeMeasures.map((m) => ({
                ...m,
                type: DataType.DOUBLE,
                format: '',
            })),
            ...this.fakeDimensions.map((d) => ({
                ...d,
                type: DataType.TEXT,
                format: '',
            })),
        ]
    }

    setColumns(columns: Column[]): void {
        this._columns = columns
        this._columnMap = columns.reduce(
            (acc: { [id: string]: Column }, column: Column) => ({
                ...acc,
                [column.id]: column,
            }),
            {}
        )
    }

    get columns(): Column[] {
        return this._columns
    }

    getColumns(twbIdx: number): Column[] {
        return this.columns.filter((c) => c.twbIdx === twbIdx)
    }

    get columnsForString(): Column[] {
        return this.columns.filter((c: Column) =>
            [DataType.TEXT].includes(c.type)
        )
    }

    getCountryColumns(twbIdx: number): Column[] {
        return this.getColumns(twbIdx).filter((c: Column) =>
            c.geoRole?.startsWith('[Country]')
        )
    }

    getRegionColumns(twbIdx: number): Column[] {
        return this.getColumns(twbIdx).filter((c: Column) =>
            c.geoRole?.startsWith('[State]')
        )
    }

    getCityColumns(twbIdx: number): Column[] {
        return this.getColumns(twbIdx).filter((c: Column) =>
            c.geoRole?.startsWith('[City]')
        )
    }

    getAvailableGeoModes(twbIdx: number): GeoMode[] {
        const geoModes: GeoMode[] = []
        if (this.getCountryColumns(twbIdx).length > 0) {
            geoModes.push(GeoMode.COUNTRY)
            if (this.getRegionColumns(twbIdx).length > 0) {
                geoModes.push(GeoMode.REGION)
                if (this.getCityColumns(twbIdx).length > 0) {
                    geoModes.push(GeoMode.CITY)
                }
            }
        }
        return geoModes
    }

    getHasGeo(twbIdx: number): boolean {
        return this.getAvailableGeoModes(twbIdx).length > 0
    }

    get dateColumns(): Column[] {
        return this.columns.filter((c: Column) =>
            [DataType.DATE, DataType.DATETIME].includes(c.type)
        )
    }

    getColumn(id: string): Column {
        const column = this._columnMap[id]
        if (!column) throw new Error('Column not found for id: ' + id)
        return column
    }

    setFormats(formats: Format[]): void {
        this._formats = formats
    }

    get formats(): Format[] {
        return this._formats
    }

    get mainFormats(): Format[] {
        return this.formats.filter((f: Format) => f.type === 'main')
    }

    get evoFormats(): Format[] {
        return this.formats.filter((f: Format) => f.type === 'evo')
    }

    getFormat(formatId: string) {
        for (const format of this.formats) {
            if (format.id === formatId) return format
        }
        return undefined
    }

    setFilters(filters: IFilter[]) {
        this._filters = filters
    }

    get filters(): IFilter[] {
        return this._filters
    }

    getFilters(twbIndexes?: number[]): IFilter[] {
        return this.filters.filter(
            (f) => !twbIndexes || twbIndexes.includes(f.twbIdx)
        )
    }

    get twbIndexes(): number[] {
        const twbIndexes: Set<number> = new Set()
        for (const column of this.columns) {
            twbIndexes.add(column.twbIdx)
        }
        return Array.from(twbIndexes)
    }

    setDates(dates: IDate[]): void {
        this._dates = dates
    }

    get dates(): IDate[] {
        return this._dates
    }

    setIsSubscribed(isSubscribed: boolean): void {
        this._isSubscribed = isSubscribed
    }

    get isSubscribed(): boolean {
        return this._isSubscribed
    }

    setLang(lang: Lang): void {
        this._lang = lang
    }

    get lang(): Lang {
        return this._lang
    }

    setDateAggregationLevel(dateAggregationLevel: 'month' | 'day'): void {
        this._dateAggregationLevel = dateAggregationLevel
    }

    get dateAggregationLevel() {
        return this._dateAggregationLevel
    }
}
