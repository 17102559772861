import { TraitSearch } from '@/ponychart/state'
import { Bool } from '@/ponychart/trait'

import { TraitId, Bands, BAND_TRAITS_OPTIONS } from 'ponychart'

export class BorderBehaviour {
    private _bands: Bands[] | null = null
    private _borderWidth: number | null = null
    private _bandWidth: number | null = null
    private _hasBorder: boolean | null = null

    constructor(private traitSearch: TraitSearch) {
        // ...
    }

    get bands() {
        if (this._bands === null) {
            this._bands =
                (this.traitSearch
                    .getTraitStringValue(TraitId.BANDS)
                    ?.split(';')
                    .filter((t) => !!t) as Bands[]) || BAND_TRAITS_OPTIONS[0]
        }
        return this._bands
    }

    get borderWidth() {
        if (this._borderWidth === null) {
            this._borderWidth =
                this.traitSearch.getTraitNumberValue(TraitId.BORDER) || 0
        }
        return this._borderWidth
    }

    get bandWidth() {
        if (this._bandWidth === null) {
            this._bandWidth =
                this.traitSearch.getTraitNumberValue(TraitId.BAND_WIDTH) || 0
        }
        return this._bandWidth
    }

    get hasBorder() {
        if (this._hasBorder === null) {
            this._hasBorder =
                (this.traitSearch.getTraitStringValue(
                    TraitId.CARD_HAS_BORDERS
                ) || Bool.FALSE) === Bool.TRUE
        }
        return this._hasBorder
    }

    get isStandardBorderForInnerBody() {
        return ![Bands.BOTTOM, Bands.BOTTOM_LEFT, Bands.BOTTOM_RIGHT].reduce(
            (acc: boolean, band: Bands) => acc || this.bands.includes(band),
            false
        )
    }

    get isStandardBorderForTitle() {
        return ![
            Bands.TOP,
            Bands.CENTER_HORIZONTAL,
            Bands.TOP_LEFT,
            Bands.TOP_RIGHT,
        ].reduce(
            (acc: boolean, band: Bands) => acc || this.bands.includes(band),
            false
        )
    }

    get hasFullLeftBorder() {
        return this.bands.includes(Bands.LEFT)
    }

    get hasFullRightBorder() {
        return this.bands.includes(Bands.RIGHT)
    }

    get hasTopBorder() {
        return this.bands.includes(Bands.TOP)
    }

    get hasBottomBorder() {
        return this.bands.includes(Bands.BOTTOM)
    }

    get hasTitleLeftBorder() {
        return this.bands.includes(Bands.TOP_LEFT)
    }

    get hasTitleRightBorder() {
        return this.bands.includes(Bands.TOP_RIGHT)
    }

    get hasCenterBorder() {
        return this.bands.includes(Bands.CENTER_HORIZONTAL)
    }
}
