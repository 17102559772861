<template lang="pug">
#explain-popup(
    v-if='show && isLoggedIn',
    :class='"popup" + (expand ? "" : " go-down")'
)
    .sub-button(@click='expand = !expand')
        v-icon.mt-1(color='white') mdi-menu-{{ expand ? "down" : "up" }}
    v-row
        h5.white--text.ml-3.mt-3.mb-4 {{ $t("pop.title") }}
        v-spacer
    p.white--text(v-html='$t("pop.p")')
    v-textarea.ma-0.pa-0(
        :disabled='loading',
        v-model='message',
        counter='250',
        dense,
        solo,
        :label='$t("pop.tell")'
    )
    v-btn.mt-n10(
        color='primary',
        dense,
        rounded,
        small,
        :loading='loading',
        :disabled='message.length == 0 || message.length > 250',
        @click='send'
    )
        v-icon(small='') send
        span {{ $t("profile_page.send") }}
</template>

<script>
import MailService from '@/services/mailService'

export default {
    name: 'contactPopup',
    data: () => ({
        expand: false,
        message: '',
        loading: false,
        show: true,
    }),
    methods: {
        async send() {
            this.loading = true
            try {
                await MailService.suggest(this.message)
                this.show = false
                this.showToast({ code: 'THANKS', type: 'info' })
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
    },
}
</script>

<style scoped>
.popup {
    padding: 20px;
    width: 380px;
    height: 330px;
    text-align: center;
    background: #78909c;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 10px 10px 10px #999;
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 100;
    -webkit-transition: -webkit-transform 0.5s ease-in; /* Changed here */
    -moz-transition: -moz-transform 0.5s ease-in;
    -o-transition: -o-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
@media (max-width: 400px) {
    .popup {
        width: 100%;
        right: 0;
        left: 0;
        height: auto;
    }
}
.sub-button {
    position: absolute;
    width: 40px;
    height: 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    top: -30px;
    right: 0px;
    cursor: pointer;
    background: #78909c;
    /* box-shadow: 0 0 8px 0px #999; */
    box-shadow: 10px 0px 10px #999;
}
.go-down {
    -webkit-transform: translateY(340px);
    -moz-transform: translateY(340px);
    -ms-transform: translateY(340px);
    -o-transform: translateY(340px);
    transform: translateY(340px);
    -webkit-transition: -webkit-transform 0.5s ease-in; /* Changed here */
    -moz-transition: -moz-transform 0.5s ease-in;
    -o-transition: -o-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
}
.popup h2 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #2c3e52;
}
.popup p {
    font-size: 0.8em;
    color: #2c3e52;
    text-align: left;
    line-height: 140%;
}
.popup:before {
    content: '';
    width: 100%;
    height: 10px;
    background: -webkit-repeating-linear-gradient(
        45deg,
        #f9432d 5%,
        #f9432d 10%,
        #fff 10%,
        #fff 15%,
        #576c87 15%,
        #576c87 20%,
        #fff 20%,
        #fff 25%
    );
    background: repeating-linear-gradient(
        45deg,
        #f9432d 5%,
        #f9432d 10%,
        #fff 10%,
        #fff 15%,
        #576c87 15%,
        #576c87 20%,
        #fff 20%,
        #fff 25%
    );
    border-top-left-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 10px 0px 10px #999;
}
.popup:after {
    content: '';
    width: 100%;
    height: 10px;
    background: -webkit-repeating-linear-gradient(
        45deg,
        #f9432d 5%,
        #f9432d 10%,
        #fff 10%,
        #fff 15%,
        #576c87 15%,
        #576c87 20%,
        #fff 20%,
        #fff 25%
    );
    background: repeating-linear-gradient(
        45deg,
        #f9432d 5%,
        #f9432d 10%,
        #fff 10%,
        #fff 15%,
        #576c87 15%,
        #576c87 20%,
        #fff 20%,
        #fff 25%
    );
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 10px 0px 10px #999;
}
.popup > h5 {
    text-align: left;
}
</style>