import axios from 'axios'

export default {
    getLogo: async function (
        logoId: string,
        opts: { withUrl?: boolean } = {}
    ): Promise<any> {
        const {
            data: { logo },
        } = await axios(
            `/v1/logos/${logoId}`,
            { params: opts }
        )
        return logo
    },
    deleteLogo: async function (logoId: string) {
        await axios.delete(`/v1/logos/${logoId}`)
    },
    updateOrder: async function (ids: { logoId: string | number, order: number }[]): Promise<void> {
        await axios.post("/v1/logos-order", { ids })
    },
    getLogoUploadUrl: async function (uuid: string): Promise<string> {
        const {
            data: { url },
        } = await axios(`/v1/logos/${uuid}/url`)
        return url
    },
    listLogos: async function (opts: { withUrl?: boolean } = {}): Promise<any[]> {
        const { data: { logos } } = await axios('/v1/logos', { params: opts })
        return logos
    }
}
