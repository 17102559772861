import Vue from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import './registerServiceWorker'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

import interceptorsSetup from './interceptor'
import attachCognitoModule from './store/cognito'

import VueGtag from 'vue-gtag'
import IdleVue from 'idle-vue'
import VueTour from 'vue-tour'
import Toast from 'vue-toastification'

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-toastification/dist/index.css'
import 'vue-tour/dist/vue-tour.css'

import { GlobalMixins } from '@/mixins'

import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'

import VueStatic from 'vue-static'
import createTooltip from './script'

createTooltip()

sync(store, router)

const env = process.env.VUE_APP_ENV?.trim()
const isDev = env !== 'prod'

const config = {
    userPoolId: isDev ? 'eu-west-3_8WwiY8AiT' : 'eu-west-3_jcGbOt8I9',
    identityPoolId: 'arn:aws:cognito-idp:eu-west-3:110918738852',
    userPoolWebClientId: isDev
        ? '5pvadnu3h67qj9sv26nsrku6sa'
        : '4jcl39k0n9i359m8ifcupcrhua',
    region: 'eu-west-3',
    oauth: {
        domain: 'datapony-dev.auth.eu-west-3.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: isDev
            ? 'http://localhost:8080'
            : 'https://ponychart.com/app',
        redirectSignOut: isDev
            ? 'http://localhost:8080/en'
            : 'https://ponychart.com/app/en',
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
}

interceptorsSetup()

if (!isDev)
    Sentry.init({
        Vue,
        dsn: 'https://f327e515119d448dbc85b68a436b1709@o1375973.ingest.sentry.io/6684834',
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', 'ponychart.com', /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })

Vue.config.productionTip = false

attachCognitoModule(store, config, 'cognito')

Vue.use(Toast, {
    maxToasts: 20,
    newestOnTop: true,
    position: 'top-right',
})
Vue.use(VueStatic)
Vue.use(VueGtag, {
    config: { id: 'UA-119991356-1' },
})
Vue.use(IdleVue, { store, idleTime: 3600000, startAtIdle: false }) // 1 hour
Vue.use(VueTour)
Vue.mixin(GlobalMixins)

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
