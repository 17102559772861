
import GradSquare from '@/components/utils/GradSquare.vue'
import ShowMoreBtn from '@/components/utils/ShowMoreBtn.vue'
import MeasureColorBtn from '@/components/Measures/MeasureColorBtn.vue'
import TwbDatasourceSelect from '@/components/utils/TwbDatasourceSelect.vue'

import { GlobalMixins } from '@/mixins'
import {
    Column,
    ComplexAggregation,
    DataType,
    FilterOperator,
    Measure,
    MeasureAggregation,
    MeasureHelpers,
    MeasureFilterHelpers,
    MeasurePopupType,
    MeasurePopupMode,
    MeasureFilter,
    SourceMemory,
    ColumnHelpers,
} from '@/ponychart'

import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

const memoryInstance = SourceMemory.getInstance()

@Component({
    components: {
        GradSquare,
        ShowMoreBtn,
        MeasureColorBtn,
        TwbDatasourceSelect,
    },
})
export default class MeasurePopupComponent extends mixins(GlobalMixins) {
    @Prop({ type: Boolean, required: true })
    readonly show!: boolean
    @Prop({ type: Object, required: true })
    readonly measure!: Measure
    @Prop({ type: Array, required: true })
    readonly measures!: Measure[]
    @Prop({ type: String, required: true })
    readonly measurePopupMode!: MeasurePopupMode
    @Prop({ type: Object, required: true })
    readonly measureNames!: { [k: string]: string }
    @Prop({ type: String, required: true })
    readonly verb!: string
    @Prop({ type: Array, required: true })
    readonly twbDatasources!: { id: string; alias: string; color: string }[]
    @Prop({ type: Array, required: true })
    readonly selectedTwbIndexes!: number[]

    modes = [MeasurePopupType.SIMPLE, MeasurePopupType.COMPLEX]
    mode: MeasurePopupType = MeasurePopupType.NOT_SET
    filterOperators = Object.values(FilterOperator)
    booleans = ['True', 'False']
    tmt: { filter?: number; alias?: number; columnId?: number } = {
        filter: undefined,
        alias: undefined,
        columnId: undefined,
    }
    aggregations: (MeasureAggregation | ComplexAggregation)[] = []
    timeout = 200
    initialAlias = ''
    simpleDescription = ''
    complexDescription = ''
    disabledMeasure = false
    showPageSettings = false

    get mainFormats() {
        return memoryInstance.mainFormats
    }
    get andOr() {
        return this.$t(this.measure?.filterOperation?.toLowerCase() || 'and')
    }
    get twbIdxMeasures() {
        return this.measures.filter((m) => m.twbIdx === this.measure.twbIdx)
    }
    get selectedCount() {
        return this.twbIdxMeasures.filter((m) => !m.id1 && !m.id2).length
    }
    get otherMeasures() {
        return this.twbIdxMeasures.filter((m) => m.id !== this.measure?.id)
    }
    get measureCount() {
        return this.otherMeasures.reduce(
            (acc: { [k: string]: number }, m: Measure) => ({
                ...acc,
                [m.alias]: (acc[m.alias] || 0) + 1,
            }),
            {}
        )
    }
    get columns() {
        return memoryInstance.columns
    }
    get columnsForFilters() {
        return ColumnHelpers.columnsForFilters(this.columns).filter(
            (c) => c.twbIdx === this.measure.twbIdx
        )
    }
    get columnsForString() {
        return ColumnHelpers.columnsForString(this.columns).filter(
            (c) => c.twbIdx === this.measure.twbIdx
        )
    }

    get complexCombinations() {
        const x =
            this.measure?.id1 && this.measureNames?.[this.measure?.id1]
                ? this.measureNames[this.measure.id1]
                : '?'
        const y =
            this.measure?.id2 && this.measureNames?.[this.measure?.id2]
                ? this.measureNames[this.measure.id2]
                : '?'
        return Object.values(ComplexAggregation).map((value) => ({
            value,
            text: this.$t('aggregations.' + value, { x, y }),
        }))
    }

    get isAddMode() {
        return this.measurePopupMode === MeasurePopupMode.ADD
    }
    get isEditMode() {
        return this.measurePopupMode === MeasurePopupMode.EDIT
    }
    get hasOtherOperator() {
        return (
            this.measure.agg && this.measure.agg !== ComplexAggregation.INVERSE
        )
    }
    get error() {
        if (!this.measure.pristine && !this.measure.alias) {
            return this.measure.columnId + this.$t('errors.empty_col_name')
        }
        if (
            this.measureCount[this.measure.alias] &&
            (this.initialAlias !== this.measure.alias || this.isAddMode)
        ) {
            return this.measure.alias + this.$t('errors.duplicate_col_name')
        }

        if (
            this.measure.alias &&
            this.measure.alias.length >= 2 &&
            MeasureHelpers.forbiddenPrefixes.has(
                this.measure.alias.substring(0, 2)
            )
        ) {
            return this.$t('errors.prefix', {
                x: this.measure.alias.substring(0, 2),
            })
        }

        if (
            this.measure.alias &&
            MeasureHelpers.forbiddenAliases.has(this.measure.alias)
        ) {
            return String(this.$t('errors.bad_name', { x: this.measure.alias }))
        }
        return null
    }
    get title() {
        if (this.mode !== MeasurePopupType.NOT_SET) {
            const output = [
                this.$t(this.verb),
                this.$t('creation.small_measure'),
            ]
            return output.join(' ')
        }
        return this.$t('popup_title')
    }
    get stepIdx() {
        const maps = {
            [MeasurePopupType.SIMPLE]: 1,
            [MeasurePopupType.COMPLEX]: 3,
        }
        return this.mode !== MeasurePopupType.NOT_SET && maps[this.mode]
            ? maps[this.mode]
            : 2
    }

    get aggregationItems() {
        return MeasureHelpers.aggregationsFromType(this.measure.type).map(
            (value) => ({
                value,
                text: `${this.$t(`aggregations.${value}`)} (${value})`,
            })
        )
    }

    get complexModeError() {
        if (this.mode !== MeasurePopupType.COMPLEX) return false
        if (
            !this.measure?.id1 ||
            !Object.values(ComplexAggregation).includes(
                this.measure?.agg as ComplexAggregation
            )
        )
            return true

        return this.hasOtherOperator && !this.measure?.id2
    }

    get addDisabled() {
        return !!this.error || !this.measure.alias || this.complexModeError
    }

    onDatasourceChange(twbIdx?: number) {
        // When user changes the datasource, rebuild the stepIdx
        this.measure.twbIdx = twbIdx === undefined ? this.selectedTwbIndexes[0] : twbIdx
        this.makeExample(this.columns)
        if (this.selectedCount > 1 && this.measure?.columnId) {
            this.mode =
                this.measure?.agg && !this.measure.id1
                    ? MeasurePopupType.SIMPLE
                    : MeasurePopupType.COMPLEX
            this.initialAlias = this.measure.alias
        } else {
            this.mode =
                this.selectedCount > 1
                    ? MeasurePopupType.NOT_SET
                    : MeasurePopupType.SIMPLE
            this.initialAlias = ''
        }
    }

    toggleReversed(m: Measure) {
        m.reversed = !m.reversed
    }
    disabledMeasureHook() {
        this.disabledMeasure = MeasureFilterHelpers.disabledMeasure(
            this.measure
        )
    }
    deleteMeasureFilter(i: number) {
        this.measure.filters.splice(i, 1)
        this.disabledMeasureHook()
    }
    isNumber(columnId?: string) {
        if (!columnId) return false
        const column = memoryInstance.getColumn(columnId)
        return [DataType.DOUBLE, DataType.INTEGER].includes(column.type)
    }
    isBool(columnId?: string) {
        if (!columnId) return false
        const column = memoryInstance.getColumn(columnId)
        return DataType.BOOLEAN === column.type
    }
    setFormatIfNotExists() {
        if (!this.measure.formatId && memoryInstance.formats.length) {
            this.measure.formatId = memoryInstance.formats[0].id || ''
        }
    }
    makeExample(
        columns: Column[],
        opts: { id?: number; oldColumnAlias?: string; oldAgg?: string } = {
            id: 0,
            oldColumnAlias: undefined,
            oldAgg: undefined,
        }
    ) {
        const options = this.columns.filter((c) => !c.type.includes('date'))
        const l = options.length
        if (l == 0) return
        const i = Math.floor(Math.random() * l)

        const { alias, type, id } = options[i]

        const aggregations = MeasureHelpers.aggregationsFromType(type)

        const agg =
            aggregations[Math.floor(Math.random() * aggregations.length)]

        if (opts.id === 0) {
            this.simpleDescription = String(
                this.$t('simple_description', {
                    column: alias,
                    agg,
                })
            )
            this.makeExample(
                [...columns].filter((c) => c.id !== id),
                { id: 1, oldColumnAlias: alias, oldAgg: agg }
            )
        } else {
            this.complexDescription = String(
                this.$t('complex_description', {
                    column1: opts.oldColumnAlias,
                    column2: alias,
                    agg1: opts.oldAgg,
                    agg2: agg,
                })
            )
        }
    }
    // makeMeasures(i: number) {
    //     this.measureItems[i - 1] = memoryInstance.measures
    //         .filter(
    //             (m) =>
    //                 !this.measure['id' + i] || m.id !== this.measure['id' + i]
    //         )

    //         .map((measure) => ({ value: measure.id, text: measure.alias }))
    // }
    setId(value: string, i: number) {
        const keys = ['id1', 'id2']
        this.measure[keys[i - 1]] = value
        // this.makeMeasures(i)
        this.$nextTick(() => this.setComplexAlias())
        this.setFormatIfNotExists()
    }
    setMode(mode: MeasurePopupType) {
        this.mode = mode
    }
    setComplexAlias() {
        if (!this.measure.agg) this.measure.agg = ComplexAggregation.POND_AVG
        const x = this.measure.id1 ? this.measureNames[this.measure.id1] : '?'
        const y = this.measure.id2 ? this.measureNames[this.measure.id2] : '?'
        this.measure.columnId = ''
        if (this.measure.pristine) {
            this.measure.alias = String(
                this.$t('aggregations.' + this.measure.agg, { x, y })
            )
        }
        this.setFormatIfNotExists()
    }
    addOrEditMeasure() {
        this.$emit(this.measurePopupMode, this.measure)
        this.$emit('close')
    }
    hasOther(operator: FilterOperator) {
        return MeasureFilterHelpers.hasOther(operator)
    }
    setColumnId(columnId: string) {
        const { type, alias } = this.columns.find((c) => c.id == columnId) || {}
        if (!type) return
        const aggregations = MeasureHelpers.aggregationsFromType(type)
        clearTimeout(this.tmt.columnId)
        this.tmt.columnId = setTimeout(
            () => {
                this.aggregations = aggregations
                if (!this.aggregations.includes(this.measure.agg)) {
                    this.measure.agg = this.aggregations[0]
                }
                if (this.measure.pristine) {
                    this.measure.alias = alias || ''
                }
                this.measure.columnId = columnId
                this.measure.type = type
                this.setFormatIfNotExists()
            },
            this.timeout,
            this
        )
    }
    setAlias(alias: string) {
        clearTimeout(this.tmt.alias)
        this.tmt.alias = setTimeout(
            () => {
                this.measure.pristine = false
                this.measure.alias = alias
                this.setFormatIfNotExists()
            },
            this.timeout,
            this
        )
    }
    addFilter() {
        this.measure.filters.push(MeasureFilterHelpers.new())
        this.disabledMeasureHook()
    }
    listFiltersOf(filter: MeasureFilter) {
        if (!filter.columnId) return []
        const column = memoryInstance.getColumn(filter.columnId)
        let other = filter.value
        if (this.isNumber(column.id) && other && isNaN(+other)) {
            filter.value = undefined
            other = undefined
        }

        if (!ColumnHelpers.allowedForFilters.includes(column.type)) return []

        return Object.values(FilterOperator)
            .filter(
                (value) =>
                    !value.includes('CONTAINS') || column.type == DataType.TEXT
            )
            .filter(
                (value) =>
                    column.type !== DataType.BOOLEAN ||
                    value === FilterOperator.EQUALS
            )
            .map((value) => ({
                value,
                text: this.$t('aggregations.' + value, {
                    x: column.alias,
                    y: other || '?',
                }),
            }))
    }

    // @Watch('mode')
    // onModeChange(mode: MeasurePopupType) {
    //     if (mode === MeasurePopupType.COMPLEX) {
    //         this.makeMeasures(1)
    //         this.makeMeasures(2)
    //     }
    // }
    // @Watch('columns')
    // onColumnsChange() {
    //     this.makeMeasures(1)
    //     this.makeMeasures(2)
    // }
    @Watch('show', { immediate: true })
    onShowChanged(show: boolean) {
        if (show) {
            this.onDatasourceChange()
        } else {
            // If popup disappears, we scan filters to remove those which are incorrect
            this.measure.filters = this.measure.filters.filter(
                (f) => !MeasureFilterHelpers.disabledFilter(f)
            )
        }
    }
}
