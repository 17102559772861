import { TraitId, QuerySelectorTag, CHART_TYPE_TRAITS } from 'ponychart'

import { ParameterTraitId, PARAMETER_TRAIT_IDS, SELECTORS_WITH_PARAMETER_MAP } from "../element/types"
import { PonychartElement, querySelectorClass } from "@/ponychart/element"
import { nanoid } from "@/ponychart/utils"
import { cloneDeep } from "lodash"
import { mainTag } from '../trait'

// type ParameterOutput = { value: DynamicParameter, type: "ParameterType" } | { value: string, type: "Text" }

interface PageBlockLocation {
    querySelectorTags: Set<QuerySelectorTag>
    pageBlockId: string
}

export interface PageLocation {
    pageId: number
    pageBlockId: string
    querySelectorTags: QuerySelectorTag[]
}

export interface ParameterInterface {
    id: string
    ids: string[]
    type: ParameterTraitId
    pageLocations: PageLocation[]
    suffix: string
    twbIdx: number
    // classes: string[]
}


export class DynamicParameter {
    static PARAMETER_TYPES: ParameterTraitId[] = PARAMETER_TRAIT_IDS
    static PARAMETER_DELIMITER = ":"
    static PARAMETER_ATTRIBUTES_DELIMITER = '|'
    static PARAMETER_ID_DELIMITER = '-'
    public ids: string[]
    public twbIdx: number
    private _suffix = ""
    private _pageBlockIndex?: number
    private _pageId: number
    private _pageLocation: { [pageId: number]: PageBlockLocation }
    constructor(
        public type: ParameterTraitId,
        opts: {
            ids: string[],
            querySelectorTag: QuerySelectorTag,
            pageBlockId: string,
            pageId: number,
            twbIdx: number
        }
    ) {
        if (!DynamicParameter.PARAMETER_TYPES.includes(type)) throw new Error("Unexpected parameter type")
        if (opts.ids.length <= 1) throw new Error("ids should be an array bigger than 1 in length")
        this.ids = opts.ids.sort()
        this._pageLocation = {
            [opts.pageId]: {
                pageBlockId: opts.pageBlockId,
                querySelectorTags: new Set([opts.querySelectorTag])
            }
        }
        this.twbIdx = opts.twbIdx
        this._pageId = opts.pageId
    }

    static sort(a: DynamicParameter, b: DynamicParameter){
        return b.type.localeCompare(a.type)
    }

    get pageLocation() {
        return this._pageLocation
    }

    get suffix(): string {
        return this._suffix
    }

    get querySelectorTags(): Set<QuerySelectorTag> {
        return this._pageLocation[this.pageId].querySelectorTags
    }

    get pageIds() {
        return Object.keys(this._pageLocation).map(pageId => Number(pageId))
    }

    get pageBlockIndex() {
        return this._pageBlockIndex
    }

    get pageId() {
        return this._pageId
    }

    get pageBlockId() {
        return this._pageLocation[this.pageId].pageBlockId
    }

    get classes(): string[] {
        if (!this.pageBlockId) return []
        return [...this.querySelectorTags].map(t => (querySelectorClass(this.pageBlockId, [t])))
    }

    // Not used
    fromParameterInterface(parameterInterface: ParameterInterface): DynamicParameter[] {
        const output: DynamicParameter[] = []
        const { type, pageLocations, ids, twbIdx } = parameterInterface
        for (const { querySelectorTags, pageId, pageBlockId } of pageLocations) {
            for (const querySelectorTag of querySelectorTags) {
                output.push(new DynamicParameter(type, { querySelectorTag, ids, pageBlockId, pageId, twbIdx }))
            }
        }
        return output
    }

    toParameterInterface(): ParameterInterface {
        return {
            id: nanoid.id(),
            ids: Array.from(this.ids),
            pageLocations: Object.entries(this._pageLocation).map(([pageId, pageBlockLocation]) => ({
                pageId: Number(pageId),
                querySelectorTags: [...pageBlockLocation.querySelectorTags],
                pageBlockId: pageBlockLocation.pageBlockId
            })),
            suffix: this.suffix,
            type: this.type,
            twbIdx: this.twbIdx
            // classes: this.classes
        }
    }

    setSuffix(suffix: string) {
        this._suffix = suffix
    }

    join(other: DynamicParameter) {
        if (!this.eq(other)) {
            return
        }
        for (const otherPageId in other.pageLocation) {
            if (this.pageLocation[otherPageId]) {
                for (const querySelectorTag of other.pageLocation[otherPageId].querySelectorTags) {
                    this.pageLocation[otherPageId].querySelectorTags.add(Number(querySelectorTag) as QuerySelectorTag)
                }
            } else {
                this.pageLocation[otherPageId] = cloneDeep(other.pageLocation[otherPageId])
            }

        }
        // this._querySelectorTags = new Set([...this._querySelectorTags, ...other.querySelectorTags])
        if (!this.suffix) this.setSuffix(other.suffix)
    }

    eq(other: DynamicParameter): boolean {
        if (this.type !== other.type || this.ids.join() !== other.ids.join()) return false

        if (this.twbIdx !== other.twbIdx) return false

        for (const pageId in this.pageLocation) {
            if (
                other.pageLocation[pageId]?.pageBlockId &&
                other.pageLocation[pageId].pageBlockId !== this.pageLocation[pageId].pageBlockId
            ) return false
        }

        return true
    }

    repr(): string {
        return [
            this.type,
            this.ids.join(DynamicParameter.PARAMETER_ID_DELIMITER)
        ].join(DynamicParameter.PARAMETER_ATTRIBUTES_DELIMITER)
    }

    static fromElement(ponychartElement: PonychartElement): DynamicParameter | undefined {
        if (ponychartElement.componentType in SELECTORS_WITH_PARAMETER_MAP) {
            return (ponychartElement as any).parameter
        }
    }

    static listFromElement(ponychartElement: PonychartElement): DynamicParameter[] {
        const parameters: DynamicParameter[] = []
        const parameter = DynamicParameter.fromElement(ponychartElement)
        if (parameter) parameters.push(parameter)
        for (const child of ponychartElement.children) {
            for (const parameter of DynamicParameter.listFromElement(child)) {
                parameters.push(parameter)
            }
        }
        return parameters
    }

    isSameContextAs(element: PonychartElement){
        if(this.querySelectorTags.size !== 1) return false 
        if (element.localState.mainQuerySelectorTag !== this.querySelectorTags.values().next().value) return false
        if(element.globalState.pageId != this.pageId) return false // NB: it seems strict equality does not work because of type string vs number issue
        if (element.globalState.pageBlockId !== this.pageBlockId) return false
        return true
    }

    isIncludedIn(parameters: DynamicParameter[]) {
        for (const c of parameters) {
            if (c.eq(this)) return true
        }
        return false
    }

    setPageBlockIndex(pageBlockIndex: number) {
        this._pageBlockIndex = pageBlockIndex
    }

}
