
import { Pages, GlobalMixins } from '@/mixins'

import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import Page from '@/components/Pages/Page.vue'
import TwbDatasourceSelect from '@/components/utils/TwbDatasourceSelect.vue'
import PageTrait from '@/components/Pages/PageTrait.vue'

import {
    StructureType,
    RegisterTour,
    Page as _Page,
    PageBlock as _PageBlock,
    PageStructure,
    listPageStructures,
    pageCharts,
    PageStructureFactory,
    SourceMemory,
    SidebarPayload,
    GlobalOptions,
    GlobalState,
    SourceMode,
} from '@/ponychart'
import { TraitId, Trait, MAX_QUERY_SELECTOR_TAG } from 'ponychart'
import { SimpleTraitSearch, TraitSearch } from '@/ponychart/state/traits'

const registerTour = RegisterTour.getInstance()

type Pagination = {
    startAt: number
    limit: number
    page: number
    next: null | number
    length: null | number
}

const LOCAL_STORAGE_KEY = 'pageSelectorDescription'

function resetPagination(limit = 5): Pagination {
    return {
        startAt: 0,
        limit,
        page: 1,
        next: null,
        length: null,
    }
}
const memoryInstance = SourceMemory.getInstance()

@Component({
    components: {
        Page,
        PageTrait,
        TwbDatasourceSelect,
    },
})
export default class PageSelector extends mixins(GlobalMixins, Pages) {
    @Prop({ type: Object, required: true })
    readonly globalOptions!: GlobalOptions
    @Prop({ type: Array, required: true })
    readonly sourceTraits!: Trait[]
    @Prop({ type: Number, required: false, default: 7 })
    readonly totalVisible!: number
    @Prop({ type: Boolean, required: false, default: false })
    readonly loading!: boolean
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: Boolean, required: false, default: false })
    readonly show!: boolean
    @Prop({ type: Array, required: true })
    readonly twbDatasources!: { id: string; alias: string; color: string }[]
    @Prop({ type: String, required: true })
    readonly sourceMode!: SourceMode

    size = 200
    wishedStructureType = StructureType.UNIQUE
    twbIdx = 0
    selectedMeasureIds: { [k: number]: string[] } = {}
    showDescription = true
    structures: _Page[] = []
    ghostStructureIds: (string | number)[] = []
    wishedChartCount = 0
    structureIndex = -1
    pagination: Pagination = resetPagination()
    traitSearch: TraitSearch | null = null
    globalState: GlobalState | null = null

    get measures() {
        const measures = memoryInstance.measures.filter(
            (m) => m.twbIdx === this.twbIdx
        )
        const selectedMeasureIds = this.selectedMeasureIds[this.twbIdx] || []
        if (selectedMeasureIds.length <= 1) {
            return measures.map((m) => ({
                ...m,
                disabled: selectedMeasureIds.includes(m.id),
            }))
        }
        return measures
    }
    get wishedStructureTypes() {
        return [this.wishedStructureType]
    }

    get measureSeeded() {
        return (
            Object.values(this.selectedMeasureIds).filter((m) => m.length > 0)
                .length === this.twbDatasources.length
        )
    }

    get sidebar(): SidebarPayload {
        const traitSearch = new SimpleTraitSearch(this.sourceTraits)
        return {
            sidebar: traitSearch.getTraitStringValue(TraitId.SIDEBAR, {querySelectorTag: 0}) as any,
            index: 0,
        }
    }

    get structureTypeItems() {
        return [
            // StructureType.ALL,
            StructureType.REPETITIVE,
            StructureType.UNIQUE,
        ].map((value) => ({ text: this.$t(`structureType.${value}`), value }))
    }

    get wishedChartCountItems() {
        const output: { value: number; text: string }[] = [
            { value: 0, text: String(this.$t('anyChart')) },
        ]
        for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++)
            output.push({ value: i, text: this.$tc('charts', i, { i }) })
        return output
    }

    get chartCount() {
        const factory = new PageStructureFactory()
        if (!this.structure) return 0
        if(!this.globalState) this.globalState = new GlobalState(this.globalOptions)
        this.globalState.setTwbIdx(this.twbIdx).setPageStructure(this.structure)
        if(!this.traitSearch) this.traitSearch = TraitSearch.createInstance(this.sourceTraits, [], this.globalState)
        this.traitSearch.syncWith(this.globalState)
        const structureTemplate = factory.buildStructure(
            this.globalState,
            this.traitSearch
        )
        return structureTemplate.chartCount
    }

    get structure(): PageStructure | null {
        return this.structureIndex >= 0 &&
            this.structureIndex < this.structures.length
            ? (this.structures[this.structureIndex].pageBlocks || [])[0]
                  .structure
            : null
    }

    get pageBlock(): _PageBlock | null {
        return this.structureIndex >= 0 &&
            this.structureIndex < this.structures.length
            ? (this.structures[this.structureIndex].pageBlocks || [])[0]
            : null
    }

    get colors() {
        return this.globalOptions.colors
    }

    get computedStructures() {
        return this.structures.map((p, i) => ({
            ...p,
            id:
                this.ghostStructureIds.length > i
                    ? this.ghostStructureIds[i]
                    : p.id,
        }))
    }

    refreshAll() {
        this.resetPagination()
        this.listPageStructures({ resetId: true })
    }

    setShowDescription(showDescription: boolean) {
        this.showDescription = showDescription
        localStorage.setItem(LOCAL_STORAGE_KEY, String(showDescription))
    }
    // pageClicked(i: number) {
    //     if (this.pages?.[i]?.disabled) return
    //     this.indexes.page = i
    // }
    key(page: _Page) {
        return page.id
    }
    deleteStructure(i: number) {
        this.structures.splice(i, 1)
    }
    pageCharts(page: _Page) {
        return pageCharts(page)
    }
    // setPage(page: _Page, i: number) {
    //     this.indexes.page = i
    //     this.$emit('setPage', page)
    // }
    resetPagination() {
        this.pagination.startAt = 0
        this.pagination.next = null
        this.pagination.page = 1
        this.pagination.length = null
        // Reset index pagination
        this.structureIndex = -1
        // this.indexes.structure = -1
    }
    paginate(n: number) {
        this.pagination.next = this.pagination.limit * (n - 1)
        this.structureIndex = -1
        return this.listPageStructures()
    }

    resetStructure() {
        setTimeout(() => (this.structureIndex = -1), 500)
    }

    listPageStructures(opts: { resetId?: boolean } = {}) {
        this.seedMeasures()
        this.$emit('setPage', null)
        this.pagination.startAt = this.pagination.next || 0
        const { pages, next, length } = listPageStructures(
            {
                ...this.globalOptions,
                navigation: !!this.sidebar?.sidebar,
                twbIdx: this.twbIdx,
            },
            this.sourceTraits,
            {
            limit: this.pagination.limit,
            startAt: this.pagination.startAt,
            wishedStructureTypes: this.wishedStructureTypes,
            wishedChartCount: this.wishedChartCount,
            measure: this.selectedMeasureIds[this.twbIdx].join(';'),
        })
        if (opts.resetId) this.ghostStructureIds = pages.map((p) => p.id)
        this.structures = pages
        this.pagination.next = next
        this.pagination.length = length
    }
    mounted() {
        this.showDescription =
            !!localStorage.getItem(LOCAL_STORAGE_KEY) &&
            localStorage.getItem(LOCAL_STORAGE_KEY) === 'true'
        this.seedMeasures()
        if(this.sourceMode === SourceMode.DEMO){
            this.pagination = resetPagination(this.paginationLimit)
        }
    }
    get paginationLimit(){
        return this.sourceMode === SourceMode.DEMO ? 4 : 5
    }
    seedMeasures() {
        if (this.measureSeeded) return
        for (const i in this.twbDatasources) {
            if (this.selectedMeasureIds[i]?.length) continue
            this.selectedMeasureIds[i] = this.measures
                .filter((m) => m.twbIdx === Number(i))
                .map((m) => m.id)
        }
    }

    @Watch('paginationLimit', { immediate: true })
    onPaginationLimit(limit: number) {
        this.seedMeasures()
        this.pagination.limit = limit
    }

    startTourTimeout(step: 'first-page' | 'page-selector') {
        setTimeout(
            () => {
                registerTour.getTourContext().startTour({ step, force: false })
                console.log('start tour', step)
            },
            1000,
            this
        )
    }

    @Watch('show', { immediate: true })
    onShow(show: boolean) {
        if (!show) {
            this.clearTour()
            this.startTourTimeout('first-page')
            return
        } else {
            this.startTourTimeout('page-selector')
        }
        this.seedMeasures()
        this.structureIndex = -1
        this.pagination = resetPagination(this.paginationLimit)
        this.listPageStructures()
    }
}
