import {
    ChartType,
    CHART_TYPE_TRAITS,
    DeviceType,
    MAX_QUERY_SELECTOR_TAG,
    QuerySelectorTag,
    querySelectorTagToChartTrait,
    SOURCE_TRAIT_IDS_SET,
    TIME_AXIS_CHART_TYPES,
    Trait,
    TraitId,
} from 'ponychart'
import { AttributesToUpdate, CustomTrait, mainTag } from '@/ponychart/trait'
import { TRAIT_ID_ORDER } from '@/ponychart/trait/dependencies'
import { traitFactory } from '@/ponychart/trait/factory'
import { GlobalState } from './state'
import { applyOutsideTraitAttributes } from './utils'

interface TraitOptions {
    querySelectorTags?: QuerySelectorTag[]
    twbIdx?: number
    deviceType?: DeviceType
    chartType?: ChartType
    multipleCharts?: boolean
}

type RawGetTraitOpts = {
    querySelectorTag?: QuerySelectorTag
    deviceType?: DeviceType
    chartType?: ChartType | ''
    explicitQuerySelectorTag?: boolean
    explicitDeviceType?: boolean
    explicitChartType?: boolean
}

type GetTraitOpts = RawGetTraitOpts | number

type SimpleGetTraitOpts = Omit<
    RawGetTraitOpts,
    'explicitDeviceType' | 'explicitChartType' | 'explicitQuerySelectorTags'
>

export class SimpleTraitSearch {
    constructor(private traits: Trait[]) {}

    getTrait(traitId: TraitId, opts: SimpleGetTraitOpts = {}) {
        return this.traits.find(
            (t) =>
                t.id === traitId &&
                (opts.chartType === undefined ||
                    opts.chartType === t.chartType) &&
                (opts.deviceType === undefined ||
                    opts.deviceType === t.deviceType) &&
                (opts.querySelectorTag === undefined ||
                    opts.querySelectorTag ===
                        mainTag(t.querySelectorTags || [0]))
        )
    }

    getTraitValue(traitId: TraitId, opts: SimpleGetTraitOpts = {}) {
        return this.getTrait(traitId, opts)?.value
    }

    getTraitStringValue(traitId: TraitId, opts: SimpleGetTraitOpts = {}) {
        const value = this.getTraitValue(traitId, opts)
        if (typeof value !== 'string' && value !== undefined)
            throw new Error(`Expects a string, got ${value},`)
        return value
    }

    getTraitNumberValue(traitId: TraitId, opts: SimpleGetTraitOpts = {}) {
        const value = this.getTraitValue(traitId, opts)
        if (typeof value !== 'number' && value !== undefined)
            throw new Error(`Expects a number, got ${value}, ${typeof value}`)
        return value
    }

    getTraitStringRequiredValue(
        traitId: TraitId,
        opts: SimpleGetTraitOpts = {}
    ) {
        const value = this.getTraitStringValue(traitId, opts)
        if (value === undefined)
            throw new Error(`Expects a required string, got ${value},`)
        return value
    }

    getTraitNumberRequiredValue(
        traitId: TraitId,
        opts: SimpleGetTraitOpts = {}
    ) {
        const value = this.getTraitNumberValue(traitId, opts)
        if (value === undefined)
            throw new Error(`Expects a required Number, got ${value},`)
        return value
    }
}

export class TraitSearch {
    private _sourceTraits: CustomTrait[] = []
    private _traits: CustomTrait[] = []
    private _traitMap: Map<
        QuerySelectorTag,
        Map<DeviceType, Map<ChartType | '', Map<TraitId, CustomTrait>>>
    > = new Map()
    public deviceType: DeviceType
    public querySelectorTags: QuerySelectorTag[]
    public twbIdx: number
    public chartType?: ChartType
    public multipleCharts?: boolean
    private constructor(traitOpts: TraitOptions = {}) {
        this.deviceType = traitOpts.deviceType || DeviceType.DESKTOP
        this.querySelectorTags = traitOpts.querySelectorTags || [0]
        this.twbIdx = traitOpts.twbIdx || 0
        this.chartType = traitOpts.chartType
        this.multipleCharts = traitOpts.multipleCharts
    }

    syncWith(globalState: GlobalState) {
        this.multipleCharts = globalState.multipleCharts || false
        this.deviceType = globalState.deviceType
        this.twbIdx = globalState.twbIdx
    }

    static fromGlobalState(globalState: GlobalState) {
        const instance = new TraitSearch()
        instance.syncWith(globalState)
        return instance
    }

    static createInstance(
        sourceTraits?: Trait[],
        traits?: Trait[],
        traitOpts: TraitOptions = {}
    ) {
        const instance = new TraitSearch(traitOpts)
        instance.initTraits(sourceTraits || [], traits || [])
        return instance
    }

    toDict() {
        return {
            twbIdx: this.twbIdx,
            chartType: this.chartType,
            deviceType: this.deviceType,
            querySelectorTags: this.querySelectorTags,
        }
    }

    initTraits(
        sourceTraits: (Trait | CustomTrait)[],
        traits: (Trait | CustomTrait)[]
    ) {
        if (this._traits.length > 0) this._traits = []
        if (this._sourceTraits.length > 0) this._sourceTraits = []
        this.pushTraits(sourceTraits, { allowError: true })
        this.pushTraits(traits, { allowError: true })
    }

    get font(): string {
        return String(this.getTraitValue(TraitId.FONT, 0)) || 'Arial'
    }

    get pageMarginX(): string {
        return `${this.getTraitValue(TraitId.PAGE_MARGIN_X, 0) || '0'}px`
    }

    get dateAggregationLevel() {
        return this.getTraitValue(TraitId.SOURCE_DATE_AGGREGATION_LEVEL, {
            querySelectorTag: 0,
            deviceType: DeviceType.DESKTOP,
        })
    }

    get mainQuerySelectorTag() {
        return mainTag(this.querySelectorTags)
    }

    get traits() {
        return this._traits
    }

    get sourceTraits() {
        return this._sourceTraits
    }

    get allTraits() {
        return [...this.traits, ...this.sourceTraits]
    }

    hasDate(opts: GetTraitOpts = {}) {
        for (const CHART_TYPE_TRAIT of CHART_TYPE_TRAITS) {
            const value = this.getTraitValue(CHART_TYPE_TRAIT, opts)
            if (value === undefined) continue
            return TIME_AXIS_CHART_TYPES.has(value as ChartType)
        }
        return false
    }

    hasDimension(opts: GetTraitOpts = {}) {
        const extractedOpts = {
            ...this.extractTraitOpts(opts),
            deviceType: DeviceType.DESKTOP,
        }
        if (extractedOpts.querySelectorTag !== 0) {
            const chartTypes = this.getTraitArrayValue(
                querySelectorTagToChartTrait(extractedOpts.querySelectorTag),
                extractedOpts
            )
            for (const chartType of chartTypes) {
                if (!chartType) continue
                const dim =
                    this.getTraitArrayValue(TraitId.DIMENSION, {
                        ...extractedOpts,
                        chartType: chartType as ChartType,
                        deviceType: DeviceType.DESKTOP,
                    }) || []
                if (dim.filter((d) => d !== 'none').length) {
                    return true
                }
            }
        } else {
            for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
                const newOpts = {
                    ...extractedOpts,
                    querySelectorTag: i as QuerySelectorTag,
                }
                const chartTypes = this.getTraitArrayValue(
                    querySelectorTagToChartTrait(i),
                    newOpts
                )
                for (const chartType of chartTypes) {
                    if (!chartType) continue
                    const dim =
                        this.getTraitArrayValue(TraitId.DIMENSION, {
                            ...newOpts,
                            chartType: chartType as ChartType,
                        }) || []
                    if (dim.filter((d) => d !== 'none').length) {
                        return true
                    }
                }
            }
        }
        return false
    }

    private get rawTraitMap(): Map<ChartType | '', Map<TraitId, CustomTrait>> {
        return (
            this._traitMap
                ?.get(this.mainQuerySelectorTag)
                ?.get(this.deviceType) || new Map()
        )
    }

    private get rawDesktopTraitMap(): Map<
        ChartType | '',
        Map<TraitId, CustomTrait>
    > {
        return (
            this._traitMap
                ?.get(this.mainQuerySelectorTag)
                ?.get(DeviceType.DESKTOP) || new Map()
        )
    }

    getAttributeMap(
        chartType: ChartType,
        deviceType: DeviceType,
        querySelectorTag: QuerySelectorTag
    ): { [k: string]: string | number } {
        let output: { [k: string]: string | number } = {}
        const chartTypes: ('' | ChartType)[] = ['', chartType]
        for (const ct of chartTypes) {
            const querySelectorTags: QuerySelectorTag[] = [0, querySelectorTag]
            for (const q of querySelectorTags) {
                const deviceTypes =
                    deviceType === DeviceType.DESKTOP
                        ? [deviceType]
                        : [DeviceType.DESKTOP, deviceType]
                for (const d of deviceTypes) {
                    output = [
                        ...(this._traitMap
                            ?.get(q)
                            ?.get(d)
                            ?.get(ct)
                            ?.entries() || []),
                    ].reduce(
                        (
                            acc: { [k: string]: string | number },
                            entry: any
                        ) => ({
                            ...acc,
                            [entry[0]]: entry[1].value,
                        }),
                        output
                    )
                }
            }
        }
        return output
    }

    private traitMapToAttributes(
        traitMap: Map<ChartType | '', Map<TraitId, CustomTrait>>
    ) {
        const output: { [k: string]: string } = {}
        for (const [chartType, lowLevelTraitMap] of traitMap) {
            for (const [key, value] of lowLevelTraitMap) {
                output[`${key}|${chartType}`] = String(value.value)
            }
        }
        return output
    }

    get traitMap() {
        const desktopTraitMap = this.traitMapToAttributes(
            this.rawDesktopTraitMap
        )
        if (this.deviceType === DeviceType.DESKTOP) return desktopTraitMap
        return {
            ...desktopTraitMap,
            ...this.traitMapToAttributes(this.rawTraitMap),
        }
    }

    get pristineMap() {
        return Object.entries(this.rawTraitMap).reduce(
            (acc: { [k: string]: any }, entries: any) => ({
                ...acc,
                [entries[0]]: entries[1]?.pristine,
            }),
            {}
        )
    }

    removeUntouchedTraits() {
        this._traitMap = new Map()
        const traits = [...this._traits]
        this._traits = []
        for (const trait of traits) {
            if (!trait.touched) {
                continue
            }
            this._traits.push(trait)
            this.onSingleTraitChange(trait)
        }
        for (const trait of this.sourceTraits) {
            this.onSingleTraitChange(trait)
        }
    }

    removeSingleTrait(trait: CustomTrait) {
        for (const i in this._traits) {
            const t = this._traits[i]
            if (
                t.id === trait.id &&
                t.deviceType === t.deviceType &&
                t.mainQuerySelectorTag === trait.mainQuerySelectorTag &&
                t.chartType === trait.chartType
            ) {
                this._traits.splice(Number(i), 1)
                return
            }
        }
    }

    onSingleTraitChange(trait: CustomTrait) {
        const traitId = trait.id
        const deviceType = trait.deviceType || DeviceType.DESKTOP
        const chartType = trait.chartType || ''
        const querySelectorTag = Math.max(
            ...(trait.querySelectorTags || [0])
        ) as QuerySelectorTag
        if (!this._traitMap.has(querySelectorTag))
            this._traitMap.set(querySelectorTag, new Map())
        if (!this._traitMap.get(querySelectorTag)?.has(deviceType))
            this._traitMap.get(querySelectorTag)?.set(deviceType, new Map())
        if (
            !this._traitMap
                .get(querySelectorTag)
                ?.get(deviceType)
                ?.has(chartType)
        )
            this._traitMap
                .get(querySelectorTag)
                ?.get(deviceType)
                ?.set(chartType, new Map())
        this._traitMap
            .get(querySelectorTag)
            ?.get(deviceType)
            ?.get(chartType)
            ?.set(traitId, trait)
    }

    tagAllTraitsAsUntouched() {
        for (const trait of this.traits) trait.tagAsTouched(false)
    }

    printTraitMap() {
        const output = {}
        for (const [key, value] of this._traitMap) {
            output[key] = {}
            for (const [key2, value2] of value) {
                output[key][key2] = {}
                for (const [key3, value3] of value2) {
                    output[key][key2][key3] = {}
                    for (const [key4, value4] of value3) {
                        this._traitMap.get(key)?.get(key2)?.get(key3)?.get(key4)
                            ?.id
                    }
                }
            }
        }
        return JSON.stringify(output)
    }

    private getSpecificTrait(
        traitId: TraitId,
        querySelectorTag: QuerySelectorTag,
        deviceType: DeviceType,
        chartType?: ChartType
    ) {
        return this._traitMap
            ?.get(querySelectorTag)
            ?.get(deviceType)
            ?.get(chartType || '')
            ?.get(traitId)
    }

    getTrait(
        traitId: TraitId,
        opts: GetTraitOpts = {}
    ): CustomTrait | undefined {
        const {
            querySelectorTag,
            deviceType,
            chartType,
            explicitQuerySelectorTag,
            explicitDeviceType,
            explicitChartType,
        } = this.extractTraitOpts(opts)
        let trait = this.getSpecificTrait(
            traitId,
            querySelectorTag,
            deviceType,
            chartType
        )
        if (trait !== undefined) return trait
        if (!explicitDeviceType && deviceType !== DeviceType.DESKTOP) {
            trait = this.getSpecificTrait(
                traitId,
                querySelectorTag,
                DeviceType.DESKTOP,
                chartType
            )
        }
        if (
            !explicitQuerySelectorTag &&
            trait === undefined &&
            querySelectorTag !== 0 &&
            this.mainQuerySelectorTag !== 0
        ) {
            trait = this.getTrait(
                traitId,
                typeof opts === 'number' ? 0 : { ...opts, querySelectorTag: 0 }
            )
        }
        if (!explicitChartType && trait === undefined && chartType?.length) {
            trait = this.getTrait(
                traitId,
                typeof opts === 'number'
                    ? { chartType: '', querySelectorTag }
                    : { ...opts, chartType: '' }
            )
        }
        return trait
    }

    getTraitValue(
        traitId: TraitId,
        opts: GetTraitOpts = {}
    ): string | number | undefined {
        let value = this.getTrait(traitId, this.extractTraitOpts(opts))?.value
        if (value === 'null') value = undefined
        return value
    }

    getTraitRequired(traitId: TraitId, opts: GetTraitOpts = {}) {
        const trait = this.getTrait(traitId, opts)
        if (!trait)
            throw new Error(
                this.throwGetTraitError(traitId, 'should exist', opts)
            )
        return trait
    }

    getTraitRequiredValue(traitId: TraitId, opts: GetTraitOpts = {}) {
        const value = this.getTraitValue(traitId, opts)
        if (value === undefined)
            throw new Error(
                this.throwGetTraitError(
                    traitId,
                    'should not be an undefined string | number',
                    opts
                )
            )
        return value
    }

    private extractTraitOpts(opts: GetTraitOpts = {}) {
        const querySelectorTag =
            typeof opts === 'number'
                ? opts
                : opts.querySelectorTag !== undefined
                ? opts.querySelectorTag
                : this.mainQuerySelectorTag
        const deviceType =
            typeof opts === 'number'
                ? this.deviceType
                : opts.deviceType || this.deviceType
        const chartType =
            typeof opts === 'number'
                ? this.chartType
                : opts.chartType === undefined
                ? this.chartType
                : opts.chartType || ''
        const explicitQuerySelectorTag =
            typeof opts === 'number' ? false : !!opts.explicitQuerySelectorTag
        const explicitDeviceType =
            typeof opts === 'number' ? false : !!opts.explicitDeviceType
        const explicitChartType =
            typeof opts === 'number' ? false : !!opts.explicitChartType
        return {
            querySelectorTag,
            deviceType,
            chartType,
            explicitQuerySelectorTag,
            explicitDeviceType,
            explicitChartType,
        } as {
            querySelectorTag: QuerySelectorTag
            deviceType: DeviceType
            chartType: ChartType
            explicitQuerySelectorTag: boolean
            explicitDeviceType: boolean
            explicitChartType: boolean
        }
    }

    private throwGetTraitError(traitId: TraitId, message: string, opts: any) {
        const mt = this.t(this.traits)
        console.log(JSON.stringify(mt, null, 2))
        const output = `${traitId} ${message}.
    opts: ${JSON.stringify(opts)}
    mainQuerySelectorTag: ${this.mainQuerySelectorTag}
    mainDeviceType: ${this.deviceType}
    mainChartType: ${this.chartType}
    Trait count: ${this.traits.length}
    Source Traits count: ${this.sourceTraits.length}`
        console.error(output)
        return output
    }

    t(traits: Trait[], defaultValue: any = {}, traitId?: TraitId) {
        return traits
            .filter((t) => !traitId || t.id === traitId)
            .sort((a, b) => a.id.localeCompare(b.id))
            .reduce(
                (acc, t) => ({
                    ...acc,
                    [`${t.id}|${mainTag(t.querySelectorTags || [0])}|${
                        t.deviceType || ''
                    }|${t.chartType || ''}`]: t.value,
                }),
                defaultValue
            )
    }

    logTouchedTraits(msg: string) {
        if (this.twbIdx != 0) return
        const mt = this.t(this.traits.filter((t) => t.touched))
        console.log(msg, JSON.stringify(mt, null, 2))
    }

    getTraitStringValue(traitId: TraitId, opts: GetTraitOpts = {}) {
        const value = this.getTraitValue(traitId, opts)
        if (typeof value !== 'string' && value !== undefined)
            throw new Error(
                this.throwGetTraitError(
                    traitId,
                    `Expects a string, got ${value},`,
                    opts
                )
            )
        return value
    }

    getTraitArrayValue(traitId: TraitId, opts: GetTraitOpts = {}) {
        const value = this.getTraitStringValue(traitId, opts) || ''
        return value.split(';').filter((v) => !!v)
    }

    getTraitStringRequiredValue(traitId: TraitId, opts: GetTraitOpts = {}) {
        const value = this.getTraitStringValue(traitId, opts)
        if (value === undefined) {
            throw new Error(
                this.throwGetTraitError(
                    traitId,
                    `should not be an undefined string`,
                    opts
                )
            )
        }
        return value
    }

    getTraitNumberRequiredValue(traitId: TraitId, opts: GetTraitOpts = {}) {
        const value = this.getTraitNumberValue(traitId, opts)
        if (value === undefined)
            throw new Error(
                this.throwGetTraitError(
                    traitId,
                    `should not be an undefined number`,
                    opts
                )
            )
        return value
    }

    getTraitNumberValue(traitId: TraitId, opts: GetTraitOpts = {}) {
        const value = this.getTraitValue(traitId, opts)
        if (typeof value !== 'number' && value !== undefined) {
            if (typeof value === 'string' && !isNaN(+value))
                return parseInt(value)
            throw new Error(
                this.throwGetTraitError(
                    traitId,
                    `Expects a number, got ${value}`,
                    opts
                )
            )
        }
        return value
    }

    getTraitPristine(traitId: TraitId, opts: GetTraitOpts = {}) {
        const trait = this.getTrait(traitId, opts)
        return !!trait?.pristine
    }

    // Avoid mutating traits since they are global
    // use local attributes instead
    private setTraitValue(
        traitId: TraitId,
        value: any,
        opts:
            | {
                  querySelectorTag?: QuerySelectorTag
                  deviceType?: DeviceType
                  chartType?: ChartType
                  createTraitIfNotExists?: boolean
              }
            | number = {}
    ) {
        const enrichedOpts = this.extractTraitOpts(opts)
        let trait = this.getTrait(traitId, {
            ...enrichedOpts,
            explicitQuerySelectorTag: true,
            explicitDeviceType: true,
            explicitChartType: true,
        })
        if (
            !trait &&
            typeof opts !== 'number' &&
            opts?.createTraitIfNotExists
        ) {
            const { querySelectorTag, deviceType, chartType } =
                this.extractTraitOpts(opts)
            trait = traitFactory(
                {
                    id: traitId,
                    value,
                    querySelectorTags: [querySelectorTag],
                    deviceType,
                    twbIdx: this.twbIdx,
                    chartType,
                },
                this
            )
            this.pushTrait(trait)
        }
        if (trait) trait.setValue(value)
        return this
    }

    setTraitValueForAllChartTypes(
        traitId: TraitId,
        value: any,
        opts: {
            querySelectorTag?: QuerySelectorTag
            deviceType?: DeviceType
            createTraitIfNotExists?: boolean
        } = {}
    ) {
        const { querySelectorTag } = this.extractTraitOpts(opts)
        const chartTypes = this.getTraitStringRequiredValue(
            querySelectorTagToChartTrait(querySelectorTag),
            opts
        ).split(';') as ChartType[]
        for (const chartType of chartTypes) {
            this.setTraitValue(traitId, value, { ...opts, chartType })
        }
        return this
    }

    setPristine(
        traitId: TraitId,
        pristine: boolean,
        opts: {
            querySelectorTag?: QuerySelectorTag
            deviceType?: DeviceType
            chartType?: ChartType
        } = {}
    ) {
        const trait = this.getTrait(traitId, opts)
        if (trait) trait.pristine = pristine
        return this
    }

    tagAsTouched(
        traitId: TraitId,
        opts: {
            querySelectorTag: QuerySelectorTag
            deviceType?: DeviceType
            chartType?: ChartType
        }
    ) {
        const traits = SOURCE_TRAIT_IDS_SET.has(traitId)
            ? this.sourceTraits
            : this.traits
        for (const trait of traits) {
            if (
                trait.id === traitId &&
                trait.querySelectorTags?.includes(opts.querySelectorTag) &&
                (trait.deviceType || DeviceType.DESKTOP) ===
                    (opts.deviceType || DeviceType.DESKTOP) &&
                (trait.chartType || '') === (opts.chartType || '')
            ) {
                trait.tagAsTouched(true)
            }
        }
    }

    refreshAllSourceTraits(sourceTraits: CustomTrait[]) {
        const memoryTraits = [...this.sourceTraits]
        this._sourceTraits = sourceTraits
        for (const trait of sourceTraits) {
            const opts = {
                querySelectorTag: trait.mainQuerySelectorTag,
                deviceType: trait.deviceType,
            }
            const oldValue = this.getTraitValue(trait.id, opts)
            trait.pristine = this.getTraitPristine(trait.id, opts)
            if (oldValue) trait.setValue(oldValue)
        }
        this.pushTraits(memoryTraits, { attributesToUpdate: ['*'] })
        return this
    }

    refreshAllTraits(traits: CustomTrait[]) {
        const memoryTraits = [...this.traits]
        this._traits = traits
        for (const trait of traits) {
            const opts = {
                querySelectorTag: trait.mainQuerySelectorTag,
                deviceType: trait.deviceType,
            }
            const oldValue = this.getTraitValue(trait.id, opts)
            trait.pristine = this.getTraitPristine(trait.id, opts)
            if (oldValue) trait.setValue(oldValue)
        }
        this.pushTraits(memoryTraits, { attributesToUpdate: ['*'] })
        return this
    }

    private _pushTraitRoot(trait: CustomTrait, index?: number) {
        if (SOURCE_TRAIT_IDS_SET.has(trait.id)) {
            if (index !== undefined) this._sourceTraits.splice(index, 0, trait)
            else this._sourceTraits.push(trait)
        } else {
            if (index !== undefined) this._traits.splice(index, 0, trait)
            else this._traits.push(trait)
            trait.tagAsTouched(true)
        }
        this.onSingleTraitChange(trait)
    }

    pushTrait(
        trait: Trait | CustomTrait,
        opts: { attributesToUpdate?: AttributesToUpdate[]; index?: number } = {
            attributesToUpdate: ['*'],
        }
    ) {
        const deviceType = trait.deviceType
        const chartType = trait.chartType
        const querySelectorTag = mainTag(trait.querySelectorTags || [0])

        // const toLog = trait.id === TraitId.CHART_1
        // if (toLog) console.log(toLog, trait, (trait as any).isInstanciated)
        const customTrait = this.getTrait(trait.id, {
            querySelectorTag,
            deviceType,
            chartType,
            explicitQuerySelectorTag: true,
            explicitDeviceType: true,
            explicitChartType: true,
        })
        if (customTrait === undefined) {
            if ((trait as CustomTrait).isInstanciated) {
                this._pushTraitRoot(trait as CustomTrait, opts.index)
            } else {
                const instanciatedTrait = traitFactory(trait, this)
                this._pushTraitRoot(instanciatedTrait, opts.index)
            }
        } else {
            if (!SOURCE_TRAIT_IDS_SET.has(trait.id))
                customTrait.tagAsTouched(true)

            const attributesToUpdate = opts?.attributesToUpdate || []
            // Whenever a trait is pushed to traitSearch with an existing value in the
            // traits, we want to update the value of the trait in the traits
            applyOutsideTraitAttributes(trait, customTrait, attributesToUpdate)
        }
        return this
    }

    private static sortTraits(traits: (Trait | CustomTrait)[]) {
        return [...traits].sort(
            (a: Trait | CustomTrait, b: Trait | CustomTrait) => {
                const indexA = TRAIT_ID_ORDER.indexOf(a.id)
                const indexB = TRAIT_ID_ORDER.indexOf(b.id)
                if (indexB >= 0 && indexA >= 0) return indexA - indexB
                if (indexB >= 0) return -1
                if (indexA >= 0) return 1
                const keyA = [
                    a.id,
                    a.deviceType || DeviceType.DESKTOP,
                    a.chartType || '',
                ].join('_')
                const keyB = [
                    b.id,
                    b.deviceType || DeviceType.DESKTOP,
                    b.chartType || '',
                ].join('_')
                return -keyB.localeCompare(keyA)
            }
        )
    }

    pushTraits(
        traits: (Trait | CustomTrait)[],
        opts: {
            attributesToUpdate?: AttributesToUpdate[]
            allowError?: boolean
        } = {}
    ) {
        // Sort traits based upon trait priority
        // so to try to avoid pushing traits which have dependencies on other traits
        const sortedTraits = TraitSearch.sortTraits(traits).filter((t) => t.id)
        for (const trait of sortedTraits) {
            if (opts.allowError) {
                try {
                    this.pushTrait(trait, opts)
                } catch (e) {
                    console.log(e)
                }
            } else {
                this.pushTrait(trait, opts)
            }
        }
        return this
    }
}
