import { t } from '@/ponychart/i18n'
import { SourceMemory } from '@/ponychart/memoize/sourceMemory'
import { PageStructure, UNIQUE_PAGE_STRUCTURE } from '@/ponychart/page/types'
import { structureChartCount } from '@/ponychart/structure/config'
import {
    CHART_TYPE_TRAITS,
    ChartType,
    TraitId,
    DeviceType,
    PositiveQuerySelectorTag,
    chartTraitToIncludesTrait,
    QuerySelectorTag,
} from 'ponychart'
import { TraitSearch } from '@/ponychart/state/traits'

import { CustomTrait } from './model'
import {
    CardHeightTrait,
    DimensionTrait,
    MeasureTrait,
    IncludesChartTrait,
} from './traits'
import { Bool } from './types'
import { getArrayValueFromString } from './utils'

const memoryInstance = SourceMemory.getInstance()

export function listDeviceTypeTraits(
    structure: PageStructure,
    traitSearch: TraitSearch
): void {
    for (const deviceType of [DeviceType.MOBILE, DeviceType.TABLET]) {
        const traits: CustomTrait[] = []

        const cardHeight = traitSearch.getTraitNumberValue(
            TraitId.CARD_HEIGHT,
            { querySelectorTag: 0, deviceType }
        )
        traits.push(
            new CardHeightTrait(
                { deviceType, value: cardHeight, querySelectorTags: [0] },
                {}
            )
        )

        const measureValue = traitSearch.getTraitStringValue(TraitId.MEASURE, {
            querySelectorTag: 0,
            deviceType: DeviceType.DESKTOP,
        })
        if (measureValue && !UNIQUE_PAGE_STRUCTURE.includes(structure)) {
            const itemIds = getArrayValueFromString(measureValue, {
                allowNone: false,
                allowMultiple: true,
                sortable: true,
                items: memoryInstance.listMeasures(traitSearch.twbIdx),
                twbIdx: traitSearch.twbIdx,
            }) as string[]
            traits.push(
                new MeasureTrait(
                    {
                        value: itemIds.join(';'),
                        querySelectorTags: [0],
                        deviceType,
                        twbIdx: traitSearch.twbIdx,
                    },
                    {
                        allowNone: false,
                        sortable: false,
                        itemIds,
                    },
                    traitSearch
                )
            )
        }

        const dimensionValue = traitSearch.getTraitStringValue(
            TraitId.DIMENSION,
            { querySelectorTag: 0, deviceType: DeviceType.DESKTOP }
        )
        if (dimensionValue && !UNIQUE_PAGE_STRUCTURE.includes(structure)) {
            const itemIds = getArrayValueFromString(dimensionValue, {
                allowNone: false,
                allowMultiple: true,
                sortable: true,
                items: memoryInstance.listDimensions(traitSearch.twbIdx),
                twbIdx: traitSearch.twbIdx,
            }) as string[]
            traits.push(
                new DimensionTrait(
                    {
                        querySelectorTags: [0],
                        value: itemIds.join(';'),
                        deviceType,
                        twbIdx: traitSearch.twbIdx,
                    },
                    {
                        allowNone: false,
                        sortable: false,
                        itemIds,
                    },
                    traitSearch
                )
            )
        }

        // If only one chart, then we don't offer the option to hide the given chart
        // when on mobile
        const chartCount = structureChartCount(structure)

        for (const i in CHART_TYPE_TRAITS) {
            if (chartCount === 1) continue
            const querySelectorTag = (Number(i) + 1) as PositiveQuerySelectorTag
            const traitId = CHART_TYPE_TRAITS[i]
            const chartId = traitSearch.getTraitStringValue(
                traitId,
                querySelectorTag as QuerySelectorTag
            )
            if (!chartId || chartId === ChartType.NONE) continue
            traits.push(
                new IncludesChartTrait(
                    chartTraitToIncludesTrait(traitId as TraitId),
                    {
                        deviceType,
                        twbIdx: traitSearch.twbIdx,
                        label: t('includeChart', {
                            chart: t('charts.' + chartId),
                        }),
                        value: Bool.TRUE,
                    }
                )
            )
        }
        traitSearch.pushTraits(traits, { attributesToUpdate: ['!value'] })
    }
}
