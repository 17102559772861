import { SourceMemory } from '@/ponychart/memoize'

import { translations } from './config'
import { Lang } from './types'

export function t(
    v: string,
    replace: { [k: string]: string } = {},
    lang?: Lang
) {
    const { lang: sourceLang } = SourceMemory.getInstance()
    const l = lang || sourceLang || Lang.EN
    let output = v
        .split('.')
        .reduce(
            (o: any, i: any) => (o && o[i] ? o[i] : ''),
            translations[l] ? translations[l] : translations.en
        )
    for (const k in replace || {}) {
        output = output.replace('{' + k + '}', replace[k])
    }
    if (output.length === 0) return v
    return output
}

export function te(v: string, lang?: Lang): boolean {
    const l = lang || Lang.EN
    const translatedOutput = v
        .split('.')
        .reduce(
            (o: any, i: any) => (o && o[i] ? o[i] : ''),
            translations[l] ? translations[l] : undefined
        )
    return translatedOutput !== undefined
}
