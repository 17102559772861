import {
    LabelId,
    TABLEAU_BLUE,
    TABLEAU_RED,
    TextElementType,
} from './ponychart'
import { SourceMemory } from '@/ponychart/memoize'
const numfmt = require('numfmt')

const sourceMemory = SourceMemory.getInstance()

let context: null | number = null
const REGEX = /({{(.+?)}}{{(.+?)}})/g

function evoValue(thisMeasure: number, lastMeasure: number) {
    return (thisMeasure - lastMeasure) / lastMeasure
}

function diffValue(thisMeasure: number, lastMeasure: number) {
    return thisMeasure - lastMeasure
}

function thisValue(thisMeasure: number, lastMeasure: number) {
    return thisMeasure
}

function lastValue(thisMeasure: number, lastMeasure: number) {
    return lastMeasure
}

function valueFactory(
    type: TextElementType,
    context: { thisMeasure: number; lastMeasure: number }
) {
    return (
        {
            [TextElementType.LAST_MEASURE]: lastValue,
            [TextElementType.THIS_MEASURE]: thisValue,
            [TextElementType.MEASURE]: thisValue,
            [TextElementType.DIFFERENCE]: diffValue,
            [TextElementType.DIFFERENCE_COLORED]: diffValue,
            [TextElementType.EVOLUTION]: evoValue,
            [TextElementType.EVOLUTION_COLORED]: evoValue,
        }[type]?.(context.thisMeasure, context.lastMeasure) || ''
    )
}

function extractAndReplaceRegex(
    element: HTMLElement,
    context: { thisMeasure: number; lastMeasure: number; reversed?: boolean }
) {
    element.innerHTML = element.innerHTML.replace(
        REGEX,
        (_m1: any, _g1: any, elementType: any, format: any) => {
            const value = valueFactory(elementType, context)
            const formattedValue = numfmt.format(format, value)
            if (elementType.includes('colored')) {
                const isBlue =
                    (context.reversed && value < 0) ||
                    (!context.reversed && value > 0)
                return `<span style="color: ${
                    isBlue ? TABLEAU_BLUE : TABLEAU_RED
                }; font-family: ${
                    sourceMemory.fontFamily
                }">${formattedValue}</span>`
            }
            return formattedValue
        }
    )
    element.style.fontFamily = sourceMemory.fontFamily || 'Arial'
}

;(window as any).s = (
    evt: MouseEvent,
    labelId: LabelId,
    payload: { thisMeasure: number; lastMeasure: number }
) => {
    // SHOW
    const tooltipWrapper: any = document.getElementById('tooltip')
    const label = document.getElementById(labelId)

    if (!label) return
    const labelCopy: any = label.cloneNode(true)
    extractAndReplaceRegex(labelCopy, payload)
    labelCopy.style.display = 'block'
    labelCopy.style.height = 'auto'
    labelCopy.style.width = 'auto'
    labelCopy.style.padding = '5px'
    labelCopy.style.borderRadius = '2px'
    labelCopy.style.fontFamily = sourceMemory.fontFamily || 'Arial'
    if (labelCopy.style.removeProperty) {
        labelCopy.style.removeProperty('display')
    } else {
        labelCopy.style.removeAttribute('display')
    }
    tooltipWrapper.innerHTML = ''
    tooltipWrapper.appendChild(labelCopy)
    tooltipWrapper.style.display = 'block'
    tooltipWrapper.style.left = evt.pageX + 10 + 'px'
    tooltipWrapper.style.top = evt.pageY + 10 + 'px'
}
;(window as any).h = function () {
    // HIDE
    if (context !== null) clearTimeout(context)
    context = setTimeout(
        () => {
            const tooltip: any = document.getElementById('tooltip')
            tooltip.style.display = 'none'
        },
        200,
        this
    )
}

function toHtmlSafe(s: string) {
    return String(s)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
}

function iterateRotatingText() {
    let i = 1
    setInterval(() => {
        const elements = document.querySelectorAll('[data-rotating]')
        for (const element of elements) {
            const texts = JSON.parse((element as any).dataset?.rotating || '[]')
            try {
                const colors = JSON.parse(
                    (element as any).dataset?.['rotating-colors'] || '[]'
                )
                const tag = element.tagName.toLowerCase()
                if (colors.length === texts.length) {
                    const styleTag = tag === 'tspan' ? 'fill' : 'color'
                    ;(element as any).style[styleTag] =
                        colors[i % colors.length]
                }
            } finally {
                element.innerHTML =
                    '&nbsp;' + toHtmlSafe(texts[i % texts.length]) + '&nbsp;'
            }
        }
        i += 1
    }, 8000)
}

function iterateRotatingCharts() {
    let i = 1
    setInterval(() => {
        for (let index = 0; index < 4; index++) {
            const elements = document.querySelectorAll(
                `.x-chart-index-${index}`
            )
            for (const element of elements) {
                const maxCount = [...element.classList].find((c) =>
                    c.startsWith('x-chart-max-count-')
                )
                if (!maxCount) continue
                const visible =
                    index === i % Number(maxCount[maxCount.length - 1])
                element.classList.add(
                    visible ? 'x-chart-display' : 'x-chart-hidden'
                )
                element.classList.remove(
                    !visible ? 'x-chart-display' : 'x-chart-hidden'
                )
            }
        }
        i += 1
    }, 3000)
}

const createTooltip = () => {
    if (document.getElementById('tooltip')) return
    const el = document.createElement('div')
    el.style.cssText =
        'position: absolute; display: none; background: white; z-index: 10000; border-radius: 2px;'
    el.id = 'tooltip'
    document.body.appendChild(el)
}
window.onload = function () {
    iterateRotatingText()
    iterateRotatingCharts()
}

export default createTooltip
