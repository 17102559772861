<template lang="pug">
v-dialog(max-width='1100', v-model='show')
    v-dialog(v-model='showSales', width='500')
        v-card(width='500') 
            v-card-title
                span {{ $t("contactSales") }}
                v-spacer
                v-btn(icon, @click='showSales = false')
                    v-icon close
            v-card-text
                v-textarea(
                    v-model='salesMessage',
                    dense,
                    outlined,
                    :label='$t("yourMessage")'
                )
            v-card-actions
                v-spacer
                v-btn.white--text(
                    :disabled='!salesMessage',
                    :color='salesColor',
                    :loading='contactSalesLoading',
                    @click='contactSales'
                ) {{ $t("contactSales") }}
    v-card(outlined, max-width='1100')
        v-card-title
            v-row.ma-0.pa-0(align='start', justify='space-between')
                span {{ title }}
                v-btn(icon, @click='show = false', :loading='!products.length')
                    v-icon close
        v-card-text(v-if="!subscription && latestSubscriptionEnded")
            v-alert.my-0(
                type="warning"
                border="left"
                dense
                outlined
            ) {{$t("subscriptionEnded", {date: format(latestSubscriptionEnded)})}}
        v-card-text.mt-0(v-if='!subscription')
            v-row.ma-0.pa-0.my-0(justify='space-between')
                v-spacer
                v-col.pt-0(cols='6', align='center')
                    v-switch.ma-0(
                        style='max-width: 200px',
                        label='EURO (€)',
                        inset,
                        :disabled='loading',
                        v-model='isEur',
                        color='primary',
                        success,
                        hide-details,
                    )
                        template(v-slot:prepend)
                            span(
                                style='transform: translateY(5px); white-space: pre'
                            ) USD ($)
                v-spacer
                v-col.pt-0(cols='6', align='center')
                    v-switch.ma-0(
                        style='max-width: 200px',
                        inset,
                        v-model='isYear',
                        color='primary',
                        hide-details,
                        success,
                        :disabled='loading'
                    )
                        template(v-slot:prepend)
                            span(
                                style='transform: translateY(5px); white-space: pre'
                            ) {{ $t("perMonth") }}
                        template.m-0(v-slot:append)
                            span(
                                style='transform: translate(-15px, 5px); white-space: pre'
                            ) {{ $t("perYear") }}
                v-spacer
                //- v-col.pa-0(cols='4')
                //-     page(
                //-         :page='page',
                //-         :colors='colors',
                //-         :source-id='-1',
                //-         :sidebar='{ sidebar: "lateral" }',
                //-         :is-subscribed='true',
                //-         :disabled='false',
                //-     )
                v-spacer
                v-row.mx-4.my-4(justify='center')
                    price-table.mx-2(
                        v-for='(product, i) in products',
                        @click='setProduct(i)',
                        :key='product.id',
                        :name='product.name',
                        :color='product.metadata.color',
                        :users='product.metadata.users',
                        :priority-consulting='!!product.metadata.priorityConsulting',
                        :price='productPrice(product)',
                        :currency='currency',
                        :selected="i == productIndex"
                        v-model='interval',
                        :loading='loading && productIndex === i',
                        :disabled='loading && productIndex !== i',
                        :is-enterprise='product.enterprise',
                        :is-pro='product.metadata && product.metadata.plan === "pro"',
                        @pay='goToPay(i)',
                        @sales='displaySales()'
                    )

        subscription-status(
            v-else,
            :subscription='subscription',
            @close='show = false'
        )
</template>

<script>
import { TYPE } from 'vue-toastification'

import Page from '@/components/Pages/Page'
import SubscriptionStatus from '@/components/Payment/SubscriptionStatus'
import PriceTable from '@/components/Payment/PriceTable'

import MailService from '@/services/mailService'
import CheckoutService from '@/services/checkoutService'

// import { buildRandomPage } from '@/ponychart'
import { Pages } from '@/mixins'
import { formatRelative } from 'date-fns'
import { es, fr } from 'date-fns/locale'

const LOCALES = {es, fr}

export default {
    name: 'checkoutComponent',
    components: {
        Page,
        SubscriptionStatus,
        PriceTable,
    },
    mixins: [Pages],
    data: () => ({
        show: false,
        showSales: false,
        salesColor: '#9c27b0',
        isEur: true,
        loading: false,
        salesMessage: '',
        contactSalesLoading: false,
        stripe: null,
        URL: '',
        products: [],
        productIndex: 0,
        page: {},
        interval: 'year',
    }),
    async mounted() {
        const key = this.$store.getters?.isProd
            ? 'pk_live_AxhaK2j2qAjhtlh6HxTqRnKB'
            : 'pk_test_0N16MiLRRTcrfWYcqg7C2kxk'
        const url = window.location.href

        const arr = url.split('/')
        this.URL = arr[0] + '//' + arr[2]
        const urlPrefix = (process.env.VUE_APP_PUBLIC_PATH || "/")
        this.URL += urlPrefix.substring(0, urlPrefix.length - 1)
        // eslint-disable-next-line
        this.stripe = Stripe(key)
        // this.page = this.buildRandomPage(this.lang)
    },
    methods: {
        // buildRandomPage,
        async loadProducts() {
            this.loading = true
            const products = await CheckoutService.listProducts()
            products.push({
                enterprise: true,
                name: 'Enterprise',
                metadata: {
                    color: this.salesColor,
                    users: "?"
                },
                id: '0',
            })
            this.products = products
            this.loading = false
        },
        round(p) {
            return Math.floor(p * 100) / 100
        },
        async call(attempt = 0) {
            try {
                return await CheckoutService.createCheckoutSession(
                    this.priceId,
                    this.lang,
                    this.successUrl,
                    this.cancelUrl
                )
            } catch (e) {
                console.log(e)
                if (attempt === 0) {
                    throw new Error()
                } else {
                    return this.call(attempt - 1)
                }
            }
        },
        async goToPay(i) {
            this.productIndex = i
            this.loading = true
            try {
                const { sessionId } = await this.call(2)
                await this.stripe.redirectToCheckout({ sessionId })
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
                this.showToast({})
            }
        },
        productPrice(product) {
            const prices = (product?.prices || []).filter(
                (price) =>
                    price.recurring.interval === this.interval &&
                    price.currency.toLowerCase() === this.currency.toLowerCase()
            )
            const div = this.interval === 'month' ? 100 : 1200
            return prices.length > 0
                ? this.round(prices[0].unit_amount / div)
                : undefined
        },
        setProduct(i) {
            this.productIndex = i
        },
        displaySales() {
            this.show = false
            this.showSales = true
        },
        async contactSales() {
            this.contactSalesLoading = true
            try {
                await MailService.contactSales(this.salesMessage)
                this.showSales = false
            } catch (e) {
                console.warn(e)
                this.showToast()
            } finally {
                this.showToast({
                    message: this.$t('contactSalesSent'),
                    type: TYPE.INFO,
                })
                this.contactSalesLoading = false
            }
        },
        format(d) {
            const locale = LOCALES[this.$i18n.locale]
                ? { locale: LOCALES[this.$i18n.locale] }
                : undefined
            return formatRelative(new Date(d * 1000), new Date(), locale)
        },
    },
    computed: {
        isYear: {
            get() {
                return this.interval === 'year'
            },
            set(value) {
                if (value) {
                    this.interval = 'year'
                } else {
                    this.interval = 'month'
                }
            },
        },
        product() {
            return this.productIndex >= 0 &&
                this.productIndex < this.products.length
                ? this.products[this.productIndex]
                : null
        },
        title() {
            if (!this.subscription) return this.$t('subscriptionTitle')
            return this.$t('subscribed')
        },
        latestSubscriptionEnded(){
            return this.$store.getters.latestSubscriptionEnded
        },
        subscription() {
            return this.$store.getters.subscription
        },
        priceId() {
            const prices = (this.product?.prices || []).filter(
                (price) =>
                    price.recurring.interval === this.interval &&
                    price.currency.toLowerCase() === this.currency.toLowerCase()
            )
            return prices.length > 0 ? prices[0].id : undefined
        },
        successUrl() {
            return `${this.URL}${this.$route.path}?status=success&session={CHECKOUT_SESSION_ID}`
        },
        cancelUrl() {
            return `${this.URL}${this.$route.path}?status=cancel&session={CHECKOUT_SESSION_ID}`
        },
        colors() {
            return (
                this.$store.state?.source?.styling?.colors || {
                    sidebar_primary: '#f3ebf3', //
                    sidebar_secondary: '#b07aa1',
                    background: '#fbf7fb', // selected text
                    curves: '#b07aa1',
                    light_background: '#e6e6e6', // background
                    border: '#d4a6c8',
                    title: '#b07aa1', // header colors
                }
            )
        },
        paymentEvent() {
            return this.$store.state.events.payment
        },
        currency() {
            return this.isEur ? 'EUR' : 'USD'
        },
    },
    watch: {
        paymentEvent() {
            this.show = true
            // this.page = this.buildRandomPage({ lang: this.lang })
            this.loadProducts()
        },
    },
}
</script>

<style scoped>
.border-primary {
    border: 5px solid #673ab7;
}
</style>

<i18n>{
  "en": {
    "contactSalesSent": "Thank you for your contact, we will get back to you by email soon",
    "subscriptionEnded": "Your subscription ended {date}. You will need to renew to continue using our services",
    "yourMessage": "Your message",
    "subscribed": "You are currently subscribed",
    "paymentTitle": "How many tokens do you want?",
    "subscriptionTitle": "Subscribe?",
    "buy": "Buy {count} tokens",
    "equivalent": "(equivalent to {count} dashboards)",
    "what": "What you'll pay:",
    "discount": "discount",
    "perMonth": "per month",
    "perYear": "per year"
  },
  "es": {
    "paymentTitle": "Selecciona cuántos tokens deseas",
    "buy": "Comprar {count} tokens",
    "equivalent": "(equivalente a {count} dashboard)",
    "what": "Lo que pagarás:",
    "discount": "descuento",
    "perMonth": "por mes",
    "perYear": "por año",
    "subscribed": "Estás suscrito actualmente",
    "subscriptionTitle": "¿Suscribirse?",
    "contactSalesSent": "Gracias por tu contacto, nos pondremos en contacto contigo pronto por correo electrónico",
    "yourMessage": "Tu mensaje",
    "subscriptionEnded": "Tu suscripción finalizó {date}. Tendrás que renovar para seguir usando nuestros servicios"
  },
  "fr": {
    "paymentTitle": "Combien de jetons souhaitez-vous ?",
    "buy": "Acheter {count} jetons",
    "equivalent": "(équivaut à {count} dashboards)",
    "what": "Ce que vous paierez:",
    "discount": "de remise",
    "perMonth": "par mois",
    "perYear": "par an",
    "subscribed": "Vous êtes actuellement abonné(e)",
    "subscriptionTitle": "S'abonner ?",
    "contactSalesSent": "Merci pour votre contact, nous vous répondrons par e-mail bientôt",
    "yourMessage": "Votre message",
    "subscriptionEnded": "Votre abonnement a pris fin le {date}. Vous devrez renouveler votre abonnement pour continuer à utiliser nos services"
  },
  "de": {
    "subscribed": "Sie sind derzeit abonniert.",
    "paymentTitle": "Wie viele Wertmarken möchten Sie?",
    "subscriptionTitle": "Abonnieren?",
    "buy": "Kaufen Sie {count} Tokens",
    "equivalent": "(entspricht {count} Dashboards)",
    "what": "Was Sie zahlen werden:",
    "discount": "Rabatt"
  }
}</i18n>