<template lang="pug">
v-app(dark)
    //- language(@switchLang='switchLang')
    tour
    checkout
    //- welcome-demo
    disconnect(
        @dialog='closeDisconnect',
        @forceLogin='forceLogin',
        :dialog='dialogDisconnect',
        :mode='dialogDisconnectMode',
        :ip='ip'
    )
    no-mobile(@out='logout')
    logout-popup(:dialog='dialog', @logout='logout', @close='dialog = false')
    v-dialog(max-width='400', :value='!!stripe.status && !!stripe.session')
        stripe-session-result(
            :width="400"
            :status='stripe.status',
            :session='stripe.session',
            @close='(stripe.session = ""), (stripe.status = "")'
        )
    logo(v-if='$store.state.getting.user')
    v-app-bar.app-bar(
        app,
        color='primary',
        dark,
        v-if='isLoggedIn && !isDemoPage',
        clipped-right
    )
        a(:href="lang === 'en' ? `https://ponychart.com` : `https://ponychart.com/${lang}`", target="_blank")
            v-avatar.hidden-xs-only.point(to='/', size='40').mr-4.py-2
                img(
                    src='@/assets/logo.png',
                    style='filter: brightness(0) invert(1)'
                )
        v-tooltip(bottom)
            template(v-slot:activator='{ on }')
                v-toolbar-title.point(v-on='on', @click='goTo("/")') Dashboard Wizard
            span {{ $t("tooltip.home", lang) }}
        v-spacer
        v-btn#data-v-step-measure-help(
            @click='activateTour()',
            icon,
            v-if='step',
            :loading='tourLoading'
        )
            v-icon help
        v-tooltip(bottom, v-if='!subscription')
            template(v-slot:activator='{ on }')
                v-btn.primary--text.mr-1(
                    v-on='on',
                    depressed,
                    light,
                    color='white',
                    @click.stop='TOGGLE_EVENT("payment")',
                    slot='activator'
                )
                    v-icon(left) payment
                    span.primary--text {{ $t("subscribe") }}

            span {{ $t("buyMoreSimply") }}

        v-badge(icon='verified', overlap, offset-x='24', offset-y='24', v-else)
            v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                    v-btn(
                        icon,
                        v-on='on',
                        @click.stop='TOGGLE_EVENT("payment")'
                    )
                        v-icon insert_chart_outlined
                span {{ $t("subscribed") }}
        v-menu
            template(v-slot:activator='{ on }')
                v-btn(icon, slot='activator', v-on='on')
                    v-avatar.white(size='32')
                        v-icon(color='primary') person
            v-list.pa-0(light)
                v-list-item
                    v-list-item-icon
                        v-icon(large, color='primary') mdi-account-circle
                    v-list-item-content
                        v-list-item-title {{ userName }}
                        v-list-item-subtitle {{ companyName }}
                v-divider
                v-list-item(key='profile', @click='goTo("/profile")')
                    v-list-item-action
                        v-icon person
                    v-list-item-content
                        v-list-item-title {{ $t("tooltip.profile") }}
                v-divider
                v-tooltip(bottom)
                    template(v-slot:activator='{ on }')
                        v-list-item(
                            v-on='on',
                            key='lock_open',
                            @click.stop='dialog = true'
                        )
                            v-list-item-action
                                v-icon lock_open
                            v-list-item-content
                                v-list-item-title {{ $t("signout") }}
                    span {{ $t("tooltip.log_out") }}
    v-main
        v-progress-linear.loaderProgressLinear(
            v-if='$store.state.loading',
            indeterminate,
            style,
            :height='isLoggedIn ? 8 : 15',
            :color='`${isLoggedIn ? "white" : "purple"} darken-2`'
        )
        background(v-show='!isLoggedIn && !isDemoPage')
        div(
            @click='TOGGLE_EVENT("payment")',
            style='height: 20px; width: 100%; background: orange; color: white; font-size: 80%; text-align: center; cursor: pointer',
            v-if='!isSubscribed && !hideWarning && isUploadRoute'
        )
            v-icon.mr-1(x-small, color='white') warning
            span.ma-auto {{ $t("buyMoreSimply") }}
            v-icon.ml-1(
                x-small,
                color='white',
                @click.stop='hideWarning = true'
            ) close
        router-view(:tour='tour', @logout='dialog = true', ref='router-view')
</template>

<script>
import axios from 'axios'
import Disconnect from '@/components/Home/Disconnect.vue'
import LogoutPopup from '@/components/Home/LogoutPopup.vue'
import StripeSessionResult from '@/components/Home/StripeSessionResult.vue'
import Checkout from '@/components/Home/Checkout.vue'
import Background from '@/components/Home/Background.vue'
import Tour from '@/components/utils/Tour.vue'

// import WelcomeDemo from '@/components/Home/WelcomeDemo.vue'
import Logo from '@/components/Home/Logo.vue'
import NoMobile from '@/components/Home/NoMobile.vue'
import { Socket } from '@/mixins'

import { mapMutations, mapActions, mapGetters } from 'vuex'
import { RegisterTour } from '@/ponychart'
import CheckoutService from '@/services/checkoutService'
import AnalyticsService from '@/services/analyticsService'

const registerTour = RegisterTour.getInstance()

export default {
    name: 'App',
    components: {
        Disconnect,
        LogoutPopup,
        StripeSessionResult,
        Checkout,
        Background,
        // WelcomeDemo,
        Tour,
        Logo,
        NoMobile,
    },
    mixins: [Socket],
    data: () => ({
        dialog: false,
        tour: false,
        gettingSession: false,
        hideWarning: false,
        tourLoading: false,
        stripe: {
            status: '',
            session: '',
        },
    }),
    computed: {
        ...mapGetters({
            dashboardCount: 'dashboardCount',
            attributes: 'cognito/userAttributes',
            companyName: 'companyName',
            userName: 'userName',
            subscription: 'subscription',
            email: 'email',
        }),
        requiresWebSocketTracker() {
            return ['basic', 'pro'].includes(
                this.subscription?.product?.metadata?.plan
            )
        },
        isPro() {
            return this.subscription?.product?.metadata?.plan === 'pro'
        },
        isDemoPage() {
            return this.$route.name === 'Demo'
        },
        step() {
            if (this.$route.name === 'Home') return 'home'
            return this.$route.params.route_step
        },
        isSubscribed() {
            return !!this.subscription
        },
        isUploadRoute() {
            return this.$route.path.includes('dashboards')
        },
    },
    async created() {
        this.SET({ loading: true })
        try {
            await this.fetchSession()
        } catch (e) {
            console.error(e)
        } finally {
            this.SET({ loading: false })
        }
    },
    mounted() {
        localStorage.setItem('tour-home', 'done') // TODO: remove when traffic

        AnalyticsService.ping()
        // const name = this.userName
        // const email = this.email
        // const ctx = this
        // window.Tawk_API.onLoad = function () {
        //     //place your code here
        //     if (!ctx.isPro) {
        //         window.Tawk_API.hideWidget()
        //     }
        //     window.Tawk_API.visitor = {
        //         name,
        //         email,
        //     }
        // }

        setTimeout(() => {
            if (this.checkGod()) this.getYou()
        }, 3000)
    },
    methods: {
        ...mapMutations(['SET', 'TOGGLE_EVENT']),
        ...mapActions({
            signout: 'cognito/signOut',
            fetchSession: 'cognito/fetchSession',
            getYou: 'getYou',
        }),
        closeDisconnect(dialogDisconnect) {
            this.dialogDisconnect = dialogDisconnect
            if (!dialogDisconnect)
                setTimeout(() => (this.dialogDisconnectMode = 'idle'), 1000)
        },
        checkGod() {
            if (this.$route.query.godModeCompany) {
                axios.defaults.headers.common['God-Mode-Company'] =
                    this.$route.query.godModeCompany
            }
            if (this.$route.query.godModeUser) {
                axios.defaults.headers.common['God-Mode-User'] =
                    this.$route.query.godModeUser
            }
            return (
                this.$route.query.godModeUser &&
                this.$route.query.godModeCompany
            )
        },
        async getCheckoutSession() {
            this.checkGod()
            if (
                this.$route.query.status &&
                this.$route.query.session &&
                !this.gettingSession
            ) {
                try {
                    this.gettingSession = true
                    await CheckoutService.getSession(this.$route.query.session)
                    this.stripe.status = this.$route.query.status
                    this.stripe.session = this.$route.query.session
                    const query = Object.assign({}, this.$route.query)
                    delete query.status
                    delete query.session
                    this.$router.replace({ query })
                } catch (e) {
                    console.log(e)
                }
                this.gettingSession = false
            }
        },
        switchLang(lang) {
            this.$store.commit('SET', { user: { lang } })
            this.$i18n.locale = lang
        },
        async activateTour() {
            this.tourLoading = true
            try {
                const vueElement = registerTour.getTourContext()
                if (!vueElement) throw new Error('Element not found')
                vueElement.startTour({ step: this.step, force: true })
            } catch (e) {
                console.log(e)
            } finally {
                this.tourLoading = false
            }
        },
        async getRefSafe(refKey) {
            return new Promise((resolve) => {
                let count = 0
                const interval = setInterval(() => {
                    if (this.$refs[refKey] || count > 50) {
                        clearInterval(interval)
                        if (Array.isArray(this.$refs[refKey])) {
                            resolve(this.$refs[refKey][0])
                        } else {
                            resolve(this.$refs[refKey])
                        }
                    }
                    count += 1
                }, 50)
            })
        },
        calendly() {
            /*eslint-disable */
            Calendly.showPopupWidget(
                `https://calendly.com/data-pony/first-meeting?name=${
                    this.userName || ''
                }&email=${this.email || ''}`
            )
        },
        async forceLogin() {
            await this.loginWebSocket(true)
            this.hasLoggedIn = true
        },
        logout() {
            this.dialog = false
            const lang = this.lang
            this.SET({ loading: true })
            this.signout()
                .then(() => {
                    this.hasLoggedIn = false
                    this.SET({ loading: false })
                    this.$router.push({
                        name: 'Signin',
                        params: {
                            lang,
                        },
                        query: {
                            redirect: this.$route.path,
                        },
                    })
                    // window.Tawk_API.hideWidget()
                })
                .catch(() => {
                    this.SET({ loading: false })
                })
        },
    },
    watch: {
        $route: {
            handler(to) {
                document.title = to?.meta?.title || 'Ponychart'
                this.getCheckoutSession()
            },
            immediate: true,
        },
        lang: {
            handler: function (val) {
                this.$i18n.locale = val
            },
            immediate: true,
        },
        requiresWebSocketTracker(value) {
            if (value && this.isLoggedIn) {
                this.loggedInHookWebSocket()
                this.loginWebSocket()
            }
        },
        //         isPro(isPro) {
        //             if (isPro) {
        //                 try {
        //                     const tawkScript = document.createElement('script')
        //                     tawkScript.setAttribute('type', 'text/javascript')
        //                     tawkScript.innerHTML = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        // (function(){
        // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        // s1.async=true;
        // s1.src='https://embed.tawk.to/5cbf15d3d6e05b735b43e338/default';
        // s1.charset='UTF-8';
        // s1.setAttribute('crossorigin','*');
        // s0.parentNode.insertBefore(s1,s0);
        // })();`
        //                     document.head.appendChild(tawkScript)

        //                     window.Tawk_API.onLoad = function () {
        //                         window.Tawk_API.visitor = {
        //                             name: this.userName,
        //                             email: this.email,
        //                         }
        //                         window.Tawk_API.showWidget()
        //                     }
        //                 } catch (e) {
        //                     return
        //                 }
        //             }
        //         },
        isLoggedIn: {
            handler: async function (isLoggedIn) {
                if (isLoggedIn) {
                    this.dialogDisconnect = false
                    if (this.requiresWebSocketTracker)
                        this.loggedInHookWebSocket()
                    this.getCheckoutSession()
                    await this.getYou().catch(() => {
                        this.logout()
                    })
                    if (this.requiresWebSocketTracker) this.loginWebSocket()
                } else {
                    if (this.requiresWebSocketTracker) this.closeWebSocket()
                }
            },
            immediate: true,
        },
    },
}
</script>

<style src="@/assets/ponychart.css" />

<style>
html {
    scroll-behavior: smooth;
}
/* .v-application .text-h1,
.v-application .text-h2,
.v-application .text-h3,
.v-application .text-h4,
.v-application .text-h5,
.v-application .text-h6,
.main-font {
    font-family: main !important;
}

@font-face {
    font-family: main;
    src: url('~@/assets/fonts/poppins.woff2');
} */
.background-gradient {
    background: linear-gradient(to top right, #2e70ec, #542edf);
}
.gjs-pn-views-container {
    left: 0 !important;
    right: auto !important;
}
.gjs-pn-views {
    left: 0 !important;
    right: auto !important;
}
.gjs-cv-canvas {
    right: 0 !important;
    left: auto !important;
}
.gjs-pn-panel {
    /* remove the right gap */
    /* width: 100%; */

    /* remove the top gap */
    /* height: 100%; */
    top: 0 !important;
    right: 0 !important;
}
/** Used to display a loader on GrapesJS */
.lds-dual-ring {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    transform: translate(-20px, 0px);
    background: #673ab7;
}
.lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 18px;
    height: 18px;
    margin: 1.2px;
    border-radius: 50%;
    border: 1.8px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/** Used for graying out charts */
.gray-filter {
    filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

.gradient-border {
    background: linear-gradient(
        60deg,
        #5f86f2,
        #a65ff2,
        #f25fd0,
        #f25f61,
        #f2cb5f,
        #abf25f,
        #5ff281,
        #5ff2f0
    );
    background-size: 300% 300%;
    background-position: 0 50%;

    border-radius: 2px;
    animation: moveGradient 4s ease infinite;
    /* animation: gradient 4s ease infinite; */
    /* box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5); */
}

@keyframes moveGradient {
    50% {
        background-position: 100% 50%;
    }
}
.multiselect__content-wrapper {
    z-index: 100 !important;
}
rect:hover {
    opacity: 0.8;
}

.gjs-clm-tags {
    display: none !important;
}
.gjs-one-bg {
    background-color: #673ab7 !important;
}
.app-bar {
    display: inline-block;
    background: linear-gradient(to left, #673ab7, #9c27b0);
}
.loaderProgressLinear {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10000;
}
@keyframes green_glow {
    0% {
        box-shadow: 0 0 -10px #00ff00;
    }
    40% {
        box-shadow: 0 0 20px #00ff00;
    }
    60% {
        box-shadow: 0 0 20px #00ff00;
    }
    100% {
        box-shadow: 0 0 -10px #00ff00;
    }
}

@keyframes orange_glow {
    0% {
        box-shadow: 0 0 -10px orange;
    }
    40% {
        box-shadow: 0 0 20px orange;
    }
    60% {
        box-shadow: 0 0 20px orange;
    }
    100% {
        box-shadow: 0 0 -10px orange;
    }
}

@keyframes red_glow {
    0% {
        box-shadow: 0 0 -10px red;
    }
    40% {
        box-shadow: 0 0 20px red;
    }
    60% {
        box-shadow: 0 0 20px red;
    }
    100% {
        box-shadow: 0 0 -10px red;
    }
}

@keyframes blue_glow {
    0% {
        box-shadow: 0 0 -10px var(--v-info);
    }
    40% {
        box-shadow: 0 0 20px var(--v-info);
    }
    60% {
        box-shadow: 0 0 20px var(--v-info);
    }
    100% {
        box-shadow: 0 0 -10px var(--v-info);
    }
}
.point {
    cursor: pointer;
}

.image-select > .v-input__control > .v-input__slot {
    min-height: 150px !important;
}
</style>
