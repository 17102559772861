<template lang="pug">
v-col.mx-0.px-0(cols='4')
    v-select(
        :value='value',
        @change='(e) => $emit("set", { [name]: e })',
        dense,
        outlined,
        item-value='id',
        item-text='alias',
        :items='items',
        :label='$t("dates." + name)',
        v-if='type == "field"'
    )
        template(v-slot:prepend)
            v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                    v-btn(
                        v-on='on',
                        icon,
                        color='green lighten-1',
                        @click='$emit("set", { [`${name}Type`]: "text" })'
                    )
                        v-icon.filterIcon text_format
                span Switch
    v-text-field(
        :value='value',
        @change='(e) => $emit("set", { [name]: e })',
        dense,
        outlined,
        type='number',
        :label='$t("dates." + name)',
        v-else
    )
        template(v-slot:prepend)
            v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                    v-btn(
                        v-on='on',
                        icon,
                        color='blue lighten-1',
                        @click='$emit("set", { [`${name}Type`]: "field" })'
                    )
                        v-icon.filterIcon settings_ethernet
                span Switch
</template>

<script>
export default {
    name: 'selectSwitch',
    props: {
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    watch: {
        value(val) {
            this.$emit('set', { [this.name]: val })
        },
    },
}
</script>


<style scoped>
.filterIcon {
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
}
.filterIcon:hover {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
</style>