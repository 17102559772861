import {
    comparisonPeriodsFromType,
    COMPARISON_PERIOD_MONTHS,
    getTimeSelectorTypes,
} from '@/ponychart/header/mappings'
import { t } from '@/ponychart/i18n'
import { PageMode } from '@/ponychart/page/types'
import { CARD_PIXEL_HEIGHTS } from '@/ponychart/utils/config'
import { LabelId } from '@/ponychart/label/types'
import { SourceMemory } from '@/ponychart/memoize'
import { nullDimension } from '@/ponychart/dimension/types'
import { TraitSearch } from '@/ponychart/state/traits'
import {
    CHART_TYPES,
    ChartType,
    TraitId,
    Bands,
    HeaderDesign,
    TraitOptionItem,
    Orientation,
    TimeSelectorType,
    DeviceType,
    querySelectorTagToChartTrait,
    SidebarDesign,
    SidebarType,
    CHART_TYPE_TRAITS,
    MultiSelectTraitOptions,
    DefaultTraitOptions,
    SelectTraitOptions,
    TiptapTraitOptions,
    CheckboxTraitOptions,
    SliderTraitOptions,
    ParameterTraitOptions,
    ParameterMode,
} from 'ponychart'

import { FONTS, FREE_CHART_TYPES } from './config'
import {
    CheckBoxTrait,
    MultiSelectTrait,
    ParameterTrait,
    SelectTrait,
    SliderTrait,
    TiptapTrait,
} from './model'
import {
    Bool,
    ColorLegend,
    DateAggregationLevel,
    MeasureColorLocation,
} from './types'
import {
    getArrayValueFromString,
    getStringValueFromArray,
    mainTag,
} from './utils'
import { omit, sample } from 'lodash'
import { TextElementFactoryV2 } from '@/ponychart/label'

const sourceInstance = SourceMemory.getInstance()

const textElementFactory = new TextElementFactoryV2()

export class BandsTrait extends MultiSelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        multiSelectOptions: MultiSelectTraitOptions = {}
    ) {
        super(TraitId.BANDS, defaultOptions, {
            ...omit(multiSelectOptions, 'options'),
            allowNone: true,
            itemIds: [
                Bands.TOP,
                Bands.LEFT,
                Bands.RIGHT,
                Bands.CENTER_HORIZONTAL,
                Bands.BOTTOM,
                Bands.TOP_LEFT,
                Bands.TOP_RIGHT,
                Bands.BOTTOM_LEFT,
                Bands.BOTTOM_RIGHT,
            ],
        })
    }
}

export class ColorTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions = {}
    ) {
        const itemIds =
            selectOptions?.itemIds && selectOptions?.itemIds?.length > 1
                ? selectOptions?.itemIds
                : [
                      ColorLegend.DIMENSION,
                      ColorLegend.DELTA,
                      ColorLegend.FIXED,
                      ColorLegend.VOLUME,
                  ]
        super(TraitId.COLOR, defaultOptions, {
            ...omit(selectOptions, 'options'),
            itemIds,
        })
    }
}

export class LabelTrait extends TiptapTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        tiptapOptions: TiptapTraitOptions,
        traitSearch: TraitSearch
    ) {
        super(
            TraitId.LABEL,
            defaultOptions,
            {
                ...tiptapOptions,
                hasDate: traitSearch?.hasDate({
                    ...defaultOptions,
                    querySelectorTag: mainTag(
                        defaultOptions.querySelectorTags || [0]
                    ),
                }),
                hasDimension: traitSearch?.hasDimension({
                    ...defaultOptions,
                    querySelectorTag: mainTag(
                        defaultOptions.querySelectorTags || [0]
                    ),
                }),
            },
            traitSearch
        )
    }

    setValue(value: string): boolean {
        const result = super.setValue(value)
        if (this.pristine && this.itemIds.length) {
            this._value = textElementFactory.buildDb(
                this.itemIds[0] as LabelId,
                {
                    hasDate: this.hasDate,
                    hasDimension: this.hasDimension,
                },
                {
                    alignment: 'center',
                }
            )
            return true
        }
        return result
    }
}

export class CardTitleTrait extends TiptapTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        tiptapOptions: TiptapTraitOptions,
        traitSearch: TraitSearch
    ) {
        super(
            TraitId.CARD_TITLE,
            defaultOptions,
            {
                ...tiptapOptions,
                hasDate: traitSearch?.hasDate({
                    ...defaultOptions,
                    querySelectorTag: mainTag(
                        defaultOptions.querySelectorTags || [0]
                    ),
                }),
                hasDimension: traitSearch?.hasDimension({
                    ...defaultOptions,
                    querySelectorTag: mainTag(
                        defaultOptions.querySelectorTags || [0]
                    ),
                }),
                itemIds: tiptapOptions?.itemIds || [LabelId.CARD_TITLE],
            },
            traitSearch
        )
    }

    setValue(value: string) {
        if (!this.traitSearch) throw new Error('traitSearch not implemented')
        const result = super.setValue(value)
        if (this.pristine && this.itemIds.length) {
            this._value = textElementFactory.buildDb(
                this.itemIds[0] as LabelId,
                {
                    hasDimension: this.hasDimension,
                    hasDate: this.hasDate,
                },
                {
                    alignment: 'left',
                }
            )
            return true
        }
        return result
    }
}

export class TooltipTrait extends TiptapTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        tiptapOptions: TiptapTraitOptions,
        traitSearch: TraitSearch
    ) {
        super(
            TraitId.TOOLTIP,
            defaultOptions,
            {
                ...tiptapOptions,
                hasDate: traitSearch?.hasDate({
                    ...defaultOptions,
                    querySelectorTag: mainTag(
                        defaultOptions.querySelectorTags || [0]
                    ),
                }),
                hasDimension: traitSearch?.hasDimension({
                    ...defaultOptions,
                    querySelectorTag: mainTag(
                        defaultOptions.querySelectorTags || [0]
                    ),
                }),
            },
            traitSearch
        )
    }

    setValue(value: string): boolean {
        const result = super.setValue(value)
        if (this.pristine && this.itemIds.length) {
            this._value = textElementFactory.buildDb(
                this.itemIds[0] as LabelId,
                {
                    hasDimension: this.hasDimension,
                    hasDate: this.hasDate,
                },
                {
                    alignment: 'left',
                }
            )
            return true
        }
        return result
    }
}

export class ChartSubtypeTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions
    ) {
        super(TraitId.CHART_SUBTYPE, defaultOptions, selectOptions)
    }
}

export class PageModeTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions = {}
    ) {
        super(TraitId.PAGE_MODE, defaultOptions, {
            ...selectOptions,
            itemIds: [PageMode.MEASURE, PageMode.DIMENSION],
        })
    }
}

export class IncludePageBlockHeaderTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions = {}
    ) {
        super(TraitId.INCLUDE_PAGE_BLOCK_HEADER, defaultOptions, options)
    }
}

export class IncludesChartTrait extends CheckBoxTrait {
    constructor(
        traitId: TraitId,
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions = {}
    ) {
        super(traitId, defaultOptions, options)
    }
}

export class DimensionTrait extends MultiSelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        dimensionOptions: MultiSelectTraitOptions,
        traitSearch: TraitSearch
    ) {
        const twbIdx: number =
            defaultOptions.twbIdx === undefined
                ? traitSearch.twbIdx
                : defaultOptions.twbIdx
        if (twbIdx === undefined)
            throw new Error('twbIdx must be defined for DimensionTrait')
        const deviceType = defaultOptions?.deviceType || DeviceType.DESKTOP
        const querySelectorTag = mainTag(
            defaultOptions?.querySelectorTags || [0]
        )
        const dimensions = sourceInstance.listDimensions(twbIdx)
        let items = (
            dimensionOptions?.allowNone
                ? [nullDimension(twbIdx), ...dimensions]
                : dimensions
        ).map((d: any) => ({
            id: d.id,
            alias: d.alias,
            twbIdx: d.twbIdx,
        })) as TraitOptionItem[]
        const sortable =
            deviceType === DeviceType.DESKTOP && querySelectorTag === 0
        if (deviceType !== DeviceType.DESKTOP && querySelectorTag === 0) {
            // If we're not desktop & querySelectorTag is 0,
            // then the sorting behaviour of the trait is determined by the desktop dimension trait
            const desktopDimensionTrait = traitSearch.getTraitRequired(
                TraitId.DIMENSION,
                { querySelectorTag, deviceType: DeviceType.DESKTOP }
            )
            const itemIds = getArrayValueFromString(
                String(desktopDimensionTrait.value),
                {
                    items,
                    allowMultiple: true,
                    sortable: true,
                    twbIdx,
                }
            ) as string[]
            const itemMap = items.reduce(
                (acc, item) => ({ ...acc, [item.id]: item }),
                {}
            )
            items = itemIds.map((id: string) => itemMap[id])
        }
        super(
            TraitId.DIMENSION,
            defaultOptions,
            {
                ...dimensionOptions,
                sortable,
                itemIds: undefined,
                items,
            },
            traitSearch
        )
    }

    setValue(value: number | string | string[]) {
        if (typeof value === 'number')
            throw new Error('Invalid type for value: ' + value)
        const stringValue = Array.isArray(value)
            ? getStringValueFromArray(value, {
                  items: this.items,
                  allowMultiple: true,
                  allowNone: this.allowNone,
                  sortable: this.sortable,
                  twbIdx: this.twbIdx,
              })
            : value
        return super.setValue(stringValue)
    }
}

export class MeasureTrait extends MultiSelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        measureOptions: MultiSelectTraitOptions,
        traitSearch: TraitSearch
    ) {
        const twbIdx =
            defaultOptions.twbIdx === undefined
                ? traitSearch.twbIdx
                : defaultOptions.twbIdx
        if (twbIdx === undefined)
            throw new Error('twbIdx must be defined for MeasureTrait')
        const deviceType = defaultOptions?.deviceType || DeviceType.DESKTOP
        const querySelectorTag = mainTag(
            defaultOptions?.querySelectorTags || [0]
        )
        let items = sourceInstance.listMeasures(twbIdx).map((m: any) => ({
            id: m.id,
            alias: m.alias,
            twbIdx: m.twbIdx,
        })) as TraitOptionItem[]
        const sortable =
            deviceType === DeviceType.DESKTOP && querySelectorTag === 0
        if (deviceType !== DeviceType.DESKTOP && querySelectorTag === 0) {
            // If we're not desktop & querySelectorTag is 0,
            // then the sorting behaviour of the trait is determined by the desktop dimension trait
            const desktopMeasureTrait = traitSearch.getTraitRequired(
                TraitId.MEASURE,
                { querySelectorTag, deviceType: DeviceType.DESKTOP }
            )
            const itemIds = getArrayValueFromString(
                String(desktopMeasureTrait.value),
                {
                    items,
                    allowMultiple: true,
                    sortable: true,
                    twbIdx,
                }
            ) as string[]
            const itemMap = items.reduce(
                (acc, item) => ({ ...acc, [item.id]: item }),
                {}
            )
            items = itemIds.map((id: string) => itemMap[id])
        }
        super(TraitId.MEASURE, defaultOptions, {
            ...measureOptions,
            itemIds: undefined,
            items,
            sortable,
        })
    }

    setValue(value: number | string | string[]) {
        if (typeof value === 'number') throw new Error('Invalid type for value')
        const stringValue = getStringValueFromArray(value, {
            items: this.items,
            allowMultiple: true,
            allowNone: this.allowNone,
            sortable: this.sortable,
            twbIdx: this.twbIdx,
        })
        return super.setValue(stringValue)
    }
}

export class DateAggregationTrait extends MultiSelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        dateAggOptions: MultiSelectTraitOptions,
        traitSearch: TraitSearch
    ) {
        super(
            TraitId.DATE_AGGREGATION_LEVEL,
            {
                value: DateAggregationTrait.suggestedValueFromState(
                    traitSearch
                ),
                ...defaultOptions,
            },
            {
                ...dateAggOptions,
                itemIds: undefined,
                // value: ColumnType.DYNAMIC,
                allowNone: false,
                items: DateAggregationTrait.itemsFromState(traitSearch),
            },
            traitSearch
        )
    }

    static dateAggregationLevelFromState(
        traitSearch: TraitSearch
    ): 'day' | 'month' {
        return (traitSearch.getTraitStringValue(
            TraitId.SOURCE_DATE_AGGREGATION_LEVEL,
            0
        ) || 'month') as 'day' | 'month'
    }

    static itemsFromState(traitSearch: TraitSearch) {
        const dateAggregationLevel =
            DateAggregationTrait.dateAggregationLevelFromState(traitSearch)
        return DateAggregationTrait.allowedDateAggregationLevels(
            dateAggregationLevel
        ).map(DateAggregationTrait.toTraitItem)
    }

    static suggestedValueFromState(traitSearch: TraitSearch) {
        const dateAggregationLevel =
            DateAggregationTrait.dateAggregationLevelFromState(traitSearch)
        return DateAggregationTrait.suggestedDateAggregationLevels(
            dateAggregationLevel
        ).join(';')
    }

    static toTraitItem(id: string): TraitOptionItem {
        return {
            id,
            alias: DateAggregationTrait.toAlias(id),
        }
    }

    static toAlias(id: string): string {
        return `${t('by')} ${t(`date.${id}`)}`
    }

    static allowedDateAggregationLevels(dateAggregationLevel: 'month' | 'day') {
        const dateAggregationLevels = [
            DateAggregationLevel.YEAR,
            DateAggregationLevel.QUARTER,
            DateAggregationLevel.MONTH,
        ]
        if (dateAggregationLevel === 'day') {
            dateAggregationLevels.push(DateAggregationLevel.WEEK)
            dateAggregationLevels.push(DateAggregationLevel.DAY)
        }
        return dateAggregationLevels
    }

    static suggestedDateAggregationLevels(
        dateAggregationLevel: 'month' | 'day'
    ) {
        const dateAggregationLevels = [DateAggregationLevel.MONTH]
        if (dateAggregationLevel === 'day') {
            dateAggregationLevels.push(DateAggregationLevel.WEEK)
            dateAggregationLevels.push(DateAggregationLevel.DAY)
        }
        return dateAggregationLevels
    }
}

export class GeoTrait extends SelectTrait {
    constructor(
        traitId: TraitId,
        defaultOptions: DefaultTraitOptions,
        geoTraitOptions: SelectTraitOptions,
        traitSearch: TraitSearch
    ) {
        if (
            ![
                TraitId.GEO_COUNTRY,
                TraitId.GEO_REGION,
                TraitId.GEO_CITY,
            ].includes(traitId)
        )
            throw new Error('Invalid traitId for GeoTrait')
        const twbIdx =
            defaultOptions.twbIdx === undefined
                ? traitSearch.twbIdx
                : defaultOptions.twbIdx
        if (twbIdx === undefined)
            throw new Error('twbIdx should be set for GeoTrait')
        const countryColumns = sourceInstance.getCountryColumns(twbIdx)
        const regionColumns = sourceInstance.getRegionColumns(twbIdx)
        const cityColumns = sourceInstance.getCityColumns(twbIdx)
        const columns = {
            [TraitId.GEO_COUNTRY]: countryColumns,
            [TraitId.GEO_REGION]: regionColumns,
            [TraitId.GEO_CITY]: cityColumns,
        }[
            traitId as
                | TraitId.GEO_COUNTRY
                | TraitId.GEO_REGION
                | TraitId.GEO_CITY
        ]
        super(traitId, defaultOptions, {
            ...geoTraitOptions,
            itemIds: undefined,
            items: columns.length ? columns : sourceInstance.fakeDimensions,
        })
    }
}

export class OrientationTrait extends SelectTrait {
    constructor(
        traitId: TraitId,
        defaultOptions: DefaultTraitOptions,
        orientationOptions: SelectTraitOptions
    ) {
        super(traitId, defaultOptions, {
            ...orientationOptions,
            itemIds: [Orientation.HORIZONTAL, Orientation.VERTICAL],
        })
    }
}

export class TimeSelectorOrientationTrait extends OrientationTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        orientationOptions: SelectTraitOptions
    ) {
        super(
            TraitId.TIME_SELECTOR_ORIENTATION,
            defaultOptions,
            orientationOptions
        )
    }
}

export class TimeSelectorTypeTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        timeSelectorTypeOptions: SelectTraitOptions,
        traitSearch: TraitSearch
    ) {
        const itemIds =
            TimeSelectorTypeTrait.getTimeSelectorTypesFromState(traitSearch)
        super(
            TraitId.TIME_SELECTOR_TYPE,
            defaultOptions,
            {
                ...timeSelectorTypeOptions,
                itemIds,
            },
            traitSearch
        )
    }

    setValue(value: string | number) {
        this.setItems([])
        const itemIds = this.items.map((item) => item.id)
        if (!itemIds.includes(value)) {
            value = itemIds[0]
        }
        const changed = value !== this.value
        if (changed) this._value = value
        return changed
    }

    setItems(_items: TraitOptionItem[]) {
        if (!this.traitSearch) throw new Error('traitSearch not implemented')
        const items = TimeSelectorTypeTrait.getTimeSelectorTypesFromState(
            this.traitSearch
        ).map((itemId) => this.toTraitItem(itemId))
        const changed = items.length !== this.items.length
        this.items = items
        return changed
    }

    static getTimeSelectorTypesFromState(traitSearch: TraitSearch) {
        const dateAggregationLevel = traitSearch.getTraitStringRequiredValue(
            TraitId.SOURCE_DATE_AGGREGATION_LEVEL,
            0
        ) as 'day' | 'month'
        return getTimeSelectorTypes(dateAggregationLevel)
    }
}

export class ColumnCountTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        columnCountOptions: SelectTraitOptions
    ) {
        super(TraitId.COLUMN_COUNT, defaultOptions, {
            ...columnCountOptions,
            items:
                columnCountOptions?.items ||
                [1, 2, 3, 4, 5, 6].map((id: number) => ({
                    id,
                    alias: `${id} ${t('column')}`,
                })),
        })
    }
}

export class AlignVerticallyTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        alignOptions: CheckboxTraitOptions
    ) {
        super(TraitId.ALIGN_VERTICALLY, defaultOptions, alignOptions)
    }
}

export class BlockTitleTrait extends TiptapTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        tiptapOptions: TiptapTraitOptions
    ) {
        super(TraitId.BLOCK_TITLE, defaultOptions, tiptapOptions)
    }
}

export class CardHasBordersTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions
    ) {
        super(TraitId.CARD_HAS_BORDERS, defaultOptions, options)
    }
}

export class BlockHasTimeIndicationTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions
    ) {
        super(TraitId.BLOCK_HAS_TIME_INDICATION, defaultOptions, options)
    }
}

export class MeasureColorTrait extends MultiSelectTrait {
    _defaultAsAllValues = false
    constructor(
        defaultOptions: DefaultTraitOptions,
        measureColorOptions: MultiSelectTraitOptions
    ) {
        super(
            TraitId.MEASURE_COLOR,
            {
                ...defaultOptions,
                hidden: true,
            },
            {
                ...measureColorOptions,
                allowNone: true,
                itemIds: [
                    MeasureColorLocation.BORDER,
                    MeasureColorLocation.CURVES,
                    MeasureColorLocation.TITLE,
                ],
            }
        )
    }
}

function chartSelectionPayload(
    traitSearch: TraitSearch,
    defaultOptions: DefaultTraitOptions,
    multiSelectOptions: MultiSelectTraitOptions
) {
    const twbIdx =
        defaultOptions.twbIdx === undefined
            ? traitSearch.twbIdx
            : defaultOptions.twbIdx
    if (twbIdx === undefined)
        throw new Error('twbIdx must be specified for ChartSelectionTrait')
    const hasGeo = sourceInstance.getHasGeo(twbIdx)
    const chartTypes = CHART_TYPES.filter(
        (ct: ChartType) => hasGeo || ct !== ChartType.MAP
    )
    return {
        ...multiSelectOptions,
        allowNone: true,
        items: chartTypes.map((id: ChartType) => ({
            id,
            alias: t(`charts.${id}`),
            disabled: chartTypeDisabled(id),
        })),
        maxCount: traitSearch.multipleCharts ? 4 : 1,
    }
}

function chartTypeDisabled(chartType: ChartType) {
    return !sourceInstance.isSubscribed && !FREE_CHART_TYPES.includes(chartType)
}

export class MultipleChartSelectionTrait extends MultiSelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        multiSelectOptions: MultiSelectTraitOptions,
        traitSearch: TraitSearch
    ) {
        if (!defaultOptions.querySelectorTags)
            throw new Error(
                'QuerySelector Tags must be specified for ChartSelectionTrait'
            )
        const querySelectorTag = mainTag(defaultOptions.querySelectorTags)
        super(
            querySelectorTagToChartTrait(querySelectorTag),
            defaultOptions,
            chartSelectionPayload(
                traitSearch,
                defaultOptions,
                multiSelectOptions
            )
        )
    }

    private applyDisabledItemsHook() {
        this.items.forEach((o: TraitOptionItem) => {
            o.disabled = o.disabled || chartTypeDisabled(o.id as ChartType)
        })
    }

    setValue(value: any) {
        value = (
            typeof value === 'string' ? (value as any).split(';') : value
        ).sort(
            (a: TraitId, b: TraitId) =>
                CHART_TYPE_TRAITS.indexOf(a) - CHART_TYPE_TRAITS.indexOf(b)
        )
        const output = super.setValue(value)
        this.applyDisabledItemsHook()
        return output
    }

    setItems(items: TraitOptionItem[]): boolean {
        const output = super.setItems(items)
        this.applyDisabledItemsHook()
        return output
    }

    getRandomValue(): string {
        const itemIds = this.items
            .map((o) => String(o.id))
            .filter((id) => id !== ChartType.NONE)
        return sample(itemIds) || itemIds[0]
    }
}

export class CardHeightTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions
    ) {
        super(
            TraitId.CARD_HEIGHT,
            {
                ...defaultOptions,
                value: defaultOptions?.value || CARD_PIXEL_HEIGHTS[4],
            },
            {
                ...selectOptions,
                itemIds: undefined,
                items: CARD_PIXEL_HEIGHTS.map((id) => ({
                    id,
                    alias: `${id}px`,
                })),
            }
        )
    }
}

export class LabelSizeTrait extends SliderTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        sliderOptions: SliderTraitOptions
    ) {
        super(
            TraitId.LABEL_SIZE,
            {
                ...defaultOptions,
                value:
                    defaultOptions.value === undefined
                        ? 100
                        : defaultOptions.value,
            },
            {
                ...sliderOptions,
                sliderPrefix: '%',
                maxValue:
                    sliderOptions.maxValue === undefined
                        ? 400
                        : sliderOptions.maxValue,
                minValue: sliderOptions.minValue || 20,
            }
        )
    }
}

export class HeaderDesignTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions
    ) {
        super(
            TraitId.HEADER_DESIGN,
            {
                ...defaultOptions,
                value: defaultOptions?.value || HeaderDesign.CENTERED,
            },
            {
                ...selectOptions,
                itemIds: [
                    HeaderDesign.CENTERED,
                    HeaderDesign.SIMPLE,
                    HeaderDesign.SIMPLE_VERTICAL,
                    HeaderDesign.DENSE,
                ],
            }
        )
    }
}

export class ComparisonPeriodCountTrait extends ParameterTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        parameterOptions: ParameterTraitOptions,
        traitSearch: TraitSearch
    ) {
        super(
            TraitId.COMPARISON_PERIOD_COUNT,
            {
                ...defaultOptions,
                value: defaultOptions?.value || 1,
            },
            {
                ...parameterOptions,
                mode: ParameterMode.RANGE,
            },
            traitSearch
        )
    }
}

export class ComparisonPeriodTrait extends MultiSelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        multiSelectOptions: MultiSelectTraitOptions,
        traitSearch: TraitSearch
    ) {
        super(
            TraitId.COMPARISON_PERIODS,
            {
                ...defaultOptions,
                value:
                    defaultOptions?.value ||
                    ComparisonPeriodTrait.comparisonPeriodsFromState(
                        traitSearch
                    ).join(';'),
            },
            {
                ...multiSelectOptions,
                allowNone: false,
                items: ComparisonPeriodTrait.comparisonPeriodsFromState(
                    traitSearch
                ).map((id: string) => ({
                    id,
                    alias: t(`${TraitId.COMPARISON_PERIODS}.${id}`),
                })),
            },
            traitSearch
        )
    }

    get hasParameter() {
        return String(this.value).split(';').length > 1
    }

    setValue(value: string | number | string[]): boolean {
        this.setItems(this.items || [])
        return super.setValue(value)
    }

    setItems(_items: TraitOptionItem[]) {
        if (!this.traitSearch) throw Error('traitSearch not implemented')
        const items = ComparisonPeriodTrait.comparisonPeriodsFromState(
            this.traitSearch
        ).map((item) => this.toTraitItem(item))
        const output = super.setItems(items)
        return output
    }

    static comparisonPeriodsFromState(traitSearch: TraitSearch) {
        const timeSelectorType = traitSearch.getTraitStringRequiredValue(
            TraitId.TIME_SELECTOR_TYPE,
            0
        ) as TimeSelectorType
        const output = comparisonPeriodsFromType(timeSelectorType)
        return output
    }
}

export class SourceDateAggregationLevelTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions
    ) {
        super(
            TraitId.SOURCE_DATE_AGGREGATION_LEVEL,
            {
                ...defaultOptions,
                value: defaultOptions.value || 'month',
            },
            {
                ...selectOptions,
                itemIds: ['month', 'day'],
            }
        )
    }
}

export class HeaderWithColorsTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions
    ) {
        super(
            TraitId.WITH_COLORS,
            { ...defaultOptions, value: defaultOptions.value || Bool.TRUE },
            options
        )
    }
}

export class IncludeCardTitleTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions
    ) {
        super(
            TraitId.INCLUDE_CARD_TITLE,
            {
                ...defaultOptions,
                value: defaultOptions.value || Bool.TRUE,
            },
            options
        )
    }
}

export class HeaderWithTimePeriodTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions
    ) {
        super(
            TraitId.WITH_TIME_PERIOD,
            {
                ...defaultOptions,
                value: defaultOptions.value || Bool.TRUE,
            },
            options
        )
    }
}

export class DensifyHeaderFiltersTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions
    ) {
        super(
            TraitId.DENSIFY_HEADER_FILTERS,
            {
                ...defaultOptions,
                value: defaultOptions.value || Bool.FALSE,
            },
            options
        )
    }
}

export class HeaderWithLogoTrait extends CheckBoxTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        options: CheckboxTraitOptions
    ) {
        super(
            TraitId.WITH_LOGO,
            { ...defaultOptions, value: defaultOptions.value || Bool.TRUE },
            options
        )
    }
}

export class SidebarTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions
    ) {
        super(
            TraitId.SIDEBAR,
            {
                ...defaultOptions,
                value: defaultOptions.value || SidebarType.EMPTY,
            },
            {
                ...selectOptions,
                itemIds: [
                    SidebarType.LATERAL,
                    SidebarType.TOP,
                    SidebarType.EMPTY,
                ],
            }
        )
    }
}

export class HeaderHeightTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions
    ) {
        super(
            TraitId.HEADER_HEIGHT,
            {
                ...defaultOptions,
                value:
                    defaultOptions.value === undefined
                        ? 300
                        : defaultOptions.value,
            },
            {
                ...selectOptions,
                itemIds: undefined,
                items: [100, 200, 300, 400, 500, 600, 700].map(
                    (id: number) => ({
                        id,
                        alias: `${id}px`,
                    })
                ),
            }
        )
    }
}

export class PageMarginXTrait extends SliderTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        sliderOptions: SliderTraitOptions
    ) {
        super(
            TraitId.PAGE_MARGIN_X,
            {
                ...defaultOptions,
                value:
                    defaultOptions.value === undefined
                        ? 100
                        : defaultOptions.value,
            },
            {
                ...sliderOptions,
                maxValue:
                    sliderOptions.maxValue === undefined
                        ? 200
                        : sliderOptions.maxValue,
                minValue: sliderOptions.minValue || 0,
            }
        )
    }
}

export class MarginTrait extends SliderTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        sliderOptions: SliderTraitOptions
    ) {
        super(
            TraitId.MARGIN,
            {
                ...defaultOptions,
                value:
                    defaultOptions.value === undefined
                        ? 4
                        : defaultOptions.value,
            },
            {
                ...sliderOptions,
                maxValue:
                    sliderOptions.maxValue === undefined
                        ? 10
                        : sliderOptions.maxValue,
                minValue: sliderOptions.minValue || 0,
            }
        )
    }
}

export class PaddingTrait extends SliderTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        sliderOptions: SliderTraitOptions
    ) {
        super(
            TraitId.PADDING,
            {
                ...defaultOptions,
                value:
                    defaultOptions.value === undefined
                        ? 4
                        : defaultOptions.value,
            },
            {
                ...sliderOptions,
                maxValue:
                    sliderOptions.maxValue === undefined
                        ? 10
                        : sliderOptions.maxValue,
                minValue: sliderOptions.minValue || 0,
            }
        )
    }
}

export class BorderTrait extends SliderTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        sliderOptions: SliderTraitOptions
    ) {
        super(
            TraitId.BORDER,
            {
                ...defaultOptions,
                value:
                    defaultOptions.value === undefined
                        ? 1
                        : defaultOptions.value,
            },
            {
                ...sliderOptions,
                maxValue:
                    sliderOptions.maxValue === undefined
                        ? 10
                        : sliderOptions.maxValue,
                minValue: sliderOptions.minValue || 0,
            }
        )
    }
}

export class BandWidthTrait extends SliderTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        sliderOptions: SliderTraitOptions
    ) {
        super(
            TraitId.BAND_WIDTH,
            {
                ...defaultOptions,
                value:
                    defaultOptions.value === undefined
                        ? 10
                        : defaultOptions.value,
            },
            {
                ...sliderOptions,
                maxValue:
                    sliderOptions.maxValue === undefined
                        ? 20
                        : sliderOptions.maxValue,
                minValue: sliderOptions.minValue || 0,
            }
        )
    }
}

export class TitleHeightTrait extends SliderTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        sliderOptions: SliderTraitOptions
    ) {
        super(
            TraitId.TITLE_HEIGHT,
            {
                ...defaultOptions,
                value:
                    defaultOptions.value === undefined
                        ? 50
                        : defaultOptions.value,
            },
            {
                ...sliderOptions,
                maxValue:
                    sliderOptions.maxValue === undefined
                        ? 100
                        : sliderOptions.maxValue,
                minValue: sliderOptions.minValue || 0,
            }
        )
    }
}

export class FontTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions
    ) {
        super(TraitId.FONT, defaultOptions, {
            ...selectOptions,
            items: FONTS.map((font: string) => ({ id: font, alias: font })),
        })
    }
}

export class SidebarDesignTrait extends SelectTrait {
    constructor(
        defaultOptions: DefaultTraitOptions,
        selectOptions: SelectTraitOptions
    ) {
        super(TraitId.SIDEBAR_DESIGN, defaultOptions, {
            ...selectOptions,
            itemIds: [
                SidebarDesign.SIMPLE,
                SidebarDesign.SQUARE,
                SidebarDesign.UNDERLINE,
                SidebarDesign.TOP_BOTTOM,
                SidebarDesign.LEFT_RIGHT,
            ],
        })
    }
}
