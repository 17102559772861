import { TraitId } from "ponychart"
import { GlobalState, TraitSearch } from "@/ponychart/state"
import { memoizedInvertColor } from "@/ponychart/utils"



export class PonychartElementHelpers {
    static getStyledTemplateOptions(globalState: GlobalState, traitSearch: TraitSearch, colored?: boolean) {
        const styles: {[k: string]: string} = {  }
        if (colored && globalState.colors?.background) {
            styles.background = globalState.colors?.background
        }
        styles.color = memoizedInvertColor(colored ? globalState.colors.background : globalState.colors.light_background)
        const border = traitSearch.getTraitValue(TraitId.BORDER, 0) || "0"
        if (colored && globalState.colors?.border) styles.border = `${border}px solid ${globalState.colors?.border}`
        const margin = traitSearch.getTraitValue(TraitId.MARGIN, 0) || 0
        if (margin) styles.margin = `${margin}px`
        // if (!styles.padding) styles.padding = "4px"
        return styles
    }
}   