import {
    dictToStyle,
    toHtmlSafe,
    isBigSize,
    add,
    memoizedInvertColor,
    multiplyBy,
    substract,
    reduceObjectSize,
    hyphenObjectToDash,
    hyphenToDash,
} from '@/ponychart/utils'
import { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import { ChartContext } from '@/ponychart/chartContext/model'
import { SIDEBAR_BAND_WIDTH, SIDEBAR_HEIGHT } from '@/ponychart/header/config'
import { DynamicParameter } from '@/ponychart/dynamicParameter/model'
import { nanoid } from '@/ponychart/utils'
import { classFilter } from '@/ponychart/state/utils'
import { LineRow } from '@/ponychart/textElement'

import {
    ComponentType,
    ComponentAttributes,
    StyleType,
    PonychartComponent,
} from './types'
import { querySelectorClass, selectorFromClass } from './utils'
import { colAttr, rowAttr } from './config'
import { validatePonychartElement } from './errorChecker'
import { cleanAttributes, cleanObject } from './attributes'

import { ChartType, CHART_TYPES_SET, TraitId } from 'ponychart'
import { isEmpty, round } from 'lodash'

const TRAIT_EXPORT = [
    TraitId.LABEL,
    TraitId.TOOLTIP,
    TraitId.CHART_SUBTYPE,
    TraitId.LABEL_SIZE,
    TraitId.MEASURE,
    TraitId.MEASURE_2,
    TraitId.DIMENSION,
    TraitId.DIMENSION_2,
    TraitId.MEASURE_COLOR,
    TraitId.COLOR,
    TraitId.DATE_AGGREGATION_LEVEL,
    TraitId.GEO_COUNTRY,
    TraitId.GEO_REGION,
    TraitId.GEO_CITY,
] // Coupling with lambdas/tableau/model/component/model.py#ComponentAttributes

class LocalComponentStyles {
    protected _localComponentStyles?: { [k: string]: string }

    addLocalComponentStyle(styleKey: string, styleValue: string) {
        this._localComponentStyles = this._localComponentStyles || {}
        this._localComponentStyles[hyphenToDash(styleKey)] = styleValue
    }

    setLocalComponentStyles(styles: { [k: string]: string }) {
        this._localComponentStyles = hyphenObjectToDash(styles)
    }

    addLocalComponentStyles(styles: { [k: string]: string }) {
        this.setLocalComponentStyles({
            ...this.localComponentStyles,
            ...styles,
        })
    }

    protected get localComponentStyles() {
        return this._localComponentStyles || {}
    }
}

interface PonychartElementOpts {
    traitSearch?: TraitSearch
    children?: PonychartElement[]
    content?: string
    frontendSafeContent?: string
}

export abstract class PonychartElement extends LocalComponentStyles {
    public id = nanoid.id()
    protected contextes: ChartContext[] = []
    public customTag?: string
    public cardId?: string
    public text?: string
    public frontendSafeText?: string
    public chartType?: ChartType
    public traitSearch: TraitSearch
    public children: PonychartElement[]
    public fontRatio = 1
    private traitMap: { [k: string]: string | number } = {}
    private _attributes: { [k: string]: string | number } = {}
    width?: number
    height?: number
    protected lineRows?: LineRow[] = []
    blockResizePropagation = false
    isDarkBackground: null | boolean = null

    constructor(
        public componentType: ComponentType,
        public globalState: GlobalState,
        public localState: LocalState,
        opts: PonychartElementOpts = {}
    ) {
        super()
        this.children = opts.children || []
        this.traitSearch =
            opts.traitSearch || TraitSearch.fromGlobalState(globalState)
        if (CHART_TYPES_SET.has(componentType as ChartType)) {
            this.chartType = componentType as ChartType
            this.traitMap = this.traitSearch.getAttributeMap(
                componentType as ChartType,
                this.globalState.deviceType,
                this.localState.mainQuerySelectorTag
            )
        }
    }

    abstract compileHtml(): string

    get attributes() {
        if (isEmpty(this._attributes)) {
            if (this.chartType) {
                this.traitSearch.syncWith(this.globalState)
                this.traitSearch.chartType = this.chartType
                this.traitSearch.querySelectorTags =
                    this.localState.querySelectorTags
            }
            const attributes: { [k: string]: any } = {
                ...this.traitMap,
                ...this.localState.attributes,
            }
            if (this.text && this.text !== '|' && this.children.length === 0)
                attributes.text = this.text
            if (this.lineRows?.length) attributes.line_rows = this.lineRows
            if (this.contextes.length > 0) {
                attributes.contextes = JSON.stringify(
                    this.contextes.map((c) => c.toDb())
                )
            }
            this._attributes = Object.fromEntries(
                Object.entries(attributes).filter((entry) => !!entry[1])
            )
        }
        return this._attributes
    }

    getStringAttribute(traitId: TraitId) {
        const value = this.attributes[traitId]
        if (value !== undefined && typeof value !== 'string')
            throw new Error(`getStringAttribute
componentType: ${this.componentType}
traitId: ${traitId}
attributes: ${JSON.stringify(this.attributes, null, 2)}
traitMap: ${JSON.stringify(this.traitSearch.traitMap, null, 2)}`)
        return value
    }

    getStringRequiredAttribute(traitId: TraitId) {
        const value = this.attributes[traitId]
        if (typeof value !== 'string')
            throw new Error(`getStringRequiredAttribute
componentType: ${this.componentType}
traitId: ${traitId}
attributes: ${JSON.stringify(this.attributes, null, 2)}
traitMap: ${JSON.stringify(this.traitSearch.traitMap, null, 2)}`)
        return value
    }

    getNumberAttribute(traitId: TraitId) {
        const value = this.attributes[traitId]
        if (value !== undefined && typeof value !== 'number')
            throw new Error(`getNumberAttribute
componentType: ${this.componentType}
traitId: ${traitId}
attributes: ${JSON.stringify(this.attributes, null, 2)}
traitMap: ${JSON.stringify(this.traitSearch.traitMap, null, 2)}`)
        return value
    }

    getNumberRequiredAttribute(traitId: TraitId) {
        const value = this.attributes[traitId]
        if (typeof value !== 'number')
            throw new Error(`getNumberRequiredAttribute
componentType: ${this.componentType}
traitId: ${traitId}
attributes: ${JSON.stringify(this.attributes, null, 2)}
traitMap: ${JSON.stringify(this.traitSearch.traitMap, null, 2)}`)
        return value
    }

    get rawStyles() {
        return { ...this.localState.rawStyles, ...this.localComponentStyles }
    }

    get styles() {
        if (this.globalState.reduceSize > 1) {
            return reduceObjectSize(this.rawStyles, this.globalState.reduceSize)
        } else {
            return this.rawStyles
        }
    }

    get classes(): string[] {
        const classes = [...this.localState.localClasses]
        if (
            this.globalState.pageBlockId &&
            this.localState.querySelectorTags.length &&
            !this.globalState.disableQuerySelectorClass
        ) {
            const cls = querySelectorClass(
                this.globalState.pageBlockId,
                this.localState.querySelectorTags
            )
            if (!classes.includes(cls)) classes.push(cls)
        }
        if (this.globalState.pageBlockId)
            classes.push(`pb_${this.globalState.pageBlockId}`)
        if (this.globalState.pageId)
            classes.push(`p_${this.globalState.pageId}`)
        if (this.cardId) classes.push(`c_${this.cardId}`)
        return classFilter(
            classes,
            this.localState.isCol,
            this.localState.isFlex
        )
    }

    // For html/css delicate behaviour, we treat some chartTypes differently from the others
    get allowOppositeKeyReStyling(): boolean {
        return true // It seems to be deprecated
        // return ![ChartType.LOGO, ChartType.TIME_PERIOD_INDICATION].includes(this.componentType as ChartType)
    }

    get ratios() {
        const ratios =
            this.globalState.ratios?.[this.localState.mainQuerySelectorTag]
        return ratios
            ? {
                  height: Math.round(ratios.height * 100) / 100,
                  width: Math.round(ratios.width * 100) / 100,
              }
            : {}
    }

    setCustomTag(customTag?: string) {
        this.customTag = customTag
        return this
    }

    setChildren(children: PonychartElement[]) {
        this.children = children
    }

    setContextes(contextes: ChartContext[]) {
        console.error('setContextes badly configured', contextes)
        throw new Error('Called setContextes on non-Title element (forbidden)')
        return this
    }

    setParameters(parameters: DynamicParameter[]) {
        console.error('setParameter badly configured', parameters)
        throw new Error(
            'Called setParameters on non-PageHeaderBlock element (forbidden)'
        )
        return this
    }

    pushParameters(...parameters: DynamicParameter[]) {
        console.error('setParameter badly configured', parameters)
        throw new Error(
            'Called setParameters on non-Kpi Card element (forbidden)'
        )
    }

    // TODO: better typing
    compileAllComponents(): PonychartComponent[] {
        const component = this.compileComponent()
        if (this.children.length === 0) return [component]
        return [
            {
                ...component,
                components: this.children.reduce(
                    (acc: any[], child) => [
                        ...acc,
                        ...child.compileAllComponents(),
                    ],
                    []
                ),
            },
        ]
    }

    // TODO: better typing
    protected compileComponent(): PonychartComponent {
        validatePonychartElement(this)
        const output = {
            id: this.id,
            type: this.componentType,
            classes: this.classes,
            attrs: cleanAttributes(
                this.attributes || {},
                this.chartType
                    ? [...TRAIT_EXPORT, 'id', 'text', 'contextes']
                    : [
                          'id',
                          'text',
                          'contextes',
                          'color',
                          'measure_color',
                          'line_rows',
                      ]
            ),
            loc: {
                q: this.localState.querySelectorTags,
                pId: this.globalState.pageId,
                blockId: this.globalState.pageBlockId,
                twbIdx: this.globalState.twbIdx,
            },
        }
        cleanObject(output.attrs)
        cleanObject(output.loc)
        cleanObject(output)
        return output as PonychartComponent
    }

    getPageBlockIds(): Set<string> {
        const pageBlockIds = new Set([this.globalState.pageBlockId])
        for (const child of this.children) {
            for (const pageBlockId of child.getPageBlockIds()) {
                pageBlockIds.add(pageBlockId)
            }
        }
        return pageBlockIds
    }

    compileAllHtml(): string {
        const html = this.compileHtml()

        if (this.children.length === 0) return html.split('|').join('')

        const innerHtml = this.children.reduce(
            (acc: string, child: PonychartElement) =>
                acc + child.compileAllHtml(),
            ''
        )

        return html.split('|').join(innerHtml)
    }

    // Deprecated?
    compileAllCss(): Set<string> {
        const css = this.compileCss()
        if (this.children.length === 0) return new Set(css)
        const output: Set<string> = new Set(css)
        for (const child of this.children) {
            const css = typeof child === 'string' ? '' : child.compileAllCss()
            for (const c of css) {
                output.add(c)
            }
        }
        return output
    }

    protected compileCss(): string[] {
        const output = []
        const styles = this.styles
        if (!isEmpty(styles)) {
            output.push(dictToStyle(this.id, styles, '#'))
        }
        return output
    }

    compileAllStyles(): any[] {
        const styles = this.compileStyles()
        if (this.children.length === 0) return styles
        const allStyles = this.children.reduce(
            (acc: any[], child) => [...acc, ...child.compileAllStyles()],
            styles
        )
        const selectors = new Set()
        const output = []
        for (const style of allStyles) {
            const selectorNames = (style.selectors || []).map(
                (s: string | { name: string }) =>
                    typeof s === 'string' ? s : s.name
            )
            const isNew = selectorNames.every(
                (selector: string) => !selectors.has(selector)
            )
            if (isNew) {
                selectorNames.forEach((selector: string) =>
                    selectors.add(selector)
                )
                output.push(style)
            }
        }
        return output
    }

    protected compileStyles(): StyleType[] {
        const output: StyleType[] = []
        if (!isEmpty(this.styles)) {
            output.push({
                selectors: [`#${this.id}`],
                style: this.styles,
            })
        }
        return output
    }

    compile(keys: ('css' | 'html' | 'styles' | 'components' | 'parameters')[]) {
        return {
            styles: keys.includes('styles')
                ? this.compileAllStyles()
                : undefined,
            components: keys.includes('components')
                ? this.compileAllComponents()
                : undefined,
            css: keys.includes('css') ? this.compileAllCss() : undefined,
            html: keys.includes('html') ? this.compileAllHtml() : undefined,
            parameters: keys.includes('parameters')
                ? DynamicParameter.listFromElement(this)
                : undefined,
        }
    }

    unshift(child: PonychartElement) {
        this.children.unshift(child)
    }

    push(child: PonychartElement): void {
        this.children.push(child)
    }

    get length() {
        return this.children.length
    }

    onMountHook(): void {
        throw new Error('onMountHook is not implemented')
    }

    mount(
        opts: {
            processedParameters?: DynamicParameter[]
            isDarkBackground?: boolean
        } = {}
    ) {
        this.isDarkBackground = this.styles.background
            ? memoizedInvertColor(this.styles.background) === '#FFF'
            : opts.isDarkBackground || null
        if (
            ![ChartType.ROW, ChartType.COLUMN].includes(
                this.componentType as ChartType
            )
        ) {
            return this
        }
        const isFlex = this.localState.isFlex
        const ratio = `${round(100 / this.children.length, 2)}%`
        const key = this.componentType === ChartType.ROW ? 'width' : 'height'
        const oppositeKey =
            this.componentType === ChartType.ROW ? 'height' : 'width'
        for (const child of this.children) {
            if (!isFlex) {
                child.mount(opts)
                continue
            }

            const margin = child.localState.rawStyles.margin || '0px'
            const border = child.localState.rawStyles.border || '0px'

            // const calc = multiplyBy(margin, 2)
            const calc = add(margin, border)
            const stylePayload: { [k: string]: string } = {
                [key]:
                    margin === '0px'
                        ? ratio
                        : `calc(${ratio} - ${calc} )!important`,
                flexBasis: `${ratio} !important`,
            }
            if (child.allowOppositeKeyReStyling) {
                stylePayload[oppositeKey] =
                    margin === '0px'
                        ? '100%'
                        : `calc(100% - ${calc} )!important`
            }
            child.addLocalComponentStyles(stylePayload)
            child.mount({
                ...opts,
                isDarkBackground:
                    this.isDarkBackground === null
                        ? undefined
                        : this.isDarkBackground,
            })
        }
        return this
    }
}

export class Container extends PonychartElement {
    protected _unsafe = false

    constructor(
        chartType:
            | ChartType.COLUMN
            | ChartType.ROW
            | ChartType.NAVIGATION_BUTTON
            | ChartType.NONE,
        globalState: GlobalState,
        localState: LocalState,
        opts: PonychartElementOpts & {
            unsafe?: boolean
            fontRatio?: number
        } = {}
    ) {
        const isCol = chartType === ChartType.COLUMN
        super(
            chartType,
            globalState,
            localState.isCol === isCol
                ? localState
                : localState.copy().setIsCol(isCol),
            opts
        )
        this._unsafe = opts.unsafe || false
        this.fontRatio = opts.fontRatio || 1
        this.text = opts.content || '|'
        this.frontendSafeText = opts.frontendSafeContent
    }

    compileHtml() {
        const text =
            this.frontendSafeText === undefined
                ? this.text
                : this.frontendSafeText
        return `<div id="${this.id}" class="${(this.classes || []).join(
            ' '
        )}" title="${this.componentType}">${
            this._unsafe ? text : toHtmlSafe(text || '')
        }</div>`
    }

    get component(): ComponentAttributes {
        const attr = this.componentType === ChartType.ROW ? rowAttr : colAttr
        return {
            type: this.componentType,
            'stylable-require': attr['data-gjs-stylable-require'],
            unstylable: attr['data-gjs-unstylable'],
            resizable: attr['data-gjs-resizable'],
            classes: this.classes.map(selectorFromClass),
            attributes: { id: this.id, title: this.componentType },
            'custom-name': 'Container',
        }
    }
}

// TODO: Reproduce exactly the style parameters of a Row
// when in FLEX mode
export class RowContainer extends Container {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        opts: PonychartElementOpts & {
            unsafe?: boolean
            fontRatio?: number
        } = {}
    ) {
        super(ChartType.ROW, globalState, localState, opts)
    }
}

export class ColumnContainer extends Container {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        opts: PonychartElementOpts & {
            unsafe?: boolean
            fontRatio?: number
        } = {}
    ) {
        super(ChartType.COLUMN, globalState, localState, opts)
    }
}

const LINE_HEIGHTS = [0, 1, 2].reduce(
    (acc: { [k: number]: string }, i: number) => ({
        ...acc,
        [i]: substract(SIDEBAR_HEIGHT, multiplyBy(SIDEBAR_BAND_WIDTH, i)),
    }),
    {}
)

export class ClickedButtonContainer extends Container {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        content?: string
    ) {
        super(ChartType.ROW, globalState, localState, {
            content: content || '',
            fontRatio: 0.5,
        })
    }

    static getStyles(background: string, height?: string, margins?: 0 | 1 | 2) {
        return {
            background: background,
            height: height || `${SIDEBAR_HEIGHT}px`,
            lineHeight: LINE_HEIGHTS[margins || 0],
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: `${SIDEBAR_HEIGHT / 2}px`,
            cursor: 'pointer',
            color: memoizedInvertColor(background),
        }
    }
}

export class ButtonComponent extends Container {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        content: string
    ) {
        super(ChartType.NAVIGATION_BUTTON, globalState, localState, {
            content,
            fontRatio: 0.5,
        })
    }

    static listClasses(reduceSize: number, index: number) {
        return [
            `d-index-${index || 0}`,
            ...(isBigSize(reduceSize) ? ['d-btn'] : []),
        ]
    }

    static getStyles(background: string, height?: string) {
        return {
            background,
            height: height || `${SIDEBAR_HEIGHT}px`,
            lineHeight: LINE_HEIGHTS[0],
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: `${SIDEBAR_HEIGHT / 2}px`,
            cursor: 'pointer',
            color: memoizedInvertColor(background),
        }
    }
}
