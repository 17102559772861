import {
    getBands,
    getColorForElement,
    isBigSize,
    svgMatrixFromNumeric,
} from '@/ponychart/utils'
import { SVGChart } from '@/ponychart/svg/model'
import { BandMode, MeasureColorLocation } from '@/ponychart/trait'
import type { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import { RandomMemory, SourceMemory } from '@/ponychart/memoize'
import { PonychartElement } from '@/ponychart/element/model'

import { TraitId, ChartType } from 'ponychart'

// import { Position } from './types'
import { initTooltip } from './utils'

const sourceMemory = SourceMemory.getInstance()

function _buildAreaChart(element: PonychartElement) {
    if (element.globalState.onlyContainer)
        return SVGChart.onlyContainer(element.id, element.classes)
    const svgChart = new SVGChart(ChartType.AREA, element.ratios)

    const measure = element.getStringRequiredAttribute(TraitId.MEASURE)

    const dimension = element.getStringAttribute(TraitId.DIMENSION)

    const memory = RandomMemory.getInstance()

    const INCREMENT = svgChart.height * 0.05

    const numericValues = dimension
        ? memory.getCumulatedMatrix(measure, dimension)
        : [memory.getNumericArray(measure, dimension || '')]

    const matrixValues = svgMatrixFromNumeric(numericValues, {
        height: svgChart.height,
        width: svgChart.width,
        maxAxisValue: Math.max(...numericValues.map((row) => Math.max(...row))),
    })

    const { html: tooltipHtml, id: tooltipId } = initTooltip(element, {
        measureId: measure,
        dimensionId: dimension || '',
    })

    const colors = dimension
        ? memory.getColors(dimension)
        : [
              getColorForElement(
                  element,
                  sourceMemory,
                  MeasureColorLocation.CURVES,
                  { measure }
              ),
          ]

    for (let i = 0; i < matrixValues.length; i++) {
        svgChart.area(
            matrixValues[i],
            i === 0
                ? matrixValues[i].map((v) => ({ ...v, value: 0 }))
                : matrixValues[i - 1],
            colors[i % colors.length],
            {
                tooltipId,
                offsetY: INCREMENT,
                opacity: !dimension ? 0.7 : 1,
                thisMeasure: numericValues[i][0],
                lastMeasure: numericValues[i][0],
            }
        )
    }

    if (element.getStringAttribute(TraitId.CHART_SUBTYPE) === BandMode.BAND) {
        for (const [s, e] of getBands(svgChart.width, svgChart.height)) {
            svgChart.rectangle(s, e, 'gray', {
                opacity: 0.5,
            })
            svgChart.line(
                s,
                { x: s.x, y: e.y },
                { strokeDashArray: '2,2', width: 0.5 }
            )
            svgChart.line(
                e,
                { x: e.x, y: s.y },
                { strokeDashArray: '2,2', width: 0.5 }
            )
        }
    }

    return (
        svgChart.rawSvg(element.id, {
            classes: element.classes,
            viewBox: element.ratios,
        }) + tooltipHtml
    )
}

export class AreaChart extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.AREA, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return _buildAreaChart(this)
    }
}
