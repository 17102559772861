import { Store } from './utils'
import CheckoutService from '@/services/checkoutService'

export default {
  getSubscriptions({ commit }: Store, deep: boolean) {
    return CheckoutService.listSubscriptions(deep).then(subscriptions => {
      commit('SET', {
        subscriptions
      })
      return subscriptions
    })
  }
}
