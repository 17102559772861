import { SVG, Svg } from '@svgdotjs/svg.js'

import { Position } from '@/ponychart/charts/types'
import {
    memoizedInvertColor,
    SvgPosition,
    TABLEAU_COLORS,
} from '@/ponychart/utils'
import { ChartType } from 'ponychart'

import { wrapAttributes, wrapSvgContent } from './utils'

const FORMATTED_NUMBERS = ['#.###,#', '##', '##,##', '#', '##,#'] as const
const FORMATTED_PREFIXES = ['€', '$', 'M', 'K', 'G'] as const
const TRANSPARENT = ':hover {opacity: 0.8}; cursor: pointer' as const

export class SVGChart {
    private _svg: Svg
    private _WIDTH: number
    private _HEIGHT: number
    private _background: string
    constructor(
        public chartType: ChartType,
        opts: {
            background?: string
            width?: number
            height?: number
        } = {}
    ) {
        this._WIDTH = opts?.width || 100
        this._HEIGHT = opts?.height || 100
        this._svg = SVG()
        this._svg.size(this._HEIGHT, this._WIDTH)
        this._background = opts?.background || '#fff'
    }

    get width() {
        return this._WIDTH
    }

    get height() {
        return this._HEIGHT
    }

    setWidth(width?: number) {
        if (width) this._WIDTH = width
        this._svg.size(this._HEIGHT, this._WIDTH)
        return this
    }
    setHeight(height?: number) {
        if (height) this._HEIGHT = height
        this._svg.size(this._HEIGHT, this._WIDTH)
        return this
    }

    viewbox() {
        this._svg.viewbox(0, 0, this._WIDTH, this._HEIGHT)
        return this
    }

    nested() {
        return this._svg.nested()
    }

    line(
        position1: Position,
        position2: Position,
        opts: {
            color?: string
            width?: number
            strokeDashArray?: string
            tooltipId?: string
            thisMeasure?: number
            lastMeasure?: number
        } = {}
    ) {
        const { x: x1, y: y1 } = position1
        const { x: x2, y: y2 } = position2
        const el = this._svg
            .line(x1, y1, x2, y2)
            .stroke({
                width: opts?.width || 0.2,
                color: opts?.color || '#000',
                dasharray: opts?.strokeDashArray,
            })
            .attr('stroke-linecap', 'round')
            .attr('style', TRANSPARENT)
        this.applyEvent(
            el,
            { thisMeasure: opts.thisMeasure, lastMeasure: opts.lastMeasure },
            opts?.tooltipId
        )
        return this
    }

    triangle(
        position: Position,
        size: number,
        direction: 'right' | 'left' | 'top' | 'bottom' = 'right',
        color: string
    ) {
        let x = position.x
        let y = position.y
        const sqrt = (Math.sqrt(3) / 2) * size
        let ax: number, ay: number, bx: number, by: number
        switch (direction) {
            case 'right':
                x += sqrt / 2
                ax = x - sqrt
                ay = y + sqrt / 2
                bx = ax
                by = y - sqrt / 2
                break
            case 'left':
                x -= sqrt / 2
                ax = x + sqrt
                ay = y + sqrt / 2
                bx = ax
                by = y - sqrt / 2
                break
            case 'top':
                y -= sqrt / 2
                ax = x - sqrt / 2
                ay = y + sqrt
                bx = x + sqrt / 2
                by = ay
                break
            case 'bottom':
                y += sqrt / 2
                ax = x - sqrt / 2
                ay = y - sqrt
                bx = x + sqrt / 2
                by = ay
                break
        }
        return this.polygon(`${x},${y} ${ax},${ay} ${bx},${by}`, color)
    }

    polygon(
        path: string,
        color: string,
        opts: {
            tooltipId?: string
            opacity?: number
            thisMeasure?: number
            lastMeasure?: number
        } = {}
    ) {
        const el = this._svg.polygon(path).fill(color)
        if (opts?.opacity !== undefined)
            el.attr('style', `opacity: ${opts.opacity}`)
        this.applyEvent(
            el,
            { thisMeasure: opts.thisMeasure, lastMeasure: opts.lastMeasure },
            opts?.tooltipId
        )
        return this
    }

    area(
        topValues: SvgPosition[],
        bottomValues: SvgPosition[],
        color: string,
        opts: {
            tooltipId?: string
            offsetY?: number
            opacity?: number
            thisMeasure?: number
            lastMeasure?: number
        } = {}
    ) {
        const offsetY = opts.offsetY || 0
        const path = []
        for (const { y, value } of topValues) {
            path.push(`${y},${this._HEIGHT - value - offsetY}`)
        }
        for (const { y, value } of bottomValues.reverse()) {
            path.push(`${y},${this._HEIGHT - value - offsetY}`)
        }

        return this.polygon(path.join(' '), color, opts)
    }

    circle(
        position: Position,
        radius: number,
        color: string,
        opts: {
            transparent?: boolean
            tooltipId?: string
            thisMeasure?: number
            lastMeasure?: number
        } = {}
    ) {
        // let style = this.TRANSPARENT
        const el = this._svg
            .circle(radius * 2)
            .fill(color)
            .move(position.x, position.y)
        if (opts?.transparent) el.attr('style', TRANSPARENT)
        this.applyEvent(
            el,
            { thisMeasure: opts.thisMeasure, lastMeasure: opts.lastMeasure },
            opts?.tooltipId
        )
        return this
    }

    text(
        text: string,
        position: Position,
        opts: {
            rotate?: number
            size?: number
            color?: string
            font?: string
        } = {}
    ) {
        const el = this._svg
            .text(text)
            .move(position.x, position.y)
            .attr('text-anchor', 'middle')
            .attr('fill', opts?.color || '#000')
            .attr('font-size', String(opts?.size || 4))
        if (opts.font) el.attr('font-family', opts.font)
        if (opts?.rotate) el.rotate(opts?.rotate || -90)
        return this
    }

    evolution(
        position: Position,
        direction: 'top' | 'bottom',
        opts: { delta?: number; size?: number } = {}
    ) {
        const color =
            direction === 'top' ? TABLEAU_COLORS.blue : TABLEAU_COLORS.red
        const sign = direction === 'top' ? '+' : '-'
        const size = opts?.size || 3.5
        const delta = opts?.delta || 4.5
        return this.triangle(
            { x: position.x, y: position.y + delta },
            size,
            direction,
            color
        ).text(
            sign + '#%',
            { x: position.x + (delta * size) / 3, y: position.y - 2 * delta },
            {
                size,
                color,
            }
        )
    }

    formattedNumber(
        position: Position,
        size: number,
        opts?: { text?: string; color?: string; font?: string }
    ) {
        if (opts?.text) {
            this.text(opts.text, position, {
                size,
                color: opts?.color,
                font: opts?.font,
            })
            return opts.text
        }
        const n =
            FORMATTED_NUMBERS[
                Math.floor(Math.random() * FORMATTED_NUMBERS.length)
            ]
        const f =
            FORMATTED_PREFIXES[
                Math.floor(Math.random() * FORMATTED_PREFIXES.length)
            ]
        this.text(n + f, position, {
            size,
            color: opts?.color,
            font: opts?.font,
        })
        return n + f
    }

    rect(
        x: number,
        y: number,
        width: number,
        height: number,
        color: string,
        opts: {
            opacity?: number
            transparent?: boolean
            tooltipId?: string
            thisMeasure?: number
            lastMeasure?: number
        } = {
            opacity: 1,
            transparent: false,
        }
    ) {
        const el = this._svg.rect(width, height).fill(color).move(x, y)
        const style = []
        if (opts?.transparent) style.push(TRANSPARENT)
        if (opts?.opacity !== undefined) style.push(`opacity: ${opts.opacity}`)
        el.attr('style', style.join(';'))
        this.applyEvent(
            el,
            { thisMeasure: opts.thisMeasure, lastMeasure: opts.lastMeasure },
            opts?.tooltipId
        )
        return this
    }

    rectangle(
        position1: Position,
        position2: Position,
        color: string,
        opts: {
            opacity?: number
            transparent?: boolean
            tooltipId?: string
            thisMeasure?: number
            lastMeasure?: number
        } = {
            opacity: 1,
            transparent: false,
        }
    ) {
        const width = position2.x - position1.x
        const height = position2.y - position1.y
        const el = this._svg
            .rect(width, height)
            .fill(color)
            .move(position1.x, position1.y)
        const style = []
        if (opts?.transparent) style.push(TRANSPARENT)
        if (opts?.opacity !== undefined) style.push(`opacity: ${opts.opacity}`)
        el.attr('style', style.join(';'))
        this.applyEvent(
            el,
            { thisMeasure: opts.thisMeasure, lastMeasure: opts.lastMeasure },
            opts?.tooltipId
        )
        return this
    }

    private applyEvent(el: any, payload: any, tooltipId?: string) {
        if (tooltipId) {
            el.attr(
                'onmousemove',
                `s(evt, '${tooltipId}', ${JSON.stringify(payload)});`
            )
            el.attr('onmouseout', `h();`)
        }
    }

    static getEvoValues(values: any[]) {
        const output = []
        for (let i = 0; i < values.length; i++) {
            output.push(Math.random() - 0.5)
        }
        return output
    }

    static onlyContainer(id: string, classes: string[]) {
        return wrapAttributes(
            'div',
            '<div style="background: grey; height: 100%; width: 100%; margin: 0; opacity: 0.5; border-radius: 5%"></div>',
            {
                id,
                class: classes.join(' '),
                style: 'margin: auto; max-width: 100%; max-height: 100%; height: 100%; width: 100%; padding: 2%',
            }
        )
    }

    rawSvg(
        id: string,
        opts: {
            classes: string[]
            attributes?: { [k: string]: string }
            viewBox?: { height?: number; width?: number } | string
            preserveAspectRatio?: {
                x: 'Min' | 'Max' | 'Mid'
                y: 'Min' | 'Max' | 'Mid'
                suffix: 'meet' | 'slice'
            }
            style?: { [k: string]: string }
        },
        content?: string
    ) {
        const pAR = opts.preserveAspectRatio || {
            x: 'Mid',
            y: 'Mid',
            suffix: 'meet',
        }
        const attributes = opts.attributes || {}
        const style = opts.style || {}
        return wrapAttributes('svg', content ? content : this._svg.svg(false), {
            ...attributes,
            id,
            class: opts.classes.join(' '),
            style: Object.entries(style)
                .map(([k, v]) => `${k}: ${v}`)
                .join('; '),
            viewBox:
                typeof opts.viewBox === 'string'
                    ? opts.viewBox
                    : `0 0 ${opts.viewBox?.width || 100} ${
                          opts.viewBox?.height || 100
                      }`,
            preserveAspectRatio: `x${pAR.x}Y${pAR.y} ${pAR.suffix}`,
        })
    }

    svg(
        opts: {
            onlyContainer?: boolean
            preserveAspectRatio?: {
                x: 'Min' | 'Max' | 'Mid'
                y: 'Min' | 'Max' | 'Mid'
                suffix: 'meet' | 'slice'
            }
            style?: { [k: string]: string }
            classes?: string[]
            id?: string
        } = {}
    ) {
        const classes = opts?.classes || ['da-container', 'da-flex']
        const mainStyle = {
            // display: 'inline-flex',
            'max-height': '100%',
            'max-width': '100%',
            // 'height': '100%',
            // 'width': '100%',
            'min-height': '0',
            'min-width': '0',
            position: 'relative',
        }
        const style = {
            ...mainStyle,
            fill: memoizedInvertColor(this._background),
            ...(opts?.style || {}),
            ...(!opts?.onlyContainer ? { cursor: 'pointer' } : {}),
        }
        const attributes = {
            overflow: 'scroll',
            width: '100%',
        }
        return wrapSvgContent(this._svg.svg(false), {
            attributes,
            style,
            preserveAspectRatio: opts?.preserveAspectRatio,
            classes,
            onlyContainer: opts?.onlyContainer,
            viewBox: {
                x: this._WIDTH,
                y: this._HEIGHT,
            },
            title: this.chartType,
            id: opts?.id,
        })
    }
}
