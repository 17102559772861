<template lang="pug">
v-row(align='center', justify='center')
    v-col(sm='10', xs='12')
        //- v-row
        //-   h1.primary--text Services
        v-row(align='start', justify='space-around')
            v-card(
                rounded,
                dark,
                :max-width='isSmall ? 350 : 400',
                :key='i',
                v-for='(card, i) in cards',
                :class='"white my-4 rounded-lg"'
            )
                v-card-title.primary
                    v-icon.mr-2(large) {{ card.icon }}
                    span {{ $t(`cards.${card.action}.title`) }}
                v-card-text.ma-0.pa-0
                    v-img.card__image.ma-0.pa-0(
                        contain,
                        :max-height='isSmall ? "190px" : "220px"',
                        :src='getImgUrl(card.image)'
                    )
                        .fill-height.bottom-gradient
                    svg.card__svg(viewBox='0 0 800 500')
                        path.card__line(
                            d='M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 ',
                            stroke='transparent',
                            fill='#673AB7'
                        )
                v-card-actions.primary.mt-n12.card__actions
                    //- v-btn(text, @click='action(card.action)') {{ $t(`cards.${card.action}.button`) }}
                    v-btn(
                        v-if='card.buy_link',
                        depressed,
                        outlined,
                        @click='$store.commit("TOGGLE_EVENT", "payment")'
                    )
                        span {{ $t(card.buy_link) }}
                        v-icon exit_to_app
                    v-spacer
                    v-btn(icon, @click='card.show = !card.show')
                        v-icon {{ card.show ? "mdi-chevron-up" : "mdi-chevron-down" }}
                v-expand-transition
                    .primary(v-show='card.show')
                        v-divider
                        v-card-text {{ $t(`cards.${card.action}.description`) }}
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'cardsComponent',
    methods: {
        getImgUrl(im) {
            const images = require.context('../../assets/', false, /\.png$/)
            return images('./' + im + '.png')
        },
        action(type) {
            if (type == 'consulting') {
                /*eslint-disable */
                Calendly.showPopupWidget(
                    `https://calendly.com/data-pony/first-meeting?name=${this.userName}&email=${this.email}&a1=`
                )
            } else if (type == 'connectors') {
                this.goTo('/')
            } else {
                this.goTo('/')
            }
        },
    },
    computed: {
        ...mapGetters(['email', 'userName']),
        isSmall() {
            return this.$vuetify.breakpoint.name == 'xs'
        },
    },
    data: () => ({
        cards: [
            {
                title: 'Dashboards tokens',
                icon: 'insert_chart_outlined',
                class: 'info',
                image: 'magic_2',
                action: 'dashboards',
                color: '#00bcd4',
                description: '',
                show: false,
                button: 'Create',
                buy_link: 'profile_page.buy',
            },
            // {
            //   title: 'Consulting',
            //   icon: 'supervisor_account',
            //   class: 'warning',
            //   image: 'desk',
            //   action: 'consulting',
            //   color: '#ffc107',
            //   description: '',
            //   show: false,
            //   button: 'Schedule',
            //   buy_link: 'profile_page.payment',
            // },
            // {
            //   title: 'Connectors',
            //   icon: 'account_tree',
            //   class: 'error',
            //   image: 'teach',
            //   action: 'connectors',
            //   color: '#f44336',
            //   description: '',
            //   show: false,
            //   button: 'Connect!',
            // },
        ],
    }),
}
</script>

<style scoped>
.card__line {
    opacity: 0;
    animation: LineFadeIn 0.8s 2.4s forwards ease-in;
}
.card__image {
    opacity: 0;
    animation: ImageFadeIn 6s 0.4s forwards;
    border-radius: 4px;
}
.card__title {
    color: white;
    margin-top: 0;
    font-weight: 800;
    letter-spacing: 0.01em;
}
.card__content {
    margin-top: -1rem;
    opacity: 0;
    animation: ContentFadeIn 1s 2.4s forwards;
}
.card__actions {
    margin-top: -1rem;
    opacity: 0;
    animation: ContentFadeIn 0.8s 2.4s forwards;
}
.card__svg {
    position: absolute;
    left: 0;
    top: 60px;
}

@keyframes LineFadeIn {
    0% {
        opacity: 0;
        d: path(
            'M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 '
        );
    }
    50% {
        opacity: 1;
        d: path(
            'M 0 300 Q 50 300 100 300 Q 250 300 350 300 C 350 300 500 300 650 300 Q 750 300 800 300 L 800 425 L 0 425'
        );
    }
    100% {
        opacity: 1;
        d: path(
            'M -2 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 420 802 300 L 800 425 L 0 425'
        );
    }
}
@keyframes ContentFadeIn {
    0% {
        transform: translateY(-1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes ImageFadeIn {
    0% {
        transform: translate(-0.5rem, -0.5rem) scale(1.05);
        opacity: 0;
        filter: blur(2px);
    }
    20% {
        opacity: 1;
        filter: blur(2px);
    }
    40% {
        transform: translateY(0) scale(1);
        opacity: 1;
        filter: blur(0);
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
        filter: blur(0);
        background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.1) 100%
        );
    }
}
.fade-enter-active {
    transition: all 0.8s;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(60px);
}
</style>