import { TABLEAU_BLUE, TABLEAU_RED } from '@/ponychart/utils'
import { TraitOptionItem, TimeSelectorType, ComparisonPeriod } from 'ponychart'

export interface BuildTooltipOpts {
    measureId: string
    measures: TraitOptionItem[]
    dimensionId: string
    dimensions: TraitOptionItem[]
    value: number
    evoValue: number
    noColor: boolean
    formats: any[]
    background: string
}

export enum Att {
    BOLD = 'b',
    X_LARGE = 'xl',
    LARGE = 'l',
    SMALL = 's',
    X_SMALL = 'xs',
    NO_COLOR = 'nc',
    ITALIC = 'i',
    NORMAL = 'n',
}

export enum Tag {
    CURRENT_DATE = 'current_date',
    VALUE = 'value',
    THIS_VALUE = 'this_value',
    LAST_VALUE = 'last_value',
    THIS_PERIOD = 'this_period',
    LAST_PERIOD = 'last_period',
    MEASURE_CHOICE = 'mc',
    MEASURE_NAME = 'm',
    DIMENSION_CHOICE = 'dc',
    DIMENSION_NAME = 'd',
    DATE = 'date',
    BR = 'br',
    DIFFERENCE = 'dif',
    EVOLUTION = 'evo',
    TEXT = 't',
    COMPARISON_PERIOD = 'comp',
}

export enum LabelId {
    LABEL_VALUE = 'label_value',
    DATE_VALUE = 'date_value',
    LABEL_THIS_VALUE = 'label_this_value',
    LABEL_THIS_VALUE_EVO = 'label_this_value_evo',
    LABEL_THIS_VALUE_EVO_COLORED = 'label_this_value_evo_colored',
    LABEL_THIS_VALUE_DIFF = 'label_this_value_diff',
    LABEL_THIS_VALUE_DIFF_COLORED = 'label_this_value_diff_colored',
    LABEL_THIS_VALUE_EVO_DIFF = 'label_this_value_evo_diff',
    LABEL_THIS_VALUE_EVO_DIFF_COLORED = 'label_this_value_evo_diff_colored',
    LABEL_EVO = 'label_evo',
    LABEL_EVO_COLORED = 'label_evo_colored',
    LABEL_DIFF = 'label_diff',
    LABEL_DIFF_COLORED = 'label_diff_colored',
    LABEL_EVO_DIFF = 'label_evo_diff',
    LABEL_EVO_DIFF_COLORED = 'label_evo_diff_colored',
    RICH_KPI = 'rich_kpi',
    RICH_KPI_COLORED = 'rich_kpi_colored',
    STANDARD_KPI = 'standard_kpi',
    STANDARD_KPI_COLORED = 'standard_kpi_colored',
    SIMPLE_KPI = 'simple_kpi',
    SIMPLE_KPI_COLORED = 'simple_kpi_colored',
    STANDARD_TOOLTIP = 'standard_tooltip',
    STANDARD_TOOLTIP_COLORED = 'standard_tooltip_colored',
    DATE_TOOLTIP = 'date_tooltip',
    TIME_PERIOD_INDICATION = 'time_period_indication',
    MAP_TOOLTIP = 'map_tooltip',
    MAP_TOOLTIP_COLORED = 'map_tooltip_colored',
    CARD_TITLE = 'card_title',
    NONE = 'none',
}

export enum LabelCentering {
    TOP_LEFT = 'top_left',
    TOP_CENTER = 'top_center',
    TOP_RIGHT = 'top_right',
    MIDDLE_LEFT = 'middle_left',
    MIDDLE_CENTER = 'middle_center',
    MIDDLE_RIGHT = 'middle_right',
    BOTTOM_LEFT = 'bottom_left',
    BOTTOM_CENTER = 'bottom_center',
    BOTTOM_RIGHT = 'bottom_right',
}

export interface TextElementPayload {
    font: string
    reduceSize: number
    timeSelectorType: TimeSelectorType
    text?: string
    comparisonPeriods?: ComparisonPeriod[]
    color?: typeof TABLEAU_BLUE | typeof TABLEAU_RED | '#000' | '#FFF'
    isNegative?: boolean
    mainFormat?: string
    sizeRatio?: number
}
