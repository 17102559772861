import { TABLEAU_COLORS } from './config'
import { round } from 'lodash'
import type { GlobalState, TraitSearch } from '@/ponychart/state'
import type { SourceMemory } from '@/ponychart/memoize/sourceMemory'
import { MeasureColorLocation } from '@/ponychart/trait/types'
import { DeviceType, TraitId } from 'ponychart'
import { PonychartElement } from '../element'

export function sToN(s: string | number): number {
    const matches = String(s)
        .split(' ')
        .map((r) => parseFloat(r.replace('px', '')))
        .filter((r) => !!r)
    return Number(matches.length ? matches[0] : 0)
}

export function nToS(n: number | string, reduceSize = 1) {
    if (typeof n === 'string') return n
    return String(round(n * reduceSize, 4)) + 'px'
}

export function substract(
    string1: number | string,
    ...string2: (number | string)[]
) {
    const n1 = sToN(string1)
    const n2 = string2.map((s) => sToN(s)).reduce((prev, acc) => prev + acc, 0)
    return nToS(n1 - n2)
}

export function add(string1: number | string, ...string2: (number | string)[]) {
    const n1 = sToN(string1)
    const n2 = string2.map((s) => sToN(s)).reduce((prev, acc) => prev + acc, 0)
    return nToS(n1 + n2)
}

export function divideBy(
    string1: number | string,
    ...string2: (number | string)[]
) {
    const n1 = sToN(string1)
    const n2 = string2.map((s) => sToN(s)).reduce((prev, acc) => prev * acc, 1)
    return nToS(n1 / n2)
}

export function multiplyBy(
    string1: number | string,
    ...string2: (number | string)[]
) {
    const n1 = sToN(string1)
    const n2 = string2.map((s) => sToN(s)).reduce((prev, acc) => prev * acc, 1)
    return nToS(n1 * n2)
}

export function reduceStyleSize(s: string, reduceSize = 10) {
    if (!s) return ''
    if (reduceSize === 1) return s
    const words = s.split(' ')
    const output = []
    for (const word of words) {
        if (word.endsWith('px')) {
            const n = Number(word.replace('px', ''))
            output.push(`${n / reduceSize}px`)
        } else {
            output.push(word)
        }
    }
    return output.join(' ')
}

export function addSize(text: string, addSize: number): string {
    const regexp = new RegExp('(\\d*px)', 'g')
    let match
    const output = []
    let i = 0
    while ((match = regexp.exec(text)) !== null) {
        output.push(text.substring(i, match.index))
        output.push(add(match[0], addSize))
        i = regexp.lastIndex
    }
    output.push(text.substring(i, text.length))
    return output.join('')
}

// export function reduceSizeCss(css: string, reduceSize: number): string {
//     const regexp = new RegExp('(\\d*px)', 'g')
//     let match
//     const output = []
//     let i = 0
//     while ((match = regexp.exec(css)) !== null) {
//         output.push(css.substring(i, match.index))
//         output.push(divideBy(match[0], reduceSize))
//         i = regexp.lastIndex
//     }
//     output.push(css.substring(i, css.length))
//     return output.join('')
// }

export function combineHtmlAndCss(
    id: string,
    css: string | Set<string>,
    html?: string,
    pageId?: string
): string {
    if (!html) return ''
    const additionalStyle = `#${pageId}-block:hover + #${id} {background: #ccc}`
    const stringCss =
        typeof css === 'string'
            ? css
            : Array.from(css)
                  .filter((style) => !style.includes('{}'))
                  .join('')
    return html + `<style>${stringCss} ${pageId ? additionalStyle : ''}</style>`
}

export function reduceObjectSize(
    style: { [k: string]: string },
    reduceSize = 1
): { [k: string]: string } {
    return reduceSize !== 1
        ? Object.keys(style || {}).reduce(
              (acc: any, key: string) => ({
                  ...acc,
                  [key]: reduceStyleSize((style as any)[key], reduceSize),
              }),
              {}
          )
        : style || {}
}

export function pickRandom<T>(arr: T[]): T {
    return arr[Math.floor(Math.random() * arr.length)]
}

export function pickRandoms<T>(arr: T[], minValues = 0, rate = 0.5): T[] {
    const output: T[] = []
    const pickedIndex = new Set()
    for (const i in arr) {
        const el = arr[i]
        if (Math.floor((Math.random() * 1) / rate) === 0) {
            output.push(el)
            pickedIndex.add(i)
        }
    }
    if (output.length < minValues) {
        for (const i in arr) {
            if (!pickedIndex.has(i)) {
                output.push(arr[i])
                if (output.length >= minValues) return output
            }
        }
    }
    return output
}

export function shuffle(array: string[]): string[] {
    let currentIndex = array.length,
        randomIndex
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ]
    }
    return array
}
export const attrsToString = (attrs: any) => {
    const result = []
    for (const key in attrs) {
        let value = attrs[key]
        const toParse = value instanceof Array || value instanceof Object
        value = toParse ? JSON.stringify(value) : value
        result.push(`${key}=${toParse ? `'${value}'` : `'${value}'`}`)
    }
    return result.length ? ` ${result.join(' ')}` : ''
}

export function dictToInlineStyle(style: { [k: string]: string }): string {
    return Object.entries(style)
        .map(([key, value]) => {
            return `${key}: ${value}`
        })
        .join('; ')
}

export function toHtmlSafe(s: string) {
    return String(s)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
}

export function dictToStyle(
    selector: string,
    d: { [k: string]: string },
    prefix: '.' | '#' | '' = ''
) {
    const values = Object.entries(d)
        .map(([key, value]) => {
            return `${key}: ${value}`
        })
        .join('; \n')
    return `${prefix}${selector} {
    ${values}
  }`
}

export function isBigSize(reduceSize: number) {
    return reduceSize <= 5
}

export function getColorFromState(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    memoryInstance: SourceMemory,
    measureColorLocation: MeasureColorLocation,
    opts: { measure?: string } = {}
) {
    const measures = (
        opts.measure ||
        traitSearch.getTraitStringValue(TraitId.MEASURE) ||
        ''
    )
        .split(';')
        .filter((v) => !!v)
    if (!measures.length) return globalState.colors[measureColorLocation]
    const isSingleMeasure = measures.length === 1
    const measureColor = traitSearch.getTraitArrayValue(TraitId.MEASURE_COLOR, {
        querySelectorTag: 0,
        deviceType: DeviceType.DESKTOP,
    })
    const hasMeasureColorForCharts = measureColor.includes(measureColorLocation)
    const color =
        isSingleMeasure && hasMeasureColorForCharts
            ? memoryInstance.getMeasure(measures[0])?.color
            : undefined
    return color || globalState.colors[measureColorLocation]
}

export function getColorForElement(
    element: PonychartElement,
    memoryInstance: SourceMemory,
    measureColorLocation: MeasureColorLocation,
    opts: { measure?: string } = {}
) {
    return getColorFromState(
        element.globalState,
        element.traitSearch,
        memoryInstance,
        measureColorLocation,
        opts
    )
}

export function dashToHyphen(dashString: string): string {
    return dashString.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
}

export function hyphenToDash(hyphenString: string) {
    return hyphenString.replace(/([a-z][A-Z])/g, (g) =>
        [g[0], g[1].toLowerCase()].join('-')
    )
}

export function hyphenObjectToDash(hyphenObject: { [k: string]: any }) {
    return Object.keys(hyphenObject).reduce(
        (acc: { [k: string]: any }, key: string) => ({
            ...acc,
            [hyphenToDash(key)]: hyphenObject[key],
        }),
        {}
    )
}
