<template lang="pug">
v-card-text
    v-dialog(v-model='show', max-width='700')
        v-card(max-width='700')
            v-card-title.ma-4
                v-row(justify='space-between')
                    span {{ $t("unsubscribe") }}?
                    v-btn(@click='show = false', icon, :disabled='loading')
                        v-icon close
            v-card-text
                v-row
                    v-col(cols='5')
                        v-img(src='@/assets/crying.png')
                    v-col(cols='7')
                        .subtitle-2 {{ $t("areYouSure") }}

                        br
                        span(
                            v-html='$t("yourAccountWillStayActive", { endDate: periodEnd })'
                        ) 
                        br
                        v-textarea.mt-6(
                            dense,
                            outlined,
                            v-model='reason',
                            :label='$t("tellMeWhy")'
                        )
            v-card-actions
                v-spacer
                v-btn(
                    color='accent',
                    :dark='!loading',
                    depressed,
                    :disabled='loading',
                    @click='show = false'
                ) {{ $t("no") + ", " + $t("stay") }}
                v-btn(
                    color='red',
                    :dark='reason.length > 0',
                    outlined,
                    :loading='loading',
                    @click='unsubscribe'
                ) {{ $t("yes") + ", " + $t("unsubscribe") }}
    v-row.mt-4(justify='center')
        v-btn(
            v-if='!subscription.status',
            color='primary',
            outlined,
            @click='$store.commit("TOGGLE_EVENT", "payment")'
        )
            v-icon credit_card
            span {{ $t("payment") }}
    v-alert.mt-4(
        v-if='["canceled", "active"].includes(subscription.status)',
        :color='color',
        dense,
        outlined,
        border='left'
    )
        span(v-html='message')
        br(v-if='cancelAtPeriodEnd')
        span(v-if='cancelAtPeriodEnd') {{ $t("willBeCanceled") }}
    span(
        v-if='subscription.collection_method === "charge_automatically" && !cancelAtPeriodEnd'
    ) {{ $t("chargeAutomatically") }}
    br(v-if='subscription.status === "active"')
    span.blue--text.lighten-2--text(
        v-if='subscription.status === "active" && isAdmin && !cancelAtPeriodEnd',
        style='cursor: pointer',
        @click='showUnsubscribe'
    ) {{ $t("unsubscribe") }}?
</template>

<script>
import CheckoutService from '@/services/checkoutService'
import format from 'date-fns/format'

function isFutureDate(value) {
    return new Date().getTime() <= new Date(value).getTime()
}
export default {
    name: 'subscriptionStatus',
    props: {
        subscription: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        show: false,
        loading: false,
        reason: '',
    }),
    computed: {
        product() {
            return this.subscription?.product
        },
        cancelAtPeriodEnd() {
            return (
                this.subscription.cancel_at_period_end &&
                this.subscription.status === 'active'
            )
        },
        startDate() {
            if (!this.subscription.current_period_start) return
            return this.subscription.current_period_start
        },
        endDate() {
            if (!this.subscription.current_period_end) return
            return this.subscription.current_period_end
        },
        isPresent() {
            return (
                isFutureDate(this.endDate * 1000) &&
                !isFutureDate(this.startDate * 1000)
            )
        },
        isFuture() {
            return !isFutureDate(this.endDate * 1000)
        },
        message() {
            return this.$t(`cancel${this.isFuture ? 'ed' : ''}Subscription`, {
                endDate: this.periodEnd,
                product: this.product?.name || 'Ponychart',
            })
        },
        color() {
            if (this.isPresent) {
                return this.subscription?.status === 'active' &&
                    !this.cancelAtPeriodEnd
                    ? 'green'
                    : 'orange'
            }
            if (this.isFuture) {
                return 'error'
            }
            return ''
        },
        periodEnd() {
            if (!this.endDate) return ''
            return format(this.endDate * 1000, 'dd/MM/yyyy')
        },
        periodStart() {
            if (!this.startDate) return ''
            return format(this.startDate * 1000, 'dd/MM/yyyy')
        },
    },
    methods: {
        async unsubscribe() {
            this.loading = true
            try {
                await CheckoutService.unsubscribe(
                    this.subscription.id,
                    this.reason
                )
                await this.$store.dispatch('getSubscriptions', true)
                await this.$nextTick()
                if (this.cancelAtPeriodEnd) {
                    this.showToast({
                        type: 'info',
                        code: 'SUBSCRIPTION_CANCELED',
                    })
                    this.show = false
                } else {
                    console.log('this should not happen')
                }
            } catch (e) {
                this.showToast({})
                console.log(e)
            }
            this.loading = false
        },
        showUnsubscribe() {
            this.$emit('close')
            this.show = true
        },
    },
}
</script>

<i18n>{
  "fr": {
    "stay": "rester",
    "tellMeWhy": "Dites-moi ce que je pourrais améliorer...",
    "areYouSure": "Êtes-vous sûre de vouloir me quitter ?",
    "iAmSad": "Je suis triste de vous voir me quitter",
    "yourAccountWillStayActive": "Votre compte restera actif jusqu'au {endDate}. <br>Vous pourrez continuer à utiliser cette application gratuitement jusqu'à cette date ou vous réabonner à tout moment !",
    "chargeAutomatically": "Cet abonnement est renouvelé automatiquement",
    "willBeCanceled": "Votre accès sera révoqué après cette date",
    "cancelSubscription": "Votre abonnement à <b>{product}</b> est actuellement actif jusqu'au {endDate}.",
    "canceledSubscription": "Votre abonnement à <b>{product}</b> a été annulé le {endDate}."
  },
  "en": {
    "stay": "stay",
    "tellMeWhy": "Tell me what I could make better...",
    "areYouSure": "Are you sure you want to leave me?",
    "iAmSad": "I am sad to see you leave me",
    "yourAccountWillStayActive": "Your account will stay active until {endDate}.<br> You can continue to use this application freely until this date or subscribe again anytime!",
    "chargeAutomatically": "This subscription is being renewed automatically",
    "willBeCanceled": "Your access will be revoked after this date",
    "cancelSubscription": "Your subscription to <b>{product}</b> is currently active until {endDate}.",
    "canceledSubscription": "Your subscription to <b>{product}</b> has been revoked on {endDate}."
  },
  "es": {
    "stay": "quedarse",
    "tellMeWhy": "Dígame qué podría mejorar...",
    "areYouSure": "¿Está seguro de que quiere dejarme?",
    "iAmSad": "Me entristece ver que me deja",
    "yourAccountWillStayActive": "Su cuenta permanecerá activa hasta el {endDate}. <br>Puede seguir utilizando esta aplicación libremente hasta esa fecha o suscribirse de nuevo en cualquier momento.",
    "chargeAutomatically": "Esta suscripción se renueva automáticamente",
    "willBeCanceled": "Su acceso se revocará después de esta fecha",
    "cancelSubscription": "Su suscripción a <b>{product}</b> está activa actualmente hasta el {endDate}.",
    "canceledSubscription": "Su suscripción a <b>{product}</b> se canceló el {endDate}."
  },
  "de": {
    "stay": "Bleiben",
    "tellMeWhy": "Sag mir, was ich besser machen könnte...",
    "areYouSure": "Sind Sie sicher, dass Sie mich verlassen wollen?",
    "iAmSad": "Es tut mir leid zu sehen, dass Sie mich verlassen.",
    "yourAccountWillStayActive": "Ihr Konto bleibt bis zum {EndDate} aktiv. <br>Sie können diese Anwendung bis zu diesem Datum frei nutzen oder jederzeit wieder abonnieren!",
    "chargeAutomatically": "Dieses Abonnement wird automatisch verlängert.",
    "willBeCanceled": "Ihr Zugang wird nach diesem Datum gesperrt."
  }
}</i18n>