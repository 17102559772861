import { SidebarDesign, SidebarType } from 'ponychart'

import { ClickedButtonContainer, ColumnContainer } from '@/ponychart/element'
import { GlobalState, LocalState } from '@/ponychart/state'

import { SIDEBAR_BAND_WIDTH, SIDEBAR_HEIGHT } from './config'

const WIDTH = `${SIDEBAR_BAND_WIDTH}px`

function _button(
    globalState: GlobalState,
    margins: 0 | 1 | 2,
    wrapperStyles: { [k: string]: string },
    buttonStyles: { [k: string]: string } = {}
) {
    const clickedButtonStyle = ClickedButtonContainer.getStyles(
        globalState.colors.sidebar_primary,
        '100%',
        margins
    )
    return {
        wrapperStyles: {
            ...wrapperStyles,
            background: globalState.colors.sidebar_secondary,
        },
        buttonStyles: {
            ...clickedButtonStyle,
            ...buttonStyles,
            background: globalState.colors.sidebar_primary,
            height: '100%',
            width: '100%',
        },
    }
}

type VisibleSidebar = SidebarType.LATERAL | SidebarType.TOP
type NavigationStyleOutput = {
    buttonStyles: { [k: string]: string }
    wrapperStyles: { [k: string]: string }
}

const MAP: {
    [k in SidebarDesign]: (
        globalState: GlobalState,
        sidebar: VisibleSidebar
    ) => NavigationStyleOutput
} = {
    [SidebarDesign.SIMPLE]: (
        globalState: GlobalState,
        sidebar: VisibleSidebar
    ) => ({
        buttonStyles: {
            ...ClickedButtonContainer.getStyles(
                globalState.colors.sidebar_secondary,
                sidebar === SidebarType.LATERAL ? `${SIDEBAR_HEIGHT}px` : '100%'
            ),
        },
        wrapperStyles: {},
    }),
    [SidebarDesign.UNDERLINE]: (
        globalState: GlobalState,
        sidebar: VisibleSidebar
    ) => {
        const key =
            sidebar === SidebarType.TOP ? 'paddingBottom' : 'paddingRight'
        const buttonKey =
            sidebar === SidebarType.TOP ? 'marginBottom' : 'marginRight'
        const margins = sidebar === SidebarType.TOP ? 1 : 0
        return _button(
            globalState,
            margins,
            { [key]: WIDTH },
            { [buttonKey]: WIDTH }
        )
    },
    [SidebarDesign.SQUARE]: (
        globalState: GlobalState,
        _sidebar: VisibleSidebar
    ) => _button(globalState, 2, { padding: WIDTH }),
    [SidebarDesign.TOP_BOTTOM]: (
        globalState: GlobalState,
        _sidebar: VisibleSidebar
    ) => _button(globalState, 2, { paddingBottom: WIDTH, paddingTop: WIDTH }),
    [SidebarDesign.LEFT_RIGHT]: (
        globalState: GlobalState,
        _sidebar: VisibleSidebar
    ) => _button(globalState, 0, { paddingLeft: WIDTH, paddingRight: WIDTH }),
}

export function clickedNavigationButtonStyles(
    globalState: GlobalState,
    sidebarDesign: SidebarDesign,
    sidebar: VisibleSidebar
): NavigationStyleOutput {
    const callable = MAP[sidebarDesign] || MAP[SidebarDesign.SIMPLE]

    return callable(globalState, sidebar)
}

export function clickedNavigationButton(
    globalState: GlobalState,
    wrapperLocalState: LocalState,
    buttonLocalState: LocalState,
    sidebarDesign: SidebarDesign,
    content: string
) {
    if (sidebarDesign === SidebarDesign.SIMPLE) {
        return new ClickedButtonContainer(
            globalState,
            buttonLocalState,
            content
        )
    } else {
        return new ColumnContainer(globalState, wrapperLocalState, {
            children: [
                new ClickedButtonContainer(
                    globalState,
                    buttonLocalState,
                    content
                ),
            ],
        })
    }
}
