import { TraitId } from 'ponychart'

import { ChartSelector, DateAggregationSelector, Dimension2Selector, DimensionSelector, Measure2Selector, MeasureSelector } from "@/ponychart/header/model"
import { GlobalState, LocalState, TraitSearch } from "@/ponychart/state";
import { PonychartElement } from "@/ponychart/element/model";
import { ParameterTraitId } from "@/ponychart/element/types";
import { DynamicParameter } from "./model"



type ParameterConstructor = {
    new(parameter: DynamicParameter, globalState: GlobalState, localState: LocalState, traitSearch: TraitSearch): PonychartElement;
}

export class ParameterElementFactory {
    private _map: { [t in ParameterTraitId]: ParameterConstructor } = {
        [TraitId.DATE_AGGREGATION_LEVEL]: DateAggregationSelector,
        [TraitId.MEASURE]: MeasureSelector,
        [TraitId.MEASURE_2]: Measure2Selector,
        [TraitId.DIMENSION]: DimensionSelector,
        [TraitId.DIMENSION_2]: Dimension2Selector,
        charts: ChartSelector,
    }

    constructor(private globalState: GlobalState, private localState: LocalState, private traitSearch: TraitSearch) { }

    build(parameter: DynamicParameter) {
        return new this._map[parameter.type](parameter, this.globalState, this.localState, this.traitSearch)
    }
}
