import { ChartFactory, NoneChart } from '@/ponychart/charts'
import {
    ColumnContainer,
    PonychartElement,
    RowContainer,
} from '@/ponychart/element'
import { KpiCardWrapper } from '@/ponychart/pageBlock'
import { SourceMemory } from '@/ponychart/memoize'
import {
    GlobalState,
    LocalState,
    TraitSearch,
    getChartState,
    getTitleState,
    getInnerBodyState,
} from '@/ponychart/state'
import {
    Bool,
    CardTitleTrait,
    getArrayValueFromString,
    guessTitleName,
    IncludeCardTitleTrait,
} from '@/ponychart/trait'
import { guessAppropriateColumnCountForKPICards } from '@/ponychart/page/utils'
import { isBigSize } from '@/ponychart/utils'

import {
    ChartType,
    CHART_TYPE_TRAITS,
    TraitId,
    QuerySelectorTag,
    Trait,
    DeviceType,
    PositiveQuerySelectorTag,
    querySelectorTagToIncludesTrait,
    traitToQuerySelectorTag,
    TraitOptionType,
} from 'ponychart'

// Coupling with MAX_QUERY_SELECTOR_TAG
const DEFAULT_CHARTS = [
    ChartType.KPI,
    ChartType.LINE,
    ChartType.BAR,
    ChartType.LOLLIPOP,
    ChartType.AREA,
]

const memoryInstance = SourceMemory.getInstance()

export function guessColumnCount(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    maxCount: number,
    forTraitId: TraitId.MEASURE | TraitId.DIMENSION
) {
    let columnCount = traitSearch.getTraitNumberValue(TraitId.COLUMN_COUNT, 0)
    const dimensionMode = forTraitId === TraitId.DIMENSION
    const items = dimensionMode
        ? memoryInstance.dimensions
        : memoryInstance.measures
    const itemId = traitSearch.getTraitStringRequiredValue(forTraitId, 0)
    const selectedOptions = getArrayValueFromString(itemId, {
        items,
        twbIdx: globalState.twbIdx,
    })
    if (!columnCount) {
        columnCount = guessAppropriateColumnCountForKPICards({
            measureCount: selectedOptions.length,
            maxCount,
        })
    }
    return columnCount
}

export function alignVerticallyTrait(traitSearch: TraitSearch): Trait[] {
    return [DeviceType.TABLET, DeviceType.MOBILE].map((deviceType) => ({
        id: TraitId.ALIGN_VERTICALLY,
        querySelectorTags: [0],
        deviceType,
        value:
            traitSearch.getTraitStringValue(TraitId.ALIGN_VERTICALLY) ||
            (deviceType === DeviceType.TABLET ? Bool.FALSE : Bool.TRUE),
    }))
}

export function cardTitleTraits(
    traitSearch: TraitSearch,
    querySelectorTag: QuerySelectorTag
) {
    const output: Trait[] = []
    const start = querySelectorTag === 0 ? 0 : 1
    const end = querySelectorTag === 0 ? 0 : querySelectorTag
    for (let i = start; i <= end; i++) {
        const querySelectorTag = Number(i) as QuerySelectorTag
        const noTitle =
            traitSearch.getTraitStringValue(
                TraitId.INCLUDE_CARD_TITLE,
                querySelectorTag
            ) === Bool.FALSE
        if (noTitle) continue
        output.push(
            new CardTitleTrait(
                {
                    querySelectorTags: [querySelectorTag],
                    deviceType: DeviceType.DESKTOP,
                    twbIdx: traitSearch.twbIdx,
                },
                {},
                traitSearch
            )
        )
    }
    return output
}

export function includeCardTitleTraits(
    traitSearch: TraitSearch,
    querySelectorTag: QuerySelectorTag
) {
    const output: Trait[] = []
    const start = querySelectorTag === 0 ? 0 : 1
    const end = querySelectorTag === 0 ? 0 : querySelectorTag
    for (let i = start; i <= end; i++) {
        const querySelectorTag = Number(i) as QuerySelectorTag
        output.push(
            new IncludeCardTitleTrait(
                {
                    querySelectorTags: [querySelectorTag],
                    deviceType: DeviceType.DESKTOP,
                    twbIdx: traitSearch.twbIdx,
                },
                {}
            )
        )
    }
    return output
}

export function chartTypeTraits(traitSearch: TraitSearch, q: QuerySelectorTag) {
    const output: Trait[] = []
    for (let i = 1; i <= q; i++) {
        const querySelectorTag = Number(i)
        const traitId = CHART_TYPE_TRAITS[i - 1]
        const chartTrait = {
            id: traitId,
            value:
                traitSearch.getTraitValue(traitId, q) || DEFAULT_CHARTS[q - 1],
            querySelectorTags: [0, querySelectorTag] as QuerySelectorTag[],
            deviceType: DeviceType.DESKTOP,
            twbIdx: traitSearch.twbIdx,
            type: TraitOptionType.MULTI_SELECT,
            maxCount: traitSearch.multipleCharts ? 4 : 1,
        }
        output.push(chartTrait)
    }
    return output
}

export function forAllDeviceTypes(trait: Trait): Trait[] {
    return [DeviceType.DESKTOP, DeviceType.TABLET, DeviceType.MOBILE].map(
        (deviceType) => ({
            ...trait,
            deviceType,
        })
    )
}

function getGrayClasses(globalState: GlobalState, localState: LocalState) {
    if (!globalState.grayFilter) return []
    return localState.mainQuerySelectorTag !==
        globalState.grayFilter?.querySelectorTag
        ? ['gray-filter']
        : ['gradient-border']
}

export function buildCharts(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    localState: LocalState,
    querySelectorTag: QuerySelectorTag
): PonychartElement[] {
    const internalState = localState
        .copy()
        .setQuerySelectorTags([0, querySelectorTag])

    if (isBigSize(globalState.reduceSize))
        internalState.addClasses(getGrayClasses(globalState, internalState))

    const chartFactory = new ChartFactory(globalState)

    return chartFactory.build(internalState, traitSearch)
}

export function buildChildrenForRepetitiveKpiCardDesign(
    globalState: GlobalState,
    chartState: LocalState,
    traitSearch: TraitSearch,
    traitIds: TraitId[][],
    opts: { measureOrDimension: string; asRow: boolean; asMeasure?: boolean }
) {
    const children: PonychartElement[] = []
    const localState = new LocalState()
    const flexState = localState.copy().setIsFlex(true)
    for (const i in traitIds) {
        const childrenRow: PonychartElement[] = []
        for (const j in traitIds[i]) {
            const chartTraitId = traitIds[i][j]
            const querySelectorTag = traitToQuerySelectorTag(chartTraitId)
            if (!includeChart(traitSearch, querySelectorTag)) continue
            const chartElements = buildCharts(
                globalState,
                traitSearch,
                chartState
                    .copy()
                    .addAttribute(
                        opts.asMeasure ? TraitId.MEASURE : TraitId.DIMENSION,
                        opts.measureOrDimension
                    ),
                querySelectorTag as QuerySelectorTag
            )
            if (chartElements.length > 1) {
                childrenRow.push(
                    new ColumnContainer(globalState, localState, {
                        children: chartElements,
                    })
                )
            } else if (chartElements.length === 1) {
                childrenRow.push(chartElements[0])
            }
        }
        if (childrenRow.length === 0) continue
        else if (childrenRow.length === 1) children.push(childrenRow[0])
        else if (opts.asRow)
            children.push(
                new RowContainer(globalState, flexState, {
                    children: childrenRow,
                })
            )
        else
            children.push(
                new ColumnContainer(globalState, flexState, {
                    children: childrenRow,
                })
            )
    }
    return children
}

export function buildDefaultChildrenForNonRepetitiveDesign(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    chartTypes: TraitId[][],
    cardHeight: number,
    opts: { asRow: boolean }
) {
    const flexState = new LocalState().setIsFlex(true)
    const chartState = getChartState(globalState, traitSearch)

    const totalHeight =
        cardHeight *
        (opts.asRow
            ? chartTypes.length
            : Math.max(...chartTypes.map((c) => c.length)))
    const children = []
    for (const chartTypeRow of chartTypes) {
        const wrapper = opts.asRow
            ? new RowContainer(globalState, flexState)
            : new ColumnContainer(globalState, flexState)
        for (const chartType of chartTypeRow) {
            const q = traitToQuerySelectorTag(
                chartType
            ) as PositiveQuerySelectorTag
            if (!includeChart(traitSearch, q)) continue
            const titleState = getTitleState(globalState, traitSearch, {
                querySelectorTag: q as QuerySelectorTag,
                withColor: true,
            })
            const height =
                totalHeight /
                (opts.asRow ? chartTypes.length : chartTypeRow.length)
            const kpiPayload = KpiCardWrapper.buildKpiCardLocalStatePayload(
                globalState,
                traitSearch,
                { height }
            )
            const innerBodyState = getInnerBodyState(globalState, traitSearch, {
                querySelectorTag: q as QuerySelectorTag,
                height,
            }).setIsFlex(false)
            wrapper.push(
                new KpiCardWrapper(
                    globalState,
                    traitSearch,
                    { ...kpiPayload, titleState, innerBodyState },
                    buildCharts(
                        globalState,
                        traitSearch,
                        chartState,
                        q as QuerySelectorTag
                    ),
                    height,
                    { ...opts, querySelectorTag: q as QuerySelectorTag }
                )
            )
        }
        if (wrapper.length === 0) continue
        if (wrapper.length === 1) children.push(wrapper.children[0])
        else children.push(wrapper)
    }
    return children
}

export function buildVerticalChildrenForNonRepetitiveDesign(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    maxQuerySelectorTag: QuerySelectorTag,
    height: number
) {
    if (maxQuerySelectorTag === 0) return []
    const children = []
    const chartState = getChartState(globalState, traitSearch)
    const payload = KpiCardWrapper.buildKpiCardLocalStatePayload(
        globalState,
        traitSearch,
        { height }
    )
    for (let q = 1; q <= maxQuerySelectorTag; q++) {
        if (!includeChart(traitSearch, q as QuerySelectorTag)) continue
        const titleState = getTitleState(globalState, traitSearch, {
            querySelectorTag: q as QuerySelectorTag,
            withColor: true,
        })
        children.push(
            new KpiCardWrapper(
                globalState,
                traitSearch,
                {
                    ...payload,
                    titleState,
                    innerBodyState: payload.innerBodyState.setIsFlex(false),
                },
                buildCharts(
                    globalState,
                    traitSearch,
                    chartState,
                    q as QuerySelectorTag
                ),
                height,
                { querySelectorTag: q as QuerySelectorTag }
            )
        )
    }
    return children
}

export function includeChart(
    traitSearch: TraitSearch,
    querySelectorTag: PositiveQuerySelectorTag
) {
    if (traitSearch.deviceType === DeviceType.DESKTOP) return true
    const includesChartTrait = querySelectorTagToIncludesTrait(querySelectorTag)
    const value = traitSearch.getTraitStringValue(includesChartTrait, {
        querySelectorTag: 0,
        chartType: undefined,
    })
    // const found = traitSearch.traits.find(t => t.id === includesChartTrait)
    // console.log("includeChart", value, found)
    return (value || Bool.TRUE) !== Bool.FALSE
}
