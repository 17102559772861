import { DeviceType, ChartType, TraitId, QuerySelectorTag } from 'ponychart'
import { TimeIndication } from '@/ponychart/charts/kpiChart'
import { Bool } from '@/ponychart/trait/types'
import { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import {
    BLOCK_HEADER_STATE,
    getOuterBodyState,
    getTitleState,
    getInnerBodyState,
} from '@/ponychart/state/seeds'
import {
    sToN,
    nToS,
    add,
    addSize,
    memoizedInvertColor,
} from '@/ponychart/utils'
import {
    PAGE_BLOCK_HEADER_HEIGHT,
    ELEMENT_HEIGHT,
    ELEMENT_WIDTH,
} from '@/ponychart/header/config'
import { fill, FillMode } from '@/ponychart/header/fill'
import { DynamicParameter } from '@/ponychart/dynamicParameter/model'
import { ParameterElementFactory } from '@/ponychart/dynamicParameter/factory'
import {
    ColumnContainer,
    Container,
    PonychartElement,
    RowContainer,
} from '@/ponychart/element/model'
import { ChartContext } from '@/ponychart/chartContext/model'
import { rotatingTextes } from '@/ponychart/chartContext/utils'
import { UNIQUE_PAGE_STRUCTURE_SET } from '@/ponychart/page/types'
import { SizePropagatorHelpers } from '@/ponychart/element/size'
import { SourceMemory } from '@/ponychart/memoize'
import {
    applyLocalParameters,
    guessAppropriateColumnCount,
    processContextes,
    processParameters,
    setAllCardId,
} from './utils'
import { LineRow, TextElementMode } from '../textElement'

const memoryInstance = SourceMemory.getInstance()
const DESKTOP_CONTEXT = {
    querySelectorTags: 0,
    deviceType: DeviceType.DESKTOP,
} as const

function _hasPageBlockTitle(traitSearch: TraitSearch): boolean {
    try {
        const dbText =
            traitSearch.getTraitStringValue(
                TraitId.BLOCK_TITLE,
                DESKTOP_CONTEXT
            ) || '[]'
        const lineRows: any[] = JSON.parse(dbText)
        if (!lineRows?.length || !lineRows?.[0]?.elems.length) return false
        return true
    } catch (e) {
        //
        console.log(e)
        return false
    }
}

export class PageBlockHeader extends Container {
    parameters: DynamicParameter[] = []
    traitSearch: TraitSearch

    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            globalState.hasMobileHeader ? ChartType.COLUMN : ChartType.ROW,
            globalState,
            localState
        )
        this.traitSearch = traitSearch
    }

    setParameters(parameters: DynamicParameter[]) {
        /** "Receives" parameters to be displayed in the text element */
        this.parameters = parameters
        this.onMountHook()
        return this
    }

    onMountHook() {
        if (this.width === undefined)
            throw new Error('Width must be propagaged down')

        const isMobile = this.globalState.isMobile
        const hasTitle = _hasPageBlockTitle(this.traitSearch)

        const flexState = new LocalState().setIsFlex(true)
        const hasTimeIndication =
            this.traitSearch.getTraitValue(
                TraitId.BLOCK_HAS_TIME_INDICATION,
                DESKTOP_CONTEXT
            ) == Bool.TRUE
        const children: PonychartElement[] = []
        this.localState.setIsFlex(true)
        if (hasTitle)
            children.push(
                new PageBlockHeaderTitle(
                    this.globalState,
                    getTitleState(this.globalState, this.traitSearch, {
                        withColor: false,
                    }).addStyles({
                        height: isMobile
                            ? nToS(PAGE_BLOCK_HEADER_HEIGHT)
                            : '100%',
                        color: memoizedInvertColor(
                            this.globalState.colors.light_background
                        ),
                    }),
                    this.traitSearch
                )
            )
        if (hasTimeIndication)
            children.push(
                new TimeIndication(
                    this.globalState,
                    new LocalState()
                        .addAttribute(
                            TraitId.COLOR,
                            this.globalState.colors.light_background
                        )
                        .addStyles({
                            height: isMobile
                                ? nToS(PAGE_BLOCK_HEADER_HEIGHT)
                                : '100%',
                        }),
                    this.traitSearch
                )
            )

        let height = PAGE_BLOCK_HEADER_HEIGHT
        if (this.parameters.length) {
            const innerWidth =
                SizePropagatorHelpers.getInnerWidth(this, this.width) /
                ((this.parameters.length ? 1 : 0) + children.length)
            const columnCount = guessAppropriateColumnCount(innerWidth)
            const parameterFactory = new ParameterElementFactory(
                this.globalState,
                flexState,
                this.traitSearch
            )
            const parameters = [...this.parameters]
                .sort(DynamicParameter.sort)
                .reverse()
            const rowContainers: RowContainer[] = []
            const rowState = new LocalState()
                .setStyles({ height: `${ELEMENT_HEIGHT}px` })
                .setIsFlex(true)
            while (parameters.length) {
                const rowParameters = parameters.splice(
                    Math.max(0, parameters.length - columnCount),
                    columnCount
                )
                rowContainers.push(
                    new RowContainer(this.globalState, rowState, {
                        children: fill(
                            this.globalState,
                            rowParameters
                                .reverse()
                                .map((p) => parameterFactory.build(p))
                                .map(
                                    (p) =>
                                        new ColumnContainer(
                                            this.globalState,
                                            flexState,
                                            { children: [p] }
                                        )
                                ),
                            FillMode.START,
                            columnCount,
                            ColumnContainer
                        ),
                    })
                )
            }

            if (rowContainers.length === 1 && !isMobile) {
                children.push(rowContainers[0])
                rowContainers[0].localState.addStyles({
                    marginTop: nToS(
                        (PAGE_BLOCK_HEADER_HEIGHT - ELEMENT_HEIGHT) / 2
                    ),
                    marginBottom: nToS(
                        (PAGE_BLOCK_HEADER_HEIGHT - ELEMENT_HEIGHT) / 2
                    ),
                })
            } else if (isMobile) {
                height =
                    PAGE_BLOCK_HEADER_HEIGHT * children.length +
                    rowContainers.length * ELEMENT_HEIGHT
                children.push(...rowContainers)
                this.localState.setIsFlex(false)
            } else if (columnCount === 1) {
                children.push(
                    new ColumnContainer(this.globalState, flexState, {
                        children: rowContainers.map((r) => r.children[0]),
                    })
                )
            } else {
                const column = new ColumnContainer(
                    this.globalState,
                    flexState,
                    { children: rowContainers }
                )
                children.push(column)
                height = Math.max(height, rowContainers.length * ELEMENT_HEIGHT)
                if (height < PAGE_BLOCK_HEADER_HEIGHT) {
                    const margin = nToS(
                        (PAGE_BLOCK_HEADER_HEIGHT -
                            rowContainers.length * ELEMENT_HEIGHT) /
                            2
                    )
                    column.localState = column.localState.copy().addStyles({
                        marginTop: margin,
                        marginBottom: nToS(
                            (PAGE_BLOCK_HEADER_HEIGHT -
                                rowContainers.length * ELEMENT_HEIGHT) /
                                2
                        ),
                    })
                }
            }
        }
        this.setChildren(children)
        this.localState.addStyles({
            height: nToS(height),
        })
    }

    mount(
        opts: {
            processedParameters?: DynamicParameter[]
            isDarkBackground?: boolean
        } = {}
    ) {
        this.onMountHook()
        super.mount(opts)
        return this
    }
}

export class PageBlockElement extends Container {
    child: ColumnContainer // the initial column container
    pageBlockHeight?: string
    asRow: boolean
    innerHeight: number
    header: PageBlockHeader
    parameters: DynamicParameter[] = []

    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch,
        child: ColumnContainer,
        opts: { asRow: boolean; innerHeight: number }
    ) {
        const header = new PageBlockHeader(
            globalState,
            BLOCK_HEADER_STATE.copy().addStyles({
                fontFamily: memoryInstance.fontFamily,
            }),
            traitSearch
        )
        super(ChartType.COLUMN, globalState, localState, {
            traitSearch,
            children: [header, child],
        })
        // this.id = `blocks-${this.globalState.reduceSize}-${this.globalState.pageBlockId}`
        this.asRow = opts.asRow
        this.child = child
        this.innerHeight = opts.innerHeight
        this.header = header
    }

    private static partition(array: any[], isValid: (elem: any) => boolean) {
        return array.reduce(
            ([pass, fail], elem) => {
                return isValid(elem)
                    ? [[...pass, elem], fail]
                    : [pass, [...fail, elem]]
            },
            [[], []]
        )
    }

    setParameters(parameters: DynamicParameter[]) {
        /** "Receives" parameters to be displayed in the text element */
        const [localParameters, globalParameters] = PageBlockElement.partition(
            parameters,
            (parameter: DynamicParameter) =>
                UNIQUE_PAGE_STRUCTURE_SET.has(this.globalState.pageStructure) &&
                parameter.querySelectorTags.size === 1
        )
        this.parameters = globalParameters

        this.header.setParameters(globalParameters)

        applyLocalParameters(this, localParameters)

        this.onMountHook()
        return this
    }

    onMountHook() {
        // Hook called at mount time to create children of PageBlock
        // this.children = []
        const noTitle =
            this.globalState.hidePageBlockHeader ||
            (!_hasPageBlockTitle(this.traitSearch) &&
                this.traitSearch.getTraitStringValue(
                    TraitId.BLOCK_HAS_TIME_INDICATION,
                    DESKTOP_CONTEXT
                ) !== Bool.TRUE &&
                this.parameters.length === 0)
        const titleHeight = noTitle
            ? 0
            : sToN(this.header.localState.rawStyles.height)
        const children = this.child.children

        this.localState.addStyles({
            height: add(this.innerHeight, titleHeight),
        })

        // this.id = `blocks-${this.globalState.reduceSize}-${this.globalState.pageBlockId}`

        const flexState = new LocalState()
            .setIsFlex(true)
            .setStyles({ height: nToS(this.innerHeight) })

        const Clss = this.asRow ? RowContainer : ColumnContainer
        this.children = [
            ...(noTitle ? [] : [this.header]),
            new Clss(this.globalState, flexState, { children }),
        ]
        this.header.onMountHook()
    }

    mount(
        opts: {
            processedParameters?: DynamicParameter[]
            isDarkBackground?: boolean
        } = {}
    ) {
        const processedParameters = processParameters(
            this,
            opts?.processedParameters || []
        )
        super.mount({
            processedParameters,
            isDarkBackground: opts.isDarkBackground,
        })
        this.onMountHook()
        // setAllPageBlockId(this) // Important! set after all children created meaning after this.onMountHook() (obviously)
        // setAllPageId(this)
        // setTwbIdx(this)

        // setAllPageBlockId(this) // Important run a second time to apply pageBlockId to PageBlockHeader (/!\ required)
        return this
    }
}

class PageBlockHeaderTitle extends ColumnContainer {
    _unsafe = true
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(globalState, localState, { traitSearch })
        this.onMountHook()
    }

    onMountHook() {
        this.children = []
        const dbText =
            this.traitSearch.getTraitStringValue(TraitId.BLOCK_TITLE, {
                deviceType: DeviceType.DESKTOP,
                querySelectorTag: this.localState.mainQuerySelectorTag,
                explicitDeviceType: true,
                explicitQuerySelectorTag: false,
            }) || '[]'
        try {
            this.lineRows = JSON.parse(dbText)
            const lines = LineRow.fromDb(dbText)
            this.text = LineRow.toHtml(lines, TextElementMode.PREVIEW, {
                styles: { width: '100%' },
            })
        } catch (e) {
            this.lineRows = []
        }
    }

    mount(opts: { processedParameters?: DynamicParameter[] } = {}) {
        this.onMountHook()
        super.mount(opts)
        return this
    }
}

// Title of a KPI card
export class Title extends ColumnContainer {
    _unsafe = true

    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(globalState, localState, { traitSearch })
    }

    setContextes(contextes: ChartContext[]) {
        this.contextes = contextes
        // this.setContextes(contextes)
        return this
    }

    onMountHook() {
        this.children = []
        const dbText =
            this.traitSearch.getTraitStringValue(TraitId.CARD_TITLE, {
                deviceType: DeviceType.DESKTOP,
                querySelectorTag: this.localState.mainQuerySelectorTag,
                explicitDeviceType: true,
                explicitQuerySelectorTag: false,
            }) || '[]'
        try {
            this.lineRows = JSON.parse(dbText)
            const lines = LineRow.fromDb(dbText)
            this.text = LineRow.toHtml(lines, TextElementMode.PREVIEW, {
                measure: rotatingTextes(
                    ChartContext.measureArray(this.contextes),
                    this.globalState.reduceSize
                ),
                dimension: rotatingTextes(
                    ChartContext.dimensionArray(this.contextes),
                    this.globalState.reduceSize
                ),
                date: rotatingTextes(
                    ChartContext.dateAggregationLevelArray(this.contextes),
                    this.globalState.reduceSize
                ),
                styles: { width: '100%' },
            })
        } catch (e) {
            this.lineRows = []
        }
    }

    mount(opts: { processedParameters?: DynamicParameter[] } = {}) {
        this.onMountHook()
        super.mount(opts)
        return this
    }
}

interface KpiCardLocalStatePayload {
    titleState: LocalState
    outerBodyState: LocalState
    innerBodyState: LocalState
}

export class KpiCardWrapper extends ColumnContainer {
    parameters: DynamicParameter[] = []
    noTitle: boolean

    static buildKpiCardLocalStatePayload(
        globalState: GlobalState,
        traitSearch: TraitSearch,
        opts: {
            measure?: string
            querySelectorTag?: QuerySelectorTag
            noTitle?: boolean
            height: number
        }
    ): KpiCardLocalStatePayload {
        return {
            titleState: getTitleState(globalState, traitSearch, {
                ...opts,
                withColor: true,
            }),
            outerBodyState: getOuterBodyState(globalState, traitSearch, opts),
            innerBodyState: getInnerBodyState(globalState, traitSearch, opts),
        }
    }

    pushParameters(...parameters: DynamicParameter[]) {
        this.parameters.push(...parameters)
    }

    constructor(
        globalState: GlobalState,
        traitSearch: TraitSearch,
        payload: KpiCardLocalStatePayload,
        children: PonychartElement[],
        private cardHeight: number,
        opts: { asRow?: boolean; querySelectorTag?: QuerySelectorTag } = {}
    ) {
        const noTitle =
            traitSearch.getTraitStringValue(
                TraitId.INCLUDE_CARD_TITLE,
                opts.querySelectorTag
            ) === Bool.FALSE
        const Constructor = opts.asRow ? RowContainer : ColumnContainer
        super(globalState, payload.outerBodyState, {
            children: [
                ...(!noTitle
                    ? [new Title(globalState, payload.titleState, traitSearch)]
                    : []),
                new Constructor(globalState, payload.innerBodyState, {
                    children,
                }),
            ],
        })
        this.noTitle = noTitle
    }

    onMountHook(): void {
        if (this.parameters.length === 0 && this.width === undefined)
            throw new Error('Width must have been propagated down')
        if (this.parameters.length === 0 || this.width === undefined) return

        const columnCount = guessAppropriateColumnCount(
            SizePropagatorHelpers.getInnerWidth(this, this.width)
        )

        const parameters = [...this.parameters]
            .sort(DynamicParameter.sort)
            .reverse()

        const rowState = new LocalState()
            .setStyles({
                height: `${ELEMENT_HEIGHT}px`,
                background: this.globalState.colors.background,
            })
            .setIsFlex(true)

        const rowContainers: RowContainer[] = []

        const parameterState = new LocalState().setStyles({
            width: `${ELEMENT_WIDTH}px`,
        })
        const parameterFactory = new ParameterElementFactory(
            this.globalState,
            parameterState,
            this.traitSearch
        )

        while (parameters.length) {
            const rowParameters = parameters.splice(
                Math.max(0, parameters.length - columnCount),
                columnCount
            )
            rowContainers.push(
                new RowContainer(this.globalState, rowState, {
                    children: fill(
                        this.globalState,
                        rowParameters
                            .reverse()
                            .map((p) => parameterFactory.build(p)),
                        FillMode.END,
                        columnCount,
                        ColumnContainer
                    ),
                })
            )
        }

        // Add the ELEMENT_HEIGHT to calc(100% - height)
        const lastChild = this.children[this.children.length - 1]
        const lastChildHeight = lastChild.localState.rawStyles.height
        lastChild.localState = lastChild.localState.copy().addStyles({
            height: addSize(
                lastChildHeight,
                ELEMENT_HEIGHT * rowContainers.length
            ),
        })
        const idx = this.noTitle ? 0 : 1
        this.children.splice(idx, 0, ...rowContainers)
    }

    mount(opts: { processedParameters?: DynamicParameter[] } = {}) {
        setAllCardId(this)
        this.onMountHook()

        // findAllContextes() will mutate the chartContext array to populate it
        // And if it finds a Title (which it will since it is a direct child of this element) then
        // it will apply the contextes to it.
        // When mounting the Title, it will be able to access the chartContext array to build a nice looking title
        // as well as passing it down to backend for rendering <dynamicParameters> in Tableau
        const contextes: ChartContext[] = []
        processContextes(this, contextes)

        const processedParameters = processParameters(
            this,
            opts?.processedParameters || []
        )
        super.mount({ processedParameters })
        return this
    }

    push(child: PonychartElement) {
        this.children[1].push(child)
    }
}
