<template lang="pug">
v-tooltip(bottom)
    template(v-slot:activator='{ on }')
        v-btn.data-v-step-measure-color.ml-2(
            icon,
            v-on='on',
            rounded,
            x-small,
            depressed,
            @click='$emit("click")'
        )
            v-avatar(:color='color', size='18')
    span {{ $t("creation.measureColor") }}
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: true,
        },
    },
}
</script>

