

export class RegisterTour {
    private static instance: RegisterTour;
    private tourContext: any = null
    private constructor() {
        this.tourContext = null
    }


    public registerTourContext(context: any) {
        this.tourContext = context
    }

    public getTourContext(): any {
        return this.tourContext
    }

    public static getInstance(): RegisterTour {
        if (!RegisterTour.instance) RegisterTour.instance = new RegisterTour();
        return RegisterTour.instance
    }

}