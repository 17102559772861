import { DataType } from "../types"
import { Column } from "./types"



export class ColumnHelpers {
    static allowedForFilters = [DataType.TEXT, DataType.INTEGER, DataType.DOUBLE, DataType.BOOLEAN]
    static allowedForStrings = [DataType.TEXT, DataType.INTEGER, DataType.BOOLEAN, DataType.DATE, DataType.DATETIME]

    static columnsForFilters(columns: Column[]) {
        return columns.filter(column => ColumnHelpers.allowedForFilters.includes(column.type))
    }

    static columnsForString(columns: Column[]) {
        return columns.filter(column => ColumnHelpers.allowedForStrings.includes(column.type))
    }

}
