import { pick } from 'lodash'

export function cleanAttributes(attributes: any, pickedKeys: string[] = []) {
    const output = {}
    const filteredAttributes = pick(attributes || {}, pickedKeys)
    for (const key of Object.keys(filteredAttributes)) {
        if (
            [
                'contextes',
                'text',
                'tooltip',
                'label',
                'sub_label',
                'sub_tooltip',
            ].includes(key)
        ) {
            try {
                output[key] = JSON.parse(filteredAttributes[key])
            } catch (_) {
                output[key] = filteredAttributes[key]
            }
        } else if (
            [
                'charts',
                'measure',
                'measure_2',
                'dimension',
                'dimension_2',
                'measure_color',
                'date_aggregation_level',
            ].includes(key)
        ) {
            output[key] = filteredAttributes[key].split(';')
        } else {
            output[key] = filteredAttributes[key]
        }
    }
    return output
}

export function cleanObject(object: any) {
    for (const key in object) {
        if (!object[key] && object[key] !== 0) delete object[key]
    }
    if (Object.keys(object).length === 0) return undefined
    return object
}
