
import draggable from 'vuedraggable'
import Format from '@/components/Profile/Format.vue'
import FormatService from '@/services/formatService'
import SourceService from '@/services/sourceService'
import ButtonsMoveEdit from '@/components/Pages/ButtonsMoveEdit.vue'
import { GlobalMixins } from '@/mixins'
import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Format as _Format, nanoid, SourceMemory, SourceMode } from '@/ponychart'

const defaultFormat: _Format = {
    id: '',
    type: 'main',
    mainFormat: '#,##0.00;-#,##0.00',
    diffFormat: '▲+#,##0.00;▼-#,##0.00',
    evoFormatId: 'percent',
    alias: 'Number (Standard)',
    suffix: '#',
    disabled: false,
    order: -1,
}
const memoryInstance = SourceMemory.getInstance()

@Component({
    components: { Format, draggable, ButtonsMoveEdit },
})
export default class FormatsComponent extends mixins(GlobalMixins) {
    @Prop({ type: Boolean, default: false })
    readonly isAdminMode?: boolean
    @Prop({ type: Array, default: () => [] })
    readonly formats!: _Format[]
    @Prop({type: Number, default: 0})
    readonly sourceId?: number
    @Prop({type: String, default: SourceMode.CLASSIC})
    readonly sourceMode?: SourceMode

    mainFormats: _Format[] = []
    evoFormats: _Format[] = []
    defaultFormat: _Format = { ...defaultFormat }
    newFormat: _Format = { ...defaultFormat }
    isLoading = false
    deleteLoadings: string[] = []
    dragOptions = {
        group: 'formats',
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    }
    focusId: string | null = null
    dialog = false

    get isDemoMode(){
        return this.sourceMode === SourceMode.DEMO
    }

    async mounted() {
        await this.listFormats()
        this.$emit('set', { loadingStep: false })
    }
    async closeDialog() {
        this.dialog = false
        await this.$nextTick()
        this.newFormat = Object.assign({}, this.defaultFormat)
    }
    focus(focusId?: string) {
        if (!focusId) this.focusId = null
        else this.focusId = focusId
    }
    async listFormats() {
        const formats = this.isAdminMode
            ? await FormatService.listFormats()
            : this.formats
        this.evoFormats = formats.filter((f: _Format) => f.type === 'evo')
        this.mainFormats = formats.filter((f: _Format) => f.type === 'main')
    }
    async deleteFormat(formatId: string) {
        this.deleteLoadings.push(formatId)
        try {
            if (this.isAdminMode) {
                await FormatService.deleteFormat(formatId)
            } else {
                if (!this.sourceId)
                    throw new Error('Source id should not be 0')
                const formats = [
                    ...this.evoFormats,
                    ...this.mainFormats,
                ].filter((f) => f.id !== formatId)
                if(!this.isDemoMode){
                    await SourceService.saveSource(
                        this.sourceId,
                        {
                            formats,
                        },
                        { noResult: true }
                    )
                }
                memoryInstance.setFormats(formats)
            }
        } catch (e) {
            this.showToast()
        } finally {
            this.deleteLoadings = this.deleteLoadings.filter(
                (id: string) => id !== formatId
            )
            this.mainFormats = this.mainFormats.filter(
                (f: _Format) => f.id !== formatId
            )
        }
    }
    async updateOrder() {
        this.isLoading = true
        try {
            if (this.isAdminMode) {
                const formats = this.mainFormats.map(
                    (f: _Format, i: number) => ({
                        formatId: f.id || '',
                        order: i,
                    })
                )
                await FormatService.updateOrder(formats)
            } else {
                await this.saveFormatsNonAdminMode()
            }
        } catch (e) {
            this.showToast()
        } finally {
            this.isLoading = false
        }
    }
    async saveFormatsNonAdminMode() {
        if (!this.sourceId) throw new Error('Source id should not be 0')
        const formats = [...this.mainFormats, ...this.evoFormats]
        if(!this.isDemoMode){
            await SourceService.saveSource(
                this.sourceId,
                { formats },
                { noResult: true }
            )
        }
        memoryInstance.setFormats(formats)
    }
    async createFormat() {
        this.isLoading = true
        try {
            const format = this.isAdminMode
                ? await FormatService.createFormat(this.newFormat)
                : { ...this.newFormat, id: nanoid.id() }
            console.log(format)
            this.mainFormats.push(format)
            await this.updateOrder()
            this.dialog = false
        } catch (e) {
            this.showToast()
        } finally {
            this.isLoading = false
        }
    }

    @Watch('dialog')
    onDialogClose(dialog: boolean) {
        if (!dialog) this.closeDialog()
    }
}
