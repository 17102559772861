import { Position } from '@/ponychart/charts/types'
import { DeviceType } from 'ponychart'

export const FILE_EXPIRATION_DAYS = 8

export const WINDOW_WIDTHS = {
  [DeviceType.DESKTOP]: 1700,
  [DeviceType.TABLET]: 1100,
  [DeviceType.MOBILE]: 600
} as const

export const BANDS: Position[][] = [
  [
    { x: 20, y: 10 },
    { x: 30, y: 90 }
  ],
  [
    { x: 70, y: 10 },
    { x: 80, y: 90 }
  ]
]

export function getBands(width: number, height: number): Position[][] {
  return BANDS.map((band: Position[]) => (band.map((p: Position) => ({ x: p.x / 100 * width, y: p.y / 100 * height }))))
}
export const SELECT_PIXEL_ITEMS = [
  0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20
]

export const CARD_PIXEL_HEIGHTS = [
  100, 200, 300, 400, 500, 600, 700, 800, 900
]

export const SIDEBAR_ITEMS = ['lateral', 'top', '']

export const TABLEAU_RED = '#d1615d'
export const TABLEAU_BLUE = '#5778a4'
export const TABLEAU_COLORS = {
  blue: TABLEAU_BLUE,
  orange: '#e49444',
  green: '#6a9f58',
  red: TABLEAU_RED,
  purple: '#a87c9f',
  brown: '#967662',
  pink: '#f1a2a9',
  grey: '#b8b0ac',
  yellow: '#e7ca60',
  teal: '#85b6b2',

} as const
export const TABLEAU_COLOR_VALUES = Object.freeze(Object.values(TABLEAU_COLORS))
export const TABLEAU_BRIGHT_VALUES = [
  "#1F77B4",
  "#FF7F0E",
  "#2CA02C",
  "#D62728",
  "#9467BD",
  "#8C564B",
  "#E377C2",
  "#7F7F7F",
  "#BCBD22",
  "#17BECF"
]
export const TABLEAU_LIGHT_VALUES = [
  "#AEC7E8",
  "#FFBB78",
  "#98DF8A",
  "#FF9896",
  "#C5B0D5",
  "#C49C94",
  "#F7B6D2",
  "#C7C7C7",
  "#DBDB8D",
  "#9EDAE5",
]
export const TABLEAU_BLIND_VALUES = [
  "#006BA4",
  "#FF800E",
  "#ABABAB",
  "#595959",
  "#5F9ED1",
  "#C85200",
  "#898989",
  "#A2C8EC",
  "#FFBC79",
  "#CFCFCF"
]

