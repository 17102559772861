import { Lang } from '@/ponychart'
import axios from 'axios'

export default {
  listUsers: async function () {
    const {
      data: { users }
    } = await axios(`/v1/users`)
    return users
  },
  deleteUser: async function (userId: string) {
    await axios.delete(`/v1/users/${userId}`)
  },
  updateUser: async function (userId: string, user: any) {
    const { data } = await axios.put(`/v1/users/${userId}`, user)
    return data
  },
  getCurrentUser: async function () {
    const {
      data: { user }
    } = await axios(`/v1/user`)
    return user
  },
  getMyself: async function () {
    const { data } = await axios('/v1/me')
    return data
  },
  createUser: async function (userToCreate: any) {
    const {
      data: { user }
    } = await axios.post(`/v1/users`, userToCreate)
    return user
  },
  updateUserLang: async function (userId: string, lang: Lang) {
    await axios({
      url: `/v1/users/${userId}/lang/${lang}`,
      method: 'PUT'
    })
  },
  saveCompany: async function (companyName: string, stripeEmail: string, accessRoles?: string[]) {
    const payload: any = {
      name: companyName,
      stripeEmail: stripeEmail
    }
    if (accessRoles) payload.accessRoles = accessRoles
    const { data } = await axios({
      url: `/v1/company`,
      data: payload,
      method: 'put'
    })
    return data
  }
}
