import { Vue, Component } from 'vue-property-decorator'
// import { debounce } from 'lodash'

const BUTTON_CLASS = 'd-btn'
const COMPARISON_PERIODS_CLASS = 'd-comparison-periods'
const COMPARISON_PERIOD_COUNT_CLASS = 'd-comparison-period-count'
const VUE_APP_PUBLIC_PATH = process.env.VUE_APP_PUBLIC_PATH || '/'

// Used to attach JS events to a component to listen to click events on the header
@Component
export class PageHeaderEventListeners extends Vue {
    navigationClicked = false
    filterClicked = false

    selectChanged(event: any) {
        const period =
            event.target.options[event.target.selectedIndex].getAttribute(
                'data-value'
            )
        const periodCounts = document.getElementsByClassName(
            COMPARISON_PERIOD_COUNT_CLASS
        )
        const display = period.includes('_y_') ? 'none' : 'block'
        for (const periodCount of periodCounts) {
            ;(periodCount as HTMLElement).style.display = display
        }
    }

    buttonClicked(clickedPayload: any) {
        const element = clickedPayload?.srcElement
        const src = element.src

        const isClosed = src.includes('/img/close')
        const isBlack = src.includes('-black')

        const classList = clickedPayload.srcElement?.classList
        if (classList?.contains('d-menu')) {
            this.navigationClicked = !isClosed
            element.src = `${VUE_APP_PUBLIC_PATH}img/${
                isClosed ? 'menu' : 'close'
            }-${isBlack ? 'black' : 'white'}.png`
            const navigationContainers = document.querySelectorAll(
                '.d-navigation-container'
            )
            for (const navigationContainer of navigationContainers)
                (navigationContainer as HTMLElement).style.display = !isClosed
                    ? 'block'
                    : 'none'
        } else if (classList?.contains('d-filter')) {
            this.filterClicked = !isClosed
            element.src = `${VUE_APP_PUBLIC_PATH}img/${
                isClosed ? 'filter' : 'close'
            }-${isBlack ? 'black' : 'white'}.png`
            const filterContainers = document.querySelectorAll(
                '.d-filter-container'
            )
            for (const filterContainer of filterContainers)
                (filterContainer as HTMLElement).style.display = !isClosed
                    ? 'block'
                    : 'none'
        } else if (classList?.contains(BUTTON_CLASS)) {
            const indexClass = [...classList].find((c) =>
                c.startsWith('d-index-')
            )
            const index = parseInt(indexClass.split('d-index-')[1])
            this.$emit('sidebarClicked', index)
        }
    }

    // emitPageButtonEvent = debounce((e) => {
    //     this.buttonClicked(e)
    // }, 250)

    cleanPageButtonEvent(): Element[] {
        const cleaned: Element[] = []
        // Clean Button events
        for (const element of document.getElementsByClassName(BUTTON_CLASS)) {
            element.removeEventListener('click', this.buttonClicked)
            cleaned.push(element)
        }
        return cleaned
    }
    cleanChangeSelectEvent(): Element[] {
        const cleaned: Element[] = []
        // Clean change events
        for (const element of document.getElementsByClassName(
            COMPARISON_PERIODS_CLASS
        )) {
            element.removeEventListener('change', this.selectChanged)
            cleaned.push(element)
        }
        return cleaned
    }

    async attachPageButtonEvent() {
        const elements = this.cleanPageButtonEvent()
        for (const element of elements) {
            element.addEventListener('click', this.buttonClicked)
        }
    }

    async attachChangeSelectEvent() {
        const elements = this.cleanChangeSelectEvent()
        for (const element of elements) {
            element.addEventListener('click', this.selectChanged)
        }
    }

    beforeDestroy() {
        this.cleanPageButtonEvent()
        this.cleanChangeSelectEvent()
    }
}
