import { SelectorId } from '@/ponychart/header/types'
import { PageMode } from '@/ponychart/page/types'
import {
    BandMode,
    ColorLegend,
    ColumnType,
    GeoMode,
    OverBarMode,
    YoYBarMode,
    TdPeriod,
    MeasureColorLocation,
    WithLabel,
} from '@/ponychart/trait/types'
import {
    ChartType,
    ComparisonPeriod,
    Orientation,
    TimeSelectorType,
    TraitId,
    HeaderDesign,
} from 'ponychart'
import { TextElementType } from '../textElement/type'

import { Lang } from './types'

type Translations = {
    [l in Lang]: {
        [k in any]: string | { [k in any]: string }
    }
}

export const translations: Translations = {
    [Lang.EN]: {
        compareWith: 'Compare with',
        empty: '(none)',
        ago: {
            prefix: '',
            suffix: 'ago',
        },
        customtitle: 'My page title here',
        vs: 'VS',
        by: 'by',
        for: 'for',
        column: 'columns',
        chapter: 'Chapter',
        row: 'arranged horizontally',
        col: 'arranged vertically',
        chartPerMeasure: 'chart per measure',
        chartsPerMeasure: 'charts per measure',
        chartsAlias: 'charts',
        chartAlias: 'chart',
        top: 'on top',
        down: 'at the bottom',
        left: 'to the left',
        right: 'to the right',
        [TraitId.COLUMN_COUNT]: {
            label: 'Choose how many columns',
        },
        [TraitId.INCLUDE_PAGE_BLOCK_HEADER]: {
            label: 'Include a header for the page block',
        },
        [TraitId.LABEL_SIZE]: {
            label: 'Text size (%)',
        },
        dates: {},
        [TraitId.COMPARISON_PERIODS]: {
            label: 'Comparison periods',
            [ComparisonPeriod.JANUARY]: 'january',
            [ComparisonPeriod.FEBRUARY]: 'february',
            [ComparisonPeriod.MARCH]: 'march',
            [ComparisonPeriod.APRIL]: 'april',
            [ComparisonPeriod.MAY]: 'may',
            [ComparisonPeriod.JUNE]: 'june',
            [ComparisonPeriod.JULY]: 'july',
            [ComparisonPeriod.AUGUST]: 'august',
            [ComparisonPeriod.SEPTEMBER]: 'september',
            [ComparisonPeriod.OCTOBER]: 'october',
            [ComparisonPeriod.NOVEMBER]: 'november',
            [ComparisonPeriod.DECEMBER]: 'december',
            [ComparisonPeriod.DAY]: 'day(s) before',
            [ComparisonPeriod.WEEK]: 'week(s) before',
            [ComparisonPeriod.MONTH]: 'month(s) before',
            [ComparisonPeriod.QUARTER]: 'quarter(s) before',
            [ComparisonPeriod.YEAR]: 'year(s) before',
        },
        textElement: {
            [TextElementType.DIMENSION]: 'Dimension',
            [TextElementType.MEASURE]: 'Measure',
            [TextElementType.MEASURE_CHOICE]: 'Measure Choice',
            [TextElementType.DIMENSION_CHOICE]: 'Dimension Choice',
            [TextElementType.DATE_AGGREGATION_LEVEL_CHOICE]:
                'Date Aggregation Level Choice',
            [TextElementType.THIS_MEASURE]: 'Measure Selected Period',
            [TextElementType.LAST_MEASURE]: 'Measure Previous Period',
            [TextElementType.EVOLUTION]: 'Measure % Difference',
            [TextElementType.EVOLUTION_COLORED]:
                'Measure % Difference (colored)',
            [TextElementType.DIFFERENCE]: 'Measure Difference',
            [TextElementType.DIFFERENCE_COLORED]:
                'Measure Difference (colored)',
            [TextElementType.DATE]: 'Date',
            [TextElementType.LAST_PERIOD]: 'Previous Period',
            [TextElementType.THIS_PERIOD]: 'Selected Period',
            [TextElementType.COMPARISON_PERIOD]: 'Comparison Period',
            [TextElementType.GEO_CITY]: 'City',
            [TextElementType.GEO_REGION]: 'Region',
            [TextElementType.GEO_COUNTRY]: 'Country',
        },
        [TraitId.ALIGN_VERTICALLY]: {
            label: 'Align charts vertically',
        },
        [TraitId.COLOR]: {
            label: 'Color Legend',
            description:
                'Allows to choose how color legend can be applied on your chart',
            [ColorLegend.DIMENSION]: 'Dimension',
            [ColorLegend.DELTA]: '% change over period',
            [ColorLegend.FIXED]: 'Fixed color',
            [ColorLegend.VOLUME]: 'Volume of measure',
        },
        [TraitId.DIMENSION]: {
            label: 'Choose dimensions:',
            description:
                'Choose one or many dimensions for your chart. If more than one dimension is selected for a single chart, a parameter will be created for your users to switch between dimensions',
            [ColumnType.SINGLE]: '(No dimension)',
            [ColumnType.DYNAMIC]: '(dynamic dimension)',
        },
        [TraitId.MEASURE]: {
            label: 'Choose measures:',
            description:
                'Allows to choose the measures for your chart. If more than one measure is selected for a single chart, a parameter will be created for your users to switch between measures',
            [ColumnType.SINGLE]: '(No measure)',
            [ColumnType.DYNAMIC]: '(dynamic measure)',
        },
        geo_level: {
            label: 'Choose the geo level of your map',
        },
        [TraitId.CHART_1]: {
            label: 'Choose the 1st graph',
        },
        [TraitId.CHART_2]: {
            label: 'Choose the 2nd graph',
        },
        [TraitId.CHART_3]: {
            label: 'Choose the 3rd graph',
        },
        [TraitId.CHART_4]: {
            label: 'Choose the 4th graph',
        },
        [TraitId.CHART_5]: {
            label: 'Choose the 5th graph',
        },
        [TraitId.CHART_SUBTYPE]: {
            label: 'Additional features',
            [BandMode.NONE]: '(None)',
            [BandMode.BAND]: 'Includes period highlighting bands',
            [OverBarMode.NONE]: '(None)',
            [OverBarMode.OVER_BAR]: 'Includes bars overlapping for each period',
            [YoYBarMode.NONE]: '(None)',
            [YoYBarMode.PERIOD_COLOR]:
                'YoY bar chart with period hlighlighting',
            [GeoMode.COUNTRY]: 'Country level',
            [GeoMode.REGION]: 'Region level',
            [GeoMode.CITY]: 'City level',
            [WithLabel.NONE]: '(None)',
            [WithLabel.WITH_LABEL]: 'Add a label on bars',
        },
        [TraitId.MEASURE_COLOR]: {
            label: 'Apply measure color to:',
            [MeasureColorLocation.BORDER]: 'the borders',
            [MeasureColorLocation.TITLE]: 'the title',
            [MeasureColorLocation.CURVES]: 'the charts',
        },
        [TraitId.PAGE_MODE]: {
            label: 'Organize:',
            description:
                'You can either apply one chart for each dimension (and measure will be constant) or one chart for each measure (and dimension will be constant)',
            [PageMode.MEASURE]: '1 graph/measure',
            [PageMode.DIMENSION]: '1 graph/dimension',
        },
        [TraitId.CARD_HEIGHT]: {
            label: 'Card height',
        },
        [TraitId.TIME_SELECTOR_ORIENTATION]: {
            label: 'Orientation:',
            [Orientation.VERTICAL]: 'Vertical',
            [Orientation.HORIZONTAL]: 'Horizontal',
        },
        [TraitId.TIME_SELECTOR_TYPE]: {
            label: 'Customize time selector:',
            description:
                'Depending upon your needs, you can pick from different date selectors (built from parameters). It can be useful to force your users to select months or to let them freedom',
            [TimeSelectorType.DAILY_FROM_TO]: 'Daily period selector',
            [TimeSelectorType.DAILY]: 'Single day selector',
            [TimeSelectorType.MONTHLY_FROM_TO]: 'Monthly period selector',
            [TimeSelectorType.MONTHLY]: 'Single month selector',
            [TimeSelectorType.MONTHLY_FROM_TO_SPLIT]:
                'Year+Month period selector',
            [TimeSelectorType.MONTHLY_SPLIT]: 'Single Year+Month selector',
        },
        [TraitId.INCLUDE_CARD_TITLE]: {
            label: 'Include a title',
        },
        [TraitId.DATE_AGGREGATION_LEVEL]: {
            label: 'Date Aggregation Level',
        },
        dashboard: {
            [ChartType.AREA]: 'Area charts',
            [ChartType.BAR]: 'Bar charts',
            [ChartType.LOLLIPOP]: 'Lollipop charts',
            [ChartType.LINE]: 'Line charts',
            [ChartType.MAP]: 'Maps',
            [ChartType.DONUT]: 'Donut charts',
            [ChartType.KPI]: 'KPI Card',
        },
        [TraitId.GEO_REGION]: {
            label: 'Choose the region field',
        },
        [TraitId.GEO_COUNTRY]: {
            label: 'Choose the country field',
        },
        [TraitId.GEO_CITY]: {
            label: 'Choose the city field',
        },
        includeChart: 'Include {chart}',
        charts: {
            label: 'Charts',
            [ChartType.NONE]: '(None)',
            [ChartType.AREA]: 'Area chart',
            [ChartType.LINE]: 'Line chart',
            [ChartType.KPI]: 'KPI Card',
            [ChartType.COLUMN]: 'Column container',
            [ChartType.ROW]: 'Row container',
            [ChartType.LOLLIPOP]: 'Lollipop chart',
            [ChartType.DONUT]: 'Donut chart',
            [ChartType.PIE]: 'Pie chart',
            [ChartType.BAR]: 'Bar chart',
            [ChartType.TIME_BAR]: 'Monthly Bar chart',
            [ChartType.YOY_TIME_BAR]: 'YoY Monthly Bar chart',
            [ChartType.MAP]: 'Map',
        },
        titles: {
            [ChartType.AREA]: 'Stacked <measure> by <dimension> over time',
            [ChartType.LINE]: '<measure> {hasDimension}over time',
            [ChartType.KPI]: '<measure>',
            [ChartType.LOLLIPOP]: 'Top <measure> by <dimension>',
            [ChartType.DONUT]: 'Share of <measure> by <dimension>',
            [ChartType.BAR]: 'Top <measure> by <dimension>',
        },
        flex: {
            label: 'Distribute evenly?',
            row: 'Horizontal Container',
            column: 'Vertical Container',
            template: 'Templates',
            kpiCard: 'KPI card',
            text: 'Text',
            title: 'Title',
        },
        months: {
            jan: 'January',
            feb: 'February',
            mar: 'March',
            apr: 'April',
            may: 'May',
            jun: 'June',
            jul: 'July',
            aug: 'August',
            sep: 'September',
            oct: 'October',
            nov: 'November',
            dec: 'December',
        },
        date: {
            year: 'Year',
            quarter: 'Quarter',
            month: 'Month',
            week: 'Week',
            day: 'Day',
        },
        dynamic: {
            measure: 'Dynamic measure',
            dimension: 'Dynamic dimension',
        },
        selects: {
            date: 'Date',
            [SelectorId.START_DATE_SELECTOR]: 'Start date',
            [SelectorId.END_DATE_SELECTOR]: 'End date',
            [SelectorId.START_YEAR_MONTH_SELECTOR]: 'Start date',
            [SelectorId.END_YEAR_MONTH_SELECTOR]: 'End date',
            [SelectorId.MEASURE_SELECTOR]: 'Choose measure',
            [SelectorId.MEASURE_SELECTOR_2]: 'Choose 2nd measure',
            [SelectorId.COMPARISON_PERIOD_SELECTOR]: 'Choose comparison period',
            [SelectorId.DIMENSION_SELECTOR]: 'Choose dimension',
            [SelectorId.DIMENSION_SELECTOR_2]: 'Choose 2nd dimension',
            [SelectorId.DATE_AGGREGATION_SELECTOR]: 'Choose date aggregation',
            [SelectorId.CHART_SELECTOR]: 'Choose chart',
            [TdPeriod.MTD]: 'MTD',
            [TdPeriod.QTD]: 'QTD',
            [TdPeriod.YTD]: 'YTD',
            [TdPeriod.WTD]: 'WTD',
            [HeaderDesign.CENTERED]: 'centered',
            [HeaderDesign.SIMPLE]: 'left aligned',
            [HeaderDesign.SIMPLE_VERTICAL]: 'in columns',
            [HeaderDesign.DENSE]: 'dense',
        },
        [TraitId.BLOCK_HAS_TIME_INDICATION]: {
            label: 'Include time indication in page block',
        },
        [TraitId.BLOCK_TITLE]: {
            label: 'Block header text',
        },
        label: {
            label: 'Label design',
        },
        [TraitId.CARD_TITLE]: {
            label: 'Card header text content',
        },
        tooltip: {
            label: 'Tooltip design',
        },
        [TraitId.SIDEBAR]: {
            label: 'Sidebar',
            lateral: 'Lateral navbar',
            top: 'Top navbar',
            none: '(None)',
        },
    },
    [Lang.ES]: {
        compareWith: 'Comparar con:',
        [TraitId.ALIGN_VERTICALLY]: {
            label: 'Alinee los gráficos verticalmente',
        },
        [TraitId.BLOCK_HAS_TIME_INDICATION]: {
            label: 'Incluir indicación de periodo',
        },
        [TraitId.BLOCK_TITLE]: {
            label: 'Texto de cabecera',
        },
        [TraitId.CARD_HEIGHT]: {
            label: 'Altura de la carta',
        },
        [TraitId.CARD_TITLE]: {
            label: 'Encabezado de carta',
        },
        [TraitId.CHART_1]: {
            label: 'Elija el primer gráfico',
        },
        [TraitId.CHART_2]: {
            label: 'Elija el segundo gráfico',
        },
        [TraitId.CHART_3]: {
            label: 'Elija el tercer gráfico',
        },
        [TraitId.CHART_4]: {
            label: 'Elija el cuarto gráfico',
        },
        [TraitId.CHART_5]: {
            label: 'Elija el quinto gráfico',
        },
        [TraitId.CHART_SUBTYPE]: {
            [BandMode.BAND]: 'Con bandas de périodo',
            [OverBarMode.OVER_BAR]: 'Barras superpuestas para cada período',
            [YoYBarMode.PERIOD_COLOR]:
                'Gráfico de barras tú con punto resaltado',
            [GeoMode.COUNTRY]: 'A nivel de país',
            [GeoMode.REGION]: 'Nivel de región',
            [GeoMode.CITY]: 'A nivel de ciudad',
            label: 'Funciones adicionales',
            [BandMode.NONE]: '(Ninguna)',
            [OverBarMode.NONE]: '(Ninguna)',
            [YoYBarMode.NONE]: '(Ninguna)',
            [WithLabel.NONE]: '(Ninguno)',
            [WithLabel.WITH_LABEL]: 'Añadir una etiqueta a las barras',
        },
        [TraitId.COLOR]: {
            label: 'Leyenda de los colores',
            description:
                'Permite elegir cómo se puede aplicar la leyenda de colores a su carta',
            [ColorLegend.DIMENSION]: 'Dimensión',
            [ColorLegend.DELTA]: '% de cambio durante el período',
            [ColorLegend.FIXED]: 'Color fijo',
            [ColorLegend.VOLUME]: 'Volumen de medida',
        },
        [TraitId.COLUMN_COUNT]: {
            label: 'Elija cuantas columnas',
        },
        [TraitId.COMPARISON_PERIODS]: {
            label: 'Períodos de comparación',
            [ComparisonPeriod.JANUARY]: 'vs enero',
            [ComparisonPeriod.PERIOD]: 'período antes',
            [ComparisonPeriod.DAY]: 'día(s) antes',
            [ComparisonPeriod.WEEK]: 'semana(s) antes',
            [ComparisonPeriod.MONTH]: 'mes(es) antes',
            [ComparisonPeriod.QUARTER]: 'trimestre(s) antes',
            [ComparisonPeriod.YEAR]: 'año(s) antes',
            [ComparisonPeriod.JANUARY]: 'enero',
            [ComparisonPeriod.FEBRUARY]: 'febrero',
            [ComparisonPeriod.MARCH]: 'marzo',
            [ComparisonPeriod.APRIL]: 'abril',
            [ComparisonPeriod.MAY]: 'mayo',
            [ComparisonPeriod.JUNE]: 'junio',
            [ComparisonPeriod.JULY]: 'julio',
            [ComparisonPeriod.AUGUST]: 'agosto',
            [ComparisonPeriod.SEPTEMBER]: 'septiembre',
            [ComparisonPeriod.OCTOBER]: 'octubre',
            [ComparisonPeriod.NOVEMBER]: 'noviembre',
            [ComparisonPeriod.DECEMBER]: 'diciembre',
        },
        [TraitId.DATE_AGGREGATION_LEVEL]: {
            label: 'Nivel de agregación de fechas',
        },
        [TraitId.DIMENSION]: {
            label: 'Elija las dimensiones:',
            description:
                'Elija una o varias dimensiones para la tabla. Si se selecciona más de una dimensión para un solo gráfico, se creará un parámetro para que los usuarios cambien entre dimensiones',
            [ColumnType.SINGLE]: '(Sin dimensión)',
            [ColumnType.DYNAMIC]: '(dimensión dinámica)',
        },
        [TraitId.GEO_CITY]: {
            label: 'Elija el campo de la ciudad',
        },
        [TraitId.GEO_COUNTRY]: {
            label: 'Elija el campo de país',
        },
        [TraitId.GEO_REGION]: {
            label: 'Elija el campo de región',
        },
        [TraitId.INCLUDE_CARD_TITLE]: {
            label: 'Incluir un título',
        },
        [TraitId.INCLUDE_PAGE_BLOCK_HEADER]: {
            label: 'Incluye un encabezado para el bloque de páginas',
        },
        [TraitId.LABEL_SIZE]: {
            label: 'Tamaño del texto (%)',
        },
        [TraitId.MEASURE]: {
            label: 'Elija las medidas:',
            description:
                'Permite elegir las medidas de su gráfica. Si se selecciona más de una medida para un solo gráfico, se creará un parámetro para que los usuarios cambien entre medidas',
            [ColumnType.SINGLE]: '(Sin medida)',
            [ColumnType.DYNAMIC]: '(medida dinámica)',
        },
        [TraitId.MEASURE_COLOR]: {
            label: 'Aplica el color de medida a:',
            [MeasureColorLocation.BORDER]: 'las fronteras',
            [MeasureColorLocation.TITLE]: 'el título',
            [MeasureColorLocation.CURVES]: 'los gráficos',
        },
        [TraitId.PAGE_MODE]: {
            label: 'Organice:',
            description:
                'Puede aplicar un gráfico para cada dimensión (y la medida será constante) o un gráfico para cada medida (y la dimensión será constante)',
            [PageMode.MEASURE]: '1 gráfico/medida',
            [PageMode.DIMENSION]: '1 gráfico/dimensión',
        },
        [TraitId.SIDEBAR]: {
            label: 'Barra lateral',
            lateral: 'Barra de navegación lateral',
            top: 'Barra de navegación superior',
            none: '(Ninguno)',
        },
        [TraitId.TIME_SELECTOR_ORIENTATION]: {
            label: 'Orientación:',
            [Orientation.VERTICAL]: 'Vertical',
            [Orientation.HORIZONTAL]: 'Horizontal',
        },
        [TraitId.TIME_SELECTOR_TYPE]: {
            label: 'Personalice el selector de hora:',
            description:
                'Según sus necesidades, puede elegir entre diferentes selectores de fecha (construidos a partir de parámetros). Puede ser útil para obligar a sus usuarios a seleccionar meses o darles libertad',
            [TimeSelectorType.DAILY_FROM_TO]: 'Selector de períodos diarios',
            [TimeSelectorType.DAILY]: 'Selector de un día',
            [TimeSelectorType.MONTHLY_FROM_TO]:
                'Selector de períodos mensuales',
            [TimeSelectorType.MONTHLY]: 'Selector de un mes',
            [TimeSelectorType.MONTHLY_FROM_TO_SPLIT]:
                'Selector de período de año+mes',
            [TimeSelectorType.MONTHLY_SPLIT]: 'Selector de un año+mes',
        },
        by: 'por',
        chapter: 'capítulo',
        chartAlias: 'gráfico',
        chartPerMeasure: 'gráfico por medida',
        charts: {
            label: 'Gráficos',
            [ChartType.NONE]: '(Ninguno)',
            [ChartType.AREA]: 'Gráfico de áreas',
            [ChartType.LINE]: 'Gráfico de líneas',
            [ChartType.KPI]: 'Tarjeta de KPI',
            [ChartType.COLUMN]: 'Contenedor de columnas',
            [ChartType.ROW]: 'Contenedor de filas',
            [ChartType.LOLLIPOP]: 'Lista de piruletas',
            [ChartType.DONUT]: 'Gráfico de anillos',
            [ChartType.BAR]: 'Gráfico de barras',
            [ChartType.PIE]: 'Gráfico circular',
            [ChartType.TIME_BAR]: 'Gráfico de barras mensual',
            [ChartType.YOY_TIME_BAR]: 'Gráfico de barras mensual interanual',
            [ChartType.MAP]: 'Mapa',
        },
        chartsAlias: 'gráficos',
        chartsPerMeasure: 'gráficos por medida',
        col: 'verticalmente',
        column: 'columnas',
        customtitle: 'El título de mi página aquí',
        dashboard: {
            [ChartType.AREA]: 'Gráficos de áreas',
            [ChartType.BAR]: 'Gráficos de barras',
            [ChartType.LOLLIPOP]: 'Listas de piruletas',
            [ChartType.LINE]: 'Gráficos de líneas',
            [ChartType.MAP]: 'Mapas',
            [ChartType.DONUT]: 'Gráficos de anillos',
            [ChartType.KPI]: 'Tarjeta de KPI',
        },
        date: {
            year: 'Año',
            quarter: 'Trimestre',
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
        },
        dates: {},
        down: 'en la parte inferior',
        dynamic: {
            measure: 'Medida dinámica',
            dimension: 'Dimensión dinámica',
        },
        empty: '(ninguno)',
        flex: {
            label: '¿Distribuir uniformemente?',
            row: 'Contenedor horizontal',
            column: 'Contenedor vertical',
            template: 'Plantillas',
            kpiCard: 'Tarjeta de KPI',
            text: 'Texto',
            title: 'Título',
        },
        for: 'para',
        geo_level: {
            label: 'Elija el nivel geográfico de tu mapa',
        },
        includeChart: 'Incluir {chart}',
        label: {
            label: 'Diseño de etiquetas',
        },
        left: 'a la izquierda',
        months: {
            jan: 'Enero',
            feb: 'Febrero',
            mar: 'Marzo',
            apr: 'Abril',
            may: 'Mayo',
            jun: 'Junio',
            jul: 'Julio',
            aug: 'Agosto',
            sep: 'Septiembre',
            oct: 'Octubre',
            nov: 'Noviembre',
            dec: 'Diciembre',
        },
        right: 'a la derecha',
        row: 'horizontalmente',
        selects: {
            date: 'Fecha',
            [SelectorId.START_DATE_SELECTOR]: 'Fecha de inicio',
            [SelectorId.END_DATE_SELECTOR]: 'Fecha de finalización',
            [SelectorId.START_YEAR_MONTH_SELECTOR]: 'Fecha de inicio',
            [SelectorId.END_YEAR_MONTH_SELECTOR]: 'Fecha de finalización',
            [SelectorId.MEASURE_SELECTOR]: 'Elija la medida',
            [SelectorId.MEASURE_SELECTOR_2]: 'Elija la segunda medida',
            [SelectorId.COMPARISON_PERIOD_SELECTOR]:
                'Elija el período de comparación',
            [SelectorId.DIMENSION_SELECTOR]: 'Elija la dimensión',
            [SelectorId.DIMENSION_SELECTOR_2]: 'Elija la segunda dimensión',
            [SelectorId.DATE_AGGREGATION_SELECTOR]:
                'Elija la agregación de fechas',
            [TdPeriod.MTD]: 'MTD',
            [TdPeriod.QTD]: 'QTD',
            [TdPeriod.YTD]: 'HASTA LA FECHA',
            [TdPeriod.WTD]: 'WTD',
            [HeaderDesign.CENTERED]: 'centrado',
            [HeaderDesign.SIMPLE]: 'alineado a la izquierda',
            [HeaderDesign.SIMPLE_VERTICAL]: 'en columnas',
            [HeaderDesign.DENSE]: 'denso',
            [SelectorId.CHART_SELECTOR]: 'Elige gráfico',
        },
        textElement: {
            [TextElementType.DIMENSION]: 'Dimensión',
            [TextElementType.MEASURE]: 'Medida',
            [TextElementType.MEASURE_CHOICE]: 'Elección de Medida',
            [TextElementType.DIMENSION_CHOICE]: 'Elección de Dimensión',
            [TextElementType.DATE_AGGREGATION_LEVEL_CHOICE]:
                'Elección de nivel de agregación de fecha',
            [TextElementType.THIS_MEASURE]: 'Medida por período seleccionado',
            [TextElementType.LAST_MEASURE]: 'Medida por período anterior',
            [TextElementType.EVOLUTION]: 'Evolución de Medida (%)',
            [TextElementType.EVOLUTION_COLORED]:
                'Evolución de Medida (%, colorada)',
            [TextElementType.DIFFERENCE]: 'Diferencia de Medida',
            [TextElementType.DIFFERENCE_COLORED]:
                'Diferencia de Medida (colorada)',
            [TextElementType.DATE]: 'Fecha',
            [TextElementType.LAST_PERIOD]: 'Periodo anterior',
            [TextElementType.THIS_PERIOD]: 'Periodo seleccionado',
            [TextElementType.COMPARISON_PERIOD]: 'Periodo de comparación',
            [TextElementType.GEO_CITY]: 'Ciudad',
            [TextElementType.GEO_REGION]: 'Región',
            [TextElementType.GEO_COUNTRY]: 'País',
        },
        titles: {
            [ChartType.AREA]: '<measure>Apilado <dimension>con el tiempo',
            [ChartType.LINE]: '<measure>{hasDimension} con el tiempo',
            [ChartType.KPI]: '<measure>',
            [ChartType.LOLLIPOP]: 'Top <measure>por <dimension>',
            [ChartType.DONUT]: 'Cuota de <measure>por <dimension>',
            [ChartType.BAR]: 'Top <measure>por <dimension>',
        },
        tooltip: {
            label: 'Diseño de información sobre herramientas',
        },
        top: 'en la parte superior',
        vs: 'FRENTE A',
        ago: {
            prefix: 'hace',
            suffix: '',
        },
    },
    [Lang.FR]: {
        compareWith: 'Comparer avec:',
        ago: {
            prefix: 'il y a',
            suffix: '',
        },
        [TraitId.ALIGN_VERTICALLY]: {
            label: 'Aligner les graphiques verticalement',
        },
        [TraitId.BLOCK_HAS_TIME_INDICATION]: {
            label: "Inclure l'indication de la période sélectionnée dans le bloc de page",
        },
        [TraitId.BLOCK_TITLE]: {
            label: "Texte d'en-tête de bloc",
        },
        [TraitId.CARD_HEIGHT]: {
            label: 'Hauteur de la carte',
        },
        [TraitId.CARD_TITLE]: {
            label: "Contenu de l'en-tête de la carte",
        },
        [TraitId.CHART_1]: {
            label: 'Choisissez le premier graphique',
        },
        [TraitId.CHART_2]: {
            label: 'Choisissez le 2e graphique',
        },
        [TraitId.CHART_3]: {
            label: 'Choisissez le 3e graphique',
        },
        [TraitId.CHART_4]: {
            label: 'Choisissez le 4e graphique',
        },
        [TraitId.CHART_5]: {
            label: 'Choisissez le 5e graphique',
        },
        [TraitId.CHART_SUBTYPE]: {
            [BandMode.NONE]: '(Aucune)',
            [BandMode.BAND]: "Inclure des bandes d'indication de période",
            [OverBarMode.NONE]: '(Aucune)',
            [OverBarMode.OVER_BAR]:
                'Inclure des barres superposées pour chaque période',
            [YoYBarMode.NONE]: '(Aucune)',
            [YoYBarMode.PERIOD_COLOR]:
                'Graphique à barres superposées Année à Année avec surlignage de période',
            [GeoMode.COUNTRY]: 'Au niveau du pays',
            [GeoMode.REGION]: 'Au niveau de la région',
            [GeoMode.CITY]: 'Au niveau de la ville',
            label: 'Fonctions additionnelles',
            [WithLabel.NONE]: '(Aucune)',
            [WithLabel.WITH_LABEL]: 'Ajouter une étiquette sur les barres',
        },
        [TraitId.COLOR]: {
            label: 'Légende des couleurs',
            description:
                'Permet de choisir comment la légende des couleurs peut être appliquée à votre graphique',
            [ColorLegend.DIMENSION]: 'Dimension',
            [ColorLegend.DELTA]: '% de variation sur la période',
            [ColorLegend.FIXED]: 'Couleur fixe',
            [ColorLegend.VOLUME]: 'Volume de mesure',
        },
        [TraitId.COLUMN_COUNT]: {
            label: 'Choisissez le nombre de colonnes',
        },
        [TraitId.DATE_AGGREGATION_LEVEL]: {
            label: "Niveau d'agrégation des dates",
        },
        [TraitId.DIMENSION]: {
            label: 'Choisissez les dimensions :',
            description:
                'Choisissez une ou plusieurs dimensions pour votre graphique. Si plusieurs dimensions sont sélectionnées pour un même graphique, un paramètre sera créé pour permettre à vos utilisateurs de basculer entre les dimensions',
            [ColumnType.SINGLE]: '(Aucune dimension)',
            [ColumnType.DYNAMIC]: '(dimension dynamique)',
        },
        [TraitId.GEO_CITY]: {
            label: 'Choisissez le champ de la ville',
        },
        [TraitId.GEO_COUNTRY]: {
            label: 'Choisissez le champ de pays',
        },
        [TraitId.GEO_REGION]: {
            label: 'Choisissez le champ de région',
        },
        [TraitId.INCLUDE_CARD_TITLE]: {
            label: 'Inclure un titre',
        },
        [TraitId.INCLUDE_PAGE_BLOCK_HEADER]: {
            label: 'Inclure un en-tête pour le bloc de pages',
        },
        [TraitId.LABEL_SIZE]: {
            label: 'Taille du texte (%)',
        },
        [TraitId.MEASURE]: {
            label: 'Choisissez des mesures :',
            description:
                "Permet de choisir les mesures pour votre graphique. Si plusieurs mesures sont sélectionnées pour un même graphique, un paramètre sera créé pour permettre à vos utilisateurs de passer d'une mesure à l'autre",
            [ColumnType.SINGLE]: '(Aucune mesure)',
            [ColumnType.DYNAMIC]: '(mesure dynamique)',
        },
        [TraitId.MEASURE_COLOR]: {
            label: 'Appliquer la couleur de mesure à :',
            [MeasureColorLocation.BORDER]: 'les bordures',
            [MeasureColorLocation.TITLE]: 'les titres',
            [MeasureColorLocation.CURVES]: 'les graphes',
        },
        [TraitId.PAGE_MODE]: {
            label: 'Organisez :',
            description:
                'Vous pouvez appliquer un graphique pour chaque dimension (pour une mesure donnée) ou un graphique pour chaque mesure (pour une dimension donnée)',
            [PageMode.MEASURE]: '1 graphique/mesure',
            [PageMode.DIMENSION]: '1 graphique/dimension',
        },
        [TraitId.SIDEBAR]: {
            label: 'Encadré',
            lateral: 'Barre de navigation latérale',
            top: 'Barre de navigation supérieure',
            none: '(Aucune)',
        },
        [TraitId.TIME_SELECTOR_ORIENTATION]: {
            label: 'Orientation :',
            [Orientation.VERTICAL]: 'Verticale',
            [Orientation.HORIZONTAL]: 'Horizontale',
        },
        [TraitId.TIME_SELECTOR_TYPE]: {
            label: 'Personnalisez le sélecteur de date :',
            description:
                'Selon vos besoins, vous pouvez choisir parmi différents sélecteurs de date (construits à partir de paramètres). Cela peut être utile pour forcer vos utilisateurs à sélectionner des mois ou au contraire pour leur laisser plus de liberté',
            [TimeSelectorType.DAILY_FROM_TO]:
                'Sélecteur de période quotidienne',
            [TimeSelectorType.DAILY]: 'Sélecteur par jour',
            [TimeSelectorType.MONTHLY_FROM_TO]:
                'Sélecteur de périodes mensuelles',
            [TimeSelectorType.MONTHLY]: 'Sélecteur mensuel',
            [TimeSelectorType.MONTHLY_FROM_TO_SPLIT]:
                'Sélecteur de période année+mois',
            [TimeSelectorType.MONTHLY_SPLIT]:
                'Sélecteur pour une seule année+un mois',
        },
        by: 'par',
        chapter: 'Chapitre',
        chartAlias: 'graphique',
        chartPerMeasure: 'graphique par mesure',
        charts: {
            label: 'Graphiques',
            [ChartType.NONE]: '(Aucun)',
            [ChartType.AREA]: 'Graphique en aires',
            [ChartType.LINE]: 'Graphique en lignes',
            [ChartType.KPI]: 'Carte KPI',
            [ChartType.COLUMN]: 'Conteneur en colonnes',
            [ChartType.ROW]: 'Conteneur en lignes',
            [ChartType.LOLLIPOP]: 'Graphique sucette',
            [ChartType.DONUT]: 'Graphique Donut',
            [ChartType.BAR]: 'Diagramme à barres',
            [ChartType.PIE]: 'Diagramme circulaire',
            [ChartType.TIME_BAR]: 'Graphique à barres mensuel',
            [ChartType.YOY_TIME_BAR]: 'Graphique à barres mensuel an par an',
            [ChartType.MAP]: 'Carte',
        },
        chartsAlias: 'graphiques',
        chartsPerMeasure: 'graphiques par mesure',
        col: 'verticalement',
        column: 'colonnes',
        [TraitId.COMPARISON_PERIODS]: {
            label: 'Choisir pour quelle période comparer',
            [ComparisonPeriod.DAY]: 'jour(s) auparavant',
            [ComparisonPeriod.WEEK]: 'semaine(s) auparavant',
            [ComparisonPeriod.MONTH]: 'mois auparavant',
            [ComparisonPeriod.QUARTER]: 'trimestre(s) auparavant',
            [ComparisonPeriod.YEAR]: 'an(s) auparavant',
            [ComparisonPeriod.JANUARY]: 'janvier',
            [ComparisonPeriod.FEBRUARY]: 'février',
            [ComparisonPeriod.MARCH]: 'mars',
            [ComparisonPeriod.APRIL]: 'avril',
            [ComparisonPeriod.MAY]: 'mai',
            [ComparisonPeriod.JUNE]: 'juin',
            [ComparisonPeriod.JULY]: 'juillet',
            [ComparisonPeriod.AUGUST]: 'août',
            [ComparisonPeriod.SEPTEMBER]: 'septembre',
            [ComparisonPeriod.OCTOBER]: 'octobre',
            [ComparisonPeriod.NOVEMBER]: 'novembre',
            [ComparisonPeriod.DECEMBER]: 'décembre',
        },
        customtitle: 'Le titre de ma page ici',
        dashboard: {
            [ChartType.AREA]: 'Graphique en aires',
            [ChartType.BAR]: 'Diagrammes à barres',
            [ChartType.LOLLIPOP]: 'Graphiques de sucettes',
            [ChartType.LINE]: 'Graphiques en courbes',
            [ChartType.MAP]: 'Cartes',
            [ChartType.DONUT]: 'Graphiques en donut',
            [ChartType.KPI]: 'Carte KPI',
        },
        date: {
            year: 'Année',
            quarter: 'Trimestre',
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour',
        },
        dates: {},
        down: 'en bas',
        dynamic: {
            measure: 'Mesure dynamique',
            dimension: 'Dimension dynamique',
        },
        empty: '(aucune)',
        flex: {
            label: 'Répartir uniformément ?',
            row: 'Conteneur horizontal',
            column: 'Conteneur vertical',
            template: 'Modèles',
            kpiCard: 'Carte KPI',
            text: 'Texte',
            title: 'Titre',
        },
        for: 'pour',
        geo_level: {
            label: 'Choisissez le niveau géographique de votre carte',
        },
        includeChart: 'Inclure {chart}',
        label: {
            label: 'Étiquettes',
        },
        left: 'vers la gauche',
        months: {
            jan: 'Janvier',
            feb: 'Février',
            mar: 'Mars',
            apr: 'Avril',
            may: 'Mai',
            jun: 'Juin',
            jul: 'Juillet',
            aug: 'Août',
            sep: 'Septembre',
            oct: 'Octobre',
            nov: 'Novembre',
            dec: 'Décembre',
        },
        right: 'vers la droite',
        row: 'horizontalement',
        selects: {
            date: 'Date',
            [SelectorId.START_DATE_SELECTOR]: 'Date de début',
            [SelectorId.END_DATE_SELECTOR]: 'Date de fin',
            [SelectorId.START_YEAR_MONTH_SELECTOR]: 'Date de début',
            [SelectorId.END_YEAR_MONTH_SELECTOR]: 'Date de fin',
            [SelectorId.MEASURE_SELECTOR]: 'Choisissez une mesure',
            [SelectorId.MEASURE_SELECTOR_2]: 'Choisissez une 2e mesure',
            [SelectorId.COMPARISON_PERIOD_SELECTOR]:
                'Choisissez une période de comparaison',
            [SelectorId.DIMENSION_SELECTOR]: 'Choisissez une dimension',
            [SelectorId.DIMENSION_SELECTOR_2]: 'Choisissez une 2e dimension',
            [SelectorId.DATE_AGGREGATION_SELECTOR]:
                'Choisissez une agrégation de dates',
            [TdPeriod.MTD]: 'MTD',
            [TdPeriod.QTD]: 'QTD',
            [TdPeriod.YTD]: 'YTD',
            [TdPeriod.WTD]: 'WTD',
            [HeaderDesign.CENTERED]: 'centré',
            [HeaderDesign.SIMPLE]: 'aligné à gauche',
            [HeaderDesign.SIMPLE_VERTICAL]: 'en colonnes',
            [HeaderDesign.DENSE]: 'dense',
            [SelectorId.CHART_SELECTOR]: 'Choisissez un graphique',
        },
        textElement: {
            [TextElementType.DIMENSION]: 'Dimension',
            [TextElementType.MEASURE]: 'Mesure',
            [TextElementType.MEASURE_CHOICE]: 'Choix de Mesure',
            [TextElementType.DIMENSION_CHOICE]: 'Choix de Dimension',
            [TextElementType.DATE_AGGREGATION_LEVEL_CHOICE]:
                "Choix de niveau d'agrégation de date",
            [TextElementType.THIS_MEASURE]: 'Mesure pour période sélectionnée',
            [TextElementType.LAST_MEASURE]: 'Mesure pour période précédente',
            [TextElementType.EVOLUTION]: 'Evolution de Mesure (%)',
            [TextElementType.EVOLUTION_COLORED]:
                'Evolution de Mesure (%, coloré)',
            [TextElementType.DIFFERENCE]: 'Différence de Mesure',
            [TextElementType.DIFFERENCE_COLORED]:
                'Différence de Mesure (coloré)',
            [TextElementType.DATE]: 'Date',
            [TextElementType.LAST_PERIOD]: 'Période précédente',
            [TextElementType.THIS_PERIOD]: 'Période sélectionnée',
            [TextElementType.COMPARISON_PERIOD]: 'Période de comparaison',
            [TextElementType.GEO_CITY]: 'Ville',
            [TextElementType.GEO_REGION]: 'Région',
            [TextElementType.GEO_COUNTRY]: 'Pays',
        },
        titles: {
            [ChartType.AREA]: 'Evolution <measure>par <dimension>',
            [ChartType.LINE]: 'Evolution <measure> {hasDimension}',
            [ChartType.KPI]: '<measure>',
            [ChartType.LOLLIPOP]: 'Top <measure> par <dimension>',
            [ChartType.DONUT]: 'Part de <measure> par <dimension>',
            [ChartType.BAR]: 'Top <measure> par <dimension>',
        },
        tooltip: {
            label: 'Infobulles',
        },
        top: 'en haut',
        vs: 'VS',
    },
}
