export enum TextElementType {
    THIS_MEASURE = 'this_measure',
    LAST_MEASURE = 'last_measure',
    MEASURE = 'measure',
    EVOLUTION = 'evolution',
    EVOLUTION_COLORED = 'evolution_colored',
    DIFFERENCE = 'difference',
    DIFFERENCE_COLORED = 'difference_colored',
    DIMENSION = 'dimension',
    MEASURE_CHOICE = 'measure_choice',
    DIMENSION_CHOICE = 'dimension_choice',
    DATE_AGGREGATION_LEVEL_CHOICE = 'date_aggregation_level_choice',
    DATE = 'date',
    THIS_PERIOD = 'this_period',
    LAST_PERIOD = 'last_period',
    COMPARISON_PERIOD = 'comparison_period',
    GEO_REGION = 'geo_region',
    GEO_CITY = 'geo_city',
    GEO_COUNTRY = 'geo_country',
}

export const TEXT_ELEMENT_TYPES = [
    TextElementType.THIS_MEASURE,
    TextElementType.LAST_MEASURE,
    TextElementType.MEASURE,
    TextElementType.EVOLUTION,
    TextElementType.DIFFERENCE,
    TextElementType.DIMENSION,
    TextElementType.MEASURE_CHOICE,
    TextElementType.DIMENSION_CHOICE,
    TextElementType.DATE,
    TextElementType.THIS_PERIOD,
    TextElementType.LAST_PERIOD,
    TextElementType.COMPARISON_PERIOD,
    TextElementType.GEO_REGION,
    TextElementType.GEO_CITY,
    TextElementType.GEO_COUNTRY,
] as const

export enum TextElementMode {
    CALCULATION = 'calculation',
    PREVIEW = 'preview',
    TOOLTIP_PREVIEW = 'tooltip_preview',
}

export type HeadingSize = 1 | 2 | 3 | 4 | 5 | 6
export type Alignment = 'left' | 'center' | 'right'
export const ALIGNMENTS: { [k in string]: Alignment } = [
    'left',
    'center',
    'right',
].reduce(
    (acc: any, a: any) => ({
        ...acc,
        [a[0]]: a,
    }),
    {}
)
