
import { nanoid, SourceMemory, DataType } from '@/ponychart'
import SelectSwitch from './SelectSwitch.vue'
const d = new Date()
const year = d.getFullYear()
const month = d.getMonth()

import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { GlobalMixins } from '@/mixins'

@Component({ components: { SelectSwitch } })
export default class MiscDates extends mixins(GlobalMixins) {
    @Prop({ type: Boolean, required: true })
    readonly show!: boolean
    @Prop({ type: String, required: true })
    readonly dateAggregationLevel!: 'month' | 'day'
    @Prop({ type: Number, required: true })
    readonly twbIdx!: number
    @Prop({ type: Array, required: true })
    readonly twbDatasources!: { id: string; alias: string; color: string }[]

    pristine = true
    label = ''
    format = 'YYYY-MM-DD'
    year = year
    yearType = 'field'
    month = month
    monthType = 'field'
    day = 1
    dayType = 'text'
    func = ``
    copyIcon = 'content_copy'
    tmt: null | number = null

    get columnMaps() {
        const filters: string[] = []
        if (this.yearType === 'field') filters.push(String(this.year))
        if (this.yearType === 'field') filters.push(String(this.month))
        if (this.dayType === 'field') filters.push(String(this.day))
        return this.columns
            .filter((c) => filters.includes(c.id))
            .reduce((acc, c) => ({ ...acc, [c.id]: c.alias }), {})
    }
    get yearAlias() {
        if (this.yearType === 'field' && this.columnMaps[this.year])
            return `[${this.columnMaps[this.year]}]`
        return this.year
    }
    get yearCalc() {
        if (this.yearType === 'field') return `[${this.year}]`
        return this.year
    }
    get monthAlias() {
        if (this.monthType === 'field' && this.columnMaps[this.month])
            return `[${this.columnMaps[this.month]}]`
        return this.month
    }
    get monthCalc() {
        if (this.monthType === 'field') return `[${this.month}]`
        return this.month
    }
    get dayAlias() {
        if (this.dayType === 'field' && this.columnMaps[this.day])
            return `[${this.columnMaps[this.day]}]`
        return this.day
    }
    get dayCalc() {
        if (this.dayType === 'field') return `[${this.day}]`
        return this.day
    }
    get formula() {
        return `DATE(STR(${this.yearCalc}) + "-" + STR(${this.monthCalc}) + "-" + STR(${this.dayCalc}))`
    }
    get yearItems() {
        return this.columns.filter(
            (c) => ![this.month, this.day].map(String).includes(c.id)
        )
    }
    get monthItems() {
        return this.columns.filter(
            (c) => ![this.year, this.day].map(String).includes(c.id)
        )
    }
    get dayItems() {
        return this.columns.filter(
            (c) => ![this.year, this.month].map(String).includes(c.id)
        )
    }
    get columns() {
        return SourceMemory.getInstance().columns.filter(
            (c) => c.type === DataType.INTEGER && c.twbIdx === this.twbIdx
        )
    }
    get disabled() {
        // TODO: add regex comprehension
        if (this.label.length === 0) return true
        if (this.yearType === 'field' && !String(this.yearCalc).startsWith('['))
            return true
        if (
            this.monthType === 'field' &&
            !String(this.monthCalc).startsWith('[')
        )
            return true
        if (this.dayType === 'field' && !String(this.dayCalc).startsWith('['))
            return true

        if (this.yearType === 'text' && typeof this.yearCalc === 'string')
            return true
        if (this.monthType === 'text' && typeof this.monthCalc === 'string')
            return true
        if (this.dayType === 'text' && typeof this.dayCalc === 'string')
            return true
        return false
    }
    get link() {
        return `https://help.tableau.com/current/pro/desktop/${this.$t(
            'link'
        )}/functions_functions_date.htm`
    }

    set(o: { [k: string]: any }) {
        this.pristine = false
        for (const key in o) {
            this[key] = o[key]
        }
    }
    save() {
        this.$emit('save', {
            alias: this.label,
            id: nanoid.id(),
            formula: this.formula,
        })
        this.$emit('close')
        // this.func += e.substring(1)
        this.label = ''
        this.format = 'YYYY-MM-DD'
        this.func = ''
    }
    copy() {
        navigator.clipboard.writeText(this.formula).then(() => {
            this.copyIcon = 'check_circle'
            setTimeout(() => (this.copyIcon = 'content_copy'), 1500)
        })
    }
    mounted() {
        this.label = String(this.$t('date'))
    }
    beforeDestroy() {
        if (this.tmt) clearInterval(this.tmt)
    }

    @Watch('dateAggregationLevel', { immediate: true })
    onDateAggregationLevelChange(dateAggregationLevel: 'month' | 'day') {
        if (!this.pristine) return
        if (dateAggregationLevel === 'day') this.dayType = 'field'
        else {
            this.dayType = 'text'
            this.day = 1
        }
    }
}
