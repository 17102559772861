import { DeviceType, QuerySelectorTag } from 'ponychart'
import {
    GlobalOptions,
    LocalOptions,
    SourceColors,
} from '@/ponychart/element/types'
import { PageStructure } from '@/ponychart/page/types'
import {
    memoizedInvertColor,
    add,
    substract,
    divideBy,
    hyphenObjectToDash,
} from '@/ponychart/utils'
import { mainTag } from '@/ponychart/trait/utils'
import { ELEMENT_HEIGHT } from '@/ponychart/header/config'

import { cloneDeep, omit } from 'lodash'

export class GlobalState {
    public globalOptions: GlobalOptions
    constructor(globalOptions: GlobalOptions) {
        this.globalOptions = cloneDeep(globalOptions)
    }

    get hidePageBlockHeader() {
        return this.globalOptions.hidePageBlockHeader || false
    }

    get multipleCharts() {
        return this.globalOptions.multipleCharts
    }

    get pageStructure() {
        if (!this.globalOptions.pageStructure)
            throw new Error('pageStructure not set')
        return this.globalOptions.pageStructure
    }

    get twbIndexes() {
        return this.globalOptions.twbIndexes
    }

    get pageTitle() {
        return this.globalOptions.pageTitle || ''
    }

    get onlyContainer() {
        return !!this.globalOptions.onlyContainer
    }

    get logoUrl() {
        return this.globalOptions?.logoUrl
    }

    get sidebarPayload() {
        return this.globalOptions.sidebarPayload
    }

    get navigation() {
        return this.globalOptions?.navigation || false
    }

    get twbIdx() {
        return this.globalOptions.twbIdx || 0
    }

    get deviceType() {
        return this.globalOptions.deviceType || DeviceType.DESKTOP
    }

    get pageBlockId(): string {
        return this.globalOptions?.pageBlockId || ''
    }

    get pageId(): string | number {
        return this.globalOptions.pageId || ''
    }

    get reduceSize() {
        return this.globalOptions?.reduceSize || 1
    }

    get colors(): SourceColors {
        return this.globalOptions.colors
        // return {
        //     ...colors,
        //     invertBackground: memoizedInvertColor(colors.background),
        //     invertLightBackground: memoizedInvertColor(colors.light_background),
        //     invertTitle: memoizedInvertColor(colors.title),
        //     invertSidebarPrimary: memoizedInvertColor(colors.sidebar_primary),
        //     invertSidebarSecondary: memoizedInvertColor(
        //         colors.sidebar_secondary
        //     ),
        // }
    }

    get titleStyle(): { [k: string]: string } {
        return {
            background: this.colors.title,
            color: memoizedInvertColor(this.colors.title),
        }
    }

    get isDarkBackground() {
        return memoizedInvertColor(this.colors.background) === '#FFF'
    }

    get isDarkLightBackground() {
        return memoizedInvertColor(this.colors.light_background) === '#FFF'
    }

    get grayFilter() {
        return this.globalOptions.grayFilter
    }

    get disableQuerySelectorClass() {
        return this.globalOptions.disableQuerySelectorClass || false
    }

    get pageBlockIndex() {
        return this.globalOptions.pageBlockIndex
    }

    get isDesktop() {
        return this.deviceType === DeviceType.DESKTOP
    }

    get isMobile() {
        return this.deviceType === DeviceType.MOBILE
    }

    get isTablet() {
        return this.deviceType === DeviceType.TABLET
    }

    get ratios() {
        return this.globalOptions.ratios
    }

    get hasMobileHeader() {
        if (this.deviceType === DeviceType.MOBILE) return true
        const aliasesLength = this.sidebarPayload?.aliases?.length || 0
        if (this.deviceType === DeviceType.TABLET && aliasesLength > 3)
            return true
        if (this.deviceType === DeviceType.DESKTOP && aliasesLength > 6)
            return true
        return false
    }

    setPageStructure(pageStructure: PageStructure) {
        this.globalOptions.pageStructure = pageStructure
        return this
    }

    setDisableQuerySelectorClass(disabled: boolean) {
        this.globalOptions.disableQuerySelectorClass = disabled
        return this
    }

    setDeviceType(deviceType: DeviceType) {
        this.globalOptions.deviceType = deviceType
        return this
    }

    setPageNavigation(navigation: boolean): GlobalState {
        this.globalOptions.navigation = navigation
        return this
    }

    setRatios(
        ratios: Partial<{
            [q in QuerySelectorTag]: { height: number; width: number }
        }>
    ): GlobalState {
        this.globalOptions.ratios = ratios
        return this
    }

    setReduceSize(reduceSize: number): GlobalState {
        this.globalOptions.reduceSize = reduceSize
        return this
    }

    setPageId(pageId: string): GlobalState {
        /** Used to 'tag' a component which belongs to a Page so we can set up parameters when it's a title */
        this.globalOptions.pageId = pageId
        return this
    }

    setTwbIdx(twbIdx: number): GlobalState {
        this.globalOptions.twbIdx = twbIdx
        return this
    }

    setPageBlockId(pageBlockId: string): GlobalState {
        /** Used to 'tag' a component which belongs to a PageBlock so we can set up parameters when it's a title */
        this.globalOptions.pageBlockId = pageBlockId
        return this
    }

    setPageBlockIndex(pageBlockIndex: number): GlobalState {
        this.globalOptions.pageBlockIndex = pageBlockIndex
        return this
    }

    setOnlyContainer(onlyContainer: boolean): GlobalState {
        this.globalOptions.onlyContainer = onlyContainer
        return this
    }
}

export class LocalState {
    constructor(public localOptions: LocalOptions = {}) {
        if (localOptions.styles)
            localOptions.styles = hyphenObjectToDash(localOptions.styles)
    }

    private unsetHook(
        valueSet: 'title' | 'card' | 'pageBlock' | 'pageBlockHeader',
        value: boolean
    ) {
        if (!value) return
        const valuesToUnset = ['title', 'card', 'pageBlock', 'pageBlockHeader']
        const actionMap = {
            title: 'setIsTitle',
            card: 'setIsCard',
            pageBlock: 'setIsPageBlock',
            pageBlockHeader: 'setIsPageBlockHeader',
        }
        for (const valueToUnset of valuesToUnset) {
            if (valueToUnset !== valueSet) {
                this[actionMap[valueToUnset]](false)
            }
        }
    }

    get isFlex(): boolean {
        return !!this.localOptions.isFlex
    }

    get isCol() {
        return !!this.localOptions.isCol
    }

    get querySelectorTags() {
        return this.localOptions.querySelectorTags || [0]
    }

    get mainQuerySelectorTag() {
        return mainTag(this.querySelectorTags)
    }

    get isPageBlock() {
        return !!this.localOptions.isPageBlock
    }

    get isCard() {
        return !!this.localOptions.isCard
    }

    get isTitle() {
        return !!this.localOptions.isTitle
    }

    get isPageBlockHeader() {
        return !!this.localOptions.isPageBlockHeader
    }

    get rawStyles() {
        return this.localOptions?.styles || {}
    }

    get localClasses() {
        return this.localOptions.classes || []
    }

    get attributes() {
        return this.localOptions.attributes || {}
    }

    get noColorStyle(): { [k: string]: string } {
        return this.rawStyles ? omit(this.rawStyles, ['border']) : {}
    }

    get isDarkBackground() {
        return (
            memoizedInvertColor(this.rawStyles.background || '#FFF') === '#FFF'
        )
    }

    addAttribute(key: string, value: string) {
        if (!this.localOptions.attributes) this.localOptions.attributes = {}
        this.localOptions.attributes[key] = value
        return this
    }

    setQuerySelectorTags(querySelectorTags: QuerySelectorTag[]) {
        this.localOptions.querySelectorTags = querySelectorTags
        return this
    }

    setIsFlex(isFlex: boolean): LocalState {
        this.localOptions.isFlex = isFlex
        return this
    }

    setIsCol(isCol: boolean) {
        this.localOptions.isCol = isCol
        return this
    }

    setStyles(styles: { [k: string]: string }): LocalState {
        this.localOptions.styles = hyphenObjectToDash(styles)
        return this
    }

    addStyles(styles: { [k: string]: string }): LocalState {
        this.localOptions.styles = {
            ...(this.localOptions.styles || {}),
            ...hyphenObjectToDash(styles),
        }
        return this
    }

    adaptStylePaddingForSelector(height: number | string) {
        const padding = add(
            this.rawStyles?.padding || '0',
            this.rawStyles?.padding || '0',
            this.rawStyles?.['padding-top'] || '0',
            this.rawStyles?.['padding-bottom'] || '0'
        )
        const innerHeight = substract(height, padding)
        const margin = this.rawStyles.margin || 0
        const border = this.rawStyles.border || 0
        const rowPadding = divideBy(
            substract(
                innerHeight,
                ELEMENT_HEIGHT,
                margin,
                margin,
                border,
                border
            ),
            2
        )
        this.addStyles({ paddingTop: rowPadding, paddingBottom: rowPadding })
        return this
    }

    addClasses(classes: string[]): LocalState {
        this.localOptions.classes = Array.from(
            new Set([...(this.localOptions.classes || []), ...classes])
        )
        return this
    }

    removeClass(clss: string): LocalState {
        this.localOptions.classes = (this.localOptions.classes || []).filter(
            (c) => c !== clss
        )
        return this
    }

    setIsPageBlockHeader(isPageBlockHeader: boolean) {
        this.localOptions.isPageBlockHeader = isPageBlockHeader
        this.unsetHook('pageBlockHeader', isPageBlockHeader)
        return this
    }

    setIsTitle(isTitle: boolean) {
        this.localOptions.isTitle = isTitle
        this.unsetHook('title', isTitle)
        return this
    }

    setIsPageBlock(isPageBlock: boolean): LocalState {
        this.localOptions.isPageBlock = isPageBlock
        this.unsetHook('pageBlock', isPageBlock)
        return this
    }

    setIsCard(isCard: boolean): LocalState {
        this.localOptions.isCard = isCard
        this.unsetHook('card', isCard)
        return this
    }

    copy(): LocalState {
        return new LocalState(cloneDeep(this.localOptions))
    }
}
