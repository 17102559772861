<template>
    <div id="contentDraw" :class="animated ? 'contentDraw' : ''">
        <div id="lineDrawing">
            <svg
                viewBox="0 0 792 792"
                x="0px"
                y="0px"
                style="enable-background: new 0 0 792 792"
            >
                <g id="Lined" class="lines">
                    <g>
                        <!--FACE-->
                        <path
                            class="st0"
                            stroke="black"
                            d="M328.5,439.8c21.5,56.7,86.7,66.4,130.6,56.7c112.2-25,108.2-84.1,108.2-84.1c-2.4-37-21.5-29.8-47.5-38.8
                        c0,0-53.4-12.9-76.7-57.2c-6.4-12-14.5-23.2-25.2-31.5c-15.8-12-39.6-21.2-68.1-3.7"
                        />
                        <path
                            class="st0"
                            stroke="black"
                            d="M307,593.8c4.8-25.2-2.2-74.5-2.2-74.5L284,419.9c-3.5-16.4-5-33.1-4.4-49.9c0.7-13.8,2.6-25,5.5-34.4"
                        />
                        <!--EAR-->
                        <path
                            class="st0"
                            stroke="black"
                            d="M285.1,335.3C285.1,335.3,284.7,335.3,285.1,335.3c-8.3-40.1,2.2-85.7,33.5-102.5l46.4,72.7
                        c0,0,34.2-1.3,52.8,30c30,50.4,74.3,27.4,74.3,27.4"
                        />

                        <!--CIRCLES-->
                        <path
                            fill="black"
                            d="M220.5,325.7c-9.9,11-28.9,12.9-39.9,2.4c-11-10.7-12.9-28-2.4-39.9c9.9-11,28.9-12.9,39.9-2.4
                        C229.2,296.5,231,313.9,220.5,325.7z"
                        />
                        <path
                            fill="black"
                            d="M250.7,323.3c-3.1,3.3-8.8,3.9-12,0.7s-3.9-8.5-0.7-12c3.1-3.3,8.8-3.9,12-0.7
                        C253.3,314.3,253.8,319.5,250.7,323.3z"
                        />
                        <path
                            fill="black"
                            d="M260.1,290c-5.5,6.1-16,7.2-22.1,1.3c-6.1-5.9-7.2-15.6-1.3-22.1c5.5-6.1,16-7.2,22.1-1.3
                        C265,273.8,266,283.4,260.1,290z"
                        />

                        <!--EYE-->
                        <circle
                            :class="
                                animated
                                    ? 'animated-circle'
                                    : 'not-animated-circle'
                            "
                            stroke="black"
                            cx="395"
                            cy="395"
                            r="15.8"
                        />

                        <!--HAIR-LEFT-->
                        <path
                            class="st0"
                            stroke="black"
                            d="M280.1,390.5c0,0-24.8,79.1-79.1,100.1"
                        />
                        <path
                            class="st0"
                            stroke="black"
                            d="M295.6,474.4c0,0,0,34.2-51.3,76.5"
                        />
                        <path
                            class="st0"
                            stroke="black"
                            d="M285.1,335.3c0,0-29.8,72.9-97.9,119.4"
                        />
                        <path
                            class="st0"
                            stroke="black"
                            d="M288.4,440.7c-14.7,35.9-34,61.1-66.8,84.1"
                        />
                        <path
                            class="st0"
                            stroke="black"
                            d="M303.5,512.8c-0.4-1.8,0,25.4-30.2,62.2"
                        />

                        <!--HAIR-TOP-->
                        <path
                            class="st0"
                            stroke="black"
                            d="M335.7,259.5c0,0,66.6-57.8,111.9,11.6c46.4,71.2,78,72.7,115.9,72.7"
                        />
                        <path
                            class="st0"
                            stroke="black"
                            d="M326.5,245.1c0,0,28.7-59.6,95.9-36.1c32,12.7,51,35.9,64.6,59.1c22.1,37.2,53,54.3,88.5,54.3
                        c0,0-11.8,34.8-47.5,53.7"
                        />

                        <!--PIE-CHART-->
                        <path
                            class="st0"
                            stroke="black"
                            d="M290.4,266.1l-31.1-38.8c37.2-30.2,85-48.4,136.7-48.4C515.8,178.9,613,276,613,396
                        c0,119.8-97,217.1-217.1,217.1S178.9,516,178.9,396c0-13.6,1.3-27.2,3.7-40.1l83,15.6"
                        />

                        <!--SMILE-->
                        <path
                            class="st0"
                            stroke="black"
                            d="M466.3,440.7l13.6,4.6c25.6,9,53.7,7.2,78.2-4.6l0,0"
                        />

                        <!--HAIR-BOTTOM-->
                        <path
                            class="st0"
                            stroke="black"
                            d="M486,489.1c0,0,31.5,50.2,42.7,78.9"
                        />
                        <path
                            class="st0"
                            stroke="black"
                            d="M510.5,479.5c0,0,32.2,39.9,41.4,66.2"
                        />
                        <path
                            class="st0"
                            stroke="black"
                            d="M459,496.3c0,0,32.9,64.8,39.6,90.7"
                        />
                        <!--STOP-->
                        <!-- <path class="st0"  stroke="black" d="M459,459c0"/> -->
                    </g>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'logoComponent',
    props: {
        animated: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
}
</script>

<style scoped>
#contentDraw {
    height: 100%;
    width: 100%;
}
.contentDraw {
    background: white;
    z-index: 9000;
    position: fixed;
    height: 100%;
    width: 100%;
}
#lineDrawing {
    position: relative;
    height: 50%;
    width: auto;
    margin: auto;
    opacity: 0.5;
}
path {
    stroke-width: 1.7;
}
svg {
    height: 100%;
    position: relative;
    display: block;
    margin: auto;
}
.st0 {
    fill: none;
    /* stroke:#FFFFFF; */
    stroke-width: 7;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
/**
    /////////////////////////////////////////////////
    */
figure {
    background-color: #eee;
    display: block;
    height: 0;
    margin: 0 auto;
    position: relative;
    font-size: 16px;
    font-size: 1vw;
    width: 40em;
    padding-bottom: 40em;
}
circle {
    fill: rgba(0, 0, 0, 0);
    stroke-width: 31.5;
    stroke-dashoffset: -160;
}
.animated-circle {
    stroke-dasharray: 0, 0, 0, 100;

    -webkit-animation: pie1 14s infinite ease both;
    animation: pie1 14s infinite ease both;
}
.not-animated-circle {
    stroke-dasharray: 0, 5, 88, 7;
}
/*
 pie percentage made by stroke-dasharray.
 stroke-dasharray format is...
 keyframe 100% : 0 offset this-percentage rest
 I added keyframe 50% for infinite presentation.
*/
/* 1st pie is 40% */
@-webkit-keyframes pie1 {
    20%,
    100% {
        stroke-dasharray: 0, 5, 88, 7;
    }
}
@keyframes pie1 {
    20%,
    100% {
        stroke-dasharray: 0, 5, 88, 7;
    }
}
</style>