import { t } from '@/ponychart/i18n'

import { TextElementType, TEXT_ELEMENT_TYPES } from './type'
import { SIZES } from './config'

export function listTextElements(
    textElements: TextElementType[] = [...TEXT_ELEMENT_TYPES]
) {
    return textElements.map((textElement) => t(`textElement.${textElement}`))
}

export function getRandomValues() {
    const i = Math.floor(Math.random() * SIZES.length)
    const thisMeasure = Math.floor(Math.random() * SIZES[i] * 100) / 100 + 0.01
    return {
        thisMeasure,
        lastMeasure: thisMeasure * (1.2 - Math.random() * 0.4),
    }
}
