import { customAlphabet } from 'nanoid'
const alphabet = 'abcdefghijklmnopqrstuvwxyz'
const _nanoid = customAlphabet(alphabet, 6)

class NanoId {
    ids: Set<string>
    constructor() {
        this.ids = new Set()
    }
    // DEPRECATED
    idPak() {
        let id = _nanoid()
        while (this.ids.has(id)) {
            id = _nanoid()
        }
        this.ids.add(id)
        return id
    }
    setDatasourceId(sourceId: string) {
        localStorage.setItem('sourceId', sourceId)
    }
    id() {
        const key = localStorage.getItem('sourceId') || '0'
        let n = -1
        try {
            n = Number(localStorage.getItem(key))
        } catch (_) {
            //
        }
        const increment = n + 1
        localStorage.setItem(key, String(increment))
        return 'd' + increment.toString(36)
    }
    size() {
        return this.ids.size
    }
}

export const nanoid = new NanoId()
