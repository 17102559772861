import { getArrayValueFromString } from "@/ponychart/trait/utils";
import { SourceMemory } from "@/ponychart/memoize/sourceMemory"
import { ChartType, TraitId } from "ponychart";
import type { PonychartElement } from "./model";

const memoryInstance = SourceMemory.getInstance()

function requiredDimension(ponychartElement: PonychartElement) {
    return
    // const dimension = ponychartElement.attributes[TraitId.DIMENSION] || ""
    // const dimensionArray = getArrayValueFromString(dimension, { options: memoryInstance.dimensions, twbIdx: ponychartElement.globalState.twbIdx })
    // if (dimensionArray.length === 0) throw new Error(`Dimension attribute is null for element ${ponychartElement.componentType}
    // ${JSON.stringify(ponychartElement.attributes, null, 2)}`)
}

function requiredMeasure(ponychartElement: PonychartElement) {
    return
    // const measure = ponychartElement.attributes[TraitId.MEASURE] || ""
    // const measureArray = getArrayValueFromString(measure, { options: memoryInstance.measures, twbIdx: ponychartElement.globalState.twbIdx })
    // if (measureArray.length === 0) throw new Error(`Measure attribute is null for element ${ponychartElement.componentType}, 
    // ${JSON.stringify(ponychartElement.attributes, null, 2)}`)
}

const CHART_TYPE_ERROR_CHECKERS: Partial<{ [c in ChartType]: ((ponychartElement: PonychartElement) => void)[] }> = {
    [ChartType.LOLLIPOP]: [requiredMeasure, requiredDimension],
    [ChartType.BAR]: [requiredMeasure, requiredDimension],
    [ChartType.PIE]: [requiredMeasure],
    [ChartType.TIME_BAR]: [requiredMeasure],
    [ChartType.YOY_TIME_BAR]: [requiredMeasure],
    [ChartType.DONUT]: [requiredMeasure, requiredDimension],
    [ChartType.LINE]: [requiredMeasure],
    [ChartType.AREA]: [requiredMeasure],
    [ChartType.KPI]: [requiredMeasure],
    [ChartType.MAP]: [requiredMeasure],
    [ChartType.TIME_PERIOD_INDICATION]: [],
}

export function validatePonychartElement(ponychartElement: PonychartElement) {
    for (const validator of CHART_TYPE_ERROR_CHECKERS?.[ponychartElement.componentType as ChartType] || []) {
        validator(ponychartElement)
    }
}