<template lang="pug">
v-autocomplete(
    :value='value',
    :items='items',
    :label='label',
    :return-object='returnObject',
    chips,
    @blur='$emit("blur")',
    @change='(e) => $emit("change", e)',
    @input='(e) => $emit("input", e)',
    multiple,
    deletable-chips,
    outlined,
    :disabled='disabled',
    :dense='dense',
    :filled='filled',
    :item-text='itemText',
    :hide-details='hideDetails',
    :item-value='itemValue',
    :messages='message ? [message] : []'
)
    template(v-slot:item='{ item }', v-if='twbDatasources.length > 0')
        v-row.px-1(align='center', v-if='item && item.twbIdx !== undefined')
            v-checkbox(:input-value='itemValues.includes(item[itemValue])')
            span {{ item[itemText] }}
            v-spacer
            datasource-pill(
                v-if='twbDatasources.length > 1',
                :twb-datasources='twbDatasources',
                :twb-idx='item ? item.twbIdx : 0',
                :text-limit='50',
                :small='true'
            )

    template(#selection='data')
        draggable(
            :id='data.index',
            :list='value',
            handle='.handle',
            v-bind='dragOptionsChips',
            :move='move',
            @change='change'
        )
            v-chip(
                style='margin: 1px 2px 1px 2px',
                draggable,
                :key='displayKey(data.item)',
                close,
                @mousedown.stop,
                @click.stop,
                :small='dense',
                @click:close='remove(data.index)'
            )
                v-icon.handle(
                    :small='dense',
                    :class='dense ? "mr-1" : "mr-2"',
                    style='cursor: grab',
                    left
                ) drag_indicator
                span {{ displayAlias(data.item) }}
</template>

<script>
import draggable from 'vuedraggable'
import DatasourcePill from '@/components/utils/DatasourcePill.vue'

export default {
    name: 'sortableAutocomplete',
    components: {
        draggable,
        DatasourcePill,
    },
    props: {
        items: {
            type: Array, //
            required: true,
        },
        value: {
            type: Array, //
            required: true,
        },
        label: {
            type: String, //
            required: true,
        },
        group: {
            type: String, //
            required: true,
        },
        dense: {
            type: Boolean, //
            default: false,
        },
        filled: {
            type: Boolean, //
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: '',
        },
        itemValue: {
            type: String,
            default: 'id',
        },
        itemText: {
            type: String,
            default: 'alias',
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        twbDatasources: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        dragged: {
            from: -1,
            to: -1,
            newIndex: -1,
        },
    }),
    computed: {
        itemValues() {
            return this.value.map((item) => item[this.itemValue])
        },
        dragOptionsChips() {
            return {
                animation: 200,
                group: this.group,
                disabled: false,
                ghostClass: 'ghost',
                sort: true,
            }
        },
    },
    methods: {
        displayKey(item) {
            if (typeof item === 'string') {
                return item
            } else {
                return item[this.itemValue]
            }
        },
        displayAlias(item) {
            if (typeof item === 'string') {
                return item
            } else {
                return item[this.itemText]
            }
        },
        move: function (value) {
            this.dragged = {
                from: parseInt(value.from.id),
                to: parseInt(value.to.id),
                newIndex: value.draggedContext.futureIndex,
            }
        },
        remove(i) {
            const value = [...this.value]
            value.splice(i, 1)
            this.$emit('change', value)
        },
        change: function (value) {
            const newValue = [...this.value]
            if (value.removed) {
                // insert
                newValue.splice(
                    this.dragged.to + this.dragged.newIndex,
                    0,
                    newValue[this.dragged.from]
                )
                // delete
                if (this.dragged.from < this.dragged.to)
                    // LTR
                    newValue.splice(this.dragged.from, 1)
                // RTL
                else newValue.splice(this.dragged.from + 1, 1)
            }
            if (
                newValue.length !== this.value.length ||
                newValue.some((item, i) => item !== this.value[i])
            ) {
                this.$emit('change', newValue)
            }
        },
    },
}
</script>
