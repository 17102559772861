import { TraitId } from 'ponychart'
import { t, translations } from '@/ponychart/i18n'
import { SourceMemory } from '@/ponychart/memoize'
import type { GlobalState, TraitSearch } from '@/ponychart/state'
import { isBigSize } from '@/ponychart/utils'

import { SelectorId } from './types'
import { MONTHS, selectWrapper, datePickerWrapper } from './utils'

const VUE_APP_PUBLIC_PATH = process.env.VUE_APP_PUBLIC_PATH || '/'

type CompileOpts = {
    classes: string[]
    isDark: boolean
    label?: string
    styles?: { [k: string]: string }
}

export function compileRangeSelector(
    id: string,
    options: { id: string | number; alias: string }[],
    globalState: GlobalState,
    opts: CompileOpts
): string {
    return selectWrapper(id, options, {
        reduceSize: globalState.reduceSize,
        ...opts,
    })
}

export function compileInputSelector(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts & {
        min?: number
        max?: number
    }
): string {
    return selectWrapper(id, [], {
        reduceSize: globalState.reduceSize,
        tag: 'input',
        ...opts,
    })
}

export function compileAggregationDateSelector(
    id: string,
    globalState: GlobalState,
    traitSearch: TraitSearch,
    opts: CompileOpts
): string {
    const aggregationIds: string[] = (
        traitSearch.getTraitStringValue(TraitId.DATE_AGGREGATION_LEVEL) || ''
    )
        .split(';')
        .filter((e: string) => !!e)
    return selectWrapper(
        id,
        aggregationIds.map((id) => ({
            id,
            alias: t(`date.${id}`),
        })),
        {
            reduceSize: globalState.reduceSize,
            ...opts,
            label: t(`selects.${SelectorId.DATE_AGGREGATION_SELECTOR}`),
        }
    )
}

export function compileComparisonPeriodSelector(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts
): string {
    return selectWrapper(
        id,
        Object.keys(translations.en.comparisonPeriods).map((id) => ({
            id,
            alias: t(`${TraitId.COMPARISON_PERIODS}.${id}`),
        })),
        {
            ...opts,
            label: t(`selects.${SelectorId.COMPARISON_PERIOD_SELECTOR}`),
            reduceSize: globalState.reduceSize,
        }
    )
}

export function compileDimensionSelector(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts
): string {
    const memory = SourceMemory.getInstance()
    return selectWrapper(id, memory.dimensions || [], {
        ...opts,
        label: t(`selects.${SelectorId.DIMENSION_SELECTOR}`),
        reduceSize: globalState.reduceSize,
    })
}

export function compileChartSelector(
    id: string,
    globalState: GlobalState,
    ids: string[],
    opts: CompileOpts
): string {
    return selectWrapper(
        id,
        ids.map((id) => ({ id, alias: t(`charts.${id}`) })),
        {
            ...opts,
            label: t(`selects.${SelectorId.CHART_SELECTOR}`),
            reduceSize: globalState.reduceSize,
        }
    )
}

export function compileMeasureSelector(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts
): string {
    const { measures } = SourceMemory.getInstance()
    return selectWrapper(id, measures, {
        ...opts,
        label: t(`selects.${SelectorId.MEASURE_SELECTOR}`),
        reduceSize: globalState.reduceSize,
    })
}

export function compileDatePicker(
    id: string,
    title: string,
    globalState: GlobalState,
    opts: CompileOpts
): string {
    return datePickerWrapper(id, title, {
        ...opts,
        label: opts.label || t(`selects.${title}`),
        reduceSize: globalState.reduceSize,
    })
}
export function compileYearSelect(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts
): string {
    const y = new Date().getFullYear()
    return selectWrapper(
        id,
        [y, y - 1, y - 2].map((id) => ({ id, alias: id.toString() })),
        {
            ...opts,
            reduceSize: globalState.reduceSize,
        }
    )
}

export function compileFilterSelector(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts
): string {
    return selectWrapper(
        id,
        ['value 1', 'value 2', 'value 3'].map((id) => ({ id, alias: id })),
        {
            reduceSize: globalState.reduceSize,
            ...opts,
        }
    )
}

export function compileMonthSelect(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts
): string {
    return selectWrapper(
        id,
        MONTHS.map((m) => ({ id: m, alias: t(m) })),
        {
            ...opts,
            reduceSize: globalState.reduceSize,
        }
    )
}

export function compileYearMonthSelect(
    id: string,
    title: string,
    globalState: GlobalState,
    opts: CompileOpts
): string {
    const d = new Date()
    const y = d.getFullYear()
    const options = MONTHS.map((m) => t(m) + ' ' + String(y - 1))
    return selectWrapper(
        id,
        options.reverse().map((id) => ({ id, alias: id })),
        {
            ...opts,
            label: opts.label || t(`selects.${title}`),
            reduceSize: globalState.reduceSize,
        }
    )
}

function _compileImage(
    id: string,
    classes: string[],
    src: string,
    title = 'image',
    style = ''
) {
    return `<div id="${id}" title="${title}" class="${classes.join(
        ' '
    )}" style="display:block;position:relative;max-width:100%;max-height:100%;">
    <img id="${id}-img" src="${src}" class="${title}" style="position: absolute;top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); max-width: 100%; max-height:100%;${style}
;${style}">
</div>`
}

export function compileLogo(
    id: string,
    globalState: GlobalState,
    classes: string[]
) {
    return _compileImage(
        id,
        classes,
        globalState.logoUrl ? globalState.logoUrl : '/favicon.ico',
        'logo'
    )
}

export function compileMenuIcon(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts
) {
    const isDark = opts.isDark
    const classes = opts.classes || []
    return _compileImage(
        id,
        [...classes, ...(isBigSize(globalState.reduceSize) ? ['d-btn'] : [])],
        `${VUE_APP_PUBLIC_PATH}img/menu-${isDark ? 'black' : 'white'}.png`,
        'd-menu',
        'cursor: pointer'
    )
}

export function compileFilterIcon(
    id: string,
    globalState: GlobalState,
    opts: CompileOpts
) {
    const isDark = opts.isDark
    const classes = opts.classes || []
    return _compileImage(
        id,
        [...classes, ...(isBigSize(globalState.reduceSize) ? ['d-btn'] : [])],
        `${VUE_APP_PUBLIC_PATH}img/filter-${isDark ? 'black' : 'white'}.png`,
        'd-filter',
        'cursor: pointer'
    )
}
