import axios from 'axios'

export default {
  listInvoices: async function() {
    const {
      data: { invoices }
    } = await axios(`/v1/invoices`)
    return invoices
  }
}
