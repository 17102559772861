import {
    applyCrossDeviceValues,
    listTraits,
    mainTag,
    PageBlock,
    SourceMemory,
} from '@/ponychart'
import { TraitSearch } from '@/ponychart/state/traits'
import { DeviceType, Trait, TraitId } from 'ponychart'
import { Vue, Component } from 'vue-property-decorator'

const memoryInstance = SourceMemory.getInstance()

const NOT_IMPLEMENTED_TRAITS = [TraitId.PADDING]

@Component
export class Traits extends Vue {
    traitKey(trait: Trait): string {
        return [
            trait.id,
            mainTag(trait.querySelectorTags || [0]),
            trait.deviceType || '',
            trait.chartType || '',
        ].join('|')
    }

    filterSourceTraits(traits: Trait[]): Trait[] {
        return traits
            .filter(
                (trait) =>
                    !trait?.deviceType ||
                    trait.deviceType === (this as any).deviceType
            )
            .filter((trait) => !NOT_IMPLEMENTED_TRAITS.includes(trait.id))
            .sort((a: Trait, b: Trait) =>
                String(a.id).localeCompare(String(b.id))
            )
    }

    refreshSourceTraits(
        traits: Trait[],
        opts: { emit?: boolean; initialTraits?: Trait[] } = {}
    ) {
        // const traits: Trait[] = (this as any).sourceTraits;
        const traitSearch = TraitSearch.createInstance(opts.initialTraits)

        // Just in case we push the default traits
        traitSearch.pushTraits(memoryInstance.allAvailableSourceTraits, {
            attributesToUpdate: ['!value'],
        })

        // By pushing all sourceTraits to state, they get converted to CustomTrait
        // thus setValue is called and the potential value should be set back to a possible state
        traitSearch.pushTraits(traits, { attributesToUpdate: ['*'] })

        const sourceTraits = traitSearch.sourceTraits.map((trait) =>
            trait.toDict()
        )

        if (opts.emit) {
            this.$emit('set', { source: { traits: sourceTraits } })
        }
        return sourceTraits

        // if ((this as any).sourceTraits.filter((t: any) => t.options && t.options.length).map((t: any) => t.id).includes(TraitId.FONT)) this.refreshCount += 1
    }

    refreshPageTraits(
        pageBlock: PageBlock,
        traitSearch: TraitSearch,
        modifiedTrait?: Trait
    ) {
        // setting twbIdx in traitSearch context at least
        traitSearch.twbIdx = pageBlock.twbIdx

        // Applies similar values to other traits in other deviceTypes
        if (modifiedTrait)
            applyCrossDeviceValues(pageBlock.traits, modifiedTrait)

        // log("BEFORE pageBlock.traits", pageBlock.traits, TraitId.CHART_1, DeviceType.DESKTOP)
        // log("BEFORE traitSearch.traits", traitSearch.traits.map(t => t.toDict()), TraitId.CHART_1, DeviceType.DESKTOP)

        traitSearch.pushTraits(pageBlock.traits, { attributesToUpdate: ['*'] })
        if (modifiedTrait)
            traitSearch.pushTraits([modifiedTrait], {
                attributesToUpdate: ['*'],
            })

        // console.log(
        //     JSON.stringify(traitSearch.traits.filter(t => t.id == TraitId.CHART_1 && t.deviceType === DeviceType.DESKTOP).map(t => ({ id: t.id, value: t.value, q: mainTag(t.querySelectorTags || [0]) }))),
        // )

        // log("AFTER pageBlock.traits", pageBlock.traits, TraitId.CHART_1)
        // log("AFTER traitSearch.traits", traitSearch.traits.map(t => t.toDict()), TraitId.CHART_1, DeviceType.DESKTOP)

        pageBlock.traits = listTraits(pageBlock.structure, traitSearch)
    }
}

function log(
    msg: string,
    traits: Trait[],
    traitId: TraitId,
    deviceType?: DeviceType
) {
    const t = traits.find((t) => t.id == traitId)
    const tValue = t ? { ...t } : {}
    delete (tValue as any).options
    console.log(
        msg,
        traitId,
        traits.filter(
            (t) =>
                t.id == traitId && (!deviceType || t.deviceType === deviceType)
        ).length,
        JSON.stringify(tValue, null, 2)
    )
}
