import Vue from 'vue'
import { Dict } from './actions/utils'
import { State } from './state'

export default {
    SET(state: State, payload: Dict) {
        for (const i in payload) {
            if (typeof payload[i] == 'object') {
                if (!state[i]) state[i] = {}
                for (const j in payload[i]) {
                    Vue.set(
                        (state as any)[i],
                        j,
                        Array.isArray((payload as any)[i][j])
                            ? [...payload[i][j]]
                            : payload[i][j]
                    )
                }
            } else {
                Vue.set(
                    state,
                    i,
                    Array.isArray(payload[i]) ? [...payload[i]] : payload[i]
                ) // make a copy of the array if array
            }
        }
    },
    TOGGLE_EVENT(state: State, payload: string) {
        state.events[payload] = !state.events[payload]
    },
    DEL(state: State, payload: Dict) {
        for (const i in payload) {
            if (Array.isArray(payload[i])) {
                ; (state as any)[i] = [
                    ...(state as any)[i].filter((v: Dict) => v.id != payload[i].id),
                ]
            } else {
                for (const j in payload[i]) {
                    ; (state as any)[i][j] = [
                        ...(state as any)[i][j].filter((v: Dict) => v.id != payload[i][j].id),
                    ]
                }
            }
        }
    },
    // Array mutation adding an element as last element
    ADD(state: State, payload: Dict) {
        for (const i in payload) {
            ; (state as any)[i].push(payload[i])
        }
    },
    // Array mutation replacing an element based on id property
    EDIT(state: State, payload: Dict) {
        for (const i in payload) {
            if (Array.isArray((state as any)[i])) {
                const idx = state[i]
                    .map((s: Dict) => s.id)
                    .indexOf(payload[i].id)
                state[i].splice(idx, 1, { ...payload[i] })
            } else {
                for (const j in payload[i]) {
                    if (Array.isArray((state as any)[i][j])) {
                        const idx = state[i][j]
                            .map((s: Dict) => s.id)
                            .indexOf(payload[i][j].id)
                        state[i][j].splice(idx, 1, { ...payload[i][j] })
                    }
                }
            }
        }
    }
}
