import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import { Store } from '../actions/utils'

export default {
  googleSignin: (_store: Store) => {
    return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
  },
  fetchCurrentAuthenticatedUser: ({ commit }: Store) => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          commit('setUser', user)
          resolve(user)
        })
        .catch(reject)
    })
  },
  fetchSession: ({ commit }: Store) =>
    new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          Auth.currentUserPoolUser()
            .then(user => {
              commit('setUser', user)

              resolve(session)
            })
            .catch(reject)
        })
        .catch(reject)
    }),
  fetchJwtToken: () =>
    new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          resolve(session.getAccessToken().getJwtToken())
        })
        .catch(reject)
    }),
  signInUser: (
    { commit }: Store,
    credentials: { username: string; password: string }
  ) =>
    new Promise((resolve, reject) => {
      Auth.signIn(credentials.username, credentials.password)
        .then(user => {
          commit('setUser', user)

          resolve(user)
        })
        .catch(reject)
    }),
  answerCustomChallenge: (
    { commit }: Store,
    credentials: { user: any; answer: string }
  ) =>
    new Promise((resolve, reject) => {
      Auth.sendCustomChallengeAnswer(credentials.user, credentials.answer)
        .then(user => {
          commit('setUser', user)

          resolve(user)
        })
        .catch(reject)
    }),
  registerUser: (
    { commit }: Store,
    credentials: { username: string; password: string; attributes: any }
  ) =>
    new Promise((resolve, reject) => {
      // TODO: Ensure I'm attribute agnostic
      Auth.signUp({
        username: credentials.username,
        password: credentials.password,
        attributes: credentials.attributes
      })
        .then(user => {
          commit('setUser', user)

          resolve(user)
        })
        .catch(reject)
    }),
  confirmUser: (_: Store, data: { username: string; code: string }) =>
    new Promise((resolve, reject) => {
      Auth.confirmSignUp(data.username, data.code)
        .then(resolve)
        .catch(reject)
    }),
  resendConfirmation: (_: Store, data: { username: string }) =>
    new Promise((resolve, reject) => {
      Auth.resendSignUp(data.username)
        .then(resolve)
        .catch(reject)
    }),
  forgotPassword: (_: Store, data: { username: string }) =>
    new Promise((resolve, reject) => {
      Auth.forgotPassword(data.username)
        .then(resolve)
        .catch(reject)
    }),
  completeNewPassword: (
    { commit }: Store,
    { user, newPassword }: { user: any; newPassword: string }
  ) =>
    new Promise((resolve, reject) =>
      Auth.completeNewPassword(user, newPassword)
        .then(result => {
          commit('setUser', result)
          resolve(result)
        })
        .catch(reject)
    ),
  changePassword: (
    _: Store,
    { oldPassword, newPassword }: { oldPassword: string; newPassword: string }
  ) =>
    new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, oldPassword, newPassword))
        .then(result => {
          resolve(result)
        })
        .catch(reject)
    }),
  forgotPasswordSubmit: (
    _: Store,
    data: { username: string; code: string; newPassword: string }
  ) =>
    new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(data.username, data.code, data.newPassword)
        .then(resolve)
        .catch(reject)
    }),
  signOut: ({ commit, getters }: Store) =>
    new Promise((resolve, reject) => {
      if (!getters.isLoggedIn) {
        reject(new Error('User not logged in.'))
      }

      Auth.signOut()
        .then(result => {
          commit('setUser', {})

          resolve(result)
        })
        .catch(reject)

      if (localStorage) localStorage.removeItem('USER')
    }),
  init(
    _: Store,
    config: { userPoolId: string; userPoolWebClientId: string; region: string }
  ) {
    if (
      !['userPoolId', 'userPoolWebClientId', 'region'].every(opt =>
        Boolean(config[opt])
      )
    ) {
      throw new Error(
        'userPoolId, userPoolWebClientId and region are required in the config object.'
      )
    }

    Amplify.configure({ Auth: config })
  }
}
