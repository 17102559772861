enum Placement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right'
}

enum TourName {
  MEASURE = 'measure',
  DIMENSION = 'dimension',
  STYLING = 'styling',
  HEADER_DESIGN = 'header-design',
  PAGES = 'pages',
  HOME_BIS = "home-bis",
  PAGE_EDIT_POPUP = "page-edit-popup",
  PAGE_SELECTOR = "page-selector",
  FIRST_PAGE = "first-page"
}

export const GIF_STEPS = [
  "open",
  "upload",
  "steps",
  "download"
]

export interface TourStepDict {
  target: string,
  content: string,
  params: {
    placement: Placement,
    highlight: boolean,
    enableScrolling: boolean
  }
}

export class TourStep {
  suffix: string
  target: string
  placement: Placement
  highlight: boolean
  enableScrolling: boolean
  constructor(
    public name: TourName,
    public content: string,
    opts: {
      target?: string
      highlight?: boolean
      enableScrolling?: boolean
      placement?: Placement
    } = {}
  ) {
    const contents = content.split('.')
    this.suffix = contents[contents.length - 1]
    this.target = opts?.target || `#data-v-step-${name}-${this.suffix}`
    this.placement = opts?.placement || Placement.BOTTOM
    this.highlight = opts?.highlight || false
    this.enableScrolling = opts?.enableScrolling || false
  }

  toDict(): TourStepDict {
    const substep = [TourName.DIMENSION, TourName.MEASURE].includes(this.name) ? "elements" : this.name
    return {
      target: this.target,
      content: `tour.${this.content.includes(".") ? "" : substep + "."}${this.content}`,
      params: {
        placement: this.placement,
        highlight: this.highlight,
        enableScrolling: this.enableScrolling
      }
    }
  }
}

const MEASURE_STEPS = [
  new TourStep(TourName.MEASURE, `elements`, {
    placement: Placement.TOP,
    highlight: true
  }),
  new TourStep(TourName.MEASURE, `chips`, { highlight: true }),
  new TourStep(TourName.MEASURE, `add`, { highlight: true }),
  new TourStep(TourName.MEASURE, `deselect`, { highlight: true }),
  new TourStep(TourName.MEASURE, `format`, { highlight: true }),
  new TourStep(TourName.MEASURE, 'drag', { highlight: true }),
  new TourStep(TourName.MEASURE, 'column'),
  new TourStep(TourName.MEASURE, 'agg'),
  new TourStep(TourName.MEASURE, `alias`),
  new TourStep(TourName.MEASURE, 'format-btn'),
  new TourStep(TourName.MEASURE, 'legend', {
    placement: Placement.BOTTOM
  }),
  new TourStep(TourName.MEASURE, 'color', {
    placement: Placement.BOTTOM,
    target: ".data-v-step-measure-color"
  }),
  new TourStep(TourName.MEASURE, 'edit'),
  new TourStep(TourName.MEASURE, 'clone'),
  new TourStep(TourName.MEASURE, 'delete'),
  new TourStep(TourName.MEASURE, 'help', { enableScrolling: true, placement: Placement.BOTTOM }),
  new TourStep(TourName.MEASURE, 'finish', { enableScrolling: true }),
]

const DIMENSION_STEPS = [
  new TourStep(TourName.DIMENSION, `elements`, {
    placement: Placement.TOP,
    highlight: true
  }),
  new TourStep(TourName.DIMENSION, `chips`, { highlight: true }),
  new TourStep(TourName.DIMENSION, `add`, { highlight: true }),
  new TourStep(TourName.DIMENSION, `deselect`, { highlight: true }),
  new TourStep(TourName.DIMENSION, 'drag', { highlight: true }),
  new TourStep(TourName.DIMENSION, 'column'),
  new TourStep(TourName.DIMENSION, 'alias'),
  new TourStep(TourName.DIMENSION, 'edit'),
  new TourStep(TourName.DIMENSION, 'delete')
]

const STYLING_STEPS = [
  new TourStep(TourName.STYLING, `preview`, {
    placement: Placement.RIGHT,
    highlight: true
  }),
  new TourStep(TourName.STYLING, `logo`, {
    highlight: true
  }),
  new TourStep(TourName.STYLING, `sidebar`),
  new TourStep(TourName.STYLING, `selects`, {
    highlight: false,
    placement: Placement.LEFT
  }),
  new TourStep(TourName.STYLING, `device-type`, { placement: Placement.LEFT }),
  new TourStep(TourName.STYLING, `slider`, { placement: Placement.TOP, target: ".page-trait-border" }),
  new TourStep(TourName.STYLING, `colors`, {
    highlight: true,
    enableScrolling: true,
    placement: Placement.LEFT
  }),
  new TourStep(TourName.STYLING, `custom`, { enableScrolling: true, highlight: true, placement: Placement.LEFT })
]
const HEADER_DESIGN_STEPS_1 = [
  new TourStep(TourName.HEADER_DESIGN, 'agg', { highlight: true }),
  new TourStep(TourName.HEADER_DESIGN, 'date-field', { target: ".data-v-step-header-design-date-field", highlight: true }),
  new TourStep(TourName.HEADER_DESIGN, 'create-date', { target: ".data-v-step-header-design-create-date" }),
  new TourStep(TourName.HEADER_DESIGN, 'continue', { target: ".data-v-step-header-design-continue", highlight: true }),
]
const HEADER_DESIGN_STEPS_2 = [
  new TourStep(TourName.HEADER_DESIGN, 'filters', { placement: Placement.TOP, highlight: true, target: ".data-v-step-header-design-filters" }),
]
const HEADER_DESIGN_STEPS_3 = [
  new TourStep(TourName.HEADER_DESIGN, 'design', { placement: Placement.TOP, highlight: true, target: ".data-v-step-header-design-design" })
]
const HOME_BIS_STEPS = [
  new TourStep(TourName.HOME_BIS, 'upload', { placement: Placement.LEFT, highlight: true }),
]
const PAGE_STEPS = [
  new TourStep(TourName.PAGES, 'add', { placement: Placement.RIGHT }),
  new TourStep(TourName.PAGES, 'preview', { placement: Placement.RIGHT, target: ".page-preview" }),
  new TourStep(TourName.FIRST_PAGE, 'download', { placement: Placement.TOP }),
]
const FIRST_PAGE_STEPS = [
  new TourStep(TourName.STYLING, 'preview', { placement: Placement.RIGHT, highlight: true }),
  new TourStep(TourName.FIRST_PAGE, 'block', { placement: Placement.LEFT, highlight: true, target: ".page-edit-block" }),
  new TourStep(TourName.FIRST_PAGE, 'add-block'),
  new TourStep(TourName.FIRST_PAGE, 'trait', { target: ".page-trait", placement: Placement.LEFT, highlight: true }),
  new TourStep(TourName.FIRST_PAGE, 'chart', { target: ".x-chart.x-reduce-size-3", highlight: true }),
  new TourStep(TourName.FIRST_PAGE, 'device-type', { highlight: true, target: ".data-v-step-device-type" }),
  new TourStep(TourName.FIRST_PAGE, 'download', { placement: Placement.TOP }),
]
const PAGE_SELECTOR_STEPS = [
  new TourStep(TourName.PAGE_SELECTOR, 'structures', { highlight: true }),
  new TourStep(TourName.PAGE_SELECTOR, 'structure-type', { highlight: true }),
  new TourStep(TourName.PAGE_SELECTOR, 'measures', { highlight: true }),
  new TourStep(TourName.PAGE_SELECTOR, 'chart-count', { highlight: true }),
  new TourStep(TourName.PAGE_SELECTOR, 'add', { highlight: true }),
]
const PAGE_EDIT_POPUP_STEPS = [
  new TourStep(TourName.PAGE_EDIT_POPUP, 'preview', { placement: Placement.RIGHT, highlight: true }),
  new TourStep(TourName.PAGE_EDIT_POPUP, 'trait', { target: ".page-edit-popup-trait", placement: Placement.LEFT, highlight: true }),
  new TourStep(TourName.PAGE_EDIT_POPUP, 'other-chart', { highlight: true, target: ".x-chart.x-reduce-size-3-5:not(.gradient-border)" }),
]

export const STEP_MAP: { [k: string]: TourStep[] } = {
  measure: MEASURE_STEPS,
  dimension: DIMENSION_STEPS,
  styling: STYLING_STEPS,
  'header-design-1': HEADER_DESIGN_STEPS_1,
  'header-design-2': HEADER_DESIGN_STEPS_2,
  'header-design-3': HEADER_DESIGN_STEPS_3,
  home: [],
  'home-bis': HOME_BIS_STEPS,
  pages: PAGE_STEPS,
  'first-page': FIRST_PAGE_STEPS,
  'page-edit-popup': PAGE_EDIT_POPUP_STEPS,
  'page-selector': PAGE_SELECTOR_STEPS,
}