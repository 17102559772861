<template lang="pug">
v-card
    v-card-title 
        span {{ $t("title") }}
        v-spacer
        v-btn(icon, small, @click='$emit("close")')
            v-icon close
    v-card-text
        span {{ $t("desc") }}
    v-card-actions
        v-spacer
        v-btn(color='primary', @click='$emit("close")')
</template>

<script>
export default {}
</script>

<i18n>{
  "fr": {
    "title": "About the measures"
  },
  "en": {
    "title": "About the measures"
  },
  "es": {
    "title": "About the measures"
  },
  "de": {
    "title": "Über die Maßnahmen"
  }
}</i18n>