import { PonychartElement } from '@/ponychart/element/model'
import type { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import { SVGChart } from '@/ponychart/svg/model'
import { ColorLegend } from '@/ponychart/trait'
import { TextElementParser } from '@/ponychart/textElement/parser'
import { LineRow } from '@/ponychart/textElement'
import { RandomMemory } from '@/ponychart/memoize'
import { getColorValues } from '@/ponychart/utils'

import { ChartType, TraitId } from 'ponychart'

import { initTooltip } from './utils'
import { Position } from './types'

function _circumPointFromAngle(cx: number, cy: number, r: number, a: number) {
    return [cx + r * Math.cos(a), cy + r * Math.sin(a)]
}
const SVG_NS = 'http://www.w3.org/2000/svg' as const

function _drawText(o: any) {
    // create a new text element
    const text = document.createElementNS(SVG_NS, 'text')
    //set the attributes for the text
    for (const name in o.props) {
        if (o?.props?.[name]) {
            text.setAttributeNS(null, name, o.props[name])
        }
    }
    // set the text content
    text.textContent = o.txtContent
    // append the text to an svg element of your choice
    return text.outerHTML
}

export function buildSvgDonut(
    center: Position,
    radius: number,
    opts: {
        isDonut: boolean
        numericValues: number[]
        colorValues: string[]
        evoValues: number[]
        idx: number
        tooltipId?: string
        reduceSize: number
        randomize: boolean
    }
) {
    let theta = -Math.PI * 0.5
    const smallRadius = opts.isDonut ? radius / 2 : 0
    const tempNumericValues = opts.randomize
        ? opts.numericValues.map(
              (v, i) =>
                  v *
                  (1 -
                      3 *
                          opts.evoValues[
                              (i + opts.idx) % opts.evoValues.length
                          ])
          )
        : opts.numericValues
    opts.idx += 1
    const max = tempNumericValues.reduce((acc, val) => acc + val, 0)
    const numericValues = tempNumericValues.map((v) => v / max)
    return numericValues
        .map((val, idx) => {
            // Get the angle of the segment (delta)
            const delta = val * 2 * Math.PI

            // // also get the normal from the center of this segment (for transition)
            // var normal = theta + delta * 0.5

            // These are the points on the circumfrence that the arc starts and ends

            const startPos = _circumPointFromAngle(
                center.x,
                center.y,
                radius,
                theta
            )
            const smallStartPost = _circumPointFromAngle(
                center.x,
                center.y,
                smallRadius,
                theta
            )
            const endPos = _circumPointFromAngle(
                center.x,
                center.y,
                radius,
                theta + delta
            )
            const smallEndPost = _circumPointFromAngle(
                center.x,
                center.y,
                smallRadius,
                theta + delta
            )

            // Add the current delta to theta for the next segment
            theta += delta

            // if the arc is greater than 180° we need to use the large arc flag
            let largeArc = '0 0 1'
            let inverseLargeArc = '0 0 0'
            if (delta >= Math.PI) {
                largeArc = '0 1 1'
                inverseLargeArc = '0 1 0'
            }

            // for convenience store each path command in an array
            const actions = []
            // actions.push('M ' + center + ' ' + center)
            actions.push('M ' + smallStartPost.join(' '))
            actions.push('L ' + startPos.join(' '))
            actions.push(
                'A ' +
                    radius +
                    ' ' +
                    radius +
                    ' ' +
                    largeArc +
                    ' ' +
                    endPos.join(' ')
            )
            actions.push('L ' + smallEndPost.join(' '))
            actions.push(
                'A ' +
                    smallRadius +
                    ' ' +
                    smallRadius +
                    ' ' +
                    inverseLargeArc +
                    ' ' +
                    smallStartPost.join(' ')
            )
            actions.push('Z')

            // create the element, set the d attr, data attrs and fill style
            const path = document.createElementNS(SVG_NS, 'path')
            if (opts.tooltipId) {
                path.setAttribute(
                    'onmousemove',
                    `s(evt, '${opts.tooltipId}', ${JSON.stringify({
                        thisMeasure: val,
                        lastMeasure: (1 - opts.evoValues[idx]) * val,
                    })});`
                )
                path.setAttribute('onmouseout', `h();`)
            }
            path.setAttribute('d', actions.join('\n'))
            // path.setAttribute('data-label', data[idx].label);
            // path.setAttribute('data-value', data[idx].value);
            // path.setAttribute('data-normal', normal);
            // path.style.fill = svgChart.colorValues[idx]
            path.setAttribute(
                'style',
                `fill: ${
                    opts.colorValues[idx % opts.colorValues.length]
                }; circle:hover {opacity: 0.8}; cursor: pointer; opacity: ${
                    opts.randomize ? 0.9 : 1
                }`
            )
            const output = path.outerHTML
            if (opts.reduceSize === 1) {
                return (
                    output +
                    _drawText({
                        props: {
                            x: center,
                            y: center,
                            'dominant-baseline': 'middle',
                            'text-anchor': 'middle',
                            'font-size': '0.5em',
                        },
                        txtConent: '#.##',
                    })
                )
            }

            return output
        })
        .join('')
    // return svg
}

function _buildDonut(
    element: PonychartElement,
    chartType: ChartType.DONUT | ChartType.PIE
) {
    if (element.globalState.onlyContainer)
        return SVGChart.onlyContainer(element.id, element.classes)

    const memory = RandomMemory.getInstance()

    const measure = element.getStringRequiredAttribute(TraitId.MEASURE)
    const dimension = element.getStringRequiredAttribute(TraitId.DIMENSION)
    const color = element.getStringRequiredAttribute(
        TraitId.COLOR
    ) as ColorLegend

    const { numericValues, colors } = memory.getSorted(measure, dimension)
    const evoValues = memory.getEvoValues(measure, dimension)
    const colorValues =
        color === ColorLegend.DIMENSION
            ? colors
            : getColorValues(color, {
                  values: numericValues,
                  evoValues,
                  curveColor: element.globalState.colors.curves,
              })

    const parser = new TextElementParser()
    const opts = {
        measureId: measure,
        dimensionId: dimension,
        thisMeasure: evoValues[0],
        lastMeasure: evoValues[1],
    }
    const labelLines: LineRow[] = parser.parse(
        element.getStringRequiredAttribute(TraitId.LABEL)
    )
    const labelPreview =
        chartType === ChartType.DONUT
            ? LineRow.toPreviewSvg(labelLines, {
                  ...opts,
                  height: element.ratios.height || 100,
                  width: element.ratios.width || 100,
                  isMiddle: true,
                  reduceSize: 3, // Maybe a confusing name? We apply a 3 time reducing factor to the font size
                  // to make it fit in the donut :)
              })
            : ''
    const { id: tooltipId, html: tooltipHtml } = initTooltip(element, {
        measureId: measure,
        dimensionId: dimension,
    })

    const h = element.ratios.height || 100
    const w = element.ratios.width || 100

    const svgContent = buildSvgDonut(
        { x: w / 2, y: h / 2 },
        (Math.min(h, w) * 1) / 3,
        {
            isDonut: chartType === ChartType.DONUT,
            numericValues,
            colorValues,
            evoValues,
            tooltipId,
            randomize: false,
            idx: 0,
            reduceSize: element.globalState.reduceSize,
        }
    )

    return (
        new SVGChart(chartType).rawSvg(
            element.id,
            {
                classes: element.classes,
                preserveAspectRatio: {
                    x: 'Mid',
                    y: 'Mid',
                    suffix: 'meet',
                },
                viewBox: {
                    width: w,
                    height: h,
                },
                style: {
                    'min-height': '0',
                    flex: '1',
                },
            },
            svgContent + labelPreview
        ) + tooltipHtml
    )
}

export class Donut extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.DONUT, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return _buildDonut(this, ChartType.DONUT)
    }
}

export class Pie extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.PIE, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return _buildDonut(this, ChartType.PIE)
    }
}
