import { LineRow, TextElementV2 } from './model'

const parser = new DOMParser()

export class TextElementParser {
    parse(str: string): LineRow[] {
        const doc = parser.parseFromString(str, 'text/html')
        const lines = doc.body.children
        const lineRows: LineRow[] = []
        for (const line of lines) {
            const headingSize = parseInt(
                line.tagName.toLowerCase().replace('h', '').replace('p', '4')
            ) as any
            const alignment = ((line as HTMLElement).style.textAlign ||
                'left') as any
            const elements: TextElementV2[] = []
            let child = line.firstChild
            while (child) {
                if (child.nodeType === Node.TEXT_NODE) {
                    elements.push(new TextElementV2(child.nodeValue || ''))
                } else {
                    elements.push(
                        ...TextElementV2.fromHtmlBlock(child as HTMLElement)
                    )
                }
                child = child.nextSibling
            }
            lineRows.push(new LineRow(elements, { headingSize, alignment }))
        }
        return lineRows
    }
}
