
// Tableau publish lookalike popup
// import Quote from '@/components/utils/Quote.vue'
import Treeselect from '@riophae/vue-treeselect'
import AnimatedChart from '@/components/utils/AnimatedChart.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import GenerateService from '@/services/generateService'
import SourceService from '@/services/sourceService'
import TableauSecretService, {
    EMPTY_STRING,
    TableauSecret,
} from '@/services/tableauSecretService'

import {
    Page as _Page,
    ParameterInterface,
    Measure as _Measure,
    Dimension as _Dimension,
    ParameterTraitId,
    PageLocation,
    SourceMemory,
    Source,
    t,
    MAXIMUM,
    SourceMode,
    DEMO_LOCAL_STORAGE_KEY,
} from '@/ponychart'
import { TraitId } from 'ponychart'

import Component, { mixins } from 'vue-class-component'
import { GlobalMixins } from '@/mixins'
import { Prop, Watch } from 'vue-property-decorator'
import { TYPE } from 'vue-toastification'

type FinaliseStatus = 'SUCCESS' | 'FAILURE'

enum PublishMode {
    DOWNLOAD = 'download',
    PUBLISH_TO_TABLEAU_SERVER = 'publish_to_tableau_server',
}

const MESSAGE_LEVELS = {
    forbidden: TYPE.WARNING,
    filename_incorrect: TYPE.WARNING,
    has_had_subscription: TYPE.WARNING,
}

@Component({
    components: {
        // Quote,
        Treeselect,
        AnimatedChart,
    },
})
export default class FinaliseComponent extends mixins(GlobalMixins) {
    @Prop({ type: Boolean, required: true })
    readonly value!: boolean
    @Prop({ type: String, required: true })
    readonly filename!: string
    @Prop({ type: Array, required: true })
    readonly pages!: _Page[]
    @Prop({ type: Array, required: true })
    readonly parameters!: ParameterInterface[]
    @Prop({ type: Number, required: true })
    readonly downloadsToday!: number
    @Prop({ type: Object, required: true })
    readonly source!: Source
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: String, required: true })
    readonly sourceMode!: SourceMode
    @Prop({ type: String, required: false })
    readonly demoSourceUuid!: string

    get isSubscribed() {
        return !!this.$store.getters.subscription
    }

    get isDemoMode() {
        return this.sourceMode === SourceMode.DEMO
    }

    loading = false
    dashboardTitle = ''
    status: { status: FinaliseStatus | null; payload: any } = {
        status: null,
        payload: {},
    }
    url: null | string = null
    tableauUrl: null | string = null
    icon = {
        SUCCESS: 'check_circle',
    }
    color = {
        FAILURE: 'red',
        SUCCESS: 'green',
    }
    showParameterDescriptionIndex = -1
    tmt: number | null = null
    memory: SourceMemory = SourceMemory.getInstance()
    publishMode = PublishMode.DOWNLOAD
    projects: { id: string; label: string }[] = []
    projectAliases: { [k: string]: string } = {}
    tableauSecret: TableauSecret = {
        url: '',
        site: '',
        projectName: '',
        projectId: '',
        tokenName: '',
        encryptedSecretValue: '',
    }
    encryptedSecretValue = ''
    realSecretValue = ''
    realSecretValueMemory = ''
    tableauSecretTmt: number | null = null

    onTableauSecretChange() {
        if (!this.tableauSecretValid) return
        if (this.tmt) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.testConnection()
            },
            1000,
            this
        )
    }
    onTableauSecretPasswordChange(realValue: string) {
        this.realSecretValue = realValue
        this.onTableauSecretChange()
    }
    onSecretFocus() {
        this.realSecretValueMemory = this.realSecretValue
        this.realSecretValue = ''
    }
    onSecretBlur() {
        if (this.realSecretValue) {
            this.encryptedSecretValue = ''
        } else {
            this.realSecretValue = this.realSecretValueMemory || ''
        }
    }
    subAlias(alias: string, textLimit: number) {
        if (alias.length < textLimit + 3) return alias
        return alias.substring(0, textLimit) + '...'
    }
    onGetTableauSecretHook(tableauSecret: TableauSecret) {
        if (tableauSecret.encryptedSecretValue) {
            this.realSecretValue = EMPTY_STRING
            this.encryptedSecretValue = tableauSecret.encryptedSecretValue
        }
    }

    testConnectionStatusColor: 'success' | 'error' | 'primary' = 'primary'
    testConnectionLoading = false

    get tableauSecretValid() {
        const t = this.tableauSecret
        return t.url && t.tokenName && this.encryptedSecretValue
    }

    async onTreeViewChange() {
        if (!this.tableauSecret.projectId) return
        this.tableauSecret.projectName =
            this.projectAliases[this.tableauSecret.projectId]
        this.tableauSecret = await TableauSecretService.putTableauSecret(
            this.tableauSecret
        )
        this.onGetTableauSecretHook(this.tableauSecret)
    }

    async testConnection(focusTreeView = false) {
        this.testConnectionLoading = true
        this.testConnectionStatusColor = 'primary'
        try {
            this.tableauSecret = await TableauSecretService.putTableauSecret({
                ...this.tableauSecret,
                realSecretValue: this.realSecretValue,
            })
            this.onGetTableauSecretHook(this.tableauSecret)
            const { projects, projectAliases } =
                await TableauSecretService.listProjects()
            this.projects = projects
            this.projectAliases = projectAliases
            this.testConnectionStatusColor = 'success'
            if (focusTreeView) {
                await this.$nextTick()
                ;(this.$refs?.treeView as any)?.focusInput()
            }
        } catch (e) {
            console.log(e)
            this.testConnectionStatusColor = 'error'
        } finally {
            this.testConnectionLoading = false
        }
    }

    get isDownloadMode() {
        return this.publishMode === PublishMode.DOWNLOAD
    }

    get isPublishMode() {
        return this.publishMode === PublishMode.PUBLISH_TO_TABLEAU_SERVER
    }

    set isPublishMode(value: boolean) {
        if (!value) {
            this.publishMode = PublishMode.DOWNLOAD
        } else {
            this.publishMode = PublishMode.PUBLISH_TO_TABLEAU_SERVER
        }
    }

    get maxDownloads() {
        return this.isDemoMode
            ? MAXIMUM.demoDownloadsToday
            : MAXIMUM.downloadsToday
    }
    get hasDownloadedToday() {
        return this.downloadsToday >= this.maxDownloads
    }

    get downloadsTodayAlert() {
        const left = this.maxDownloads - this.downloadsToday
        if (this.hasDownloadedToday) {
            if (this.isDemoMode) return this.$t('thankYouForTrying')
            return this.$t('comeBackTomorrow')
        }
        return this.$t('onlyDownloadsLeftToday', {
            left,
            downloads: this.$tc('downloads', left),
        })
    }

    get displaySubscribeBtn() {
        return this.hasDownloadedToday && !this.isSubscribed
    }

    get displayAlreadyDownloadedWarning() {
        return !this.isSubscribed && this.hasDownloadedToday
    }

    get displayTextField() {
        return (
            !this.loading &&
            !this.url &&
            (!this.status || this.status.status != 'FAILURE')
        )
    }
    get computedParameters() {
        const typeCount = this.parameters.reduce(
            (acc, p) => ({ ...acc, [p.type]: (acc[p.type] || 0) + 1 }),
            {}
        )
        return this.parameters.filter((p) => typeCount[p.type] > 1)
    }

    get hasLogo() {
        return this.source.logo?.id
    }

    get hasFilters() {
        return (this.source.filters || [])?.length > 0
    }

    get isValid() {
        //TODO: Check behaviour when generating...
        return this.dashboardTitle.length > 0
    }

    get title() {
        if (this.hasDownloadedToday && !this.isSubscribed) {
            if (this.isDemoMode) return this.$t('signupForMore')
            return this.$t('hasDownloadedToday')
        }
        return this.dashboardTitle
            ? this.$t('download')
            : this.$t('filenameError')
    }

    get width() {
        if (this.loading || this.status.status || !this.isPublishMode)
            return 500
        return 800
    }

    get infos() {
        const output = [
            {
                icon: 'dashboard',
                text: 'pages',
                count: this.pages.length,
            },
            {
                icon: 'insights',
                text: 'measures',
                count: this.memory.measures.length,
            },
            {
                icon: 'leaderboard',
                text: 'dimensions',
                count: this.memory.dimensions.length,
            },
        ]
        if (this.hasFilters)
            output.push({
                icon: 'filter_alt',
                text: 'filters',
                count: this.memory.filters.length,
            })
        if (this.hasLogo) output.push({ icon: 'image', text: 'logo', count: 1 })
        return output
    }
    loadParameterSuffixes() {
        try {
            const payload = JSON.parse(
                localStorage.getItem('parameterSuffixes') || '{}'
            )
            return payload
        } catch (e) {
            return {}
        }
    }
    saveParameterSuffixes() {
        const payload = this.loadParameterSuffixes()
        const sourcePayload = {}
        payload[this.sourceId] = sourcePayload
        for (const parameter of this.parameters) {
            if (!sourcePayload[parameter.type])
                sourcePayload[parameter.type] = []
            sourcePayload[parameter.type].push(parameter.suffix)
        }
        localStorage.setItem('parameterSuffixes', JSON.stringify(payload))
    }
    setShowParameterIndex(i: number) {
        if (i === this.showParameterDescriptionIndex) {
            this.showParameterDescriptionIndex = -1
        } else {
            this.showParameterDescriptionIndex = i
        }
    }
    truncateString(s: string, num = 40) {
        if (s.length > num) {
            return s.slice(0, num) + '...'
        } else {
            return s
        }
    }

    summary(arr: string[]) {
        const output = []
        for (let i = 0; i < arr.length - 2; i++) {
            output.push(`<b>${arr.shift()}</b>,`)
        }
        if (arr.length === 1) return `<b>${arr[0]}</b>`
        output.push(`<b>${arr.shift()}</b>`)
        output.push('&')
        output.push(`<b>${arr.shift()}</b>`)
        return output.join(' ')
    }
    parameterLabel(parameter: ParameterInterface, i: number) {
        const type = parameter.type
        let count = 0
        for (let j = 0; j <= i; j++) {
            if (this.computedParameters[j].type == type) {
                count += 1
            }
        }
        return this.$t('suffixFor', {
            element: this.$t(parameter.type),
            ordinal: this.$t(`ordinals.${count - 1}`),
        })
    }
    parameterAlias(parameter: ParameterInterface) {
        function chartSelectorAlias(
            context: any,
            parameter: ParameterInterface
        ) {
            return String(
                context.$t('parameterAlias', {
                    element: context.$t('chartSelector'),
                    elements: context.summary(
                        parameter.ids.map((id) => t(`charts.${id}`))
                    ),
                })
            )
        }
        const constructorMap: { [t in ParameterTraitId]: string } = {
            [TraitId.DATE_AGGREGATION_LEVEL]: String(
                this.$t('parameterAlias', {
                    element: this.$t('dateAggregation'),
                    elements: this.summary(
                        parameter.ids.map((id) =>
                            String(this.$t('dates.' + id || ''))
                        )
                    ),
                })
            ),
            [TraitId.MEASURE]: String(
                this.$t('parameterAlias', {
                    element: this.$t('measure'),
                    elements: this.summary(
                        parameter.ids.map(
                            (id) => this.memory.measureNames[id] || ''
                        )
                    ),
                })
            ),
            [TraitId.MEASURE_2]: String(
                this.$t('parameterAlias', {
                    element: this.$t('measure'),
                    elements: this.summary(
                        parameter.ids.map(
                            (id) => this.memory.measureNames[id] || ''
                        )
                    ),
                })
            ),
            [TraitId.DIMENSION]: String(
                this.$t('parameterAlias', {
                    element: this.$t('dimension'),
                    elements: this.summary(
                        parameter.ids.map(
                            (id) => this.memory.dimensionNames[id] || ''
                        )
                    ),
                })
            ),
            [TraitId.DIMENSION_2]: String(
                this.$t('parameterAlias', {
                    element: this.$t('dimension'),
                    elements: this.summary(
                        parameter.ids.map(
                            (id) => this.memory.dimensionNames[id] || ''
                        )
                    ),
                })
            ),
            charts: chartSelectorAlias(this, parameter),
        }
        return constructorMap[parameter.type]
    }
    parameterLocation(parameter: ParameterInterface) {
        const locations = parameter.pageLocations.map(
            (pageLocation: PageLocation) => {
                return (
                    this.pages.find(
                        (page: _Page) => page.id == pageLocation.pageId
                    )?.alias || ''
                )
            }
        )
        return this.$tc('parameterLocation', locations.length, {
            element: this.$t(parameter.type),
            locations: this.summary(locations),
        })
    }
    showPayment() {
        this.$store.commit('TOGGLE_EVENT', 'payment')
        this.$emit('close')
    }
    initPrefixes() {
        const localStorageState =
            this.loadParameterSuffixes()?.[this.sourceId] || {}
        const count = {}
        for (const parameter of this.computedParameters) {
            const localStorageStateParameter =
                localStorageState[parameter.type] || []
            const currentCount = count[parameter.type] || 0
            parameter.suffix =
                localStorageStateParameter?.[currentCount] ||
                String(currentCount + 1)
            count[parameter.type] = currentCount + 1
        }
    }
    onParameterChanged() {
        if (this.tmt) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.saveParameterSuffixes()
            },
            1000,
            this
        )
    }
    save() {
        this.saveParameterSuffixes()
        return SourceService.saveSource(
            this.sourceId,
            {
                title: this.dashboardTitle,
                parameters: this.parameters,
                lang: this.lang,
            },
            { noResult: true }
        )
    }
    clean() {
        this.url = null
        this.tableauUrl = null
        this.loading = false
        this.status = { status: null, payload: {} }
    }

    async generateClassic() {
        await this.save()
        return await GenerateService.generate(
            this.source.id || 0,
            this.isPublishMode ? this.encryptedSecretValue : undefined
        )
    }

    async generateDemo() {
        const res = await GenerateService.generateDemo(
            this.demoSourceUuid,
            this.source.measures || [],
            this.source.dimensions || [],
            this.parameters || [],
            this.source.formats || [],
            this.source.pages || []
        )
        localStorage.setItem(DEMO_LOCAL_STORAGE_KEY, '1')
        return res
    }

    async generateDemoOrClassic() {
        if (this.isDemoMode) {
            return this.generateDemo()
        } else {
            return this.generateClassic()
        }
    }

    async generate() {
        if (this.isPublishMode && !this.tableauSecretValid) return
        this.clean()
        this.loading = true
        await this.generateDemoOrClassic()
            .then(({ url, tableauUrl }) => {
                this.url = url
                this.tableauUrl = tableauUrl
                this.status.status = 'SUCCESS'
                this.showToast({
                    message: 'Dashboard created',
                    type: TYPE.SUCCESS,
                })
                this.$emit('checkIfUserHasDownloadedToday')

                setTimeout(
                    () => {
                        const a = document.createElement('a')
                        a.href = tableauUrl ? tableauUrl : url
                        if (tableauUrl) {
                            a.target = '_blank'
                        }
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                    },
                    500,
                    this
                )
            })
            .catch((err) => {
                console.log(err)
                const code: string | undefined = err?.response?.data?.message
                console.log(err?.response?.data?.message)
                this.showToast({
                    code,
                    type: code ? MESSAGE_LEVELS[code] : undefined,
                })
            })
        this.loading = false
    }
    async loadTableauSecret() {
        if (this.isDemoMode) return
        try {
            const tableauSecret = await TableauSecretService.getTableauSecret()
            this.onGetTableauSecretHook(tableauSecret)
            this.tableauSecret = tableauSecret
        } catch (e) {
            console.warn(e)
        }
    }

    @Watch('value', { immediate: true })
    onShowChanged(show: boolean) {
        if (!show) {
            this.clean()
            // this.$emit("change", false)
        } else {
            this.loadTableauSecret()
            this.initPrefixes()
            this.save()
        }
        if (this.filename) this.dashboardTitle = this.filename
    }
}
