import { Bands, TraitId } from 'ponychart'
import { TITLE_HEIGHT } from '@/ponychart/header/config'
import { TraitSearch } from '@/ponychart/state/traits'
import { add, nToS } from '@/ponychart/utils'

export function getTitleHeight(traitSearch: TraitSearch) {
    const titleHeight =
        traitSearch.getTraitNumberValue(TraitId.TITLE_HEIGHT, 0) || TITLE_HEIGHT
    const bands = (traitSearch.getTraitStringValue(TraitId.BANDS, 0) || '')
        .split(';')
        .filter((v) => !!v)
    const bandWidth =
        traitSearch.getTraitNumberValue(TraitId.BAND_WIDTH, 0) || 0
    const bandHeightCount = [Bands.TOP, Bands.CENTER_HORIZONTAL].reduce(
        (acc: number, band: Bands) => acc + (bands.includes(band) ? 1 : 0),
        0
    )
    return add(titleHeight, bandWidth * bandHeightCount)
}

export function getInnerBodyHeight(
    traitSearch: TraitSearch,
    noTitle?: boolean
) {
    const titleHeight = noTitle ? '0px' : getTitleHeight(traitSearch)
    const bands = traitSearch.getTraitArrayValue(TraitId.BANDS, 0)
    const bandWidth = bands.includes(Bands.CENTER_HORIZONTAL)
        ? traitSearch.getTraitNumberValue(TraitId.BAND_WIDTH, 0) || 0
        : 0
    return `calc(100% - ${add(titleHeight, bandWidth)} )`
}

export function getTitleWidth(traitSearch: TraitSearch, noTitle?: boolean) {
    const bands = traitSearch.getTraitArrayValue(TraitId.BANDS, 0)
    const bandWidthCount = [Bands.TOP_LEFT, Bands.TOP_RIGHT].reduce(
        (acc: number, band: Bands) => acc + (bands.includes(band) ? 1 : 0),
        0
    )
    if (bandWidthCount === 0) return '100%'
    else {
        const bandWidth =
            traitSearch.getTraitNumberValue(TraitId.BAND_WIDTH, 0) || 0
        if (bandWidth === 0) return '100%'
        return `calc(100% - ${nToS(bandWidthCount * bandWidth)} )`
    }
}
