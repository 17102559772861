export const ELEMENT_HEIGHT = 47 // Parameter element height
export const TITLE_HEIGHT = 55 // Not used anymore (just for default value)
export const PAGE_BLOCK_HEADER_HEIGHT = 84
export const ELEMENT_WIDTH = 200
export const SIDEBAR_HEIGHT = 62
export const SIDEBAR_WIDTH = 250
export const SIDEBAR_BAND_WIDTH = 10
export const PAGE_MARGIN_TOP = 15
export const MOBILE_TOP_HEADER_HEIGHT = 60
export const MOBILE_BOTTOM_HEADER_HEIGHT = 200

export const TITLE_STYLE = {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    lineClamp: '2',
    justifyContent: 'center',
    paddingLeft: '10px',
    width: '100%',
} as const

export const LABEL_CLASS = 'da-flex da-container da-row' as const

export const SELECT_STYLE = {
    'line-height': '100%',
    height: '23px',
    'font-size': '14px',
    'padding-left': '8px',
    display: 'flex',
    'align-items': 'center',
    'border-radius': '2px',
    margin: '0 1px',
    'max-height': '25px',
} as const
