import { Bool, CustomTrait, defaultMeasureArray } from '@/ponychart/trait'
import { listCustomTraits } from '@/ponychart/trait/main'
import { DeviceType, ChartType, Trait, TraitId } from 'ponychart'
import { SourceMemory } from '@/ponychart/memoize'
import { DEFAULT_COLORS } from '@/ponychart/element'
import { TraitSearch } from '@/ponychart/state'

import { listPages } from './main'
import { PageStructure } from './types'
import { guessAppropriateColumnCountForKPICards } from './utils'

import { sample } from 'lodash'

const ALLOWED_STRUCTURES_FOR_RANDOM = [
    PageStructure.KPI_CARD_2_COL,
    PageStructure.UNIQUE_3_RIGHT,
    PageStructure.UNIQUE_3_LEFT,
    PageStructure.UNIQUE_4_DOWN,
    PageStructure.UNIQUE_4_TOP,
]
const RANDOM_DISABLED_TRAIT_IDS = [
    TraitId.CARD_HEIGHT,
    TraitId.INCLUDES_CHART_1,
    TraitId.INCLUDES_CHART_2,
    TraitId.INCLUDES_CHART_3,
    TraitId.INCLUDES_CHART_4,
    TraitId.COLUMN_COUNT,
    TraitId.BANDS,
    TraitId.MEASURE_COLOR,
    TraitId.INCLUDE_CARD_TITLE,
]
const memoryInstance = SourceMemory.getInstance()

function applyColumnCount(traits: CustomTrait[], count: number): void {
    for (const trait of traits) {
        if (trait.id !== TraitId.COLUMN_COUNT) continue
        trait.setValue(count)
    }
}

export function getEmptyPage() {
    const pageStructure =
        sample(ALLOWED_STRUCTURES_FOR_RANDOM) || PageStructure.KPI_CARD_2_COL
    const pages = listPages(
        {
            colors: DEFAULT_COLORS, // Will not be used here so does not matter what we put here
            pageStructure,
            navigation: true,
        },
        {},
        {
            limit: 1,
        }
    ).pages
    const page = pages[0]
    page.pageBlocks = []
    return page
}

export function getCardPage() {
    const measures =
        memoryInstance.measures || defaultMeasureArray({ length: 6 })
    const page = listPages(
        {
            pageStructure: PageStructure.KPI_CARD_2_COL,
            colors: DEFAULT_COLORS, // Will not be used here so does not matter what we put here
            navigation: true,
        },
        {},
        {
            limit: 1,
            measure: measures[0].id,
        }
    ).pages[0]
    if (page.pageBlocks?.[0]?.traits) page.pageBlocks[0].traits = []
    return page
}

export function buildRandomPage(sourceTraits: Trait[]) {
    const measures =
        memoryInstance.measures || defaultMeasureArray({ length: 6 })
    const twbIdx = Math.floor(
        Math.random() * memoryInstance.twbDatasources.length
    )
    const firstPage = listPages(
        {
            colors: DEFAULT_COLORS,
            navigation: true,
            twbIdx,
            pageStructure:
                sample(ALLOWED_STRUCTURES_FOR_RANDOM) ||
                PageStructure.KPI_CARD_2_COL,
        },
        {},
        {
            limit: 1,
        }
    ).pages[0]
    const secondPage = listPages(
        {
            colors: DEFAULT_COLORS,
            navigation: false,
            twbIdx,
            pageStructure:
                sample(ALLOWED_STRUCTURES_FOR_RANDOM) ||
                PageStructure.KPI_CARD_2_COL,
        },
        {},
        {
            limit: 1,
        }
    ).pages[0]
    firstPage.pageBlocks?.push(...(secondPage.pageBlocks || []))

    const pageBlocks = firstPage.pageBlocks || []
    const traitSearch = TraitSearch.createInstance(sourceTraits)

    for (const i in pageBlocks) {
        const pageBlock = pageBlocks[i]

        traitSearch.pushTraits(pageBlock.traits, { attributesToUpdate: ['*'] })

        for (const trait of traitSearch.traits) {
            if (RANDOM_DISABLED_TRAIT_IDS.includes(trait.id as TraitId))
                continue
            const value = trait.getRandomValue() as any
            trait.setValue(value)
        }

        listCustomTraits(pageBlock.structure, traitSearch)

        for (const traitId of [TraitId.MEASURE, TraitId.DIMENSION]) {
            const measureValue = traitSearch.getTraitStringValue(traitId, {
                deviceType: DeviceType.DESKTOP,
            })
            if (!measureValue) continue
            for (const deviceType of [DeviceType.TABLET, DeviceType.MOBILE]) {
                const deviceTrait = traitSearch.getTraitRequired(traitId, {
                    deviceType,
                })
                deviceTrait.setValue(measureValue)
            }
        }

        applyColumnCount(
            traitSearch.traits,
            guessAppropriateColumnCountForKPICards({
                measureCount: measures.length,
                maxCount: 3,
            })
        )
        pageBlock.traits = traitSearch.traits.map((t: CustomTrait) =>
            t.toDict()
        )
    }
    return firstPage
}
