import {
    ColumnContainer,
    PonychartElement,
    RowContainer,
} from '@/ponychart/element/model'
import { t } from '@/ponychart/i18n/translate'
import { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'

import { SelectorId } from './types'
import {
    EndDatePicker,
    EndMonthSelect,
    EndYearMonthSelect,
    EndYearSelect,
    StartDatePicker,
    StartMonthSelect,
    StartYearMonthSelect,
    StartYearSelect,
} from './model'
import { ComparisonPeriodElement } from './comparisonPeriod'
import { TraitId, DeviceType, TimeSelectorType } from 'ponychart'
import { toHtmlSafe } from '../utils'

function buildSplitSelector(
    selectorId: SelectorId.START_DATE_SELECTOR | SelectorId.END_DATE_SELECTOR,
    globalState: GlobalState,
    localState: LocalState,
    traitSearch: TraitSearch,
    opts: { label?: string } = {}
): PonychartElement {
    const YearSelect =
        selectorId === SelectorId.START_DATE_SELECTOR
            ? StartYearSelect
            : EndYearSelect
    const MonthSelect =
        selectorId === SelectorId.START_DATE_SELECTOR
            ? StartMonthSelect
            : EndMonthSelect
    const selectorState = new LocalState()
        .setStyles({
            height: '9px',
            fontSize: '9px',
        })
        .setIsFlex(true)
    const defaultState = new LocalState().setIsCol(true).setIsFlex(true)
    const content = opts.label || t('selects.' + selectorId)
    return new ColumnContainer(globalState, localState.copy().setIsFlex(true), {
        children: [
            new RowContainer(globalState, selectorState, {
                content,
                unsafe: true,
                fontRatio: 0.7,
                frontendSafeContent: `<span style="align-self: flex-end;">${toHtmlSafe(
                    content
                )}</span>`,
            }),
            new RowContainer(globalState, selectorState, {
                children: [
                    new MonthSelect(globalState, defaultState, traitSearch),
                    new YearSelect(globalState, defaultState, traitSearch),
                ],
            }),
        ],
    })
}

abstract class TimeSelector {
    constructor(
        protected globalState: GlobalState,
        protected traitSearch: TraitSearch
    ) {}

    public abstract build(localState: LocalState): PonychartElement[]

    protected get comparisonPeriods() {
        const trait = this.traitSearch.getTrait(TraitId.COMPARISON_PERIODS, {
            deviceType: DeviceType.DESKTOP,
            querySelectorTag: 0,
        })
        if (!trait) throw new Error('Could not find COMPARISON_PERIOD Trait')
        return (trait.options?.values || []).filter((value) => value.selected)
    }

    protected get hasComparisonElement() {
        const trait: any = this.traitSearch.getTrait(
            TraitId.COMPARISON_PERIODS,
            {
                deviceType: DeviceType.DESKTOP,
                querySelectorTag: 0,
            }
        )
        if (!trait) throw new Error('Could not find COMPARISON_PERIOD Trait')
        const count: any = this.traitSearch.getTrait(
            TraitId.COMPARISON_PERIOD_COUNT,
            {
                deviceType: DeviceType.DESKTOP,
                querySelectorTag: 0,
            }
        )
        if (!trait) throw new Error('Could not find COMPARISON_PERIOD Trait')
        return trait?.hasParameter && count?.hasParameter
    }
}

type TimeSelectorConstructor = {
    new (globalState: GlobalState, traitSearch: TraitSearch): TimeSelector
}

class DailyTimeSelector extends TimeSelector {
    build(localState: LocalState): PonychartElement[] {
        const output = [
            new StartDatePicker(
                this.globalState,
                localState,
                this.traitSearch,
                {
                    label: t('selects.date'),
                }
            ),
        ]
        if (this.hasComparisonElement)
            output.push(
                new ComparisonPeriodElement(
                    this.globalState,
                    localState,
                    this.traitSearch
                )
            )
        return output
    }
}

class DailyFromToSelector extends TimeSelector {
    build(localState: LocalState): PonychartElement[] {
        const output = [
            new StartDatePicker(this.globalState, localState, this.traitSearch),
            new EndDatePicker(this.globalState, localState, this.traitSearch),
        ]
        if (this.hasComparisonElement)
            output.push(
                new ComparisonPeriodElement(
                    this.globalState,
                    localState,
                    this.traitSearch
                )
            )
        return output
    }
}

class MonthlySelector extends TimeSelector {
    build(localState: LocalState): PonychartElement[] {
        const output = [
            new StartYearMonthSelect(
                this.globalState,
                localState.copy().setIsCol(true).setIsFlex(true),
                this.traitSearch,
                { label: t('selects.date') }
            ),
        ]
        if (this.hasComparisonElement)
            output.push(
                new ComparisonPeriodElement(
                    this.globalState,
                    localState,
                    this.traitSearch
                )
            )
        return output
    }
}

class MonthlyFromToSelector extends TimeSelector {
    build(localState: LocalState): PonychartElement[] {
        const newState = localState.copy().setIsCol(true).setIsFlex(true)
        const output = [
            new StartYearMonthSelect(
                this.globalState,
                newState,
                this.traitSearch
            ),
            new EndYearMonthSelect(
                this.globalState,
                newState,
                this.traitSearch
            ),
        ]
        if (this.hasComparisonElement)
            output.push(
                new ComparisonPeriodElement(
                    this.globalState,
                    localState,
                    this.traitSearch
                )
            )
        return output
    }
}

class MonthlySplitSelector extends TimeSelector {
    build(localState: LocalState): PonychartElement[] {
        const output = [
            buildSplitSelector(
                SelectorId.START_DATE_SELECTOR,
                this.globalState,
                localState,
                this.traitSearch,
                { label: t('selects.date') }
            ),
        ]
        if (this.hasComparisonElement)
            output.push(
                new ComparisonPeriodElement(
                    this.globalState,
                    localState,
                    this.traitSearch
                )
            )
        return output
    }
}

class MonthlyFromToSplitSelector extends TimeSelector {
    build(localState: LocalState): PonychartElement[] {
        const output = [
            buildSplitSelector(
                SelectorId.START_DATE_SELECTOR,
                this.globalState,
                localState,
                this.traitSearch
            ),
            buildSplitSelector(
                SelectorId.END_DATE_SELECTOR,
                this.globalState,
                localState,
                this.traitSearch
            ),
        ]
        if (this.hasComparisonElement)
            output.push(
                new ComparisonPeriodElement(
                    this.globalState,
                    localState,
                    this.traitSearch
                )
            )
        return output
    }
}

export class TimeSelectorFactory {
    private timeSelectorMap: {
        [k in TimeSelectorType]: TimeSelectorConstructor
    } = {
        [TimeSelectorType.DAILY]: DailyTimeSelector,
        [TimeSelectorType.DAILY_FROM_TO]: DailyFromToSelector,
        [TimeSelectorType.MONTHLY]: MonthlySelector,
        [TimeSelectorType.MONTHLY_SPLIT]: MonthlySplitSelector,
        [TimeSelectorType.MONTHLY_FROM_TO]: MonthlyFromToSelector,
        [TimeSelectorType.MONTHLY_FROM_TO_SPLIT]: MonthlyFromToSplitSelector,
    }

    constructor(
        private globalState: GlobalState,
        private traitSearch: TraitSearch
    ) {}

    build(localState: LocalState): PonychartElement[] {
        const t =
            this.traitSearch.getTraitStringRequiredValue(
                TraitId.TIME_SELECTOR_TYPE,
                0
            ) || TimeSelectorType.MONTHLY
        return new this.timeSelectorMap[t](
            this.globalState,
            this.traitSearch
        ).build(localState)
    }
}
