<template lang="pug">
v-container.px-1(sm='12', md='12', xl='10')
    v-dialog(persistent, max-width='700', :value='!!uuid')
        v-card(max-width='700')
            v-card-title
                span {{ title }}
                v-spacer
                v-progress-circular.ml-4(
                    indeterminate,
                    width='3',
                    size='20',
                    v-if='loading',
                    color='primary'
                )
                v-btn(v-else, icon, @click='close')
                    v-icon close
            v-divider(v-if='!loading')
            v-card-text
                v-alert.mt-4(v-if='error', type='error') {{ error }}

                v-checkbox(
                    v-for='(d, i) in datasourceOptions',
                    v-model='datasourceIds',
                    :key='d.value',
                    :disabled='notSubscribed && datasourceIds.length > 0 && !datasourceIds.includes(d.value)',
                    :label='d.text',
                    :value='d.value',
                    hide-details,
                    :class='i === 0 && "mt-1"'
                )
                    template(v-slot:label)
                        span {{ d.text }}
                        page-paid-or-free-chip(
                            v-if='notSubscribed && datasourceIds.length > 0 && !datasourceIds.includes(d.value)',
                            :is-free='false',
                            outlined
                        )
            v-divider(v-if='!loading')
            v-card-actions
                v-spacer
                v-btn(
                    color='primary',
                    @click='scanFile(uuid)',
                    :disabled='loading || !datasourceIds.length'
                ) {{ $t('generate') }}
    v-scroll-y-transition
        v-card.animated(
            transition='scroll-y-transition',
            shaped,
            width='95%',
            data-v-step='0',
            v-if='mounted'
        )
            |
            v-row.ma-0.pa-0
                v-col(cols='6')
                    v-card(flat)
                        v-card-title.layout.justify-center(primary-title)
                            .headline {{ this.$t('onboarding.title_1') + userName + '!' }}
                            v-chip.ml-4(label, outlined, color='orange', small)
                                span beta
                        v-card-text(align='start')
                            p {{ $t('description') }}
                            v-col.ma-0.pa-0(v-if='notSubscribed')
                                v-btn.ma-1(
                                    color='primary',
                                    :disabled='testLoading',
                                    small,
                                    depressed,
                                    @click='scanFileDemo'
                                )
                                    |
                                    v-icon(small, left) bug_report
                                    span {{ $t('try') }}
                                v-btn.ma-1(
                                    dark,
                                    small,
                                    outlined,
                                    color='accent',
                                    onclick='Calendly.initPopupWidget({url: \'https://calendly.com/ponychart/demo\'});return false;'
                                )
                                    v-icon(small, left) schedule
                                    span {{ $t('scheduleDemo') }}

                                br
                                br
                                span.my-4
                                    | {{ $t('download') }}&nbsp;
                                    a(:href='link', download) {{ filename + '.twbx' }}&nbsp;
                                    | {{ $t('toTry') }}

                            v-divider(v-if='show')
                            v-row.ma-0.pa-0
                                v-spacer
                                v-btn(icon, @click='show = !show')
                                    v-icon {{ show ? 'mdi-chevron-up' : 'help' }}
                            v-expand-transition
                                div(v-show='show')
                                    ul
                                        li {{ $t('desc_1') }}
                                        li {{ $t('desc_2') }}

                v-divider.mt-16.mb-8(vertical)
                    //- TODO: Add user-friendly message
                v-col.mt-16(align='center', justify='center')
                    v-row(align='center', justify='center')
                        upload#data-v-step-home-bis-upload.mb-8(
                            v-if='$store.state.user.licenced || notSubscribed',
                            :is-big='true',
                            :include-styling='true',
                            :message='$t("message")',
                            path-parameter='dashboards',
                            accepted-files='.twbx, .twb',
                            @uploaded='scanFile'
                        )
                        v-alert.mx-4(v-else, outlined, type='info') {{ $t('noLicence') }}
                    small.mt-0.mb-4.text--secondary(v-if='notSubscribed') {{ $t('privacy', { x: fileExpiration }) }}
</template>

<script>
import Upload from '@/components/utils/Upload'
import PagePaidOrFreeChip from '@/components/Pages/PagePaidOrFreeChip'
import GenerateService from '@/services/generateService'
import { mapGetters } from 'vuex'
import { FILE_EXPIRATION_DAYS } from '@/ponychart'

export default {
    name: 'getStarted',
    components: {
        Upload,
        PagePaidOrFreeChip,
    },
    data: () => ({
        uuid: '',
        datasourceOptions: [],
        datasourceIds: [],
        testLoading: false,
        loading: true,
        mounted: false,
        show: false,
        error: '',
        fileExpiration: FILE_EXPIRATION_DAYS,
    }),
    computed: {
        ...mapGetters({
            userName: 'userName',
            mail: 'email',
        }),
        notSubscribed() {
            return !this.$store.getters.subscription
        },
        title() {
            return this.error
                ? this.$t('errors.UNKNOWN')
                : this.loading
                ? this.$t('title')
                : this.$t('pleaseChoose')
        },
        filename() {
            return (
                {
                    en: 'Sample - Superstore',
                    fr: 'Exemple - Supermarché',
                    es: 'Muestra - Supertienda',
                }[this.lang] || 'Sample - Superstore'
            )
        },
        link() {
            return `https://ponychart.s3.eu-west-3.amazonaws.com/demo-dashboards/prod/${this.filename}.twbx`
        },
    },
    methods: {
        close() {
            this.uuid = ''
            this.error = ''
            this.datasourceOptions = []
            this.datasourceIds = []
        },
        async scanFileDemo() {
            try {
                this.testLoading = true
                await this.scanFile('demo', true)
            } finally {
                this.testLoading = false
            }
        },
        async scanFile(uuid, demo = false) {
            this.clearTour()
            this.loading = true
            this.uuid = uuid
            const { sourceId, datasources } = await GenerateService.scan(
                this.uuid,
                {
                    datasourceIds: this.datasourceIds,
                    ...(demo ? { demoLang: this.lang } : {}),
                }
            ).catch((e) => {
                const version = e?.response?.data?.version
                const versions = e?.response?.data?.versions?.join(', ')
                const max = e?.response?.data?.max
                const filesize = e?.response?.data?.filesize
                this.error = this.$t(
                    e?.response?.data?.message || 'errors.UNKNOWN',
                    {
                        version,
                        versions,
                        max,
                        filesize,
                    }
                )
                return {
                    datasources: [],
                    sourceId: 0,
                }
            })
            if (sourceId) {
                this.goTo(`/dashboards/${sourceId}`)
            }
            if (datasources) {
                this.datasourceOptions = datasources
            }
            this.loading = false
        },
    },
    watch: {
        userName: {
            handler: function (u) {
                if (u) setTimeout(() => (this.mounted = true), 500)
            },
            immediate: true,
        },
    },
}
</script>

<i18n>{
  "fr": {
    "try": "Essayer avec un fichier de test",
    "choose": "Choisir ce que vous souhaitez ajouter à votre fichier twb",
    "comingSoon": "Bientôt disponible",
    "message": "Déposez votre fichier .twb / .twbx ici",
    "internal_error_while_saving": "An unexpected internal error occured while saving the results of your file scan, we apologise for the inconvenience. You can contact us at contact@ponychart.com to let us know.",
    "description": "Commencez par déposer un fichier .twb pour créer un dashboard en quelques clics. La plateforme n'aura pas accès à vos données, simplement au schéma donné par le fichier .twb.",
    "desc_1": "Chaque dashboard est basé sur des calculs période à période comme le YTD, MoM, ... etc, etc. Vous aurez donc besoin d'au moins un champ date.",
    "desc_2": "Ce type de dashboard peut se révéler lent sur des systèmes SQL classiques, il est recommandé d'utiliser des Extraits Hyper pour de meilleures performances.",
    "title": "Veuillez patienter pendant que votre fichier est analysé ...",
    "pleaseChoose": "Veuillez choisir vos sources de données",
    "title1": "Champs calculés",
    "title2": "Dashboard complet",
    "desc1": "gratuit",
    "desc2": "1 token par page",
    "cost": "Coût",
    "select": "Quelle source de données utiliserez-vous pour créer votre tableau de bord?",
    "currently_in_construction": "La plateforme de génération de dashboards est encore en phase de construction, je vous contacterai quand je l'ouvrirai en bêta, mes excuses pour l'inconvéniences.",
    "already_generated": "Il semble que vous ayez uploadé un fichier issu de la plateforme Ponychart. Il n'est pas possible d'uploader un fichier twb plus d'une fois via la plateforme pour le moment. Vous devrez sélectionner un nouveau fichier twb/twbx pour commencer.",
    "not_found": "Aucune source de données n'a été trouvée dans votre tableau de bord",
    "unexpected_error": " Une erreur inattendue s'est produite, veuillez réessayer avec un autre tableau de bord. ",
    "file_too_big": "Le fichier que vous avez uploadé est trop gros ({filesize}). Veuillez vous assurer que votre fichier twb/twbx n'inclue pas d'extrait .hyper par exemple. [Max {max}]",
    "not_supported": "La version {version} de Tableau Desktop n'est pas prise en charge par notre service, veuillez télécharger l'une des versions suivantes: {versions}.",
    "noLicence": "Vous n'avez pas de licence valide pour commencer à utiliser Ponychart, veuillez contacter votre administrateur pour obtenir une licence de travail.",
    "privacy": "*Les fichiers téléchargés sont automatiquement supprimés au bout de {x} jours pour protéger votre vie privée.",
    "scheduleDemo": "Planifier une démo"
  },
  "en": {
    "scheduleDemo": "Book a demo",
    "privacy": "*Uploaded files are automatically deleted after {x} days to protect your privacy.",
    "try": "Try with a test file",
    "noLicence": "You do not have a valid licence to start using Ponychart, please contact your administrator to ask for a working licence.",
    "choose": "Choose what you wish to add to your twb file",
    "comingSoon": "Coming soon",
    "message": "Upload your .twb/.twbx file here",
    "internal_error_while_saving": "An unexpected internal error occured while saving the results of your file scan, we apologise for the inconvenience. You can contact us at contact@ponychart.com to let us know.",
    "description": "Start by uploading a .twb file to create a dashboard in a few clicks. The platform will not have access to your data, it will simply access the data model (column names) from the .twb file.",
    "desc_1": "Each dashboard is based on period-to-period calculations such as YTD, MoM, ... etc, etc. You will therefore need at least one date field.",
    "desc_2": "This type of dashboard can be slow on classic SQL systems, it is recommended to use Hyper Extracts for better performance.",
    "title": "Please wait while your file is scanned...",
    "pleaseChoose": "Please choose the datasources",
    "title1": "Calculated fields",
    "title2": "Complete dashboard",
    "desc1": "free",
    "desc2": "1 token per page",
    "cost": "Cost",
    "select": "Which datasource will you use to build your dashboard?",
    "currently_in_construction": "The dashboard generation platform is still under construction, I will contact you when I open it in beta, my apologies for the inconvenience.",
    "already_generated": "It seems you uploaded a file coming from the Ponychart Wizard. It is not possible to upload a twb file more than once through the platform at the moment. You will need to select a new twb / twbx file to start.",
    "not_found": "No datasource was found in your dashboard",
    "unexpected_error": "An unexpected error occured, please try again with another dashboard.",
    "file_too_big": "The file you uploaded is too big ({filesize}). Please ensure your twb/twbx file does not include .hyper data extract for instance. [Max {max}]",
    "not_supported": "Version {version} of Tableau Desktop is not supported by our service, please upload one of the following versions: {versions}."
  },
  "es": {
    "choose": "Elejir lo que quieres agregar a tu archivo twb",
    "comingSoon": "Pronto",
    "message": "Suba tu archivo .twb / .twbx aquí",
    "description": "Empieza subiendo un archivo .twb para crear un panel con unos pocos clics. La plataforma no tendrá acceso a tus datos, solo el archivo .twb.",
    "desc_1": "Cada panel se basa en cálculos de período a período, como YTD, MoM, ... etc., etc. Por lo tanto, necesitarás al menos un campo de fecha.",
    "desc_2": "Este tipo de dashboard puede ser lento en los sistemas SQL clásicos, se recomienda utilizar Hyper Extracts para un mejor rendimiento.",
    "title": "Espera mientras analizamos tu archivo ...",
    "pleaseChoose": "Por favor elige fuentes de datos",
    "title1": "Campos calculados",
    "title2": "Dashboard completo",
    "desc1": "gratuito",
    "desc2": "1 token por pagina",
    "cost": "Costo",
    "select": "¿Qué fuente de datos utilizarás para crear tu dashboard?",
    "currently_in_construction": "La plataforma de generación de tableros aún está en construcción, me comunicaré con ustedes cuando la abra en beta, mis disculpas por las molestias.",
    "already_generated": "Parece que subiste un archivo generado por la plataforma Ponychart. No es posible cargar un archivo twb más de una vez a través de la plataforma por ahora. Deberás seleccionar un nuevo archivo twb / twbx para comenzar.",
    "not_found": "No se encontró ninguna fuente de datos en tu dashboard",
    "file_too_big": "El archivo que cargaste es demasiado grande ({filesize}). Asegúrate de que tu archivo twb/twbx no incluya extractos de datos .hyper, por ejemplo. [Máximo {max}]",
    "not_supported": "Nuestro servicio no admite la versión {version} de Tableau Desktop, cargua una de las siguientes versiones: {versions}",
    "internal_error_while_saving": "Ha ocurrido un error interno inesperado al guardar los resultados del análisis de sus archivos, le pedimos disculpas por las molestias ocasionadas. Puede ponerse en contacto con nosotros en contact@ponychart.com para hacérnoslo saber.",
    "unexpected_error": "Ha ocurrido un error inesperado, vuelva a intentarlo con otro panel.",
    "noLicence": "No tiene una licencia válida para empezar a usar Ponychart, póngase en contacto con su administrador para solicitar una licencia de trabajo.",
    "try": "Pruébalo con un archivo de prueba",
    "privacy": "*Los archivos subidos se eliminan automáticamente a los {x} días para proteger tu privacidad.",
    "scheduleDemo": "Reservar una demostración"
  },
  "de": {
    "choose": "Wählen Sie aus, was Sie zu Ihrer TWB-Datei hinzufügen möchten.",
    "comingSoon": "Kommt bald",
    "message": "Laden Sie Ihre .twb/.twbx-Datei hier hoch",
    "internal_error_while_saving": "Beim Speichern der Ergebnisse Ihres Dateiscans ist ein unerwarteter interner Fehler aufgetreten. Wir entschuldigen uns für die Unannehmlichkeiten. Sie können uns unter contact@ponychart.com kontaktieren, um uns das mitzuteilen.",
    "description": "Laden Sie zunächst eine TWB-Datei hoch, um mit ein paar Klicks ein Dashboard zu erstellen. Die Plattform wird keinen Zugriff auf Ihre Daten haben, sondern einfach auf das Datenmodell, das in der TWB-Datei angegeben ist.",
    "desc_1": "Jedes Dashboard basiert auf periodischen Berechnungen wie YTD, MoM usw. usw. Sie benötigen daher mindestens ein Datumsfeld.",
    "desc_2": "Diese Art von Dashboard kann auf klassischen SQL-Systemen langsam sein. Für eine bessere Leistung wird empfohlen, Hyper Extracts zu verwenden.",
    "title": "Bitte warten Sie, während Ihre Datei gescannt wird...",
    "pleaseChoose": "Bitte wählen Sie, was Sie tun möchten.",
    "title1": "Berechnete Felder",
    "title2": "Vollständiges Armaturenbrett",
    "desc1": "kostenlos",
    "desc2": "1 Token pro Seite",
    "cost": "Kosten",
    "select": "Welche Datenquelle werden Sie verwenden, um Ihr Dashboard zu erstellen?",
    "currently_in_construction": "Die Plattform zur Generierung des Dashboards befindet sich noch im Aufbau, ich werde Sie kontaktieren, wenn ich sie in der Betaversion öffne. Ich entschuldige mich für die Unannehmlichkeiten.",
    "already_generated": "Es scheint, als hätten Sie eine Datei vom Ponychart-Assistenten hochgeladen. Derzeit ist es nicht möglich, eine TWB-Datei mehr als einmal über die Plattform hochzuladen. Sie müssen eine neue TWB-/TWBX-Datei auswählen, um zu starten.",
    "not_found": "In Ihrem Dashboard wurde keine Datenquelle gefunden",
    "unexpected_error": "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es erneut mit einem anderen Dashboard.",
    "file_too_big": "Die Datei, die Sie hochgeladen haben, ist zu groß ({Dateigröße}). Bitte stellen Sie sicher, dass Ihre twb/twbx-Datei zum Beispiel keinen Hyperdatenextrakt enthält. [Max {max}]",
    "not_supported": "Version {Version} von Tableau Desktop wird von unserem Service nicht unterstützt, bitte laden Sie eine der folgenden Versionen hoch: {versions}."
  }
}</i18n>

<style scoped>
.bordered {
    border: solid 2px #673ab7 !important;
}
.clicked {
    cursor: pointer;
}
.v-overlay {
    z-index: 20001 !important;
}
.v-dialog__content {
    z-index: 20002 !important;
}
</style>
