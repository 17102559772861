import { traitArrayToPayload, traitPayloadToArray, SourceMemory } from '@/ponychart'
import axios from 'axios'
const S3_KEYS = ["traits"]

const sourceInstance = SourceMemory.getInstance()
const VUE_APP_PUBLIC_PATH = process.env.VUE_APP_PUBLIC_PATH || "/"


function s3KeyToTraitArray(dashboardPreferenceResponse: { [k: string]: any }) {
    for (const key of S3_KEYS) {
        if (key !== "traits") continue
        // WARNING: here, it only works for traits array payload
        dashboardPreferenceResponse[key] = traitPayloadToArray(dashboardPreferenceResponse[key] || {}, sourceInstance.allAvailableSourceTraits)
    }
    return dashboardPreferenceResponse
}

async function listDemoDashboardPreferences(){
    const { dashboardPreferences } = await fetch(`${VUE_APP_PUBLIC_PATH}demo/dashboardPreferences.json`).then((res) => res.json())
    return dashboardPreferences.map(s3KeyToTraitArray)
}

export default {
    listDashboardPreferences: async function (opts: { asAdmin?: boolean, asDemo?: boolean } = {}) {
        if(opts.asDemo) return await listDemoDashboardPreferences()
        const config = opts?.asAdmin ? { params: { asAdmin: true } } : undefined
        const {
            data: { dashboardPreferences },
        } = await axios('/v1/dashboard-preferences', config)
        return dashboardPreferences.map(s3KeyToTraitArray)
    },
    getDashboardPreference: async function (id: string) {
        const {
            data: { dashboardPreferences },
        } = await axios(`/v1/dashboard-preferences/${id}`)
        return s3KeyToTraitArray(dashboardPreferences)
    },
    deleteDashboardPreference: async function (id: string) {
        const {
            data: { status },
        } = await axios.delete(`/v1/dashboard-preferences/${id}`)
        return status
    },
    createDashboardPreference: async function (payload: any) {
        const payloadCopy = { ...payload }
        for (const key of S3_KEYS) {
            if (payloadCopy[key]) payloadCopy[key] = traitArrayToPayload(payloadCopy[key])
        }
        const {
            data: { dashboardPreference },
        } = await axios.post('/v1/dashboard-preferences', payloadCopy)
        return dashboardPreference
    },
    updateDashboardPreference: async function (id: string, payload: any) {
        const payloadCopy = { ...payload }
        for (const key of S3_KEYS) {
            if (payloadCopy[key]) payloadCopy[key] = traitArrayToPayload(payloadCopy[key])
        }
        const {
            data: { dashboardPreference },
        } = await axios.put(`/v1/dashboard-preferences/${id}`, payloadCopy)
        return dashboardPreference
    },
    updateOrder: async function (ids: { id: string, order: number }[]) {
        return await axios.post(`/v1/dashboard-preferences-order`, { ids })
    }
}
