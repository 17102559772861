import { nanoid } from '@/ponychart/utils/nanoId';
import { Column } from '@/ponychart/column/types';
import { FilterOperator, Measure, MeasureAggregation, MeasureFilter } from './types';
import { DataType } from '../types';
import { TABLEAU_COLOR_VALUES, TABLEAU_LIGHT_VALUES, TABLEAU_BRIGHT_VALUES } from '../utils';

const COLORS: string[] = [...TABLEAU_BRIGHT_VALUES, ...TABLEAU_COLOR_VALUES, ...TABLEAU_LIGHT_VALUES]

let colorIndex = 0

export class MeasureHelpers {
    static forbiddenPrefixes: Set<string> = new Set([])
    static forbiddenAliases: Set<string> = new Set([])

    static aggregationsLevel: { [t in DataType]: MeasureAggregation[] } = {
        [DataType.TEXT]: [MeasureAggregation.COUNTD],
        [DataType.INTEGER]: Object.values(MeasureAggregation),
        [DataType.DOUBLE]: [
            MeasureAggregation.SUM,
            MeasureAggregation.MIN,
            MeasureAggregation.MAX,
            MeasureAggregation.AVG
        ],
        [DataType.BOOLEAN]: [],
        [DataType.DATE]: [
            MeasureAggregation.COUNTD
        ],
        [DataType.DATETIME]: [
            MeasureAggregation.COUNTD
        ],
    }

    static id() {
        return nanoid.id()
    }

    static new(opts: { formatId: string, twbIdx: number }): Measure & { aggregations: MeasureAggregation[], ids: string[] } {
        colorIndex++
        const color = COLORS[colorIndex % COLORS.length]
        return {
            id: MeasureHelpers.id(),
            id1: undefined,
            id2: undefined,
            agg: MeasureAggregation.SUM,
            columnId: '',
            formatId: opts.formatId,
            alias: '',
            type: DataType.INTEGER,
            aggregations: [],
            reversed: false,
            pristine: true,
            filters: [],
            filterOperation: 'AND',
            ids: [],
            color,
            twbIdx: opts.twbIdx
        }
    }

    static aggregationsFromType(type: DataType): MeasureAggregation[] {
        return MeasureHelpers.aggregationsLevel[type]
    }

    static fromAlias(alias: string, columnId: string, type: DataType, i: number, opts: { max: number, formatId?: string, twbIdx: number }): (Measure & { aggregations: string[], ids: string[] }) {
        return {
            ...MeasureHelpers.new({ formatId: "", twbIdx: opts.twbIdx }),
            alias,
            columnId,
            type,
            ids: [],
            aggregations: MeasureHelpers.aggregationsFromType(type),
            agg: MeasureHelpers.aggregationsLevel[type][0],
            formatId: opts.formatId || ""
        }
    }

    static fromColumns(columns: Column[], opts: { max: number, formatId?: string }): (Measure & { aggregations: string[], ids: string[] })[] {
        const output = columns.filter(
            (column) =>
                [DataType.INTEGER, DataType.DOUBLE].includes(column.type)
        ).map(({ alias, id, type, twbIdx }, i) => MeasureHelpers.fromAlias(alias, id, type, i, { ...opts, twbIdx }))
        if (output.length >= 3) return output

        return [
            ...output,
            ...columns
                .filter((c) => DataType.TEXT === c.type)
                .map(({ alias, id, type, twbIdx }, i) => MeasureHelpers.fromAlias(alias, id, type, output.length + i, { ...opts, twbIdx }))
        ].map((measure, i) => {
            colorIndex = i
            return { ...measure, color: COLORS[i % COLORS.length] }
        })
    }
}

export class MeasureFilterHelpers {

    static hasOther(operator: FilterOperator) {
        return ![FilterOperator.ISNULL, FilterOperator.NOT_ISNULL].includes(operator)
    }

    static new(): MeasureFilter {
        return {
            columnId: undefined,
            value: undefined,
            operator: FilterOperator.EQUALS
        }
    }

    static disabledFilter(measureFilter: MeasureFilter): boolean {
        if (!measureFilter.columnId) return true;
        if (!measureFilter.value && MeasureFilterHelpers.hasOther(measureFilter.operator)) return true;
        return false
    }

    static disabledMeasure(measure: Measure): boolean {
        return measure.filters.reduce((acc: boolean, filter: MeasureFilter) => (
            acc || MeasureFilterHelpers.disabledFilter(filter)
        ), false)
    }
}