
import Component, { mixins } from 'vue-class-component'

import { GlobalMixins } from '@/mixins'
import Parameter from '@/components/utils/Parameter.vue'
import { ComparisonPeriod } from 'ponychart'

enum Mode {
    LIST = 'list',
    RANGE = 'range',
    STRING_LIST = 'string_list',
}

type ParameterInterface =
    | {
          mode: Mode.LIST | Mode.STRING_LIST
          values: Value[]
          minimum?: number
          maximum?: number
          stepSize?: number
          allowMinimum?: boolean
          allowMaximum?: boolean
          allowStepSize?: boolean
      }
    | {
          mode: Mode.RANGE
          minimum: number
          maximum: number
          stepSize: number
          allowMinimum: boolean
          allowMaximum: boolean
          allowStepSize: boolean
          values?: Value[]
      }

interface Value {
    id: number | string
    alias: string
    editId?: boolean
    editAlias?: boolean
    selected?: boolean
}

function defaultListFactory($t: (k: string) => string) {
    return [...Array(6)].map((_, i) => ({
        id: i + 1,
        alias: $t(`numbers.${i}`),
        editId: false,
        editAlias: false,
    }))
}

function defaultStringListFactory($t: (k: string) => string) {
    return [...Array(6)].map((_, i) => ({
        id: i + 1,
        alias: $t(`numbers.${i}`),
        editId: false,
        editAlias: false,
    }))
}

function defaultValueFactory(mode: Mode, $t: (k: string) => string): Value[] {
    switch (mode) {
        case Mode.LIST:
            return defaultListFactory($t)
        case Mode.RANGE:
            return []
        case Mode.STRING_LIST:
            return defaultStringListFactory($t)
        default:
            return []
    }
}

@Component({
    components: {
        Parameter,
    },
})
export default class TestComponent extends mixins(GlobalMixins) {
    parameter: ParameterInterface = {
        mode: Mode.STRING_LIST,
        values: [
            {
                id: ComparisonPeriod.YEAR,
                alias: 'Year',
                selected: true,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.QUARTER,
                alias: 'Quarter',
                selected: true,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.MONTH,
                alias: 'Month',
                selected: true,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.JANUARY,
                alias: 'January',
                selected: false,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.FEBRUARY,
                alias: 'February',
                selected: false,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.MARCH,
                alias: 'March',
                selected: false,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.APRIL,
                alias: 'April',
                selected: false,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.MAY,
                alias: 'May',
                selected: false,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.JUNE,
                alias: 'June',
                selected: false,
                editAlias: false,
            },
            {
                id: ComparisonPeriod.JULY,
                alias: 'July',
                selected: false,
                editAlias: false,
            },
        ],
        // minimum: 1,
        // maximum: 100,
        // stepSize: 1,
        // allowMinimum: true,
        // allowMaximum: false,
        // allowStepSize: false,
    }
    show = true
    // mounted(){
    //   this.parameter.values = defaultValueFactory(Mode.LIST, (k: string) => String(this.$t(k)))
    // }
}
