
import draggable from 'vuedraggable'
import { GlobalMixins, Traits, PageHeaderEventListeners } from '@/mixins'
import HeaderPreview from '@/components/Header/HeaderPreview.vue'
import HeaderEditor from '@/components/Header/HeaderEditor.vue'
import Filters from '@/components/Source/Filters.vue'

import Date from '@/components/Source/Date.vue'
import Tour from '@/components/utils/Tour.vue'
import PageTrait from '@/components/Pages/PageTrait.vue'
import {
    SourceMemory,
    IFilter,
    IDate,
    Source,
    TABLEAU_BRIGHT_VALUES,
    RegisterTour,
    SourceMode,
    buildTimeSelectorPreview,
    GlobalState,
} from '@/ponychart'
import { RawAccessRights, Trait, TraitId } from 'ponychart'

import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { TraitSearch } from '@/ponychart/state'

const memoryInstance = SourceMemory.getInstance()
const registerTour = RegisterTour.getInstance()

@Component({
    components: {
        draggable,
        PageTrait,
        Date,
        HeaderPreview,
        HeaderEditor,
        Tour,
        Filters,
    },
})
export default class HeaderDesignComponent extends mixins(
    GlobalMixins,
    Traits,
    PageHeaderEventListeners
) {
    @Prop({ type: Object, required: true })
    readonly source!: Source
    @Prop({ type: Object, required: true })
    readonly rawAccessRights!: RawAccessRights
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: String, required: true })
    readonly sourceMode!: SourceMode

    step = 1
    selectedDates: IDate[] = []
    pristine = true
    filters: IFilter[] = []
    dragOptions = {
        group: 'filters',
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    }
    hasFilterErrors = false
    selectedTwbDatasources: string[] = []
    comparisonPeriodHtml = ''

    event = false

    getTrait(traitId: TraitId) {
        // If timeout is set, then return undefined
        // to fetch the trait again (from lack of reactivity)
        return this.sourceTraits.find((trait) => trait.id === traitId)
    }

    buildTimeSelectorPreview() {
        const globalState = new GlobalState({
            ...this.globalOptions,
            reduceSize: 1,
        })
        const traitSearch = TraitSearch.createInstance(
            this.sourceTraits,
            [],
            globalState
        )
        const header = buildTimeSelectorPreview(globalState, traitSearch)
        const html = header.compileAllHtml()
        const css = header.compileAllCss()
        this.comparisonPeriodHtml = `${html}<style>${[...Array.from(css)].join(
            ' '
        )}</style>`
    }

    get comparisonPeriodTrait() {
        return this.getTrait(TraitId.COMPARISON_PERIODS)
    }

    get comparisonPeriodCountTrait() {
        return this.getTrait(TraitId.COMPARISON_PERIOD_COUNT)
    }

    get timeSelectorTypeTrait() {
        return this.getTrait(TraitId.TIME_SELECTOR_TYPE)
    }

    setTraitValue(trait: Trait, value: number | string) {
        trait.value = value
        trait.pristine = false
        this.source.traits = this.refreshSourceTraits(this.sourceTraits, {
            emit: false,
        })
        this.buildTimeSelectorPreview()
        this.refreshEvents()
    }

    get twbDatasources() {
        return (this.source?.twbDatasources || []).map((t, i) => ({
            ...t,
            color: TABLEAU_BRIGHT_VALUES[i % TABLEAU_BRIGHT_VALUES.length],
        }))
    }

    get globalOptions() {
        return {
            logoUrl: this.logo.url,
            colors: this.colors,
            reduceSize: 2,
        }
    }

    get hasError() {
        return (
            this.selectedDates.length != this.twbDatasources.length ||
            this.selectedDates.filter((d) => !!d).length === 0 ||
            this.hasFilterErrors
        )
        // return this.selectedDates.some((d) => !d) || this.step !== 3
    }

    get sourceTraits() {
        return this.source?.traits || []
    }

    get routerStep() {
        return this.$route.params.route_step
    }
    get filterItems() {
        const { columnsForString } = SourceMemory.getInstance()
        return columnsForString
    }
    get colors(): any {
        return this.source?.colors || {}
    }
    get logo() {
        return this.source?.logo || { id: '' }
    }

    async set(
        o: { [k: string]: any },
        opts: { context: any } = {
            context: this,
        }
    ) {
        for (const key in o) {
            if (key === 'dashboardPreference') {
                this.set(o[key])
                continue
            } else if (key === 'source') {
                const source = { ...this.source, ...o[key] }
                this.$emit('set', { source })
                await this.$nextTick()
                this.event = !this.event
                continue
            }
            if (typeof o[key] === 'object' && !Array.isArray(o[key])) {
                if (opts.context[key])
                    this.set(o[key], {
                        context: opts.context[key],
                    })
            } else {
                opts.context[key] = o[key]
            }
        }
    }

    async mount() {
        this.step = 1
        this.filters = memoryInstance.filters
        this.selectedDates = memoryInstance.dates
        this.selectedTwbDatasources = this.twbDatasources.map((t) => t.id)
        this.buildTimeSelectorPreview()
    }

    refreshEvents() {
        this.$nextTick().then(() => {
            this.attachPageButtonEvent()
            this.attachChangeSelectEvent()
        })
    }

    @Watch('routerStep', { immediate: true })
    onRouterStepChange(routerStep: string) {
        console.log(routerStep)
        if (routerStep === 'header-design') this.mount()
    }
    @Watch('hasError', { immediate: true })
    onErrorChanged(hasHeaderDesignErrors: string) {
        this.$emit('set', { hasHeaderDesignErrors })
    }
    @Watch('step', { immediate: true })
    onStepChange(headerStep: number) {
        if (headerStep == 3 && this.pristine) {
            // this.header.height = this.filters.length > 0 ? 400 : 300
            this.pristine = false
        }
        if (headerStep == 2) {
            this.buildTimeSelectorPreview()
        }
        setTimeout(
            () => {
                registerTour
                    .getTourContext()
                    .startTour({ step: `header-design-${headerStep}` })
            },
            800,
            this
        )

        this.$emit('set', { headerStep: Number(headerStep) })
        setTimeout(
            () => {
                this.event = !this.event
            },
            100,
            this
        )
        if (headerStep == 2) this.$emit('save')
        this.refreshEvents()
    }
}
