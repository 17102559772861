export const MAXIMUM = {
    measures: 30,
    demoMeasures: 10,
    filters: 8,
    dimensions: 30,
    demoDimensions: 10,
    dates: 20,
    demoDates: 5,
    pages: 10,
    demoPages: 2,
    pageBlocks: 5,
    demoPageBlocks: 2,
    preferences: 10,
    downloadsToday: 3,
    demoDownloadsToday: 1
}
export const MINIMUM = {
    measures: 1,
    filters: 0,
    dimensions: 1,
    dates: 1,
    pages: 1,
    preferences: 1
}
export const DEMO_LOCAL_STORAGE_KEY = "user_downloads_total"