
import Component, { mixins } from 'vue-class-component'
import { GlobalMixins } from '@/mixins'
import { SidebarDesign, Trait, TraitId, TraitOptionItem } from 'ponychart'
import { Prop, Watch } from 'vue-property-decorator'
import Page from '@/components/Pages/Page.vue'
import { getEmptyPage, GlobalOptions, SidebarPayload } from '@/ponychart'

@Component({
    components: { Page },
})
export default class SidebarDesignPreview extends mixins(GlobalMixins) {
    @Prop({ type: String, required: true })
    readonly value!: SidebarDesign
    @Prop({ type: Array, required: true })
    readonly sidebarDesigns!: TraitOptionItem[]
    @Prop({ type: Array, required: true })
    readonly sourceTraits!: Trait[]
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: Object, required: true })
    readonly globalOptions!: GlobalOptions
    @Prop({ type: Object, required: true })
    readonly sidebar!: SidebarPayload
    @Prop({ type: Boolean, required: true })
    readonly show!: boolean

    page = getEmptyPage()
    items: SidebarDesign[] = []

    get component() {
        return this.sidebar.sidebar === 'top' ? 'v-col' : 'v-row'
    }
    get width() {
        return this.sidebar.sidebar === 'top' ? 500 : 75
    }
    get height() {
        return this.sidebar.sidebar === 'top' ? 50 : 250
    }
    get classes() {
        return this.sidebar.sidebar === 'top' ? 'my-4' : 'mx-4'
    }

    listSourceTraits(sidebarDesign: SidebarDesign): Trait[] {
        return this.sourceTraits.map((trait) =>
            trait.id === TraitId.SIDEBAR_DESIGN
                ? { ...trait, value: sidebarDesign }
                : trait
        )
    }

    @Watch('show', { immediate: true })
    async onShowChange(show: boolean) {
        if (!show) return
        this.items = []
        await this.$nextTick()
        this.items = [
            ...this.sidebarDesigns.map((o) => o.id),
        ] as SidebarDesign[]
    }
}
