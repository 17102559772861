<template lang="pug">
v-col.mention.my-4(@click="$emit('click')" style="cursor: pointer")
    v-row.ma-0
        v-col
            label {{ $t("code") }}
        v-col
            label {{ $t("preview") }}
    v-row.ma-1
        v-col(v-html="textElement.calculation" :style="{background, color, zoom: '0.6', minWidth: '20px', minHeight: '20px'}")
        v-col(v-html="textElement.preview" :style="{background, color}")
</template>

<script>
export default {
    props: {
        textElement: {
            type: Object,
            required: true,
        },
        background: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
}
</script>

<i18n>{
  "en": {
    "code": "Tableau calculation"
  },
  "fr": {
    "code": "Calcul Tableau"
  },
  "es": {
    "code": "Cálculo de Tableau"
  }
}</i18n>

<style scoped>
h1 {
    font-size: 1rem !important;
}
h2 {
    font-size: 0.75rem !important;
}
h3 {
    font-size: 0.55rem !important;
}
h4 {
    font-size: 0.4rem !important;
}
h5 {
    font-size: 0.3rem !important;
}
h6 {
    font-size: 0.3rem !important;
}
</style>
