<template lang="pug">
v-container(v-if='l && l > 0', fluid='', grid-list-md='')
  h2 {{ $t("meeting.my") }}
  v-row(wrap='')
    v-flex(
      v-for='(m, i) in meetings',
      :key='i',
      :xs4='l % 3 == 0',
      :xs6='l % 2 == 0'
    )
      v-card(raised='', max-width='400')
        v-card-title(primary-title='')
          div
            h3.headline.mb-0 {{ m.event_type.name }}
            br
            ul
              li.subtitle-2(
                :key='q.question',
                v-for='q in m.questions_and_answers'
              )
                span {{ q.question }} :
                b {{ q.answer }}
              li.subtitle-2
                span {{ $t("meeting.schedule") }}&nbsp;:
                b {{ m.event.start_time_pretty }}
              li.subtitle-2
                span {{ $t("meeting.duration") }}&nbsp;:
                b {{ m.event_type.duration }}
                span {{ $t("meeting.min") }}
        v-card-actions
          ul
            li
              span {{ $t("meeting.join") }}&nbsp;
              a(
                target='_blank',
                rel='noopener noreferrer',
                :href='m.event.location'
              ) {{ $t("here") }}
              span &nbsp;{{ $t("meeting.join_2") }}
          v-spacer
          v-btn(icon='', @click='show = !show')
            v-icon {{ show ? "keyboard_arrow_down" : "keyboard_arrow_up" }}
        v-slide-y-transition
          v-card-text(v-show='show')
            span {{ $t("meeting.can") }}
            ul
              li
                a(
                  target='_blank',
                  rel='noopener noreferrer',
                  :href='"https://calendly.com/reschedulings/" + m.invitee.uuid'
                ) {{ $t("meeting.reprogram") }}
              li
                a(
                  target='_blank',
                  rel='noopener noreferrer',
                  :href='"https://calendly.com/cancellations/" + m.invitee.uuid'
                ) {{ $t("meeting.cancel") }}
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'meetingsComponent',
  data() {
    return {
      ONE_HOUR: 60 * 60 * 1000,
    }
  },
  computed: {
    ...mapGetters(['meetings']),
    l() {
      return this.meetings.length
    },
  },
}
</script>