import { nanoid } from '@/ponychart'
import { Vue, Component } from 'vue-property-decorator'

@Component
export class Socket extends Vue {
    sessionId: string | null = null
    websocket: WebSocket | null = null
    dialogDisconnectMode: "login" | "logout" | "idle" = "idle"
    hasLoggedIn = false
    ip = ""
    dialogDisconnect = false

    get session() {
        return this.$store.getters["cognito/session"]
    }

    get websocketUrl(): string {
        const url = process.env.VUE_APP_SOCKET_BASE_URL?.trim() || `wss://${!location.port ? '' : 'dev-'}socket.fr.ponychart.com`
        return `${url}/v1/?Authorizer=${this.session.idToken.jwtToken}`
    }

    getSessionId(force = false) {
        const KEY = 'websocketSessionid'
        let localSessionId = localStorage.getItem(KEY)
        if (!localSessionId || force) {
            localSessionId = nanoid.id()
            localStorage.setItem(KEY, localSessionId)
        }
        return localSessionId
    }
    onWebsocketMessage(payload: MessageEvent<any>) {
        const { sessionId, type, ip } = JSON.parse(payload.data)
        if (type !== "logged_in") return
        this.ip = ip
        if (sessionId !== this.getSessionId() && !this.hasLoggedIn) {
            this.dialogDisconnect = true
            this.dialogDisconnectMode = "login"
        } else if (sessionId !== this.getSessionId() && this.hasLoggedIn) {
            this.dialogDisconnect = true
            this.dialogDisconnectMode = "logout"
        } else {
            this.hasLoggedIn = true
            this.dialogDisconnect = false
            this.dialogDisconnectMode = "idle"
        }
    }
    loggedInHookWebSocket() {
        this.sessionId = this.getSessionId()
        this.websocket = new WebSocket(this.websocketUrl)
        this.websocket.onmessage = this.onWebsocketMessage
    }
    closeWebSocket() {
        if (!this.websocket) return
        this.websocket.close()
    }
    async loginWebSocket(force = false) {
        if (!this.websocket) return
        this.send(
            JSON.stringify({
                type: 'login',
                sessionId: this.sessionId,
                force,
            })
        )
    }
    send(message: string, callback?: () => void) {
        if (!this.websocket) return
        this.waitForConnection(() => {
            if (!this.websocket) return
            this.websocket.send(message)
            if (typeof callback !== 'undefined') callback()
        }, 1000)
    }
    waitForConnection(callback: () => void, interval: number) {
        if (!this.websocket) return
        if (this.websocket.readyState === 1) {
            callback()
        } else {
            setTimeout(
                () => {
                    this.waitForConnection(callback, interval)
                },
                interval,
                this
            )
        }
    }
}
