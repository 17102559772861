import { Page, PageBlock } from '@/ponychart/page/types'
import axios from 'axios'
import { cloneDeep } from "lodash"

function _pagePath(sourceId: number, pageId?: string | number, noResult?: boolean, demoUuid?: string) {
    const pagePath = pageId ? `/v1/sources/${sourceId}/pages/${pageId}` : `/v1/sources/${sourceId}/pages`
    const output = noResult ? pagePath + "?noresult=true" : pagePath
    return demoUuid ? output.replace(`/v1/sources/${sourceId}/`, `/v1/demo/${demoUuid}/`) : output
}

function _splitPage(page: any) {
    const trimmedPage = cloneDeep(page)
    const pageBlocks = trimmedPage.pageBlocks
    delete trimmedPage.pageBlocks
    return {
        trimmedPage, pageBlocks
    }
}
type PresignedParams = {
    resolution: "low" | "high" | "edit",
    pageType: "tree"
} | { pageType: "components" }

export default {
    createPage: async function (sourceId: number, page: any, opts: { noResult?: boolean } = {}): Promise<Page> {
        const { trimmedPage, pageBlocks } = _splitPage(page)
        const { data: { page: newlySavedPage } } = await axios.post(_pagePath(sourceId, undefined, opts.noResult), trimmedPage)
        const putUrl = await this.putPresigned(sourceId, newlySavedPage.id, { pageType: "components" })
        await fetch(putUrl, {
            method: 'PUT',
            body: JSON.stringify({ pageBlocks }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        newlySavedPage.pageBlocks = pageBlocks
        return newlySavedPage
    },
    updateOrder: async function (sourceId: number, ids: { pageId: number, order: number }[]): Promise<void> {
        await axios.post(_pagePath(sourceId) + "-order", { ids })
    },
    updatePage: async function (sourceId: number, pageId: string | number, page: any): Promise<Page> {
        const { trimmedPage, pageBlocks } = _splitPage(page)
        const { data: { page: newlySavedPage } } = await axios.put(_pagePath(sourceId, pageId), trimmedPage)
        // if (newlySavedPage.isTemplate) return newlySavedPage
        const putUrl = await this.putPresigned(sourceId, newlySavedPage.id, { pageType: "components" })
        await fetch(putUrl, {
            method: 'PUT',
            body: JSON.stringify({ pageBlocks }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        newlySavedPage.pageBlocks = pageBlocks
        // TODO: Add page to store
        return { ...newlySavedPage, pristine: true, templatePristine: false }
    },
    listPages: async function (sourceId: number): Promise<Page[]> {
        const { data: { pages } } = await axios(_pagePath(sourceId))
        const output: Page[] = await Promise.all(
            pages.sort((a: any, b: any) => a.order - b.order)
                .map(async (p: any) => (this.enrichPage(sourceId, p, { pageType: "components" })))
        )
        return output
            .filter((p) => !!p)
            .map((p: Page) => ({ ...p, pristine: true, templatePristine: false }))
    },
    dropAllPages: async function (sourceId: number): Promise<void> {
        const { data: { pages } } = await axios(_pagePath(sourceId))
        await Promise.all(pages.map((page: any) => this.deletePage(sourceId, page.id)))
    },
    getPageBlocks(sourceId: number, pageId: string | number): Promise<PageBlock[]> {
        return this.getPresigned(sourceId, pageId, { pageType: "components" })
            .then(url => fetch(url))
            .then(response => response.json())
            .then(payload => payload.pageBlocks.filter((p: any) => !!p))
    },
    enrichPage: async function (sourceId: number, page: any, opts: PresignedParams): Promise<Page> {
        return {
            ...page,
            pageBlocks: await this.getPageBlocks(sourceId, page.id),
        }
    },
    getTemplate: async function (
        sourceId: number,
        id: string,
        opts: PresignedParams
    ): Promise<any> {
        const url = await this.getPresigned(
            sourceId,
            id,
            opts,
        )
        const response = await fetch(url)
        return response.json()
    },
    async saveTemplate(
        sourceId: number,
        id: string | number,
        payload: any,
        opts: PresignedParams,
        demoUuid?: string
    ): Promise<void> {
        const url = await this.putPresigned(
            sourceId,
            id,
            opts,
            demoUuid
        )
        await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    getPage: async function (sourceId: number, pageId: string | number): Promise<Page> {
        const page = axios(_pagePath(sourceId, pageId))
            .then(res => this.enrichPage(sourceId, res.data.page, { pageType: "components" }))
        return { ...page, pristine: true, templatePristine: false }
    },
    deletePage: async function (sourceId: number, pageId: string | number): Promise<{ id: number | string }> {
        await axios.delete(_pagePath(sourceId, pageId))
        return { id: pageId }
    },
    getPresigned: async function (sourceId: number, pageId: string | number, opts: PresignedParams): Promise<string> {
        return await this._presigned(sourceId, pageId, "get", opts)
    },
    putPresigned: async function (sourceId: number, pageId: string | number, opts: PresignedParams, demoUuid?: string): Promise<string> {
        return await this._presigned(sourceId, pageId, "put", opts, demoUuid)
    },
    _presigned: async function (
        sourceId: number,
        pageId: string | number,
        method: "get" | "put",
        params: PresignedParams,
        demoUuid?: string
    ): Promise<string> {
        const {
            data,
        } = await axios({
            url: `${_pagePath(sourceId, pageId, false, demoUuid)}/url`,
            method,
            params
        })
        return data.url
    },
}
