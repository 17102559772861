import { listTraits, SourceMemory, TABLEAU_BRIGHT_VALUES } from '@/ponychart'
import axios from 'axios'
import { serverTraitFactory, Trait } from 'ponychart'
import { pick } from 'lodash'
import { TraitSearch } from '@/ponychart/state/traits'
import { dimensionMask, measureMask, pageMask } from './utils'

const S3_KEYS = ['traits']
const sourceInstance = SourceMemory.getInstance()
const VUE_APP_PUBLIC_PATH = process.env.VUE_APP_PUBLIC_PATH || '/'
const FORBIDDEN_PROPERTIES: string[] = [
    'columns',
    's3Key',
    'userId',
    'id',
    'company',
    'version',
    'twbDatasources',
    'ttl',
    'filesize',
    'updatedAt',
    'createdAt',
    'tempTtl',
]

function filterUnsupported(trait: Trait): boolean {
    return serverTraitFactory(trait.id, { noType: false }).length > 0
}

function addType(trait: Trait): Trait {
    const traits = serverTraitFactory(trait.id, { noType: false })
    if (traits.length === 0)
        throw new Error(
            `TraitId ${trait.id} is not implemented in ponychart module`
        )
    const traitType = traits[0].type
    if (!traitType) throw new Error('Weird error')
    return { ...trait, type: traitType }
}

const MASK_MAP = {
    measures: measureMask,
    dimensions: dimensionMask,
    pages: pageMask,
}

export default {
    updateTtl: async function (sourceId: number, ttl: number) {
        const {
            data: { source },
        } = await axios({
            url: `/v1/sources/${sourceId}/ttl`,
            method: 'PUT',
            data: { ttl },
        })
        return source
    },
    deleteTtl: async function (sourceId: number) {
        const {
            data: { source },
        } = await axios({
            url: `/v1/sources/${sourceId}/ttl`,
            method: 'DELETE',
        })
        return source
    },
    deleteSource: async function (sourceId: number) {
        const {
            data: { sourceId: deletedSourceId },
        } = await axios({
            url: `/v1/sources/${sourceId}`,
            method: 'DELETE',
        })
        return deletedSourceId
    },
    _saveS3Key: async function (sourceId: number, key: string, payload: any) {
        return axios({
            url: `/v1/sources/${sourceId}/${key}`,
            method: 'PUT',
            data: payload,
        })
            .then((res) => res.data?.url)
            .then((url) =>
                fetch(url, {
                    method: 'PUT',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            )
    },
    saveSource: async function (
        sourceId: number,
        source: any,
        opts: { noResult?: boolean } = {}
    ) {
        // if (source?.pages?.length > 0) {
        //     const promises = source.pages.map((page: Page) => (PageService.updatePage(sourceId, page.id, page)))
        //     Promise.all(promises)
        // }
        const payload = pick(
            source,
            Object.keys(source).filter(
                (k) => !FORBIDDEN_PROPERTIES.includes(k) && !S3_KEYS.includes(k)
            )
        )
        for (const key in MASK_MAP) {
            if (source[key]) {
                payload[key] = (source[key] || []).map(MASK_MAP[key])
            }
        }
        const promises = [
            axios({
                url: `/v1/sources/${sourceId}`,
                method: 'PUT',
                data: payload,
                params: opts.noResult ? { noresult: 'true' } : undefined,
            }).then((res) => res.data?.source),
        ]
        for (const key of S3_KEYS) {
            if (!source[key]) continue
            promises.push(this._saveS3Key(sourceId, key, source[key]))
        }
        await Promise.all(promises)
        return promises[0]
    },
    // noS3Key = false is deprecated
    getDemoSource: async function (lang: string) {
        const [sourceResult, traitsResult] = await Promise.all(
            [
                fetch(`${VUE_APP_PUBLIC_PATH}demo/${lang}.json`),
                fetch(`${VUE_APP_PUBLIC_PATH}demo/traits.json`),
            ].map((p) => p.then((res) => res.json()))
        )
        const result = {
            ...sourceResult,
            source: {
                ...sourceResult.source,
                traits: traitsResult.traits
                    .filter(filterUnsupported)
                    .map(addType),
            },
        }
        sourceInstance.setSource(result.source)
        return result
    },
    getSource: async function (
        sourceId: number,
        opts: { noS3Key?: boolean } = { noS3Key: true }
    ) {
        const firstPromises = [
            axios(`/v1/sources/${sourceId}`, {
                params: { noS3Key: opts.noS3Key },
            }).then((res) => res.data),
        ]
        // If mode is without s3 key, then fetch traits[] directly from API as promise
        if (opts.noS3Key) {
            for (const key of S3_KEYS) {
                firstPromises.push(
                    axios(`/v1/sources/${sourceId}/${key}`).then(
                        (res) => res.data
                    )
                )
            }
        }
        const firstPromiseResult = await Promise.all(firstPromises)
        const data = firstPromiseResult.reduce((acc, promiseResult) => {
            for (const key in promiseResult) {
                if (S3_KEYS.includes(key)) acc.source[key] = promiseResult[key]
                else acc = { ...acc, ...promiseResult }
            }
            return acc
        }, {})

        // If traits as array, we enrich them with types
        if (
            data.source &&
            data.source.traits &&
            Array.isArray(data.source.traits)
        )
            data.source.traits = data.source.traits
                .filter(filterUnsupported)
                .map(addType)

        data.source.traits = TraitSearch.createInstance(
            data.source.traits
        ).allTraits.map((t) => t.toDict())

        const source = data?.source || {}
        const pages = (source?.pages || []).filter((page: any) => page.url)
        const promises = []

        if (!opts.noS3Key) {
            for (const key of S3_KEYS) {
                promises.push(
                    fetch(source[key]).then((response) => response.json())
                )
            }
        }
        for (const page of pages) {
            promises.push(
                fetch(page.url)
                    .then((response) => response.json())
                    .then((payload) =>
                        payload.pageBlocks.filter((p: any) => !!p)
                    )
            )
        }
        const promiseResult = await Promise.all(promises)
        if (!opts.noS3Key) {
            for (const key of S3_KEYS) {
                source[key] = promiseResult.shift()
            }
        }
        source.twbDatasources = (source?.twbDatasources || []).map(
            (twbDatasource: any, i: number) => ({
                ...twbDatasource,
                color: TABLEAU_BRIGHT_VALUES[i % TABLEAU_BRIGHT_VALUES.length],
            })
        )
        // Important to set source early on (Required by new TraitSearch())
        sourceInstance.setSource(data.source)

        for (const page of pages) {
            const pageBlocks = promiseResult.shift()
            for (const pageBlock of pageBlocks) {
                const traitSearch = TraitSearch.createInstance(
                    source?.traits,
                    pageBlock.traits,
                    {
                        twbIdx: pageBlock.twbIdx,
                        multipleCharts: page.multipleCharts,
                    }
                )
                pageBlock.traits = listTraits(
                    pageBlock.structure,
                    traitSearch,
                    { applyCrossDeviceValues: true }
                )
            }
            page.pageBlocks = pageBlocks
        }
        return data
    },
    listSources: async function (count = 10, lastKey?: number) {
        const params = { count, lastKey }
        const { data } = await axios({
            url: `/v1/sources`,
            params,
        })
        const sources = data.sources.filter(
            (source: any) => Object.keys(source).length > 4
        )
        return { ...data, sources }
    },
}
