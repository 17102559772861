import { Bool } from '@/ponychart/trait/types'
import {
    TraitId,
    HeaderDesign,
    ComparisonPeriod,
    Orientation,
    TimeSelectorType,
} from 'ponychart'

export const COMPARISON_PERIOD_MONTHS = [
    ComparisonPeriod.JANUARY,
    ComparisonPeriod.FEBRUARY,
    ComparisonPeriod.MARCH,
    ComparisonPeriod.APRIL,
    ComparisonPeriod.MAY,
    ComparisonPeriod.JUNE,
    ComparisonPeriod.JULY,
    ComparisonPeriod.AUGUST,
    ComparisonPeriod.SEPTEMBER,
    ComparisonPeriod.OCTOBER,
    ComparisonPeriod.NOVEMBER,
    ComparisonPeriod.DECEMBER,
]

const BIG_COMPARISON_PERIODS = [
    ComparisonPeriod.YEAR,
    ComparisonPeriod.QUARTER,
    ComparisonPeriod.MONTH,
]

const SMALL_COMPARISON_PERIODS = [ComparisonPeriod.DAY, ComparisonPeriod.WEEK]

const TIME_SELECTOR_TO_COMPARISON = {
    [TimeSelectorType.DAILY]: [
        ...BIG_COMPARISON_PERIODS,
        ...SMALL_COMPARISON_PERIODS,
    ],
    [TimeSelectorType.DAILY_FROM_TO]: [
        ...BIG_COMPARISON_PERIODS,
        ...SMALL_COMPARISON_PERIODS,
    ],
    [TimeSelectorType.MONTHLY]: [
        ...BIG_COMPARISON_PERIODS,
        ...COMPARISON_PERIOD_MONTHS,
    ],
    [TimeSelectorType.MONTHLY_SPLIT]: [
        ...BIG_COMPARISON_PERIODS,
        ...COMPARISON_PERIOD_MONTHS,
    ],
    [TimeSelectorType.MONTHLY_FROM_TO]: [...BIG_COMPARISON_PERIODS],
    [TimeSelectorType.MONTHLY_FROM_TO_SPLIT]: [...BIG_COMPARISON_PERIODS],
}

const MONTH_COMPATIBLES = [
    TimeSelectorType.MONTHLY_FROM_TO_SPLIT,
    TimeSelectorType.MONTHLY_FROM_TO,
    TimeSelectorType.MONTHLY_SPLIT,
    TimeSelectorType.MONTHLY,
]

export function getTimeSelectorTypes(dateAggregationLevel: 'month' | 'day') {
    if (dateAggregationLevel === 'day')
        return Object.keys(TIME_SELECTOR_TO_COMPARISON)
    else return MONTH_COMPATIBLES
}

export function comparisonPeriodsFromType(
    timeSelectorType: TimeSelectorType
): ComparisonPeriod[] {
    return TIME_SELECTOR_TO_COMPARISON[timeSelectorType]
}

// Used to seed random setups
export const HEADER_OPTIONS: Partial<{ [t in TraitId]: any }>[] = [
    {
        [TraitId.HEADER_DESIGN]: HeaderDesign.SIMPLE,
        [TraitId.TIME_SELECTOR_ORIENTATION]: Orientation.HORIZONTAL,
        [TraitId.HEADER_HEIGHT]: 200,
    },
    {
        [TraitId.HEADER_DESIGN]: HeaderDesign.CENTERED,
        [TraitId.TIME_SELECTOR_ORIENTATION]: Orientation.HORIZONTAL,
        [TraitId.HEADER_HEIGHT]: 300,
    },
    {
        [TraitId.HEADER_DESIGN]: HeaderDesign.SIMPLE_VERTICAL,
        [TraitId.TIME_SELECTOR_ORIENTATION]: Orientation.VERTICAL,
        [TraitId.HEADER_HEIGHT]: 300,
    },
    {
        [TraitId.HEADER_DESIGN]: HeaderDesign.DENSE,
        [TraitId.TIME_SELECTOR_ORIENTATION]: Orientation.VERTICAL,
        [TraitId.HEADER_HEIGHT]: 300,
    },
].map((o) => ({
    ...o,
    [TraitId.WITH_LOGO]: Bool.TRUE,
    [TraitId.WITH_COLORS]: Bool.TRUE,
    [TraitId.WITH_TIME_PERIOD]: Bool.TRUE,
    [TraitId.SOURCE_DATE_AGGREGATION_LEVEL]: 'day',
    [TraitId.TIME_SELECTOR_TYPE]: TimeSelectorType.DAILY_FROM_TO,
    [TraitId.COMPARISON_PERIODS]: comparisonPeriodsFromType(
        TimeSelectorType.DAILY_FROM_TO
    ),
}))
