<template lang="pug">
div(style='height: 100%')
    v-row(v-if='isAdmin', style='height: 100%')
        v-col(cols='2')
            v-list(dense, nav)
                //- v-subheader PROFILE
                v-list-item-group(
                    v-model='stepIdx',
                    color='primary',
                    mandatory
                )
                    v-list-item(v-for='(s, i) in profileSteps', :key='i')
                        v-list-item-icon
                            v-icon {{ s.icon }}

                        v-list-item-content
                            v-list-item-title 
                                span {{ $t(s.t) }}
                        v-list-item-action(
                            v-if='loadingStep && step.name === s.name'
                        )
                            v-progress-circular(
                                indeterminate,
                                style,
                                width='2',
                                size='16',
                                color='blue-grey'
                            )

                //- v-list-item(@click='$emit("logout")', v-ripple)
                //-     v-list-item-icon 
                //-         v-icon lock_open
                //-     v-list-item-content
                //-         v-list-item-title {{ $t("signout") }}
        v-col.pr-8.pt-2(cols='10', style='position: relative; height: 100%')
            .overlay(v-if='hideRoute(step.name)')
                v-row.pt-16.mt-16(
                    align='start',
                    justify='center',
                    style='height: 100%'
                )
                    v-card.mt-16
                        v-card-title {{ hiddenTitle(step.name) }}
                        v-card-text 
                            v-list(dense, disabled)
                                v-list-item-group(color='primary')
                                    v-list-item
                                        v-list-item-icon
                                            v-icon check
                                        v-list-item-content
                                            v-list-item-title {{ $t("unlimitedCharts") }}

                                    v-list-item
                                        v-list-item-icon
                                            v-icon check
                                        v-list-item-content
                                            v-list-item-title {{ $t("inviteUsers") }}

                                    v-list-item
                                        v-list-item-icon
                                            v-icon check
                                        v-list-item-content
                                            v-list-item-title {{ $t("createTemplates") }}

                        v-card-actions
                            v-spacer
                            v-btn(
                                v-if='!subscription',
                                color='primary',
                                @click='$store.commit("TOGGLE_EVENT", "payment")'
                            ) {{ $t("subscribe") }}
                            v-btn(
                                v-else,
                                color='accent',
                                depressed,
                                dense,
                                :loading='loadingPortal',
                                @click='toPaymentPortal'
                            )
                                v-icon(left) open_in_new
                                span {{ $t("toPaymentPortal") }}

            v-container(v-if='step.name === "payment"')
                v-row.mt-8(justify='center', v-if='!!subscription')
                    v-btn(
                        color='accent',
                        depressed,
                        dense,
                        :loading='loadingPortal',
                        @click='toPaymentPortal'
                    )
                        v-icon(left) open_in_new
                        span {{ $t("toPaymentPortal") }}
                subscription-status(
                    :subscription='subscription',
                    :products='products'
                )
            v-row(
                align='center',
                justify='center',
                v-else-if='step.name === "payment" && !subscription'
            )
                v-btn(
                    color='primary',
                    outlined,
                    @click='$store.commit("TOGGLE_EVENT", "payment")'
                ) {{ $t("subscribe") }}
            component(
                v-else,
                @set='set',
                :is='step.name',
                :is-admin-mode="true"
                styling-mode='admin',
                :max-users='maxUsers'
            )

    user(v-else)

    language(@switchLang='switchLang')
</template>

<script>
import User from '@/components/Profile/User'
import Cards from '@/components/Profile/Cards'
import SubscriptionStatus from '@/components/Payment/SubscriptionStatus'
import Invoices from '@/components/Payment/Invoices'
import Styling from '@/components/Source/Styling'
import Formats from '@/components/Profile/Formats'
import Logos from '@/components/Profile/Logos'
import AccessRights from '@/components/Profile/AccessRights'
import Users from '@/components/Profile/Users'
import Language from '@/components/Home/Language'

import { mapMutations } from 'vuex'

import UserService from '@/services/userService'
import CheckoutService from '@/services/checkoutService'

export default {
    name: 'profileComponent',
    components: {
        User,
        Users,
        Cards,
        Language,
        Styling,
        AccessRights,
        Logos,
        Formats,
        SubscriptionStatus,
        Invoices,
    },
    data: () => ({
        stepIdx: 0,
        loadingPortal: false,
        loadingStep: false,
        products: [],
        publicRoutes: ['user', 'invoices', 'payment'],
        proRoutes: ['access-rights'],
    }),
    watch: {
        stepIdx: {
            handler: function (stepIdx) {
                window.localStorage.setItem('profileStepIdx', String(stepIdx))
                if (this.step.name !== 'payment') this.loadingStep = true
            },
            immediate: false,
        },
        isAdmin: {
            handler: function (isAdmin) {
                if (isAdmin) {
                    try {
                        this.stepIdx =
                            Number(
                                window.localStorage.getItem('profileStepIdx')
                            ) || 0
                    } catch (e) {
                        console.log(e)
                    }
                }
            },
            immediate: true,
        },
    },
    async mounted() {
        this.products = await CheckoutService.listProducts()
    },
    methods: {
        ...mapMutations(['SET', 'TOGGLE_EVENT']),
        hideRoute(stepName) {
            if (!this.subscription || !this.product)
                return !this.publicRoutes.includes(stepName)

            if (this.product.metadata?.plan === 'basic')
                return this.proRoutes.includes(stepName)

            return false
        },
        hiddenTitle(stepName) {
            const subscription = this.proRoutes.includes(stepName) ? ' PRO' : ''
            return this.$t('reserved', { subscription })
        },
        set(o) {
            for (const k in o) {
                this[k] = o[k]
            }
        },
        async switchLang(lang) {
            this.SET({ user: { lang } })

            this.$i18n.locale = lang
            this.change = !this.change
            await UserService.updateUserLang(this.userId, lang)
        },
        async toPaymentPortal() {
            this.loadingPortal = true
            try {
                const url = await CheckoutService.toCustomerPortal(
                    window.location.href
                    // 'https://app.ponychart;co'
                )
                window.open(url, '_self')
            } catch (e) {
                this.showToast()
            } finally {
                this.loadingPortal = false
            }
        },
    },
    computed: {
        product() {
            return this.subscription?.product
        },
        maxUsers() {
            return parseInt(this.product?.metadata?.users || '0')
        },
        profileSteps() {
            const output = [
                {
                    name: 'user',
                    icon: 'account_circle',
                    t: 'tooltip.profile',
                },
            ]
            if (!this.isAdmin) return output
            // if (this.isSubscribed) {
            output.push({
                name: 'users',
                icon: 'people',
                t: 'users',
            })
            output.push({
                name: 'logos',
                icon: 'image',
                t: 'creation.title_logo',
            })
            output.push({
                name: 'styling',
                icon: 'settings',
                t: 'dashboardPreference',
            })
            output.push({
                name: 'formats',
                icon: 'mdi-format-font',
                t: 'formats',
            })
            // output.push({
            //     name: 'access-rights',
            //     icon: 'lock',
            //     t: 'accessRights',
            // })
            output.push({
                name: 'payment',
                icon: 'mdi-credit-card',
                t: 'payment',
            })
            output.push({
                name: 'invoices',
                icon: 'receipt',
                t: 'invoices',
            })
            return output
        },
        step() {
            if (this.stepIdx >= this.profileSteps.length)
                return this.profileSteps[0]
            return this.profileSteps[this.stepIdx]
        },
        subscription() {
            return this.$store.getters.subscription
        },
        userId() {
            return this.$store.state?.user?.id
        },
    },
}
</script>

<i18n>{
  "en": {
    "toPaymentPortal": "to payment portal",
    "reserved": "This section is for{subscription} subscribers only",
    "unlimitedCharts": "Access all the charts in the library",
    "inviteUsers": "Invite your colleagues to the app & manage their access",
    "createTemplates": "Create your own reusable templates with your own branding",
    "formats": "Formats"
  },
  "es": {
    "toPaymentPortal": "al portal de pagos",
    "formats": "Formatos",
    "reserved": "Esta sección es solo para suscriptores{subscription}",
    "unlimitedCharts": "Acceda a todas las listas de la biblioteca",
    "inviteUsers": "Invita a tus colegas a la aplicación y gestione su acceso",
    "createTemplates": "Crea tus propias plantillas reutilizables con tu propia marca"
  },
  "fr": {
    "toPaymentPortal": "vers portail de paiement",
    "formats": "Formats",
    "reserved": "Cette section est réservée aux abonnés{subscription}",
    "unlimitedCharts": "Accédez à tous les graphiques de la bibliothèque",
    "inviteUsers": "Invitez vos collègues sur l'application et gérez leur accès",
    "createTemplates": "Créez vos propres modèles réutilisables avec votre propre design"
  }
}</i18n>

<style scoped>
body,
html {
    overflow-y: none !important;
    overflow-x: none !important;
}
.overlay {
    position: absolute;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background: linear-gradient(
        180deg,
        rgba(50, 50, 50, 0.2) 0%,
        rgba(50, 50, 50, 0.2) 35%,
        rgba(50, 50, 50, 0.8) 80%,
        rgba(50, 50, 50, 1) 100%
    );
    z-index: 3;
    height: 100%;
    width: 100%;
}
</style>