
import Date from '@/components/Source/Date.vue'
import Description from '@/components/Source/Description.vue'
import Dimension from '@/components/Source/Dimension.vue'
import Filters from '@/components/Source/Filters.vue'
import Finalise from '@/components/Source/Finalise.vue'
import Logo from '@/components/Home/Logo.vue'
import Measure from '@/components/Source/Measure.vue'
import Styling from '@/components/Source/Styling.vue'
import Pages from '@/components/Source/Pages.vue'
import HeaderDesign from '@/components/Source/HeaderDesign.vue'

import SourceService from '@/services/sourceService'
import PageService from '@/services/pageService'
import ResultService from '@/services/resultService'

import Component, { mixins } from 'vue-class-component'

import { GlobalMixins } from '@/mixins'

import { TYPE } from 'vue-toastification'
import { Prop, Watch } from 'vue-property-decorator'
import {
    ParameterInterface,
    sortPagesHook,
    Source,
    SourceMemory,
    RegisterTour,
    SourceMode,
    DEMO_LOCAL_STORAGE_KEY,
} from '@/ponychart'
import { DEFAULT_RAW_ACCESS_RIGHTS, RawAccessRights } from 'ponychart'
import { cloneDeep, omit } from 'lodash'
import { STEPS } from '@/config'

const memoryInstance = SourceMemory.getInstance()
const registerTour = RegisterTour.getInstance()

@Component({
    components: {
        Description,
        Dimension,
        Finalise,
        Filters,
        Logo,
        Measure,
        Styling,
        Pages,
        HeaderDesign,
        Date,
    },
})
export default class SourceComponent extends mixins(GlobalMixins) {
    @Prop({ type: String, default: SourceMode.CLASSIC })
    readonly mode!: SourceMode
    @Prop({ type: String, required: true })
    readonly source_id!: string
    @Prop({ type: String, required: true })
    readonly route_step!: string

    dialog = false
    hasMeasureErrors = false
    downloadsToday = 0
    hasDimensionErrors = false
    hasFilterErrors = false
    hasHeaderDesignErrors = false
    gettingSource = false
    nextLoading = false
    navigation = true
    show = false
    isLocalDev = !process.env.VUE_APP_ENV
    filename = ''
    headerStep = 1
    tourLoading = true
    finalisedLoading = false
    sourceEvent = false
    selected = []
    source: Source | null = null
    dashboardPreferences = []
    parameters: ParameterInterface[] = []
    dashboardPreferenceCount = 0
    destinationStep: string | null = null
    rawAccessRights: RawAccessRights = cloneDeep(DEFAULT_RAW_ACCESS_RIGHTS)
    demoStep: string | null = null
    demoSourceUuid: string | null = null
    icons = {
        measures: 'mdi-chart-areaspline',
        dimensions: 'mdi-chart-bubble',
        filters: 'mdi-filter-variant',
        styling: 'mdi-palette',
        pages: 'table_chart',
        'header-design': 'mdi-format-header-1',
    }

    get destinationStepIdx() {
        return this.destinationStep
            ? this.steps.indexOf(this.destinationStep) + 1
            : -1
    }

    get steps() {
        // if (
        //     this.dashboardPreferenceCount <= 1 &&
        //     Object.values(this.rawAccessRights.styling).reduce(
        //         (acc: boolean, val: boolean) => acc && val,
        //         this.rawAccessRights.logo && this.rawAccessRights.colors
        //     )
        // )
        //     return STEPS.filter((step) => step !== 'styling')
        return STEPS
    }

    get isDemoMode() {
        return this.mode === SourceMode.DEMO
    }

    broofa() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8
                return v.toString(16)
            }
        )
    }

    beforeMount() {
        this.demoSourceUuid = this.broofa()
        if (this.source?.id !== this.sourceId) {
            this.clean(true)
        }
    }

    beforeDestroy() {
        this.clean()
    }
    async downloadSource() {
        await this.nextFactory()
        this.show = false
        const source = this.source
        if (source) {
            // const sourceToDownload = omit(source, ['id'])
            const dataStr =
                'data:text/json;charset=utf-8,' +
                encodeURIComponent(
                    JSON.stringify(
                        {
                            source,
                            rawAccessRights: this.rawAccessRights,
                            dashboardPreferenceCount:
                                this.dashboardPreferenceCount,
                            formats: this.source?.formats || [],
                        },
                        null,
                        2
                    )
                )
            const downloadAnchorNode = document.createElement('a')
            downloadAnchorNode.setAttribute('href', dataStr)
            downloadAnchorNode.setAttribute('download', this.lang + '.json')
            document.body.appendChild(downloadAnchorNode) // required for firefox
            downloadAnchorNode.click()
            downloadAnchorNode.remove()
        }
    }
    clean(force = false) {
        this.source = {
            id: -1,
            measures: [],
            columns: [],
            dimensions: [],
            dates: [],
            pages: [],
            traits: [],
            filters: [],
            stepIdx: 1,
            step: 'measure',
            steps: {},
            version: undefined,
            twbDatasources: [],
            formats: [],
        }
        if (force) memoryInstance.clean()
    }
    SET(payload: any) {
        this.$store.commit('SET', payload)
    }
    TOGGLE_EVENT(event: string) {
        this.$store.commit('TOGGLE_EVENT', event)
    }

    async handleStepper(stepIdx: number) {
        if (!this.isEditableStep(stepIdx)) return
        await this.nextFactory(stepIdx + 1)
    }
    error() {
        this.nextLoading = false
        this.showToast({
            code: 'NEXT_ERROR',
        })
    }
    next() {
        this.nextLoading = false
        if (this.stepIdx < this.steps.length) {
            this.stepIdx++
        }
    }
    async prev() {
        if (this.stepIdx <= 1) return
        this.handleStepper(this.stepIdx - 1)
    }
    set(o: any) {
        for (const k in o) {
            this[k] = o[k]
        }
    }
    async getSource() {
        this.gettingSource = true
        try {
            const { source, rawAccessRights, dashboardPreferenceCount } = this
                .isDemoMode
                ? await SourceService.getDemoSource(this.lang)
                : await SourceService.getSource(this.sourceId)
            if (this.isDemoMode) {
                this.$i18n.locale = this.lang
                memoryInstance.setLang(this.lang)
            }
            this.rawAccessRights = rawAccessRights
            this.dashboardPreferenceCount = dashboardPreferenceCount
            // This should already be set inside getSource()
            // memoryInstance.setSource(source)
            this.source = source
            if (source?.filename)
                this.filename = source.filename + ' ' + this.$t('modified')
            if (this.isDemoMode && !this.filename)
                this.filename = String(this.$t('myTestFile'))
        } catch (err) {
            console.log(err)
            this.showToast({
                code: 'not_found',
                type: TYPE.WARNING,
            })

            if (!this.isDemoMode) this.goTo('/404')
        } finally {
            this.gettingSource = false
        }
    }
    savePages() {
        this.show = true
        this.checkIfUserHasDownloadedToday()
        this.finalisedLoading = true
    }
    isCurrentStep(stepIdx: number) {
        return stepIdx === this.stepIdx - 1
    }
    isNextStep(stepIdx: number) {
        const processStep = (s: string) => s.split('-')[0]
        const stepsSaved = Object.keys(this.source?.steps || {})
        const steps = this.steps.map(processStep)
        stepsSaved.push(processStep(this.step))
        const maxStepIdx = Math.max(...stepsSaved.map((s) => steps.indexOf(s)))
        return stepIdx === maxStepIdx + 1
    }
    async activateTour() {
        try {
            const vueElement = registerTour.getTourContext()
            if (!vueElement) throw new Error('Element not found')
            vueElement.startTour({ step: this.step })
        } catch (e) {
            console.log(e)
        }
    }
    isFutureStep(stepIdx: number) {
        return stepIdx >= this.stepIdx
    }
    isPastStep(stepIdx: number) {
        return stepIdx < this.stepIdx - 1
    }
    isSavedStep(stepIdx: number) {
        const step = this.steps[stepIdx]
        return !!this.source?.steps?.[step.split('-')[0]]
    }
    isStepDisabled(stepIdx: number) {
        if (this.disabled || this.nextLoading) return true
        if (this.isFutureStep(stepIdx) && !this.isSavedStep(stepIdx))
            return true
        if (this.isCurrentStep(stepIdx)) return true
        return false
    }
    isEditableStep(stepIdx: number): boolean {
        if (this.isNextStep(stepIdx) && !this.disabled) return true
        if (this.isStepDisabled(stepIdx)) return false
        if (this.isCurrentStep(stepIdx)) return false
        return true
    }
    isCompleteStep(stepIdx: number): boolean {
        return this.isSavedStep(stepIdx) || this.isPastStep(stepIdx)
    }
    get hasDescription() {
        return !['styling', 'header-design', 'pages'].includes(this.step)
    }
    get dashboardCount() {
        return this.$store.getters.dashboardCount
    }
    get loading() {
        return this.nextLoading || this.gettingSource
    }
    getError(step: string) {
        if (step === 'measure') return this.hasMeasureErrors
        if (step === 'dimension') return this.hasDimensionErrors
        if (step === 'filters') return this.hasFilterErrors
        if (step === 'header-design') return this.hasHeaderDesignErrors
        return false
    }
    get disabled() {
        return this.getError(this.step)
    }
    get storeStep() {
        return this.source?.step || 'measure'
    }
    get stepIdx() {
        // TODO
        return this.steps.indexOf(this.step) + 1
    }
    set stepIdx(stepIdx: number) {
        const step = this.steps[stepIdx - 1]
        if (step !== this.step) {
            this.step = step
            if (!this.source) return
            this.source.stepIdx = stepIdx
            this.source.step = step
        }
    }

    get step() {
        if (this.isDemoMode && this.demoStep) {
            return this.demoStep
        }
        return this.route_step
    }
    set step(step: string) {
        if (this.isDemoMode) return
        this.$nextTick(() => this.goTo(`/dashboards/${this.sourceId}/${step}`))
    }
    get nextStepIdx() {
        return this.stepIdx === this.steps.length
            ? this.stepIdx
            : this.stepIdx + 1
    }
    get sourceId() {
        return parseInt(this.source_id)
    }
    get tokens() {
        return this.$store.state.company?.token || 0
    }
    get isSmall() {
        return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    }
    async getRefSafe(refKey?: string) {
        if (!refKey) refKey = this.step
        return new Promise((resolve) => {
            let count = 0
            const interval = setInterval(() => {
                if (!refKey) refKey = this.step
                if (this.$refs[refKey] || count > 50) {
                    clearInterval(interval)
                    const ref = this.$refs[refKey]
                    if (Array.isArray(ref)) {
                        resolve(ref[0])
                    } else {
                        resolve(ref)
                    }
                }
                count += 1
            }, 50)
        })
    }

    checkIfUserHasDownloadedToday() {
        if (this.isDemoMode) {
            try {
                this.downloadsToday = parseInt(
                    localStorage.getItem(DEMO_LOCAL_STORAGE_KEY) || '0'
                )
            } catch (e) {
                console.log(e)
                return
            }
            return
        }
        ResultService.checkUserHasDownloadedToday().then(
            (downloadsToday: number) => {
                this.downloadsToday = downloadsToday
            }
        )
    }
    async makeMeasurePayload(ref?: any) {
        ref = ref || (await this.getRefSafe('measure'))
        const measures = ref.measures
        memoryInstance.setMeasures(measures)
        const payload: any = {
            measures,
            formats: memoryInstance.formats,
        }
        const memoryPages = memoryInstance.pages
        if (memoryPages.length > 0) {
            sortPagesHook(memoryPages)
            payload.pages = memoryPages
        }
        return payload
    }
    async makeDimensionPayload(ref?: any) {
        ref = ref || (await this.getRefSafe('dimension'))
        const dimensions = ref.dimensions.map((d: Dimension) =>
            omit(d, ['errors'])
        )
        memoryInstance.setDimensions(dimensions)
        const payload: any = {
            dimensions,
        }
        const memoryPages = memoryInstance.pages
        if (memoryPages.length > 0) {
            sortPagesHook(memoryPages)
            payload.pages = memoryPages
        }
        return payload
    }
    async makeFiltersPayload(ref?: any) {
        ref = ref || (await this.getRefSafe('filters'))
        const filters = ref.filters || []
        return { filters }
    }
    async makeStylingPayload(ref?: any) {
        ref = ref || (await this.getRefSafe('styling'))
        const dashboardPreference = ref.dashboardPreference || {}
        return {
            traits: dashboardPreference.traits,
            logo: dashboardPreference.logo,
            colors: dashboardPreference.colors,
        }
    }
    async makeHeaderDesignPayload(ref?: any) {
        if (!this.source) return {}
        ref = ref || (await this.getRefSafe('header-design'))
        const dates = ref.selectedDates
        memoryInstance.setDates(dates)
        const payload: any = {
            traits: this.source.traits,
            filters: memoryInstance.filters,
            dates,
        }
        const memoryPages = memoryInstance.pages
        if (memoryPages.length > 0) {
            sortPagesHook(memoryPages)
            payload.pages = memoryPages
        }
        return payload
    }
    async makePagesPayload(ref?: any) {
        ref = ref || (await this.getRefSafe('pages'))
        // Voluntary side-effect
        this.checkIfUserHasDownloadedToday()
        memoryInstance.setPages(ref.selected)
        return {
            pages: ref.selected,
        }
    }

    async makePayload(ref?: any) {
        const actions = {
            measure: this.makeMeasurePayload,
            dimension: this.makeDimensionPayload,
            styling: this.makeStylingPayload,
            'header-design': this.makeHeaderDesignPayload,
            pages: this.makePagesPayload,
            filters: this.makeFiltersPayload,
        }
        return await actions[this.step](ref)
    }
    async save() {
        const payload = await this.makePayload()
        if (!this.isDemoMode) {
            SourceService.saveSource(this.sourceId, payload, { noResult: true })
        }
    }
    async nextFactory(nextStepIdx: number = this.nextStepIdx) {
        this.clearTour()
        this.nextLoading = true
        const ref: any | undefined = await this.getRefSafe()
        const payload: any = {}
        if (nextStepIdx <= this.steps.length) {
            payload.stepIdx = nextStepIdx
            payload.step = this.steps[nextStepIdx - 1]
            if (!payload.steps) payload.steps = this.source?.steps || {}
            payload.steps[this.step.split('-')[0]] = true
        }
        if (!ref) {
            this.nextLoading = false
            this.showToast({})
            return
        }
        this.destinationStep = payload.step
        const body = await this.makePayload(ref)

        Object.assign(payload, body)

        if (Object.keys(body).includes('pages') && !body?.pages?.length) {
            if (!this.isDemoMode) PageService.dropAllPages(this.sourceId)
        }

        try {
            if (!this.isDemoMode) {
                SourceService.saveSource(this.sourceId, payload, {
                    noResult: true,
                }).catch(() => {
                    this.showToast({ code: 'NEXT_ERROR' })
                })
            } else {
                this.demoStep = this.destinationStep
            }
            this.source = { ...(this.source || {}), ...payload }
            if (this.source) memoryInstance.setSource(this.source)
            if (nextStepIdx <= this.steps.length) {
                this.stepIdx = nextStepIdx
                await this.$nextTick()
                window.scrollTo(0, 0)
            } else {
                this.show = true
            }
            if (this.stepIdx === this.steps.length)
                this.sourceEvent = !this.sourceEvent
        } catch (e) {
            console.log(e)
            this.showToast()
        } finally {
            setTimeout(
                () => {
                    this.nextLoading = false
                    this.activateTour()
                },
                1500,
                this
            )
            this.destinationStep = null
        }
    }

    @Watch('sourceId', { immediate: true })
    onSourceIdChange(sourceId: number) {
        if (!!sourceId && this.source?.id !== sourceId) {
            this.getSource()
        }
    }
    @Watch('storeStep')
    onStepIxChange(storeStep: string) {
        this.stepIdx = this.steps.indexOf(storeStep) + 1
    }
    @Watch('loading')
    onLoadingChange(loading: boolean) {
        if (loading) {
            this.tourLoading = loading
        } else {
            setTimeout(() => (this.tourLoading = loading), 2400)
        }
    }
}
