<template lang="pug">
div
    v-card.borderRadius.mt-3.mb-3
        v-card-title.headline
            v-row.ml-3(justify='space-between')
                span {{ title }}
                v-progress-circular(
                    indeterminate,
                    v-if='loading',
                    color='blue'
                )
        v-divider
        v-window(:value='window', vertical)
            v-window-item
                v-card-text
                    v-container
                        component(:is='row ? "v-row" : "v-col"')
                            v-col.pl-3
                                v-text-field(
                                    @input='setName',
                                    outlined,
                                    dense,
                                    :error='!name',
                                    required,
                                    :value='name',
                                    :label='$t("name")'
                                )
                                v-text-field(
                                    @input='setEmail',
                                    outlined,
                                    required,
                                    :error-messages='emailError',
                                    dense,
                                    :value='email',
                                    :label='$t("email")'
                                )
                                v-text-field(
                                    @input='setPassword',
                                    outlined,
                                    dense,
                                    :error-messages='passwordError',
                                    :type='showPassword ? "text" : "password"',
                                    :append-icon='showPassword ? "mdi-eye" : "mdi-eye-off"',
                                    @click:append='showPassword = !showPassword',
                                    :value='password',
                                    :label='$t("password")'
                                )
                                //- v-text-field(
                                //-     @input='setCompany',
                                //-     outlined,
                                //-     :error='!companyName',
                                //-     dense,
                                //-     :value='companyName',
                                //-     :label='$t("company")'
                                //- )
                                v-select(
                                    dense,
                                    outlined,
                                    :items='langs',
                                    :label='$t("profile_page.lang")',
                                    :value='lang',
                                    @change='setLang'
                                )
                                v-text-field(
                                    disabled,
                                    outlined,
                                    dense,
                                    :value='$t(zone)',
                                    :label='$t("zone")'
                                )
                            v-divider(vertical)
                            v-col.pr-3
                                v-checkbox(v-model='accept', color='blue')
                                    template(slot='label')
                                        v-layout(
                                            :column='lang == "de" || !row'
                                        )
                                            | {{ $t("signup_page.accept[0]") + " " }}&nbsp;&nbsp;
                                            br(v-if='lang == "de"')
                                            a(
                                                :href='`https://ponychart.com/${lang}/terms`',
                                                rel='noopener noreferrer',
                                                :style='lang == "de" || row ? "" : "padding-left:8px"',
                                                target='_blank',
                                                @click.stop,
                                                v-html='link'
                                            )
                                p {{ $t("signup_page.accept_detail") }}
            v-window-item
                v-card-text
                    v-container
                        p(v-html='$t("signup_page.validation", { name })')
                        v-col
                            v-text-field(
                                :value='code',
                                @input='setCode',
                                solo,
                                :label='$t("code")'
                            )
        v-divider
        v-card-actions(v-if='window === 0 && !loading')
            v-btn(:disabled='loading', text, @click.stop='$emit("back")') {{ $t("creation.back") }}
            v-spacer
            v-btn(
                :disabled='!accept || loading || !valid',
                color='primary',
                @click.stop='$emit("signup")'
            ) {{ $t("signup") }}
        v-card-actions(v-else)
            v-spacer
            v-btn(
                @click.stop='$emit("resendCode")',
                color='accent',
                text,
                :disabled='loading'
            ) {{ $t("resend") }}
            v-btn(
                :disabled='loading || !code',
                color='primary',
                @click.stop='$emit("login")'
            ) {{ $t("signup") }}
</template>

<script>
export default {
    name: 'signupSummary',
    props: {
        name: {
            type: String,
            required: true,
        },
        zone: {
            type: String,
            required: false,
            default: 'fr',
        },
        email: {
            type: String,
            required: true,
        },
        password: {
            type: String,
            required: true,
        },
        companyName: {
            type: String,
            required: true,
        },
        code: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        window: {
            type: Number,
            required: true,
        },
        emailError: {
            type: String,
        },
        passwordError: {
            type: String,
        },
    },
    methods: {
        setName(name) {
            if (name || name === '') {
                this.$emit('set', { name })
            }
        },
        setLang(lang) {
            this.goTo(`/${lang}/signup`)
        },
        setCompany(companyName) {
            if (companyName || companyName === '') {
                this.$emit('set', { companyName })
            }
        },
        setCode(code) {
            this.$emit('set', { code })
        },
        setPassword(password) {
            if (password || password === '') {
                this.$emit('set', { password, passwordError: '' })
            }
        },
        setEmail(email) {
            if (email || email === '') {
                this.$emit('set', { email, emailError: '' })
            }
        },
    },
    computed: {
        title() {
            return this.window == 0
                ? `${this.$t('creation.summary')} ${this.$t('signup')}`
                : this.$t('errors.thanks')
        },
        langs() {
            return ['en', 'fr', 'es'].map((value) => ({
                value,
                text: this.$t('profile_page.' + value),
            }))
        },
        link() {
            return `${this.$t('signup_page.accept[1]')}&nbsp;<span>${this.$t(
                'signup_page.accept[2]'
            )}</span>&nbsp;${
                this.lang != 'de' ? this.$t('signup_page.accept[3]') : ''
            }`
        },
        valid() {
            return (
                !this.emailInvalid(this.email) &&
                !!this.name &&
                // !!this.companyName &&
                !!this.password
            )
        },
        row() {
            return !['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
        },
    },
    data: () => ({
        accept: false,
        showPassword: false,
    }),
}
</script>

<style scoped>
.borderRadius {
    border-radius: 10px;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
}
@media (max-width: 800px) {
    .borderRadius {
        width: 90%;
    }
}
</style>