
import draggable from 'vuedraggable'

import TwbDatasourceSelect from '@/components/utils/TwbDatasourceSelect.vue'
import MeasurePopup from '@/components/Measures/MeasurePopup.vue'
import MeasureRow from '@/components/Measures/MeasureRow.vue'
import MeasureColorPicker from '@/components/Measures/MeasureColorPicker.vue'
import MeasureDemo from '@/components/Measures/MeasureDemo.vue'
import Formats from '@/components/Profile/Formats.vue'
import Tour from '@/components/utils/Tour.vue'

import { GlobalMixins } from '@/mixins'
import { cloneDeep } from 'lodash'

import {
    Column,
    Format as _Format,
    Measure,
    MeasureAggregation,
    MeasureHelpers,
    MeasureFormat as _MeasureFormat,
    MeasurePopupMode,
    SourceMemory,
    Source,
    MAXIMUM,
    TABLEAU_BRIGHT_VALUES,
    SourceMode,
} from '@/ponychart'

import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

const memoryInstance = SourceMemory.getInstance()

@Component({
    components: {
        draggable,
        MeasurePopup,
        MeasureRow,
        MeasureDemo,
        MeasureColorPicker,
        Tour,
        Formats,
        TwbDatasourceSelect,
    },
})
export default class MeasureComponent extends mixins(GlobalMixins) {
    @Prop({ type: String, required: true })
    readonly step!: string
    @Prop({ type: Object, required: true })
    readonly source!: Source
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: String, required: true })
    readonly sourceMode!: SourceMode

    show = {
        add: false,
        format: false,
        demo: false,
        clone: false,
        color: false,
    }
    clicked = {
        add: false,
        format: false,
    }
    dragOptions = {
        animation: 0,
        disabled: false,
        ghostClass: 'ghost',
    }
    tableauColors = TABLEAU_BRIGHT_VALUES
    formatMode = false
    verb: 'add' | 'edit' | 'clone' = 'add'
    measurePopupMode: MeasurePopupMode = MeasurePopupMode.ADD
    measures: Measure[] = []
    selectedMeasures: Measure[] = []
    measure: Measure = MeasureHelpers.new({ twbIdx: 0, formatId: '' })
    measureAliasCount: { [k: string]: number } = {}
    tmt: null | number = null

    selectedTwbDatasources: string[] = []

    get isDemoMode(){
        return this.sourceMode === SourceMode.DEMO
    }

    get sortedSelectedTwbIndexes() {
        const indexes = this.twbDatasources.map((t) => t.id)
        return this.selectedTwbDatasources.map((t) => indexes.indexOf(t)).sort()
    }

    get twbDatasources() {
        return this.source.twbDatasources.map((t, i) => ({
            ...t,
            color: TABLEAU_BRIGHT_VALUES[i % TABLEAU_BRIGHT_VALUES.length],
        }))
    }

    get datasourceMissingMeasure(): string {
        if (this.twbDatasources.length <= 1) return ''
        for (const i in this.twbDatasources) {
            const twbIdx = Number(i)
            if (this.measures.filter((m) => m.twbIdx === twbIdx).length === 0)
                return String(
                    this.$t('datasourceMissingMeasure', {
                        datasource: this.twbDatasources[twbIdx].alias,
                    })
                )
        }
        return ''
    }

    get hasTimeType() {
        return this.measures.reduce(
            (acc, m) => acc || m.formatId === 'h:m:s',
            false
        )
    }
    get maxMeasures() {
        return this.isDemoMode ? MAXIMUM.demoMeasures : MAXIMUM.measures
    }
    get helperSentence() {
        return `<b>${this.measures.length}</b>&nbsp;${String(
            this.$t('creation.small_measure')
        ).toLowerCase()} ${this.$t('creation.selected')} (max ${
            this.maxMeasures
        })`
    }
    get columns(): Column[] {
        return memoryInstance.columns
    }
    get storeMeasures() {
        return this.source?.measures || []
    }
    get hasAverage() {
        for (const measure of this.measures) {
            if (measure.agg === MeasureAggregation.AVG) return true
        }
        return false
    }
    get idsWithDependency() {
        const ids: string[] = []
        for (const measure of this.measures) {
            if (measure.id1) ids.push(measure.id1)
            if (measure.id2) ids.push(measure.id2)
        }
        return ids
    }
    get disabled() {
        return this.measures.length >= this.maxMeasures
    }

    get measureNames() {
        return this.measures.reduce(
            (prev, measure) => ({
                ...prev,
                [measure.id]: measure.alias,
            }),
            {}
        )
    }

    get mainFormats() {
        return this.step === 'measure' ? memoryInstance.mainFormats : []
    }

    get usedMeasureIds() {
        return this.step === 'measure' ? memoryInstance.usedMeasureIds : []
    }

    applyFormats() {
        for (const measure of this.measures) {
            if (!measure.formatId) {
                measure.formatId = this.mainFormats[0].id || ''
            }
        }
    }
    measureKey(m: Measure) {
        return `${m.id}_${m.alias}_${m.type}_${m.agg}_${m.filters.length}_${m.formatId}_${m.twbIdx}`
    }
    refreshErrors() {
        this.setMeasureAliasCount()
        let hasAtLeastOneError = false
        for (const measure of this.measures) {
            if (!measure?.alias?.length) {
                const column = memoryInstance.getColumn(measure.columnId)
                measure.error =
                    column.alias + String(this.$t('errors.empty_col_name'))
            } else if (this.measureAliasCount[measure.alias] > 1) {
                measure.error =
                    measure.alias + String(this.$t('errors.duplicate_col_name'))
            } else if (
                measure?.alias?.length >= 2 &&
                MeasureHelpers.forbiddenPrefixes.has(
                    measure.alias.substring(0, 2)
                )
            ) {
                measure.error = String(
                    this.$t('errors.prefix', {
                        x: measure.alias.substring(0, 2),
                    })
                )
            } else if (MeasureHelpers.forbiddenAliases.has(measure.alias)) {
                measure.error = String(
                    this.$t('errors.bad_name', { x: measure.alias })
                )
            } else {
                measure.error = undefined
            }
            if (measure.alias === 'Year') console.log(measure.error)
            if (measure.error) hasAtLeastOneError = true
        }
        const hasMeasureErrors =
            hasAtLeastOneError ||
            this.measures.length === 0 ||
            this.datasourceMissingMeasure.length > 0
        this.$emit('set', {
            hasMeasureErrors,
        })
    }
    setMeasureAliasCount() {
        this.measureAliasCount = this.measures.reduce(
            (prev, cur) => ({
                ...prev,
                [cur.alias]: (prev[cur.alias] || 0) + 1,
            }),
            {}
        )
    }
    closeMeasurePopup() {
        this.show.add = false
        if (this.measurePopupMode === MeasurePopupMode.ADD) {
            this.refreshErrors()
        }
    }
    deleteMeasure(measure: Measure) {
        const idx = this.measures.map((m) => m.id).indexOf(measure.id)
        this.measures.splice(idx, 1)
        this.selectedMeasuresHook()
        this.saveHook()
        this.refreshErrors()
    }
    setReversed(i: number, reversed: boolean) {
        this.measures[i].reversed = reversed
        this.saveHook()
    }
    showFormat() {
        this.show.format = true
        this.clicked.format = true
    }
    showAdd() {
        this.clearTour()
        this.verb = 'add'
        this.measure = MeasureHelpers.new({
            formatId: this.mainFormats[0].id || '',
            twbIdx: this.sortedSelectedTwbIndexes[0] || 0,
        })
        this.clicked.add = true
        this.measurePopupMode = MeasurePopupMode.ADD
        this.show.add = true
    }
    showClone(m: Measure) {
        this.clearTour()
        this.verb = 'clone'
        this.measure = {
            ...cloneDeep(m),
            id: MeasureHelpers.id(),
        }
        this.measurePopupMode = MeasurePopupMode.ADD
        this.show.add = true
    }
    showColor(m: Measure) {
        this.clearTour()
        this.measure = m
        this.show.color = true
    }
    showEdit(measure: Measure) {
        this.clearTour()
        this.verb = 'edit'
        this.measure = measure
        this.measurePopupMode = MeasurePopupMode.EDIT
        this.show.add = true
    }
    addMeasure(measure: Measure) {
        this.measures.splice(this.measures.length, 0, {
            ...cloneDeep(measure),
        })
        this.selectedMeasuresHook()
        this.saveHook()
    }
    deleteAllMeasures() {
        this.measures = this.measures.filter((m) => this.usedMeasureIds.includes(m.id))
        this.selectedMeasuresHook()
        this.saveHook()
    }
    saveHook() {
        if (this.tmt) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.$emit('save')
            },
            5000,
            this
        )
    }
    selectedMeasuresHook() {
        this.selectedMeasures = this.measures.filter((m) =>
            this.sortedSelectedTwbIndexes.includes(m.twbIdx)
        )
    }
    async onDragChange(
        type: 'chips' | 'measure',
        e: { moved: { element: Measure; oldIndex: number; newIndex: number } }
    ) {
        const movedMeasure: Measure = e.moved.element
        if (type === 'chips') {
            // If the sorted element is not displayed, then return
            if (!this.sortedSelectedTwbIndexes.includes(movedMeasure.twbIdx))
                return
            // Tested and works easily
            this.selectedMeasuresHook()
        } else {
            const selectedNewIndex = e.moved.newIndex
            const switchMeasureId =
                selectedNewIndex === this.selectedMeasures.length - 1
                    ? undefined
                    : this.selectedMeasures[selectedNewIndex + 1].id

            const newMeasures = []
            let pushed = false
            for (const measure of this.measures) {
                if (measure.id === switchMeasureId) {
                    newMeasures.push(movedMeasure)
                    pushed = true
                } else if (measure.id === movedMeasure.id) {
                    continue
                }
                newMeasures.push(measure)
            }
            if (!pushed) newMeasures.push(movedMeasure)
            this.measures = newMeasures
        }
    }

    mounted() {
        this.selectedTwbDatasources = this.twbDatasources.map((t) => t.id)
        this.selectedMeasuresHook()
    }

    @Watch('columns', { immediate: true })
    onColumnsChanged(columns: Column[]) {
        if (!columns.length) return

        if (this.storeMeasures.length) this.measures = [...this.storeMeasures]
        else
            this.measures = MeasureHelpers.fromColumns(this.columns, {
                max: this.maxMeasures,
                formatId: this.mainFormats?.[0]?.id,
            })
        this.selectedMeasuresHook()
        this.applyFormats()
        this.refreshErrors()
    }
}
