import { Vue, Component } from 'vue-property-decorator'

@Component
export class Profile extends Vue {
    pristine = true
    style(color: string) {
        return {
            marginTop: '-5px',
            width: '20px',
            float: 'right',
            height: '20px',
            'background-color': color,
            'box-shadow': `0 0 -10px ${color}`,
            '-moz-box-shadow': `0 0 -10px ${color}`,
            '-webkit-box-shadow': `0 0 -10px ${color}`,
            '-o-box-shadow': `0 0 -10px ${color}`,
            'border-radius': '50%',
            animation: `${color}_glow 5000ms infinite`,
        }
    }
}
