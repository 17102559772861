
import { GlobalMixins } from '@/mixins'
import { Format } from '@/ponychart'
import ShowMoreBtn from '@/components/utils/ShowMoreBtn.vue'
import FormatInput from '@/components/Profile/FormatInput.vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import FormatService from '@/services/formatService'
import SourceService from '@/services/sourceService'

@Component({
    components: {
        ShowMoreBtn,
        FormatInput,
    },
})
export default class FormatComponent extends mixins(GlobalMixins) {
    @Prop({ type: Object, required: true })
    readonly format!: Format
    @Prop({ type: Array, required: true })
    readonly evoFormats!: Format[]
    @Prop({ type: Boolean, required: false })
    readonly isPopup!: boolean
    @Prop({ type: Boolean, required: false })
    readonly loading!: boolean
    @Prop({ type: Boolean, required: true })
    readonly adminMode!: boolean
    @Prop({type: Number, required: true})
    readonly sourceId!: number

    tmt: number | null = null
    show = false
    updateLoading = false
    edit = {
        mainFormat: false,
        suffix: false,
        alias: false,
        diffFormat: false,
    }
    get error() {
        return (
            this.suffixError ||
            this.aliasError ||
            this.diffFormatError ||
            this.mainFormatError
        )
    }
    get suffixError() {
        return !this.format.suffix
    }
    get aliasError() {
        return !this.format.alias
    }
    get mainFormatError() {
        return !this.format.mainFormat
    }
    get diffFormatError() {
        return !this.format.diffFormat
    }
    

    async blur(key: 'mainFormat' | 'suffix' | 'alias' | 'diffFormat') {
        this.edit[key] = false
        this.update()
    }

    async update() {
        if (this.tmt) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            async () => {
                if (!this.format.id || this.error) return
                this.updateLoading = true
                try {
                    if (this.adminMode) {
                        await FormatService.updateFormat(
                            this.format.id,
                            this.format
                        )
                    } else {
                        if (!this.sourceId)
                            throw new Error('source id should not be null')
                        this.$emit('saveFormatsNonAdminMode')
                    }
                } catch (e) {
                    console.error(e)
                    this.showToast()
                } finally {
                    this.updateLoading = false
                }
            },
            2000,
            this
        )
    }
}
