
import { GlobalMixins } from '@/mixins'
import Page from '@/components/Pages/Page.vue'
import PageTrait from '@/components/Pages/PageTrait.vue'
import ShowMoreBtn from '@/components/utils/ShowMoreBtn.vue'
import { Bool, getCardPage, GlobalOptions, SourceMemory } from '@/ponychart'
import {
    Trait,
    TraitId,
    TraitOptionType,
    Bands,
    BAND_TRAITS_OPTIONS,
    ChartType,
} from 'ponychart'
import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

const sourceMemory = SourceMemory.getInstance()

@Component({
    components: { Page, PageTrait, ShowMoreBtn },
})
export default class CardDesignPreview extends mixins(GlobalMixins) {
    @Prop({ type: String, required: true })
    readonly value!: string
    @Prop({ type: Array, required: true })
    readonly sourceTraits!: Trait[]
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: Object, required: true })
    readonly globalOptions!: GlobalOptions
    @Prop({ type: Boolean, required: true })
    readonly show!: boolean

    page = getCardPage()
    currentColor = ''
    tmt: null | number = null
    event = false
    showMoreBtn = false

    toggleEvent() {
        this.event = !this.event
    }

    get cardHasBordersTrait() {
        return this.sourceTraits.find(
            (trait) => trait.id === TraitId.CARD_HAS_BORDERS
        )
    }

    get borderTrait() {
        return this.sourceTraits.find((trait) => trait.id === TraitId.BORDER)
    }

    get bandTrait() {
        return this.sourceTraits.find((trait) => trait.id === TraitId.BANDS)
    }

    get bandWidthTrait() {
        return this.sourceTraits.find(
            (trait) => trait.id === TraitId.BAND_WIDTH
        )
    }

    get alert() {
        if (this.bandWidthTrait && this.bandWidthTrait.value) return false
        return true
    }

    get style() {
        return {
            maxHeight: '450px',
            overflowX: 'hidden',
            overflowY: 'auto',
            border: '1px solid black',
            borderRadius: '10px',
            margin: '5px',
        }
    }

    items: Bands[][] = []

    name(items: Bands[]) {
        if (!items.length) return ''
        if (items.length >= 5) return this.$t('bands.everywhere')
        if (items.length === 4) return this.$t('bands.everywhereTitle')
        const joinedText = [...items].sort().reverse().join()
        if (this.$te(`bands.${joinedText}`))
            return this.$t(`bands.${joinedText}`)
        const bands = items.map((i) =>
            String(this.$t(`bands.${i}`)).toLowerCase()
        )
        const lastItem = bands.pop()
        const output = []
        if (bands.length) output.push(bands.join(', '))
        output.push(lastItem)
        const outputString = output.join(' & ')
        // returns as titlecase
        return outputString[0].toUpperCase() + outputString.slice(1)
    }

    equals(bands: Bands[]) {
        return this.value === bands.join(';')
    }

    setCurrentColor(currentColor: string) {
        if (this.tmt) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            async () => {
                this.$emit('color', currentColor)
                await this.$nextTick()
                this.onShowChange(true)
            },
            400,
            this
        )
    }

    async set(trait: Trait, e: number) {
        this.$emit('set', { trait, value: e })
        await this.$nextTick()
        this.onShowChange(true)
    }

    listSourceTraits(bands: Bands[]): Trait[] {
        const value = bands.join(';')
        const traits = this.sourceTraits
            .map((trait) =>
                trait.id === TraitId.BANDS ? { ...trait, value } : trait
            )
            .map((trait) =>
                trait.id === TraitId.PAGE_MARGIN_X
                    ? { ...trait, value: 5 }
                    : trait
            )
        traits.push({
            id: TraitId.COLUMN_COUNT,
            value: 1,
            type: TraitOptionType.SELECT,
        })
        traits.push({
            id: TraitId.MEASURE,
            value: sourceMemory.measures[0].id,
            type: TraitOptionType.SELECT,
            querySelectorTags: [0],
            twbIdx: 0,
        })
        traits.push({
            id: TraitId.DIMENSION,
            value: sourceMemory.dimensions[0].id,
            type: TraitOptionType.SELECT,
            querySelectorTags: [0],
            twbIdx: 0,
        })
        traits.push({
            id: TraitId.MARGIN,
            value: 10,
            type: TraitOptionType.SLIDER,
            twbIdx: 0,
        })
        traits.push({
            id: TraitId.CHART_1,
            value: ChartType.KPI,
            type: TraitOptionType.SELECT,
            querySelectorTags: [0, 1],
            twbIdx: 0,
        })
        traits.push({
            id: TraitId.LABEL_SIZE,
            value: 30,
            type: TraitOptionType.SLIDER,
            querySelectorTags: [0, 1],
            twbIdx: 0,
        })
        traits.push({
            id: TraitId.CHART_2,
            value: ChartType.BAR,
            type: TraitOptionType.SELECT,
            querySelectorTags: [0, 2],
            twbIdx: 0,
        })
        traits.push({
            id: TraitId.CARD_HEIGHT,
            value: 600,
            type: TraitOptionType.SLIDER,
            twbIdx: 0,
        })
        traits.push({
            id: TraitId.INCLUDE_PAGE_BLOCK_HEADER,
            value: Bool.FALSE,
            type: TraitOptionType.CHECKBOX,
            twbIdx: 0,
        })
        return traits
    }

    mounted() {
        this.currentColor = this.globalOptions.colors.border
    }

    @Watch('show', { immediate: true })
    async onShowChange(show: boolean) {
        if (!show) return
        if (!this.items || this.items.length === 0)
            this.items = [...BAND_TRAITS_OPTIONS]
        await this.$nextTick()
        this.toggleEvent()
        this.currentColor =
            this.globalOptions.colors.border || this.currentColor
    }
}
