import { ChartType, TraitId } from 'ponychart'

import { SVGChart } from '@/ponychart/svg'
import { PonychartElement } from '@/ponychart/element/model'
import type { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import { SourceMemory } from '@/ponychart/memoize'
import { t } from '@/ponychart/i18n/translate'
import { memoizedInvertColor } from '@/ponychart/utils'

import { getRandomValues, LineRow } from '../textElement'

const memoryInstance = SourceMemory.getInstance()

export class TimeIndication extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.TIME_PERIOD_INDICATION, globalState, localState, {
            traitSearch,
        })
    }

    compileHtml(): string {
        const svgChart = new SVGChart(ChartType.TIME_PERIOD_INDICATION, {
            height: 60,
            width: 100,
        })
        const colorHexa =
            this.localState.attributes[TraitId.COLOR] ||
            this.globalState.colors.light_background
        const d = new Date()
        const year = d.getFullYear()
        const month = t(
            `months.${d
                .toLocaleString('en-US', { month: 'short' })
                .toLowerCase()}`
        )
        const text = month + ' ' + String(year)
        const previousYear = month + ' ' + String(year - 1)
        svgChart.formattedNumber(
            { x: 0.5 * svgChart.width, y: 0.3 * svgChart.height },
            0.2 * svgChart.height,
            {
                text,
                color: memoizedInvertColor(colorHexa),
                font: memoryInstance.fontFamily,
            }
        )
        svgChart.formattedNumber(
            { x: 0.5 * svgChart.width, y: 0.6 * svgChart.height },
            0.2 * svgChart.height,
            {
                text: 'vs ' + previousYear,
                color: memoizedInvertColor(colorHexa),
                font: memoryInstance.fontFamily,
            }
        )
        return svgChart.svg({
            style: {
                background: colorHexa, // ? weird?
            },
            preserveAspectRatio: {
                x: 'Mid',
                y: 'Mid',
                suffix: 'meet',
            },
            onlyContainer: this.globalState.onlyContainer,
            classes: this.classes,
            id: this.id,
        })
    }
}

export class KpiChart extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.KPI, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        if (this.globalState.onlyContainer)
            return SVGChart.onlyContainer(this.id, this.classes)
        const label = this.getStringRequiredAttribute(TraitId.LABEL)

        const lines = LineRow.fromDb(label)

        // TODO: get values from memory
        const randomValue = getRandomValues()

        const measureIds = this.getStringRequiredAttribute(TraitId.MEASURE)

        const measureIdsArray = measureIds.split(';')

        const height = this.ratios.height || 100
        const width = this.ratios.width || 100
        const reduceSize =
            100 / (this.getNumberAttribute(TraitId.LABEL_SIZE) || 100)
        const svg = LineRow.toPreviewSvg(lines, {
            ...randomValue,
            reduceSize,
            height,
            width,
            isMiddle: true,
            measureIds: measureIdsArray,
        })

        return `<svg id="${
            this.id
        }" viewBox="0 0 ${width} ${height}" preserveAspectRatio="xMidYMid meet" class="${this.classes.join(
            ' '
        )}" style="width: 100%; height: 100%; fill: ${memoizedInvertColor(
            this.globalState.colors.background
        )}">${svg}</svg>`
    }
}
