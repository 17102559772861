import { ChartType, TraitId } from 'ponychart'
import { Measure, MeasureAggregation } from '@/ponychart/measure/types'
import { Dimension } from '@/ponychart/dimension/types'
import { DataType } from '@/ponychart/types'
import { TABLEAU_COLOR_VALUES } from '@/ponychart/utils'

import { TdPeriod } from './types'

export const TD_PERIODS: TdPeriod[] = [
    TdPeriod.MTD,
    TdPeriod.WTD,
    TdPeriod.QTD,
    TdPeriod.YTD,
]

export const NO_PRISTINE: TraitId[] = [
    // TraitId.BLOCK_TITLE,
    // TraitId.BLOCK_HAS_TIME_INDICATION
]

const MEASURE_NAMES = [
    'Revenue',
    'Margin',
    'Conversion',
    'Total',
    'Customers',
    'Clients',
    'Traffic',
    'Bounces',
]
const DIMENSION_NAMES = [
    'Category',
    'Product',
    'Service',
    'Country',
    'City',
    'Region',
    'Zipcode',
    'Group',
]

export const FREE_CHART_TYPES = [
    ChartType.KPI,
    ChartType.LINE,
    ChartType.BAR,
    ChartType.NONE,
]

export function defaultMeasureArray(
    opts: { length?: number; measureNames?: string[] } = {}
): Measure[] {
    const length = opts.length || 6
    const measureNames = opts.measureNames || MEASURE_NAMES
    const shuffled = [...measureNames]
        .sort(() => 0.5 - Math.random())
        .splice(0, length)
    return shuffled.map((alias: string, i: number) => ({
        id: String(i),
        columnId: String(i),
        alias,
        agg: MeasureAggregation.SUM,
        type: DataType.INTEGER,
        filterOperation: 'AND',
        formatId: '',
        id1: undefined,
        id2: undefined,
        filters: [],
        reversed: false,
        pristine: true,
        color: TABLEAU_COLOR_VALUES[i % TABLEAU_COLOR_VALUES.length],
        twbIdx: 0,
    }))
}

export function defaultDimensionArray(
    opts: { length?: number; dimensionNames?: string[] } = {}
): Dimension[] {
    const length = opts.length || 6
    const names = opts.dimensionNames || DIMENSION_NAMES
    const shuffled = [...names]
        .sort(() => 0.5 - Math.random())
        .splice(0, length)
    return shuffled.map((alias: string, i: number) => ({
        id: String(i),
        columnId: String(i),
        alias,
        type: DataType.TEXT,
        calculations: [],
        errors: [],
        pristine: true,
        twbIdx: 0,
    }))
}

export const TRAIT_OPTION_KEYS = [
    'id',
    'alias',
    'name',
    'format',
    'reversed',
    'disabled',
    'twbIdx',
]

export const TRAIT_PRIORITY = [
    TraitId.CHART_1,
    TraitId.CHART_2,
    TraitId.CHART_3,
    TraitId.CHART_4,
    TraitId.CHART_5,
    TraitId.INCLUDE_CARD_TITLE,
    TraitId.CARD_TITLE,
    TraitId.CARD_HEIGHT,
    TraitId.COLUMN_COUNT,
    TraitId.MEASURE,
    TraitId.MEASURE_2,
    TraitId.DIMENSION,
    TraitId.DIMENSION_2,
    TraitId.PAGE_MODE,
    TraitId.COLOR,
    TraitId.DATE_AGGREGATION_LEVEL,
    TraitId.LABEL,
    TraitId.TOOLTIP,
    TraitId.BLOCK_TITLE,
    TraitId.BLOCK_HAS_TIME_INDICATION,
    TraitId.CHART_SUBTYPE,
    TraitId.GEO_COUNTRY,
    TraitId.GEO_REGION,
    TraitId.GEO_CITY,
    TraitId.LABEL_SIZE,
    TraitId.ALIGN_VERTICALLY,
    TraitId.INCLUDES_CHART_1,
    TraitId.INCLUDES_CHART_2,
    TraitId.INCLUDES_CHART_3,
    TraitId.INCLUDES_CHART_4,
]

export const ICONS = {
    [TraitId.CARD_TITLE]: 'text_fields',
    [TraitId.CARD_HEIGHT]: 'straighten',
    [TraitId.HEADER_HEIGHT]: 'straighten',
    [TraitId.LABEL_SIZE]: 'straighten',
    [TraitId.WITH_LOGO]: 'image',
    [TraitId.WITH_TIME_PERIOD]: 'mdi-calendar',
    [TraitId.WITH_COLORS]: 'palette',
    [TraitId.DENSIFY_HEADER_FILTERS]: 'filter_alt',
    [TraitId.CHART_1]: 'mdi-chart-line',
    [TraitId.CHART_2]: 'mdi-chart-line',
    [TraitId.CHART_3]: 'mdi-chart-line',
    [TraitId.CHART_4]: 'mdi-chart-line',
    [TraitId.CHART_5]: 'mdi-chart-line',
    [TraitId.BLOCK_TITLE]: 'text_fields',
    [TraitId.DATE_AGGREGATION_LEVEL]: 'mdi-calendar',
    [TraitId.DIMENSION]: 'stacked_bar_chart',
    [TraitId.MEASURE]: 'insights',
    [TraitId.CHART_SUBTYPE]: 'settings_suggest',
    [TraitId.ALIGN_VERTICALLY]: 'vertical_align_bottom',
    [TraitId.COLOR]: 'palette',
    [TraitId.COLUMN_COUNT]: 'view_week',
    [TraitId.MEASURE_COLOR]: 'palette',
    [TraitId.GEO_CITY]: 'location_city',
    [TraitId.GEO_REGION]: 'mdi-earth-box',
    [TraitId.GEO_COUNTRY]: 'public',
    [TraitId.LABEL]: 'numbers',
    [TraitId.TOOLTIP]: 'textsms',
}

export const FONTS = [
    'Arial',
    'Arial Black',
    'Bahnschrift',
    'Bahnschrift Condensed',
    'Bahnschrift Light',
    'Bahnschrift Light Condensed',
    'Bahnschrift Light SemiCondensed',
    'Bahnschrift SemiBold',
    'Bahnschrift SemiBold Condensed',
    'Bahnschrift SemiBold Semiconden',
    'Bahnschrift SemiCondensed',
    'Bahnschrift SemiLight',
    'Bahnschrift SemiLight Condensed',
    'Bahnschrift SemiLight Semiconde',
    'Benton Sans Book',
    'Benton Sans SemiDemi',
    'Calibri',
    'Calibri Light',
    'Cambria',
    'Cambria Math',
    'Candara',
    'Candara Light',
    'Comic Sans MS',
    'Consolas',
    'Constantia',
    'Corbel',
    'Corbel Light',
    'Courier New',
    'Ebrima',
    'Franklin Gothic Medium',
    'Gabriola',
    'Gadugi',
    'Georgia',
    'HoloLens MDL2 Assets',
    'Impact',
    'Ink Free',
    'Javanese Text',
    'Leelawadee UI',
    'Leelawadee UI Semilight',
    'Lucida Console',
    'Lucida Sans Unicode',
    'MS Gothic',
    'MS PGothic',
    'MS Shell Dlg 2',
    'MS UI Gothic',
    'MV Boli',
    'Malgun Gothic',
    'Malgun Gothic Semilight',
    'Microsoft Himalaya',
    'Microsoft JhengHei',
    'Microsoft Jheng Hei Light',
    'Microsoft JhengHei UI',
    'Microsoft JhengHei UI Light',
    'Microsoft New Tai Lue',
    'Microsoft PhagsPa',
    'Microsoft Sans Serif',
    'Microsoft Tai Le',
    'Microsoft YaHei',
    'Microsoft YaHei Light',
    'Microsoft YaHei UI',
    'Microsoft YaHei UI Light',
    'Microsoft Yi Baiti',
    'Modern',
    'Mongolian Baiti',
    'Myanmar Text',
    'NSimSun',
    'Nirmala UI',
    'Nirmala UI Semilight',
    'Palatino Linotype',
    'Segoe MDL2 Assets',
    'Segoe Print',
    'Segoe Script',
    'Segoe UI',
    'Segoe UI Black',
    'Segoe UI Emoji',
    'Segoe UI Historic',
    'Segoe UI Light',
    'Segoe UI Semibold',
    'Segoe UI Semilight',
    'Segoe UI Symbol',
    'SimSun',
    'SimSun-ExtB',
    'Sitka',
    'Sitka Banner',
    'Sitka Display',
    'Sitka Heading',
    'Sitka Small',
    'Sitka Subheading',
    'Sitka Text',
    'Sylfaen',
    'Tableau Bold',
    'Tableau Book',
    'Tableau Light',
    'Tableau Medium',
    'Tableau Regular',
    'Tableau Semibold',
    'Tahoma',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana',
    'Yu Gothic',
    'Yu Gothic Light',
    'Yu Gothic Medium',
    'Yu Gothic UI',
    'Yu Gothic UI Light',
    'Yu Gothic UI Semibold',
    'Yu Gothic UI Semilight',
]
