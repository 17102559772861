<template lang="pug">
v-dialog(v-if='editor', v-model='show', max-width='1100', persistent)
    template(v-slot:activator='{ on, attrs }')
        v-row.my-1(align="center" justify="start")
            .mx-2.mt-1.pl-2
                slot(name='prepend')
            div(style="position: relative; max-width: 80%")
                .preview.mention(
                    v-html='preview'
                    :data-dark='isDark ? "true" : "false"'
                    :style="{ background, 'minWidth': '200px', 'minHeight': '30px', color }"
                    @click="show = true"
                )
                label.v-label.preview-label.px-1 {{ label }}
            v-btn.ml-2(
                color='grey',
                icon
                small,
                outlined,
                v-bind='attrs',
                v-on='on'
            )
                v-icon(small) edit
            //- v-btn(icon @click="refresh")
            //-     v-icon refresh
                //- span {{ $t(`${traitId}Edit`) }}
    v-card(width='1100')
        v-card-title
            v-toolbar-title {{ $t(`${traitId}Editor`, { chart: translatedChart }) }}
            v-spacer
            v-btn(icon, @click='show = false')
                v-icon mdi-close
        v-card-text
            v-row
                v-col.ma-0(cols='7')
                v-col(cols='5')
                    v-row
                        label.ma-0.pa-0 {{ $t("examples") }}
                        v-spacer
                        v-btn(
                            v-for='h in centeringOptions.filter((_) => !hasCenteringOptions)',
                            icon,
                            :key='h',
                            tile,
                            @click='(alignment = h), listTextElements()',
                            :outlined='alignment === h'
                        )
                            v-icon {{ "format_align_" + h }}
                        v-spacer
                        label.ma-0.pa-0(style='visibility: hidden') {{ $t("examples") }}
            v-row
                v-col.ma-0.ma-0.py-0.px-1(cols='7', style='max-height: 400px')
                    tiptap-menu-bar.editor__header(:editor='editor')
                    .v-input.v-textarea.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--is-booted.v-text-field--enclosed.pt-3(
                    )
                        .v-input__control
                            .v-input__slot.pl-0
                                editor-content.v-text-field__slot(
                                    :editor='editor'
                                    :data-dark='isDark ? "true" : "false"'
                                    :style="{background, color}"
                                )
                    label {{ $t("preview") }}
                    .preview.mention(v-html='preview' :style="{background, color }")

                v-col.px-1.mt-1(
                    cols='5',
                    style='max-height: 400px; overflow-y: auto; overflow-x: hidden'
                )
                    tiptap-element-preview.mx-1(
                        v-for='(textElement, i) in textElements',
                        :text-element='textElement',
                        :background="background"
                        :data-dark='isDark ? "true" : "false"'
                        :color="color"
                        :key='textElement.labelId',
                        @click='setContent(textElement)',
                        :class='textElement.labelId === selectedLabelId && "border-primary"'
                    )
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'
import TextStyle from '@tiptap/extension-text-style'
// import Color from '@tiptap/extension-color'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import { Editor, EditorContent } from '@tiptap/vue-2'

import TiptapMenuBar from '@/components/utils/TiptapMenuBar.vue'
import TiptapElementPreview from '@/components/utils/TiptapElementPreview.vue'
import tiptapSuggestion from './tiptapSuggestion'
import {
    LineRow,
    TextElementFactoryV2,
    TextElementMode,
    getRandomValues,
    TEXT_ELEMENT_TYPES,
    t,
    TextElementParser,
    SourceMemory,
} from '@/ponychart'
import { TraitId } from 'ponychart'

const textElementFactory = new TextElementFactoryV2()
const textElementParser = new TextElementParser()
const memoryInstance = SourceMemory.getInstance()

export default {
    name: 'tiptapComponent',
    props: {
        value: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => TEXT_ELEMENT_TYPES.map((id) => ({ id, alias: id })),
        },
        traitId: {
            type: String,
            default: TraitId.TOOLTIP,
        },
        chartType: {
            type: String,
            default: '',
        },
        fromPageEditBlock: {
            type: Boolean,
            default: false,
        },
        hasDimension: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
    },
    components: {
        EditorContent,
        TiptapMenuBar,
        TiptapElementPreview,
    },
    data() {
        return {
            editor: null,
            show: false,
            textElements: [],
            randomValues: getRandomValues(),
            preview: '',
            selectedLabelId: '',
            alignment: 'left',
            centeringOptions: ['left', 'center', 'right'],
            pristine: true,
        }
    },
    computed: {
        isDark() {
            if (this.traitId === TraitId.TOOLTIP) return false
            if (this.traitId === TraitId.LABEL)
                return memoryInstance.isDarkLabel
            if (this.traitId === TraitId.CARD_TITLE)
                return memoryInstance.isDarkTitle
            return false
        },
        background() {
            if (this.traitId === TraitId.TOOLTIP) return '#FFFFFF'
            if (this.traitId === TraitId.LABEL)
                return memoryInstance.colors.background
            if (this.traitId === TraitId.CARD_TITLE)
                return memoryInstance.colors.title
            return '#FFFFFF'
        },
        color() {
            return this.isDark ? 'white' : 'black'
        },
        translatedChart() {
            return this.chartType ? t('charts.' + this.chartType) : ''
        },
        hasCenteringOptions() {
            if (this.isTooltip) return false
            return true
        },
        isTooltip() {
            return this.traitId === TraitId.TOOLTIP
        },
        defaultAlignment() {
            return this.isTooltip ? 'left' : 'center'
        },
        labelIds() {
            return this.items.map((i) => i.id)
        },
        textElementTypes() {
            const textElementTypeSet = new Set()
            for (const labelId of this.labelIds) {
                const textElementTypes =
                    textElementFactory.listTextElementTypesForLabelId(labelId, {
                        hasDimension: this.hasDimension,
                    })
                for (const textElementType of textElementTypes)
                    textElementTypeSet.add(textElementType)
            }
            return Array.from(textElementTypeSet)
        },
    },
    methods: {
        getRandomValues() {
            if (Math.random() < 0.1) {
                this.randomValues = getRandomValues()
            }
            return this.randomValues
        },
        listTextElements() {
            const opts = {
                reduceSize: 2,
                alignment: this.alignment,
                measureIds: memoryInstance.fakeMeasureIds,
                dimensionIds: memoryInstance.fakeDimensionIds,
            }
            this.textElements = this.labelIds.map((labelId) => ({
                labelId,
                calculation: textElementFactory.buildHtml(
                    labelId,
                    { hasDimension: this.hasDimension },
                    TextElementMode.CALCULATION,
                    opts
                ),
                preview: textElementFactory.buildHtml(
                    labelId,
                    { hasDimension: this.hasDimension },
                    TextElementMode.PREVIEW,
                    opts
                ),
            }))
        },
        refresh() {
            this.refreshPreview(this.dbToHtml(this.value))
        },
        setContent(textElement) {
            this.pristine = false
            this.selectedLabelId = textElement.labelId
            this.editor.commands.setContent(textElement.calculation, false)
            this.refreshPreview(this.editor.getHTML())
        },
        emitInput(context) {
            const lines = textElementParser.parse(context)
            this.$emit('input', JSON.stringify(lines.map((l) => l.toDb())))
        },
        refreshPreview(htmlValue) {
            const lines = textElementParser.parse(htmlValue)
            const opts = {
                reduceSize: 2,
                measureIds: memoryInstance.fakeMeasureIds,
                dimensionIds: memoryInstance.fakeDimensionIds,
            }
            this.preview = LineRow.toHtml(lines, TextElementMode.PREVIEW, opts)
        },
        htmlToDb(html) {
            const lines = textElementParser.parse(html)
            return JSON.stringify(lines.map((l) => l.toDb()))
        },
        dbToHtml(db) {
            try {
                const lines = LineRow.fromDb(db)
                return LineRow.toHtml(
                    lines,
                    TextElementMode.CALCULATION,
                    this.getRandomValues()
                )
            } catch (e) {
                console.warn('Error parsing db', e)
                return ''
            }
        },
    },
    mounted() {
        this.listTextElements()
        const htmlValue = this.dbToHtml(this.value)
        this.editor = new Editor({
            extensions: [
                StarterKit.configure({
                    heading: {
                        HTMLAttributes: {
                            class: 'heading',
                        },
                    },
                }),
                Underline,
                // Color,
                TextStyle,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                    alignments: ['left', 'center', 'right'],
                }),
                Mention.configure({
                    HTMLAttributes: {
                        class: 'mention',
                    },
                    renderLabel({ options, node }) {
                        return `${options.suggestion.char}${
                            node.attrs.label ?? node.attrs.id
                        }]`
                    },
                    suggestion: tiptapSuggestion(this.textElementTypes),
                }),
            ],
            onUpdate: async () => {
                // HTML
                this.pristine = false
                const html = this.editor.getHTML()
                this.refreshPreview(html)

                // JSON
                // this.$emit('input', this.editor.getJSON())
            },

            content: htmlValue,
        })
        this.refreshPreview(htmlValue)
    },
    beforeUnmount() {
        this.editor.destroy()
    },
    watch: {
        show: {
            handler: function (show) {
                if (show) {
                    this.pristine = true
                    this.alignment = this.defaultAlignment
                    this.listTextElements()
                } else if (this.editor && !this.pristine) {
                    const html = this.editor.getHTML()
                    this.emitInput(html)
                }
            },
            immediate: true,
        },
        alignment() {
            this.listTextElements()
        },
        value(value) {
            // HTML
            const html = this.editor.getHTML()
            const lineRows = textElementParser.parse(html)
            const isSame =
                JSON.stringify(lineRows.map((l) => l.toDb())) == value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
            if (isSame) return

            this.editor.commands.setContent(this.dbToHtml(this.value), false)
            this.refreshPreview(this.dbToHtml(this.value))
        },
    },
}
</script>

<style scoped>
.v-text-field__slot p {
    margin-bottom: 0 !important;
}
h1 {
    font-weight: normal;
}
h2 {
    font-weight: normal;
}
h3 {
    font-weight: normal;
}
h4 {
    font-weight: normal;
}
h5 {
    font-weight: normal;
}
h6 {
    font-weight: normal;
}
.heading {
    font-weight: normal;
}
.border-primary {
    border: 2px solid #673ab7;
}
</style>
<style lang="scss">
.ProseMirror {
    width: 100%;
    padding: 4px;
    padding-top: 8px;
    min-height: 200px;
    > * {
        margin-top: 0;
        margin-bottom: 0 !important;
    }
    background: 'red';
}
.heading {
    font-weight: normal;
}

.options {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    box-decoration-break: clone;
}
.preview-label {
    position: absolute;
    top: -5px;
    left: 10px;
    background: white;
    border-radius: 2px;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.6);
}
.preview {
    border: 1px solid rgba(0, 0, 0, 0.4);
    cursor: pointer;
    border-radius: 4px;
}
.mention {
    border: 1px solid #eee;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
}
[data-dark='false'] [data-id='Dimensión'],
[data-dark='false'] [data-id='Dimension'],
[data-dark='false'] [data-id*='Periodo'],
[data-dark='false'] [data-id*='Period'],
[data-dark='false'] [data-id*='Période'],
[data-dark='false'] [data-id*='Fecha'],
[data-dark='false'] [data-id*='Date'] {
    color: #4996b2;
}
[data-dark='true'] [data-id='Dimensión'],
[data-dark='true'] [data-id='Dimension'],
[data-dark='true'] [data-id*='Periodo'],
[data-dark='true'] [data-id*='Period'],
[data-dark='true'] [data-id*='Période'],
[data-dark='true'] [data-id*='Fecha'],
[data-dark='true'] [data-id*='Date'] {
    color: #b6d5e1;
}
[data-dark='false'] [data-id*='Mesure'],
[data-dark='false'] [data-id*='Medida'],
[data-dark='false'] [data-id*='Measure'],
[data-dark='false'] [data-id='City'],
[data-dark='false'] [data-id='Ciudad'],
[data-dark='false'] [data-id='Ville'],
[data-dark='false'] [data-id='Region'],
[data-dark='false'] [data-id='Región'],
[data-dark='false'] [data-id='Région'],
[data-dark='false'] [data-id='Country'],
[data-dark='false'] [data-id='Pays'],
[data-dark='false'] [data-id='País'] {
    color: #00b180;
}
[data-dark='true'] [data-id*='Mesure'],
[data-dark='true'] [data-id*='Medida'],
[data-dark='true'] [data-id*='Measure'],
[data-dark='true'] [data-id='City'],
[data-dark='true'] [data-id='Ciudad'],
[data-dark='true'] [data-id='Ville'],
[data-dark='true'] [data-id='Region'],
[data-dark='true'] [data-id='Región'],
[data-dark='true'] [data-id='Région'],
[data-dark='true'] [data-id='Country'],
[data-dark='true'] [data-id='Pays'],
[data-dark='true'] [data-id='País'] {
    color: #7affda;
}
[data-dark='false'] [data-id*='Choice'],
[data-dark='false'] [data-id*='Choix'],
[data-dark='false'] [data-id*='Elección'] {
    color: #936eb0;
}
[data-dark='true'] [data-id*='Choice'],
[data-dark='true'] [data-id*='Choix'],
[data-dark='true'] [data-id*='Elección'] {
    color: #d4c5df;
}
[data-id*='coloré'] {
    animation: color-change 3s infinite;
}
[data-id*='colored'] {
    animation: color-change 3s infinite;
}
[data-id*='colorada'] {
    animation: color-change 3s infinite;
}

@keyframes color-change {
    0% {
        color: #d1615d;
    }
    24% {
        color: #d1615d;
    }
    25% {
        color: #5778a4;
    }
    74% {
        color: #5778a4;
    }
    75% {
        color: #d1615d;
    }
    100% {
        color: #d1615d;
    }
}
</style>

<i18n>{
  "en": {
    "examples": "Examples",
    "tooltipEdit": "Edit tooltip",
    "labelEdit": "Edit label",
    "labelEditor": "Label editor for {chart}",
    "tooltipEditor": "Tooltip editor for {chart}",
    "card_titleEdit": "Edit title",
    "card_titleEditor": "Card title editor"
  },
  "fr": {
    "examples": "Exemples",
    "tooltipEdit": "Modifier l'infobulle",
    "labelEdit": "Modifier l'étiquette",
    "labelEditor": "Éditeur de libellés pour {chart}",
    "tooltipEditor": "Éditeur d'infobulles pour {chart}",
    "card_titleEdit": "Modifier le titre",
    "card_titleEditor": "Éditeur de titre de carte"
  },
  "es": {
    "examples": "Ejemplos",
    "tooltipEdit": "Editar tooltip",
    "labelEdit": "Editar etiqueta",
    "labelEditor": "Editor de etiquetas para el {chart}",
    "tooltipEditor": "Editor de tooltips para el {chart}",
    "card_titleEdit": "Editar título",
    "card_titleEditor": "Editor de título de tarjeta"
  }
}</i18n>
