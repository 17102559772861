
import { traitKey, PageBlock, GlobalState } from '@/ponychart'
import {
    TraitId,
    TraitOptionType,
    DeviceType,
    Trait,
    traitToQuerySelectorTag,
} from 'ponychart'

import { Pages, GlobalMixins, Traits } from '@/mixins'

import ShowMoreBtn from '@/components/utils/ShowMoreBtn.vue'
import PageTrait from '@/components/Pages/PageTrait.vue'
import DatasourcePill from '@/components/utils/DatasourcePill.vue'

import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { DEFAULT_COLORS } from '@/ponychart/element/config'
import { TraitSearch } from '@/ponychart/state/traits'

@Component({
    components: {
        PageTrait,
        ShowMoreBtn,
        DatasourcePill,
    },
})
export default class PageEditBlock extends mixins(GlobalMixins, Pages, Traits) {
    @Prop({ type: Object, required: true })
    readonly pageBlock!: PageBlock
    @Prop({ type: Number, default: 0 }) readonly length!: number
    @Prop({ type: Boolean, default: false }) readonly loading!: boolean
    @Prop({ type: Number, required: true })
    readonly pageBlockIndex!: number
    @Prop({ type: Number, required: true })
    readonly pageBlockLength!: number
    @Prop({ type: String, required: true })
    readonly deviceType!: DeviceType
    @Prop({ type: Array, required: true })
    readonly sourceTraits!: Trait[]
    @Prop({ type: Array, required: true })
    readonly twbDatasources!: { id: string; alias: string; color: string }[]
    @Prop({ type: Boolean, default: false })
    readonly multipleCharts!: boolean

    show = false
    showAdditionalChart = true
    showSecondaryChart = [true, true, true]
    globalState: GlobalState | null = null
    traitSearch: TraitSearch | null = null

    get hasHiddenTraits() {
        return this.pageBlock.traits.filter(this.hiddenTrait).length > 0
    }
    get cols() {
        if (this.chartTraits.length === 2) return 6
        if (this.chartTraits.length === 4) return 3
        return 4
    }
    get key() {
        return (
            this.pageBlock?.traits.find((t) => t.id === TraitId.CHART_1)
                ?.value || 'unknown'
        )
    }
    get chartTraits() {
        const output: Trait[][] = []
        for (const trait of this.pageBlock.traits) {
            const id = Math.max(...(trait.querySelectorTags || [0]))
            if (id !== -1) {
                if (id > 0 && trait.id !== 'measure') {
                    while (id > output.length) {
                        output.push([])
                    }
                    output[id - 1].push(trait)
                }
            }
        }
        return output
    }

    hover(value: boolean) {
        this.$emit('hover', { id: this.pageBlock.id, value })
    }
    traitKey(trait: Trait) {
        return [
            traitKey(trait),
            this.pageBlock.id,
            trait.deviceType || '',
            trait.type,
        ].join('|')
    }
    chartClicked(pageBlockId: string, traitId: string) {
        const querySelectorTag = traitToQuerySelectorTag(traitId as TraitId)
        this.$emit('chartClicked', {
            querySelectorTag,
            pageBlockId,
        })
    }
    isCurrentDeviceTypeTrait(trait: Trait) {
        return (trait.deviceType || DeviceType.DESKTOP) === this.deviceType
    }
    visibleTrait(trait: Trait) {
        const querySelectorTags = trait.querySelectorTags || [0]
        return (
            this.isCurrentDeviceTypeTrait(trait) &&
            querySelectorTags.includes(0) &&
            !trait.hidden
        )
    }
    hiddenTrait(trait: Trait) {
        const querySelectorTags = trait.querySelectorTags || [0]
        return (
            this.isCurrentDeviceTypeTrait(trait) &&
            querySelectorTags.includes(0) &&
            trait.hidden
        )
    }

    async set(trait: Trait, value: string) {
        this.$emit('pristine', false)
        trait.value = value
        trait.pristine = false
        if (!this.traitSearch) return
        this.traitSearch.multipleCharts = this.multipleCharts
        this.refreshPageTraits(this.pageBlock, this.traitSearch, trait)
    }

    mounted() {
        this.globalState = new GlobalState({
            colors: DEFAULT_COLORS,
            deviceType: this.deviceType,
            twbIdx: this.pageBlock.twbIdx,
        })
        this.traitSearch = TraitSearch.createInstance(
            this.sourceTraits,
            this.pageBlock.traits,
            this.globalState
        )
    }
}
