
import { GlobalMixins } from '@/mixins'
import Component, { mixins } from 'vue-class-component'

import Vibrant from 'node-vibrant'
import draggable from 'vuedraggable'

import DashboardPreferenceService from '@/services/dashboardPreferenceService'
import LogoService from '@/services/logoService'

import HeaderEditor from '@/components/Header/HeaderEditor.vue'
import HeaderPreview from '@/components/Header/HeaderPreview.vue'
import ButtonsMoveEdit from '@/components/Pages/ButtonsMoveEdit.vue'
import Page from '@/components/Pages/Page.vue'
import PageTrait from '@/components/Pages/PageTrait.vue'

import Upload from '@/components/utils/Upload.vue'
import Tour from '@/components/utils/Tour.vue'
import DeviceTypeSwitch from '@/components/utils/DeviceTypeSwitch.vue'
import SidebarDesignPreview from '@/components/utils/SidebarDesignPreview.vue'
import CardDesignPreview from '@/components/utils/CardDesignPreview.vue'

import {
    buildRandomPage,
    Page as _Page,
    DashboardPreference,
    SIDEBAR_ITEMS,
    SidebarPayload,
    Logo,
    COLOR_SUGGESTIONS,
    SELECT_PIXEL_ITEMS,
    invertColor,
    multiplyBy,
    DEFAULT_COLORS,
    divideBy,
    SourceColors,
    Source,
    MAXIMUM,
    GlobalOptions,
    StylingMode,
    SourceMode,
} from '@/ponychart'
import {
    TraitId,
    TraitOptionType,
    DeviceType,
    Trait,
    RawAccessRights,
    DEFAULT_RAW_ACCESS_RIGHTS,
    STYLING_TRAIT_IDS,
    SidebarType,
} from 'ponychart'

import { Prop, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { Traits } from '@/mixins'
import { TraitSearch, SimpleTraitSearch } from '@/ponychart/state/traits'

type CurrentKey = keyof SourceColors

@Component({
    components: {
        Page,
        draggable,
        ButtonsMoveEdit,
        HeaderEditor,
        HeaderPreview,
        Upload,
        Tour,
        PageTrait,
        DeviceTypeSwitch,
        SidebarDesignPreview,
        CardDesignPreview,
    },
})
export default class StylingComponent extends mixins(GlobalMixins, Traits) {
    @Prop({ type: String, required: false, default: StylingMode.CLASSIC })
    readonly stylingMode!: StylingMode
    @Prop({
        type: Object,
        required: false,
        default: () => cloneDeep(DEFAULT_RAW_ACCESS_RIGHTS),
    })
    readonly rawAccessRights!: RawAccessRights
    @Prop({ type: Object, required: false })
    readonly source?: Source
    @Prop({ type: String, default: SourceMode.CLASSIC })
    readonly sourceMode!: SourceMode
    @Prop({ type: Number, default: 0 })
    readonly sourceId!: number

    dashboardPreferences: DashboardPreference[] = []
    dashboardPreferenceIndex = -1
    colorClickedIndex = 0
    sidebarIndex = 0
    mainReduceSize = 2.3
    event = false
    events: boolean[] = []
    dashboardPreferenceLoading = false
    dashboardPreferenceLoadings: string[] = []
    pageLoading = false
    logoLoading = false
    editTitle = false
    page: _Page | null = null
    logos: Logo[] = []
    tmt: number | null = null
    aliasTmt: number | null = null
    eventTmt: number | null = null
    saveDashboardPreferenceTmt: { [k: string]: number } = {}
    dashboardPreference: DashboardPreference | null = null
    colorSuggestions: SourceColors[] = []
    pristines: { [k: string]: boolean } = {}
    deviceType: DeviceType = DeviceType.DESKTOP

    sourceTraits: Trait[] = []

    get isAdminMode() {
        return this.stylingMode === StylingMode.ADMIN
    }

    get isClassicMode() {
        return this.stylingMode === StylingMode.CLASSIC
    }

    get isDemoMode() {
        return this.sourceMode === SourceMode.DEMO
    }

    get companyId() {
        return this.$store.state.company?.id
    }

    get maximumPages() {
        return MAXIMUM.pages
    }
    async onDashboardPreferenceChange() {
        this.refreshDashboardPreferenceTraits()
        await this.$nextTick()
        this.refreshSidebarPayload()
    }
    get sliderTraits() {
        return this.sourceTraits.filter(
            (trait) =>
                trait.type === TraitOptionType.SLIDER &&
                STYLING_TRAIT_IDS.includes(trait.id)
        )
    }
    get selectTraits() {
        return this.sourceTraits.filter(
            (trait) =>
                trait.type === TraitOptionType.SELECT &&
                STYLING_TRAIT_IDS.includes(trait.id)
        )
    }

    get colors(): SourceColors {
        if (!this.dashboardPreference) return DEFAULT_COLORS
        return this.dashboardPreference.colors
    }

    dashboardPreferenceFromSource() {
        const source = this.source
        if (!source) return
        if (this.isAdminMode) return
        this.dashboardPreference = {
            alias: 'custom',
            id: '(not set)',
            isDefaultFor: [],
            isHiddenFor: [],
            logo: {
                ...(source.logo || { id: '', url: '' }),
            },
            traits: source.traits || [], // Random Traits
            colors: source.colors as SourceColors,
            lockDeviceType: source.lockDeviceType || [],
            order: -1,
        }
        this.onDashboardPreferenceChange()
        this.currentColor = source?.colors?.[this.currentKey] || '#fff'

        this.makeColorItems()
        this.onColorEvent()
    }

    async addDashboardPreference() {
        if (!this.isAdminMode) return
        if (!this.dashboardPreference || !this.dashboardPreferenceName) return
        this.dashboardPreferenceLoading = true
        const order = this.dashboardPreferences.reduce(
            (acc: number, value: DashboardPreference) =>
                Math.max(value.order, acc),
            0
        )
        await DashboardPreferenceService.createDashboardPreference({
            ...cloneDeep(this.dashboardPreference),
            alias: this.dashboardPreferenceName,
            order: order + 1,
            logo: this.dashboardPreference?.logo || { id: '', url: '' },
        })
            .then((d: DashboardPreference) => {
                if (this.dashboardPreferenceIndex === -1) {
                    this.dashboardPreferences.push(d)
                    this.events.push(false)
                } else {
                    this.dashboardPreferences.splice(
                        this.dashboardPreferenceIndex,
                        0,
                        d
                    )
                    this.events.splice(this.dashboardPreferenceIndex, 0, false)
                }
                this.showAddDashboardPreference = false
            })
            .catch(() => {
                this.showToast({})
            })
        this.dashboardPreferenceLoading = false
    }

    async delayedUpdateDashboardPreference(
        opts: { delay?: number; id?: string } = {}
    ) {
        if (!this.dashboardPreference || !this.isAdminMode) return
        const delay = opts?.delay || 10000
        const id = opts?.id || this.dashboardPreference.id
        if (this.saveDashboardPreferenceTmt[id])
            clearTimeout(this.saveDashboardPreferenceTmt[id])
        this.saveDashboardPreferenceTmt[id] = setTimeout(
            () => {
                this.updateDashboardPreference(id)
            },
            delay,
            this
        )
    }

    async updateDashboardPreference(id: string) {
        if (!this.isAdminMode) return
        const dashboardPreference = this.dashboardPreferences.find(
            (d: any) => d.id === id
        )
        if (!dashboardPreference) return
        this.dashboardPreferenceLoadings.push(id)
        await DashboardPreferenceService.updateDashboardPreference(
            id,
            dashboardPreference
        )
        this.pristines[id] = true
        this.dashboardPreferenceLoadings =
            this.dashboardPreferenceLoadings.filter((i: string) => i !== id)
    }

    initPristine() {
        this.pristines = this.dashboardPreferences.reduce(
            (acc: { [k: string]: boolean }, d: DashboardPreference) => ({
                ...acc,
                [d.id]: true,
            }),
            {}
        )
    }
    tagPristine() {
        if (!this.dashboardPreference || !this.isAdminMode) return
        this.pristines[this.dashboardPreference.id] = false
    }

    async deleteDashboardPreference(idx: number) {
        if (!this.isAdminMode) return
        const id = this.dashboardPreferences[idx].id
        this.dashboardPreferenceLoadings.push(id)
        await DashboardPreferenceService.deleteDashboardPreference(id)
            .then(() => {
                this.dashboardPreferences = this.dashboardPreferences.filter(
                    (d) => d.id !== id
                )
            })
            .catch(() => {
                this.showToast({})
            })

        this.dashboardPreferenceLoadings =
            this.dashboardPreferenceLoadings.filter((i: string) => i !== id)
    }
    refreshPageBlocks() {
        if (!this.page) return
        const pageBlocks = this.page?.pageBlocks || []
        for (const pageBlock of pageBlocks) {
            const traitSearch = TraitSearch.createInstance(
                this.sourceTraits,
                pageBlock.traits,
                {
                    deviceType: this.deviceType,
                }
            )
            this.refreshPageTraits(pageBlock, traitSearch)
        }
    }
    async reloadPage() {
        this.pageLoading = true
        await new Promise((resolve) => setTimeout(resolve, 100))
        this.page = buildRandomPage(this.sourceTraits)
        this.refreshPageBlocks()

        this.buildRandomSourceTraits()
        this.event = !this.event
        this.pageLoading = false
    }
    buildRandomSourceTraits() {
        // TODO: fill up
        return
    }
    async wait(timeout = 2000) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(null)
            }, timeout)
        })
    }
    async getLogoUrl(
        logoId: string,
        opts = { attempts: 15, timeout: 2000 }
    ): Promise<string> {
        if (!this.dashboardPreference || this.isDemoMode) return ''
        try {
            this.dashboardPreference.logo = { id: '', url: '' }
            this.logoLoading = true
            const url = await LogoService.getLogoUploadUrl(logoId)
            this.logoLoading = false
            return url
        } catch (e) {
            if (!opts.attempts) throw new Error(e as string)
            await this.wait(opts.timeout)
            return this.getLogoUrl(logoId, {
                timeout: opts.timeout * 1.3,
                attempts: opts.attempts - 1,
            })
        }
    }
    delayedRefreshEvents() {
        if (this.eventTmt) clearTimeout(this.eventTmt)
        this.eventTmt = setTimeout(
            () => {
                this.refreshEvents()
            },
            100,
            this
        )
    }

    refreshEvents() {
        if (this.isAdminMode) {
            this.events[this.dashboardPreferenceIndex] =
                !this.events[this.dashboardPreferenceIndex]
        }
        this.event = !this.event
    }

    makeEvents() {
        this.events = this.dashboardPreferences.map(() => false)
    }

    async set(
        o: { [k: string]: any },
        opts: { context: any; setEvent?: boolean } = {
            context: this,
            setEvent: true,
        }
    ) {
        // Handle events from HeaderEditor.vue
        if (o?.source?.traits) {
            if (!this.dashboardPreference) return

            this.onDashboardPreferenceChange()
            this.delayedUpdateDashboardPreference({
                id: this.dashboardPreference?.id,
            })
            this.refreshEvents()
            return
        }
        for (const key in o) {
            if (typeof o[key] === 'object' && !Array.isArray(o[key])) {
                if (opts.context[key])
                    this.set(o[key], {
                        context: opts.context[key],
                        setEvent: false,
                    })
            } else {
                opts.context[key] = o[key]
            }
        }

        try {
            const keys = Object.keys(o)
            if (
                opts.setEvent &&
                keys.some((key) =>
                    ['dashboardPreference', 'header', 'logo'].includes(key)
                )
            ) {
                this.delayedUpdateDashboardPreference()
                this.tagPristine()
                if (this.tmt) clearTimeout(this.tmt)
                this.tmt = setTimeout(
                    () => {
                        if (this.isAdminMode) {
                            this.buildDashboardPreference()
                        }
                        this.refreshEvents()
                    },
                    100,
                    this
                )
            }
        } catch (e) {
            console.error(e)
            console.log(o)
        }
    }

    async sort(e: any) {
        if (!this.isAdminMode) return
        const { oldIndex, newIndex } = e as {
            oldIndex: number
            newIndex: number
        }
        this.events[Number(oldIndex)] = !this.events[Number(oldIndex)]
        this.events[Number(newIndex)] = !this.events[Number(newIndex)]
        if (this.dashboardPreferenceIndex === oldIndex) {
            this.dashboardPreferenceIndex = newIndex
        } else if (this.dashboardPreferenceIndex === newIndex) {
            this.dashboardPreferenceIndex = oldIndex
        }
        // Don't know why the UI requires this line
        this.dashboardPreferences = [...this.dashboardPreferences]
        await DashboardPreferenceService.updateOrder(
            this.dashboardPreferences.map(
                (d: DashboardPreference, i: number) => ({ id: d.id, order: i })
            )
        )
    }

    async listDashboardPreferences() {
        const dashboardPreferences =
            await DashboardPreferenceService.listDashboardPreferences({
                asAdmin: this.isAdminMode,
                asDemo: this.isDemoMode,
            })
        if (this.isDemoMode)
            this.dashboardPreferences = [dashboardPreferences[0]]
        else this.dashboardPreferences = dashboardPreferences
        this.$emit('set', { dashboardPreferences: this.dashboardPreferences })
        this.initPristine()
        this.makeEvents()
        if (this.isAdminMode) this.adaptIndexToDashboardPreferences()
    }
    async listLogos() {
        if (this.isDemoMode) {
            this.logos = []
            return
        }
        this.logos = await LogoService.listLogos({ withUrl: true })
    }

    adaptIndexToDashboardPreferences() {
        if (this.dashboardPreferences.length === 0) {
            this.dashboardPreferenceIndex = -1
        } else if (this.dashboardPreferenceIndex < 0) {
            this.dashboardPreferenceIndex = 0
        } else if (
            this.dashboardPreferenceIndex >= this.dashboardPreferences.length
        ) {
            this.dashboardPreferenceIndex = this.dashboardPreferences.length - 1
        }
    }

    @Watch('dashboardPreferenceIndex', { immediate: true })
    async onIndexChanged(_: number, oldIndex: number) {
        this.currentKey = 'background'
        this.buildDashboardPreference()
        await this.$nextTick()
        if (this.dashboardPreference)
            this.currentColor = this.dashboardPreference.colors.background

        if (
            (!!oldIndex || oldIndex === 0) &&
            oldIndex >= 0 &&
            oldIndex < this.dashboardPreferences.length
        ) {
            const oldId = this.dashboardPreferences[oldIndex].id
            if (!this.pristines[oldId])
                this.delayedUpdateDashboardPreference({ id: oldId, delay: 100 })
        }
        this.event = !this.event
    }

    buildDashboardPreference() {
        const index = this.dashboardPreferenceIndex
        const dashboardPreference =
            index >= 0 && index < this.dashboardPreferences.length
                ? this.dashboardPreferences[index]
                : null
        if (this.isAdminMode) {
            this.dashboardPreference = dashboardPreference
        } else {
            this.dashboardPreference = cloneDeep(dashboardPreference)
        }
        this.onDashboardPreferenceChange()
        this.onColorEvent()
        this.makeColorItems()
    }

    chartClicked(trait: Trait) {
        this.clearTour()
        if (trait.id !== TraitId.SIDEBAR) return
        this.showSidebarDesign = true
    }
    // START SIDEBAR DESIGN
    showSidebarDesign = false

    get sidebarDesignTrait() {
        return this.sourceTraits.find(
            (trait) => trait.id == TraitId.SIDEBAR_DESIGN
        )
    }

    // END SIDEBAR DESIGN

    // START BAND DESIGN
    showBandDesign = false

    get bandDesignTrait() {
        return this.sourceTraits.find((trait) => trait.id === TraitId.BANDS)
    }

    // END BAND DESIGN

    // START LOGO
    showLogoSuggestion = false
    onlyKeys(k: string) {
        return !!COLOR_SUGGESTIONS[0][k]
    }
    setColorFromPopup(colors: SourceColors, i: number) {
        for (const key in colors) {
            this.setColorByClassName(colors[key], key as CurrentKey)
        }
        this.set({ dashboardPreference: { colors } })
        this.colorClickedIndex = i
        this.onColorEvent()
    }
    setBorderColor(border: string) {
        if (!this.dashboardPreference) return
        this.set({
            dashboardPreference: {
                colors: { ...this.dashboardPreference.colors, border },
            },
        })
        this.onColorEvent()
    }
    sidebarClicked(e: number) {
        this.sidebarIndex = e
        this.event = !this.event
    }
    async onSuggestColorFromLogo() {
        if (!this.dashboardPreference?.logo?.id || this.isDemoMode) return
        this.clearTour()
        this.logoLoading = true
        try {
            const logoUrl = await LogoService.getLogoUploadUrl(
                this.dashboardPreference?.logo?.id
            )
            await Vibrant.from(logoUrl)
                .getPalette()
                .then((palette) => {
                    if (!this.dashboardPreference) return
                    this.colorSuggestions = [
                        { ...this.dashboardPreference.colors },
                    ]
                    for (const c of COLOR_SUGGESTIONS) {
                        const o: Partial<SourceColors> = {}
                        const hasColor = Object.keys(
                            this.dashboardPreference.colors
                        )
                            .filter(this.onlyKeys)
                            .every((k) => !!palette[c[k]]?.hex)
                        if (hasColor) {
                            Object.keys(
                                this.dashboardPreference.colors
                            ).forEach((k) => {
                                const nk = c[k]
                                if (k.includes('background')) o[k] = '#FFFFFF'
                                else
                                    o[k] = palette[nk]?.hex
                                        ? palette[nk]?.hex
                                        : this.dashboardPreference?.colors?.[k]
                            })
                            this.colorSuggestions.push(o as SourceColors)
                        }
                    }
                    this.event = !this.event
                    this.colorClickedIndex = 0
                    this.showLogoSuggestion = this.colorSuggestions.length > 1
                })
                .catch((e) => {
                    console.log(e.message)
                })
        } catch (e) {
            this.showToast()
        } finally {
            this.logoLoading = false
        }
    }
    // END LOGO

    // START LIST
    dashboardPreferenceName = ''
    dragOptions = {
        group: 'pages',
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    }
    showAddDashboardPreference = false

    get showDashboardPreferenceList() {
        return (
            this.page &&
            this.dashboardPreferences.length &&
            (this.isAdminMode || this.dashboardPreferences.length > 1)
        )
    }

    get dashboardPreferenceErrorMessages(): string[] {
        if (!this.dashboardPreferenceName.length) return []
        if (
            this.dashboardPreferences
                .map((d) => d.alias)
                .includes(this.dashboardPreferenceName)
        )
            return [String(this.$t('alreadyExists'))]
        return []
    }
    preferenceGlobalOptions(
        dashboardPreference: DashboardPreference
    ): GlobalOptions {
        return {
            navigation: true,
            colors: dashboardPreference.colors,
            logoUrl: dashboardPreference?.logo?.url,
        }
    }
    preferenceSidebar(sourceTraits: Trait[]): SidebarPayload {
        const sidebar = sourceTraits.find(
            (t) => t.id === TraitId.SIDEBAR
        )?.value
        return {
            sidebar: sidebar as any,
            index: this.sidebarIndex,
        }
    }
    // END LIST

    // START EDITOR
    largeItems: string[] = [
        ...SELECT_PIXEL_ITEMS.map((value: number) => multiplyBy(value, 5)),
    ]
    invert: SourceColors = {
        sidebar_primary: '#fff',
        sidebar_secondary: '#fff',
        background: '#fff',
        curves: '#fff',
        light_background: '#fff',
        border: '#fff',
        title: '#fff',
    }
    currentColor = '#f3ebf3'
    currentKey: CurrentKey = 'background'
    showUrlSuggestion = false
    colorItems: string[] = []
    mainSidebarPayload: SidebarPayload | null = null

    refreshSidebarPayload(traits?: Trait[]) {
        if (!this.dashboardPreference) this.mainSidebarPayload = null
        else
            this.mainSidebarPayload = this.preferenceSidebar(
                traits || this.dashboardPreference.traits
            )
    }

    get sidebarItems() {
        return SIDEBAR_ITEMS.map((value: string) => ({
            value: String(value),
            text: this.titlecase(
                String(this.$t(String(value ? value : 'null')))
            ),
        }))
    }

    makeColorItems(): string[] {
        if (!this.dashboardPreference) return []
        const traitSearch = new SimpleTraitSearch(this.sourceTraits)
        const output = ['background', 'light_background', 'title', 'curves']
        if (traitSearch.getTrait(TraitId.BORDER, { querySelectorTag: 0 }))
            output.push('border')
        if (traitSearch.getTrait(TraitId.SIDEBAR, { querySelectorTag: 0 })) {
            output.push('sidebar_primary')
            output.push('sidebar_secondary')
        }
        this.colorItems = output
        return output
    }

    get sidebarLabel() {
        return this.$t('colors.sidebar_primary', {
            sidebar: ['lateral', 'top'].map((s) => this.$t(s)).join(' / '),
        })
    }

    get computedDashboardPreferenceAlias() {
        return this.dashboardPreference?.alias || ''
    }
    set computedDashboardPreferenceAlias(alias: string) {
        if (this.aliasTmt) clearTimeout(this.aliasTmt)
        this.aliasTmt = setTimeout(
            () => {
                this.set({ dashboardPreference: { alias } })
            },
            100,
            this
        )
    }

    setCurrentKey(value: any) {
        this.currentKey = value
        const color = this.colors?.[value] || '#FFFFFF'
        this.setCurrentColor(color)
    }
    setCurrentColor(currentColor: string) {
        this.currentColor = currentColor
        const invertedColor = invertColor(currentColor)
        const colors = { [this.currentKey]: currentColor }
        const invert = { [this.currentKey]: invertedColor }
        this.setColorByClassName(currentColor)
        this.set({ invert, dashboardPreference: { colors } })
    }

    get displayLogo() {
        if (this.rawAccessRights.logo) return false

        if (this.isAdminMode) return this.logos.length > 1

        if (this.isDemoMode) return false

        return this.logos.length !== 1
    }
    refreshDashboardPreferenceTraits() {
        if (!this.dashboardPreference) return
        this.dashboardPreference.traits = this.refreshSourceTraits(
            this.sourceTraits,
            {
                emit: false,
                initialTraits: this.dashboardPreference.traits,
            }
        )
        this.sourceTraits = this.filterSourceTraits(
            this.dashboardPreference.traits
        )
    }
    setSelectTrait(trait: Trait, e: string) {
        if (!this.dashboardPreference) return
        trait.value = e
        trait.pristine = false
        // this.refreshSourceStyling()
        this.syncDashboardPreferenceWithSourceTraits(trait)
        if (trait.id === TraitId.SIDEBAR) {
            this.makeColorItems()
            this.refreshDashboardPreferenceTraits()
            this.refreshSidebarPayload()
        }
        this.refreshEvents()
        this.tagPristine()
        if (this.isAdminMode) {
            this.delayedUpdateDashboardPreference({
                id: this.dashboardPreference.id,
            })
        } else {
            this.updateDashboardPreference(this.dashboardPreference.id)
        }
    }
    setDeviceType(deviceType: DeviceType) {
        this.onDashboardPreferenceChange()
        // @/mixins/traits.ts
        this.refreshEvents()
    }
    syncDashboardPreferenceWithSourceTraits(trait: Trait) {
        if (!this.dashboardPreference) return

        for (const preferenceTrait of this.dashboardPreference.traits) {
            if (
                preferenceTrait.id === trait.id &&
                preferenceTrait.deviceType === trait.deviceType
            ) {
                preferenceTrait.value = trait.value
            }
        }
    }
    applyValueToDeviceTypesIfLocked(traitId: TraitId, value: string | number) {
        if (
            !this.source?.lockDeviceType ||
            this.source.lockDeviceType.indexOf(traitId) === -1
        )
            return
        for (const dashboardPreferenceTrait of this.dashboardPreference
            ?.traits || []) {
            if (dashboardPreferenceTrait.id === traitId)
                dashboardPreferenceTrait.value = value
        }
    }
    async setSliderTrait(trait: Trait, e: number) {
        if (!this.dashboardPreference) return
        trait.value = e
        trait.pristine = false

        this.applyValueToDeviceTypesIfLocked(trait.id, trait.value)
        // @/mixins/traits.ts
        this.refreshDashboardPreferenceTraits()
        this.syncDashboardPreferenceWithSourceTraits(trait)
        this.delayedRefreshEvents()
        this.tagPristine()
        // Set values with querySelector for quick refresh
        if (trait.id === TraitId.MARGIN) {
            this.setMarginByClassName(e)
        } else if (trait.id === TraitId.PAGE_MARGIN_X) {
            this.setPageMarginXByClassName(e)
        } else if (trait.id === TraitId.TITLE_HEIGHT) {
            this.setTitleHeightByClassName(e)
        } else if (trait.id === TraitId.BORDER) {
            this.setBorderByClassName(e)
        }
        // Rebuilds the view later on
        this.delayedUpdateDashboardPreference()
    }

    setPageMarginXByClassName(e: number) {
        const value = divideBy(e, this.mainReduceSize)
        for (const element of document.getElementsByClassName('x-block')) {
            ;(element as any).style['padding-left'] = value
            ;(element as any).style['padding-right'] = value
        }
    }
    setTitleHeightByClassName(e: number) {
        const value = divideBy(e, this.mainReduceSize)
        for (const element of document.getElementsByClassName('x-title')) {
            ;(element as any).style.height = value
        }
    }
    setMarginByClassName(e: number) {
        const value = divideBy(e, this.mainReduceSize)
        for (const element of document.getElementsByClassName('x-card')) {
            ;(element as any).style.margin = value
        }
    }
    setBorderByClassName(e: number) {
        const value = divideBy(e, this.mainReduceSize)
        const border = this.dashboardPreference?.colors.border
        for (const element of document.getElementsByClassName('x-card')) {
            ;(element as any).style.border = `${border} solid ${value}`
        }
    }
    setColorByClassName(color: string, currentKey?: CurrentKey) {
        const key = currentKey || this.currentKey
        const map: Partial<{
            [k in CurrentKey]: {
                tags: string[]
                styleKeys: ('background' | 'border' | 'background-color')[]
            }
        }> = {
            background: {
                tags: ['x-card', 'x-chart'],
                styleKeys: ['background', 'background-color'],
            },
            light_background: {
                tags: ['x-block'],
                styleKeys: ['background', 'background-color'],
            },
            title: {
                tags: ['x-title'],
                styleKeys: ['background', 'background-color'],
            },
            border: { tags: ['x-card'], styleKeys: ['border'] },
        }
        const { tags, styleKeys } = map?.[key] || {}

        if (!tags || !styleKeys) return
        const traitSearch = new SimpleTraitSearch(this.sourceTraits)
        const border = divideBy(
            traitSearch.getTraitNumberValue(TraitId.BORDER, {
                querySelectorTag: 0,
            }) || 0,
            this.mainReduceSize
        )
        for (const tag of tags) {
            const elements = document.getElementsByClassName(tag)
            for (const element of elements) {
                for (const styleKey of styleKeys) {
                    if (styleKey === 'border') {
                        ;(element as any).style[
                            styleKey
                        ] = `${color} solid ${border}`
                    } else {
                        ;(element as any).style[styleKey] = color
                    }
                }
            }
        }
    }

    getLogoUrlfromId(id: string): string | undefined {
        for (const logo of this.logos) {
            if (logo.id === id) return logo.url
        }
        return undefined
    }

    async setLogo(e: any) {
        const url = this.isAdminMode
            ? this.getLogoUrlfromId(e)
            : await this.getLogoUrl(e)
        this.set({
            dashboardPreference: {
                logo: { id: e, url },
            },
        })
        if (url) {
            this.showUrlSuggestion = true
        } else {
            this.showUrlSuggestion = false
        }
        this.tagPristine()
        this.delayedUpdateDashboardPreference()
    }

    onColorEvent() {
        for (const key in this.colors) {
            this.invert[key] = invertColor(this.colors[key])
        }
    }
    // END EDITOR

    async mounted() {
        if (this.source && !this.source.lockDeviceType)
            this.source.lockDeviceType = [
                TraitId.MARGIN,
                TraitId.BORDER,
                TraitId.TITLE_HEIGHT,
            ]
        this.makeColorItems()
        this.refreshSidebarPayload()
        await Promise.all([this.listDashboardPreferences(), this.listLogos()])

        if (!this.isAdminMode) this.dashboardPreferenceFromSource()
        else this.$emit('set', { loadingStep: false })
        await this.reloadPage()
        this.refreshDashboardPreferenceTraits()
        if (this.isAdminMode) this.event = !this.event
    }

    async beforeDestroy() {
        for (const key in this.pristines) {
            if (!this.pristines[key]) this.updateDashboardPreference(key)
        }
    }

    async openEditTitle() {
        if (!this.isAdminMode) return
        this.editTitle = true
        await this.$nextTick()
        const el = this.$refs['title-text-field']
        if (!el) return
        ;(el as any).focus()
    }
}
