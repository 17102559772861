
import draggable from 'vuedraggable'

import {
    DimensionHelper,
    Dimension,
    Column,
    nanoid,
    DimensionCalculation,
    DimensionOperator,
    DataType,
    SourceMemory,
    Source,
    MAXIMUM,
    TABLEAU_BRIGHT_VALUES,
    SourceMode,
} from '@/ponychart'
import DatasourcePill from '@/components/utils/DatasourcePill.vue'
import TwbDatasourceSelect from '@/components/utils/TwbDatasourceSelect.vue'
import Tour from '@/components/utils/Tour.vue'
import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { GlobalMixins } from '@/mixins'

const memoryInstance = SourceMemory.getInstance()

@Component({
    components: {
        draggable,
        Tour,
        TwbDatasourceSelect,
        DatasourcePill,
    },
})
export default class DimensionComponent extends mixins(GlobalMixins) {
    @Prop({ type: String, required: true })
    readonly step!: string
    @Prop({ type: Object, required: true })
    readonly source!: Source
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: String, required: true })
    readonly sourceMode!: SourceMode

    dragOptions = {
        animation: 0,
        disabled: false,
        ghostClass: 'ghost',
    }
    panel = -1
    dimensions: Dimension[] = []
    selectedDimensions: Dimension[] = []
    dimension = DimensionHelper.new(0)
    dialog = false
    dimensionDisabled = false
    tmt: null | number = null

    selectedTwbDatasources: string[] = []

    get sortedSelectedTwbIndexes() {
        if (this.twbDatasources.length === 1) return [0]
        const indexes = this.twbDatasources.map((t) => t.id)
        return this.selectedTwbDatasources.map((t) => indexes.indexOf(t)).sort()
    }

    get twbDatasources() {
        return this.source.twbDatasources.map((t, i) => ({
            ...t,
            color: TABLEAU_BRIGHT_VALUES[i % TABLEAU_BRIGHT_VALUES.length],
        }))
    }

    get twbIdx() {
        return this.sortedSelectedTwbIndexes[0]
    }

    get columns() {
        return memoryInstance.columns
    }
    get usedDimensionIds() {
        return this.step === 'dimension' ? memoryInstance.usedDimensionIds : []
    }
    get storeDimensions() {
        return memoryInstance.dimensions
    }
    get isDemoMode() {
        return this.sourceMode === SourceMode.DEMO
    }
    get maxDimensions() {
        return this.isDemoMode ? MAXIMUM.demoDimensions : MAXIMUM.dimensions
    }
    get subtitle() {
        return `<b>${this.dimensions.length}</b>&nbsp;${String(
            this.$t('creation.small_dimension')
        ).toLowerCase()} ${this.$t('creation.selected')} (max ${
            this.maxDimensions
        })`
    }
    get calculationCannotBeSaved() {
        if (this.dimension.errors.length > 0) return true

        for (const calculation of this.dimension.calculations) {
            if (!calculation.operator) return true
            if (
                this.isMissingOperator(
                    calculation.operator,
                    calculation.values.length
                )
            )
                return true
            for (const value of calculation.values) {
                if (
                    value.type === DataType.TEXT &&
                    String(value?.label)?.includes("'")
                )
                    return true
            }
        }
        return false
    }
    get operators() {
        const output: Partial<{ [k in DataType]: DimensionOperator[] }> = {}
        for (const operator of DimensionHelper.OPERATORS) {
            for (const type of DimensionHelper.allowedTypesFromOperators(
                operator
            )) {
                if (!output[type]) output[type] = []
                if (!output[type]?.includes(operator))
                    output[type]?.push(operator)
            }
        }
        return output
    }
    get lengths(): Partial<{ [k in DimensionOperator]: number }> {
        return DimensionHelper.OPERATORS.reduce(
            (
                acc: Partial<{ [k in DimensionOperator]: number }>,
                operator: DimensionOperator
            ) => ({
                ...acc,
                [operator]:
                    DimensionHelper.operationsFromOperators(operator).length,
            }),
            {}
        )
    }
    get dimensionCount() {
        return this.dimensions.reduce(
            (p, c) => ({
                ...p,
                [c.alias]: (p[c.alias] || 0) + 1,
            }),
            {}
        )
    }
    get datasourceMissingDimension(): string {
        if (this.twbDatasources.length <= 1) return ''
        for (const i in this.twbDatasources) {
            const twbIdx = Number(i)
            if (this.dimensions.filter((d) => d.twbIdx === twbIdx).length === 0)
                return String(
                    this.$t('datasourceMissingDimension', {
                        datasource: this.twbDatasources[twbIdx].alias,
                    })
                )
        }
        return ''
    }
    get hasError() {
        if (this.datasourceMissingDimension.length > 0) return true
        return (
            this.dimensions.reduce(
                (acc: boolean, d: Dimension) =>
                    acc || this.checkAlias(d.alias).length > 0,
                false
            ) || this.dimensions.length === 0
        )
    }
    switchValue(val: any, type: string) {
        val.type = type
        val.value = null
        this.disabledDimensionHook()
    }
    labelError(l: string) {
        return l.includes("'")
    }
    calculationMandatory(dimension: Dimension) {
        const column = memoryInstance.getColumn(dimension.columnId)
        return column.type?.startsWith('date')
    }
    getCalculation(columnId: string, calc: DimensionCalculation) {
        const column = memoryInstance.getColumn(columnId)
        const value = calc.operator
        const operators = calc.values ? calc.values : []
        const y =
            operators.length > 0 && operators[0].value
                ? operators[0].value
                : '?'
        const z =
            operators.length > 1 && operators[1].value
                ? operators[1].value
                : '?'
        return String(
            this.$t('aggregations.' + value, { x: column.alias, y, z })
        )
    }
    getColumnsExcept(columnId: string, twbIdx: number) {
        return this.columns.filter(
            (c: Column) => c.id !== columnId && c.twbIdx === twbIdx
        )
    }
    getOperators(columnId: string, y?: string, z?: string) {
        const column = memoryInstance.getColumn(columnId)
        // type = type == 'datetime' ? 'date' : type
        return (this.operators?.[column.type] || []).map(
            (value: DimensionOperator) => ({
                value,
                text: this.$t('aggregations.' + value, {
                    x: column.alias,
                    y: y || '?',
                    z: z || '?',
                }),
            })
        )
    }
    disabledDimensionHook() {
        this.dimensionDisabled = DimensionHelper.dimensionDisabled(
            this.dimension
        )
    }
    async deleteCalculation(dimension: Dimension, i: number) {
        dimension.calculations = dimension.calculations.filter(
            (_, j) => j !== i
        )
        await this.$nextTick()
        this.disabledDimensionHook()
    }
    setOperator(calc: DimensionCalculation, operator: DimensionOperator) {
        calc.operator = operator
        calc.values = []
        const operators = DimensionHelper.operationsFromOperators(operator)
        for (const operator of operators) {
            calc.values.push({
                type: operator.default,
                value: operator.value || undefined,
                label: operator.label || undefined,
            })
        }
    }
    setAlias(dimension: Dimension, alias: string) {
        dimension.pristine = false
        dimension.alias = alias
        dimension.errors = this.checkAlias(alias)
    }
    setColumnId(dimension: Dimension, columnId: string) {
        dimension.calculations = []
        const column = memoryInstance.getColumn(columnId)
        if (dimension.pristine) {
            dimension.alias = column.alias
        }
        dimension.columnId = columnId
        if ([DataType.DATE, DataType.DATETIME].includes(column.type)) {
            dimension.calculations = [
                { operator: DimensionOperator.YEAR, values: [] },
            ]
        }
    }
    displayButton(dimension: Dimension) {
        const column = memoryInstance.getColumn(dimension.columnId)
        const type = column.type
        if ([DataType.DATE, DataType.DATETIME].includes(type)) {
            return dimension.calculations.length < 1
        } else if (
            !this.operators[type] ||
            this.operators?.[type]?.length == 0
        ) {
            return false
        } else {
            return true
        }
    }
    isMissingOperator(operator: DimensionOperator, length: number) {
        return (this.lengths[operator] || 0) > length
    }
    deleteAll() {
        this.dimensions = this.dimensions.filter((d) =>
            this.usedDimensionIds.includes(d.id)
        )
        this.selectedDimensionsHook()
        this.saveHook()
    }
    deleteDimension(id?: string) {
        this.dimensions = this.dimensions.filter(
            (dimension) =>
                (!!id && dimension.id !== id) ||
                this.usedDimensionIds.includes(dimension.id)
        )
        this.selectedDimensionsHook()
        this.saveHook()
    }
    async addCalculation() {
        const ops = this.getOperators(this.dimension.columnId)
        const operator = ops[0].value
        const calc: DimensionCalculation = { operator, values: [] }
        this.setOperator(calc, operator)
        this.dimension.calculations.push(calc)
        await this.$nextTick()
        this.disabledDimensionHook()
    }
    getAlias(dimension: Dimension) {
        if (dimension.alias) return dimension.alias
        if (dimension.calculations.length == 1) {
            const op = dimension.calculations[0].operator
            return this.$t('aggregations.' + op)
        }
        return ''
    }
    checkDimension(dimension: Dimension) {
        dimension.errors = []
        if (!dimension.alias) {
            const error = [
                this.getAlias(dimension),
                this.$t('errors.empty_col_name'),
            ].join('')
            dimension.errors.push(error)
            return dimension
        }
        if (this.dimensionCount[dimension.alias] > 1) {
            dimension.errors.push(
                dimension.alias + this.$t('errors.duplicate_col_name')
            )
            return dimension
        }
        if (dimension.alias && dimension.alias.startsWith('_')) {
            dimension.errors.push(
                String(this.$t('errors.bad_name', { x: dimension.alias }))
            )
        }
        return dimension
    }
    checkAlias(alias: string) {
        const dimension = DimensionHelper.new(this.twbIdx)
        dimension.alias = alias
        return this.checkDimension(dimension).errors
    }

    getClass(alias: string, i: number) {
        const errors = this.checkAlias(alias)
        if (errors.length == 0) {
            return i % 2 == 0 ? 'blue-grey lighten-5' : 'white'
        } else {
            return 'red lighten-4'
        }
    }
    addDimension() {
        if (!this.dimension.id) {
            this.dimensions.push({
                ...this.dimension,
                id: nanoid.id(),
            })
            const errors = this.checkAlias(this.dimension.alias)
            if (errors.length > 0) {
                this.showToast({ message: errors[0] })
                this.dimensions.pop()
            }
        } else {
            this.dimensions.forEach((d: Dimension) => {
                if (d.id == this.dimension.id) {
                    Object.assign(d, this.dimension)
                }
            })
        }
        Object.assign(this.dimension, DimensionHelper.new(this.twbIdx))
        this.dialog = false
        this.selectedDimensionsHook()
        this.saveHook()
    }
    showDialog(dimensionId: string) {
        this.clearTour()
        if (!dimensionId) {
            this.dimension = DimensionHelper.new(this.twbIdx)
        } else {
            for (const dimension of this.dimensions) {
                if (dimension.id === dimensionId) {
                    Object.assign(this.dimension, dimension)
                }
            }
        }
        this.dialog = true
    }
    buildDateDimension(
        columnId: string,
        type: DataType,
        operator: DimensionOperator,
        twbIdx: number
    ): Dimension {
        const calculation = {
            operator,
            values: [],
        }
        return {
            columnId,
            type,
            calculations: [calculation],
            errors: [],
            alias: this.getCalculation(columnId, calculation),
            pristine: true,
            id: nanoid.id(),
            twbIdx,
        }
    }
    createDimensions() {
        const output: Dimension[] = []
        let count = 0
        for (const { id, alias, type, twbIdx } of this.columns) {
            if (type == DataType.TEXT) {
                output.push({
                    columnId: id,
                    type,
                    calculations: [],
                    errors: [],
                    alias,
                    pristine: true,
                    id: nanoid.id(),
                    twbIdx,
                })
                count++
            } else if ([DataType.DATE, DataType.DATETIME].includes(type)) {
                const periods = [DimensionOperator.DAY_OF_WEEK]
                for (const period of periods) {
                    output.push(
                        this.buildDateDimension(id, type, period, twbIdx)
                    )
                    count++
                }
            }
        }
        this.dimensions = output.filter((_, i) => i < this.maxDimensions / 2)
        this.selectedDimensionsHook()
    }
    onTwbDatasourceChange() {
        this.dimension.columnId = ''
        this.dimension.calculations = []
        this.selectedDimensionsHook()
    }
    selectedDimensionsHook() {
        this.selectedDimensions = this.dimensions.filter((d) =>
            this.sortedSelectedTwbIndexes.includes(d.twbIdx)
        )
    }
    saveHook() {
        if (this.tmt) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.$emit('save')
            },
            7000,
            this
        )
    }
    async onDragChange(
        type: 'chips' | 'dimension',
        e: { moved: { element: Dimension; oldIndex: number; newIndex: number } }
    ) {
        const movedDimension: Dimension = e.moved.element

        if (type === 'chips') {
            // If the sorted element is not displayed, then return
            if (!this.sortedSelectedTwbIndexes.includes(movedDimension.twbIdx))
                return
            // Tested and works easily
            this.selectedDimensionsHook()
        } else {
            const selectedNewIndex = e.moved.newIndex
            const switchDimensionId =
                selectedNewIndex === this.selectedDimensions.length - 1
                    ? undefined
                    : this.selectedDimensions[selectedNewIndex + 1].id

            const newDimensions = []
            let pushed = false
            for (const dimension of this.dimensions) {
                if (dimension.id === switchDimensionId) {
                    newDimensions.push(movedDimension)
                    pushed = true
                } else if (dimension.id === movedDimension.id) {
                    continue
                }
                newDimensions.push(dimension)
            }
            if (!pushed) newDimensions.push(movedDimension)
            this.dimensions = newDimensions
        }
    }

    mounted() {
        this.selectedTwbDatasources = this.twbDatasources.map((t) => t.id)
        this.selectedDimensionsHook()
    }

    @Watch('columns', { immediate: true })
    onColumnsChanged(columns: Column[]) {
        if (!columns?.length) return
        if (!this.storeDimensions.length) {
            this.createDimensions()
        } else {
            this.dimensions = [...this.storeDimensions].map((d) => ({
                ...d,
                errors: [],
            }))
        }
        this.selectedDimensionsHook()
    }
    @Watch('hasError', { immediate: true })
    onDimensionError(hasDimensionErrors: boolean) {
        this.$emit('set', { hasDimensionErrors })
    }
}
