import {
    CHART_TYPE_TRAITS,
    DeviceType,
    MAX_QUERY_SELECTOR_TAG,
    TraitId,
} from 'ponychart'
import {
    BlockHasTimeIndicationTrait,
    BlockTitleTrait,
    Bool,
    CustomTrait,
    IncludePageBlockHeaderTrait,
} from '@/ponychart/trait'
import { TraitSearch } from '@/ponychart/state/traits'

export class PageBlockTraitsFactory {
    constructor(private traitSearch: TraitSearch) {}

    private initMapStep(): CustomTrait[] {
        return [
            new BlockHasTimeIndicationTrait(
                {
                    hidden: true,
                    value: Bool.FALSE,
                    deviceType: DeviceType.DESKTOP,
                    querySelectorTags: [0],
                    twbIdx: this.traitSearch.twbIdx,
                },
                {}
            ),
        ]
    }

    private blockTitleStep(): CustomTrait[] {
        return [
            new BlockTitleTrait(
                {
                    hidden: true,
                    deviceType: DeviceType.DESKTOP,
                    querySelectorTags: [0],
                    twbIdx: this.traitSearch.twbIdx,
                },
                {}
            ),
        ]
    }

    private includePageHeaderStep(): CustomTrait[] {
        const hasTitle =
            (this.traitSearch.getTraitStringValue(TraitId.BLOCK_TITLE, 0) || '')
                .length > 0
        if (hasTitle) return []
        const hasTimeIndication =
            (this.traitSearch.getTraitStringValue(
                TraitId.BLOCK_HAS_TIME_INDICATION,
                0
            ) || Bool.FALSE) === Bool.TRUE
        if (hasTimeIndication) return []
        for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
            for (const traitId of [
                TraitId.DIMENSION,
                TraitId.MEASURE,
                TraitId.DATE_AGGREGATION_LEVEL,
                ...CHART_TYPE_TRAITS,
            ]) {
                const value = this.traitSearch.getTraitStringValue(traitId, i)
                if (value && value.includes(';')) {
                    return []
                }
            }
        }

        return [
            new IncludePageBlockHeaderTrait({
                hidden: true,
                deviceType: DeviceType.DESKTOP,
                querySelectorTags: [0],
                twbIdx: this.traitSearch.twbIdx,
                value: Bool.FALSE,
            }),
        ]
    }

    preListTraits() {
        for (const method of [this.initMapStep, this.blockTitleStep]) {
            const traits = method.bind(this)() as CustomTrait[]
            this.traitSearch.pushTraits(traits, {
                attributesToUpdate: ['!value'],
            })
        }
    }

    postListTraits() {
        for (const method of [this.includePageHeaderStep]) {
            const traits = method.bind(this)() as CustomTrait[]
            this.traitSearch.pushTraits(traits, {
                attributesToUpdate: ['!value'],
            })
        }
    }
}
