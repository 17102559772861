<template lang="pug">
#dropzone_id(
    ref='dropzoneElement',
    :class='{ dropzone: includeStyling, "vue-dropzone": includeStyling, "small-vue-dropzone": !isBig }'
)
    .dz-message(v-if='useCustomSlot')
        slot#slot_id
</template>

<script>
import '@/assets/dropzone.css'
import { Dropzone } from 'dropzone'
import UploadService from '@/services/uploadService'

Dropzone.autoDiscover = false

export default {
    name: 'uploadComponent',
    props: {
        isBig: {
            type: Boolean,
            default: true,
        },
        useCustomSlot: {
            type: Boolean,
            default: false,
            required: false,
        },
        includeStyling: {
            type: Boolean,
            default: true,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        message: {
            type: String,
            required: false,
        },
        acceptedFiles: {
            type: String,
            required: false,
            default: 'image/*',
        },
        pathParameter: {
            type: String,
            required: false,
            validate: (v) => ['dashboards', 'images'].includes(v),
            default: 'images',
        },
        metadata: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        maxFilesize: {
            type: Number,
            default: 5
        },
        showMaxFileSize: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        internalUuid: '',
    }),
    mounted() {
        const ctx = this
        const success = (file) => {
            this.$emit('uploaded', this.internalUuid)

            file.previewElement.classList.add('dz-success')
        }
        const removedFile = (file) => {
            file.previewElement.innerHTML = ''
            this.$emit('delete')
            const d = document.getElementById('dropzone_id')
            for (const element of d?.children || []) {
                if (element.classList.contains('dz-preview')) {
                    element.parentNode.removeChild(element)
                }
            }
        }
        const accept = (file, done) => {
            this.getSignedURL(file)
                .then((url) => {
                    file.uploadURL = url
                    done()
                    setTimeout(() => this.dropzone.processFile(file))
                })
                .catch((err) => {
                    done('Failed to get an S3 signed upload URL', err)
                })
        }
        const options = {
            url: '/',
            method: 'put',
            sending(file, xhr) {
                const _send = xhr.send
                xhr.send = () => {
                    _send.call(xhr, file)
                }
            },
            parallelUploads: 1,
            maxFilesize: this.maxFilesize,
            acceptedFiles: this.acceptedFiles,
            uploadMultiple: false,
            header: '',
            addRemoveLinks: true,
            dictDefaultMessage: this.message + (this.showMaxFileSize ? ` (max ${this.maxFilesize}MB)` : ""),
            autoProcessQueue: false,
            renameFile: function (file) {
                return file.name.normalize('NFD').replace(/[^a-zA-Z ]/g, '')
            },
            success: (file) => success(file),
            error: function (file, errorMessage) {
                if(errorMessage && errorMessage.includes("Max filesize")){
                    ctx.$emit("fileTooBig")
                }
            },
            removedfile: (file) => removedFile(file),
            init: function () {
                this.on('addedfile', function () {
                    if (this.files[1] != null) {
                        this.removedFile(this.files[0])
                    }
                })
            },
            accept,
        }
        this.dropzone = new Dropzone(this.$el, options)
        this.dropzone.on(
            'processing',
            (file) => {
                this.dropzone.options.url = file.uploadURL
            },
            this
        )
    },
    methods: {
        accept(file, done) {
            const processFile = this.processFile
            this.getSignedURL(file)
                .then((url) => {
                    file.uploadURL = url
                    done()
                    // Manually process each file
                    setTimeout(() => processFile(file))
                })
                .catch((err) => {
                    done('Error', err)
                })
        },
        getSignedURL(file) {
            const payload = {
                contentType: file.type,
                fileName: file.name
                    .normalize('NFD')
                    .replace(/[^a-zA-Z .0-9]/g, ''),
                ...this.metadata,
            }
            return UploadService.getUploadUrl(this.pathParameter, payload)
                .then((url) => {
                    const arr = url.split('/')
                    this.internalUuid = arr[arr.length - 1].split('?')[0]
                    return Promise.resolve(url)
                })
                .catch((err) => {
                    console.log(err)
                    return Promise.reject('/')
                })
        },
    },
}
</script>

<style>
.vue-dropzone {
    border: 2px dashed rgb(33, 150, 243);
    border-radius: 20px;
    font-family: 'Arial', sans-serif;
    letter-spacing: 0.2px;
    color: #777;
    transition: 0.2s linear;
    width: 250px;
    height: 250px;
    margin: 7px;
}
.small-vue-dropzone {
    border-radius: 15px;
    width: 140px !important;
    height: 140px !important;
    padding: 5px !important;
    margin: 5px !important;
}
.vue-dropzone:hover {
    background-color: #f6f6f6;
    border: 2px dashed #e6e6ee;
}
.vue-dropzone > i {
    color: #ccc;
}
.small-vue-dropzone > i {
    color: #ccc;
}
.vue-dropzone > .dz-preview {
    margin: 0;
    z-index: 0;
}
.vue-dropzone > .dz-preview .dz-image {
    /* z-index: -1; */
    border-radius: 20px;
    width: 200px;
    height: 200px;
}
.vue-dropzone > .dz-preview .dz-image img {
    border-radius: 20px;
    width: 200px;
    height: 200px;
}
.vue-dropzone > .dz-preview .dz-image img:not([src]) {
    border-radius: 20px;
    width: 200px;
    height: 200px;
}

.small-vue-dropzone > .dz-preview .dz-image {
    border-radius: 15px !important;
    width: 125px !important;
    height: 125px !important;
}
.small-vue-dropzone > .dz-preview .dz-image img {
    border-radius: 15px !important;
    width: 125px !important;
    height: 125px !important;
}
.small-vue-dropzone > .dz-preview .dz-image img:not([src]) {
    border-radius: 15px !important;
    width: 125px !important;
    height: 125px !important;
}

.vue-dropzone > .dz-preview .dz-image:hover img {
    transform: none;
    --webkit-filter: none;
    border-radius: 20px;
}
.vue-dropzone > .dz-preview .dz-details {
    bottom: 0;
    top: 0;
    color: white;
    background-color: rgba(33, 150, 243, 0.8);
    transition: opacity 0.2s linear;
    text-align: left;
    border-radius: 20px;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename {
    overflow: hidden;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename span,
.vue-dropzone > .dz-preview .dz-details .dz-size span {
    background-color: transparent;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: none;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename:hover span {
    background-color: transparent;
    border: none;
}
.vue-dropzone > .dz-preview .dz-progress .dz-upload {
    background: #cccccc;
}
.vue-dropzone > .dz-preview .dz-remove {
    /* position: absolute; */
    /* z-index: 1; */
    color: white;
    margin-left: 15px;
    padding: 10px;
    top: inherit;
    bottom: 15px;
    border: 2px white solid;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 800;
    letter-spacing: 1.1px;
    opacity: 0;
}
.small-vue-dropzone > .dz-preview .dz-remove {
    padding: 2px;
    margin: 0 auto;
    display: inline;
    left: 50%;
    font-size: 0.6rem;
    transform: translate(-50%, 8px);
}
.vue-dropzone > .dz-preview:hover .dz-remove {
    opacity: 1;
}
.vue-dropzone > .dz-preview .dz-success-mark,
.vue-dropzone > .dz-preview .dz-error-mark {
    margin-left: auto;
    margin-top: auto;
    width: 100%;
    top: 35%;
    left: 0;
}
.vue-dropzone > .dz-preview .dz-success-mark svg,
.vue-dropzone > .dz-preview .dz-error-mark svg {
    margin-left: auto;
    margin-right: auto;
}
.vue-dropzone > .dz-preview .dz-error-message {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    width: 100%;
    text-align: center;
}
.vue-dropzone > .dz-preview .dz-error-message:after {
    display: none;
}
</style>
