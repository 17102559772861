
import draggable from 'vuedraggable'

import {
    PopupMode,
    Page as _Page,
    PageBlock as _PageBlock,
    emptyPage,
    DynamicParameter,
    ParameterInterface,
    SourceMemory,
    t,
    RegisterMemory,
    Source,
    SourceColors,
    MAXIMUM,
    PonychartComponent,
    RegisterTour,
    GlobalState,
    GlobalOptions,
    traitKey,
    SourceMode,
} from '@/ponychart'
import {
    TraitId,
    DeviceType,
    QuerySelectorTag,
    Trait,
    TraitOptionType,
} from 'ponychart'
import DatasourcePill from '@/components/utils/DatasourcePill.vue'
import Page from '@/components/Pages/Page.vue'
import PageSelector from '@/components/Pages/PageSelector.vue'
import Logo from '@/components/Home/Logo.vue'
import ButtonsMoveEdit from '@/components/Pages/ButtonsMoveEdit.vue'
import PageEditBlock from '@/components/Pages/PageEditBlock.vue'
import AddPageBtn from '@/components/Pages/AddPageBtn.vue'
import ShowMoreBtn from '@/components/utils/ShowMoreBtn.vue'
import DeviceTypeSwitch from '@/components/utils/DeviceTypeSwitch.vue'
import PageTrait from '@/components/Pages/PageTrait.vue'

import PageService from '@/services/pageService'
import SourceService from '@/services/sourceService'

import { cloneDeep, debounce } from 'lodash'

import { Pages, GlobalMixins, Traits } from '@/mixins'

import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { TYPE } from 'vue-toastification'
import { SimpleTraitSearch, TraitSearch } from '@/ponychart/state/traits'

const registerInstance = RegisterMemory.getInstance()
const registerTour = RegisterTour.getInstance()

@Component({
    components: {
        draggable,
        Page,
        PageSelector,
        Logo,
        ButtonsMoveEdit,
        PageEditBlock,
        AddPageBtn,
        ShowMoreBtn,
        DeviceTypeSwitch,
        PageTrait,
        DatasourcePill,
    },
})
export default class PagesComponent extends mixins(
    GlobalMixins,
    Pages,
    Traits
) {
    @Prop({ type: Number, required: true })
    readonly pagesLength!: number
    @Prop({ type: Boolean, required: true })
    readonly sourceEvent!: boolean
    @Prop({ type: String, required: true })
    readonly step!: string
    @Prop({ type: Object, required: true })
    readonly source!: Source
    @Prop({ type: String, required: true })
    readonly sourceMode!: SourceMode
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: String, required: false })
    readonly demoSourceUuid!: string
    @Prop({ type: Number, required: true })
    readonly downloadsToday!: number

    finalisedLoading = false
    show = false
    showDelete = false
    editLoadings: (string | number)[] = []
    savingLoadings: (string | number)[] = []
    deleteLoadings: (string | number)[] = []
    onlyShowInfo = false
    mode = PopupMode.EDIT
    page: _Page | null = null
    dragOptions = {
        group: 'pages',
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    }
    reduceSizes = {
        desktop: [10, 3],
        tablet: [10, 2.5],
        mobile: [5, 1.5],
    }
    deviceType: DeviceType = DeviceType.DESKTOP
    globalState: GlobalState | null = null
    traitSearch: TraitSearch | null = null
    insertPageBlockAtIndex = -1
    deleteIndex = -1
    selected: _Page[] = []
    events: { [k: string]: boolean } = {}
    event = false
    headerCache = false
    parameters: ParameterInterface[] = []
    pageParameters: Map<number | string, DynamicParameter[]> = new Map()

    get isDemoMode() {
        return this.sourceMode === SourceMode.DEMO
    }

    onAllowMultipleChartSelectionModeChange() {
        if (!this.page) return
        this.refreshPage(this.page)
    }

    get twbDatasources() {
        return this.source.twbDatasources
    }

    get twbIndexes() {
        return this.source.twbDatasources.map((t, i) => i)
    }

    get maximumPages() {
        return this.isDemoMode ? MAXIMUM.demoPages : MAXIMUM.pages
    }

    get maximumPageBlocks() {
        return this.isDemoMode ? MAXIMUM.demoPageBlocks : MAXIMUM.pageBlocks
    }

    get isDesktop() {
        return this.deviceType == DeviceType.DESKTOP
    }

    get pages() {
        return this.source?.pages || []
    }

    get sourceTraits() {
        return this.source.traits || []
    }

    get globalOptions(): GlobalOptions {
        return {
            colors: this.source.colors as SourceColors,
            logoUrl: this.source?.logo?.url,
            navigation: this.page?.navigation,
            deviceType: this.deviceType,
            multipleCharts: this.page?.multipleCharts,
        }
    }

    get grayFilter() {
        if (!this.showEditing) return undefined
        return this.editing === null ? undefined : this.editing
    }

    mounted() {
        this.globalState = new GlobalState(this.globalOptions)
        this.traitSearch = TraitSearch.createInstance(
            this.sourceTraits,
            [],
            this.globalState
        )
        this.traitSearch.syncWith(this.globalState)
        this.selected = this.pages
        this.listPages()
        // Fire an event when user arrives on /upload/i/pages
        // to check if user has downloaded today
        this.$emit('checkIfUserHasDownloadedToday')

        // START PAGE EDIT
        // if user has clicked on a chart already, do not display helper to do so
        this.clickedOnChart = localStorage.getItem('clickedOnChart') === 'true'
    }

    get disabled() {
        return (
            this.loading.main ||
            !!this.savingLoadings.length ||
            !!this.deleteLoadings.length
        )
    }

    get columns() {
        return this.source?.columns || []
    }

    get pageToDelete() {
        return this.selected?.[this.deleteIndex]?.alias || ''
    }

    get noBlocks() {
        return this.selected.reduce(
            (acc: boolean, page: _Page) => acc && !page.pageBlocks?.length,
            true
        )
    }

    get pageIsAddMode() {
        if (this.isDemoMode) {
            const selectedPageIds = this.selected.map((p) => p.id)
            return this.page?.id && !selectedPageIds.includes(this.page?.id)
        }
        return this.page?.id && isNaN(+this.page?.id)
    }

    get pageSelectorMode() {
        return this.pageIsAddMode ? 'add' : 'edit'
    }

    get orderPayload(): { pageId: number; order: number }[] {
        return this.selected.map((p, order) => ({
            pageId: p.id as number,
            order,
        }))
    }
    get pageId() {
        return this.page?.id
    }
    get colors() {
        return this.source?.colors || {}
    }
    get pageIndex() {
        if (!this.page) return -1
        return this.selected.map((p) => p.id).indexOf(this.page.id)
    }
    get logoUrl() {
        return this.source?.logo?.url
    }

    get sidebar() {
        return this.sidebarOptions(this.pageIndex)
    }

    get maxDownloads() {
        return this.isDemoMode
            ? MAXIMUM.demoDownloadsToday
            : MAXIMUM.downloadsToday
    }

    get hasDownloadedToday() {
        return this.downloadsToday >= this.maxDownloads
    }

    get isSubscribed() {
        return !!this.$store.getters.subscription
    }

    sidebarOptions(index: number, page?: _Page) {
        const traitSearch = new SimpleTraitSearch(this.sourceTraits)
        const defaultSidebar = traitSearch.getTraitStringValue(TraitId.SIDEBAR)
        const sidebar = page
            ? page.navigation
                ? defaultSidebar
                : ''
            : defaultSidebar
        let count = 0
        for (let i = 0; i < this.selected.length; i++) {
            if (!this.selected[i].navigation) continue
            if (i === index) break
            count++
        }
        return {
            sidebar,
            aliases: this.selected
                .filter((p) => p.navigation)
                .map((p) => p.alias),
            index: count,
        }
    }

    EDIT(payload: any) {
        this.$store.commit('EDIT', payload)
    }
    TOGGLE_EVENT(event: string) {
        this.$store.commit('TOGGLE_EVENT', event)
    }
    sort(_sortEvent: {
        newDraggableIndex: number
        newIndex: number
        oldDraggableIndex: number
        oldIndex: number
    }) {
        // registerInstance.applySort(e)
        if (this.isDemoMode) return
        PageService.updateOrder(this.sourceId, this.orderPayload)
    }
    sidebarClicked(i: number) {
        let count = 0
        for (const page of this.selected) {
            if (!page.navigation) continue
            if (count === i) {
                this.setPage(page)
                return
            }
            count++
        }
    }
    setPage(page: _Page) {
        this.page = page
        this.event = !this.event
        setTimeout(() => {
            registerTour.getTourContext().startTour({ step: 'first-page' })
        }, 1000)
    }
    makeParameters() {
        const parameters: DynamicParameter[] = []
        for (const page of this.selected) {
            for (const parameter of this.pageParameters.get(page.id) || []) {
                let found = false
                for (const previousParameter of parameters) {
                    if (parameter.eq(previousParameter)) {
                        previousParameter.join(parameter)
                        found = true
                        break
                    }
                }
                if (!found) parameters.push(parameter)
            }
        }
        return parameters.map((p) => p.toParameterInterface())
    }
    async listPages() {
        if (this.pages.length) {
            this.selected = cloneDeep(this.pages)
            return
        } else {
            const instance = SourceMemory.getInstance()
            this.selected = instance.pages
        }
    }
    async delPageBlock(page: _Page, i?: number) {
        if (!page.pageBlocks?.length) return
        if (i === undefined) {
            page.pageBlocks.pop()
        } else {
            page.pageBlocks.splice(i, 1)
        }
        page.pristine = false
        await this.updatePage(page)
    }
    isPageSelected(page: _Page) {
        return page.id === this.page?.id
    }
    addDisabled(page: _Page) {
        return (page.pageBlocks || []).length >= this.maximumPageBlocks
    }
    isPageEditLoading(page: _Page) {
        return (
            this.editLoadings.includes(page.id) ||
            this.savingLoadings.includes(page.id)
        )
    }
    isDeleteLoading(page: _Page) {
        return this.deleteLoadings.includes(page.id)
    }
    isPageLoading(page: _Page) {
        return this.isPageEditLoading(page) || this.isDeleteLoading(page)
    }
    copyPage(page: _Page, id: number | string) {
        return cloneDeep({ ...page, id })
    }
    saveAfterTimeout(id: number) {
        for (const page of this.selected) {
            if (page.id === id && !page.pristine) {
                this.updatePage(page)
                return
            }
        }
    }
    async createPage(page: _Page) {
        this.loading.main = true
        try {
            let newPage = page
            if (!this.isDemoMode) {
                newPage = await PageService.createPage(this.sourceId, page)
            } else {
                // WARNING WATCH OUT! potential duplication in source.pages because of this.selected.push?
                if (!this.source.pages) this.source.pages = []
                this.source.pages.push(page)
            }
            this.selected.push(newPage)
            this.setPage(newPage)
        } catch (e) {
            this.showToast({ type: TYPE.ERROR })
        } finally {
            this.show = false
            this.loading.main = false
        }
    }
    async updatePage(page: _Page) {
        if (this.isDemoMode) return
        const pageId = page.id
        this.editLoadings.push(pageId)
        await PageService.updatePage(this.sourceId, page.id, page)
        this.editLoadings = this.editLoadings.filter((id) => id !== pageId)
    }

    refreshPage(page: _Page) {
        if (!this.traitSearch) return
        this.traitSearch.multipleCharts = page.multipleCharts
        for (const pageBlock of page.pageBlocks || []) {
            this.refreshPageTraits(pageBlock, this.traitSearch)
        }
    }

    async updateParameters() {
        if (this.isDemoMode) return
        await SourceService.saveSource(
            this.sourceId,
            {
                parameters: this.parameters,
                pages: this.selected,
            },
            { noResult: true }
        )
    }
    async getRefSafe(refKey: string) {
        return new Promise((resolve) => {
            let count = 0
            const interval = setInterval(() => {
                if (this.$refs[refKey] || count > 50) {
                    clearInterval(interval)
                    resolve(this.$refs[refKey])
                }
                count += 1
            }, 50)
        })
    }

    async saveAllPages() {
        this.finalisedLoading = true
        this.clearTour()
        if (this.hasDownloadedToday && !this.isSubscribed) {
            this.$emit('savePages')
            this.finalisedLoading = false
            return
        }
        try {
            const promises = []
            const pagePromises: Promise<any>[] = []
            for (const i in this.selected) {
                const page = this.selected[i]
                const childrenContext = registerInstance.getPageContext(
                    Number(i)
                )
                if (!childrenContext)
                    throw new Error('Children context not found')

                if (!page.pristine) pagePromises.push(this.updatePage(page))
                const payload: any = {}
                for (const deviceType of [
                    DeviceType.DESKTOP,
                    DeviceType.TABLET,
                    DeviceType.MOBILE,
                ]) {
                    const { styles, components, parameters } =
                        childrenContext.build({
                            reduceSize: 1,
                            onlyContainer: true,
                            includeParameters: true,
                            calledFromParentComponent: true,
                            deviceType,
                            keys: ['styles', 'components', 'parameters'],
                        }) as {
                            css: Set<string>
                            html: string
                            styles: any
                            components: PonychartComponent[]
                            parameters: DynamicParameter[]
                        }
                    if (deviceType === DeviceType.DESKTOP)
                        this.pageParameters.set(page.id, parameters)
                    // const highResPayload = this.mapReduce({styles, components})
                    payload[deviceType] = { styles, components }
                }
                promises.push(this.saveTemplate(page.id, payload, 'high'))
            }
            this.parameters = this.makeParameters()
            pagePromises.push(this.updateParameters())
            this.$emit('set', { parameters: this.parameters })
            Promise.all(pagePromises)
            await Promise.all(promises)

            this.$emit('savePages')
        } catch (e) {
            console.error(e)
            this.showToast(
                'An error happened while saving your work, please refresh and try again'
            )
        } finally {
            this.finalisedLoading = false
        }
    }
    async saveTemplate(
        pageId: number | string,
        payload: { [k in string]: any },
        resolution: 'high' | 'low' = 'high'
    ) {
        this.savingLoadings.push(pageId)
        await PageService.saveTemplate(
            this.sourceId,
            pageId,
            payload,
            {
                pageType: 'tree',
                resolution,
            },
            this.isDemoMode ? this.demoSourceUuid : undefined
        )
        this.savingLoadings = this.savingLoadings.filter((id) => id !== pageId)
    }

    async deleteSelected(i: number) {
        const pageId = this.selected[i].id
        if (this.isDemoMode) {
            this.selected = this.selected.filter((p) => p.id !== pageId)
            this.showDelete = false
            if (this.page?.id === pageId) this.page = null
        } else {
            this.deleteLoadings.push(pageId)
            await PageService.deletePage(this.sourceId, pageId)
                .then(() => {
                    this.selected = this.selected.filter((p) => p.id !== pageId)
                    this.showDelete = false
                    if (this.page?.id === pageId) this.page = null
                })
                .catch((e) => {
                    console.log(e)
                    this.showToast({ type: TYPE.ERROR, timeout: 2000 })
                })

            this.deleteLoadings = []
        }
    }
    async addPage() {
        const traitSearch = new SimpleTraitSearch(this.sourceTraits)
        const sidebar = traitSearch.getTraitStringValue(TraitId.SIDEBAR)
        this.deviceType = DeviceType.DESKTOP
        this.setPage(emptyPage(this.newPageName(), !!sidebar))
        this.show = true
    }
    pageCount(page: _Page) {
        return page?.pageBlocks?.length || 0
    }
    closePageSelector() {
        this.show = false
        if (!this.page) return
        this.page = null
    }
    async setPageBlock(pageBlock: _PageBlock) {
        if (!this.page) return
        if (!this.page.pageBlocks) this.page.pageBlocks = []
        pageBlock.alias = this.newPageName('pageBlock')
        pageBlock.title = pageBlock.alias // TODO: only one of the two
        this.page.pristine = false
        this.page.pageBlocks.splice(this.insertPageBlockAtIndex, 0, pageBlock)
        if (this.pageIsAddMode) {
            console.log('creating page', this.pageIsAddMode)
            await this.createPage(this.page)
        } else {
            console.log('not creating page', this.pageIsAddMode)
            this.event = !this.event
            if (!this.selected.map((p) => p.id).includes(this.page.id))
                this.selected.push(this.page)
            this.loading.main = true
            await this.updatePage(this.page)
            this.loading.main = false
            this.show = false
        }
    }
    newPageName(s: 'page' | 'pageBlock' = 'page') {
        const page = String(this.$t(s))
        const arr: _Page[] | _PageBlock[] =
            s === 'page' ? this.selected : this.page?.pageBlocks || []
        const max = (arr as any).reduce(
            (acc: number, p: _PageBlock) =>
                Math.max(acc, parseInt((p.alias || '').replace(page, '')) || 0),
            0
        )
        return [this.$t(s), String(Math.max(max, arr.length) + 1)].join(' ')
    }
    set(o: any) {
        for (const key in o) {
            this[key] = cloneDeep(o[key])
        }
    }
    @Watch('pageId')
    async onPageIdChange(
        pageId: number | string | undefined,
        oldPageId: number | string | undefined
    ) {
        if (oldPageId && !isNaN(+oldPageId)) {
            for (const page of this.selected) {
                if (page.id === oldPageId && !page.pristine) {
                    await this.updatePage(page)
                    return
                }
            }
        }
    }

    @Watch('pagesLength', { immediate: true })
    onPageLengthChange(length: number) {
        if (length === 0) {
            this.selected = []
            this.page = null
        }
    }
    @Watch('selected.length', { immediate: true })
    onSelectedLengthChange() {
        this.$emit('set', { selected: this.selected })
        for (const page of this.selected) {
            this.refreshPage(page)
        }
    }
    @Watch('sourceEvent')
    async onSourceEventChange() {
        this.headerCache = false
        for (const page of this.selected) {
            page.pristine = false
        }
        this.refreshAllEvents()
        await this.$nextTick()
        this.headerCache = true
    }

    refreshAllEvents() {
        for (const page of this.selected) {
            this.events[String(page.id)] = !this.events[String(page.id)]
        }
        this.event = !this.event
    }

    // START PAGE EDIT (RIGHT SIDE OF THE SCREEN)
    disabledPanelWatcher = false
    panel = 0
    loading = {
        toEdit: false,
        backToPage: false,
        save: false,
        main: false,
    }
    clickedOnChart = false
    hovered: string[] = []
    delayedHovered: string[] = []
    showAddPageBtn: boolean[] = []

    showEditing = false
    showPageSettings = false
    editing: {
        querySelectorTag: QuerySelectorTag
        pageBlockId: string
    } | null = null
    popupWidth = 1000
    height = 400
    tmt = -1
    mapTmt = {}
    maxTitle = 35
    titleFocused = false

    get pageBlocks() {
        return this.page?.pageBlocks || []
    }
    get isLoading() {
        return (
            this.loading.toEdit || this.loading.backToPage || this.loading.save
        )
    }

    get innerSidebar() {
        return this.page?.navigation ? this.sidebar : { sidebar: undefined }
    }

    get hasSidebar() {
        return !!this.sidebar.sidebar
    }

    get title() {
        const alias = this.page?.alias || ''
        return this.mode === PopupMode.INFO
            ? alias
            : this.$t('edit') + ' ' + alias
    }

    get icon() {
        return this.mode === PopupMode.INFO ? PopupMode.EDIT : PopupMode.INFO
    }

    setClickOnChart() {
        this.clickedOnChart = true
        localStorage.setItem('clickedOnChart', 'true')
    }
    setDebouncedPageAlias = debounce(this.setPageAlias, 200, { maxWait: 1200 })
    setPageAlias(s: string) {
        if (!this.page) return
        if (s.length > this.maxTitle)
            this.page.alias = s.substring(0, this.maxTitle)
        else this.page.alias = s
        this.setPristine()
    }
    setPageNavigation(navigation: boolean) {
        this.$emit('set', { sourceEvent: !this.sourceEvent })
        if (this.globalState) this.globalState.setPageNavigation(navigation)
        this.setPristine()
    }
    async setDeviceType(deviceType: DeviceType) {
        if (this.globalState) this.globalState.setDeviceType(deviceType)
        this.$emit('set', { sourceEvent: !this.sourceEvent })
        await this.$nextTick()
    }
    showIntermediateBtn(i: number) {
        if (!this.page) return
        if (i < 0) return false
        if (i >= (this.page.pageBlocks || []).length - 1) return false
        const pageBlocks = this.page.pageBlocks || []
        return (
            this.delayedHovered.includes(pageBlocks[i].id) ||
            this.delayedHovered.includes(pageBlocks[i + 1].id)
        )
    }
    showModeEditBtns(pageBlock: _PageBlock) {
        return this.isDesktop && this.delayedHovered.includes(pageBlock.id)
    }
    get showFirstAddBtn() {
        return (
            this.isDesktop &&
            this.pageBlocks.length < this.maximumPageBlocks &&
            (this.pageBlocks.length === 0 ||
                this.delayedHovered.includes(this.pageBlocks[0].id))
        )
    }
    get showLastAddBtn() {
        return (
            this.isDesktop &&
            this.pageBlocks.length &&
            this.pageBlocks.length < this.maximumPageBlocks &&
            this.delayedHovered.includes(
                this.pageBlocks[this.pageBlocks.length - 1].id
            )
        )
    }
    showAddElementBtn(i: number, hover: boolean) {
        return (
            this.isDesktop &&
            i < this.pageBlocks.length - 1 &&
            (this.showAddPageBtn[i] || hover) &&
            this.pageBlocks.length < this.maximumPageBlocks
        )
    }
    addElement(insertPageBlockAtIndex: number) {
        this.insertPageBlockAtIndex = insertPageBlockAtIndex
        this.show = true
    }
    close() {
        this.$emit('close')
        this.loading.backToPage = true
        setTimeout(() => (this.loading.backToPage = false), 3000)
    }
    async chartClicked(editing: {
        querySelectorTag: QuerySelectorTag
        pageBlockId: string
    }) {
        this.clearTour()
        this.setClickOnChart()
        this.editing = editing
        this.showEditing = true
        await this.$nextTick()
        this.event = !this.event
    }
    setPristine() {
        if (!this.page) return
        this.page.pristine = false
        const id = this.page.id
        clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.saveAfterTimeout(Number(id))
            },
            10000,
            this
        )
        setTimeout(
            () => {
                this.event = !this.event
                if (this.page?.id)
                    this.events[String(this.page.id)] =
                        !this.events[String(this.page.id)]
            },
            100,
            this
        )
    }
    setHeight(h: number) {
        this.height = h
    }
    scroll(scrollType: 'blocks' | 'page-edit-block', id: string) {
        const wrapper = document.getElementById(scrollType + '-wrapper')
        if (!wrapper) return
        const { y: wrapperY } = wrapper.getBoundingClientRect()
        const tags = [scrollType, id]
        if (scrollType === 'blocks') tags.splice(1, 0, '2')
        const element = document.getElementById(tags.join('-'))
        if (!element) return
        const { y } = element.getBoundingClientRect()
        wrapper.scrollBy({ top: y - wrapperY, left: 0, behavior: 'smooth' })
    }
    async setPageHover({ id, value }: { id: string; value: boolean }) {
        if (!value || !this.page) return
        this.scroll('page-edit-block', id)
        const pageBlocks = this.pageBlocks
        for (const i in pageBlocks) {
            if (pageBlocks[i].id == id) {
                this.disabledPanelWatcher = true
                this.panel = Number(i)
                await this.$nextTick()
                this.disabledPanelWatcher = false
                return
            }
        }
    }
    setHover({ id, value }: { id: string; value: string }) {
        clearTimeout(this.mapTmt[id])
        const hovered = this.hovered.filter((h) => h !== id)
        const delayedHovered = this.delayedHovered.filter((h) => h !== id)
        if (value) {
            hovered.push(id)
            delayedHovered.push(id)
            this.delayedHovered = delayedHovered
        } else {
            this.mapTmt[id] = setTimeout(
                () =>
                    (this.delayedHovered = this.delayedHovered.filter(
                        (h) => h !== id
                    )),
                2000
            )
        }
        this.hovered = hovered
    }
    async save() {
        if (!this.page) return
        this.loading.save = true
        await this.savePage(this.page)
            .then(() => {
                this.showToast({
                    code: 'saved',
                    type: TYPE.INFO,
                    timeout: 2000,
                })
            })
            .catch((e) => {
                console.log(e)
                this.showToast({ timeout: 2000 })
            })
        this.loading.save = false
    }
    async savePage(page: _Page) {
        if (!this.isDemoMode) return
        await PageService.updatePage(this.sourceId, page.id, page)
    }
    async toEdit() {
        if (!this.page) return
        this.loading.toEdit = true
        this.$store.commit('SET', { page: this.page })
        this.$store.commit('EDIT', { pages: this.page })
        await this.savePage(this.page)
        this.goTo(`/dashboards/${this.sourceId}/pages/${this.page.id}/edit`)
        this.loading.toEdit = false
    }

    // START CHART TRAITS SIDEBAR
    get pageBlock() {
        return this.pageBlockIndex >= 0
            ? this.pageBlocks[this.pageBlockIndex]
            : undefined
    }
    get querySelectorTag() {
        return this.grayFilter?.querySelectorTag
    }
    get pageBlockIndex() {
        if (!this.grayFilter?.pageBlockId) return -1
        return this.pageBlocks
            .map((p) => p.id)
            .indexOf(this.grayFilter?.pageBlockId)
    }
    get traits() {
        if (!this.querySelectorTag) return []
        const traits =
            this.pageBlock?.traits?.filter((trait) =>
                (trait.querySelectorTags || [0]).includes(
                    this.querySelectorTag || 0
                )
            ) || []
        return traits.sort((a: Trait, b: Trait) => {
            const aType = a.chartType || ''
            const bType = b.chartType || ''
            return aType.localeCompare(bType)
        })
    }

    get chartTypeCount() {
        return new Set(this.traits.map((t) => t.chartType).filter((v) => !!v))
            .size
    }

    showSubheader(i: number) {
        if (this.chartTypeCount <= 1) return
        return i > 0 && this.traits[i - 1].chartType != this.traits[i].chartType
            ? t(`charts.${this.traits[i].chartType}`) + ':'
            : ''
    }
    traitKey(trait: Trait) {
        return traitKey(trait)
    }
    async setTrait(trait: Trait, value: string) {
        if (!this.page) return
        trait.value = value
        trait.pristine = false
        if (!this.pageBlock || !this.traitSearch) return
        this.traitSearch.multipleCharts = this.page.multipleCharts
        this.refreshPageTraits(this.pageBlock, this.traitSearch, trait)
        this.setPristine()
    }
    startTourTimeout(step: 'first-page' | 'page-edit-popup') {
        setTimeout(
            () => {
                registerTour.getTourContext().startTour({ step, force: false })
            },
            1000,
            this
        )
    }

    @Watch('showEditing')
    async onShowEditingChange(showEditing: boolean) {
        if (!showEditing) {
            this.editing = null
            this.startTourTimeout('first-page')
            await this.$nextTick()
            this.event = !this.event
        } else {
            this.startTourTimeout('page-edit-popup')
        }
    }

    // END CHART TRAITS SIDEBAR

    @Watch('hovered', { immediate: true })
    onPageBlockLengthChange(h: string[]) {
        const values: boolean[] = []
        for (let i = 0; i < this.pageBlocks.length; i++) {
            values.push(!!this.showIntermediateBtn(Number(i)))
        }
        if (!this.showAddPageBtn.length || h.length > 0)
            this.showAddPageBtn = values
        else setTimeout(() => (this.showAddPageBtn = values), 500)
    }

    @Watch('panel', { immediate: true })
    onPanelChange(panel?: number) {
        if (panel === undefined || this.disabledPanelWatcher) return
        const pageBlocks = this.pageBlocks || []
        if (panel >= pageBlocks.length) return
        this.scroll('blocks', pageBlocks[panel].id)
    }

    @Watch('step', { immediate: true })
    onStepChange(step: string) {
        if (step === 'pages') {
            for (const page of this.selected) {
                page.pristine = true
            }
            this.refreshAllEvents()
        }
    }
}
