// Utilities to display rotating text in titles :) */

import { isBigSize, toHtmlSafe } from '@/ponychart/utils/functions'

function noHack(s: string) {
    return s
        .replace('{measure}', '')
        .replace('{dimension}', '')
        .replace('{date}', '')
}
export function rotatingTextes(
    texts: string[][],
    reduceSize: number,
    opts: { prefix?: string; colors?: string[][] } = {}
) {
    const colors = opts.colors || []
    const prefix = opts.prefix || ''
    const uniqueTextSet = new Set()
    const uniqueTextes: string[][] = []
    const uniqueColors: string[][] = []
    for (const i in texts) {
        const text = texts[i]
        const uniqueText = JSON.stringify(text)
        if (!uniqueTextSet.has(uniqueText)) {
            uniqueTextSet.add(uniqueText)
            uniqueTextes.push(text)
            uniqueColors.push(colors[i] || [])
        }
    }
    const rotatingTextes = uniqueTextes.map((text: string[], i: number) =>
        rotatingText(text, reduceSize, prefix, uniqueColors[i] || [])
    )
    return `<${prefix}span style="display: inline-block;">${rotatingTextes.join(
        '&nbsp;&#38;&nbsp;'
    )}</${prefix}span>`
}

export function rotatingText(
    texts: string[],
    reduceSize: number,
    prefix: string,
    color: string[]
): string {
    const hasColor = color.length === texts.length && color.length > 0
    const styleTag = prefix ? 'fill' : 'color'

    if (!hasColor && texts.length === 1) return noHack(toHtmlSafe(texts[0]))
    let dataAttribute = isBigSize(reduceSize)
        ? ` data-rotating="${noHack(toHtmlSafe(JSON.stringify(texts)))}"`
        : ' '
    if (hasColor)
        dataAttribute += ` data-rotating-color="${noHack(
            toHtmlSafe(JSON.stringify(color))
        )}"`
    if (texts.length === 1) dataAttribute = ' '
    return `<${prefix}span style="display: inline-block;${
        hasColor ? `${styleTag}: ${color[0]}` : ''
    }"${dataAttribute}>&nbsp;${noHack(
        toHtmlSafe(texts[0])
    )}&nbsp;</${prefix}span>`
}
