export const column4Icon = `<svg version="1.1" x="0px" y="0px"
width="35px" height="35px" viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve">
<g>
 <rect width="6.119" height="35"/>
 <rect x="9.615" width="6.133" height="35"/>
 <rect x="19.245" width="6.116" height="35"/>
 <rect x="28.873" width="6.127" height="35"/>
</g>
</svg>`
export const row2Icon = `<svg version="1.1" x="0px" y="0px"
width="35px" height="35px" viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve">
<g>
 <rect y="0" height="12" width="35"/>
 <rect y="19.245" height="12" width="35"/>
</g>
</svg>`
export const row3Icon = `<svg version="1.1" x="0px" y="0px"
width="35px" height="35px" viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve">
<g>
 <rect y="0" height="7" width="35"/>
 <rect y="14" height="7" width="35"/>
 <rect y="28" height="7" width="35"/>
</g>
</svg>`

export const row4Icon = `<svg version="1.1" x="0px" y="0px"
width="35px" height="35px" viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve">
<g>
 <rect height="6.119" width="35"/>
 <rect y="9.615" height="6.133" width="35"/>
 <rect y="19.245" height="6.116" width="35"/>
 <rect y="28.873" height="6.127" width="35"/>
</g>
</svg>`

export const cardIcon = `<svg version="1.1" x="0px" y="0px"
width="35px" height="35px" viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve">
<g>
 <rect width="35" height="35" style="fill: none; stroke-width: 1; stroke: #FFF"/>
 <rect width="35" height="6.133" style="fill: #FFF" />
</g>
</svg>`

export const lollipopIcon = `<svg viewBox="0 0 100 100">
<rect x="8" y="8" height="4" width="80" style="fill: #FFF;"></rect>
<circle cx="88" cy="10" r="6" style="fill: #FFF;"></circle><rect x="8" y="16" height="4" width="78.97770946761422" style="fill: #FFF;"></rect>
<circle cx="86.97770946761422" cy="18" r="6" style="fill: #FFF;"></circle><rect x="8" y="24" height="4" width="76.17345147262435" style="fill: #FFF;"></rect>
<circle cx="84.17345147262435" cy="26" r="6" style="fill: #FFF;"></circle><rect x="8" y="32" height="4" width="64.63973384067997" style="fill: #FFF;"></rect>
<circle cx="72.63973384067997" cy="34" r="6" style="fill: #FFF;"></circle><rect x="8" y="40" height="4" width="46.314512096646894" style="fill: #FFF;"></rect>
<circle cx="54.314512096646894" cy="42" r="6" style="fill: #FFF;"></circle><rect x="8" y="48" height="4" width="40.77328767833126" style="fill: #FFF;"></rect>
<circle cx="48.77328767833126" cy="50" r="6" style="fill: #FFF;"></circle><rect x="8" y="56" height="4" width="8.647339199988442" style="fill: #FFF;"></rect>
<circle cx="16.647339199988444" cy="58" r="6" style="fill: #FFF;"></circle><rect x="8" y="64" height="4" width="6.041866341310236" style="fill: #FFF;"></rect>
<circle cx="14.041866341310236" cy="66" r="6" style="fill: #FFF;"></circle><rect x="8" y="72" height="4" width="3.746890033125256" style="fill: #FFF;"></rect>
<circle cx="11.746890033125256" cy="74" r="6" style="fill: #FFF;"></circle>
</svg>`

export const barChartIcon = `<svg viewBox="0 0 100 100">
<rect x="8" y="8" height="4" width="80" style="fill: #FFF;"></rect>
<rect x="8" y="16" height="4" width="78.97770946761422" style="fill: #FFF;"></rect>
<rect x="8" y="24" height="4" width="76.17345147262435" style="fill: #FFF;"></rect>
<rect x="8" y="32" height="4" width="64.63973384067997" style="fill: #FFF;"></rect>
<rect x="8" y="40" height="4" width="46.314512096646894" style="fill: #FFF;"></rect>
<rect x="8" y="48" height="4" width="40.77328767833126" style="fill: #FFF;"></rect>
<rect x="8" y="56" height="4" width="8.647339199988442" style="fill: #FFF;"></rect>
<rect x="8" y="64" height="4" width="6.041866341310236" style="fill: #FFF;"></rect>
<rect x="8" y="72" height="4" width="3.746890033125256" style="fill: #FFF;"></rect>
</svg>`

export const doubleBarChartIcon = `<svg viewBox="0 0 200 100">
<rect x="8" y="8" height="4" width="80" style="fill: #FFF;"></rect>
<rect x="8" y="16" height="4" width="78.97770946761422" style="fill: #FFF;"></rect>
<rect x="8" y="24" height="4" width="76.17345147262435" style="fill: #FFF;"></rect>
<rect x="8" y="32" height="4" width="64.63973384067997" style="fill: #FFF;"></rect>
<rect x="8" y="40" height="4" width="46.314512096646894" style="fill: #FFF;"></rect>
<rect x="8" y="48" height="4" width="40.77328767833126" style="fill: #FFF;"></rect>
<rect x="8" y="56" height="4" width="8.647339199988442" style="fill: #FFF;"></rect>
<rect x="8" y="64" height="4" width="6.041866341310236" style="fill: #FFF;"></rect>
<rect x="8" y="72" height="4" width="3.746890033125256" style="fill: #FFF;"></rect>
<rect x="108" y="8" height="4" width="80" style="fill: #FFF;"></rect>
<rect x="108" y="16" height="4" width="78.97770946761422" style="fill: #FFF;"></rect>
<rect x="108" y="24" height="4" width="76.17345147262435" style="fill: #FFF;"></rect>
<rect x="108" y="32" height="4" width="64.63973384067997" style="fill: #FFF;"></rect>
<rect x="108" y="40" height="4" width="46.314512096646894" style="fill: #FFF;"></rect>
<rect x="108" y="48" height="4" width="40.77328767833126" style="fill: #FFF;"></rect>
<rect x="108" y="56" height="4" width="8.647339199988442" style="fill: #FFF;"></rect>
<rect x="108" y="64" height="4" width="6.041866341310236" style="fill: #FFF;"></rect>
<rect x="108" y="72" height="4" width="3.746890033125256" style="fill: #FFF;"></rect>
</svg>`

export const donutIcon = `<svg x="0px" y="0px"
viewBox="0 0 510.921 510.921" style="enable-background:new 0 0 510.921 510.921;">
<g>
<g>
   <g>
       <path d="M275.713,128.609c55.208,8.542,98.042,51.385,106.604,106.615c0.813,5.198,5.271,9.031,10.542,9.031h107.396
           c2.979,0,5.833-1.25,7.854-3.458c2.021-2.198,3.021-5.146,2.771-8.125C500.192,108.588,402.358,10.755,278.254,0.046
           c-2.917-0.271-5.917,0.74-8.125,2.76c-2.208,2.021-3.458,4.875-3.458,7.865v107.396
           C266.671,123.328,270.504,127.807,275.713,128.609z"/>
       <path d="M500.254,265.588H392.858c-5.271,0-9.729,3.833-10.542,9.031c-3.104,20.052-11.083,39.313-23.042,55.708
           c-3.104,4.24-2.646,10.115,1.063,13.833l75.958,75.979c2,2,4.708,3.125,7.542,3.125c0.146,0,0.313,0,0.458-0.01
           c3-0.125,5.792-1.51,7.708-3.792c33.979-40.448,54.334-89.646,58.875-142.292c0.25-2.979-0.75-5.927-2.771-8.125
           C506.088,266.838,503.233,265.588,500.254,265.588z"/>
       <path d="M345.254,359.244c-3.729-3.719-9.646-4.188-13.833-1.063c-22.125,16.188-48.208,24.74-75.417,24.74
           c-70.583,0-128-57.417-128-128c0-63.479,45.542-116.604,108.292-126.313c5.208-0.802,9.042-5.281,9.042-10.542V10.671
           c0-2.99-1.25-5.844-3.458-7.865c-2.208-2.031-5.083-3.042-8.125-2.76C102.671,11.348,0.004,123.307,0.004,254.921
           c0,141.156,114.833,256,256,256c60.063,0,118.479-21.302,164.521-59.99c2.292-1.927,3.667-4.719,3.813-7.708
           c0.125-2.979-1-5.885-3.125-8L345.254,359.244z"/>
   </g>
</g>
</g>
</svg>
`

export const lineChartIcon = `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;">
<g>
   <path d="M501.333,426.667h-416v-416C85.333,4.771,80.563,0,74.667,0H53.333c-5.896,0-10.667,4.771-10.667,10.667v416h-32
       C4.771,426.667,0,431.438,0,437.333v21.333c0,5.896,4.771,10.667,10.667,10.667h32v32c0,5.896,4.771,10.667,10.667,10.667h21.333
       c5.896,0,10.667-4.771,10.667-10.667v-32h416c5.896,0,10.667-4.771,10.667-10.667v-21.333
       C512,431.438,507.229,426.667,501.333,426.667z"/>
   <path d="M124.874,359.541c4.166,4.166,10.919,4.166,15.085,0l105.374-105.374l48.917,48.917c8.33,8.33,21.836,8.33,30.167,0
       L448,179.5v1.833c0,5.896,4.771,10.667,10.667,10.667H480c5.891,0,10.667-4.776,10.667-10.667v-64
       c0-5.891-4.776-10.667-10.667-10.667h-64c-5.891,0-10.667,4.776-10.667,10.667v21.333c0,5.896,4.771,10.667,10.667,10.667h1.833
       l-108.5,108.5l-48.917-48.917c-8.33-8.33-21.836-8.33-30.167,0L109.792,329.374c-4.166,4.166-4.166,10.919,0,15.085
       L124.874,359.541z"/>
</g>
</svg>`
