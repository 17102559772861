export enum PopupMode {
    EDIT = 'edit',
    INFO = 'info',
}

export enum SourceMode {
    DEMO = 'demo',
    CLASSIC = 'classic',
}

export enum StylingMode {
    CLASSIC = 'classic',
    ADMIN = 'admin',
}

export enum DataType {
    INTEGER = 'int',
    TEXT = 'text',
    DOUBLE = 'double',
    DATETIME = 'datetime',
    DATE = 'date',
    BOOLEAN = 'boolean',
}

export type IDate =
    | {
          columnId: string
          alias: string
          twbIdx: number
      }
    | {
          formula: string
          alias: string
          twbIdx: number
      }

export enum FilterType {
    MULTI_SELECT = 'multi-select',
    SINGLE_SELECT = 'single-select',
}

export interface IFilter {
    id: string
    columnId: string
    alias: string
    showApply?: boolean
    type: FilterType
    twbIdx: number
}

export enum ClickEvent {
    MENU_CLICK = 'menu',
    FILTER_CLICK = 'filter',
    OUTSIDE_CLICK = 'outside',
    NAVIGATION_BUTTON = 'navigation',
}
