import axios from 'axios'

export default {
    listResults: async function (sourceId: number, opts: { count?: number, lastKey?: string } = {}) {
        const { data: { results } } = await axios({
            url: `/v1/sources/${sourceId}/results`,
            params: opts
        })
        return results
    },
    getResult: async function (sourceId: number, resultId: string, opts: { includeDownloadUrl?: boolean } = {}) {
        const { data: { result } } = await axios({
            url: `/v1/sources/${sourceId}/results/${resultId}`,
            params: opts
        })
        return result
    },
    checkUserHasDownloadedToday: async function () {
        const {
            data: { downloadsToday },
        } = await axios(`/v1/results-today`)
        return downloadsToday
    }
}
