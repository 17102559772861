
import {
    combineHtmlAndCss,
    PageBlock as _PageBlock,
    PageStructureFactory,
    invertColor,
    isBigSize,
    sToN,
    GlobalState,
    GlobalOptions,
} from '@/ponychart'
import { DeviceType, MAX_QUERY_SELECTOR_TAG, Trait } from 'ponychart'

import Component, { mixins } from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'

import { GlobalMixins, Pages, PageBlockEventListeners } from '@/mixins'
import { reduceSizeClass } from '@/ponychart/page/utils'
import { TraitSearch } from '@/ponychart/state/traits'

@Component
export default class PageBlock extends mixins(
    GlobalMixins,
    Pages,
    PageBlockEventListeners
) {
    @Prop({ type: Object, required: true })
    readonly pageBlock!: _PageBlock
    @Prop({ type: String, required: true })
    readonly pageId!: string
    @Prop({ type: Object, required: true })
    readonly globalOptions!: GlobalOptions
    @Prop({ type: Array, required: true })
    readonly sourceTraits?: Trait[]
    @Prop({ type: Boolean, default: false })
    readonly event!: boolean
    @Prop({ type: Boolean, default: false })
    readonly isHovered?: boolean
    @Prop({ type: Boolean, required: true })
    readonly listenForEvents!: boolean

    html = ''
    built = false
    pageBlockHtmlId: string | null = null
    ratios: { [k: number]: { height: number; width: number } } = {}

    get grayFilter() {
        return this.globalOptions.grayFilter
    }
    get onlyContainer() {
        return !!this.globalOptions.onlyContainer
    }
    get reduceSize() {
        return this.globalOptions.reduceSize || 1
    }
    get pageBlockId() {
        return this.pageBlock.id
    }
    get pageStructure() {
        return this.pageBlock.structure
    }
    get traits() {
        return this.pageBlock.traits
    }
    get dateAggregationLevel(): 'day' | 'month' {
        return this.$store.state?.source?.header?.dateAggregationLevel || 'day'
    }
    get dashColor() {
        return invertColor(this.globalOptions.colors.light_background)
    }
    get enrichedOptions(): GlobalOptions {
        return {
            ...this.globalOptions,
            pageId: this.pageId,
            pageBlockId: this.pageBlockId,
            twbIdx: this.pageBlock.twbIdx,
            pageStructure: this.pageBlock.structure,
            ratios: this.ratios,
        }
    }

    collectRatios() {
        /** After a first rendering, collects the height/width of the drawn element in the pageBlock
         *  in order to redraw SVGs later with better sizing (not 100x100)
         */
        if (!this.pageBlockHtmlId) {
            return
        }
        const pageBlockElement = document.getElementById(this.pageBlockHtmlId)
        if (!pageBlockElement) {
            return
        }
        let found = false
        for (let i = 1; i <= MAX_QUERY_SELECTOR_TAG; i++) {
            const elementKey = `.x-${this.pageBlockHtmlId
                .split('-')
                .pop()}-${i}.x-chart.${reduceSizeClass(
                this.globalOptions.reduceSize || 0
            )}`
            const element = pageBlockElement.querySelector(elementKey)
            if (element) {
                const domRect = element.getBoundingClientRect()
                this.ratios[i] = {
                    height: domRect.height,
                    width: domRect.width,
                }
                found = true
            } else {
                delete this.ratios[i]
            }
        }
        return found
    }
    // Is also being called from Page.vue directly to generate the components
    // from build() which itself is called from Pages.vue
    pageBlockFactory(opts: {
        reduceSize: number
        onlyContainer: boolean
        deviceType?: DeviceType
    }) {
        // TODO: Refresh traits for current pageStructure?
        const globalState = new GlobalState(this.enrichedOptions)
            .setReduceSize(opts.reduceSize)
            .setDeviceType(
                opts.deviceType ||
                    this.enrichedOptions?.deviceType ||
                    DeviceType.DESKTOP
            )
            .setOnlyContainer(opts.onlyContainer)
            .setRatios(this.ratios)

        const structureFactory = new PageStructureFactory()

        const traitSearch = TraitSearch.createInstance(
            this.sourceTraits,
            this.pageBlock.traits,
            globalState
        )

        const pageStructure = structureFactory.buildStructure(
            globalState,
            traitSearch
        )

        const element = pageStructure.buildPonychartElement()
        const height = sToN(element.localState.rawStyles.height)

        this.pageBlockHtmlId = `blocks-${this.reduceSize}-${this.pageBlockId}`

        this.$emit('pageBlockBuilt', {
            id: this.pageBlockId,
            height: height,
            hasAtLeastOneChart: pageStructure.hasAtLeastOneChart,
            twbIdx: globalState.twbIdx,
        })

        return element
    }
    // Called manually from Page.vue component
    build(
        opts: {
            reduceSize: number
            onlyContainer: boolean
            redraw?: boolean
            includeParameters?: boolean
            keys?: ('css' | 'html' | 'styles' | 'components' | 'parameters')[]
        } = {
            reduceSize: -1,
            onlyContainer: false,
            redraw: false,
        }
    ) {
        const keys = opts.keys || [
            'css',
            'html',
            'styles',
            'components',
            'parameters',
        ]
        const hasRatios = Object.keys(this.ratios).length > 0
        if (opts.reduceSize === -1) opts.reduceSize = this.reduceSize
        const redraw = !!opts.redraw

        const ponychartElement = this.pageBlockFactory({
            ...opts,
            onlyContainer:
                opts.onlyContainer || (!hasRatios && !!this.listenForEvents),
        })

        if (!ponychartElement) return

        const { css, html, styles, components, parameters } =
            ponychartElement.compile([...keys, 'css'])

        const combinedHtmlCss = combineHtmlAndCss(
            ponychartElement.id,
            css || new Set(),
            html,
            this.pageBlockId
        )
        if (keys.includes('html')) this.html = combinedHtmlCss

        if (opts?.onlyContainer)
            return {
                css,
                html,
                styles,
                components,
                parameters,
            }

        if (this.listenForEvents) {
            this.$nextTick().then(() => {
                this.attachEvents()
                const ratiosFound = this.collectRatios()
                if (ratiosFound && redraw && !hasRatios) {
                    this.build({
                        reduceSize: opts.reduceSize,
                        onlyContainer: opts.onlyContainer,
                        keys,
                    })
                }
            })
        }
        return {
            html: combinedHtmlCss,
            css,
            parameters,
        }
    }

    @Watch('event', { immediate: true })
    onEventChanged() {
        this.ratios = {}
        this.build({
            onlyContainer: !!this.onlyContainer,
            reduceSize: this.reduceSize,
            redraw: isBigSize(this.reduceSize),
            keys: ['css', 'html'],
        })
    }

    // @Watch('traits', { deep: true })
    // onTraitsChanged() {
    //     this.ratios = {}
    //     this.build({
    //         onlyContainer: !!this.onlyContainer,
    //         reduceSize: this.reduceSize,
    //         redraw: isBigSize(this.reduceSize),
    //         keys: ['css', 'html'],
    //     })
    // }
}
