import { TraitId, AccessRights, RawAccessRights, STYLING_TRAIT_IDS, HEADER_TRAIT_IDS } from "ponychart"


function _includes(value: string[], userRole?: string) {
    if (!userRole) return true
    return value.includes(userRole)
}

function _toRaw(payload: any, userRole?: string) {
    return Object.entries(payload)
        .reduce((acc: any, value: any) => ({
            ...acc,
            [value[0]]: _includes(value[1], userRole)
        }), {})
}

export function accessRightsToRaw(accessRights: AccessRights, userRole?: string): RawAccessRights {
    return {
        logo: _includes(accessRights.logo, userRole),
        colors: _includes(accessRights.colors, userRole),
        traits: _toRaw(accessRights.traits, userRole),
    }
}

export function makeRawAccessRights(accessRights: AccessRights, rawAccessRights: RawAccessRights, role: string) {
    let allSelected = true
    let stylingSelected = true
    let headerSelected = true
    let noSelected = true
    let noStylingSelected = true
    let noHeaderSelected = true
    for (const key in rawAccessRights) {
        if (typeof rawAccessRights[key] === 'boolean') {
            rawAccessRights[key] = !accessRights[key]?.includes(
                role
            )
            allSelected = allSelected && rawAccessRights[key]
            noSelected = noSelected && !rawAccessRights[key]
            if (STYLING_TRAIT_IDS.includes(key as TraitId)) {
                stylingSelected = stylingSelected && rawAccessRights[key]
                noStylingSelected = noStylingSelected && !rawAccessRights[key]
            }
            if (HEADER_TRAIT_IDS.includes(key as TraitId)) {
                headerSelected = headerSelected && rawAccessRights[key]
                noHeaderSelected = noHeaderSelected && !rawAccessRights[key]
            }
        } else if (typeof rawAccessRights[key] === 'string') {
            continue
        } else {
            for (const subkey in rawAccessRights[key]) {
                rawAccessRights[key][subkey] = !accessRights[key][
                    subkey
                ]?.includes(role)
                allSelected =
                    allSelected && rawAccessRights[key][subkey]
                noSelected = noSelected && !rawAccessRights[key][subkey]
                if (STYLING_TRAIT_IDS.includes(key as TraitId)) {
                    stylingSelected = stylingSelected && rawAccessRights[key][subkey]
                    noStylingSelected = noStylingSelected && !rawAccessRights[key][subkey]
                }
                if (HEADER_TRAIT_IDS.includes(key as TraitId)) {
                    headerSelected = headerSelected && rawAccessRights[key][subkey]
                    noHeaderSelected = noHeaderSelected && !rawAccessRights[key][subkey]
                }
            }
        }
    }
    return { allSelected, stylingSelected, headerSelected, noSelected, noStylingSelected, noHeaderSelected }
}

export function disableAllAccessRights(accessRights: AccessRights, role: string, forbiddenKeys: TraitId[]): void {
    for (const key in accessRights.traits) {
        if (forbiddenKeys.includes(key as TraitId)) continue
        if (Array.isArray(accessRights[key])) {
            if (!accessRights[key]?.includes(role))
                accessRights[key].push(role)
        } else {
            for (const subkey in accessRights[key]) {
                if (!Array.isArray(accessRights[key][subkey])) continue
                if (!accessRights[key][subkey]?.includes(role))
                    accessRights[key][subkey].push(role)
            }
        }
    }
}

export function enableAllAccessRights(accessRights: AccessRights, role: string, forbiddenKeys: TraitId[]): void {
    for (const key in accessRights.traits) {
        if (forbiddenKeys.includes(key as TraitId)) continue
        if (Array.isArray(accessRights[key])) {
            accessRights[key] = accessRights[key].filter(
                (r: string) => r !== role
            )
        } else {
            for (const subkey in accessRights[key]) {
                if (!Array.isArray(accessRights[key][subkey])) continue
                accessRights[key][subkey] = accessRights[key][
                    subkey
                ].filter((r: string) => r !== role)
            }
        }
    }
}