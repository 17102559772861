import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Signin from '@/views/Signin.vue'
import Signup from '@/views/Signup.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import Profile from '@/views/Profile.vue'
import Source from '@/views/Source.vue'
import Results from '@/views/Results.vue'

import NotFound from '@/views/NotFound.vue'
import PageTemplate from '@/views/PageTemplate.vue'
import Test from '@/views/Test.vue'

import Login from './login'
import Logout from './logout'
import { STEPS } from '@/config'
import { SourceMode } from '@/ponychart'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: Login,
        meta: { title: 'Ponychart Application' },
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
        beforeEnter: Login,
        meta: { title: 'Ponychart - Your Test' },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        beforeEnter: Login,
        meta: { title: 'Ponychart - Your profile' },
    },
    {
        path: '/dashboards/:source_id',
        redirect: '/dashboards/:source_id/measure',
    },
    {
        path: `/dashboards/:source_id/:route_step(${STEPS.join('|')})`,
        name: 'Create Dashboard',
        component: Source,
        beforeEnter: Login,
        props: true,
        meta: { title: 'Ponychart - Create a dashboard' },
    },
    {
        path: '/dashboards/:source_id/pages/:page_id/template',
        name: 'Edit Page Template',
        component: PageTemplate,
        beforeEnter: Login,
        meta: { title: 'Ponychart - Create a dashboard' },
    },
    {
        path: '/downloads/:source_id/results',
        name: 'Results',
        component: Results,
        beforeEnter: Login,
        meta: { title: 'Ponychart - Create a dashboard' },
    },
    {
        path: '/:lang(fr|es|en|de)/login',
        name: 'Signin',
        component: Signin,
        beforeEnter: Logout,
        meta: { title: 'Ponychart - Login' },
    },
    {
        path: '/:lang(fr|es|en|de)/forgot',
        name: 'Forgot',
        component: ForgotPassword,
        beforeEnter: Logout,
    },
    {
        path: '/:lang(fr|es|en|de)/signup',
        name: 'Signup',
        component: Signup,
        beforeEnter: Logout,
    },
    {
        path: '/:lang(fr|es|en|de)/:mode/:source_id/:route_step',
        name: 'Demo',
        component: Source,
        props: true
    },
    // { path: '/404', name: 'NotFound', component: NotFound },
    { path: '*', name: 'NotFound', component: NotFound },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
