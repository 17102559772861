import { Page } from '@/ponychart/page'
import { SourceMemory } from '@/ponychart/memoize'
import {
    DateAggregationTrait,
    mainTag,
    TRAIT_OPTION_ATTRIBUTES,
} from '@/ponychart/trait'

import { pick } from 'lodash'
import { TraitId, TraitOptionItem } from 'ponychart'

import {
    filterFromAllowedAggregationLevels,
    sortMeasureOrDimension,
} from './utils'

const memoryInstance = SourceMemory.getInstance()

export async function sortPagesHook(pages: Page[]) {
    for (const twbIdx of memoryInstance.twbIndexes) {
        const selectedMeasures = memoryInstance.listMeasures(twbIdx)
        const selectedDimensions = memoryInstance.listDimensions(twbIdx)
        const dateAggregationLevel = memoryInstance.dateAggregationLevel
        const measureItems = selectedMeasures.map((m) =>
            pick(m, TRAIT_OPTION_ATTRIBUTES)
        ) as TraitOptionItem[]
        const dimensionItems = selectedDimensions.map((m) =>
            pick(m, TRAIT_OPTION_ATTRIBUTES)
        ) as TraitOptionItem[]
        const measureIds = selectedMeasures.map((m) => m.id)
        const dimensionIds = selectedDimensions.map((d) => d.id)
        for (const page of pages) {
            for (const pageBlock of page.pageBlocks || []) {
                if (pageBlock.twbIdx !== twbIdx) continue
                for (const trait of pageBlock.traits) {
                    // Sorting is enabled for Measures if QuerySelectorTag == 0
                    // same for dimension
                    if (mainTag(trait.querySelectorTags || [0]) === 0) continue
                    // else, we reapply options & value for Measure, Dimension & Date Aggregation Level
                    if (trait.id === TraitId.MEASURE) {
                        if (!trait.options) trait.options = {}
                        trait.options.items = measureItems
                        trait.value = sortMeasureOrDimension(
                            String(trait.value),
                            measureIds
                        )
                    } else if (trait.id === TraitId.DIMENSION) {
                        if (!trait.options) trait.options = {}
                        trait.options.items = dimensionItems
                        trait.value = sortMeasureOrDimension(
                            String(trait.value),
                            dimensionIds
                        )
                    } else if (trait.id === TraitId.DATE_AGGREGATION_LEVEL) {
                        if (!trait.options) trait.options = {}
                        const allowedAggregationLevels =
                            DateAggregationTrait.allowedDateAggregationLevels(
                                dateAggregationLevel
                            )
                        trait.options.items = allowedAggregationLevels.map(
                            DateAggregationTrait.toTraitItem
                        )
                        trait.value = filterFromAllowedAggregationLevels(
                            String(trait.value),
                            allowedAggregationLevels
                        )
                    }
                }
            }
        }
    }
}
