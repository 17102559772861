import { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import { t, te } from '@/ponychart/i18n'
import {
    ColumnContainer,
    PonychartElement,
    RowContainer,
} from '@/ponychart/element/model'

import { SelectorId } from './types'
import { compileInputSelector, compileRangeSelector } from './compilers'
import { TraitId } from 'ponychart'
import { ParameterTrait } from '../trait'

export class ComparisonPeriodSelector extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.COMPARISON_PERIOD_SELECTOR,
            globalState,
            localState.copy().addClasses(['d-comparison-periods']),
            { traitSearch }
        )
    }

    compileHtml(): string {
        const styles: { [k: string]: string } = {
            width: 'calc(100% - 40px )',
        }
        return compileRangeSelector(
            this.id,
            this.traitSearch
                .getTraitArrayValue(TraitId.COMPARISON_PERIODS)
                .map((id) => ({
                    id,
                    alias: t(`${TraitId.COMPARISON_PERIODS}.${id}`),
                })),
            this.globalState,
            {
                classes: this.classes,
                isDark: !!this.isDarkBackground,
                styles,
            }
        )
    }
}

export class ComparisonPeriodCountListSelector extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.COMPARISON_PERIOD_COUNT_LIST_SELECTOR,
            globalState,
            localState
                .copy()
                .addClasses(['d-comparison-period-count'])
                .addStyles({ width: '40px' })
                .setIsFlex(true),
            { traitSearch }
        )
    }

    compileHtml(): string {
        return compileRangeSelector(
            this.id,
            this.traitSearch?.getTraitRequired(TraitId.COMPARISON_PERIOD_COUNT)
                ?.options?.values || [],
            this.globalState,
            { classes: this.classes, isDark: !!this.isDarkBackground }
        )
    }
}

export class ComparisonPeriodCountRangeSelector extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.COMPARISON_PERIOD_COUNT_RANGE_SELECTOR,
            globalState,
            localState
                .copy()
                .addClasses(['d-comparison-period-count'])
                .addStyles({ width: '40px' })
                .setIsFlex(true),
            { traitSearch }
        )
    }

    compileHtml(): string {
        const trait = this.traitSearch.getTraitRequired(
            TraitId.COMPARISON_PERIOD_COUNT
        )
        return compileInputSelector(this.id, this.globalState, {
            min: trait.options?.allowMinimum
                ? trait.options.minimum
                : undefined,
            max: trait.options?.allowMaximum
                ? trait.options.maximum
                : undefined,
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

// function comparisonTextPrefix(
//     globalState: GlobalState,
//     localState: LocalState,
//     traitSearch: TraitSearch
// ): PonychartElement[] {
//     const output: string[] = []
//     output.push(...(t('ago.prefix') !== "ago.prefix" ? [t('ago.prefix')] : []))
//     const comparisonPeriodTrait = traitSearch.getTraitRequired(
//         TraitId.COMPARISON_PERIODS
//     ) as ParameterTrait
//     const comparisonPeriodCountParameter = traitSearch.getTraitRequired(
//         TraitId.COMPARISON_PERIOD_COUNT
//     ) as ParameterTrait
//     if (comparisonPeriodCountParameter.text !== undefined) {
//         output.push(comparisonPeriodCountParameter.text)
//         if (comparisonPeriodTrait.text !== undefined) {
//             output.push(comparisonPeriodTrait.text)
//             output.push(...(t('ago.suffix') !== "ago.suffix" ? [t('ago.suffix')] : []))
//         }
//     }
//     const width = textMeasurer.measureTextWidth(output.join(' '))
//     return output.length
//         ? [
//             new UnsafeRowContainer(
//                 globalState,
//                 localState.copy().addStyles({ width: nToS(width / 8) })
//                 ,
//                 [],
//                 `<span style="align-self: center">${toHtmlSafe(output.join(' '))}</span>`
//             )
//         ]
//         : []
// }

function comparisonPeriodCountSelector(
    globalState: GlobalState,
    localState: LocalState,
    traitSearch: TraitSearch
): PonychartElement[] {
    const comparisonPeriodCountParameter = traitSearch.getTraitRequired(
        TraitId.COMPARISON_PERIOD_COUNT
    ) as ParameterTrait

    const comparisonPeriods = traitSearch.getTraitArrayValue(
        TraitId.COMPARISON_PERIODS
    )

    const onlyMonths = comparisonPeriods.every((comparisonPeriod) =>
        comparisonPeriod.includes('_y_')
    )

    if (!comparisonPeriodCountParameter.hasParameter || onlyMonths) return []

    if (comparisonPeriodCountParameter.options.mode === 'list') {
        return [
            new ComparisonPeriodCountListSelector(
                globalState,
                localState,
                traitSearch
            ),
        ]
    } else {
        return [
            new ComparisonPeriodCountRangeSelector(
                globalState,
                localState,
                traitSearch
            ),
        ]
    }
}

function comparisonPeriodSelector(
    globalState: GlobalState,
    localState: LocalState,
    traitSearch: TraitSearch
): PonychartElement[] {
    const comparisonPeriodParameter = traitSearch.getTraitRequired(
        TraitId.COMPARISON_PERIODS
    ) as ParameterTrait

    if (!comparisonPeriodParameter.hasParameter) return []

    return [new ComparisonPeriodSelector(globalState, localState, traitSearch)]
}

function comparisonTextSuffix(
    globalState: GlobalState,
    localState: LocalState,
    traitSearch: TraitSearch
): PonychartElement[] {
    const comparisonPeriodTrait = traitSearch.getTraitRequired(
        TraitId.COMPARISON_PERIODS
    ) as ParameterTrait
    const comparisonPeriodCountParameter = traitSearch.getTraitRequired(
        TraitId.COMPARISON_PERIOD_COUNT
    ) as ParameterTrait
    if (
        comparisonPeriodCountParameter.hasParameter &&
        !comparisonPeriodTrait.hasParameter
    )
        return []
    return []
    // return t('ago.suffix') !== 'ago.suffix'
    //     ? [
    //           new RowContainer(
    //               globalState,
    //               localState.addStyles({ width: '40px' }),
    //               { content: t('ago.suffix') }
    //           ),
    //       ]
    //     : []
}

export class ComparisonPeriodElement extends ColumnContainer {
    blockResizePropagation = true
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        const localStatetext = localState
            .copy()
            .addStyles({
                fontSize: '10px',
                paddingTop: '0', // Coupling with .adaptStylePaddingForSelector(height) for dense header
                paddingBottom: '0',
            })
            .setIsCol(true)
            .setIsFlex(true)
        super(globalState, localState.copy().setIsFlex(true), {
            children: [
                new RowContainer(globalState, localStatetext, {
                    frontendSafeContent: `<span style="align-self: flex-end">${t(
                        'compareWith'
                    )}</span>`,
                    content: t('compareWith'),
                    fontRatio: 0.7,
                    unsafe: true,
                }),
                new RowContainer(
                    globalState,
                    localStatetext
                        .copy()
                        .addStyles({ position: 'relative' })
                        .setIsFlex(false),
                    {
                        children: [
                            ...comparisonPeriodCountSelector(
                                globalState,
                                localStatetext,
                                traitSearch
                            ),
                            ...comparisonPeriodSelector(
                                globalState,
                                localStatetext,
                                traitSearch
                            ),
                            ...comparisonTextSuffix(
                                globalState,
                                localStatetext,
                                traitSearch
                            ),
                        ],
                    }
                ),
            ],
            traitSearch,
        })
    }
}
