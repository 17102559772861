
import draggable from 'vuedraggable'
import Upload from '@/components/utils/Upload.vue'
import LogoService from '@/services/logoService'
import ButtonsMoveEdit from '@/components/Pages/ButtonsMoveEdit.vue'
import { GlobalMixins } from '@/mixins'
import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

interface Logo {
    id: string
    source: string | number
    url?: string
}

@Component({
    components: { Upload, ButtonsMoveEdit, draggable },
})
export default class LogosComponent extends mixins(GlobalMixins) {
    logos: Logo[] = []
    currentLogoId: string | null = null
    logoLoading = false
    logoListLoading = false
    dragOptions = {
        group: 'logos',
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    }
    loadingDelete: string[] = []

    get companyId() {
        return this.$store.state.company?.id || ''
    }
    async deleteLogo(id: string) {
        this.loadingDelete.push(id)
        try {
            await LogoService.deleteLogo(id)
            const idx = this.logos.map((logo: any) => logo.id).indexOf(id)
            if (idx !== -1) this.logos.splice(idx, 1)
        } catch (e) {
            this.showToast()
        } finally {
            this.loadingDelete = this.loadingDelete.filter(
                (i: string) => i !== id
            )
        }
    }
    async updateLogoOrder() {
        this.logoListLoading = true
        try {
            await LogoService.updateOrder(
                this.logos.map((logo: any, order: number) => ({
                    logoId: logo.id,
                    order,
                }))
            )
        } catch (e) {
            this.showToast()
        } finally {
            this.logoListLoading = false
        }
    }
    async getLogo(logoId: string, opts: { withUrl?: boolean } = {}) {
        this.logoListLoading = true
        try {
            const logo = await LogoService.getLogo(logoId, opts)
            this.logoListLoading = false
            return logo
        } catch (e) {
            this.showToast()
            this.logoListLoading = false
        }
    }
    async getLogoUrl(
        uuid: string,
        opts = { attempts: 0, timeout: 2000 }
    ): Promise<any> {
        try {
            this.logoLoading = true
            return await LogoService.getLogoUploadUrl(uuid)
        } catch (e) {
            if (!opts.attempts) throw new Error(e as any)
            await this.wait(opts.timeout)
            return this.getLogoUrl(uuid, {
                timeout: opts.timeout * 1.3,
                attempts: opts.attempts - 1,
            })
        } finally {
            this.logoLoading = false
        }
    }
    async wait(timeout = 2000) {
        return new Promise((resolve) =>
            setTimeout(() => resolve(undefined), timeout)
        )
    }
    async listLogos() {
        this.logoListLoading = true
        try {
            this.logos = await LogoService.listLogos({ withUrl: true })
        } catch (e) {
            this.showToast()
        } finally {
            this.logoListLoading = false
        }
    }

    async mounted() {
        await this.listLogos()
        this.$emit('set', { loadingStep: false })
    }

    @Watch('currentLogoId')
    async onCurrentLogoIdChanged(id: string | null) {
        if (!id) return
        await this.getLogoUrl(id, { attempts: 15, timeout: 2000 })
        const logo = await this.getLogo(id, { withUrl: true })
        this.logos.push(logo)
        this.logos = this.logos.sort((a: any, b: any) => a.order - b.order)
    }
}
