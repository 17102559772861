
import { GlobalMixins } from '@/mixins'
import Component, { mixins } from 'vue-class-component'
import ResultService from '@/services/resultService'
import { formatRelative } from 'date-fns'
import { es, fr } from 'date-fns/locale'

interface _Result {
    id: string
    title: string
    downloadUrl?: string
}

@Component({
    components: {},
})
export default class ResultsComponent extends mixins(GlobalMixins) {
    results: _Result[] = []
    hasMore = false
    count = 6
    locales = { es, fr }
    loadings: string[] = []
    loading = false

    get sourceId() {
        return parseInt(this.$route.params.source_id || '0')
    }

    format(d: any) {
        const locale = this.locales[this.$i18n.locale]
            ? { locale: this.locales[this.$i18n.locale] }
            : undefined
        return formatRelative(new Date(d), new Date(), locale)
    }

    mounted() {
        this.listResults()
    }

    async getResult(resultId: string) {
        return await ResultService.getResult(this.sourceId, resultId, {
            includeDownloadUrl: true,
        })
    }

    async download(resultId: string) {
        try {
            this.loadings.push(resultId)
            const result = await this.getResult(resultId)
            for (const r of this.results) {
                if (r.id === resultId) {
                    r.downloadUrl = result.downloadUrl
                }
            }
            const a = document.createElement('a')
            a.href = result.downloadUrl
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } catch (e) {
            this.showToast({})
        } finally {
            this.loadings = this.loadings.filter((id) => id != resultId)
        }
    }

    async listResults(lastKey?: string) {
        this.loading = true
        try {
            const results = await ResultService.listResults(
                this.sourceId,
                lastKey ? { lastKey, count: this.count } : { count: this.count }
            )
            this.hasMore = results.length === this.count
            this.results = this.results.concat(results)
        } catch (e) {
            this.showToast({})
        } finally {
            this.loading = false
        }
    }
}
