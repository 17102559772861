
import { Dict } from '@/store/actions/utils'
import GoogleSignin from '@/components/utils/GoogleSignin.vue'

import { Component, Watch } from 'vue-property-decorator'

import { GlobalMixins } from '@/mixins'

import Vue from 'vue'
import { TYPE } from 'vue-toastification'

@Component({
    components: { GoogleSignin },
})
export default class Signin extends GlobalMixins {
    password = ''
    show = false
    code = ''
    mode = 'code'
    wrong = {
        email: false,
        password: false,
    }
    shaking = {
        email: false,
        password: false,
    }

    confirmUser(username: string, code: string) {
        return this.$store.dispatch('cognito/confirmUser', { username, code })
    }
    async signin() {
        if (this.emailEmpty) {
            this.setWrong('email')
            this.showToast(this.emailEmpty as any)
        } else if (this.emailInvalid(this.email)) {
            this.setWrong('email')
            this.showToast({
                code: 'BAD_EMAIL_FORMAT',
                type: TYPE.WARNING,
                timeout: 4000,
            })
        } else if (this.passwordEmpty) {
            const p = this.$refs.password as Vue & { focus: () => boolean }
            p.focus()
        } else if (this.passwordTooShort) {
            this.setWrong('password')
            this.showToast(this.passwordTooShort as any)
        } else {
            this.SET({ loading: true })
            await new Promise((resolve) => {
                if (!this.show || this.mode === 'new_password') {
                    return resolve(null)
                } else {
                    return resolve(this.confirmUser(this.email, this.code))
                }
            })
                .then(() =>
                    this.$store.dispatch('cognito/signInUser', {
                        username: this.email,
                        password: this.password,
                    })
                )
                .then(async (user) => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        if (!this.code) {
                            this.mode = 'new_password'
                            this.show = true
                            return
                        } else {
                            return await this.$store.dispatch(
                                'cognito/completeNewPassword',
                                {
                                    user,
                                    newPassword: this.code,
                                }
                            )
                        }
                    }
                })
                .catch((err) => {
                    if (err.code == 'UserNotConfirmedException') {
                        err.type = 'info'
                        this.show = true
                        this.showToast(err)
                    } else {
                        this.showToast({
                            code:
                                err.code === 'UserNotFoundException'
                                    ? 'NotAuthorizedException'
                                    : err.code,
                        })
                    }
                })
            this.SET({ loading: false })
        }
    }
    SET(payload: Dict) {
        return this.$store.commit('SET', payload)
    }
    write(e: any, key: 'email' | 'password' | 'code') {
        if (e.keyCode != 13) {
            this.wrong[key] = false
        } else if (key === 'email') {
            const p = this.$refs.password as Vue & { focus: () => boolean }
            p.focus()
        }
    }
    setShaking(key: 'email' | 'password') {
        this.shaking[key] = true
        setTimeout(() => (this.shaking[key] = false), 1000)
    }

    setWrong(key: 'email' | 'password') {
        this.wrong[key] = true
        this.setShaking(key)
    }

    googleSignin() {
        this.$store.dispatch('cognito/googleSignin')
    }

    get loading() {
        return this.$store.state.loading
    }
    get passwordTooShort() {
        return this.password.length < 8
            ? {
                  code: 'PASSWORD_GUIDE_3',
                  type: 'warning',
              }
            : null
    }
    get passwordEmpty() {
        return this.password.length == 0
            ? {
                  code: 'PASSWORD_EMPTY',
                  type: 'warning',
              }
            : null
    }
    get emailEmpty() {
        return this.email.length == 0
            ? {
                  code: 'FIELDS_MISSING',
                  type: 'warning',
              }
            : null
    }
    get redirect() {
        return this.$route.query.redirect &&
            !Array.isArray(this.$route.query.redirect)
            ? this.$route.query.redirect
            : '/'
    }
    get email() {
        return this.$store.state.email
    }
    set email(email: string) {
        this.SET({ email })
    }

    @Watch('isLoggedIn', { immediate: true })
    onLoggedInChange(value: boolean) {
        if (value) {
            this.goTo(this.redirect)
        }
        const query = { ...this.$route.query }
        let pristine = true
        if (typeof query.email === 'string') {
            this.email = query.email
            delete query.email
            pristine = false
        }
        if (typeof query.password === 'string') {
            this.password = query.password
            delete query.password
            pristine = false
        }
        if (!pristine) this.$router.replace({ query })
    }
}
