import axios from 'axios'

export interface TableauSecret {
    url: string
    site?: string
    projectId?: string
    projectName?: string
    tokenName?: string
    encryptedSecretValue: string
    realSecretValueMemory?: string
    realSecretValue?: string
}

export const EMPTY_STRING = new Array(31).join("x");

const LOCAL_STORAGE_KEY = "tableau-secret" as const

export default {
    getTableauSecret: async function () {
        let output
        try{
            const { data: { tableauSecret } } = await axios(`/v1/tableau-secret`)
            output = tableauSecret
        }catch(e){
            output = {}
        }
        const encryptedSecretValue = localStorage.getItem(LOCAL_STORAGE_KEY)
        return {...output, encryptedSecretValue}
    },
    putTableauSecret: async function(tableauSecret: TableauSecret){
        const promises = [
            axios.put(`/v1/tableau-secret`, {
                url: tableauSecret.url,
                site: tableauSecret.site,
                projectId: tableauSecret.projectId,
                projectName: tableauSecret.projectName,
                tokenName: tableauSecret.tokenName
            }).then(({ data }: any) => data.tableauSecret)
        ]
        if (!tableauSecret.encryptedSecretValue || (tableauSecret.realSecretValue && tableauSecret.realSecretValue !== EMPTY_STRING)){
            promises.push(
                axios.post(`/tableau/encrypt`, { secretValue: tableauSecret.realSecretValue }).then(({ data }: any) => {
                    localStorage.setItem(LOCAL_STORAGE_KEY, data.secretValue)
                    return data.secretValue
                })
            )
        }
        const promiseResult = await Promise.all(promises)
        const result: TableauSecret = promiseResult[0]
        if(promiseResult.length > 1){
            result.encryptedSecretValue = promiseResult[1]
        } else {
            result.encryptedSecretValue = tableauSecret.encryptedSecretValue
        }
        return result
    },
    testConnection: async function(){
        const secretValue = localStorage.getItem(LOCAL_STORAGE_KEY)
        console.log(secretValue)
        const { data: { isValid } } = await axios(`/tableau/test-connection`, {params: {secretValue}})
        return isValid
    },
    listProjects: async function () {
        const { data: { projects } } = await axios(`/tableau/projects`, {
            params: { secretValue: localStorage.getItem(LOCAL_STORAGE_KEY) }
        })
        const projectMap = projects.reduce((acc: {[k: string]: any}, project: any) => ({
            ...acc,
            [project.id]: project
        }), {})
        const projectItems = []
        for(const project of projects){
            if(project.parentId){
                const parentProject = projectMap[project.parentId]
                if(!parentProject.children) parentProject.children = []
                delete project.parentId
                parentProject.children.push(project)
            } else {
                projectItems.push(project)
            }
        }
        return {projects: projectItems, projectAliases: projects.reduce((acc: any, p: any) => ({...acc, [p.id]: p.label}), {})}
    },
}
