import { Store } from './utils'
import ColorService from '@/services/colorService'

export default {
  async saveColors({ commit, dispatch }: Store, colors: any[]) {
    commit('SET', { loading: true })
    try {
      const updatedColors = await ColorService.saveColors(colors)
      commit('SET', { company: { colors: updatedColors }, loading: false })
    } catch (e) {
      console.log(e)
      commit('SET', { loading: false })
      dispatch('getColors')
    }
  },
  async getColors({ commit }: Store) {
    const colors = await ColorService.getColors()
    commit('SET', { company: { colors } })
  }
}
