import { PonychartElement } from '@/ponychart/element'
import {
    LineRow,
    TextElementMode,
    TextElementParser,
} from '@/ponychart/textElement'
import { LabelId } from '@/ponychart/label'
import { isBigSize } from '@/ponychart/utils'

import { TraitId } from 'ponychart'

export function initTooltip(
    element: PonychartElement,
    opts: { measureId: string; dimensionId: string }
) {
    const chartId = element.id

    const tooltip =
        (element.traitSearch.getTraitStringValue(TraitId.TOOLTIP) as LabelId) ||
        LabelId.STANDARD_TOOLTIP

    const lines = LineRow.fromDb(tooltip)

    const html = LineRow.toHtml(lines, TextElementMode.TOOLTIP_PREVIEW, opts)

    const tooltipId = `${chartId}-tooltip`

    return isBigSize(element.globalState.reduceSize)
        ? {
              html: `<div id="${tooltipId}" style="display: none">${html}</div>`,
              id: tooltipId,
          }
        : { html: '', id: undefined }
}
