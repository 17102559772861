import { PonychartElement } from '@/ponychart/element'
import { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import { MeasureColorLocation } from '@/ponychart/trait'
import { ChartType, TraitId } from 'ponychart'

import {
    getColorForElement,
    invertColorRaw,
    svgArrayFromNumeric,
    svgMatrixFromNumeric,
} from '@/ponychart/utils'
import { SVGChart } from '@/ponychart/svg'
import { RandomMemory, SourceMemory } from '@/ponychart/memoize'

import { initTooltip } from './utils'

const sourceMemory = SourceMemory.getInstance()

interface BuildPayload {
    tooltipId?: string
    chartType: ChartType.TIME_BAR | ChartType.YOY_TIME_BAR
}

function _buildMatrix(
    element: PonychartElement,
    svgChart: SVGChart,
    opts: BuildPayload
) {
    const dimension = element.getStringRequiredAttribute(TraitId.DIMENSION)
    const measure = element.getStringRequiredAttribute(TraitId.MEASURE)

    const isYoY = opts.chartType == ChartType.YOY_TIME_BAR

    const memory = RandomMemory.getInstance()
    const numericValues = memory.getCumulatedMatrix(measure, dimension)
    const evoValues = memory.getEvoValues(measure, dimension)
    const colorValues = memory.getColors(dimension)
    const maxAxisValue = Math.max(
        ...numericValues.map((row) => Math.max(...row))
    )

    const month = new Date().getMonth()

    const values = svgMatrixFromNumeric(numericValues, {
        ratio: isYoY ? 0.4 : 0.9,
        height: svgChart.height,
        width: svgChart.width,
        percentOfScreen: 0.9,
        maxAxisValue,
    })

    const delta = -0.04 * svgChart.height

    function fromRow(row: any, _h: number) {
        const { x: y, y: x, value: height, height: width } = row
        return { x, y, height, width }
    }

    if (isYoY) {
        const yOyValues = svgMatrixFromNumeric(numericValues, {
            ratio: 0.8,
            height: svgChart.height,
            width: svgChart.width,
            percentOfScreen: 0.9,
            maxAxisValue,
        })

        for (const j in yOyValues[0]) {
            let prev = 0
            let heights = 0
            for (const i in yOyValues) {
                const { x, height, width } = fromRow(
                    yOyValues[i][j],
                    svgChart.height
                )

                const evo = evoValues[i]
                const newHeight = (height - prev) * (1 - evo)
                svgChart.rectangle(
                    { x, y: svgChart.height - (heights + newHeight) + delta },
                    { x: x + width, y: svgChart.height - heights + delta },
                    colorValues[i],
                    {
                        opacity: 0.5,
                        tooltipId: opts.tooltipId,
                        thisMeasure: height,
                        lastMeasure: (1 - evo) * height,
                    }
                )
                prev = height
                heights += newHeight
            }
        }
    }

    for (const j in values[0]) {
        let prev = 0
        for (const i in values) {
            if (Number(j) > month && isYoY) continue
            const { x, height, width } = fromRow(values[i][j], svgChart.height)
            const newX = x + width / 2
            const evo = evoValues[j]
            svgChart.rectangle(
                { x: newX, y: svgChart.height - height + delta },
                { x: newX + width, y: svgChart.height - prev + delta },
                colorValues[i],
                {
                    opacity: 1,
                    tooltipId: opts.tooltipId,
                    thisMeasure: height,
                    lastMeasure: (1 - evo) * height,
                }
            )
            prev = height
        }
    }
}

function _buildArray(
    element: PonychartElement,
    svgChart: SVGChart,
    opts: BuildPayload
) {
    const dimension = ''
    const measure = element.getStringRequiredAttribute(TraitId.MEASURE)

    const isYoY = opts.chartType === ChartType.YOY_TIME_BAR

    const memory = RandomMemory.getInstance()
    const numericValues = memory.getNumericArray(measure, dimension)
    const evoValues = memory.getEvoValues(measure, dimension)
    const month = new Date().getMonth()

    const multipleEvoValues = numericValues.map(
        (v: number, i: number) => v * (1 - evoValues[i % evoValues.length])
    )

    const values = svgArrayFromNumeric(numericValues, {
        ratio: isYoY ? 0.4 : 0.9,
        height: svgChart.height,
        width: svgChart.width,
        percentOfScreen: 0.9,
    })
    const color = getColorForElement(
        element,
        sourceMemory,
        MeasureColorLocation.CURVES,
        { measure }
    )
    const invertedColor = invertColorRaw(color)

    if (isYoY) {
        const yOyValues = svgArrayFromNumeric(multipleEvoValues, {
            ratio: 0.9,
            height: svgChart.height,
            width: svgChart.width,
            percentOfScreen: 0.9,
        })

        for (const i in yOyValues) {
            const { x: y, y: x, value: height, height: width } = yOyValues[i]
            const evo = evoValues[Number(i) % evoValues.length]
            const isNegative = evo < 0
            svgChart.rect(
                x,
                svgChart.height - 1.5 * y - height,
                width,
                height,
                Number(i) === month && isYoY ? invertedColor : color,
                {
                    opacity: 0.5,
                    tooltipId: opts.tooltipId,
                    thisMeasure: height,
                    lastMeasure: (1 - evo) * height,
                }
            )
        }
    }

    for (const i in values) {
        const { x: y, y: x, value: height, height: width } = values[i]
        const evo = evoValues[Number(i) % evoValues.length]
        if (Number(i) > month && isYoY) continue

        svgChart.rect(
            x + width * 0.6,
            svgChart.height - 1.5 * y - height,
            width,
            height,
            Number(i) === month && isYoY ? invertedColor : color,
            {
                opacity: 1,
                tooltipId: opts.tooltipId,
                thisMeasure: height,
                lastMeasure: (1 - evo) * height,
            }
        )
    }
}

function _buildSvg(
    element: PonychartElement,
    chartType: ChartType.TIME_BAR | ChartType.YOY_TIME_BAR
) {
    if (element.globalState.onlyContainer)
        return SVGChart.onlyContainer(element.id, element.classes)
    const svgChart = new SVGChart(chartType, element.ratios)

    const measure = element.getStringAttribute(TraitId.MEASURE)
    const dimension = element.getStringAttribute(TraitId.DIMENSION)

    const { id: tooltipId, html: tooltipHtml } = initTooltip(element, {
        measureId: measure,
        dimensionId: dimension,
    })

    if (!dimension) _buildArray(element, svgChart, { chartType, tooltipId })
    else _buildMatrix(element, svgChart, { chartType, tooltipId })

    return (
        svgChart.rawSvg(element.id, {
            classes: element.classes,
            viewBox: element.ratios,
            preserveAspectRatio: {
                x: 'Mid',
                y: 'Min',
                suffix: 'slice',
            },
        }) + tooltipHtml
    )
}

export class YoYTimeBarChart extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.YOY_TIME_BAR, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return _buildSvg(this, ChartType.YOY_TIME_BAR)
    }
}

export class TimeBarChart extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.TIME_BAR, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return _buildSvg(this, ChartType.TIME_BAR)
    }
}
