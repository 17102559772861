const state = {
    session: '',
    email: '',
    loading: false,
    user: {
        firstName: '',
        lastName: '',
        email: '',
        lang: '',
        role: '',
        id: '',
        accessRole: ''
    },
    subscriptions: [],
    users: [],
    company: {
        name: '',
        stripeEmail: '',
        accessRoles: [],
        region: 'eu-west-3',
        token: 0,
    },
    events: {
        payment: false,
    },
    getting: {
        sources: false,
        user: false,
    },
}

export { state }
export type State = typeof state
