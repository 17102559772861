<template lang="pug">
v-btn.blue--text.text--lighten-2(
    x-small,
    depressed,
    rounded,
    color='white',
    @click='$emit("change", !show)',
    style='cursor: pointer; transform: translateY(-10px)'
) 
    v-icon(x-small) {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
    span.text-decoration-underline {{ $t(show ? "showLess" : "showMore") }}
</template>


<script>
export default {
    name: 'showMoreBtn',
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    model: {
        prop: 'show',
        event: 'change',
    },
}
</script>