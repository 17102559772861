import { Bands, QuerySelectorTag, TraitId } from 'ponychart'

import { PonychartElementHelpers } from '@/ponychart/element/helpers'
import {
    PAGE_BLOCK_HEADER_HEIGHT,
    TITLE_HEIGHT,
    TITLE_STYLE,
} from '@/ponychart/header/config'
import { SourceMemory } from '@/ponychart/memoize'
import { reduceSizeClass } from '@/ponychart/page/utils'
import { BorderBehaviour } from '@/ponychart/pageBlock/border'
import { Bool, MeasureColorLocation } from '@/ponychart/trait'
import {
    getColorFromState,
    isBigSize,
    memoizedInvertColor,
    nToS,
} from '@/ponychart/utils'

import { GlobalState, LocalState } from './state'
import { TraitSearch } from './traits'
import {
    getInnerBodyHeight,
    getTitleHeight,
    getTitleWidth,
} from '../trait/getters'

const memoryInstance = SourceMemory.getInstance()

export function getStyledState(
    globalState: GlobalState,
    traitSearch: TraitSearch
) {
    const colored =
        traitSearch.getTraitValue(TraitId.WITH_COLORS, 0) === Bool.TRUE
    return new LocalState()
        .setStyles(
            PonychartElementHelpers.getStyledTemplateOptions(
                globalState,
                traitSearch,
                colored
            )
        )
        .addClasses(isBigSize(globalState.reduceSize) ? ['x-card'] : [])
}

export function getChartState(
    globalState: GlobalState,
    traitSearch: TraitSearch
) {
    const measureColor =
        traitSearch.getTraitStringValue(TraitId.MEASURE_COLOR) || ''

    return new LocalState()
        .setIsFlex(true)
        .addAttribute(TraitId.MEASURE_COLOR, measureColor) // I don't understand why this is necessary but I was tired
        .addClasses(isBigSize(globalState.reduceSize) ? ['x-chart'] : [])
        .addClasses([reduceSizeClass(globalState.reduceSize)])
        .addStyles({
            background: globalState.colors.background,
            height: '100%',
            width: '100%',
        })
}

export function getTitleState(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    opts: {
        fontSize?: number
        withColor?: boolean
        measure?: string
        querySelectorTag?: QuerySelectorTag
    }
) {
    const borderBehaviour = new BorderBehaviour(traitSearch)

    const background = getColorFromState(
        globalState,
        traitSearch,
        memoryInstance,
        MeasureColorLocation.TITLE,
        { measure: opts.measure }
    )

    const styles: { [k: string]: string } = {
        ...TITLE_STYLE,
        width: getTitleWidth(traitSearch),
        height: getTitleHeight(traitSearch),
        fontSize: opts.fontSize
            ? `${opts.fontSize}px`
            : nToS(
                  (traitSearch.getTraitNumberValue(TraitId.TITLE_HEIGHT, 0) ||
                      TITLE_HEIGHT) / 2
              ),
    }
    if (opts.withColor) {
        styles.background = background
        styles.color = memoizedInvertColor(background)
    }
    const bandWidth = `${borderBehaviour.bandWidth}px`
    if (borderBehaviour.hasCenterBorder) styles.marginBottom = bandWidth
    if (borderBehaviour.hasTitleRightBorder) styles.marginRight = bandWidth
    if (borderBehaviour.hasTitleLeftBorder) styles.marginLeft = bandWidth
    return new LocalState()
        .setStyles(styles)
        .addClasses(isBigSize(globalState.reduceSize) ? ['x-title'] : [])
        .setIsTitle(true)
        .setQuerySelectorTags([opts.querySelectorTag || 0])
}

export function getBlockState(
    globalState: GlobalState,
    traitSearch: TraitSearch
) {
    traitSearch.syncWith(globalState)
    const margin =
        traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X, 0) || 0
    return new LocalState()
        .addStyles({
            backgroundColor: 'transparent',
            paddingLeft: `${margin}px`,
            paddingRight: `${margin}px`,
        })
        .setIsPageBlock(true)
        .setIsFlex(false)
        .addClasses(isBigSize(globalState.reduceSize) ? ['x-block'] : [])
}

export const BLOCK_HEADER_STATE = new LocalState()
    .addStyles({ height: `${PAGE_BLOCK_HEADER_HEIGHT}px`, padding: '0' })
    .setIsPageBlockHeader(true)
    .setIsFlex(true)

/**
 * State for the outer shell of the Card (with borders)
 *
 * @param globalState: GlobalState
 * @param traitSearch: TraitSearch
 * @returns localState: LocalState
 */
export function getOuterBodyState(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    opts: { measure?: string } = {}
) {
    const borderColor = getColorFromState(
        globalState,
        traitSearch,
        memoryInstance,
        MeasureColorLocation.BORDER,
        opts
    )
    const withBorder =
        (traitSearch.getTraitStringValue(TraitId.CARD_HAS_BORDERS, 0) ||
            Bool.FALSE) === Bool.TRUE
    const borderWidth = traitSearch.getTraitNumberValue(TraitId.BORDER, 0) || 0
    const marginWidth = traitSearch.getTraitNumberValue(TraitId.MARGIN, 0) || 0

    const borderBehaviour = new BorderBehaviour(traitSearch)

    const background =
        borderBehaviour.bands.length > 0
            ? borderColor
            : globalState.colors.background

    const width = `${borderBehaviour.bandWidth}px`
    const styles: any = {
        background,
        margin: `${marginWidth}px`,
    }

    if (withBorder) styles.border = `${borderWidth}px solid ${borderColor}`
    if (borderBehaviour.hasBottomBorder) styles.paddingBottom = width
    if (borderBehaviour.hasTopBorder) styles.paddingTop = width
    if (borderBehaviour.hasFullLeftBorder) styles.paddingLeft = width
    if (borderBehaviour.hasFullRightBorder) styles.paddingRight = width
    return new LocalState().setStyles(styles).setIsCard(true)
}

export function getInnerBodyState(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    opts: { querySelectorTag?: QuerySelectorTag; height: number }
) {
    // const borderBehaviour = new BorderBehaviour(traitSearch)
    const noTitle =
        traitSearch.getTraitStringValue(
            TraitId.INCLUDE_CARD_TITLE,
            opts.querySelectorTag
        ) === Bool.FALSE
    const styles: { [k: string]: string } = {
        // /!\ WARNING/!\ Trick here:
        // It would make more sense to return height: getInnerBodyHeight(traitSearch, noTitle)
        // however, this has unexpected side effects in Tableau when rendering the card with a chart which
        // does not take the full height of the card.
        // This is why we "force" the height to be the same as the card height (which is never the case really)
        // and we "block" the height by using max-height CSS property so it does not grow.
        // It means that in Tableau, the height of the inner body of the card will try to "grow" just like it
        // tries to grow in CSS.
        height: nToS(opts.height),
        maxHeight: getInnerBodyHeight(traitSearch, noTitle),
        background: globalState.colors.background,
    }

    return new LocalState().setStyles(styles).setIsFlex(true)
}
