import { FlexType } from '@/ponychart/element/types'
import { PageMode } from '@/ponychart/page/types'
import { LabelId } from '@/ponychart/label/types'
import { TraitId, ChartType } from 'ponychart'

export type AttributesToUpdate =
    | 'hidden'
    | 'label'
    | 'value'
    | 'querySelectorTags'
    | '*'
    | '!value'
    | 'options.items'
    | 'options.sortable'
    | 'options.allowNone'
    | 'options.allowMultiple'
    | 'options.maxCount'
    | 'options.hasDimension'
    | 'options.hasDate'

export interface TraitMapPayload {
    [TraitId.MEASURE]?: string
    [TraitId.DIMENSION]?: string
    [TraitId.PAGE_MODE]?: PageMode
    [TraitId.COLOR]?: ColorLegend
    [TraitId.CHART_SUBTYPE]?: OverBarMode | YoYBarMode | BandMode | GeoMode
    [TraitId.CHART_1]?: ChartType
    [TraitId.CHART_2]?: ChartType
    [TraitId.CHART_3]?: ChartType
    [TraitId.CHART_4]?: ChartType
    [TraitId.CHART_5]?: ChartType
    [TraitId.COLUMN_COUNT]?: string
    [TraitId.TOOLTIP]?: LabelId
    [TraitId.LABEL]?: LabelId
    [TraitId.CARD_HEIGHT]?: string
    [TraitId.GEO_CITY]?: string
    [TraitId.GEO_COUNTRY]?: string
    [TraitId.GEO_REGION]?: string
}

// DEPRECATED/NOT IMPLEMENTED
export enum TdPeriod {
    MTD = 'mtd',
    WTD = 'wtd',
    QTD = 'qtd',
    YTD = 'ytd',
}

export enum TimeSelectorComparison {
    DELTA_PERIOD = 'period',
    DELTA_MONTH = 'month',
    DELTA_QUARTER = 'quarter',
    DELTA_WEEK = 'week',
    DELTA_DAY = 'day',
    DELTA_YEAR = 'year',
}

export enum GeoMode {
    COUNTRY = 'country',
    REGION = 'region',
    CITY = 'city',
}

export enum BandMode {
    BAND = 'band',
    NONE = 'none_band',
}

export enum YoYBarMode {
    NONE = 'none_yoy_mode',
    PERIOD_COLOR = 'period_color',
}

export enum OverBarMode {
    OVER_BAR = 'over_bar',
    NONE = 'none_over_bar',
}

export enum Bool {
    TRUE = 'true',
    FALSE = 'false',
}

export enum WithLabel {
    WITH_LABEL = 'with_label',
    NONE = 'none_with_label',
}

export enum ColumnType {
    DYNAMIC = 'dynamic',
    SINGLE = 'single',
}

export enum ColorLegend {
    DELTA = 'delta',
    FIXED = 'fixed',
    DIMENSION = 'dimension',
    VOLUME = 'volume',
}

export enum DateAggregationLevel {
    YEAR = 'year',
    QUARTER = 'quarter',
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day',
}

export enum MeasureColorLocation {
    TITLE = 'title',
    BORDER = 'border',
    CURVES = 'curves',
}

export const TRAIT_OPTION_ATTRIBUTES = [
    'id',
    'alias',
    'name',
    'format',
    'reversed',
    'disabled',
    'twbIdx',
] as const
