<template lang="pug">
v-container
    v-card-title
        span {{ $t("invoices") }}
        v-spacer
        //- v-text-field(
        //-     dense,
        //-     v-model='stripeEmail',
        //-     @input='pristine = false',
        //-     :error-messages='disabled ? [$t("errors.BAD_EMAIL_FORMAT")] : []',
        //-     :label='$t("sendInvoices")',
        //-     :append-icon='!pristine ? "save" : undefined',
        //-     :loading='saving'
        //- )
        //-     template(v-slot:append)
        //-         v-btn(icon, @click='save', v-if='!saving && !pristine')
        //-             v-icon save
    v-data-table.elevation-1(
        :headers='headers',
        :items='invoices',
        :loading='loading',
        :no-data-text='$t("noResults")'
    )
        template(v-slot:item.status='{ item }')
            v-chip(:color='getColor(item.status)', dark) {{ item.status }}
        template(v-slot:item.total='{ item }')
            span {{ number(item) }}
            span {{ currency(item.currency) }}
        template(v-slot:item.created='{ item }')
            span {{ created(item) }}
        template(v-slot:item.period='{ item }')
            span {{ period(item) }}
        template(v-slot:item.invoice_pdf='{ item }')
            a(
                style='text-decoration: none',
                small,
                :loading='loadings.includes(item.number)',
                icon,
                :href='item.invoice_pdf'
            )
                v-icon.primary--text(color='primary') file_download
</template>

<script>
import format from 'date-fns/format'
import InvoiceService from '@/services/invoiceService'
export default {
    name: 'invoicesComponent',
    data: () => ({
        invoices: [],
        pristine: true,
        saving: false,
        loading: false,
        loadings: [],
    }),
    computed: {
        stripeEmail: {
            get() {
                return this.$store.state?.company?.stripeEmail || ''
            },
            set(stripeEmail) {
                this.$store.commit('SET', { company: { stripeEmail } })
            },
        },
        disabled() {
            return this.emailInvalid(this.stripeEmail)
        },
        headers() {
            return [
                'number',
                'status',
                'customer_email',
                'total',
                'period',
                'invoice_pdf',
            ].map((value) => ({
                text: value === 'invoice_pdf' ? '' : this.$t(value),
                sortable: value !== 'invoice_pdf',
                value,
            }))
        },
    },
    methods: {
        async save() {
            this.saving = true
            try {
                await this.$store.dispatch('saveCompany')
                this.pristine = true
            } catch (e) {
                const er = e?.response?.data || {}
                this.showToast(er)
            }
            this.saving = false
        },
        created(item) {
            return this.titlecase(format(item.created * 1000, 'dd/MM/yy'))
        },
        period(item) {
            return format(item.period_start * 1000, 'dd/MM/yyyy')
            // return `${format(item.period_start * 1000, 'dd/MM/yy')} ${this.$t(
            //   'to'
            // )} ${format(item.period_end * 1000, 'dd/MM/yy')}`
        },
        currency(c) {
            if (!c) return ''
            return (
                {
                    usd: '$',
                    eur: '€',
                }[c] || c
            )
        },
        number(item) {
            return item.total ? item.total / 100 : 0
        },
        async download(item) {
            this.loadings.push(item.number)
            const response = await fetch(item.invoice_pdf, {
                method: 'GET',
            })
            const blob = response.blob()
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = item.number + '.pdf'
            document.body.appendChild(a)
            a.click()
            a.remove()
            this.loadings = []
        },
        getColor(status) {
            return (
                {
                    paid: 'green',
                    canceled: 'orange',
                    draft: 'gray',
                    open: 'blue',
                    uncollectible: 'red',
                    void: 'red',
                }[status] || 'orange'
            )
        },
        async listInvoices() {
            this.loading = true
            this.invoices = await InvoiceService.listInvoices()
            this.loading = false
        },
    },
    async mounted() {
        await this.listInvoices()
        this.$emit('set', { loadingStep: false })
    },
}
</script>

<i18n>{
  "fr": {
    "sendInvoices": "Envoi des factures à:",
    "from": "De",
    "to": "à",
    "customer_email": "Email client",
    "number": "# de facture",
    "period": "Période",
    "invoice_pdf": "Télécharger",
    "total": "Total",
    "noResults": "Vous n'avez pas encore de factures..."
  },
  "es": {
    "sendInvoices": "Envio de las factures a:",
    "from": "Desde",
    "to": "hasta",
    "customer_email": "Correo cliente",
    "number": "# de factura",
    "period": "Periodo",
    "invoice_pdf": "Descargar",
    "total": "Total",
    "noResults": "Aún no tienes facturas..."
  },
  "en": {
    "sendInvoices": "Send invoices to:",
    "from": "From",
    "to": "to",
    "customer_email": "Customer email",
    "number": "Invoice #",
    "period": "Period",
    "invoice_pdf": "Download",
    "total": "Total",
    "noResults": "You don't have any invoices yet..."
  },
  "de": {
    "sendInvoices": "Rechnungen schicken an:",
    "from": "Von",
    "to": "zu",
    "customer_email": "E-Mail-Adresse des Kunden",
    "number": "Rechnung #",
    "period": "Zeitraum",
    "invoice_pdf": "Herunterladen",
    "total": "Insgesamt",
    "noResults": "Sie haben noch keine Rechnungen..."
  }
}</i18n>