import { TraitId } from 'ponychart'
import {
    PageStructure,
    REPETITIVE_PAGE_STRUCTURE,
} from '@/ponychart/page/types'
import { t } from '@/ponychart/i18n'

import { memoize } from 'lodash'

const MAP: { [s in PageStructure]: { asRow: boolean; traitIds: TraitId[][] } } =
    {
        [PageStructure.SIMPLE]: { asRow: false, traitIds: [[TraitId.CHART_1]] },
        [PageStructure.KPI_CARD_2_COL]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2]],
        },
        [PageStructure.KPI_CARD_2_ROW]: {
            asRow: true,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2]],
        },
        [PageStructure.KPI_CARD_3_COL]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3]],
        },
        [PageStructure.KPI_CARD_3_ROW]: {
            asRow: true,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3]],
        },
        [PageStructure.KPI_CARD_3_TOP]: {
            asRow: true,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2], [TraitId.CHART_3]],
        },
        [PageStructure.KPI_CARD_3_DOWN]: {
            asRow: true,
            traitIds: [[TraitId.CHART_1], [TraitId.CHART_2, TraitId.CHART_3]],
        },
        [PageStructure.KPI_CARD_3_LEFT]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2], [TraitId.CHART_3]],
        },
        [PageStructure.KPI_CARD_3_RIGHT]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1], [TraitId.CHART_2, TraitId.CHART_3]],
        },
        [PageStructure.KPI_CARD_4]: {
            asRow: false,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2],
                [TraitId.CHART_3, TraitId.CHART_4],
            ],
        },
        [PageStructure.KPI_CARD_4_COL]: {
            asRow: false,
            traitIds: [
                [
                    TraitId.CHART_1,
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                ],
            ],
        },
        [PageStructure.KPI_CARD_4_ROW]: {
            asRow: true,
            traitIds: [
                [
                    TraitId.CHART_1,
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                ],
            ],
        },
        [PageStructure.KPI_CARD_4_TOP]: {
            asRow: true,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3],
                [TraitId.CHART_4],
            ],
        },
        [PageStructure.KPI_CARD_4_DOWN]: {
            asRow: true,
            traitIds: [
                [TraitId.CHART_1],
                [TraitId.CHART_2, TraitId.CHART_3, TraitId.CHART_4],
            ],
        },
        [PageStructure.KPI_CARD_4_LEFT]: {
            asRow: false,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3],
                [TraitId.CHART_4],
            ],
        },
        [PageStructure.KPI_CARD_4_RIGHT]: {
            asRow: false,
            traitIds: [
                [TraitId.CHART_1],
                [TraitId.CHART_2, TraitId.CHART_3, TraitId.CHART_4],
            ],
        },
        [PageStructure.UNIQUE_1]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1]],
        },
        [PageStructure.UNIQUE_2_ROW]: {
            asRow: true,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2]],
        },
        [PageStructure.UNIQUE_2_COL]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2]],
        },
        [PageStructure.UNIQUE_3_ROW]: {
            asRow: true,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3]],
        },
        [PageStructure.UNIQUE_3_COL]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3]],
        },
        [PageStructure.UNIQUE_3_DOWN]: {
            asRow: true,
            traitIds: [[TraitId.CHART_1], [TraitId.CHART_2, TraitId.CHART_3]],
        },
        [PageStructure.UNIQUE_3_TOP]: {
            asRow: true,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2], [TraitId.CHART_3]],
        },
        [PageStructure.UNIQUE_3_LEFT]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1, TraitId.CHART_2], [TraitId.CHART_3]],
        },
        [PageStructure.UNIQUE_3_RIGHT]: {
            asRow: false,
            traitIds: [[TraitId.CHART_1], [TraitId.CHART_2, TraitId.CHART_3]],
        },
        [PageStructure.UNIQUE_4]: {
            asRow: true,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2],
                [TraitId.CHART_3, TraitId.CHART_4],
            ],
        },
        [PageStructure.UNIQUE_4_ROW]: {
            asRow: true,
            traitIds: [
                [
                    TraitId.CHART_1,
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                ],
            ],
        },
        [PageStructure.UNIQUE_4_COL]: {
            asRow: false,
            traitIds: [
                [
                    TraitId.CHART_1,
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                ],
            ],
        },
        [PageStructure.UNIQUE_4_DOWN]: {
            asRow: true,
            traitIds: [
                [TraitId.CHART_1],
                [TraitId.CHART_2, TraitId.CHART_3, TraitId.CHART_4],
            ],
        },
        [PageStructure.UNIQUE_4_TOP]: {
            asRow: true,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3],
                [TraitId.CHART_4],
            ],
        },
        [PageStructure.UNIQUE_4_LEFT]: {
            asRow: false,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3],
                [TraitId.CHART_4],
            ],
        },
        [PageStructure.UNIQUE_4_RIGHT]: {
            asRow: false,
            traitIds: [
                [TraitId.CHART_1],
                [TraitId.CHART_2, TraitId.CHART_3, TraitId.CHART_4],
            ],
        },
        [PageStructure.UNIQUE_5_ROW]: {
            asRow: true,
            traitIds: [
                [
                    TraitId.CHART_1,
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                    TraitId.CHART_5,
                ],
            ],
        },
        [PageStructure.UNIQUE_5_COL]: {
            asRow: false,
            traitIds: [
                [
                    TraitId.CHART_1,
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                    TraitId.CHART_5,
                ],
            ],
        },
        [PageStructure.UNIQUE_5_2_DOWN]: {
            asRow: true,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2],
                [TraitId.CHART_3, TraitId.CHART_4, TraitId.CHART_5],
            ],
        },
        [PageStructure.UNIQUE_5_1_DOWN]: {
            asRow: true,
            traitIds: [
                [TraitId.CHART_1],
                [
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                    TraitId.CHART_5,
                ],
            ],
        },
        [PageStructure.UNIQUE_5_2_TOP]: {
            asRow: true,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3],
                [TraitId.CHART_4, TraitId.CHART_5],
            ],
        },
        [PageStructure.UNIQUE_5_1_TOP]: {
            asRow: true,
            traitIds: [
                [
                    TraitId.CHART_1,
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                ],
                [TraitId.CHART_5],
            ],
        },
        [PageStructure.UNIQUE_5_2_RIGHT]: {
            asRow: false,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2],
                [TraitId.CHART_3, TraitId.CHART_4, TraitId.CHART_5],
            ],
        },
        [PageStructure.UNIQUE_5_1_RIGHT]: {
            asRow: false,
            traitIds: [
                [TraitId.CHART_1],
                [
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                    TraitId.CHART_5,
                ],
            ],
        },
        [PageStructure.UNIQUE_5_2_LEFT]: {
            asRow: false,
            traitIds: [
                [TraitId.CHART_1, TraitId.CHART_2, TraitId.CHART_3],
                [TraitId.CHART_4, TraitId.CHART_5],
            ],
        },
        [PageStructure.UNIQUE_5_1_LEFT]: {
            asRow: false,
            traitIds: [
                [
                    TraitId.CHART_1,
                    TraitId.CHART_2,
                    TraitId.CHART_3,
                    TraitId.CHART_4,
                ],
                [TraitId.CHART_5],
            ],
        },
    }

export function translateStructure(structure: PageStructure) {
    const chartCount = structureChartCount(structure)
    const s = chartCount > 1 ? 's' : ''
    const chartAlias = REPETITIVE_PAGE_STRUCTURE.includes(structure)
        ? t(`chart${s}PerMeasure`)
        : t(`chart${s}Alias`)

    const structureWords = structure.split('_')
    const lastWord = structureWords[structureWords.length - 1]
    if (!['top', 'down', 'left', 'right'].includes(lastWord)) {
        const message = [chartCount + ' ' + chartAlias]
        if (['col', 'row'].includes(lastWord)) message.push(t(lastWord))
        return message.join(',\n')
    }
    const reversedWords = {
        top: 'down',
        down: 'top',
        left: 'right',
        right: 'left',
    }
    const lastNumber = structureWords[structureWords.length - 2]
    const previousNumber = structureWords[structureWords.length - 3]
    const lastCount = !isNaN(+previousNumber) ? parseInt(lastNumber) : 1
    return [
        chartCount - lastCount + ' ' + t(lastWord),
        lastCount + ' ' + t(reversedWords[lastWord]),
    ].join(',\n')
}

export function structureChartTypes(structure: PageStructure): TraitId[][] {
    return MAP[structure]?.traitIds || []
}

export function structureAsRow(structure: PageStructure): boolean {
    return MAP[structure]?.asRow || false
}

export const structureChartCount = memoize(
    (structure: PageStructure): number => {
        let count = 0
        const traitIds = MAP[structure]?.traitIds || []
        for (let i = 0; i < traitIds.length; i++) {
            for (let j = 0; j < traitIds[i].length; j++) count++
        }
        return count
    }
)

export function defaultCardHeight(structure: PageStructure): number {
    if (REPETITIVE_PAGE_STRUCTURE.includes(structure)) {
        if (structure.includes('row')) return 400
        return 600
    } else {
        const asRow = structureAsRow(structure)
        const chartTypes = structureChartTypes(structure)
        const count = !asRow
            ? Math.max(...chartTypes.map((c) => c.length))
            : chartTypes.length
        return Math.min(500, Math.floor(7 / count) * 100) // 1200px as a default Page Block
    }
}

export function defaultColumnCount(structure: PageStructure): number {
    if (structure.includes('row')) return 2
    return 3
}
