import { ColumnContainer, PonychartElement, RowContainer } from "@/ponychart/element/model"
import { GlobalState, LocalState } from "@/ponychart/state"

export enum FillMode {
    CENTER = "center",
    END = "end",
    START = "start"
}

const DEFAULT_STATE = new LocalState().setIsFlex(true)

function loop<T>(elements: T[], insertElement: () => T, opts: { count: number, useSplice?: boolean, useBoth?: boolean }) {
    while (elements.length < opts.count) {
        if (opts.useSplice || opts?.useBoth) elements.splice(0, 0, insertElement())
        if (!opts.useSplice || opts?.useBoth) elements.push(insertElement())
    }
    return elements
}

export function fillArrayStart(elements: PonychartElement[][], count: number): PonychartElement[][] {
    return loop(elements, () => [], { count, useSplice: true })
}

function fillEnd(
    globalState: GlobalState,
    elements: PonychartElement[],
    count: number,
    Element: typeof RowContainer | typeof ColumnContainer
) {
    return loop(elements, () => new Element(globalState, DEFAULT_STATE), { count })
}

function fillStart(
    globalState: GlobalState,
    elements: PonychartElement[],
    count: number,
    Element: typeof RowContainer | typeof ColumnContainer
) {
    return loop(elements, () => new Element(globalState, DEFAULT_STATE), { count, useSplice: true })
}

function fillCenter(
    globalState: GlobalState,
    elements: PonychartElement[],
    count: number,
    Element: typeof RowContainer | typeof ColumnContainer
) {
    return loop(elements, () => new Element(globalState, DEFAULT_STATE), { count, useBoth: true })
}

export function fill(
    globalState: GlobalState,
    elements: PonychartElement[],
    fillMode: FillMode,
    count: number,
    Element: typeof RowContainer | typeof ColumnContainer
) {
    const MODES = {
        [FillMode.CENTER]: fillCenter,
        [FillMode.END]: fillEnd,
        [FillMode.START]: fillStart
    }
    return MODES[fillMode](globalState, elements, count, Element)
}
