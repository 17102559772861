


export function sortMeasureOrDimension(measure: string, optionIds: string[]) {
    const measureArray = measure.split(";")
    return measureArray.sort((a, b) => (optionIds.indexOf(a) - optionIds.indexOf(b))).join(";")
}

export function filterFromAllowedAggregationLevels(value: string, allowedDateAggregationLevels: string[]) {
    const values = value.split(";")
    const filteredValues = values.filter(v => allowedDateAggregationLevels.includes(v))
    if (filteredValues.length == 0) filteredValues.push(allowedDateAggregationLevels[0])
    return filteredValues.join(";")
}