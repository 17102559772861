
import { TraitId, HeaderDesign, Trait, RawAccessRights } from 'ponychart'
import { GlobalMixins, Traits } from '@/mixins'
import PageTrait from '@/components/Pages/PageTrait.vue'
import { IFilter, t } from '@/ponychart'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
    components: { PageTrait },
})
export default class HeaderEditorComponent extends mixins(
    GlobalMixins,
    Traits
) {
    @Prop({ type: Array, required: true })
    readonly sourceTraits!: Trait[]
    @Prop({ type: Object, required: true })
    readonly accessRights!: RawAccessRights['traits']
    @Prop({ type: Boolean, required: true })
    readonly event!: boolean
    @Prop({ type: Boolean, default: false })
    readonly adminMode!: boolean
    @Prop({ type: Array, default: () => [] })
    readonly filters!: IFilter[]

    heightPristine = true

    getTrait(traitId: TraitId) {
        // If timeout is set, then return undefined
        // to fetch the trait again (from lack of reactivity)
        return this.sourceTraits.find((trait) => trait.id === traitId)
    }
    getValue(traitId: TraitId) {
        return this.getTrait(traitId)?.value
    }
    setTraitValue(trait: Trait, value: number | string) {
        trait.value = value
        trait.pristine = false
        this.refreshSourceTraits(this.sourceTraits, { emit: true })
    }

    setAllTraitValues(trait: Trait, value: number | string) {
        for (const sourceTrait of this.sourceTraits) {
            if (sourceTrait.id !== trait.id) continue
            sourceTrait.value = value
            sourceTrait.pristine = false
        }
        this.refreshSourceTraits(this.sourceTraits, { emit: true })
    }

    get heightTrait() {
        return this.getTrait(TraitId.HEADER_HEIGHT)
    }
    get withLogoTrait() {
        return this.getTrait(TraitId.WITH_LOGO)
    }
    get withColorsTrait() {
        return this.getTrait(TraitId.WITH_COLORS)
    }
    get withTimePeriodTrait() {
        return this.getTrait(TraitId.WITH_TIME_PERIOD)
    }
    get headerDesignTrait() {
        return this.getTrait(TraitId.HEADER_DESIGN)
    }
    get densifyTrait() {
        return this.getTrait(TraitId.DENSIFY_HEADER_FILTERS)
    }
    get allowForDensify() {
        const design = this.designItems.map((d) => d.value)[this.designIdx]
        return design !== HeaderDesign.DENSE && this.filters.length > 0
    }
    t(value: string, prefix = 'selects') {
        return { text: t(prefix + '.' + value, this.lang), value }
    }
    get design() {
        return this.getValue(TraitId.HEADER_DESIGN)
    }
    get designIdx() {
        return this.designItems.map((d: any) => d.value).indexOf(this.design)
    }
    get designItems() {
        return [
            HeaderDesign.CENTERED,
            HeaderDesign.SIMPLE,
            HeaderDesign.SIMPLE_VERTICAL,
            HeaderDesign.DENSE,
        ]
            .map((v) => this.t(v))
            .map(({ value, text }, i) => ({
                value,
                text: `${this.$t('design')} ${String.fromCharCode(
                    97 + i
                ).toUpperCase()} (${text})`,
            }))
    }
}
