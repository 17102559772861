import { flexHook } from '@/ponychart/element/flex'
import { Vue, Component } from 'vue-property-decorator'

@Component
export class Editor extends Vue {
    editor: any = null
    postEditor() {
        const self: any = this
        this.editor.on('storage:load', () => {
            this.editor.render()
            this.editor.Panels.getButton('views', 'open-blocks').set(
                'active',
                true
            )
        })
        // this.editor.on('change', this.change)
        this.editor.Panels.removeButton('options', 'sw-visibility')
        // this.editor.Panels.removeButton('options', 'export-template')
        this.editor.Panels.removeButton('options', 'preview')
        this.editor.Panels.removeButton('views', 'open-layers')
        this.editor.Panels.addButton('options', {
            id: 'saveButton',
            className: 'fas fa fa-save',
            attributes: { title: this.$t('save') },
            command: async function (editor: any) {
                try {
                    self.saving = 'fa-save'
                    await editor.store()
                    // TODO: Add loading animation
                } catch (e) {
                    console.log(e)
                    self.showToast({ code: 'UNKNOWN' })
                }
            },
        })
        this.editor.Panels.addButton('options', {
            id: 'switchToEditMode',
            className: 'fas fa fa-eraser',
            attributes: { title: this.$t('switchToEdit') },
            command: async function (editor: any) {
                self.saving = 'fa-eraser'
                await editor.store()
            },
        })
        this.editor.Panels.addButton('options', {
            id: 'backToPages',
            className: 'fas fa fa-arrow-circle-right',
            attributes: { title: this.$t('backToPages') },
            command: async function (editor: any) {
                try {
                    self.saving = 'fa-arrow-circle-right'
                    await editor.store()
                } catch (e) {
                    console.log(e)
                    self.showToast({ code: 'UNKNOWN' })
                }
            },
        })
        this.editor.getConfig().showDevices = false
        this.editor.DomComponents.getWrapper().set('style', {
            'background-color': self.styling.colors.light_background,
        })
        this.editor.on('sorter:drag:end', ({ modelToDrop }: any) => {
            flexHook()(modelToDrop)
        })
        this.editor.on('component:add', flexHook())
        this.editor.load()
        this.editor.on('load', () => {
            const el = this.editor.Canvas.getDocument().head
            for (const src of ['/script.js']) {
                const scriptEl = document.createElement('script')
                scriptEl.src = src
                el.appendChild(scriptEl)
            }
        })
    }
}
