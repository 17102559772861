<template lang="pug">
v-card(outlined, width='300', @click.stop='$emit("click")', :ripple='false'
:style='selected ? `border: 5px solid ${color}` : "cursor: pointer"'
)
    //- v-row(align='center', justify='center')

    v-card-title.white--text.mt-8(
        :style='`background-color: ${color}; border-top-left-radius: 0; border-top-right-radius: 0`'
    )
        v-row(justify='center') {{ name }}
    v-row.mx-4.mt-6(align='center', justify='center')
        h1.d-flex.flex-row.align-top.justify-start.pb-2.text--secondary
            span.heading-2(v-if='isEnterprise', style='text-align: center') {{ $t("contactSales") }}
            span.subtitle-2(v-if='beforeCurrency') {{ beforeCurrency }}
            span.heading-2(v-if='!isEnterprise') {{ price }}
            span.subtitle-2(v-if='afterCurrency') {{ afterCurrency }}
            span.subtitle-1.ml-1(v-if='!isEnterprise') {{ intervalString }}
    v-row.mb-2(
        align='center',
        justify='center',
        :class='isEnterprise && "py-4"'
    )
        h5.text--secondary(v-if='!isEnterprise') {{ $t(interval + "ly") }}
    v-row.mt-4(align='center', justify='center')
        .custom-separator(:style='`background-color: ${color}`')
    v-card-text.ml-0.mr-1.my-4(:class='selected ?["ml-0", "mr-0", "px-2", "my-4"] : ["ml-0", "mr-1", "my-4"]')
        ul.list-unstyled(v-if='!isEnterprise')
            li.mb-2
                v-icon.mr-4(:color='color', small) done
                b {{ $t(`${users}user`) }}
            li.mb-2
                v-icon.mr-4(:color='color', small) done
                span {{ $t("unlimited") }}
            li.mb-2
                v-icon.mr-4(:color='color', small) done
                span {{ $t("fullAccess") }}
            li.mb-2
                v-icon.mr-4(:color='color', small) done
                span {{ $t("customDesigns") }}
            li.mb-2
                v-icon.mr-4(:color='isPro ? color : "gray"', small) {{ isPro ? "done" : "close" }}
                component(
                    :is='isPro ? "b" : "span"',
                    :class='!isPro && "text-decoration-line-through"'
                ) {{ $t("accessRights") }}
        ul.list-unstyled(v-else)
            li.mb-2
                b {{ $t(`moreUser`) }}?
            li.mb-2
                span {{ $t("priorityConsulting") }}?
            li.mb-2
                span {{ $t("consulting") }}?

    v-card-actions
        v-row.mb-4(align='center', justify='center')
            v-btn.white--text(
                v-if='!isEnterprise',
                :loading='loading',
                :disabled='disabled',
                :color='color',
                rounded,
                depressed,
                @click='$emit("pay")'
            )
                v-icon(left) payment
                span {{ $t("profile_page.payment") }}
            v-btn.white--text(
                v-else,
                :loading='loading',
                :disabled='disabled',
                :color='color',
                rounded,
                depressed,
                @click='$emit("sales")'
            ) {{ $t("contact") }}
</template>

<script>
export default {
    name: 'priceTable',
    model: {
        prop: 'interval',
        event: 'change',
    },
    props: {
        price: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        interval: {
            type: String,
            required: true,
        },
        priorityConsulting: {
            type: Boolean,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        users: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        currency: {
            type: String,
            required: true,
            validation: (c) => ['USD', 'EUR'].includes(c),
        },
        isEnterprise: {
            type: Boolean,
            required: false,
        },
        isPro: {
            type: Boolean,
            required: false,
        },
        selected: {
            type: Boolean, 
            default: false
        }
    },
    data: () => ({
        isYear: true,
    }),
    computed: {
        intervalString() {
            return `/${this.$t('dates.month').toLowerCase()}`
        },
        beforeCurrency() {
            return this.currency === 'USD' && !this.isEnterprise ? '$' : ''
        },
        afterCurrency() {
            return this.currency === 'EUR' && !this.isEnterprise ? '€' : ''
        },
    },
    watch: {
        interval: {
            handler: function (interval) {
                if (interval === 'month' && this.isYear) this.isYear = false
                if (interval === 'year' && !this.isYear) this.isYear = true
            },
            immediate: true,
        },
        isYear: {
            handler: function (isYear) {
                if (this.interval === 'month' && isYear)
                    this.$emit('change', 'year')
                if (this.interval === 'year' && !isYear)
                    this.$emit('change', 'month')
            },
            immediate: true,
        },
    },
}
</script>

<i18n>{
  "en": {
    "moreUser": "More users",
    "monthly": "paid monthly",
    "yearly": "paid yearly",
    "1user": "1 user",
    "10user": "Up to 10 users",
    "100user": "Up to 100 users",
    "unlimited": "Unlimited dashboard creations",
    "fullAccess": "Full access to chart library",
    "title": "Ponychart Subscription",
    "priorityConsulting": "Priority support",
    "consulting": "Consulting",
    "contact": "Contact",
    "customDesigns": "Save your own templates"
  },
  "fr": {
    "monthly": "paiement mensuel",
    "yearly": "paiement annuel",
    "unlimited": "Création de dashboards illimitée",
    "fullAccess": "Accès complet à la librairie de graphiques",
    "title": "Souscription Ponychart",
    "1user": "1 utilisateur",
    "10user": "Jusqu'à 10 utilisateurs",
    "100user": "Jusqu'à 100 utilisateurs",
    "priorityConsulting": "Assistance et support prioritaire",
    "moreUser": "Plus d'utilisateurs",
    "contact": "Contactez",
    "consulting": "Consulting",
    "customDesigns": "Enregistrez vos propres modèles"
  },
  "es": {
    "monthly": "pagado mensualmente",
    "yearly": "pagado anualmente",
    "unlimited": "Creaciones de paneles ilimitadas",
    "fullAccess": "Acceso completo a la biblioteca de gráficos",
    "title": "Suscripción a Ponychart",
    "1user": "1 usuario",
    "10user": "Hasta 10 usuarios",
    "100user": "Hasta 100 usuarios",
    "priorityConsulting": "Asesoramiento y soporte prioritarios",
    "moreUser": "Más usuarios",
    "contact": "Contacto",
    "consulting": "Consulting",
    "customDesigns": "Guarda tus propias plantillas"
  },
  "de": {
    "monthly": "monatlich bezahlt",
    "yearly": "jährlich bezahlt",
    "unlimited": "Unbegrenzte Dashboard-Kreationen",
    "fullAccess": "Voller Zugriff auf die Diagrammbibliothek",
    "title": "Ponychart-Abonnement"
  }
}</i18n>

<style scoped>
.list-unstyled {
    list-style: none;
}
.custom-separator {
    width: 5rem;
    height: 6px;
    border-radius: 1rem;
}
</style>