import axios from 'axios'
import store from './store'
import router from './router'

function isUnauthorized(error: any) {
  return (
    typeof error.response === 'undefined' || error?.response?.status === 401
  )
}

function link() {
  window.addEventListener("beforeinstallprompt", function (event: any) {
    event.preventDefault()
    store.commit("install/setAddToHomeScreen", event)
  })
}


export default function setup() {
  const isProduction = !location.port
  link()
  const region = 'fr'
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL?.trim() || `https://${isProduction ? "" : "dev-"}api.${region}.ponychart.com`
  setInterval(() => {
    store.dispatch('cognito/fetchSession').then(res => {
      if (res?.idToken?.jwtToken) {
        axios.defaults.headers.common['Authorization'] = res.idToken.jwtToken
      }
    })
  }, 1000000)
  axios.interceptors.request.use(
    config => {
      if (
        store.getters?.['cognito/session']?.idToken?.jwtToken && config?.headers
      ) {
        config.headers['Authorization'] =
          store.getters['cognito/session'].idToken.jwtToken
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )
  axios.interceptors.response.use(
    response => {
      return Promise.resolve(response)
    },
    error => {
      const originalRequest = error.config || {}
      if (isUnauthorized(error) && !originalRequest?.retry) {
        originalRequest.retry = true
        return store.dispatch('cognito/fetchSession').then(res => {
          if (res && res['cognito/session']?.idToken?.jwtToken) {
            axios.defaults.headers.common['Authorization'] =
              res['cognito/session'].idToken.jwtToken
          }
          return axios.request(originalRequest)
        })
      } else if (isUnauthorized(error) && !window.location.href.includes("/demo/")) {
        router.push(`/en/login`)
      }

      return Promise.reject(error)
    }
  )
}
