
export default {
    namespaced: true,
    state: {
        addToHomeScreen: null,
    },
    mutations: {
        setAddToHomeScreen(state: any, val: any) {
            state.addToHomeScreen = val;
        },
    },
    actions: {
        addToHomeScreen({ state, commit }: any) {
            const self = this as any
            if (state.addToHomeScreen) {
                state.addToHomeScreen.prompt();
                state.addToHomeScreen.userChoice.then(function (choiceResult: any) {
                    if (choiceResult.outcome === "dismissed") {
                        self.$flash.info("Vous pourrez toujours installer l'application plus tard depuis les options de votre navigateur");
                    } else {
                        self.$flash.success("L'application est en cours d'installation");
                    }
                });
                commit("setAddToHomeScreen", null);
            }
        }
    }
}

