
import draggable from 'vuedraggable'

import {
    Column,
    SourceMemory,
    Source,
    MAXIMUM,
    TABLEAU_BRIGHT_VALUES,
    SourceMode,
    IFilter,
    FilterType,
} from '@/ponychart'
import DatasourcePill from '@/components/utils/DatasourcePill.vue'
import TwbDatasourceSelect from '@/components/utils/TwbDatasourceSelect.vue'
import SortableAutocomplete from '@/components/utils/SortableAutocomplete.vue'

import Tour from '@/components/utils/Tour.vue'
import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { GlobalMixins } from '@/mixins'

const memoryInstance = SourceMemory.getInstance()

@Component({
    components: {
        draggable,
        Tour,
        TwbDatasourceSelect,
        DatasourcePill,
        SortableAutocomplete,
    },
})
export default class FiltersComponent extends mixins(GlobalMixins) {
    @Prop({ type: Object, required: true })
    readonly source!: Source
    @Prop({ type: Number, required: true })
    readonly sourceId!: number
    @Prop({ type: String, required: true })
    readonly sourceMode!: SourceMode
    @Prop({ type: Boolean, default: true })
    readonly displayHeader!: boolean

    tmt: number | null = null
    selectedTwbDatasources: string[] = []
    filters: IFilter[] = []
    panel = -1

    get max() {
        return MAXIMUM.filters
    }

    get sortedSelectedTwbIndexes() {
        if (this.twbDatasources.length === 1) return [0]
        const indexes = this.twbDatasources.map((t) => t.id)
        return this.selectedTwbDatasources.map((t) => indexes.indexOf(t)).sort()
    }

    get twbDatasources() {
        return this.source.twbDatasources.map((t, i) => ({
            ...t,
            color: TABLEAU_BRIGHT_VALUES[i % TABLEAU_BRIGHT_VALUES.length],
        }))
    }

    get twbIdx() {
        return this.sortedSelectedTwbIndexes[0]
    }

    get filterItems() {
        const filterIds = this.filters.map((f) => f.id)
        const filterCount = filterIds.length
        return memoryInstance.columnsForString.map((c) => ({
            ...c,
            disabled: !(filterCount < this.max || filterIds.includes(c.id)),
        }))
    }

    get filterMap() {
        return this.filters.reduce(
            (acc: { [key: string]: number }, filter: IFilter) => {
                acc[filter.alias] = (acc[filter.alias] || 0) + 1
                return acc
            },
            {}
        )
    }

    get hasError() {
        return Object.values(this.filterMap).some((v) => v > 1)
    }

    @Watch('hasError', { immediate: true })
    onFiltersError(hasFilterErrors: boolean) {
        this.$emit('set', { hasFilterErrors })
    }

    get filterTypes() {
        return [FilterType.MULTI_SELECT, FilterType.SINGLE_SELECT].map((t) => ({
            text: this.$t(t),
            value: t,
        }))
    }

    get storeFilters() {
        return memoryInstance.filters
    }
    get columns() {
        return memoryInstance.columns
    }

    mounted() {
        this.selectedTwbDatasources = this.twbDatasources.map((t) => t.id)
    }

    setColumnId(filter: IFilter, columnId: string) {
        filter.twbIdx =
            this.filterItems.find((f) => f.id === columnId)?.twbIdx || 0
        filter.columnId = columnId
    }

    saveHook() {
        if (this.tmt) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.$emit('save')
            },
            7000,
            this
        )
    }

    onDragChange(e: any) {
        const movedFilter: IFilter = e.moved.element
        const selectedNewIndex = e.moved.newIndex
        const switchDimensionId =
            selectedNewIndex === this.filters.length - 1
                ? undefined
                : this.filters[selectedNewIndex + 1].id
        const newFilters = []
        let pushed = false
        for (const dimension of this.filters) {
            if (dimension.id === switchDimensionId) {
                newFilters.push(movedFilter)
                pushed = true
            } else if (dimension.id === movedFilter.id) {
                continue
            }
            newFilters.push(dimension)
        }
        if (!pushed) newFilters.push(movedFilter)
        this.filters = newFilters
    }

    registerFilters(columns?: Column[]) {
        if (columns)
            this.filters = columns.map(({ id, alias, twbIdx }) => ({
                columnId: id,
                alias,
                id,
                twbIdx,
                type: FilterType.MULTI_SELECT,
            }))
        memoryInstance.setFilters(this.filters)
    }

    @Watch('columns', { immediate: true })
    onColumnsChanged(columns: Column[]) {
        if (!columns?.length) return
        if (this.storeFilters.length && !this.filters.length) {
            this.filters = [...this.storeFilters]
        }
    }
}
