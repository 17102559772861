import { PageMode, PageStructure } from "@/ponychart/page";
import { TraitSearch } from "@/ponychart/state/traits";

import { ChartType, DeviceType, MAX_QUERY_SELECTOR_TAG, QuerySelectorTag, querySelectorTagToChartTrait, TraitId } from "ponychart";

export class StructurePostProcessor {
    constructor(private pageStructure: PageStructure, private traitSearch: TraitSearch) { }


    postProcess() {
        const allowForDimension = this.pageStructure === PageStructure.SIMPLE
        // WARNING, if TraitId.MEASURE already exists & its querySelectorTags are at 0 only 
        // then we should not add the equivalent chartTrait.MEASURE (with querySelectorTags at [0, 1] for instance) 
        // because they would be in duplicate
        // the same applies to TraitId.DIMENSION when PAGE_MODE === PageMode.DIMENSION
        const pageModeTraitId = allowForDimension && this.traitSearch.getTraitStringValue(TraitId.PAGE_MODE, 0) === PageMode.DIMENSION ? TraitId.DIMENSION : TraitId.MEASURE
        const alreadyExists = this.traitSearch.getTrait(pageModeTraitId, { querySelectorTag: 0, deviceType: DeviceType.DESKTOP })?.touched
        if (!alreadyExists) return
        for (let q = 1; q <= MAX_QUERY_SELECTOR_TAG; q++) {
            const querySelectorTag = q as QuerySelectorTag
            const chartTypes = (this.traitSearch.getTraitStringValue(querySelectorTagToChartTrait(querySelectorTag), querySelectorTag)?.split(";") || []) as ChartType[] 
            if(!chartTypes.length) continue
            for (const deviceType of [DeviceType.DESKTOP, DeviceType.TABLET, DeviceType.MOBILE]) {
                for(const chartType of chartTypes){
                    const toDeleteTrait = this.traitSearch.getTrait(pageModeTraitId, { querySelectorTag, deviceType, chartType })
                    if (toDeleteTrait) toDeleteTrait.tagAsTouched(false)
                }
                
            }
        }


    }
}