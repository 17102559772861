import { t } from '@/ponychart/i18n'
import {
    TraitId,
    TraitOptionType,
    Trait,
    TraitOptionItem,
    QuerySelectorTag,
    DeviceType,
    ChartType,
    SpecificTraitOptions,
    CheckboxTraitOptions,
    SelectTraitOptions,
    MultiSelectTraitOptions,
    TiptapTraitOptions,
    DefaultTraitOptions,
    SliderTraitOptions,
    ParameterTraitOptions,
    ParameterMode,
} from 'ponychart'
import { TraitSearch } from '@/ponychart/state/traits'
import { sample } from 'lodash'

import { Bool } from './types'
import { mainTag } from './utils'
import { NO_PRISTINE } from './config'

export abstract class CustomTrait {
    isInstanciated = true // Transverse
    touched = true // Transverse
    protected _value: string | number
    protected _label?: string
    constructor(
        public id: TraitId,
        public type: TraitOptionType,
        public defaultOptions: DefaultTraitOptions,
        public options: SpecificTraitOptions,
        public traitSearch?: TraitSearch
    ) {
        this._value =
            this.defaultOptions.value === undefined
                ? ''
                : this.defaultOptions.value
        this._label = this.defaultOptions.label
        this.setValue(this._value)
    }

    get isDesktop() {
        const deviceType = this.deviceType || DeviceType.DESKTOP
        return deviceType === DeviceType.DESKTOP
    }

    get querySelectorTags(): QuerySelectorTag[] {
        return this.defaultOptions?.querySelectorTags || [0]
    }

    set querySelectorTags(tags: QuerySelectorTag[]) {
        this.defaultOptions.querySelectorTags = tags
    }

    get hidden(): boolean {
        return this.defaultOptions.hidden || false
    }

    set hidden(hidden: boolean) {
        this.defaultOptions.hidden = hidden
    }

    get pristine(): boolean {
        return this.defaultOptions.pristine === undefined
            ? !NO_PRISTINE.includes(this.id)
            : this.defaultOptions.pristine
    }

    set pristine(pristine: boolean) {
        this.defaultOptions.pristine = pristine
    }

    get chartType(): ChartType | undefined {
        return this.defaultOptions.chartType
    }

    get deviceType(): DeviceType | undefined {
        return this.defaultOptions.deviceType
    }

    get twbIdx(): number | undefined {
        return this.defaultOptions.twbIdx
    }

    get mainQuerySelectorTag() {
        return mainTag(this.querySelectorTags)
    }

    log(...msg: (string | number)[]) {
        if (this.id !== TraitId.MEASURE) return
        console.log(...msg)
    }

    get value(): string | number {
        return this._value
    }

    set value(_: string | number) {
        throw new Error('Should not set value directly')
    }

    tagAsTouched(value: boolean): void {
        this.touched = value
    }

    abstract setValue(value: string | number): boolean

    abstract setItems(items: TraitOptionItem[]): boolean

    abstract getRandomValue(): string | number

    get label() {
        if (this._label) return this._label
        return t(`${this.id}.label`)
    }

    set label(label: string) {
        this._label = label
    }

    toDb(): any {
        const db: any = {
            id: this.id,
            value: this.value,
            label: this.label,
            querySelectorTags: this.querySelectorTags,
            pristine: this.pristine,
            twbIdx: this.twbIdx,
        }
        if (this.chartType) db.chartType = this.chartType
        if (this.deviceType) db.deviceType = this.deviceType
        return db
    }

    toDict(simpleMode = false): Trait {
        const traitPayload: Trait = {
            id: this.id,
            type: this.type,
            value: this.value,
            querySelectorTags: this.querySelectorTags,
            label: this.label,
            pristine: this.pristine, // IMPORTANT to leave it there sadly
            options: {},
        }
        if (this.twbIdx !== undefined) traitPayload.twbIdx = this.twbIdx
        if (this.chartType) traitPayload.chartType = this.chartType
        if (this.deviceType) traitPayload.deviceType = this.deviceType
        if (this.hidden) traitPayload.hidden = this.hidden
        if (simpleMode) return traitPayload
        return {
            ...traitPayload,
            options: this.options,
        }
    }
}

export class CheckBoxTrait extends CustomTrait {
    constructor(
        id: TraitId,
        defaultOptions: DefaultTraitOptions,
        checkboxOptions: CheckboxTraitOptions,
        traitSearch?: TraitSearch
    ) {
        super(
            id,
            TraitOptionType.CHECKBOX,
            defaultOptions,
            checkboxOptions,
            traitSearch
        )
        if (!this.checkboxOptions.valueFalse)
            this.checkboxOptions.valueFalse = Bool.FALSE
        if (!this.checkboxOptions.valueTrue)
            this.checkboxOptions.valueTrue = Bool.TRUE
    }

    get checkboxOptions(): CheckboxTraitOptions {
        return this.options as CheckboxTraitOptions
    }

    get valueFalse(): string {
        return this.checkboxOptions.valueFalse || Bool.FALSE
    }

    get valueTrue(): string {
        return this.checkboxOptions.valueTrue || Bool.TRUE
    }

    getRandomValue() {
        return sample([this.valueFalse, this.valueTrue]) || this.value
    }

    setValue(value: Bool): boolean {
        this._value = value
        return false
    }

    setItems(items: TraitOptionItem[]): boolean {
        return false
        // throw new Error('Cannot set items on checkbox')
    }
}

abstract class AbstractSelectTrait extends CustomTrait {
    constructor(
        public id: TraitId,
        public type: TraitOptionType,
        public defaultOptions: DefaultTraitOptions,
        public options: SpecificTraitOptions,
        public traitSearch?: TraitSearch
    ) {
        super(id, type, defaultOptions, options, traitSearch)
        this.initItems(this.options)
        this.setValue(this.value)
        this.setItems(this.items)
    }
    get items() {
        return (this.options as any).items || ([] as TraitOptionItem[])
    }

    set items(items: TraitOptionItem[]) {
        ;(this.options as any).items = items
    }

    abstract setItems(items: (TraitOptionItem | string)[]): boolean

    pushItems(items: TraitOptionItem[]) {
        this.items = []
        for (const item of items) {
            this.items.push(item)
        }
    }

    toTraitItem(id: string | number): TraitOptionItem {
        return { id, alias: t(`${this.id}.${id}`), twbIdx: this.twbIdx }
    }

    initItems(opts: SelectTraitOptions) {
        if (opts.items?.length) {
            this.pushItems(opts.items)
        } else {
            this.items = []
            for (const itemId of opts.itemIds || []) {
                this.items.push(this.toTraitItem(itemId))
            }
        }
    }
}

export class SelectTrait extends AbstractSelectTrait {
    constructor(
        id: TraitId,
        defaultOptions: DefaultTraitOptions,
        selectOptions: SpecificTraitOptions,
        traitSearch?: TraitSearch
    ) {
        super(
            id,
            TraitOptionType.SELECT,
            defaultOptions,
            selectOptions,
            traitSearch
        )
    }

    getRandomValue(): string | number {
        return sample(this.items)?.id || this.value
    }

    get selectOptions(): SelectTraitOptions {
        return this.options as SelectTraitOptions
    }

    protected preprocessValue(
        value: string | number,
        itemIds: (string | number)[]
    ) {
        if (typeof value !== 'string') return value
        const values = value.split(';').filter((v) => itemIds.includes(v))
        return values.length > 0 ? values[0] : value
    }

    setValue(value: string | number): boolean {
        // Returns true if value has been changed
        const itemIds = this.items.map((o) => o.id)
        value = this.preprocessValue(value, itemIds)
        if (itemIds.includes(value)) {
            const changed = value !== this.value
            const oldValue = this.value + ''
            this._value = value
            if (typeof this._value === 'object')
                throw new Error(
                    `Can't set object ${JSON.stringify(value)} ${JSON.stringify(
                        this._value
                    )} ${JSON.stringify(this.options)}`
                )
            return changed
        } else {
            const firstValue = itemIds?.[0]
            const changed = firstValue !== this.value
            const oldValue = this.value + ''
            this._value = firstValue
            if (typeof this._value === 'object')
                throw new Error(
                    `Can't set object ${JSON.stringify(value)} ${JSON.stringify(
                        this._value
                    )} ${JSON.stringify(this.options)}`
                )
            return changed
        }
    }

    setItems(items: TraitOptionItem[]): boolean {
        // Returns true if value has been changed
        const itemIds = this.items.map((o) => o.id)
        this._value = this.preprocessValue(this._value, itemIds)
        this.pushItems(items)
        if (this.items.length === 0) return false
        if (!itemIds.includes(this.value)) {
            this._value = itemIds[0]
            this.log('setOptions ' + this._value)
            return true
        }
        return false
    }
}

export class MultiSelectTrait extends AbstractSelectTrait {
    protected _defaultAsAllValues = true
    constructor(
        id: TraitId,
        defaultOptions: DefaultTraitOptions,
        multiSelectTraitOptions: MultiSelectTraitOptions,
        traitSearch?: TraitSearch
    ) {
        super(
            id,
            TraitOptionType.MULTI_SELECT,
            defaultOptions,
            {
                ...multiSelectTraitOptions,
                allowMultiple: true,
                allowNone: multiSelectTraitOptions.allowNone || false,
                sortable: multiSelectTraitOptions.sortable || false,
                maxCount: multiSelectTraitOptions.maxCount || 1000,
            },
            traitSearch
        )
    }

    get multiSelectTraitOptions(): MultiSelectTraitOptions {
        return this.options as MultiSelectTraitOptions
    }

    get items() {
        return this.multiSelectTraitOptions.items || []
    }

    set items(items: TraitOptionItem[]) {
        this.multiSelectTraitOptions.items = items
    }

    get maxCount() {
        return this.multiSelectTraitOptions.maxCount || 1000
    }

    get allowMultiple() {
        return this.multiSelectTraitOptions.allowMultiple || false
    }

    set allowMultiple(allowMultiple: boolean) {
        this.multiSelectTraitOptions.allowMultiple = allowMultiple
    }

    get allowNone() {
        return this.multiSelectTraitOptions.allowNone || false
    }

    set allowNone(allowNone: boolean) {
        if (allowNone !== this.multiSelectTraitOptions.allowNone)
            console.log(
                'setting allowNone',
                this.multiSelectTraitOptions.allowNone,
                'to',
                allowNone
            )
        this.multiSelectTraitOptions.allowNone = allowNone
    }

    get sortable() {
        return this.multiSelectTraitOptions.sortable || false
    }

    set sortable(sortable: boolean) {
        this.multiSelectTraitOptions.sortable = sortable
    }

    getRandomValue(): string {
        return this.items.map((o) => o.id).join(';')
    }

    setValue(value: number | string | string[]): boolean {
        // if (this.id === TraitId.MEASURE && value !== this.value) console.log("setValue", this.value, "to", value)
        const values = Array.isArray(value) ? value : String(value).split(';')
        const itemIds = this.items.map((o) => o.id)
        // this.log(`setValue, ${value}, ${JSON.stringify(itemIds)}`)
        let output: any[] = []
        let changed = false
        let count = 0
        for (const val of values) {
            if (itemIds.includes(val) && count < this.maxCount) {
                output.push(val)
                count++
            } else {
                changed = true
            }
        }
        // this.log(count, itemIds as any, output as any)

        if (output.length === 0 && !this.allowNone) {
            if (!this.allowMultiple || !this._defaultAsAllValues) {
                output.push(itemIds[0])
            } else {
                for (const optionId of itemIds) {
                    output.push(optionId)
                }
            }
        }
        if (output.length > 1 && !this.allowMultiple) output = [output[0]]
        const outputString = output.join(';')
        changed = changed || outputString !== this.value
        this._value = outputString
        if (count === this.maxCount && this.maxCount > 1) {
            this.items = this.items.map((o) => ({
                ...o,
                disabled: !output.includes(o.id),
            }))
        } else {
            this.items = this.items.map((o) => ({ ...o, disabled: false }))
        }
        return changed
    }

    setItems(items: TraitOptionItem[]) {
        const values = String(this.value).split(';')
        this.pushItems(items)
        const itemIds = this.items.map((o) => o.id)
        // this.log(`setOptions, ${this.value}, ${JSON.stringify(optionIds)}`)

        if (itemIds.length === 0) return false
        const output: any[] = []
        let changed = false
        let count = 0
        for (const value of values) {
            if (itemIds.includes(value) && count < this.maxCount) {
                output.push(value)
                count++
            } else {
                changed = true
            }
        }
        if (!output.length && !this.allowNone) {
            if (!this.allowMultiple) {
                output.push(itemIds[0])
            } else {
                for (const optionId of itemIds) {
                    output.push(optionId)
                }
            }
            changed = true
        }
        if (count === this.maxCount && this.maxCount > 1) {
            this.items = this.items.map((o) => ({
                ...o,
                disabled: !output.includes(o.id),
            }))
        } else {
            this.items = this.items.map((o) => ({ ...o, disabled: false }))
        }
        this._value = output.join(';')
        return changed
    }
}

export class TiptapTrait extends AbstractSelectTrait {
    constructor(
        id: TraitId,
        defaultOptions: DefaultTraitOptions,
        tiptapOptions: TiptapTraitOptions,
        traitSearch?: TraitSearch
    ) {
        super(
            id,
            TraitOptionType.TIPTAP,
            defaultOptions,
            tiptapOptions,
            traitSearch
        )
    }

    get itemIds(): string[] {
        return (this.tiptapOptions.itemIds || []).map((o) => String(o))
    }

    get hasDate(): boolean {
        return this.tiptapOptions.hasDate || false
    }

    get hasDimension(): boolean {
        return this.tiptapOptions.hasDimension || false
    }

    getRandomValue(): string | number {
        return ''
    }

    get tiptapOptions(): TiptapTraitOptions {
        return this.options as TiptapTraitOptions
    }

    setValue(value: string) {
        const changed = this.value !== value
        this._value = value
        return changed
    }

    setItems(items: TraitOptionItem[]): boolean {
        this.pushItems(items)
        return false
    }
}

export class SliderTrait extends CustomTrait {
    constructor(
        id: TraitId,
        defaultOptions: DefaultTraitOptions,
        sliderOptions: SliderTraitOptions,
        traitSearch?: TraitSearch
    ) {
        super(
            id,
            TraitOptionType.SLIDER,
            defaultOptions,
            sliderOptions,
            traitSearch
        )
        if (this.sliderOptions.maxValue === undefined)
            this.sliderOptions.maxValue = 100
        if (this.sliderOptions.minValue === undefined)
            this.sliderOptions.minValue = 0
    }

    setItems(items: TraitOptionItem[]): boolean {
        throw new Error('Method not implemented.')
    }

    getRandomValue(): string | number {
        return Math.random() * (this.maxValue - this.minValue) + this.minValue
    }

    get sliderOptions(): SliderTraitOptions {
        return this.options as SliderTraitOptions
    }

    get maxValue(): number {
        return this.sliderOptions.maxValue || 100
    }

    get minValue(): number {
        return this.sliderOptions.minValue || 0
    }

    setValue(value: number) {
        if (this.maxValue === undefined || this.minValue === undefined)
            throw new Error('maxValue/minValue must be set')
        if (this.maxValue < this.minValue)
            throw new Error('maxValue must be greater than minValue')
        if (value > this.maxValue) value = this.maxValue
        if (value < this.minValue) value = this.minValue
        const changed = this.value !== value
        this._value = value
        return changed
    }
}

export class ParameterTrait extends CustomTrait {
    constructor(
        id: TraitId,
        defaultOptions: DefaultTraitOptions,
        parameterOptions: ParameterTraitOptions,
        traitSearch?: TraitSearch
    ) {
        super(
            id,
            TraitOptionType.PARAMETER,
            defaultOptions,
            parameterOptions,
            traitSearch
        )
    }
    setValue(value: string | number): boolean {
        this._value = value
        return true
    }
    setItems(items: TraitOptionItem[]): boolean {
        return false
    }
    getRandomValue(): string | number {
        return 1
    }

    get hasParameter(): boolean {
        if (this.options.mode === ParameterMode.RANGE) {
            const minimum = this.options.allowMinimum
                ? this.options.minimum
                : undefined
            const maximum = this.options.allowMaximum
                ? this.options.maximum
                : undefined
            return !(minimum !== undefined && maximum === minimum)
        } else {
            return (this.options?.values?.length || 0) > 1
        }
    }

    get text() {
        if (this.hasParameter) return undefined
        return this.options.mode === ParameterMode.RANGE
            ? `${this.options.minimum}`
            : this.options?.values?.[0].alias
    }
}
