<template lang="pug">
v-dialog(:value='isSmall && isLoggedIn', fullscreen)
  v-card
    v-card-title {{ $t("title") }}
    v-card-text {{ $t("description") }}
    v-card-actions 
      v-spacer
      v-btn(@click='$emit("out")', color='primary') 
        v-icon lock_open
        span {{ $t("signout") }}
</template>

<script>
export default {
  name: 'noMobile',
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
}
</script>

<i18n>{
  "fr": {
    "title": "Veuillez utiliser l'application sur un grand écran",
    "description": "L'application ne fonctionnera pas sur un écran de smartphone. Veuillez vous reconnecter depuis votre ordinateur de bureau."
  },
  "es": {
    "title": "Utilice la aplicación en una pantalla grande",
    "description": "La aplicación no funcionará en la pantalla de un teléfono inteligente. Vuelva a conectarse desde su computadora de escritorio."
  },
  "en": {
    "title": "Please use the application on a big screen",
    "description": "The app will not work on a smartphone screen. Please reconnect from your desktop computer."
  },
  "de": {
    "title": "Bitte verwenden Sie die Anwendung auf einer großen Leinwand.",
    "description": "Die App funktioniert nicht auf dem Bildschirm eines Smartphones. Bitte verbinden Sie sich erneut von Ihrem Desktop-Computer aus."
  }
}</i18n>