import { t } from "@/ponychart/i18n"
import { Trait } from "ponychart"
import { nanoid } from "@/ponychart/utils/nanoId"

import { PageStructure } from "./types"
import { translateStructure } from "../structure/config"


export function reduceSizeClass(reduceSize: number) {
    return `x-reduce-size-${String(reduceSize).replace(".", "-")}`
}


export function seedPageBlock(structure: PageStructure): {
    id: string
    alias: string
    title: string
    traits: Trait[]
    includesTitle: boolean
    includesTimeIndication: boolean
    structure: PageStructure
} {
    const alias = t('page_structure.' + PageStructure.SIMPLE)
    return {
        id: nanoid.id(),
        alias,
        title: alias,
        traits: [],
        includesTitle: true,
        includesTimeIndication: true,
        structure,
    }
}

export function seedPage(
    structure: PageStructure,
): {
    id: string
    alias: string
    title: string
    pristine: boolean
    templatePristine: boolean
} {
    const alias = translateStructure(structure)
    return {
        id: nanoid.id(),
        alias,
        pristine: true,
        templatePristine: true,
        title: alias,
    }
}

/**
 * Guesses an appropriate column count for KPI Cards
 */
export function guessAppropriateColumnCountForKPICards(opts: { measureCount: number, maxCount: number }): number {
    const remainders: { index: number, quotient: number, emptyFieldCount: number }[] = []
    for (let index = 2; index <= opts.maxCount; index++) {
        const quotient = Math.floor(opts.measureCount / index)
        const emptyFieldCount = (index - opts.measureCount % index) % index
        remainders.push({ index, quotient, emptyFieldCount })
    }
    const minEmptyFieldCount = Math.min(...remainders.map(r => r.emptyFieldCount))
    const filteredRemainders = remainders.filter(r => r.emptyFieldCount === minEmptyFieldCount)
    const minQuotient = Math.min(...filteredRemainders.map(r => r.quotient))
    for (const r of filteredRemainders) {
        if (r.quotient === minQuotient) {
            return r.index
        }
    }
    return remainders[0].index
}
