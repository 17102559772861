import axios from 'axios'

export default {
    getUploadUrl: async function (pathParameter: "images" | "dashboards", payload: any) {
        const {
            data: { url },
        } = await axios.post('/v1/upload/' + pathParameter, payload)
        return url
    },
}
