import { render, staticRenderFns } from "./AccessRights.vue?vue&type=template&id=31b34272&scoped=true&lang=pug&"
import script from "./AccessRights.vue?vue&type=script&lang=ts&"
export * from "./AccessRights.vue?vue&type=script&lang=ts&"
import style0 from "./AccessRights.vue?vue&type=style&index=0&id=31b34272&prod&scoped=true&lang=css&"
import style1 from "./AccessRights.vue?vue&type=style&index=1&id=31b34272&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b34272",
  null
  
)

/* custom blocks */
import block0 from "./AccessRights.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports