
import Component, { mixins } from 'vue-class-component'
import { GlobalMixins } from '@/mixins'
import { Prop } from 'vue-property-decorator'

@Component
export default class DeviceTypeSwitch extends mixins(GlobalMixins) {
    @Prop({ type: String, required: true, default: 'desktop' })
    readonly value!: 'desktop' | 'tablet' | 'mobile'

    values = [
        {
            value: 'desktop',
            icon: 'desktop_windows',
        },
        { value: 'tablet', icon: 'tablet' },
        { value: 'mobile', icon: 'smartphone' },
    ]

    change(value: 'desktop' | 'tablet' | 'mobile') {
        this.$emit('input', value)
    }
}
