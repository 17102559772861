const MEMORY: {
    [k in string]: number
} = {}

/**
 * Uses div.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be measured.
 * @param {String} fontWeight The css font weight normal or bold
 * @param {String} fontSize The css font size eg 12px
 * @param {String} fontFamily The css font family eg Arial
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
class TextMeasurer {
    div: HTMLDivElement = document.createElement('div')
    constructor() {
        document.body.appendChild(this.div)
        this.div.style.position = 'absolute'
        this.div.style.visibility = 'hidden'
        this.div.style.top = '0'
        this.div.style.left = '0'
    }

    measureTextWidth(text: string): number {
        this.div.innerHTML = text
        return this.div.getBoundingClientRect().width
    }

    findOptimalFontSize(
        text: string,
        height: number,
        width: number,
        fontWeight: string,
        fontFamily: string,
        ratio = 0.7
    ): number {
        const key = `${text}-${height}-${width}-${fontWeight}-${fontFamily}-${ratio}`
        if (MEMORY[key]) return MEMORY[key]
        this.div.style.width = width + 'px'
        this.div.style.fontWeight = fontWeight
        this.div.style.fontFamily = fontFamily
        this.div.innerHTML = text
        let fontSize = 0
        const ctx = this
        increaseFontSize()
        function increaseFontSize() {
            fontSize++
            ctx.div.style.fontSize = `${fontSize}px`
            const textHeight = ctx.div.getBoundingClientRect().height
            if (textHeight <= height) {
                increaseFontSize()
            } else {
                fontSize--
                ctx.div.style.fontSize = `${fontSize}px`
            }
        }
        const result = fontSize * ratio
        MEMORY[key] = result
        return result
    }
}

export const textMeasurer = new TextMeasurer()
