import {
    PonychartElement,
    ColumnContainer,
    RowContainer,
} from '@/ponychart/element/model'
import { SelectorId } from './types'
import {
    compileAggregationDateSelector,
    compileDatePicker,
    compileDimensionSelector,
    compileFilterSelector,
    compileLogo,
    compileMeasureSelector,
    compileMonthSelect,
    compileYearMonthSelect,
    compileYearSelect,
    compileMenuIcon,
    compileFilterIcon,
    compileChartSelector,
} from './compilers'
import { ChartType, TraitId } from 'ponychart'

import {
    add,
    divideBy,
    isBigSize,
    multiplyBy,
    substract,
} from '@/ponychart/utils/functions'
import { ELEMENT_HEIGHT } from './config'
import { LocalState, GlobalState, TraitSearch } from '@/ponychart/state'
import { DynamicParameter } from '@/ponychart/dynamicParameter/model'
import { PonychartComponent } from '@/ponychart/element'
import { PonychartElementHelpers } from '@/ponychart/element/helpers'
import { Bool } from '@/ponychart/trait'

export class MenuIcon extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.MENU_ICON, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return compileMenuIcon(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class FilterIcon extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.FILTER_ICON, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return compileFilterIcon(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class FilterSelector extends PonychartElement {
    name: string
    filterId: string
    constructor(
        filterId: string,
        name: string,
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.FILTER_SELECTOR,
            globalState,
            localState.copy().setIsFlex(true).setIsCol(true),
            { traitSearch }
        )
        this.name = name
        this.filterId = filterId
    }

    compileHtml(): string {
        return compileFilterSelector(this.id, this.globalState, {
            classes: this.classes,
            label: this.name,
            isDark: !!this.isDarkBackground,
        })
    }

    compileComponent(): PonychartComponent {
        const element = super.compileComponent()
        return {
            ...element,
            attrs: { ...element.attrs, filterId: this.filterId },
        }
    }
}

export class MeasureSelector extends PonychartElement {
    parameter: DynamicParameter
    constructor(
        parameter: DynamicParameter,
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.MEASURE_SELECTOR,
            globalState,
            localState
                .copy()
                .setIsCol(true)
                .setIsFlex(true)
                .setQuerySelectorTags([...parameter.querySelectorTags]),
            { traitSearch }
        )
        this.parameter = parameter
    }

    compileHtml(): string {
        return compileMeasureSelector(this.id, this.globalState, {
            classes: this.classes,
            isDark: this.localState.isDarkBackground,
        })
    }
}

export class Measure2Selector extends PonychartElement {
    parameter: DynamicParameter
    constructor(
        parameter: DynamicParameter,
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.MEASURE_SELECTOR_2,
            globalState,
            localState
                .copy()
                .setIsCol(true)
                .setIsFlex(true)
                .setQuerySelectorTags([...parameter.querySelectorTags]),
            { traitSearch }
        )
        this.parameter = parameter
    }

    compileHtml(): string {
        return compileMeasureSelector(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class DimensionSelector extends PonychartElement {
    parameter: DynamicParameter
    constructor(
        parameter: DynamicParameter,
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.DIMENSION_SELECTOR,
            globalState,
            localState
                .copy()
                .setIsFlex(true)
                .setIsCol(true)
                .setQuerySelectorTags([...parameter.querySelectorTags]),
            { traitSearch }
        )
        this.parameter = parameter
    }

    compileHtml(): string {
        return compileDimensionSelector(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class ChartSelector extends PonychartElement {
    parameter: DynamicParameter
    constructor(
        parameter: DynamicParameter,
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.CHART_SELECTOR,
            globalState,
            localState
                .copy()
                .setIsFlex(true)
                .setIsCol(true)
                .setQuerySelectorTags([...parameter.querySelectorTags]),
            { traitSearch }
        )
        this.parameter = parameter
    }

    compileHtml(): string {
        return compileChartSelector(
            this.id,
            this.globalState,
            this.parameter.ids,
            {
                classes: this.classes,
                isDark: !!this.isDarkBackground,
            }
        )
    }
}

export class Dimension2Selector extends PonychartElement {
    parameter: DynamicParameter
    constructor(
        parameter: DynamicParameter,
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.DIMENSION_SELECTOR_2,
            globalState,
            localState
                .copy()
                .setIsFlex(true)
                .setIsCol(true)
                .setQuerySelectorTags([...parameter.querySelectorTags]),
            { traitSearch }
        )
        this.parameter = parameter
    }

    compileHtml(): string {
        return compileDimensionSelector(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class DateAggregationSelector extends PonychartElement {
    parameter: DynamicParameter
    constructor(
        parameter: DynamicParameter,
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(
            SelectorId.DATE_AGGREGATION_SELECTOR,
            globalState,
            localState
                .copy()
                .setIsFlex(true)
                .setIsCol(true)
                .setQuerySelectorTags([...parameter.querySelectorTags]),
            { traitSearch }
        )
        this.parameter = parameter
    }

    compileHtml(): string {
        return compileAggregationDateSelector(
            this.id,
            this.globalState,
            this.traitSearch,
            {
                classes: this.classes,
                isDark: this.localState.isDarkBackground,
            }
        )
    }
}

export class StartDatePicker extends PonychartElement {
    label?: string
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch,
        opts: { label?: string } = {}
    ) {
        super(SelectorId.START_DATE_SELECTOR, globalState, localState, {
            traitSearch,
        })
        this.label = opts.label
    }

    compileHtml(): string {
        return compileDatePicker(
            this.id,
            SelectorId.START_DATE_SELECTOR,
            this.globalState,
            {
                label: this.label,
                isDark: !!this.isDarkBackground,
                classes: this.classes,
            }
        )
    }
}

export class EndDatePicker extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(SelectorId.END_DATE_SELECTOR, globalState, localState, {
            traitSearch,
        })
    }

    compileHtml(): string {
        return compileDatePicker(
            this.id,
            SelectorId.END_DATE_SELECTOR,
            this.globalState,
            {
                classes: this.classes,
                isDark: !!this.isDarkBackground,
            }
        )
    }
}

export class StartYearSelect extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(SelectorId.START_YEAR_SELECTOR, globalState, localState, {
            traitSearch,
        })
    }

    compileHtml(): string {
        return compileYearSelect(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class EndYearSelect extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(SelectorId.END_YEAR_SELECTOR, globalState, localState, {
            traitSearch,
        })
    }

    compileHtml(): string {
        return compileYearSelect(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class StartMonthSelect extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(SelectorId.START_MONTH_SELECTOR, globalState, localState, {
            traitSearch,
        })
    }

    compileHtml(): string {
        return compileMonthSelect(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class EndMonthSelect extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(SelectorId.END_MONTH_SELECTOR, globalState, localState, {
            traitSearch,
        })
    }

    compileHtml(): string {
        return compileMonthSelect(this.id, this.globalState, {
            classes: this.classes,
            isDark: !!this.isDarkBackground,
        })
    }
}

export class StartYearMonthSelect extends PonychartElement {
    label?: string
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch,
        opts: { label?: string } = {}
    ) {
        super(SelectorId.START_YEAR_MONTH_SELECTOR, globalState, localState, {
            traitSearch,
        })
        this.label = opts.label
    }

    compileHtml(): string {
        return compileYearMonthSelect(
            this.id,
            SelectorId.START_YEAR_MONTH_SELECTOR,
            this.globalState,
            {
                label: this.label,
                isDark: !!this.isDarkBackground,
                classes: this.classes,
            }
        )
    }
}

export class EndYearMonthSelect extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(SelectorId.END_YEAR_MONTH_SELECTOR, globalState, localState, {
            traitSearch,
        })
    }

    compileHtml(): string {
        return compileYearMonthSelect(
            this.id,
            SelectorId.END_YEAR_MONTH_SELECTOR,
            this.globalState,
            {
                classes: this.classes,
                isDark: !!this.isDarkBackground,
            }
        )
    }
}

export class Logo extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.LOGO, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return compileLogo(this.id, this.globalState, this.classes)
    }
}

export class HeaderColumn extends ColumnContainer {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        children: PonychartElement[]
    ) {
        super(globalState, localState, { children })
    }

    static getHeaderColumnLocalState(
        globalState: GlobalState,
        traitSearch: TraitSearch,
        opts: { isContainer?: boolean; colored?: boolean } = {}
    ) {
        const localState = new LocalState().setIsFlex(true)
        if (!opts.isContainer)
            localState.addStyles(
                PonychartElementHelpers.getStyledTemplateOptions(
                    globalState,
                    traitSearch,
                    !!opts.colored
                )
            )
        return localState
    }

    adaptYPaddings(indexes: number[]): PonychartElement {
        /**
         * Makes sure the padding is adjusting in order to center vertically the selectors
         */
        // Copy localState to avoid any side-effect since we are about to mutate it
        const height = this.localState.rawStyles.height
        const padding = add(
            this.localState.rawStyles?.padding || '0',
            this.localState.rawStyles?.padding || '0',
            this.localState.rawStyles?.['padding-top'] || '0',
            this.localState.rawStyles?.['padding-bottom'] || '0'
        )
        const innerHeight = substract(height, padding)
        const rowHeight = divideBy(innerHeight, this.children.length)

        for (const index of indexes) {
            if (index >= this.children.length) continue

            const row = this.children[index]
            const margin = row.localState.rawStyles.margin || 0
            const border = row.localState.rawStyles.border || 0
            const rowPadding = divideBy(
                substract(
                    rowHeight,
                    ELEMENT_HEIGHT,
                    margin,
                    margin,
                    border,
                    border
                ),
                2
            )
            row.localState = row.localState
                .copy()
                .addStyles({ padding: rowPadding })
        }
        return this
    }
}

export class HeaderRow extends RowContainer {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        children: PonychartElement[]
    ) {
        super(globalState, localState, { children })
    }

    static getHeaderRowLocalState(
        globalState: GlobalState,
        traitSearch: TraitSearch,
        opts: {
            isContainer?: boolean
            colored?: boolean
            noCard?: boolean
        } = {}
    ) {
        const localState = new LocalState()
            .setIsFlex(true)
            .addStyles(
                PonychartElementHelpers.getStyledTemplateOptions(
                    globalState,
                    traitSearch,
                    !!opts.colored
                )
            )
            .addClasses(
                opts?.colored &&
                    isBigSize(globalState.reduceSize) &&
                    !opts.noCard
                    ? ['x-card']
                    : []
            )

        return localState
    }

    static columnHeight(
        height: number | string,
        padding: string,
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ): number {
        const colored =
            traitSearch.getTraitValue(TraitId.WITH_COLORS, 0) ||
            Bool.FALSE !== Bool.FALSE
        const styledPayload = localState
            .copy()
            .addStyles(
                PonychartElementHelpers.getStyledTemplateOptions(
                    globalState,
                    traitSearch,
                    !!colored
                )
            )
        const innerHeight = substract(height, padding, padding)
        const rawStyles = styledPayload.rawStyles
        const border = multiplyBy(rawStyles?.border || 0, 2)
        const margin = multiplyBy(rawStyles?.margin || 0, 2)
        const p = multiplyBy(rawStyles?.padding || 0, 2)
        const containerHeight = substract(innerHeight, border, margin, p)
        return parseInt(
            divideBy(containerHeight, ELEMENT_HEIGHT).replace('px', '')
        )
    }
}
