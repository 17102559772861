import {
    TraitId,
    QUERY_SELECTOR_TAGS,
    DeviceType,
    TraitOptionItem,
    QuerySelectorTag,
    Trait,
    CHART_TYPE_TRAITS,
    TIMELINE_CHART_TYPES,
    ChartType,
    CHART_TYPE_TRAITS_SET,
} from 'ponychart'
import { t } from '@/ponychart/i18n'
import { TraitSearch } from '@/ponychart/state/traits'
import { SourceMemory } from '@/ponychart/memoize'
import { ColumnType } from './types'
import { cloneDeep } from 'lodash'
import { ICONS } from './config'

export function traitArrayToPayload(traits: Trait[]) {
    return traits.reduce(
        (acc: { [k: string]: string | number }, trait: Trait) => ({
            ...acc,
            [`${trait.id as any}|${trait.deviceType || ''}`]: trait.value,
        }),
        {}
    )
}

export function traitPayloadToArray(
    traitPayload: { [k: string]: string | number },
    traits: Trait[]
) {
    const output: Trait[] = []
    for (const key in traitPayload) {
        try {
            const [traitId, deviceType] = key.split('|')
            const trait = traits.find(
                (trait) =>
                    trait.id === traitId &&
                    (trait.deviceType || DeviceType.DESKTOP) ===
                        (deviceType || DeviceType.DESKTOP)
            )
            if (!trait) continue
            const newTrait = cloneDeep(trait)
            newTrait.value = traitPayload[key]
            output.push(newTrait)
        } catch (e) {
            continue
        }
    }
    // If by any chance some traitIds are missing in the payload, we need to add them.
    const idsFound = output.map((trait) => trait.id)
    for (const trait of traits) {
        if (idsFound.includes(trait.id)) continue
        else output.push(cloneDeep(trait))
    }
    return output
}

// DEPRECATED
export function guessTitleName(
    tag: QuerySelectorTag,
    traitSearch: TraitSearch
) {
    const tags: QuerySelectorTag[] =
        tag === 0 ? QUERY_SELECTOR_TAGS.filter((t) => t >= 0) : [tag]
    let hasDimension = false
    let hasMeasure = false //
    let hasTime = false
    for (const t of tags) {
        if (traitSearch.getTraitValue(TraitId.MEASURE, t)) hasMeasure = true
        if (traitSearch.getTraitValue(TraitId.DIMENSION, t)) hasDimension = true
        for (const chartTypeTrait of CHART_TYPE_TRAITS) {
            if (
                TIMELINE_CHART_TYPES.includes(
                    traitSearch.getTraitValue(chartTypeTrait, t) as ChartType
                )
            ) {
                hasTime = true
            }
        }
    }
    const output = []
    if (hasMeasure) output.push('{measure}')
    if (hasDimension) output.push('{dimension}')
    if (hasTime) output.push('{date}')
    return output.join(` ${t('by')} `)
}

export function dynamicMeasureAlias(traitSearch: TraitSearch) {
    const measureId = traitSearch.getTraitStringValue(TraitId.MEASURE)
    if (!measureId || measureId.includes(';')) return t('dynamic.measure')
    const memory = SourceMemory.getInstance()
    return memory.getMeasure(measureId)?.alias || ''
}

export function dynamicDimensionAlias(traitSearch: TraitSearch) {
    const dimensionId = traitSearch.getTraitStringValue(TraitId.DIMENSION)
    if (!dimensionId || dimensionId.includes(';')) return t('dynamic.dimension')
    const memory = SourceMemory.getInstance()
    return memory.getDimension(dimensionId)?.alias || ''
}

export function showEdit(id: TraitId, value: string) {
    if (CHART_TYPE_TRAITS_SET.has(id)) return value !== ChartType.NONE
    if (id === TraitId.SIDEBAR) return value !== 'none'
    if (id === TraitId.BORDER) return true
    return false
}

export function showIcon(traitId: TraitId) {
    return ICONS[traitId]
}

export function mainTag(
    querySelectorTags: QuerySelectorTag[]
): QuerySelectorTag {
    const tags = querySelectorTags.filter((t) => t !== 0)
    const tag = tags.length === 0 ? 0 : Math.max(...tags)
    return tag as QuerySelectorTag
}

function hash(str: string) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash // Convert to 32bit integer
    }
    return hash
}

export function traitKey(trait: Trait): string {
    const tag = mainTag(trait.querySelectorTags || [0])
    const output: (string | number)[] = [trait.id, tag]
    if (trait.chartType) output.push(trait.chartType)
    return output.join('-')
}

export function toTraitIds(
    traitOptions: TraitOptionItem[] | string[]
): (string | number)[] {
    const optionIds: (string | number)[] = []
    for (const option of traitOptions) {
        if (typeof option === 'string') {
            optionIds.push(option)
        } else {
            optionIds.push(option.id)
        }
    }
    return optionIds
}

export function defaultTraitOption(
    traitOptions: TraitOptionItem[] | string[],
    twbIdx?: number
) {
    if (traitOptions.length > 0) {
        return toTraitIds(_filterTwbIdx(traitOptions, twbIdx))[0]
    } else {
        return ''
    }
}

export function allTraitOptionItems(
    traitOptions: TraitOptionItem[] | string[],
    twbIdx?: number
) {
    return toTraitIds(_filterTwbIdx(traitOptions, twbIdx)).map(String).join(';')
}

function _sort(
    values: (string | number)[],
    optionIds: (string | number)[],
    opts: { sortable?: boolean } = {}
) {
    return opts.sortable
        ? [...values]
        : values.sort((a, b) => optionIds.indexOf(a) - optionIds.indexOf(b))
}

function _filterTwbIdx(options: string[] | TraitOptionItem[], twbIdx?: number) {
    return (options as any).filter(
        (option: string | TraitOptionItem) =>
            typeof option === 'string' ||
            twbIdx === undefined ||
            option?.twbIdx === twbIdx
    )
}

export function getArrayValueFromString(
    value: string | string[],
    opts: {
        items: TraitOptionItem[] | string[]
        allowNone?: boolean
        allowMultiple?: boolean
        sortable?: boolean
        twbIdx?: number
    }
): (string | number)[] {
    const optionIds = toTraitIds(_filterTwbIdx(opts.items, opts.twbIdx))
    let stringValue: string = Array.isArray(value)
        ? getStringValueFromArray(value, opts)
        : value
    stringValue = stringValue || ''

    if (
        ![ColumnType.SINGLE, ColumnType.DYNAMIC].includes(
            stringValue as ColumnType
        )
    ) {
        return _sort(
            stringValue.split(';').filter((value) => !!value),
            optionIds,
            opts
        )
    }

    if (stringValue === ColumnType.SINGLE) {
        return _sort(
            opts.allowNone ? [] : [defaultTraitOption(opts.items, opts.twbIdx)],
            optionIds,
            opts
        )
    }
    if (stringValue === ColumnType.DYNAMIC) {
        return _sort(
            opts.allowMultiple
                ? allTraitOptionItems(opts.items, opts.twbIdx).split(';')
                : [defaultTraitOption(opts.items, opts.twbIdx)],
            optionIds,
            opts
        )
    }
    return []
}
export function getStringValueFromArray(
    value: string[] | string,
    opts: {
        allowNone?: boolean
        allowMultiple?: boolean
        items: TraitOptionItem[] | string[]
        sortable?: boolean
        twbIdx?: number
    }
): string {
    const optionIds: (string | number)[] = toTraitIds(
        _filterTwbIdx(opts.items, opts.twbIdx)
    )

    let arrayValue: (string | number)[] = []
    if (Array.isArray(value)) {
        arrayValue = value.filter((v) => !!v)
    } else if (value == ColumnType.SINGLE) {
        arrayValue = [optionIds.filter((v: string | number) => !!v)[0]]
    } else if (value == ColumnType.DYNAMIC) {
        arrayValue = optionIds.filter((v: string | number) => !!v)
    } else {
        arrayValue = getArrayValueFromString(value, opts).filter((v) => !!v)
    }
    if (arrayValue.length === 0) {
        return opts?.allowNone
            ? ''
            : String(defaultTraitOption(opts.items, opts.twbIdx))
    } else if (arrayValue.length > 1) {
        return opts?.allowMultiple
            ? arrayValue.map(String).join(';')
            : String(defaultTraitOption(opts.items, opts.twbIdx))
    }
    return arrayValue.join(';')
}

// Used in .vue files
export function getStringValueFromString(
    stringValue: string,
    opts: {
        allowNone?: boolean
        items: TraitOptionItem[] | string[]
        allowMultiple?: boolean
        sortable?: boolean
        twbIdx?: number
    }
) {
    if (stringValue === ColumnType.SINGLE || stringValue === '') {
        return opts?.allowNone
            ? ''
            : defaultTraitOption(opts.items, opts.twbIdx)
    } else if (
        stringValue === ColumnType.DYNAMIC ||
        stringValue === allTraitOptionItems(opts.items, opts.twbIdx)
    ) {
        return opts.allowMultiple
            ? allTraitOptionItems(opts.items, opts.twbIdx)
            : defaultTraitOption(opts.items, opts.twbIdx)
    } else {
        return !opts.allowMultiple && stringValue.includes(';')
            ? defaultTraitOption(opts.items, opts.twbIdx)
            : stringValue
    }
}
