import { t } from '@/ponychart/i18n'
import { LabelId } from './types'

import { round } from 'lodash'
import {
    TextElementType,
    TextElementMode,
    LineRow,
    TextElementV2,
} from '@/ponychart/textElement'

// const GROW_ICON = "▲"
// const DECREASE_ICON = "▼"
const BLACK = '#000000'
const WHITE = '#FFFFFF'

const c = (isDark?: boolean) => (isDark ? WHITE : BLACK)

interface LabelContext {
    measureId?: string
    dimensionId?: string
    measureIds?: string[]
    dimensionIds?: string[]
}

interface LabelOptions {
    hasDimension?: boolean
    hasDate?: boolean
}

type EvoType = TextElementType.EVOLUTION | TextElementType.EVOLUTION_COLORED
type DiffType = TextElementType.DIFFERENCE | TextElementType.DIFFERENCE_COLORED

function richKpiV2(evoType: EvoType, diffType: DiffType) {
    return (_: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.MEASURE_CHOICE, {
                    bold: true,
                    isCalculation: true,
                }),
                new TextElementV2(': ', { bold: true }),
                new TextElementV2(TextElementType.THIS_MEASURE, {
                    bold: true,
                    isCalculation: true,
                }),
            ],
            { headingSize: 2 }
        ),
        new LineRow(
            [
                new TextElementV2(diffType, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(evoType, {
                    italic: true,
                    isCalculation: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 4 }
        ),
        new LineRow(
            [
                new TextElementV2(`${t('vs')} `, {}),
                new TextElementV2(TextElementType.LAST_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(` ${t('for')} `),
                new TextElementV2(TextElementType.COMPARISON_PERIOD, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 4 }
        ),
    ]
}

function standardKpiV2(evoType: EvoType, diffType: DiffType) {
    return (_: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.THIS_MEASURE, {
                    bold: true,
                    isCalculation: true,
                }),
            ],
            { headingSize: 2 }
        ),
        new LineRow(
            [
                new TextElementV2(diffType, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(evoType, {
                    italic: true,
                    isCalculation: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 4 }
        ),
        new LineRow(
            [
                new TextElementV2(`${t('vs')} `),
                new TextElementV2(TextElementType.LAST_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(` ${t('for')} `),
                new TextElementV2(TextElementType.COMPARISON_PERIOD, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 4 }
        ),
    ]
}

function simpleKpiV2(evoType: EvoType, diffType: DiffType) {
    return (_: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.THIS_MEASURE, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 2 }
        ),
        new LineRow(
            [
                new TextElementV2(diffType, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(evoType, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 4 }
        ),
    ]
}

function standardTooltipV2(evoType: EvoType, diffType: DiffType) {
    return ({ hasDimension }: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.THIS_PERIOD, {
                    isCalculation: true,
                    bold: true,
                }),
            ],
            { headingSize: 3 }
        ),
        ...(hasDimension
            ? [
                  new LineRow(
                      [
                          new TextElementV2(TextElementType.DIMENSION_CHOICE, {
                              isCalculation: true,
                              bold: true,
                          }),
                          new TextElementV2(': ', {
                              bold: true,
                          }),
                          new TextElementV2(TextElementType.DIMENSION, {
                              isCalculation: true,
                          }),
                      ],
                      { headingSize: 3 }
                  ),
              ]
            : []),
        new LineRow(
            [
                new TextElementV2(TextElementType.MEASURE_CHOICE, {
                    isCalculation: true,
                    bold: true,
                }),
                new TextElementV2(': ', { bold: true }),
                new TextElementV2(TextElementType.THIS_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(' '),
                new TextElementV2(diffType, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(evoType, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 4 }
        ),
        new LineRow(
            [
                new TextElementV2(`${t('vs')} `),
                new TextElementV2(TextElementType.LAST_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(` ${t('for')} `),
                new TextElementV2(TextElementType.COMPARISON_PERIOD, {
                    isCalculation: true,
                }),
                new TextElementV2(` (`, { italic: true }),
                new TextElementV2(TextElementType.LAST_PERIOD, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(`)`, { italic: true }),
            ],
            { headingSize: 4 }
        ),
    ]
}

function dateTooltipV2() {
    return ({ hasDimension }: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.DATE, {
                    isCalculation: true,
                    bold: true,
                }),
            ],
            { headingSize: 2 }
        ),
        ...(hasDimension
            ? [
                  new LineRow(
                      [
                          new TextElementV2(TextElementType.DIMENSION_CHOICE, {
                              isCalculation: true,

                              bold: true,
                          }),
                          new TextElementV2(': ', {
                              bold: true,
                          }),
                          new TextElementV2(TextElementType.DIMENSION, {
                              isCalculation: true,
                          }),
                      ],
                      { headingSize: 3 }
                  ),
              ]
            : []),
        new LineRow(
            [
                new TextElementV2(TextElementType.MEASURE_CHOICE, {
                    isCalculation: true,
                    bold: true,
                }),
                new TextElementV2(': ', { bold: true }),
                new TextElementV2(TextElementType.MEASURE, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 3 }
        ),
    ]
}

function labelEvoDiffV2(evoType: EvoType, diffType: DiffType) {
    return (_: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(diffType, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(evoType, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 3 }
        ),
    ]
}

function labelValueDiffV2(diffType: DiffType) {
    return (_: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.THIS_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(' '),
                new TextElementV2(diffType, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 3 }
        ),
    ]
}

function labelValueEvoV2(evoType: EvoType) {
    return (_: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.THIS_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(' '),
                new TextElementV2(evoType, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 3 }
        ),
    ]
}

function labelValueEvoDiffV2(evoType: EvoType, diffType: DiffType) {
    return (_: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.THIS_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(evoType, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(' ', { italic: true }),
                new TextElementV2(diffType, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 3 }
        ),
    ]
}

function timePeriodIndicationV2() {
    return (_: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.THIS_PERIOD, {
                    isCalculation: true,
                    bold: true,
                }),
            ],
            { headingSize: 2 }
        ),
        new LineRow(
            [
                new TextElementV2('vs '),
                new TextElementV2(TextElementType.LAST_PERIOD, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(TextElementType.COMPARISON_PERIOD, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 3 }
        ),
    ]
}

function mapTooltipV2(evoType: EvoType, diffType: DiffType) {
    return ({ hasDimension }: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.THIS_PERIOD, {
                    isCalculation: true,
                    bold: true,
                }),
            ],
            { headingSize: 3 }
        ),
        ...(hasDimension
            ? [
                  new LineRow(
                      [
                          new TextElementV2(TextElementType.DIMENSION_CHOICE, {
                              isCalculation: true,
                              bold: true,
                          }),
                          new TextElementV2(': ', {
                              bold: true,
                          }),
                          new TextElementV2(TextElementType.DIMENSION, {
                              isCalculation: true,
                          }),
                      ],
                      { headingSize: 3 }
                  ),
              ]
            : []),
        new LineRow(
            [
                new TextElementV2(t('country'), {
                    bold: true,
                }),
                new TextElementV2(': ', { bold: true }),
                new TextElementV2(TextElementType.GEO_COUNTRY, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 3 }
        ),
        new LineRow(
            [
                new TextElementV2(t('region'), {
                    bold: true,
                }),
                new TextElementV2(': ', { bold: true }),
                new TextElementV2(TextElementType.GEO_REGION, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 3 }
        ),
        new LineRow(
            [
                new TextElementV2(t('city'), { bold: true }),
                new TextElementV2(': ', { bold: true }),
                new TextElementV2(TextElementType.GEO_CITY, {
                    isCalculation: true,
                }),
            ],
            { headingSize: 3 }
        ),
        new LineRow(
            [
                new TextElementV2(TextElementType.MEASURE_CHOICE, {
                    isCalculation: true,
                    bold: true,
                }),
                new TextElementV2(': ', { bold: true }),
                new TextElementV2(TextElementType.THIS_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(diffType, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(', ', { italic: true }),
                new TextElementV2(evoType, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 3 }
        ),
        new LineRow(
            [
                new TextElementV2('vs '),
                new TextElementV2(TextElementType.LAST_MEASURE, {
                    isCalculation: true,
                }),
                new TextElementV2(` ${t('for')} `),
                new TextElementV2(TextElementType.LAST_PERIOD, {
                    isCalculation: true,
                }),
                new TextElementV2(' (', { italic: true }),
                new TextElementV2(TextElementType.COMPARISON_PERIOD, {
                    isCalculation: true,
                    italic: true,
                }),
                new TextElementV2(')', { italic: true }),
            ],
            { headingSize: 4 }
        ),
    ]
}

function cardTitleV2() {
    return ({ hasDimension, hasDate }: LabelOptions) => [
        new LineRow(
            [
                new TextElementV2(TextElementType.MEASURE_CHOICE, {
                    isCalculation: true,
                }),
                ...(hasDimension
                    ? [
                          new TextElementV2(` ${t('by')} `, {}),
                          new TextElementV2(TextElementType.DIMENSION_CHOICE, {
                              isCalculation: true,
                          }),
                      ]
                    : []),
                ...(hasDate
                    ? [
                          new TextElementV2(` ${t('by')} `, {}),
                          new TextElementV2(
                              TextElementType.DATE_AGGREGATION_LEVEL_CHOICE,
                              { isCalculation: true }
                          ),
                      ]
                    : []),
            ],
            { headingSize: 3 }
        ),
    ]
}

export class TextElementFactoryV2 {
    private mapElements: {
        [l in LabelId]: (labelOptions: LabelOptions) => LineRow[]
    } = {
        [LabelId.RICH_KPI]: richKpiV2(
            TextElementType.EVOLUTION,
            TextElementType.DIFFERENCE
        ),
        [LabelId.RICH_KPI_COLORED]: richKpiV2(
            TextElementType.EVOLUTION_COLORED,
            TextElementType.DIFFERENCE_COLORED
        ),
        [LabelId.STANDARD_KPI]: standardKpiV2(
            TextElementType.EVOLUTION,
            TextElementType.DIFFERENCE
        ),
        [LabelId.STANDARD_KPI_COLORED]: standardKpiV2(
            TextElementType.EVOLUTION_COLORED,
            TextElementType.DIFFERENCE_COLORED
        ),
        [LabelId.SIMPLE_KPI]: simpleKpiV2(
            TextElementType.EVOLUTION,
            TextElementType.DIFFERENCE
        ),
        [LabelId.SIMPLE_KPI_COLORED]: simpleKpiV2(
            TextElementType.EVOLUTION_COLORED,
            TextElementType.DIFFERENCE_COLORED
        ),
        [LabelId.STANDARD_TOOLTIP]: standardTooltipV2(
            TextElementType.EVOLUTION,
            TextElementType.DIFFERENCE
        ),
        [LabelId.STANDARD_TOOLTIP_COLORED]: standardTooltipV2(
            TextElementType.EVOLUTION_COLORED,
            TextElementType.DIFFERENCE_COLORED
        ),
        [LabelId.DATE_TOOLTIP]: dateTooltipV2(),
        [LabelId.DATE_VALUE]: (_: LabelOptions) => [
            new LineRow(
                [
                    new TextElementV2(TextElementType.DATE, {
                        isCalculation: true,
                        bold: true,
                    }),
                ],
                { headingSize: 3 }
            ),
        ],
        [LabelId.LABEL_THIS_VALUE]: (_: LabelOptions) => [
            new LineRow(
                [
                    new TextElementV2(TextElementType.THIS_MEASURE, {
                        isCalculation: true,
                    }),
                ],
                { headingSize: 3 }
            ),
        ],
        [LabelId.LABEL_VALUE]: (_: LabelOptions) => [
            new LineRow(
                [
                    new TextElementV2(TextElementType.MEASURE, {
                        isCalculation: true,
                    }),
                ],
                { headingSize: 3 }
            ),
        ],
        [LabelId.LABEL_EVO]: (_: LabelOptions) => [
            new LineRow(
                [
                    new TextElementV2(TextElementType.EVOLUTION, {
                        isCalculation: true,
                    }),
                ],
                { headingSize: 3 }
            ),
        ],
        [LabelId.LABEL_EVO_COLORED]: (_: LabelOptions) => [
            new LineRow(
                [
                    new TextElementV2(TextElementType.EVOLUTION_COLORED, {
                        isCalculation: true,
                    }),
                ],
                { headingSize: 3 }
            ),
        ],
        [LabelId.LABEL_DIFF]: (_: LabelOptions) => [
            new LineRow(
                [
                    new TextElementV2(TextElementType.DIFFERENCE, {
                        isCalculation: true,
                    }),
                ],
                { headingSize: 3 }
            ),
        ],
        [LabelId.LABEL_DIFF_COLORED]: (_: LabelOptions) => [
            new LineRow(
                [
                    new TextElementV2(TextElementType.DIFFERENCE_COLORED, {
                        isCalculation: true,
                    }),
                ],
                { headingSize: 3 }
            ),
        ],
        [LabelId.LABEL_THIS_VALUE_EVO]: labelValueEvoV2(
            TextElementType.EVOLUTION
        ),
        [LabelId.LABEL_THIS_VALUE_EVO_COLORED]: labelValueEvoV2(
            TextElementType.EVOLUTION_COLORED
        ),
        [LabelId.LABEL_THIS_VALUE_DIFF]: labelValueDiffV2(
            TextElementType.DIFFERENCE
        ),
        [LabelId.LABEL_THIS_VALUE_DIFF_COLORED]: labelValueDiffV2(
            TextElementType.DIFFERENCE_COLORED
        ),
        [LabelId.LABEL_EVO_DIFF]: labelEvoDiffV2(
            TextElementType.EVOLUTION,
            TextElementType.DIFFERENCE
        ),
        [LabelId.LABEL_EVO_DIFF_COLORED]: labelEvoDiffV2(
            TextElementType.EVOLUTION_COLORED,
            TextElementType.DIFFERENCE_COLORED
        ),
        [LabelId.LABEL_THIS_VALUE_EVO_DIFF]: labelValueEvoDiffV2(
            TextElementType.EVOLUTION,
            TextElementType.DIFFERENCE
        ),
        [LabelId.LABEL_THIS_VALUE_EVO_DIFF_COLORED]: labelValueEvoDiffV2(
            TextElementType.EVOLUTION_COLORED,
            TextElementType.DIFFERENCE_COLORED
        ),
        [LabelId.TIME_PERIOD_INDICATION]: timePeriodIndicationV2(),
        [LabelId.MAP_TOOLTIP]: mapTooltipV2(
            TextElementType.EVOLUTION,
            TextElementType.DIFFERENCE
        ),
        [LabelId.MAP_TOOLTIP_COLORED]: mapTooltipV2(
            TextElementType.EVOLUTION_COLORED,
            TextElementType.DIFFERENCE_COLORED
        ),
        [LabelId.NONE]: (_: LabelOptions) => [
            new LineRow([new TextElementV2('')], {
                headingSize: 3,
            }),
        ],
        [LabelId.CARD_TITLE]: cardTitleV2(),
    }

    get labels() {
        return Object.keys(this.mapElements).filter((k) => k !== LabelId.NONE)
    }

    listTextElementTypesForLabelId(
        labelId: LabelId,
        labelOptions: LabelOptions
    ) {
        const getTextElementType = (e: TextElementV2) =>
            e.textElementType as TextElementType
        return this.mapElements[labelId](labelOptions).reduce(
            (acc: TextElementType[], l: LineRow) => [
                ...acc,
                ...l.elements
                    .filter(getTextElementType)
                    .map(getTextElementType),
            ],
            []
        )
    }

    constructor() {
        //
    }

    buildLines(labelId: LabelId, labelOptions: LabelOptions) {
        return this.mapElements[labelId](labelOptions)
    }

    buildDb(
        labelId: LabelId,
        labelOptions: LabelOptions,
        opts: { alignment: 'left' | 'center' | 'right' }
    ): string {
        const lines = this.mapElements[labelId](labelOptions)
        for (const line of lines) line.alignment = opts.alignment
        return JSON.stringify(lines.map((line) => line.toDb()))
    }

    buildHtml(
        labelId: LabelId,
        labelOptions: LabelOptions,
        mode: TextElementMode,
        opts: LabelContext & { alignment: 'left' | 'center' | 'right' }
    ) {
        const lines = this.mapElements[labelId](labelOptions)
        for (const line of lines) line.alignment = opts.alignment
        return lines.map((line) => line.getHtml(mode, opts)).join('')
    }

    buildSvg(
        labelId: LabelId,
        labelOptions: LabelOptions,
        opts: LabelContext & {
            mode: TextElementMode
            reduceSize: number
            height: number
            width: number
            isCenter: boolean
            isMiddle: boolean
        }
    ) {
        const lines = this.mapElements[labelId](labelOptions)
        const lineHeights = lines.map((l) => l.height)
        const totalHeight = round(
            lineHeights.reduce((a, b) => a + b, 0),
            2
        )
        let y = (opts.height - totalHeight) / 2
        const output = []
        for (const line of lines) {
            y += line.height
            output.push(
                line.getPreviewSvg({
                    ...opts,
                    x: 0,
                    y,
                })
            )
        }
        return output.join('')
    }
}
