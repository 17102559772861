<template lang="pug">
v-btn.primary--text(
    small,
    color='white',
    style='box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; position: absolute; left: 50%; top: 0; transform: translate(-50%, -50%); z-index: 2',
    depressed,
    @click='$emit("click")'
)
    v-icon(color='primary', left) add_circle
    span {{ $t("addPageBlock") }}
</template>

<script>
export default {
    props: {
        outlined: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<i18n>{
  "en": {
    "addPageBlock": "Add page block"
  },
  "fr": {
    "addPageBlock": "Ajouter bloc de page"
  },
  "es": {
    "addPageBlock": "Aggregar bloque de página"
  },
  "de": {
    "addPageBlock": "Seitenblock hinzufügen"
  }
}</i18n>