<template lang="pug">
.container
    form.form
        h1(style='color: white') {{ $t("forgot") }}
        br
        .shaker(:class='shaking.email && "shaking"')
            input(
                :class='wrong.email && "wrong"',
                :disabled='loading',
                type='text',
                :placeholder='$t("backup_email")',
                v-model='email'
            )
        .shaker(:class='shaking.code && "shaking"', v-if='show')
            input(
                :class='wrong.code && "wrong"',
                :disabled='loading',
                type='text',
                :placeholder='$t("code")',
                v-model='code'
            )
        .shaker(:class='shaking.password && "shaking"', v-if='show')
            input(
                :class='wrong.password && "wrong"',
                :disabled='loading',
                ref='signin_password',
                type='password',
                :placeholder='$t("signup_page.new")',
                v-model='password'
            )
        v-layout(row, align-center, fill-height, justify-center)
            .hover-button(v-if='show', @click.prevent='forgotPassword') {{ $t("resend") }}
            button(
                :disabled='loading',
                :style='show ? "width:150px" : ""',
                @click.prevent='show ? submitNewPassword() : forgotPassword()'
            ) {{ $t("reset") }}
        div(style='margin-top: 10px')
            router-link(style='color: white', :to='"/" + lang + "/signup"') {{ $t("signup") }}
        div(style='margin-top: 10px')
            router-link(style='color: white', :to='"/" + lang + "/login"') {{ $t("signin") }}
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
    name: 'forgotPassword',
    methods: {
        ...mapMutations(['SET']),
        async submitNewPassword() {
            if (this.emailInvalid(this.email)) {
                this.setWrong('email')
                this.showToast({
                    code: 'BAD_EMAIL_FORMAT',
                    type: 'warning',
                    timeout: 4000,
                })
                return
            }
            if (!this.code) {
                this.setWrong('code')
                this.showToast({
                    type: 'warning',
                    message: this.$t('errors.CodeMismatchException'),
                    timeout: 6000,
                })
                return
            }
            const message = this.passwordInvalid(this.password)
            if (message) {
                this.setWrong('password')
                this.showToast({
                    type: 'warning',
                    message,
                    timeout: 6000,
                })
                return
            }
            this.SET({ loading: true })
            await this.$store
                .dispatch('cognito/forgotPasswordSubmit', {
                    username: this.email,
                    code: this.code,
                    newPassword: this.password,
                })
                .then(() => {
                    this.showToast({
                        code: 'RESET_PASSWORD_SUCCESS',
                        type: 'info',
                        timeout: 6000,
                    })
                    this.goTo(`/${this.lang}/login`)
                })
                .catch((e) => {
                    this.showToast(e)
                })
            this.SET({ loading: false })
        },
        forgotPassword() {
            if (this.isFormValid()) {
                this.SET({ loading: true })
                this.$store
                    .dispatch('cognito/forgotPassword', {
                        username: this.email,
                    })
                    .then(() => {
                        this.showToast({
                            code: 'EMAIL_IF_CORRECT',
                            type: 'info',
                            time: 4000,
                        })
                        this.SET({ loading: false })
                        this.show = true
                    })
                    .catch((err) => {
                        this.showToast(err)
                        this.SET({ loading: false })
                    })
            } else {
                this.SET({ loading: false })
            }
        },
        isFormValid() {
            if (this.emailInvalid(this.email)) {
                this.showToast({
                    code: 'BAD_EMAIL_FORMAT',
                    type: 'warning',
                    time: 4000,
                })
                this.setWrong('email')

                return false
            }
            return true
        },
        setShaking(key) {
            this.shaking[key] = true
            setTimeout(() => (this.shaking[key] = false), 1000)
        },
        setWrong(key) {
            this.wrong[key] = true
            this.setShaking(key)
        },
    },
    data: () => ({
        email: '',
        code: '',
        password: '',
        show: false,
        shaking: {
            email: false,
            password: false,
            code: false,
        },
        wrong: {
            email: false,
            password: false,
            code: false,
        },
    }),

    computed: {
        ...mapState(['loading']),
    },
    watch: {
        isLoggedIn: {
            handler: function (value) {
                if (value) {
                    this.goTo('/')
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.$refs.forgot_shaker.childNodes[0].addEventListener(
            'keyup',
            (e) => {
                if (e.keyCode != 13) {
                    this.$refs.forgot_shaker.childNodes[0].classList.remove(
                        'wrong'
                    )
                }
            },
            this
        )
    },
}
</script>

<style scoped src="@/assets/form.css" />
