<template lang="pug">
div(style='height: 70%')
    #progress_signup(
        ref='progress',
        v-if='!ended && !$store.state.loading && !endedTimeout',
        :style='{ width: (position * 100) / (questions.length - 2) + "%" }'
    )
    v-fab-transition
        form#container_signup(
            onsubmit='return false',
            v-if='!ended && !endedTimeout'
        )
            .center_signup
                .title_signup {{ $t("signup_page.title") }}
                #register_signup(ref='register')
                    i#previousButton_signup.material-icons.notranslate(
                        @click='back'
                    ) {{ position ? "arrow_back" : "person" }}
                    i#forwardButton_signup.material-icons.notranslate(
                        @click='next'
                    ) arrow_forward
                    #inputContainer_signup(ref='inputContainer')
                        input#inputField_signup(
                            ref='inputField',
                            required,
                            multiple,
                            v-model='questions[position].answer',
                            :type='currentQuestion.type || "text"',
                            v-on:keyup.enter='next'
                        )
                        label#inputLabel_signup(
                            ref='inputLabel',
                            v-html='$t(`signup_page.${currentQuestion.question}`, { name })'
                        )
                        #inputProgress_signup.mr-8(ref='inputProgress')
                br
                .router_signup
                    router-link(style='color: white', :to='`/${lang}/login`') {{ $t("already_signed_up") }}
                .router_signup
                    router-link(style='color: white', :to='`/${lang}/forgot`') {{ $t("forgot") }}
    v-fab-transition
        signup-summary.mt-16(
            v-if='ended && endedTimeout',
            :loading='loading',
            @back='back',
            @signup='signup',
            @login='login(email, code, password)',
            @set='set',
            @resendCode='resendCode(email)',
            :window='window',
            :code='code',
            :name='name',
            :email='email',
            :password='password',
            :company-name='companyName',
            :email-error='emailError',
            :password-error='passwordError'
        )
    language
</template>

<script>
import axios from 'axios'
import SignupSummary from '@/components/utils/SignupSummary'
import Language from '@/components/Home/Language'
import { mapActions, mapMutations } from 'vuex'
import { TYPE } from 'vue-toastification'
export default {
    name: 'signupComponent',
    components: {
        SignupSummary,
        Language,
    },
    data: () => ({
        switching: false,
        loading: false,
        translateTime: 100,
        widthTime: 200,
        position: 0,
        window: 0,
        questions: [
            { question: 'name', answer: '' },
            {
                question: 'email',
                answer: '',
            },
            {
                question: 'password',
                type: 'password',
                answer: '',
            },
            // {
            //     question: 'companyName',
            //     answer: '',
            // },
            { question: 'summary', answer: {} },
        ],
        emailError: '',
        passwordError: '',
        code: '',
        help: false,
        endedTimeout: false,
    }),
    methods: {
        ...mapActions({
            confirmUser: 'cognito/confirmUser',
            signInUser: 'cognito/signInUser',
            resendConfirmation: 'cognito/resendConfirmation',
            registerUser: 'cognito/registerUser',
        }),
        ...mapMutations(['SET']),
        async login(username, code, password) {
            this.loading = true
            await this.confirmUser({ username, code })
                .then(() => this.signInUser({ username, password }))
                .then(() => {
                    this.goTo('/')
                })
                .catch((err) => {
                    console.log(err)
                    this.showToast(err)
                })
            this.loading = false
        },
        back() {
            if (this.position !== 0) this.position -= 1
        },
        transform(x, y) {
            this.$refs.register.style.transform =
                'translate(' + x + 'px ,  ' + y + 'px)'
        },
        ok() {
            this.$refs.register.className = ''
            setTimeout(this.transform, this.translateTime * 0, 0, 10)
            setTimeout(this.transform, this.translateTime * 1, 0, 0)
            return new Promise((resolve) => {
                setTimeout(() => resolve(), this.translateTime * 2)
            })
        },
        wrong() {
            this.$refs.register.className = 'wrong'
            for (let i = 0; i < 6; i++) {
                setTimeout(
                    this.transform,
                    this.translateTime * i,
                    ((i % 2) * 2 - 1) * 20,
                    0
                )
            }
            setTimeout(this.transform, this.translateTime * 6, 0, 0)
            if (this.$refs.inputProgress)
                this.$refs.inputProgress.style.width = '85%'
            return new Promise((resolve) => {
                setTimeout(() => resolve(), this.translateTime * 7)
            })
        },
        async validateEmail() {
            const emailInvalid = this.emailInvalid(this.email)
            if (emailInvalid) return false
            try {
                await axios('/v1/check-email', {
                    params: { email: this.email },
                })
                return true
            } catch (error) {
                const message = error?.response?.data?.message
                const domain = error?.response?.data?.domain || ' '
                if (this.$te('errors.' + message))
                    this.showToast({
                        message: this.$t('errors.' + message, { domain }),
                    })
                return false
            }
        },
        validatePassword() {
            const invalid = this.password.length < 8
            if (invalid)
                this.showToast({ type: 'warning', code: 'PASSWORD_GUIDE_2' })
            return !invalid
        },
        async validate() {
            if (!this.currentQuestion.answer) return false
            if (this.currentQuestionId == 'email') {
                return await this.validateEmail()
            } else if (this.currentQuestionId == 'password') {
                return this.validatePassword()
            }
            return true
        },
        async resendCode(username) {
            this.SET({ loading: true })
            const errorPayload = await this.resendConfirmation({ username })
                .then(() => ({
                    code: 'USER_CREATED',
                    type: 'info',
                    timeout: 8000,
                }))
                .catch(() => ({
                    code: 'UNKNOWN',
                    type: 'error',
                }))
            this.showToast(errorPayload)
            this.SET({ loading: false })
        },
        async next() {
            if (this.switching) return
            if (this.$refs.inputProgress)
                this.$refs.inputProgress.style.width = '0'
            this.switching = true
            if (await this.validate()) {
                await this.ok()
                this.position += 1
            } else {
                await this.wrong()
            }
            this.setFocus()
            this.switching = false
        },
        setFocus() {
            if (this.$refs.inputField) this.$refs.inputField.focus()
        },
        displayAndReturnQuestion() {
            const inputContainer = this.$refs.inputContainer
            if (!inputContainer) return
            inputContainer.style.opacity = 1
            inputContainer.style.transition = ''
            inputContainer.style.width = '100%'
            return inputContainer.$el
        },
        async showCurrent() {
            await this.$nextTick()
            return new Promise((resolve) => {
                // this.$refs.inputProgress.style.transition = ''
                setTimeout(
                    () => {
                        if (this.$refs.inputProgress)
                            this.$refs.inputProgress.style.width = '85%'
                    },
                    this.translateTime,
                    this
                )
                const el = this.displayAndReturnQuestion()
                this.setFocus()
                if (el) {
                    setTimeout(
                        () => {
                            resolve()
                        },
                        this.widthTime,
                        this
                    )
                } else {
                    resolve()
                }
            })
        },
        findAnswer(question) {
            const q = this.questions.find((q) => q.question == question)
            return q ? q.answer : ''
        },
        set(payload) {
            for (const i in payload) {
                if (['email', 'name', 'password', 'companyName'].includes(i)) {
                    for (const question of this.questions) {
                        if (question.question == i) {
                            question.answer = payload[i]
                        }
                    }
                } else {
                    this[i] = payload[i]
                }
            }
        },
        handleSignupError(err) {
            this.window = 0
            const errorMap = {
                UsernameExistsException: {
                    code: 'USER_EXISTS',
                    type: TYPE.ERROR,
                    make: 'emailError',
                },
                InvalidPasswordException: {
                    code: 'PASSWORD_GUIDE_2',
                    type: TYPE.WARNING,
                    make: 'passwordError',
                },
                InvalidParameterException: {
                    code: 'PASSWORD_GUIDE_2',
                    type: TYPE.WARNING,
                    make: 'passwordError',
                },
                ExpiredCodeException: {
                    code: 'CodeMismatchException',
                    type: TYPE.ERROR,
                    make: undefined,
                },
            }
            const { code, type, make } = errorMap[err.code]
                ? errorMap[err.code]
                : {
                      code: err.code ? err.code : 'UNKNOWN',
                      type: TYPE.ERROR,
                      make: false,
                  }
            if (make) {
                this[make] = this.$t('errors.' + code)
            }
            this.showToast({
                type,
                code,
                timeout: 4000,
            })
        },
        async signup() {
            this.loading = true
            const { email, name, password, lang, companyName } = this
            const username = email.toLowerCase().trim()
            const params = {
                username,
                password,
                attributes: {
                    email: username,
                    family_name: name.split(' ').slice(0, -1).join(' '),
                    given_name: name.split(' ').slice(-1).join(' '),
                    locale: lang,
                    'custom:company_name': companyName,
                },
                validationData: [],
            }
            await this.registerUser(params)
                .then(() => {
                    this.window = 1
                })
                .catch(this.handleSignupError)
            this.loading = false
        },
    },
    computed: {
        email() {
            return this.findAnswer('email')
        },
        url() {
            return this.findAnswer('url')
        },
        password() {
            return this.findAnswer('password')
        },
        name() {
            return this.findAnswer('name')
        },
        companyName() {
            return ''
            // return this.findAnswer('companyName')
        },
        currentQuestion() {
            return this.questions[this.position]
        },
        currentQuestionId() {
            return this.currentQuestion ? this.currentQuestion.question : ''
        },
        ended() {
            return this.position === this.questions.length - 1
        },
    },
    watch: {
        ended: {
            handler: async function (ended) {
                await new Promise((resolve) => setTimeout(() => resolve(), 500))
                this.endedTimeout = ended
                if (!this.endedTimeout) this.showCurrent()
            },
            immediate: true,
        },
        position: {
            handler: async function () {
                if (!this.ended) {
                    await this.$nextTick()
                    this.showCurrent()
                }
            },
            immediate: true,
        },
        isLoggedIn(val) {
            if (val) {
                this.goTo('/')
            }
        },
    },
}
</script>

<style scoped src="@/assets/signup.css"></style>
