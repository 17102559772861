import {
    getBands,
    getColorForElement,
    lineChartIcon,
    svgArrayFromNumeric,
    svgMatrixFromNumeric,
} from '@/ponychart/utils'
import {
    BandMode,
    dynamicMeasureAlias,
    MeasureColorLocation,
} from '@/ponychart/trait'
import { ChartType, TraitId } from 'ponychart'

import { SVGChart } from '@/ponychart/svg/model'
import { PonychartElement } from '@/ponychart/element/model'
import type { GlobalState, LocalState, TraitSearch } from '@/ponychart/state'
import { RandomMemory, SourceMemory } from '@/ponychart/memoize'

import { initTooltip } from './utils'
import { Position } from './types'

const sourceMemory = SourceMemory.getInstance()

function _buildMultilineChart(
    element: PonychartElement,
    svgChart: SVGChart,
    prev: Position,
    tooltipId?: string
) {
    const measure = element.getStringRequiredAttribute(TraitId.MEASURE)
    const dimension = element.getStringRequiredAttribute(TraitId.DIMENSION)

    const memory = RandomMemory.getInstance()

    const numericValues = memory.getNumericMatrix(measure, dimension)
    const colors = memory.getColors(dimension)

    const matrixValues = svgMatrixFromNumeric(numericValues, {
        height: svgChart.height,
        width: svgChart.width,
        maxAxisValue: Math.max(...numericValues.map((row) => Math.max(...row))),
    })

    const increment = matrixValues[0][0].increment

    for (let j = 0; j < matrixValues.length; j++) {
        for (let i = 0; i < matrixValues[j].length; i++) {
            const { y, value } = matrixValues[j][i]
            if (i !== 0) {
                svgChart.line(
                    prev,
                    { x: y + increment, y: svgChart.height - value },
                    {
                        color: colors[j],
                        width: 2,
                        tooltipId,
                        thisMeasure: value,
                        lastMeasure: value,
                    }
                )
            }
            prev.x = y + increment
            prev.y = svgChart.height - value
        }
    }
    return increment
}

function _buildSingleLineChart(
    element: PonychartElement,
    svgChart: SVGChart,
    prev: Position,
    tooltipId?: string
) {
    const measure = element.getStringRequiredAttribute(TraitId.MEASURE)
    const dimension = element.getStringAttribute(TraitId.DIMENSION) || ''
    const memory = RandomMemory.getInstance()

    const numericValues = memory.getNumericArray(measure, dimension)

    const values = svgArrayFromNumeric(numericValues, {
        height: svgChart.height,
        width: svgChart.width,
    })

    const increment = values[0].increment

    values.forEach(({ y, value }, i) => {
        if (i !== 0) {
            svgChart.line(
                prev,
                { x: y + increment, y: svgChart.height - value + increment },
                {
                    color: getColorForElement(
                        element,
                        sourceMemory,
                        MeasureColorLocation.CURVES,
                        { measure }
                    ),
                    width: 2,
                    tooltipId,
                    thisMeasure: value,
                    lastMeasure: value,
                }
            )
        }
        prev.x = y + increment
        prev.y = svgChart.height - value + increment
    })
    return increment
}

function _buildLineChart(element: PonychartElement) {
    if (element.globalState.onlyContainer)
        return SVGChart.onlyContainer(element.id, element.classes)
    const svgChart = new SVGChart(ChartType.LINE, element.ratios)

    const isMatrix = !!element.getStringAttribute(TraitId.DIMENSION)
    const measure = element.getStringRequiredAttribute(TraitId.MEASURE)
    const dimension = element.getStringAttribute(TraitId.DIMENSION)

    const { id: tooltipId, html: tooltipHtml } = initTooltip(element, {
        dimensionId: dimension,
        measureId: measure,
    })

    const bandMode = element.getStringRequiredAttribute(TraitId.CHART_SUBTYPE)
    if (bandMode === BandMode.BAND) {
        for (const [s, e] of getBands(svgChart.width, svgChart.height)) {
            svgChart.rectangle(s, e, 'gray', {
                opacity: 0.5,
            })
            svgChart.line(
                s,
                { x: s.x, y: e.y },
                { strokeDashArray: '2,2', width: 0.5 }
            )
            svgChart.line(
                e,
                { x: e.x, y: s.y },
                { strokeDashArray: '2,2', width: 0.5 }
            )
        }
    }

    const prev: Position = { x: 0, y: 0 }
    const increment = isMatrix
        ? _buildMultilineChart(element, svgChart, prev, tooltipId)
        : _buildSingleLineChart(element, svgChart, prev, tooltipId)

    const start: Position = { x: increment, y: svgChart.height - increment }
    const end: Position = {
        x: svgChart.width - increment,
        y: increment,
    }
    const c = svgChart
        // X Axis
        .line(start, { x: end.x, y: start.y })
        .triangle({ x: start.x, y: end.y }, increment / 5, 'top', '#000')
        // Y Axis
        .line(start, { x: start.x, y: end.y })
        // Axis arrow
        .triangle({ x: end.x, y: start.y }, increment / 5, 'right', '#000')
    if (element.globalState.reduceSize === 1) {
        c.text(`Time`, {
            x: svgChart.width / 2,
            y: start.y - (increment * 4) / 3,
        }).text(
            `Measure: ${dynamicMeasureAlias(element.traitSearch)}`,
            { x: increment / 2, y: svgChart.height / 3 },
            { rotate: -90 }
        )
    }

    return (
        svgChart.rawSvg(element.id, {
            classes: element.classes,
            viewBox: element.ratios,
            preserveAspectRatio: {
                x: 'Mid',
                y: 'Mid',
                suffix: 'meet',
            },
        }) + tooltipHtml
    )
}

export class LineChart extends PonychartElement {
    constructor(
        globalState: GlobalState,
        localState: LocalState,
        traitSearch: TraitSearch
    ) {
        super(ChartType.LINE, globalState, localState, { traitSearch })
    }

    compileHtml(): string {
        return _buildLineChart(this)
    }

    get icon(): string {
        return lineChartIcon
    }
}
