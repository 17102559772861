import {
    buildNumericMatrix,
    cumulateMatrix,
    dimensionColor,
    sumMatrixToArray,
} from '@/ponychart/utils'

function getEvoValues(l: number) {
    const output = []
    for (let i = 0; i < l; i++) {
        output.push((Math.random() - 0.5) * 0.5)
    }
    return output
}

function getDimensionTotal(matrix: number[][]) {
    const output = matrix.map((row) => row.reduce((a, b) => a + b, 0))
    const maxValue = Math.max(...output)
    return output.map((v) => v / maxValue)
}

export class RandomMemory {
    private static instance: RandomMemory
    private evoValues: { [key: string]: number[] } = {}
    private numericMatrix: { [key: string]: number[][] } = {}
    private cumulatedMatrix: { [key: string]: number[][] } = {}
    private numericArray: { [key: string]: number[] } = {}
    private dimensionTotal: { [key: string]: number[] } = {}
    private colorValues: { [key: string]: string[] } = {}

    private constructor() {
        this.colorValues = {}
    }

    public static getInstance(): RandomMemory {
        if (!RandomMemory.instance) RandomMemory.instance = new RandomMemory()
        return RandomMemory.instance
    }

    flush() {
        this.evoValues = {}
        this.numericArray = {}
        this.numericMatrix = {}
        this.cumulatedMatrix = {}
    }

    randomDimension() {
        return Math.random() * (10 - 5) + 5
    }

    private getKey(measure: string, dimension: string) {
        return measure + '_' + dimension
    }

    private buildNumbers(measure: string, dimension: string) {
        const colors = this.getColors(dimension)
        const l = colors.length
        const key = this.getKey(measure, dimension)
        this.numericMatrix[key] = buildNumericMatrix({
            arrayLength: 12,
            arrayMaxValue: 1,
            arrayMinValue: 0.6,
            matrixLength: l,
        })
        this.cumulatedMatrix[key] = cumulateMatrix(this.numericMatrix[key])
        this.numericArray[key] = sumMatrixToArray(this.numericMatrix[key])
        this.evoValues[key] = getEvoValues(l)
        this.dimensionTotal[key] = getDimensionTotal(this.numericMatrix[key])
    }

    getColors(dimension: string) {
        if (!this.colorValues[dimension])
            this.colorValues[dimension] = dimensionColor(this.randomDimension())
        return this.colorValues[dimension]
    }

    getEvoValues(measure: string, dimension: string): number[] {
        const key = this.getKey(measure, dimension)
        const colors = this.getColors(dimension)
        if (this.evoValues[key] && this.evoValues[key].length === colors.length)
            return this.evoValues[key]
        this.buildNumbers(measure, dimension)
        return this.evoValues[key]
    }

    getCumulatedMatrix(measure: string, dimension: string) {
        const key = this.getKey(measure, dimension)
        const colors = this.getColors(dimension)
        if (
            this.cumulatedMatrix[key] &&
            this.cumulatedMatrix[key].length === colors.length
        )
            return this.cumulatedMatrix[key]
        this.buildNumbers(measure, dimension)
        return this.cumulatedMatrix[key]
    }

    getNumericMatrix(measure: string, dimension: string) {
        const key = this.getKey(measure, dimension)
        const colors = this.getColors(dimension)
        if (
            this.numericMatrix[key] &&
            this.numericMatrix[key].length === colors.length
        )
            return this.numericMatrix[key]
        this.buildNumbers(measure, dimension)
        return this.numericMatrix[key]
    }

    getNumericArray(measure: string, dimension: string): number[] {
        const key = this.getKey(measure, dimension)
        if (this.numericArray[key]) return this.numericArray[key]
        this.buildNumbers(measure, dimension)
        return this.numericArray[key]
    }

    getMeasure(measure: string, dimension?: string) {
        const total = this.getNumericArray(measure, dimension || '')[0]
        const evo = this.getEvoValues(measure, dimension || '')[0]
        return {
            thisMeasure: total,
            lastMeasure: total * (1 - evo),
        }
    }

    getDimensionTotal(measure: string, dimension: string) {
        const key = this.getKey(measure, dimension)
        const colors = this.getColors(dimension)
        if (
            this.dimensionTotal[key] &&
            this.dimensionTotal[key].length === colors.length
        )
            return this.dimensionTotal[key]
        this.buildNumbers(measure, dimension)
        return this.dimensionTotal[key]
    }

    getSorted(measure: string, dimension: string) {
        const total = this.getDimensionTotal(measure, dimension)
        const colors = this.getColors(dimension)

        const sortedTotal = [...total].sort((a, b) => b - a)
        const indexes = sortedTotal.map((v) => total.indexOf(v))
        const newColors = indexes.map((i) => colors[i])

        return {
            numericValues: sortedTotal,
            colors: newColors,
        }
    }
}
