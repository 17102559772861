import { FlexType } from '@/ponychart'
import { CLS_COL, CLS_ROW } from '@/ponychart/element/config'
import { Vue, Component } from 'vue-property-decorator'

@Component
export class PageTemplate extends Vue {
    flexHook(model: any, source: string) {
        console.log(source)
        const components = model.components()
        const parent = model.parent()
        const attributes = model.getAttributes()
        console.log(attributes.flex)
        if (parent) {
            console.log(components.length)
            const { title, flex } = parent.getAttributes()
            // Avoid using Array.from() on IE
            let unstylable = new Set((model.get('unstylable') || []).filter(
                (s: string) => !!s
            ))
            const style = model.getStyle() || {}
            let newStyle: any = {}
            if (flex === FlexType.FLEX) {
                model.addClass(FlexType.FLEX)
                model.removeClass(FlexType.INLINE)
                unstylable = new Set([...unstylable, 'width', 'height'])
                newStyle = { ...style, width: 'auto', height: 'auto' }
            } else {
                model.addClass(FlexType.INLINE)
                model.removeClass(FlexType.FLEX)
                if (title === CLS_ROW) {
                    unstylable.add('height')
                    newStyle.height = 'auto'
                }
                if (title === CLS_COL) {
                    unstylable.add('width')
                    newStyle.width = 'auto'
                }
            }
            model.set({ unstylable: Array.from(unstylable) })
            model.setStyle({
                ...style,
                ...newStyle,
            })
        }
    }
    styleManager = {
        sectors: [
            {
                name: 'Dimension',
                buildProps: ['width', 'height'],
                properties: [
                    {
                        type: 'integer',
                        name: this.$t('width'),
                        property: 'width',
                        units: ['px'],
                        defaults: 'auto',
                        min: 0,
                    },
                    {
                        type: 'integer',
                        name: this.$t('height'),
                        property: 'height',
                        units: ['px'],
                        defaults: 'auto',
                        min: 0,
                    },
                ],
            },
            {
                name: 'Color',
                buildProps: [
                    'background-color',
                    'color',
                    'border-width',
                    'border-color',
                ],
                properties: [
                    {
                        type: 'integer',
                        property: 'border-width',
                        units: ['px'],
                        defaults: 0,
                        min: 0,
                    },
                ],
            },
            {
                name: 'Margin & padding',
                buildProps: ['margin', 'padding'],
                properties: [
                    {
                        type: 'integer',
                        property: 'margin',
                        units: ['px'],
                        defaults: 0,
                        min: 0,
                    },
                    {
                        type: 'integer',
                        property: 'padding',
                        units: ['px'],
                        defaults: 0,
                        min: 0,
                    },
                ],
            },
            {
                name: 'Font',
                buildProps: ['font-size'],
                properties: [
                    {
                        property: 'font-size',
                        units: ['px'],
                        defaults: 0,
                        min: 0,
                    },
                ],
            },
        ],
    }
}
