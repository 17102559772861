import { TraitId } from 'ponychart'

export const TRAIT_DEPENDENCIES: [TraitId, TraitId][] = [
    [TraitId.TIME_SELECTOR_TYPE, TraitId.SOURCE_DATE_AGGREGATION_LEVEL],
    [TraitId.COMPARISON_PERIODS, TraitId.TIME_SELECTOR_TYPE],
    [TraitId.DATE_AGGREGATION_LEVEL, TraitId.SOURCE_DATE_AGGREGATION_LEVEL],
    [TraitId.CARD_TITLE, TraitId.DIMENSION],
    [TraitId.CARD_TITLE, TraitId.DATE_AGGREGATION_LEVEL],
]

/**
 * general topological sort
 * @author SHIN Suzuki (shinout310@gmail.com)
 * @param Array<Array> edges : list of edges. each edge forms Array<ID,ID> e.g. [12 , 3]
 *
 * @returns Array : topological sorted list of IDs
 **/

function tsort(edges: [TraitId, TraitId][]) {
    const nodes = {}, // hash: stringified id of the node => { id: id, afters: lisf of ids }
        sorted: TraitId[] = [], // sorted list of IDs ( returned value )
        visited = {} // hash: id of already visited node => true

    class Node {
        afters = []
        constructor(public id: TraitId) {}
    }

    // 1. build data structures
    edges.forEach((v) => {
        const from = v[0],
            to = v[1]
        if (!nodes[from]) nodes[from] = new Node(from)
        if (!nodes[to]) nodes[to] = new Node(to)
        nodes[from].afters.push(to)
    })
    function visit(idstr: string, ancestors: TraitId[] = []) {
        const node = nodes[idstr],
            id = node.id

        // if already exists, do nothing
        if (visited[idstr]) return

        if (!Array.isArray(ancestors)) ancestors = []

        ancestors.push(id)

        visited[idstr] = true

        node.afters.forEach((afterID: TraitId) => {
            if (ancestors.indexOf(afterID) >= 0)
                // if already in ancestors, a closed chain exists.
                throw new Error('closed chain : ' + afterID + ' is in ' + id)

            visit(
                afterID.toString() as TraitId,
                ancestors.map(function (v) {
                    return v
                })
            ) // recursive call
        })

        sorted.unshift(id)
    }

    // 2. topological sort
    Object.keys(nodes).forEach((e) => visit(e))

    return sorted.reverse()
}

export const TRAIT_ID_ORDER = tsort(TRAIT_DEPENDENCIES)
