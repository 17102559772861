import axios from 'axios'

const PING_KEY = "ping" as const

const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "desktop";
};

export default {
    ping: async function (): Promise<any> {
        const notPingedYet = !localStorage.getItem(PING_KEY)
        if (!notPingedYet) return
        await axios.post(`/analytics`, { deviceType: getDeviceType() })
        localStorage.setItem(PING_KEY, "true")
    },
}
