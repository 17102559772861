<template lang="pug">
v-row(style="height: 50px" align="center" justify="center").grey.lighten-2.semi-rounded.pa-2.mx-0.mt-2
    template(v-for="(item, index) in items")
        .divider(v-if="item.type === 'divider'" :key="`divider${index}`")
        //- input(
        //-   style="cursor: pointer"
        //-   v-else-if="item.type === 'color'"
        //-   type="color"
        //-   @input="editor.chain().focus().setColor($event.target.value).run()"
        //-   :value="editor.getAttributes('textStyle').color"
        //- )
        v-select(
          dense outlined hide-details
          style="max-width: 100px; transform: translateY(-2px)"
          v-else-if="item.type === 'v-select'"
          :items="[0, 1, 2, 3, 4].map(i => ({value: i + 1, text: $t(`sizes.${i}`)}))"
          v-model="activeHeading"
        ).grey.lighten-2
        v-btn(
            v-else
            :key="`btn${index}`"
            @click="item.action"
            :outlined="item.isActive ? item.isActive() : false"
            :title="item.title"
            color="primary"
            icon
            tile
        )
            v-icon {{ item.icon }}
</template>

<script>
export default {
    props: {
        editor: {
            type: Object,
            required: true,
        },
    },
    computed: {
        activeHeading: {
            get() {
                if (!this.editor) return 4
                for (let i = 1; i < 7; i++) {
                    if (this.editor.isActive('heading', { level: i })) return i
                }
                return 4
            },
            set(value) {
                if (!this.editor) return
                this.editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: value })
                    .run()
            },
        },
    },

    data() {
        return {
            items: [
                {
                    icon: 'format_bold',
                    title: 'Bold',
                    action: () =>
                        this.editor.chain().focus().toggleBold().run(),
                    isActive: () => this.editor.isActive('bold'),
                },
                {
                    icon: 'format_italic',
                    title: 'Italic',
                    action: () =>
                        this.editor.chain().focus().toggleItalic().run(),
                    isActive: () => this.editor.isActive('italic'),
                },
                {
                    icon: 'format_underline',
                    title: 'Strike',
                    action: () =>
                        this.editor.chain().focus().toggleUnderline().run(),
                    isActive: () => this.editor.isActive('underline'),
                },
                {
                    type: 'divider',
                },
                {
                    type: 'v-select',
                },
                // ...[1, 2, 3, 4, 5, 6].map(i => ({
                //   icon: `mdi-format-header-${i}`,
                //   title: `Heading ${i}`,
                //   action: () => this.editor.chain().focus().toggleHeading({ level: i }).run(),
                //   isActive: () => this.editor.isActive('heading', { level: i }) ,
                // })),
                {
                    type: 'divider',
                },
                {
                    icon: 'format_align_left',
                    title: 'Align left',
                    action: () =>
                        this.editor.chain().focus().setTextAlign('left').run(),
                    isActive: () => this.editor.isActive({ textAlign: 'left' }),
                },
                {
                    icon: 'format_align_center',
                    title: 'Align center',
                    action: () =>
                        this.editor
                            .chain()
                            .focus()
                            .setTextAlign('center')
                            .run(),
                    isActive: () =>
                        this.editor.isActive({ textAlign: 'center' }),
                },
                {
                    icon: 'format_align_right',
                    title: 'Align right',
                    action: () =>
                        this.editor.chain().focus().setTextAlign('right').run(),
                    isActive: () =>
                        this.editor.isActive({ textAlign: 'right' }),
                },
                {
                    icon: 'cleaning_services',
                    title: 'Clear Format',
                    action: () =>
                        this.editor
                            .chain()
                            .focus()
                            .clearNodes()
                            .unsetAllMarks()
                            .run(),
                },
                // {
                //     type: 'divider',
                // },
                // {
                //     type: 'color',
                // },
                {
                    type: 'divider',
                },
                {
                    icon: 'undo',
                    title: 'Undo',
                    action: () => this.editor.chain().focus().undo().run(),
                },
                {
                    icon: 'redo',
                    title: 'Redo',
                    action: () => this.editor.chain().focus().redo().run(),
                },
            ],
        }
    },
}
</script>

<style lang="scss">
.divider {
    width: 2px;
    height: 1.25rem;
    background-color: rgba(#000, 0.1);
    margin-left: 0.5rem;
    margin-right: 0.75rem;
}
.semi-rounded {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
</style>

<i18n>{
  "en": {
    "sizes": [
      "Extra large",
      "Large",
      "Medium",
      "Small",
      "Extra small"
    ]
  },
  "fr": {
    "sizes": [
      "Très grand",
      "Grand",
      "Moyen",
      "Petit",
      "Très petit"
    ]
  },
  "es": {
    "sizes": [
      "Muy grande",
      "Grande",
      "Mediano",
      "Pequeño",
      "Muy pequeño"
    ]
  }
}</i18n>
