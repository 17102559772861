import axios from 'axios'

export default {
  contactSales: async function (message: string) {
    await axios({
      url: `/v1/mail/contact-sales`,
      method: 'post',
      data: { message }
    })
  },
  suggest: async function (message: string) {
    await axios({
      url: `/v1/mail/suggestions`,
      method: 'post',
      data: { message }
    })
  },
  beta: async function () {
    await axios({
      url: `/v1/mail/beta`,
      method: 'post'
    })
  }
}
