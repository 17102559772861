import store from '../store'
import { Route, NavigationGuardNext } from 'vue-router'

export default (_to: Route, _redirect: Route, next: NavigationGuardNext) => {
  if (store.getters['cognito/isLoggedIn']) {
    next('/')
  } else {
    next()
  }
}
