import { nanoid } from "@/ponychart/utils/nanoId";
import { DataType } from "@/ponychart/types";
import { Dimension, DimensionCalculation, DimensionCalculationValue, DimensionOperator } from "./types";

interface OperatorOption {
    text?: boolean
    field?: boolean
    number?: boolean
    default: 'text' | 'field' | 'number'
    label: string
    value?: number | string
}

export class DimensionHelper {
    static OPERATORS: DimensionOperator[] = Object.keys(DimensionOperator).map(k => DimensionOperator[k])
    static operationMap: { [o in DimensionOperator]: { types: DataType[], operators?: OperatorOption[] } } = {
        [DimensionOperator.REPLACE]: {
            types: [DataType.TEXT],
            operators: [
                {
                    text: true,
                    field: true,
                    default: 'text',
                    label: 'target',
                },
                {
                    text: true,
                    field: true,
                    default: 'text',
                    label: 'replacement',
                },
            ],
        },
        [DimensionOperator.SPLIT]: {
            types: [DataType.TEXT],
            operators: [
                {
                    text: true,
                    field: true,
                    default: 'text',
                    label: 'split',
                },
                {
                    number: true,
                    default: 'number',
                    value: 1,
                    label: 'keep',
                },
            ],
        },
        [DimensionOperator.CONCAT]: {
            types: [DataType.TEXT],
            operators: [
                {
                    text: true,
                    field: true,
                    default: 'text',
                    label: 'concat',
                },
            ],
        },
        [DimensionOperator.YEAR]: {
            types: [DataType.DATE, DataType.DATETIME],
        },
        [DimensionOperator.QUARTER]: {
            types: [DataType.DATE, DataType.DATETIME],
        },
        [DimensionOperator.MONTH]: {
            types: [DataType.DATE, DataType.DATETIME],
        },
        [DimensionOperator.WEEK]: {
            types: [DataType.DATE, DataType.DATETIME],
        },
        [DimensionOperator.DAY_OF_WEEK]: {
            types: [DataType.DATE, DataType.DATETIME],
        },
        [DimensionOperator.DAY_OF_MONTH]: {
            types: [DataType.DATE, DataType.DATETIME],
        },
        [DimensionOperator.YEAR_MONTH]: {
            types: [DataType.DATE, DataType.DATETIME],
        },
        [DimensionOperator.YEAR_MONTH_DAY]: {
            types: [DataType.DATE, DataType.DATETIME],
        },
    }

    static new(twbIdx: number): Dimension {
        return {
            id: '',
            columnId: '',
            calculations: [],
            errors: [],
            alias: '',
            type: DataType.INTEGER,
            pristine: true,
            twbIdx
        }
    }

    static dimensionCalculationDisabled(dimensionCalculation: DimensionCalculation): boolean {
        return dimensionCalculation.values.reduce((acc: boolean, value: DimensionCalculationValue) => (
            acc || (!value.value && value.value !== 0)
        ), false)
    }

    static dimensionDisabled(dimension: Dimension) {
        return !dimension.columnId || dimension.calculations.reduce((acc: boolean, val: DimensionCalculation) =>
            (acc || DimensionHelper.dimensionCalculationDisabled(val))
            , false)
    }

    static allowedTypesFromOperators(operator: DimensionOperator): DataType[] {
        return DimensionHelper.operationMap[operator].types
    }

    static operationsFromOperators(operator: DimensionOperator): OperatorOption[] {
        return DimensionHelper.operationMap[operator].operators || []
    }
}