import { TraitSearch } from '@/ponychart/state/traits'
import { LabelId } from '@/ponychart/label/types'
import { CustomTrait } from '@/ponychart/trait/model'
import {
    ChartSubtypeTrait,
    ColorTrait,
    DateAggregationTrait,
    DimensionTrait,
    GeoTrait,
    LabelSizeTrait,
    LabelTrait,
    MeasureTrait,
    TooltipTrait,
} from '@/ponychart/trait/traits'
import {
    BandMode,
    ColorLegend,
    GeoMode,
    OverBarMode,
    WithLabel,
} from '@/ponychart/trait/types'
import { SourceMemory } from '@/ponychart/memoize'
import { t } from '@/ponychart/i18n/translate'
import { PageStructure } from '@/ponychart/page/types'
import { structureChartCount } from '@/ponychart/structure/config'
import { mainTag } from '@/ponychart/trait'

import {
    ChartType,
    CHART_TYPE_TRAITS,
    DeviceType,
    QuerySelectorTag,
    TraitId,
} from 'ponychart'

const memoryInstance = SourceMemory.getInstance()

const BAR_LABEL_IDS = [
    LabelId.LABEL_THIS_VALUE,
    LabelId.LABEL_THIS_VALUE_DIFF_COLORED,
    LabelId.LABEL_THIS_VALUE_EVO_COLORED,
    LabelId.LABEL_THIS_VALUE_EVO_DIFF_COLORED,
    LabelId.LABEL_DIFF_COLORED,
    LabelId.LABEL_EVO_COLORED,
    LabelId.LABEL_EVO_DIFF_COLORED,
    LabelId.LABEL_THIS_VALUE_DIFF,
    LabelId.LABEL_THIS_VALUE_EVO,
    LabelId.LABEL_THIS_VALUE_EVO_DIFF,
    LabelId.LABEL_DIFF,
    LabelId.LABEL_EVO,
    LabelId.LABEL_EVO_DIFF,
] as const
const DATE_LABEL_IDS = [LabelId.NONE, LabelId.LABEL_VALUE, LabelId.DATE_VALUE]
const PIE_LABEL_IDS = [
    LabelId.LABEL_THIS_VALUE,
    LabelId.LABEL_DIFF_COLORED,
    LabelId.LABEL_EVO_COLORED,
    LabelId.LABEL_DIFF,
    LabelId.LABEL_EVO,
]

const STANDARD_LABEL_IDS = [
    LabelId.STANDARD_TOOLTIP_COLORED,
    LabelId.STANDARD_TOOLTIP,
] as const

export class ChartTraitFactory {
    constructor(private traitSearch: TraitSearch) {}

    listTraits(
        chartType: ChartType,
        opts: { querySelectorTags: QuerySelectorTag[]; twbIdx: number }
    ): CustomTrait[] {
        const deviceType = DeviceType.DESKTOP
        const querySelectorTag = mainTag(opts.querySelectorTags)
        const defaultOptions = {
            ...opts,
            deviceType,
            chartType,
            querySelectorTag,
        }
        switch (chartType) {
            case ChartType.AREA:
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(
                        defaultOptions,
                        { allowNone: true },
                        this.traitSearch
                    ),
                    new ChartSubtypeTrait(defaultOptions, {
                        itemIds: [BandMode.NONE, BandMode.BAND],
                    }),
                    new DateAggregationTrait(
                        defaultOptions,
                        {},
                        this.traitSearch
                    ),
                ]
            case ChartType.DONUT:
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(defaultOptions, {}, this.traitSearch),
                    new ColorTrait(defaultOptions, {
                        itemIds: [ColorLegend.DIMENSION, ColorLegend.DELTA],
                    }),
                ]
            case ChartType.PIE:
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(defaultOptions, {}, this.traitSearch),
                    new ColorTrait(defaultOptions, {
                        itemIds: [ColorLegend.DIMENSION, ColorLegend.DELTA],
                    }),
                ]
            case ChartType.TIME_PERIOD_INDICATION:
                return [
                    // new TextInputTrait(TraitId.COLOR, {
                    //     ...optsPayload,
                    //     value: '',
                    // }),
                ]
            case ChartType.NONE:
                return [new MeasureTrait(defaultOptions, {}, this.traitSearch)]
            case ChartType.KPI:
                return [new MeasureTrait(defaultOptions, {}, this.traitSearch)]
            case ChartType.LINE:
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(
                        defaultOptions,
                        { allowNone: true },
                        this.traitSearch
                    ),
                    new ChartSubtypeTrait(defaultOptions, {
                        itemIds: [BandMode.NONE, BandMode.BAND],
                    }),
                    new DateAggregationTrait(
                        defaultOptions,
                        {},
                        this.traitSearch
                    ),
                ]
            case ChartType.LOLLIPOP:
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(
                        defaultOptions,
                        { allowNone: false },
                        this.traitSearch
                    ),
                    new ColorTrait(defaultOptions),
                ]
            case ChartType.BAR:
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(
                        defaultOptions,
                        { allowNone: false },
                        this.traitSearch
                    ),
                    new ChartSubtypeTrait(defaultOptions, {
                        itemIds: [OverBarMode.NONE, OverBarMode.OVER_BAR],
                    }),
                    new ColorTrait(defaultOptions),
                ]
            case ChartType.MAP: {
                const availableGeoModes = memoryInstance.getAvailableGeoModes(
                    opts.twbIdx
                )
                const countryColumns = memoryInstance.getCountryColumns(
                    opts.twbIdx
                )
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(
                        defaultOptions,
                        { allowNone: true },
                        this.traitSearch
                    ),
                    new ChartSubtypeTrait(
                        {
                            ...defaultOptions,
                            deviceType: DeviceType.DESKTOP,
                            label: t('geo_level.label'),
                            value: availableGeoModes[0], // Basically it should always have at least one chart subtype value
                        },
                        {
                            itemIds: availableGeoModes,
                        }
                    ),
                    new GeoTrait(
                        TraitId.GEO_COUNTRY,
                        {
                            ...defaultOptions,
                            value: countryColumns?.[0]?.id,
                        },
                        {},
                        this.traitSearch
                    ),
                ]
            }

            case ChartType.YOY_TIME_BAR:
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(
                        defaultOptions,
                        { allowNone: true },
                        this.traitSearch
                    ),
                    // new ChartSubtypeTrait(this.traitSearch, {
                    //     ...optsPayload,
                    //     querySelectorTags,
                    //     label: t("chooseYoYDetail"),
                    //     labels: [YoYBarMode.NONE, YoYBarMode.PERIOD_COLOR]
                    // }),
                ]
            case ChartType.TIME_BAR:
                return [
                    new MeasureTrait(defaultOptions, {}, this.traitSearch),
                    new DimensionTrait(
                        defaultOptions,
                        { allowNone: true },
                        this.traitSearch
                    ),
                    new ChartSubtypeTrait(defaultOptions, {
                        itemIds: [WithLabel.NONE, WithLabel.WITH_LABEL],
                    }),
                ]
            default:
                throw new Error(
                    `Invalid value for chartType: ${chartType} not implemented`
                )
        }
    }

    listPostTraits(
        chartType: ChartType,
        opts: { querySelectorTags: QuerySelectorTag[]; twbIdx: number }
    ): CustomTrait[] {
        const querySelectorTags = opts.querySelectorTags
        const deviceType = DeviceType.DESKTOP
        const querySelectorTag = mainTag(querySelectorTags)
        const defaultOptions = {
            ...opts,
            deviceType,
            chartType,
            querySelectorTag,
        }
        switch (chartType) {
            case ChartType.MAP: {
                const regionColumns = memoryInstance.getRegionColumns(
                    opts.twbIdx
                )
                const cityColumns = memoryInstance.getCityColumns(opts.twbIdx)
                const output = []
                const geoMode =
                    this.traitSearch.getTraitStringValue(
                        TraitId.CHART_SUBTYPE,
                        defaultOptions
                    ) || GeoMode.COUNTRY
                if (regionColumns.length > 0 && geoMode !== GeoMode.COUNTRY) {
                    output.push(
                        new GeoTrait(
                            TraitId.GEO_REGION,
                            {
                                ...defaultOptions,
                                value: regionColumns[0].id,
                            },
                            {},
                            this.traitSearch
                        )
                    )
                }
                if (cityColumns.length > 0 && geoMode === GeoMode.CITY) {
                    output.push(
                        new GeoTrait(
                            TraitId.GEO_CITY,
                            {
                                ...defaultOptions,
                                value: cityColumns[0].id,
                            },
                            {},
                            this.traitSearch
                        )
                    )
                }

                if (
                    !this.traitSearch.getTraitValue(
                        TraitId.DIMENSION,
                        defaultOptions
                    )
                ) {
                    output.push(
                        new ColorTrait(defaultOptions, {
                            itemIds: [
                                ColorLegend.VOLUME,
                                ColorLegend.DELTA,
                                ColorLegend.FIXED,
                            ],
                        })
                    )
                }
                output.push(
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [
                                LabelId.MAP_TOOLTIP_COLORED,
                                LabelId.MAP_TOOLTIP,
                            ],
                        },
                        this.traitSearch
                    ),
                    new LabelTrait(
                        defaultOptions,
                        { itemIds: [...PIE_LABEL_IDS] },
                        this.traitSearch
                    )
                )
                return output
            }
            case ChartType.TIME_BAR:
                return [
                    // TODO: dependency from ChartSubtypeTrait
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: DATE_LABEL_IDS,
                        },
                        this.traitSearch
                    ),
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [LabelId.DATE_TOOLTIP],
                        },
                        this.traitSearch
                    ),
                ]
            case ChartType.YOY_TIME_BAR:
                return [
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: DATE_LABEL_IDS,
                        },
                        this.traitSearch
                    ),
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [LabelId.DATE_TOOLTIP],
                        },
                        this.traitSearch
                    ),
                ]
            case ChartType.BAR:
                return [
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: [...BAR_LABEL_IDS],
                        },
                        this.traitSearch
                    ),
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [...STANDARD_LABEL_IDS],
                        },
                        this.traitSearch
                    ),
                ]
            case ChartType.LOLLIPOP:
                return [
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: [...BAR_LABEL_IDS],
                        },
                        this.traitSearch
                    ),
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [...STANDARD_LABEL_IDS],
                        },
                        this.traitSearch
                    ),
                ]
            case ChartType.LINE:
                return [
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [LabelId.DATE_TOOLTIP],
                        },
                        this.traitSearch
                    ),
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: DATE_LABEL_IDS,
                        },
                        this.traitSearch
                    ),
                ]
            case ChartType.AREA:
                return [
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [LabelId.DATE_TOOLTIP],
                        },
                        this.traitSearch
                    ),
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: DATE_LABEL_IDS,
                        },
                        this.traitSearch
                    ),
                ]
            case ChartType.KPI:
                return [
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: [
                                LabelId.STANDARD_KPI_COLORED,
                                LabelId.RICH_KPI_COLORED,
                                LabelId.SIMPLE_KPI_COLORED,
                                LabelId.STANDARD_KPI,
                                LabelId.RICH_KPI,
                                LabelId.SIMPLE_KPI,
                            ],
                        },
                        this.traitSearch
                    ),
                    new LabelSizeTrait(defaultOptions, {}),
                ]
            case ChartType.PIE:
                return [
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: [...PIE_LABEL_IDS],
                        },
                        this.traitSearch
                    ),
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [...STANDARD_LABEL_IDS],
                        },
                        this.traitSearch
                    ),
                ]
            case ChartType.DONUT:
                return [
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: [...PIE_LABEL_IDS],
                        },
                        this.traitSearch
                    ),
                    new TooltipTrait(
                        defaultOptions,
                        {
                            itemIds: [...STANDARD_LABEL_IDS],
                        },
                        this.traitSearch
                    ),
                ]
            case ChartType.TIME_PERIOD_INDICATION:
                return [
                    new LabelTrait(
                        defaultOptions,
                        {
                            itemIds: [LabelId.TIME_PERIOD_INDICATION],
                        },
                        this.traitSearch
                    ),
                ]

            default:
                return []
        }
    }
}

/**
 * Lists traits for charts found on the page
 * @param traitSearch
 */
export function listChartRelatedTraits(
    structure: PageStructure,
    traitSearch: TraitSearch
) {
    const chartCount = structureChartCount(structure)
    for (const i in CHART_TYPE_TRAITS) {
        const tag = (Number(i) + 1) as QuerySelectorTag
        if (tag > chartCount) continue
        const traitId = CHART_TYPE_TRAITS[i]
        const querySelectorTags: QuerySelectorTag[] = [tag]

        const chartTypes = traitSearch
            .getTraitStringValue(traitId, {
                querySelectorTag: tag,
                deviceType: DeviceType.DESKTOP,
            })
            ?.split(';')
            .filter((v) => !!v) as ChartType[] | undefined

        if (
            !chartTypes?.length ||
            (chartTypes.length === 1 && chartTypes[0] === ChartType.NONE)
        )
            continue

        const chartTraitFactory = new ChartTraitFactory(traitSearch)

        for (const chartType of chartTypes) {
            const chartTraits = chartTraitFactory.listTraits(chartType, {
                querySelectorTags,
                twbIdx: traitSearch.twbIdx,
            })

            traitSearch.pushTraits(chartTraits, {
                attributesToUpdate: ['!value'],
            })

            const postChartTraits = chartTraitFactory.listPostTraits(
                chartType,
                { querySelectorTags, twbIdx: traitSearch.twbIdx }
            )

            traitSearch.pushTraits(postChartTraits, {
                attributesToUpdate: ['!value'],
            })
        }
    }
}
