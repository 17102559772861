import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#673ab7',
        secondary: '#9c27b0',
        accent: '#2196f3',
        error: '#f44336',
        warning: '#ffc107',
        info: '#00bcd4',
        success: '#8bc34a'
        // success: '#673ab7'
      }
    }
  }
})
