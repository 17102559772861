
type SortEvent = { newIndex: number, oldIndex: number }

export class RegisterMemory {
    private static instance: RegisterMemory;
    private pagesContext: { [k: string]: any } = {}
    private constructor() {
        this.pagesContext = {}
    }


    // NOT USED
    public applySort(sortEvent: SortEvent) {
        const { newIndex, oldIndex } = sortEvent;
        [this.pagesContext[String(newIndex)], this.pagesContext[String(oldIndex)]] = [this.pagesContext[String(oldIndex)], this.pagesContext[String(newIndex)]]
    }

    public registerPageContext(pageIndex: number, context: any) {
        this.pagesContext[String(pageIndex)] = context
    }

    public getPageContext(pageIndex: number): any {
        return this.pagesContext[String(pageIndex)]
    }

    public unregisterPageContext(pageIndex: number) {
        delete this.pagesContext[String(pageIndex)]
    }

    public static getInstance(): RegisterMemory {
        if (!RegisterMemory.instance) RegisterMemory.instance = new RegisterMemory();
        return RegisterMemory.instance
    }

}