// import { dictToStyle } from '@/ponychart/utils/functions'
import { TraitOptionItem, QuerySelectorTag } from 'ponychart'
import { ColumnType } from "@/ponychart/trait/types"
import { mainTag } from '@/ponychart/trait/utils'
import {
    // CLS_COL,
    // CLS_DA,
    CLS_FLEX,
    // CLS_ROW,
    CLS_INLINE,
    TYPE_MAP
} from './config'
import { ClassType, FlexType } from './types'

// const memoize = require('fast-memoize')

const baseStyles = Object.values(TYPE_MAP)

// export class ClassStyle {
//     className: string
//     style: { [k: string]: string }
//     str: string
//     constructor(className: string, style: { [k: string]: string }) {
//         this.style = style
//         this.className = className
//         this.str = dictToStyle(this.className, this.style)
//     }
// }


// export const CLASS_TO_STYLES: {
//     [k: string]: { cs: ClassStyle; allowSized: boolean }[]
// } = CLASSES.reduce(
//     (acc, { selector, classNames, style, allowSized }) => ({
//         ...acc,
//         [className]: !acc[className]
//             ? [{ cs: new ClassStyle(className, style), allowSized }]
//             : [
//                 ...acc[className],
//                 { cs: new ClassStyle(className, style), allowSized }
//             ]
//     }),
//     {}
// )

// export function stylesFromClasses(classes: string[], sized?: boolean) {
//     const output = []
//     const already = new Set()
//     for (const c of classes) {
//         if (CLASS_TO_STYLES[c]) {
//             for (const { cs, allowSized } of CLASS_TO_STYLES[c]) {
//                 if ((allowSized || !sized) && !already.has(cs.className)) {
//                     output.push(cs)
//                     already.add(cs.className)
//                 }
//             }
//         }
//     }
//     return output
// }

// export const memoizedStylesFromClasses = memoize(stylesFromClasses)

// export function stylesStringFromClasses(classes: string[], sized?: boolean) {
//     return memoizedStylesFromClasses(classes, sized).map((c: any) => c.str)
// }

// export function styleDouble(sized: boolean) {
//     return stylesStringFromClasses(
//         [CLS_ROW, CLS_COL, CLS_DA, CLS_FLEX, CLS_INLINE],
//         sized
//     )
// }

// export function rowStyle(sized: boolean) {
//     return stylesStringFromClasses(
//         [CLS_ROW, CLS_DA, CLS_FLEX, CLS_INLINE],
//         sized
//     )
// }

// export function colStyle(sized: boolean) {
//     return stylesStringFromClasses(
//         [CLS_COL, CLS_DA, CLS_FLEX, CLS_INLINE],
//         sized
//     )
// }


export function defaultClasses(classes: string[], isFlex: boolean) {
    if (isFlex) {
        if (!classes.includes(CLS_FLEX)) classes.push(CLS_FLEX)
        return classes.filter(c => c != CLS_INLINE)
    } else {
        if (!classes.includes(CLS_INLINE)) classes.push(CLS_INLINE)
        return classes.filter(c => c != CLS_FLEX)
    }
}


export function attrUpdated(
    components: any,
    flex: FlexType,
    type: 'column' | 'row'
) {
    const otherFlex = flex === FlexType.INLINE ? FlexType.FLEX : FlexType.INLINE

    const newUnstylable =
        flex === FlexType.FLEX ? ['height', 'width'] : [TYPE_MAP[type]]

    const newStyle = newUnstylable.reduce(
        (acc: any, style: string) => ({ ...acc, [style]: 'auto' }),
        {}
    )

    components.each((model: any) => {
        model.addClass(flex)
        model.removeClass(otherFlex)
        const unstylable = (model.get('unstylable') || [])
            .filter((s: string) => !!s)
            .filter((s: string) => !baseStyles.includes(s))
        model.set({
            unstylable: Array.from(new Set([...unstylable, ...newUnstylable]))
        })
        const style = model.getStyle() || {}
        model.setStyle({ ...style, ...newStyle })
    })
}

export function selectorFromClass(cls: string): ClassType {
    return { name: cls, private: 1 }
}

export function cleanColumn(value: string | number, values: TraitOptionItem[]): string {
    if (value === ColumnType.DYNAMIC) {
        return values.map((v) => String(v.id)).join(';')
    } else if ([ColumnType.SINGLE, '', undefined].includes(value as any)) {
        return ''
    } else {
        return String(value)
    }
}

export function querySelectorClass(
    id: string,
    querySelectorTags: QuerySelectorTag[]
): string {
    const tag = mainTag(querySelectorTags)
    return `x-${id}-${tag}`
}


