import { Bool, ColumnType } from "@/ponychart/trait";
import { PageMode, PageStructure, UNIQUE_PAGE_STRUCTURE } from "@/ponychart/page/types";
import { TraitSearch } from "@/ponychart/state/traits";
import { DeviceType, QuerySelectorTag, Trait, TraitId } from "ponychart"

import { cardTitleTraits, forAllDeviceTypes, chartTypeTraits, alignVerticallyTrait, includeCardTitleTraits } from "./utils";
import { defaultCardHeight, structureChartCount } from "./config";



export class PageStructureTraitsFactory {

    private initMapStep(): Trait[] {
        return [
            ...chartTypeTraits(this.traitSearch, this.chartCount as QuerySelectorTag),
            ...forAllDeviceTypes({
                hidden: false,
                id: TraitId.CARD_HEIGHT,
                value: defaultCardHeight(this.pageStructure),
                querySelectorTags: [0],
                twbIdx: this.traitSearch.twbIdx
            }),
        ]
    }

    private cardTitleSteps(): Trait[] {
        const cardTitleCount = this.isUniqueStructure ? this.chartCount : 0
        return cardTitleTraits(this.traitSearch, cardTitleCount as QuerySelectorTag)
    }

    private pageModeStep(): Trait[] {
        if (this.pageStructure !== PageStructure.SIMPLE) return []
        return [
            {
                hidden: true,
                id: TraitId.PAGE_MODE,
                deviceType: DeviceType.DESKTOP,
                value: PageMode.MEASURE,
                querySelectorTags: [0],
                twbIdx: this.traitSearch.twbIdx
            }
        ]
    }

    private alignVerticallyStep(): Trait[] {
        // If structure is unique (not repetitive), and there is more than one chart on it, then
        // then we allow the user to check a checkbox about wether he/she wants to align in columns
        // the structure for TABLET & MOBILE deviceTypes
        if (!this.isUniqueStructure || this.chartCount === 1 || this.pageStructure.includes("col")) return []
        return alignVerticallyTrait(this.traitSearch)
    }

    private includeCardTitleStep(): Trait[] {
        const cardTitleCount = this.isUniqueStructure ? this.chartCount : 0
        return includeCardTitleTraits(this.traitSearch, cardTitleCount as QuerySelectorTag)
    }

    private repetitiveMeasureStep(): Trait[] {
        if (this.isUniqueStructure || this.pageStructure === PageStructure.SIMPLE) return []
        return [
            {
                hidden: false,
                id: TraitId.MEASURE,
                value: ColumnType.DYNAMIC,
                querySelectorTags: [0],
                deviceType: DeviceType.DESKTOP,
                twbIdx: this.traitSearch.twbIdx
            }
        ]
    }

    private simplePageStructureMeasureOrDimensionStep(): Trait[] {
        if (this.pageStructure !== PageStructure.SIMPLE) return []
        const traitId = this.repetitiveDimensionMode ? TraitId.DIMENSION : TraitId.MEASURE
        return [
            {
                hidden: false,
                id: traitId,
                value: ColumnType.DYNAMIC,
                querySelectorTags: [0],
                deviceType: DeviceType.DESKTOP,
                twbIdx: this.traitSearch.twbIdx
            }
        ]
    }

    private columnCountStep(): Trait[] {
        if (this.isUniqueStructure) return []
        const value = this.traitSearch.getTraitNumberValue(TraitId.COLUMN_COUNT, 0)
        return [
            ...forAllDeviceTypes({
                id: TraitId.COLUMN_COUNT,
                value: value === undefined ? 3 : value,
                // value: guessColumnCount(
                //     this.state,
                //     defaultColumnCount(this.pageStructure),
                //     this.repetitiveDimensionMode ? TraitId.DIMENSION : TraitId.MEASURE
                // ),
                twbIdx: this.traitSearch.twbIdx,
                hidden: false,
                querySelectorTags: [0],
            }),
        ]
    }

    private measureColorStep(): Trait[] {
        if (
            this.isUniqueStructure ||
            (
                this.pageStructure === PageStructure.SIMPLE &&
                this.traitSearch.getTraitStringValue(TraitId.PAGE_MODE, 0) === PageMode.DIMENSION
            )
        ) return []
        return [
            {
                id: TraitId.MEASURE_COLOR,
                value: "",
                querySelectorTags: [0] as QuerySelectorTag[],
                hidden: true,
                deviceType: DeviceType.DESKTOP,
                twbIdx: this.traitSearch.twbIdx
            }
        ]
    }

    constructor(private pageStructure: PageStructure, private traitSearch: TraitSearch) { }

    get chartCount() {
        return structureChartCount(this.pageStructure)
    }
    // TODO: yield?
    get isUniqueStructure() {
        return UNIQUE_PAGE_STRUCTURE.includes(this.pageStructure)
    }


    get repetitiveDimensionMode(): boolean {
        if (this.pageStructure !== PageStructure.SIMPLE) return false
        return this.traitSearch.getTraitValue(TraitId.PAGE_MODE, 0) === PageMode.DIMENSION
    }


    preListTraits() {
        for (
            const method of [
                this.initMapStep,
                this.pageModeStep,
                this.includeCardTitleStep,
                this.alignVerticallyStep,
                this.repetitiveMeasureStep,
                this.simplePageStructureMeasureOrDimensionStep,
                this.measureColorStep,
                this.columnCountStep,
            ]
        ) {
            const traits = method.bind(this)() as Trait[]
            this.traitSearch.pushTraits(
                traits,
                { attributesToUpdate: ["!value"] }
            )
        }
    }

    private setValueHook(traits: Trait[]) {
        // fires setValue hook
        for (const trait of traits) {
            const existingTrait = this.traitSearch.getTrait(trait.id, trait)
            if (!existingTrait) continue
            existingTrait.setValue(existingTrait.value)
        }
    }

    postListTraits() {
        for (
            const method of [
                this.cardTitleSteps,
            ]
        ) {
            const traits = method.bind(this)() as Trait[]
            this.traitSearch.pushTraits(
                traits,
                { attributesToUpdate: ["!value"] }
            )
            this.setValueHook(traits)
        }
    }
}