import { Position } from "@/ponychart/charts/types"
import { ArrayBuilderOpts, buildNumericArray } from "./arrays"

export interface SvgPosition {
    x: number
    y: number
    value: number
    height: number
    increment: number
}

export interface SvgArrayBuilderOpts {
    percentOfScreen?: number
    ratio?: number // Only for barchart / Lollipop chart (coupling?)
    height?: number
    width?: number
    start?: Position
    maxAxisValue?: number
}
export function svgArrayFromNumeric(numericArray: number[], opts: SvgArrayBuilderOpts = {}) {
    const height = opts?.height || 100
    const width = opts?.width || 100
    const ratio = opts?.ratio || 0.3
    const percentOfScreen = opts?.percentOfScreen || 0.9
    const start = opts?.start || undefined
    const maxAxisValue = opts?.maxAxisValue || undefined


    const sortedValues = [...numericArray]
        .sort((a, b) => b - a)


    const internalIncrement = (width / sortedValues.length) * percentOfScreen


    const internalHeight =
        ((width * percentOfScreen) / sortedValues.length) *
        ratio

    const internalStart = start || { x: height / 20, y: width / 20 }
    const x = internalStart.x
    let y = internalStart.y
    const output = []
    for (const val of numericArray) {
        const value =
            (height * percentOfScreen * val) /
            (maxAxisValue || sortedValues[0])
        output.push({
            value,
            x,
            y,
            height: internalHeight,
            increment: internalIncrement
        })
        y += internalIncrement
    }
    return output
}


export function svgMatrixFromNumeric(numericMatrix: number[][], opts: SvgArrayBuilderOpts = {}): SvgPosition[][] {
    return numericMatrix.map((numericArray) => svgArrayFromNumeric(numericArray, opts))
}


export function getArray(opts: ArrayBuilderOpts & SvgArrayBuilderOpts = {}) {
    const numberArray = buildNumericArray(opts)
    return svgArrayFromNumeric(numberArray, opts)
}