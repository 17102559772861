import { ColorLegend } from '@/ponychart/trait/types'
import { TABLEAU_COLORS } from './config'
import { shuffle } from './functions'
import { memoize } from 'lodash'

const WHITE = '#FFF'

export function findMostDistantColor(
    color: string,
    colorOptions: string[]
): string {
    let maxColor = colorOptions[0]
    let delta = 0
    for (const i in colorOptions) {
        const colorOption = colorOptions[i]
        const deltaColor = deltaColors(color, colorOption)
        if (deltaColor > delta) {
            maxColor = colorOption
            delta = deltaColor
        }
    }
    return maxColor
}

function deltaColors(rgbAHex: string, rgbBHex: string) {
    const rgbA = hexToRgb(rgbAHex)
    const rgbB = hexToRgb(rgbBHex)
    const labA = rgb2lab(rgbA)
    const labB = rgb2lab(rgbB)
    const deltaL = labA[0] - labB[0]
    const deltaA = labA[1] - labB[1]
    const deltaB = labA[2] - labB[2]
    const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2])
    const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2])
    const deltaC = c1 - c2
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH)
    const sc = 1.0 + 0.045 * c1
    const sh = 1.0 + 0.015 * c1
    const deltaLKlsl = deltaL / 1.0
    const deltaCkcsc = deltaC / sc
    const deltaHkhsh = deltaH / sh
    const i =
        deltaLKlsl * deltaLKlsl +
        deltaCkcsc * deltaCkcsc +
        deltaHkhsh * deltaHkhsh
    return i < 0 ? 0 : Math.sqrt(i)
}

function rgb2lab(rgb: any) {
    let r = rgb[0] / 255,
        g = rgb[1] / 255,
        b = rgb[2] / 255,
        x,
        y,
        z
    r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92
    g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92
    b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883
    x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116
    return [116 * y - 16, 500 * (x - y), 200 * (y - z)]
}

// function hexToRgb(hex: string) {
//     if (!hex) return [255, 255, 255]
//     hex = hex.length !== 7 ? hex.substring(0, 7) : hex
//     if (hex.indexOf('#') === 0) {
//         hex = hex.slice(1)
//     }
//     // convert 3-digit hex to 6-digits.
//     if (hex.length === 3) {
//         hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
//     }
//     if (hex.length !== 6) {
//         throw new Error('Invalid HEX color.')
//     }
//     const r = parseInt(hex.slice(0, 2), 16),
//         g = parseInt(hex.slice(2, 4), 16),
//         b = parseInt(hex.slice(4, 6), 16)
//     return [r, g, b]
// }

export function invertColor(hex: string) {
    const [r, g, b] = hexToRgb(hex)
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000' : '#FFF'
}

export const memoizedInvertColor = memoize(invertColor)

export function invertColorRaw(hex: string) {
    const [r, g, b] = hexToRgb(hex)

    const newR = (255 - r).toString(16)
    const newG = (255 - g).toString(16)
    const newB = (255 - b).toString(16)
    // pad each with zeros and return
    return '#' + padZero(newR, 2) + padZero(newG, 2) + padZero(newB, 2)
}

export function padZero(s: string, len: number) {
    len = len || 2
    const zeros = new Array(len).join('0')
    return (zeros + s).slice(-len)
}

export function rgbToHex(r: number, g: number, b: number) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

export function hexToRgb(hex: string) {
    if (hex.startsWith('rgb(')) {
        const rgb = hex
            .substring(4, hex.length - 1)
            .split(',')
            .map((x) => parseInt(x.trim()))
        return rgb
    }
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? [
              parseInt(result[1], 16),
              parseInt(result[2], 16),
              parseInt(result[3], 16),
          ]
        : [0, 0, 0]
}

const RGB_WHITE = hexToRgb(WHITE)

export function pickHex(color1: number[], color2: number[], weight: number) {
    const p = weight
    const w = p * 2 - 1
    const w1 = (w / 1 + 1) / 2
    const w2 = 1 - w1
    return [
        Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2),
    ]
}

export function continuousColor(ratio: number) {
    if (ratio > 0) {
        const [r, g, b] = pickHex(
            hexToRgb(TABLEAU_COLORS.blue),
            RGB_WHITE,
            ratio
        )
        return rgbToHex(r, g, b)
    } else if (ratio < 0) {
        const [r, g, b] = pickHex(
            hexToRgb(TABLEAU_COLORS.red),
            RGB_WHITE,
            -ratio
        )
        return rgbToHex(r, g, b)
    } else {
        return WHITE
    }
}

export const COLOR_SUGGESTIONS = [
    {
        sidebar_primary: 'DarkVibrant',
        sidebar_secondary: 'DarkMuted',
        background: 'LightVibrant',
        curves: 'Vibrant',
        light_background: 'LightMuted',
        border: 'Muted',
        title: 'Vibrant',
    },
    {
        sidebar_primary: 'LightVibrant',
        sidebar_secondary: 'LightMuted',
        background: 'DarkVibrant',
        curves: 'Muted',
        light_background: 'DarkMuted',
        border: 'Vibrant',
        title: 'Muted',
    },
]

export function dimensionColor(l: number) {
    const colors = shuffle(Object.values(TABLEAU_COLORS))
    const output = []
    for (let i = 0; i < l; i++) {
        output.push(colors[i % colors.length])
    }
    return output
}

export class ColorBuilder {
    constructor(
        private curveColor: string,
        private values: number[],
        private evoValues: number[]
    ) {}
    paint(colorLegend: ColorLegend) {
        const colorMap: { [c in ColorLegend]: () => string[] } = {
            [ColorLegend.FIXED]: this.fixedColor.bind(this),
            [ColorLegend.DIMENSION]: this.dimensionColor.bind(this),
            [ColorLegend.DELTA]: this.deltaColor.bind(this),
            [ColorLegend.VOLUME]: this.volumeColor.bind(this),
        }
        return colorMap[colorLegend]
            ? colorMap[colorLegend]()
            : this.dimensionColor()
    }
    fixedColor() {
        return this.values.map(() => this.curveColor)
    }
    dimensionColor() {
        return dimensionColor(this.values.length)
    }
    deltaColor() {
        return this.continuousColor(this.evoValues)
    }
    volumeColor() {
        return this.continuousColor(this.values)
    }
    private continuousColor(values: number[]) {
        const output = []
        const max = Math.max(
            values.reduce((acc, val) => Math.max(acc, val), -1000),
            -values.reduce((acc, val) => Math.min(acc, val), 1000)
        )
        for (let i = 0; i < this.values.length; i++) {
            output.push(continuousColor(values[i] / max))
        }
        return output
    }
}

export function getColorValues(
    colorLegend: ColorLegend,
    opts: { values: number[]; evoValues: number[]; curveColor: string }
) {
    return new ColorBuilder(opts.curveColor, opts.values, opts.evoValues).paint(
        colorLegend
    )
}

// Interesting but not implemented
export function shadeHexColor(color: string, percent: number) {
    const f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = (f >> 8) & 0x00ff,
        B = f & 0x0000ff
    return (
        '#' +
        (
            0x1000000 +
            (Math.round((t - R) * p) + R) * 0x10000 +
            (Math.round((t - G) * p) + G) * 0x100 +
            (Math.round((t - B) * p) + B)
        )
            .toString(16)
            .slice(1)
    )
}
