
import { TraitId, Trait } from 'ponychart'
import {
    buildHeader,
    divideBy,
    t,
    add,
    GlobalState,
    GlobalOptions,
} from '@/ponychart'
import { GlobalMixins } from '@/mixins'
import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { SimpleTraitSearch, TraitSearch } from '@/ponychart/state/traits'

@Component
export default class HeaderPreviewComponent extends mixins(GlobalMixins) {
    @Prop({
        type: Array,
        required: true,
    })
    readonly sourceTraits!: Trait[]
    @Prop({
        type: Object,
        required: false,
        default: () => ({
            width: '100%',
            border: '2px solid black',
        }),
    })
    readonly containerStyle!: { [k: string]: string }
    @Prop({ type: Object, required: true })
    readonly globalOptions!: GlobalOptions
    @Prop({ type: Boolean, required: false, default: false })
    readonly event!: boolean

    html = ''
    headerHeight = '0'

    get style() {
        const traitSearch = new SimpleTraitSearch(this.sourceTraits)
        const marginTop = (traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X) || 0) / 2
        return {
            ...this.containerStyle,
            background: this.globalOptions.colors.light_background,
            height: `calc(${divideBy(
                add(this.headerHeight, marginTop),
                this.globalOptions.reduceSize || 1
            )} + 6px)`,
        }
    }

    async build() {
        await this.$nextTick()
        const globalState = new GlobalState({
            ...this.globalOptions,
            pageTitle: t('customtitle')
        })
        const traitSearch = TraitSearch.createInstance(this.sourceTraits, [], globalState)
        const header = buildHeader(globalState, traitSearch)
        this.headerHeight = header.localState.rawStyles.height
        const html = header.compileAllHtml()
        const css = header.compileAllCss()
        this.html = `${html}<style>${[...Array.from(css)].join(' ')}</style>`
    }
    @Watch('event')
    onEventChanged() {
        this.build()
    }
}
