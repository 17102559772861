import { Vue, Component } from 'vue-property-decorator'
import { Page as _Page, Format as _Format } from '@/ponychart'
import SourceService from "@/services/sourceService"
import PageService from "@/services/pageService"


@Component
export class Pages extends Vue {
    gettingSource = false;

    async loadSourceAndPage(sourceId: number, pageId: string) {
        this.gettingSource = true
        const promises: Promise<any>[] = [PageService.getPage(sourceId, pageId)]
        if (!(this as any).source.id)
            promises.push(
                SourceService.getSource(sourceId).then((source) =>
                    this.$emit('set', { source })
                )
            )
        const promiseResult = await Promise.all(promises)

        await this.$nextTick()
        this.gettingSource = false
        return promiseResult[0]
    }

    mapReduce(o: any) {
        const stringifyValues = new Set(['styles', 'components'])
        return Object.entries(o).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [`gjs-${key}`]: stringifyValues.has(key)
                    ? JSON.stringify(value)
                    : value,
            }),
            { 'gjs-assets': '[]' }
        )
    }


}
