import { ActionTree } from 'vuex'
import { State } from '../state'
import colors from './colors'
import user from './user'
import subscriptions from './subscriptions'

export const actions: ActionTree<State, State> = {
    ...user,
    ...subscriptions,
    ...colors,
}
