
const DONE_VALUE = 'done'
import { STEP_MAP, TourStepDict, GIF_STEPS } from '@/tour'
import Component, { mixins } from 'vue-class-component'
import { GlobalMixins } from '@/mixins'
import { Watch } from 'vue-property-decorator'
import { STEPS } from '@/config'
import { FILE_EXPIRATION_DAYS, RegisterTour } from '@/ponychart'

const registerTour = RegisterTour.getInstance()

@Component
export default class TourComponent extends mixins(GlobalMixins) {
    step = 'measure'
    steps: TourStepDict[] = []
    show = false
    gifStep = GIF_STEPS[0]
    gifSteps = GIF_STEPS
    internalSteps = STEPS.map((s) => s.split('-')[0])
    internalStepIdx = 0

    get internalStep() {
        return this.internalSteps[this.internalStepIdx]
    }
    set internalStep(value: string) {
        this.internalStepIdx = this.internalSteps.indexOf(value)
    }

    get gifStepIdx(): number {
        return GIF_STEPS.indexOf(this.gifStep)
    }
    set gifStepIdx(value: number) {
        this.gifStep = GIF_STEPS[value]
    }

    get filename(): string {
        return (
            {
                en: 'Sample - Superstore',
                fr: 'Exemple - Supermarché',
                es: 'Muestra - Supertienda',
            }[this.lang] || 'Sample - Superstore'
        )
    }

    get link() {
        return `https://ponychart.s3.eu-west-3.amazonaws.com/demo-dashboards/prod/twb/${this.filename}.twbx`
    }

    next() {
        this.gifStepIdx =
            this.gifStepIdx + 1 === GIF_STEPS.length ? 0 : this.gifStepIdx + 1
    }
    prev() {
        this.gifStepIdx =
            this.gifStepIdx - 1 < 0 ? GIF_STEPS.length - 1 : this.gifStepIdx - 1
    }

    get component() {
        return this.step === 'home' ? 'v-dialog' : 'v-tour'
    }

    element(step: string) {
        switch (step) {
            case 'measure':
                return String(this.$t('measure')).toLowerCase()
            case 'dimension':
                return String(this.$t('dimension')).toLowerCase()
            default:
                return ''
        }
    }

    elements(step: string) {
        switch (step) {
            case 'measure':
                return String(this.$t('measures')).toLowerCase()
            case 'dimension':
                return String(this.$t('dimensions')).toLowerCase()
            default:
                return ''
        }
    }

    get callbacks() {
        return {
            // onStart: this.onStart,
            // onPreviousStep: this.onPreviousStep,
            // onNextStep: this.onNextStep,
            onFinish: this.onFinish,
            onSkip: this.onSkip,
        }
    }
    get options() {
        return {
            startTimeout: 100,
            labels: {
                buttonSkip: String(this.$t('skip')).toLowerCase(),
                buttonPrevious: String(this.$t('creation.back')).toLowerCase(),
                buttonNext: String(this.$t('next')),
                buttonStop: String(this.$t('finish')).toLowerCase(),
            },
        }
    }

    get stepTitle() {
        return `${this.$t('step')} ${this.internalStepIdx + 1}/5 : ${this.$t(
            'creation.small_' + this.internalStep
        )}`
    }

    getImgUrl() {
        if (!['upload', 'download', 'open'].includes(this.gifStep)) return
        const images = require.context('../../assets/img/', false, /\.gif$/)
        return images(`./${this.gifStep}-${this.lang}.gif`)
    }

    buildSteps(step: string) {
        const steps = STEP_MAP[step] || []
        if (steps.length === 0) return true
        const element = this.element(step)
        const elements = this.elements(step)
        const possibleSteps = steps
            .map((s) => s.toDict())
            .filter((tourStep: TourStepDict) => {
                const exists = document.querySelector(tourStep.target)
                return exists
            })
            .map((tourStep: TourStepDict) => ({
                ...tourStep,
                content: String(
                    this.$t(tourStep.content, {
                        element,
                        elements,
                        x: FILE_EXPIRATION_DAYS,
                    })
                ),
            }))
        const valid = this.stepsAreLongEnough(
            steps.length,
            possibleSteps.length
        )
        if (valid) this.steps = possibleSteps
        return valid
    }
    stepsAreLongEnough(expectedLength: number, realLength: number) {
        if (this.step.startsWith('header-design')) return realLength > 0
        if (this.step.includes('pages')) return realLength > 0
        return realLength / expectedLength > 0.5 // If more than 50% of the steps have been found in the DOM, then start the tour
    }
    makeStep(i: number) {
        return String(i + 1) + ':&nbsp;'
    }
    onSkip() {
        this.hideTour()
        this.steps = []
    }
    onFinish() {
        this.hideTour()
        this.steps = []
    }
    hideTour() {
        localStorage.setItem('tour-' + this.step, DONE_VALUE)
    }
    clearMemory(step: string) {
        localStorage.removeItem('tour-' + step)
    }
    stepExists(step: string) {
        return !!STEP_MAP[step]
    }
    timeout(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }
    startTour(opts: {
        step: string
        force?: boolean
        attempt?: number
        denyRetry?: boolean
    }): boolean {
        if (!opts.step) return false
        const STEPS = {
            'header-design': [
                'header-design-1',
                'header-design-2',
                'header-design-3',
            ],
            pages: ['first-page', 'pages'],
        }
        if (STEPS[opts.step] && !opts.attempt) {
            for (const step of STEPS[opts.step]) {
                const valid = this.startTour({
                    ...opts,
                    step,
                    attempt: 100,
                    denyRetry: true,
                })
                console.log('valid', valid, step)
                if (valid) return true
            }
            return false
        }
        if (!this.stepExists(opts.step)) return false
        const stepIsDone =
            localStorage.getItem('tour-' + opts.step) === DONE_VALUE
        if (!opts.force && stepIsDone) return false

        const valid = this.buildSteps(opts.step)
        const attempt = opts.attempt || 0
        if (!valid) {
            // If invalid, it may be because DOM has not loaded completely, retrying 10 times...
            if (attempt > 10 || opts.denyRetry) return false
            this.timeout(1000).then(() => {
                this.startTour({ ...opts, attempt: attempt + 1 })
            })
            return false
        } else {
            this.step = opts.step
            if (opts.force) this.clearLinkedSteps()
            this.start()
            return true
        }
    }
    clearLinkedSteps() {
        const steps =
            {
                pages: ['page-selector', 'first-page', 'page-edit-popup'],
                'first-page': ['page-selector', 'pages', 'page-edit-popup'],
                home: ['home-bis'],
                'header-design-1': ['header-design-2', 'header-design-3'],
                'header-design-2': ['header-design-1', 'header-design-3'],
                'header-design-3': ['header-design-2', 'header-design-1'],
            }[this.step] || []
        for (const step of steps) this.clearMemory(step)
    }
    async start() {
        if (this.component === 'v-dialog') {
            this.show = true
        } else {
            console.log('start', this.step, this.steps.length)
            // This nextTick is important so that VTour builds the results from the list of elements
            await this.$nextTick()
            this.$tours['main'].start()
        }
    }
    @Watch('show')
    onShowChange(value: boolean) {
        if (!value) {
            this.hideTour()
            this.startTour({ step: 'home-bis', force: true })
        } else {
            this.gifStep = GIF_STEPS[0]
        }
    }

    mounted() {
        registerTour.registerTourContext(this)
    }
}
