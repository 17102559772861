import { Format } from '@/ponychart/format'
import { Lang, t, LANGS } from '@/ponychart/i18n'
import { Measure } from '@/ponychart/measure'
import {
    TABLEAU_BLUE,
    TABLEAU_RED,
    reduceStyleSize,
    hyphenObjectToDash,
    memoizedInvertColor,
} from '@/ponychart/utils'
import { RandomMemory, SourceMemory } from '@/ponychart/memoize'
import { textMeasurer } from '@/ponychart/utils/canvas'

import { HEADING_SIZES } from './config'
import {
    HeadingSize,
    Alignment,
    TextElementMode,
    TextElementType,
    ALIGNMENTS,
} from './type'
const numfmt = require('numfmt')
import { round } from 'lodash'
import { rotatingTextes } from '../chartContext/utils'
import { TraitId } from 'ponychart'

const memoryInstance = SourceMemory.getInstance()
const randomInstance = RandomMemory.getInstance()

export const TRANSLATED_TEXT_ELEMENTS: { [k: string]: TextElementType } =
    LANGS.map((lang: Lang) =>
        Object.values(TextElementType).reduce(
            (acc: any, textElementType: string) => ({
                ...acc,
                [t('textElement.' + textElementType, {}, lang)]:
                    textElementType,
            }),
            {}
        )
    ).reduce((acc: any, textElements: any) => ({ ...acc, ...textElements }), {})

interface DBLineRow {
    h: HeadingSize
    a: string
    elems: DBTextElement[]
}

export class LineRow {
    public headingSize: HeadingSize
    public alignment: Alignment = 'left'
    constructor(
        public elements: TextElementV2[],
        opts: { headingSize: HeadingSize; alignment?: Alignment } = {
            headingSize: 3,
        }
    ) {
        this.headingSize = opts.headingSize
        this.alignment = opts.alignment || 'left'
    }

    static fromDb(lineRowsPayload: string): LineRow[] {
        try {
            const lineRows = JSON.parse(lineRowsPayload) as DBLineRow[]
            return lineRows.map(
                (db) =>
                    new LineRow(
                        db.elems.map((e) => TextElementV2.fromDb(e)),
                        { headingSize: db.h, alignment: ALIGNMENTS[db.a] }
                    )
            )
        } catch (e) {
            return []
        }
    }

    toDb(): DBLineRow {
        return {
            h: this.headingSize,
            a: this.alignment[0],
            elems: this.elements.map((e) => e.toDb()),
        }
    }

    get height() {
        return HEADING_SIZES[this.headingSize - 1]
    }

    getWidth(context: TextElementContext) {
        return textMeasurer.measureTextWidth(
            this.getHtml(TextElementMode.PREVIEW, context)
        )
    }

    static toHtml(
        lineRows: LineRow[],
        mode: TextElementMode,
        context: TextElementContext
    ) {
        return lineRows
            .map((lineRow) => lineRow.getHtml(mode, context))
            .join('')
    }

    static toPreviewSvg(
        lineRows: LineRow[],
        opts: {
            reduceSize: number
            height: number
            width: number
            isMiddle: boolean
        } & TextElementContext
    ) {
        const rowHeights = lineRows.map((line) => line.height)
        const textHeight = round(
            rowHeights.reduce((a, b) => a + b, 0) / opts.reduceSize,
            2
        )

        let y = round(
            opts.isMiddle
                ? (opts.height - textHeight / opts.reduceSize) / 2
                : 0,
            2
        )
        const texts = []
        for (const row of lineRows) {
            // TODO: row.alignment = "right"
            y += round(row.height / opts.reduceSize, 2)
            const x = round(
                (row.alignment === 'center'
                    ? (opts.width - row.getWidth(opts)) / 2
                    : 0) / opts.reduceSize,
                2
            )
            texts.push(
                row.getPreviewSvg({
                    ...opts,
                    x,
                    y,
                    isCenter: row.alignment === 'center',
                })
            )
        }
        return texts.join('')
    }

    getHtml(mode: TextElementMode, context: TextElementContext) {
        const titleCaseStyles: { [k: string]: string } = {
            fontFamily: memoryInstance.fontFamily,
            fontWeight: 'normal',
            ...(context.styles || {}),
        }
        if (this.alignment !== 'left')
            titleCaseStyles.textAlign = this.alignment
        const styleString = Object.entries(hyphenObjectToDash(titleCaseStyles))
            .map(([k, v]) => `${k}: ${v}`)
            .join('; ')
        return `<h${this.headingSize} style="${styleString}">${this.elements
            .map((e) => e.getHtml(mode, context))
            .join('')}</h${this.headingSize}>`
    }

    getPreviewSvg(
        opts: {
            reduceSize: number
            isCenter: boolean
            x: number
            y: number
        } & TextElementContext
    ) {
        return `<text x="${opts.isCenter ? '50%' : opts.x}" y="${opts.y}"${
            opts.isCenter ? ' text-anchor="middle"' : ''
        } font-family="${memoryInstance.fontFamily}">${this.elements
            .map((e) =>
                e.getPreviewSvg({ ...opts, headingSize: this.headingSize })
            )
            .join('')}</text>`
    }
}
interface TextElementContext {
    dateId?: string
    date?: string
    measureId?: string
    measureIds?: string[]
    measure?: string
    dimensionId?: string
    dimensionIds?: string[]
    dimension?: string
    styles?: { [k: string]: string }
}

interface DBTextElement {
    t: string
    c?: string
    b?: boolean
    i?: boolean
    u?: boolean
    type?: TextElementType
}

export class TextElementV2 {
    public bold: boolean
    public italic: boolean
    public underline: boolean
    public textElementType?: TextElementType
    constructor(
        private text: string,
        opts: {
            bold?: boolean
            italic?: boolean
            isCalculation?: boolean
            underline?: boolean
        } = {}
    ) {
        this.bold = opts.bold || false
        this.underline = opts.underline || false
        this.italic = opts.italic || false
        if (opts.isCalculation)
            this.textElementType = this.text as TextElementType
    }

    static fromDb(db: DBTextElement) {
        return new TextElementV2(db.t, {
            bold: db.b,
            italic: db.i,
            underline: db.u,
            isCalculation: db.type ? true : false,
        })
    }

    toDb(): DBTextElement {
        const output: DBTextElement = {
            t: this.text,
        }
        if (this.bold) output.b = this.bold
        if (this.italic) output.i = this.italic
        if (this.underline) output.u = this.underline
        if (this.textElementType) output.type = this.textElementType
        return output
    }

    static fromHtmlBlock(
        block: HTMLElement,
        opts: {
            isCalculation?: boolean
            underline?: boolean
            bold?: boolean
            italic?: boolean
            color?: string
        } = {}
    ): TextElementV2[] {
        const localOpts = { ...opts }
        const tagName = block.tagName
        if (tagName == 'STRONG') localOpts.bold = true
        if (tagName == 'EM') localOpts.italic = true
        if (tagName == 'U') localOpts.underline = true
        if (block.classList.contains('mention')) localOpts.isCalculation = true
        if (block.style.color) localOpts.color = block.style.color
        const textElements = []
        let child = block.firstChild
        while (child) {
            if (child.nodeType === 3) {
                if (!child.nodeValue) continue
                else {
                    if (localOpts.isCalculation) {
                        const text = child.nodeValue.trim()
                        const l = text.length
                        if (text.length > 2) {
                            const textElementType =
                                TRANSLATED_TEXT_ELEMENTS[
                                    text.substring(1, l - 1)
                                ]
                            if (textElementType)
                                textElements.push(
                                    new TextElementV2(
                                        textElementType,
                                        localOpts
                                    )
                                )
                        } else {
                            console.error('Text element is too short', text)
                        }
                    } else {
                        textElements.push(
                            new TextElementV2(child.nodeValue, localOpts)
                        )
                    }
                }
            } else {
                textElements.push(
                    ...TextElementV2.fromHtmlBlock(
                        child as HTMLElement,
                        localOpts
                    )
                )
            }
            child = child.nextSibling
        }
        return textElements
    }

    private static getMeasure(context: TextElementContext) {
        const measureId = context.measureId
            ? context.measureId.split(';')[0]
            : undefined
        const measure = measureId
            ? memoryInstance.getMeasure(measureId) || memoryInstance.measures[0]
            : memoryInstance.measures[0]
        return measure
    }

    private static getMeasureChoiceAlias(context: TextElementContext): string {
        if (context.measure) return context.measure
        const measureIds = context.measureId
            ? context.measureId.split(';')
            : context.measureIds

        return measureIds
            ? rotatingTextes(
                  [
                      measureIds.map(
                          (id: string) =>
                              memoryInstance.getMeasure(id)?.alias || id
                      ),
                  ],
                  1,
                  { prefix: 't' }
              )
            : memoryInstance.measures[0].alias
    }

    private static getDimensionChoiceAlias(
        context: TextElementContext
    ): string {
        if (context.dimension) return context.dimension
        const dimensionIds = context.dimensionId
            ? context.dimensionId.split(';')
            : context.dimensionIds
        return dimensionIds
            ? rotatingTextes(
                  [
                      dimensionIds.map(
                          (id: string) =>
                              memoryInstance.getDimension(id)?.alias || id
                      ),
                  ],
                  1,
                  { prefix: 't' }
              )
            : memoryInstance.dimensions[0].alias
    }

    private static getMainFormat(measure: Measure) {
        const mainFormat =
            memoryInstance.getFormat(measure.formatId) ||
            memoryInstance.mainFormats[0]
        return (
            mainFormat || {
                mainFormat: '#,##0.00" €";-#,##0.00" €"',
                diffFormat: '▲#,##0.00" €";-▼#,##0.00" €"',
            }
        )
    }

    private static getEvoFormat(mainFormat: Format) {
        const evoFormat = mainFormat.evoFormatId
            ? memoryInstance.getFormat(mainFormat.evoFormatId) ||
              memoryInstance.evoFormats[0]
            : memoryInstance.evoFormats[0]
        return (
            evoFormat || {
                mainFormat: '[blue]▲+#.##%;[red]▼-#.##%',
                diffFormat: '0.0',
            }
        )
    }

    private getRawText(
        mode: TextElementMode,
        context: TextElementContext,
        asSvg = false
    ) {
        return TextElementV2.getTextFromTextElementType(
            this.text,
            mode,
            context,
            this.textElementType,
            asSvg
        )
    }

    static formatFromContext(
        type: TextElementType,
        context: TextElementContext
    ) {
        if (
            [
                TextElementType.MEASURE,
                TextElementType.THIS_MEASURE,
                TextElementType.LAST_MEASURE,
            ].includes(type)
        ) {
            return TextElementV2.getMainFormat(
                TextElementV2.getMeasure(context)
            ).mainFormat
        } else if (
            [
                TextElementType.DIFFERENCE,
                TextElementType.DIFFERENCE_COLORED,
            ].includes(type)
        ) {
            return TextElementV2.getMainFormat(
                TextElementV2.getMeasure(context)
            ).diffFormat
        } else if (
            [
                TextElementType.EVOLUTION,
                TextElementType.EVOLUTION_COLORED,
            ].includes(type)
        ) {
            return TextElementV2.getEvoFormat(
                TextElementV2.getMainFormat(TextElementV2.getMeasure(context))
            ).mainFormat
        } else {
            throw new Error(`type ${type} not supported`)
        }
    }

    static getTextFromTextElementType(
        text: string,
        mode: TextElementMode,
        context: TextElementContext,
        type?: TextElementType,
        asSvg?: boolean
    ) {
        if (type && mode === TextElementMode.CALCULATION)
            return t(`textElement.${text}`)
        else if (
            type &&
            (mode === TextElementMode.PREVIEW ||
                mode === TextElementMode.TOOLTIP_PREVIEW)
        ) {
            const prefix = asSvg ? 't' : ''
            if (
                mode === TextElementMode.TOOLTIP_PREVIEW &&
                [
                    TextElementType.DIFFERENCE,
                    TextElementType.EVOLUTION,
                    TextElementType.THIS_MEASURE,
                    TextElementType.LAST_MEASURE,
                    TextElementType.DIFFERENCE_COLORED,
                    TextElementType.EVOLUTION_COLORED,
                    TextElementType.MEASURE,
                ].includes(type)
            ) {
                return `{{${type}}}{{${TextElementV2.formatFromContext(
                    type,
                    context
                )}}}`
            }
            if (
                type === TextElementType.MEASURE ||
                type === TextElementType.THIS_MEASURE
            ) {
                const measureIds = context.measureId
                    ? context.measureId.split(';')
                    : context.measureIds
                if (!measureIds) return ''
                return rotatingTextes(
                    [
                        measureIds.map((measureId) => {
                            const { thisMeasure } =
                                randomInstance.getMeasure(measureId)
                            const format = TextElementV2.formatFromContext(
                                type,
                                { ...context, measureId }
                            )
                            return numfmt.format(format, thisMeasure)
                        }),
                    ],
                    1,
                    { prefix }
                )
            } else if (type === TextElementType.LAST_MEASURE) {
                const measureIds = context.measureId
                    ? context.measureId.split(';')
                    : context.measureIds
                if (!measureIds) return ''
                return rotatingTextes(
                    [
                        measureIds.map((measureId) => {
                            const { lastMeasure } =
                                randomInstance.getMeasure(measureId)
                            const format = TextElementV2.formatFromContext(
                                type,
                                { ...context, measureId }
                            )
                            return numfmt.format(format, lastMeasure)
                        }),
                    ],
                    1,
                    { prefix }
                )
            } else if (type === TextElementType.DIMENSION) {
                return '....'
            } else if (
                [
                    TextElementType.DIFFERENCE,
                    TextElementType.DIFFERENCE_COLORED,
                    TextElementType.EVOLUTION,
                    TextElementType.EVOLUTION_COLORED,
                ].includes(type)
            ) {
                const measureIds = context.measureId
                    ? context.measureId.split(';')
                    : context.measureIds
                if (!measureIds) return ''
                return rotatingTextes(
                    [
                        measureIds.map((measureId) => {
                            const { thisMeasure, lastMeasure } =
                                randomInstance.getMeasure(measureId)
                            const format = TextElementV2.formatFromContext(
                                type,
                                { ...context, measureId }
                            )
                            const value = type.includes('difference')
                                ? thisMeasure - lastMeasure
                                : thisMeasure / lastMeasure - 1
                            return numfmt.format(format, value)
                        }),
                    ],
                    1,
                    {
                        prefix,
                        colors: !type.endsWith('colored')
                            ? []
                            : [
                                  measureIds.map((measureId) => {
                                      const { thisMeasure, lastMeasure } =
                                          randomInstance.getMeasure(measureId)
                                      const reversed =
                                          memoryInstance.getMeasure(measureId)
                                              ?.reversed || false
                                      const measureGrew =
                                          thisMeasure > lastMeasure
                                      return (measureGrew && reversed) ||
                                          (!measureGrew && !reversed)
                                          ? TABLEAU_RED
                                          : TABLEAU_BLUE
                                  }),
                              ],
                    }
                )
            } else if (type === TextElementType.MEASURE_CHOICE) {
                return TextElementV2.getMeasureChoiceAlias(context)
            } else if (type === TextElementType.DIMENSION_CHOICE) {
                return TextElementV2.getDimensionChoiceAlias(context)
            } else if (type === TextElementType.GEO_CITY) {
                return 'Miami'
            } else if (type === TextElementType.GEO_COUNTRY) {
                return 'United States'
            } else if (type === TextElementType.GEO_REGION) {
                return 'Florida'
            } else if (type === TextElementType.COMPARISON_PERIOD) {
                // TODO: use the real comparison period count value (and not 1)
                // same for month, week, day
                return '1 ' + t(`${TraitId.COMPARISON_PERIODS}.month`)
            } else if (type === TextElementType.LAST_PERIOD) {
                const date = new Date()
                if (date.getMonth() === 1) {
                    date.setFullYear(date.getFullYear() - 1, 12)
                } else {
                    date.setMonth(date.getMonth() - 1)
                }
                return new Intl.DateTimeFormat(memoryInstance.lang, {
                    year: 'numeric',
                    month: 'long',
                }).format(date)
            } else if (
                type === TextElementType.THIS_PERIOD ||
                type === TextElementType.DATE
            ) {
                const date = new Date()
                return new Intl.DateTimeFormat(memoryInstance.lang, {
                    year: 'numeric',
                    month: 'long',
                }).format(date)
            } else if (type === TextElementType.DATE_AGGREGATION_LEVEL_CHOICE) {
                return context.date || t('date.month')
            }
            return '##.#'
        } else return text
    }

    private getRawHtml(mode: TextElementMode, context: TextElementContext) {
        const text = this.getRawText(mode, context)
        if (this.textElementType && mode == TextElementMode.CALCULATION)
            return `<span data-type="mention" class="mention" data-id="${text}">[${text}]</span>`
        return text
    }

    getHtml(mode: TextElementMode, context: TextElementContext) {
        let output = this.getRawHtml(mode, context)
        if (this.bold) output = `<strong>${output}</strong>`
        if (this.italic) output = `<em>${output}</em>`
        if (this.underline) output = `<u>${output}</u>`
        return output
    }

    getPreviewSvg(
        opts: { reduceSize: number; headingSize: number } & TextElementContext
    ) {
        const styles: string[] = []
        if (this.bold) styles.push('font-weight: bold')
        if (this.italic) styles.push('font-style: italic')
        if (this.underline) styles.push('text-decoration: underline')
        const styleString =
            styles.length > 0 ? ` style="${styles.join(';')}"` : ''
        return `<tspan font-size="${reduceStyleSize(
            HEADING_SIZES[opts.headingSize - 1] + 'px',
            opts.reduceSize
        )}"${styleString}>${this.getRawText(
            TextElementMode.PREVIEW,
            opts,
            true
        )}</tspan>`
    }
}
