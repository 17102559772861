import { accessRightsToRaw } from '@/ponychart'
import { AccessRights } from "ponychart"
import axios from 'axios'



export default {
    getAccessRights: async function (): Promise<AccessRights> {
        const {
            data: { accessRights },
        } = await axios(`/v1/access-rights`)
        return accessRights
    },
    getRawAccessRights: async function (userRole?: string) {
        const accessRights = await this.getAccessRights()
        return accessRightsToRaw(accessRights, userRole)
    },
    updateAccessRights: async function (accessRightsPayload: any, opts: { noresult?: boolean } = { noresult: true }) {
        const url = ['/v1/access-rights']
        if (opts.noresult) url.push("?noresult=true")
        const {
            data: { accessRights },
        } = await axios.put(url.join(""), accessRightsPayload)
        return accessRights
    }
}
