
import Component, { mixins } from 'vue-class-component'

import { GlobalMixins } from '@/mixins'
import { Prop } from 'vue-property-decorator'
import { ParameterMode, ParameterValue, ParameterTraitOptions } from 'ponychart'

@Component({
    components: {},
})
export default class ParameterComponent extends mixins(GlobalMixins) {
    @Prop({ type: String, required: true })
    readonly traitId!: string
    @Prop({ type: Object, required: true })
    readonly parameter!: ParameterTraitOptions
    @Prop({
        type: Array,
        required: false,
        default: () => [ParameterMode.LIST, ParameterMode.RANGE],
    })
    readonly modes!: ParameterMode[]
    @Prop({ type: String, required: false })
    readonly value!: string

    show = false
    Mode = ParameterMode
    add = false
    allowStepSize = false
    stepSize = 1
    minimum = 1
    allowMinimum = true
    maximum = 100
    allowMaximum = false
    newValue: ParameterValue = {
        id: 0,
        alias: '',
        editId: false,
        editAlias: false,
    }

    get all() {
        return (this.parameter.values || []).every((v) => v.selected)
    }

    set all(value: boolean) {
        if (this.parameter.values) {
            this.parameter.values.forEach((v) => (v.selected = value))
            this.parameter.values[0].selected = true
        }
    }

    get indeterminate() {
        return (
            !this.all && (this.parameter.values || []).some((v) => v.selected)
        )
    }

    get checkboxDisabled() {
        return (
            this.values.reduce(
                (count: number, value: ParameterValue) =>
                    count + (value.selected ? 1 : 0),
                0
            ) <= 1
        )
    }

    get values(): ParameterValue[] {
        return this.parameter.values || []
    }

    get idMap() {
        return this.values
            .filter((v) => v.selected !== false)
            .reduce((acc: { [key: number | string]: number }, v: any) => {
                acc[v.id] = (acc[v.id] || 0) + 1
                return acc
            }, {})
    }

    get aliasMap() {
        return this.values
            .filter((v) => v.selected !== false)
            .reduce((acc: { [key: string]: number }, v: any) => {
                acc[v.alias] = (acc[v.alias] || 0) + 1
                return acc
            }, {})
    }

    get hasError() {
        if (this.parameter?.mode !== ParameterMode.RANGE && !this.values.length)
            return true
        return (
            Object.values(this.idMap).some((v: any) => v > 1) ||
            Object.values(this.aliasMap).some((v: any) => v > 1)
        )
    }

    change() {
        this.$emit('change', this.value)
    }

    getStyle(value: ParameterValue, key: 'editId' | 'editAlias') {
        if (
            this.parameter.mode === ParameterMode.STRING_LIST &&
            key === 'editId'
        )
            return { opacity: '0.8' }
        if (this.parameter.mode !== ParameterMode.STRING_LIST) return {}
        if (value?.selected) return {}
        return { opacity: '0.5' }
    }

    submit() {
        console.log('submit')
    }

    createElement() {
        const ids = this.values.map((v) => v.id)
        if (ids.includes(this.newValue.id)) {
            return
        }
        if (!this.newValue.id && this.newValue.id !== 0) {
            return
        }
        this.values.push(this.newValue)
        this.newValue = { id: 0, alias: '', editId: false, editAlias: false }
    }
    async setEdit(
        value: {
            id: number
            alias: string
            editId?: boolean
            editAlias?: boolean
        },
        key: 'editId' | 'editAlias',
        i: number
    ) {
        if (
            this.parameter.mode === ParameterMode.STRING_LIST &&
            key === 'editId'
        )
            return
        value[key] = true
        const otherKey = key === 'editId' ? 'editAlias' : 'editId'
        for (const v of this.values) {
            if (v.id !== value.id) {
                v[key] = false
                v[otherKey] = false
            }
            if (key === 'editId' && !v.alias) {
                v.alias = String(v.id)
            }
        }
        await this.$nextTick()
        this.focusInput(i, key)
    }

    focusInput(i: number, key: 'editId' | 'editAlias') {
        this.$refs[`input-${key}-${i}`]?.[0]?.focus()
    }

    deleteValue(i: number) {
        this.values.splice(i, 1)
    }
}
