import { Trait, TraitId } from 'ponychart'
import {
    CLS_COL,
    CLS_DA,
    CLS_FLEX,
    CLS_INLINE,
    CLS_ROW,
} from '../element/config'
import { CustomTrait } from '../trait/model'
import { AttributesToUpdate } from '../trait/types'
const FORBIDDEN_CLASSES = new Set([CLS_ROW, CLS_COL, CLS_FLEX, CLS_INLINE])

export function classFilter(
    classes: string[],
    isCol: boolean,
    isFlex: boolean
) {
    const output: Set<string> = new Set([CLS_DA])
    for (const c of classes) {
        if (FORBIDDEN_CLASSES.has(c)) continue
        output.add(c)
    }
    if (isCol) output.add(CLS_COL)
    else output.add(CLS_ROW)
    if (isFlex) output.add(CLS_FLEX)
    else output.add(CLS_INLINE)
    return Array.from(output)
}

export function applyOutsideTraitAttributes(
    outsideTrait: Trait | CustomTrait,
    customTrait: CustomTrait,
    attributesToUpdate: AttributesToUpdate[]
) {
    const hasAll =
        attributesToUpdate.includes('*') ||
        attributesToUpdate.includes('!value')

    if (
        hasAll ||
        (attributesToUpdate.includes('options.hasDimension') &&
            outsideTrait.options?.hasDimension !== undefined)
    ) {
        customTrait.options.hasDimension = outsideTrait.options?.hasDimension
    }

    if (
        hasAll ||
        (attributesToUpdate.includes('options.hasDate') &&
            outsideTrait.options?.hasDate !== undefined)
    ) {
        customTrait.options.hasDate = outsideTrait.options?.hasDate
    }
    // Important to set type first since setValue & setOptions depend on it
    if (
        (hasAll || attributesToUpdate.includes('options.maxCount')) &&
        outsideTrait.options?.maxCount !== undefined
    ) {
        customTrait.options.maxCount = outsideTrait.options?.maxCount || 2
        customTrait.setValue(customTrait.value)
    }
    if (
        attributesToUpdate.includes('*') ||
        attributesToUpdate.includes('value')
    ) {
        // Important to set pristine first since setValue depends on it
        customTrait.pristine = outsideTrait.pristine || false
        customTrait.setValue(outsideTrait.value)
    }
    if (
        (hasAll || attributesToUpdate.includes('options.items')) &&
        outsideTrait.options &&
        outsideTrait.options.items &&
        outsideTrait.options.items.length
    ) {
        customTrait.setItems(outsideTrait.options.items)
    }
    if (
        hasAll ||
        (attributesToUpdate.includes('label') && outsideTrait.label)
    ) {
        customTrait.label = outsideTrait.label || ''
    }
    if (hasAll || attributesToUpdate.includes('querySelectorTags')) {
        customTrait.querySelectorTags = outsideTrait.querySelectorTags || [0]
    }
    for (const key of ['allowNone', 'allowMultiple', 'sortable', 'hidden']) {
        if (!hasAll && !attributesToUpdate.includes(key as AttributesToUpdate))
            continue
        if (outsideTrait[key] === undefined) continue
        customTrait[key] = outsideTrait[key]
    }
}
