import store from '../store'
import { Route, NavigationGuardNext } from 'vue-router'

function validateLang(lang: string) {
    return ['en', 'es', 'de', 'fr'].includes(lang)
}

function nextLang(lang: string, to: Route, next: NavigationGuardNext) {
    return next({ path: `/${lang}/login`, query: { redirect: to.fullPath } })
}

export default (to: Route, _from: Route, next: NavigationGuardNext) => {
    if (store.getters['cognito/isLoggedIn']) {
        next()
    } else {
        const pathLang = to.path.slice(1, 3)
        if (validateLang(pathLang)) return nextLang(pathLang, to, next)
        const navLang = navigator.language
        if (navLang.indexOf('-') === 2 && validateLang(navLang))
            return nextLang(navLang.split('-')[0], to, next)
        if (navLang.length === 2 && validateLang(navLang))
            return nextLang(navLang, to, next)
        if (validateLang(store.getters.lang))
            return nextLang(store.getters.lang, to, next)
        return nextLang('en', to, next)
    }
}
