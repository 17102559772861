
import MiscDates from '@/components/utils/MiscDates.vue'
import DatasourcePill from '@/components/utils/DatasourcePill.vue'

import { SourceMemory, IDate } from '@/ponychart'
import { GlobalMixins, Traits } from '@/mixins'

import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Trait, TraitId } from 'ponychart'

@Component({
    components: {
        MiscDates,
        DatasourcePill,
    },
})
export default class DateComponent extends mixins(GlobalMixins, Traits) {
    @Prop({ type: Array, required: true })
    readonly selectedDates!: (IDate | undefined)[]
    @Prop({ type: Array, required: true })
    readonly sourceTraits!: Trait[]
    @Prop({ type: Array, required: true })
    readonly twbDatasources!: { id: string; alias: string; color: string }[]

    show = false
    currentIndex = -1

    get dateAggregationLevel() {
        for (const trait of this.sourceTraits) {
            if (trait.id === TraitId.SOURCE_DATE_AGGREGATION_LEVEL)
                return trait.value as 'day' | 'month'
        }
        return 'day'
    }

    set dateAggregationLevel(dateAggregationLevel: 'day' | 'month') {
        for (const trait of this.sourceTraits) {
            if (trait.id === TraitId.SOURCE_DATE_AGGREGATION_LEVEL)
                trait.value = dateAggregationLevel
        }
        this.refreshSourceTraits(this.sourceTraits, { emit: true })
    }

    setShow(i: number) {
        this.currentIndex = i
        this.show = true
    }

    dateItems(twbIdx: number): IDate[] {
        // if (this.selectedDates.length === 1){} && this.selectedDates[0]?.formula)
        //     return this.selectedDates
        const { dateColumns } = SourceMemory.getInstance()
        const output = dateColumns
            .filter((d) => d.twbIdx === twbIdx)
            .map(({ id, alias }) => ({
                columnId: id,
                alias,
                formula: undefined,
                twbIdx,
            }))
        if (output.length === 0) {
            const selectedDate = this.selectedDates[twbIdx]
            return selectedDate !== undefined ? [selectedDate] : []
        }
        return output
    }

    async mounted() {
        await this.$nextTick()
        this.buildSelectedDatesOnMounted()
        this.refreshSourceTraits(this.sourceTraits, { emit: true })
    }
    buildSelectedDatesOnMounted() {
        if (this.selectedDates.length > 0) return
        const selectedDates: (IDate | undefined)[] = []
        for (const i in this.twbDatasources) {
            const twbIdx = Number(i)
            if (
                this.selectedDates.length > twbIdx &&
                this.selectedDates[twbIdx] &&
                this.selectedDates[twbIdx] !== undefined
            ) {
                selectedDates.push(selectedDates[twbIdx])
                continue
            }

            const dateItems = this.dateItems(twbIdx)
            if (dateItems.length > 0) {
                selectedDates.push(dateItems[0])
            } else {
                selectedDates.push(undefined)
            }
        }
        this.$emit('set', { selectedDates })
    }

    setSelectedDates(twbIdx: number, e: any) {
        const selectedDates = [...this.selectedDates]
        if (e === undefined) selectedDates[twbIdx] = undefined
        else selectedDates[twbIdx] = { ...e, twbIdx }
        this.$emit('set', { selectedDates })
    }
    addDate(d: any) {
        // called from MiscDate
        // this.dates.push(d)
        this.setSelectedDates(this.currentIndex, d)
    }
}
