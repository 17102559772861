import { Position } from '@/ponychart/charts/types'


export function wrapSvgContent(
    svgContent: string,
    opts: {
        attributes?: { [k in string]: string }
        style?: { [k in string]: string }
        classes?: string[]
        id?: string
        preserveAspectRatio?: {
            x: 'Min' | 'Max' | 'Mid'
            y: 'Min' | 'Max' | 'Mid'
            suffix: 'meet' | 'slice'
        }
        onlyContainer?: boolean
        viewBox?: Position | string
        title?: string
    } = {}
): string {
    const attributes = (opts?.attributes || {}) as { [k in string]: string }

    const style = {
        ...opts?.style || {},
    }
    style["justify-content"] = "center"
    if (opts.onlyContainer) style["cursor"] = "pointer"
    attributes.style = Object.entries(style)
        .map(([k, v]) => `${k}: ${v}`)
        .join('; ')
    attributes.style += ""
    const pAR = opts?.preserveAspectRatio || {
        x: 'Mid',
        y: 'Mid',
        suffix: 'slice',
    }
    // attributes.preserveAspectRatio = `x${pAR.x}Y${pAR.y} ${pAR.suffix}`
    if (opts?.classes?.length) attributes.class = opts.classes.join(' ')
    if (opts?.title) attributes.title = opts.title
    if (opts?.id) attributes.id = opts.id

    return wrapAttributes(
        'div',
        opts?.onlyContainer ? '<div style="background: grey; height: 90%; width: 90%; margin: 5%;opacity: 0.8; border-radius: 5%"></div>' : wrapAttributes(
            'svg',
            svgContent,
            {
                style: `margin: auto; max-width: 100%; max-height: 100%;height: 100%`,
                preserveAspectRatio: `x${pAR.x}Y${pAR.y} ${pAR.suffix}`,
                viewBox: typeof opts.viewBox === "string" ? opts.viewBox : `0 0 ${opts?.viewBox?.x || 100} ${opts?.viewBox?.y || 100}`
            }
        ),
        attributes
    )
}


export function wrapAttributes(htmlTag: string, content: string, attributes: { [k: string]: string | undefined }) {
    const stringAttributes = Object.entries(attributes)
        .filter(([k, v]) => !!k && !!v)
        .map(([k, v]) => `${k}="${v}"`)
        .join(' ')
    return `<${htmlTag} ${stringAttributes}>${content}</${htmlTag}>`
}