import { PageStructureTraitsFactory } from "@/ponychart/structure/traits"
import { listChartRelatedTraits } from "@/ponychart/charts/traits"
import { PageBlockTraitsFactory } from "@/ponychart/pageBlock/traits"
import { TraitSearch } from "@/ponychart/state/traits"
import { StructurePostProcessor } from "@/ponychart/structure/postProcessor"
import { PageStructure } from "@/ponychart/page/types"
import { Trait } from 'ponychart'

import { listDeviceTypeTraits } from "./deviceType"
import { applyCrossDeviceValues } from "./crossDeviceTraits"
import { TRAIT_PRIORITY } from "./config"


export function listCustomTraits(
    structure: PageStructure,
    traitSearch: TraitSearch,
    opts: { applyCrossDeviceValues?: boolean } = {}
) {

    if (opts.applyCrossDeviceValues) {
        for (const trait of traitSearch.traits) {
            applyCrossDeviceValues(traitSearch.traits, trait)
        }
    }

    // state.logTouchedTraits("0: starting")
    traitSearch.tagAllTraitsAsUntouched()

    const blockTraitsFactory = new PageBlockTraitsFactory(traitSearch)
    const structureTraitsFactory = new PageStructureTraitsFactory(structure, traitSearch)
    const postProcessStructure = new StructurePostProcessor(structure, traitSearch)

    /** CREATE STRUCTURE RELATED TRAITS */
    structureTraitsFactory.preListTraits()
    // state.logTouchedTraits("1: preListStructureRelatedTraits")

    /** CREATE BLOCK RELATED TRAITS */
    blockTraitsFactory.preListTraits()
    // state.logTouchedTraits("2: preListBlockTraits")

    /** CREATE CHART RELATED TRAITS */
    listChartRelatedTraits(structure, traitSearch)
    // state.logTouchedTraits("3: listChartRelatedTraits")

    /** POST LIST BLOCK TRAITS */
    blockTraitsFactory.postListTraits()
    // state.logTouchedTraits("4: postListBlockTraits")

    /** CREATE STRUCTURE RELATED TRAITS which have dependencies over chart traits (CARD_TITLE for instance)*/
    structureTraitsFactory.postListTraits()
    // state.logTouchedTraits("5: postListStructureRelatedTraits")

    /** LISTS ALL DEVICE_TYPE RELATED TRAITS*/
    listDeviceTypeTraits(structure, traitSearch)
    // state.logTouchedTraits("6: listDeviceTypeTraits")

    /** POTENTIALLY REMOVES SOME TRAITS WHICH ARE DUPLICATED */
    postProcessStructure.postProcess()
    // state.logTouchedTraits("7: postProcessStructure")

    traitSearch.removeUntouchedTraits()
    // state.logTouchedTraits("8: The end")

    return traitSearch.traits
}

export function listTraits(
    structure: PageStructure,
    traitSearch: TraitSearch,
    opts: { applyCrossDeviceValues?: boolean } = {}
): Trait[] {
    return listCustomTraits(structure, traitSearch, opts)
        .sort((a: Trait, b: Trait) => TRAIT_PRIORITY.indexOf(a.id) - TRAIT_PRIORITY.indexOf(b.id)).map(t => t.toDict()) 
}

