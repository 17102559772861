
import {
    showEdit,
    getArrayValueFromString,
    getStringValueFromArray,
    getStringValueFromString,
    defaultTraitOption,
    allTraitOptionItems,
    ColumnType,
    GlobalOptions,
    showIcon,
} from '@/ponychart'
import {
    ChartType,
    TraitId,
    TraitOptionType,
    Trait,
    TraitOptionItem,
    CHART_TYPE_TRAITS_SET,
} from 'ponychart'
import SortableAutocomplete from '@/components/utils/SortableAutocomplete.vue'
import Tiptap from '@/components/utils/Tiptap.vue'
import Parameter from '@/components/utils/Parameter.vue'
import PagePaidOrFreeChip from '@/components/Pages/PagePaidOrFreeChip.vue'
import { GlobalMixins, Pages } from '@/mixins'

import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TraitSearch } from '@/ponychart/state'

const CHART_ICONS = {
    [ChartType.TIME_BAR]: 'stacked_bar_chart',
    [ChartType.BAR]: 'align_horizontal_left',
    [ChartType.LOLLIPOP]: 'align_horizontal_left',
    [ChartType.PIE]: 'pie_chart',
    [ChartType.LINE]: 'show_chart',
    [ChartType.DONUT]: 'donut_large',
    [ChartType.MAP]: 'public',
    [ChartType.AREA]: 'area_chart',
    [ChartType.KPI]: 'numbers',
}
@Component({
    components: { SortableAutocomplete, PagePaidOrFreeChip, Tiptap, Parameter },
})
export default class PageTrait extends mixins(GlobalMixins, Pages) {
    @Prop({ type: Object, required: true })
    readonly trait!: Trait
    @Prop({ type: Boolean, default: false })
    readonly hideEdit!: boolean
    @Prop({ type: Object, default: () => ({}) })
    readonly globalOptions!: Partial<GlobalOptions>
    @Prop({ type: Boolean, default: false })
    readonly disabled!: boolean
    @Prop({ type: Boolean, default: true })
    readonly dense!: boolean
    @Prop({ type: Boolean, default: true })
    readonly hideDetails!: boolean
    @Prop({ type: String, default: '' })
    readonly label!: string
    @Prop({ type: Boolean, default: false })
    readonly fromPageEditBlock!: boolean
    @Prop({ type: Object, required: false })
    readonly traitSearch?: TraitSearch

    tmt: number | null = null

    getChartIcon(id: ChartType) {
        return CHART_ICONS[id]
    }

    get maxValue() {
        return this.trait.options?.maxValue || 0
    }
    get minValue() {
        return this.trait.options?.minValue || 0
    }
    get isSlider() {
        return this.trait.type === TraitOptionType.SLIDER
    }
    get isTextInput() {
        return false
        // return this.trait.type === TraitOptionType.TEXT_INPUT
    }
    get isTiptap() {
        return this.trait.type === TraitOptionType.TIPTAP
    }
    get internalValue() {
        switch (this.trait.type) {
            case TraitOptionType.SELECT:
                return this.trait.value
            case TraitOptionType.CHECKBOX:
                return this.trait.value == this.trait.options?.valueTrue
            case TraitOptionType.MULTI_SELECT: {
                if (this.trait.options?.maxCount === 1) return this.trait.value
                return this.valueArray
            }
            // case TraitOptionType.TEXT_INPUT:
            //     return this.trait.value
            case TraitOptionType.SLIDER:
                return this.trait.value
            case TraitOptionType.TIPTAP:
                return this.trait.value
            default:
                return 'div'
        }
    }
    get group() {
        return (
            this.trait.type +
            '_' +
            (this.trait.querySelectorTags || []).join('_')
        )
    }
    get isChartSelectionType() {
        return CHART_TYPE_TRAITS_SET.has(this.trait.id as TraitId)
    }
    get component() {
        switch (this.trait.type) {
            case TraitOptionType.SELECT:
                return 'v-select'
            case TraitOptionType.CHECKBOX:
                return 'v-checkbox'
            case TraitOptionType.MULTI_SELECT: {
                if (!this.multiple) return 'v-select'
                return this.trait.options?.sortable
                    ? 'sortable-autocomplete'
                    : 'v-autocomplete'
            }
            // case TraitOptionType.TEXT_INPUT:
            //     return 'v-text-field'
            case TraitOptionType.SLIDER:
                return 'v-slider'
            case TraitOptionType.TIPTAP:
                return 'tiptap'
            case TraitOptionType.PARAMETER:
                return 'parameter'
            default:
                return 'div'
        }
    }
    get multiple() {
        return (
            this.trait.type === TraitOptionType.MULTI_SELECT &&
            this.trait.options?.allowMultiple &&
            (this.trait.options?.maxCount || 2) > 1
        )
    }
    get icon() {
        if (this.allSelected) return 'mdi-close-box'
        if (this.valueArray.length > 0) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }
    get valueArray() {
        return getArrayValueFromString(String(this.trait.value) || '', {
            ...this.trait,
            ...this.trait.options,
            items: this.trait.options?.items || [],
        })
    }
    get traitIcon() {
        if (this.isChartSelectionType)
            return this.getChartIcon(this.trait.value as ChartType)
        return showIcon(this.trait.id as TraitId)
    }
    get showEdit() {
        return (
            !this.hideEdit &&
            showEdit(this.trait.id as TraitId, String(this.trait.value))
        )
    }
    get defaultItem() {
        return defaultTraitOption(this.trait.options?.items || [])
    }
    get allItems() {
        return allTraitOptionItems(this.trait.options?.items || [])
    }
    get itemValues() {
        return (this.traitOptionItems as any).map((item: any) => item.id)
    }
    get traitOptionItems() {
        const options: TraitOptionItem[] = []
        for (const option of this.trait.options?.items || []) {
            if (typeof option === 'string') {
                options.push({
                    id: String(option),
                    alias: String(this.$t(`${this.trait.id}.${option}`)),
                })
            } else {
                options.push(option)
            }
        }
        return options.filter(
            (col: TraitOptionItem) =>
                ![ColumnType.SINGLE, ColumnType.DYNAMIC].includes(
                    col.id as ColumnType
                )
        )
    }
    get allSelected() {
        return this.valueArray.length === this.traitOptionItems.length
    }

    async toggle() {
        await this.$nextTick()
        if (this.allSelected) {
            this.setMultipleValue(
                this.trait.options?.allowNone ? [] : String(this.defaultItem),
                true
            )
        } else if (
            this.valueArray.length === 1 &&
            this.trait.options?.allowMultiple &&
            !this.trait.options?.allowNone
        ) {
            this.setMultipleValue(this.allItems, true)
        } else {
            this.setMultipleValue(
                this.trait.options?.allowMultiple
                    ? this.allItems
                    : String(this.defaultItem),
                true
            )
        }
    }
    setCheckboxValue(e: string) {
        return e
            ? this.trait.options?.valueTrue
            : this.trait.options?.valueFalse
    }
    setSelectValue(e: string) {
        return e
    }
    inputDebounceSet(e: any) {
        if (!this.isTextInput && !this.isTiptap) return // Important
        if (this.tmt) clearTimeout(this.tmt)
        this.tmt = setTimeout(
            () => {
                this.emitSet(e)
            },
            400,
            this
        )
    }
    log(...e: any) {
        if (this.trait.id !== TraitId.MEASURE) return
        console.error(...e)
    }
    emitSet(e: any) {
        this.$emit('set', e)
        return e
    }
    setMultipleValue(e: string[] | string, emit = false) {
        const traitValue = {
            ...this.trait,
            ...this.trait.options,
            items: this.trait.options?.items || [],
        }
        const stringValue = Array.isArray(e)
            ? getStringValueFromArray(e, traitValue)
            : getStringValueFromString(e, traitValue)
        if (emit) this.emitSet(stringValue)
        return stringValue
    }
    setValue(e: string, emit = true) {
        // if (this.isTextInput) return
        let output
        if (this.trait.type === TraitOptionType.CHECKBOX) {
            output = this.setCheckboxValue(e)
        } else if (this.trait.type === TraitOptionType.SELECT) {
            output = this.setSelectValue(e)
        } else if (this.trait.type === TraitOptionType.SLIDER) {
            output = this.setSelectValue(e)
        } else if (this.trait.type === TraitOptionType.MULTI_SELECT) {
            output = this.setMultipleValue(e, false)
        } else if (this.isTextInput) {
            output = this.inputDebounceSet(e)
            return e
        }
        if (emit) {
            this.emitSet(output)
        }

        return output
    }
}
