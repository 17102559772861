import { State } from './state'
import { GetterTree } from 'vuex'
import { findSubscription } from '@/ponychart/utils/subscription'


export const getters: GetterTree<State, State> = {
  lang: (state: State, getters) => {
    if ((state as any).route?.params?.lang)
      return (state as any).route.params.lang
    if (state?.user?.lang) return state.user.lang
    if (getters?.['cognito/userAttributes']?.locale)
      return getters?.['cognito/userAttributes'].locale
    return 'en'
  },
  isAdmin: (state: State) => state?.user?.role === 'admin',
  dashboardCount: state => state.company?.token || 0,
  company: state => state.company,
  companyName: (state, getters) =>
    state.company && state.company.name
      ? state.company.name
      : getters['cognito/userAttributes']['custom:company_name'],
  userName: (state, getters) =>
    state.user?.firstName || getters['cognito/userAttributes']['given_name'],
  email: (state, getters) =>
    state.user?.email || getters['cognito/userAttributes']['email'],
  consultingHours: () => 0,
  consultingDays: () => 0,
  meetings: () => [],
  isProd: () => !location.port,
  subscription: state => {
    return findSubscription(state.subscriptions)
  },
  latestSubscriptionEnded: state => {
    return state.subscriptions.length > 0 ? Math.max(...state.subscriptions.map((s: any) => s.current_period_end)) : undefined
  }
 }

export type Getters = typeof getters
