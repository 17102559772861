<template lang="pug">
v-col(justify='center', align='center')
    //- demo
    get-started
    v-row(justify='center', align='center')
        v-col(cols='8')
            source-pagination
    meetings
    contact-popup
    install
</template>

<script>
import Meetings from '@/components/Home/Meetings'
import ContactPopup from '@/components/Home/ContactPopup'
import SourcePagination from '@/components/Source/SourcePagination'
import GetStarted from '@/components/Home/GetStarted'
import Install from '@/components/Home/Install'
export default {
    name: 'HomeComponent',
    components: {
        Meetings,
        ContactPopup,
        GetStarted,
        Install,
        SourcePagination,
    },
    // mounted(){
    //     // @ts-ignore
    //     Calendly.initInlineWidget({
    //         url: 'https://calendly.com/data-pony/beta'
    //     });
    // }
}
</script>
