import { Dimension, Format, Lang, Measure, Page, ParameterInterface } from '@/ponychart';
import axios from 'axios'
import { dimensionMask, measureMask, pageMask } from './utils';

export default {
    generateDemo: async function (
        sourceDemoUuid: string, 
        measures: Measure[],
        dimensions: Dimension[],
        parameters: ParameterInterface[],
        formats: Format[],
        pages: Page[]
    ){
        const {data: {url}} = await axios(`/tableau/demo/${sourceDemoUuid}`, {
            method: "post",
            data: {
                measures: measures.map(measureMask),
                dimensions: dimensions.map(dimensionMask),
                parameters,
                formats,
                pages: pages.map(pageMask)
            },
            timeout: 180000,
        })
        return {url, tableauUrl: null}
    },
    generate: async function (sourceId: number, secretValue?: string) {
        let tableauUrl: string | null = null
        const {
            data: { result },
        } = await axios({
            url: `/tableau/create/${sourceId}`,
            method: 'post',
            params: {
                secretValue
            },
            timeout: 180000,
        }).then(({ data }: any) =>{
            tableauUrl = data.tableauUrl
            return axios({
                url: `/v1/sources/${sourceId}/results/${data.id}`,
                method: 'get',
                params: { includeDownloadUrl: true },
            })
        })
        return {url: result.downloadUrl, tableauUrl}
    },
    scan: async function (
        uuid: string,
        opts: { datasourceIds?: string[], demoLang?: Lang }
    ): Promise<{ datasources: any[]; url?: string }> {
        const { data } = await axios({
            url: `/tableau/scan/${uuid}`,
            params: opts,
        })
        if (data.datasources)
            data.datasources = data.datasources.map((d: any) => ({
                ...d,
                text: d.text || d.value,
            }))
        return data
    },
}
