// import { shadeHexColor } from '@/ponychart/utils'
import {
    PonychartElement,
    ColumnContainer,
    RowContainer,
    ButtonComponent,
} from '@/ponychart/element/model'
import {
    GlobalState,
    LocalState,
    TraitSearch,
    getStyledState,
} from '@/ponychart/state'
import { TimeIndication } from '@/ponychart/charts/kpiChart'
import { SourceMemory } from '@/ponychart/memoize'
import { Bool } from '@/ponychart/trait/types'
import {
    add,
    divideBy,
    isBigSize,
    multiplyBy,
    nToS,
    WINDOW_WIDTHS,
} from '@/ponychart/utils'
import { PonychartElementHelpers } from '@/ponychart/element/helpers'
import { SizePropagatorHelpers } from '@/ponychart/element/size'
import { guessAppropriateColumnCount } from '@/ponychart/pageBlock/utils'
import {
    TraitId,
    HeaderDesign,
    SidebarDesign,
    SidebarType,
    DeviceType,
} from 'ponychart'

import {
    Logo,
    FilterSelector,
    HeaderRow,
    HeaderColumn,
    MenuIcon,
    FilterIcon,
} from './model'

import { fill, FillMode, fillArrayStart } from './fill'
import { splitArrayIntoChunksOfLen } from './utils'
import { TimeSelectorFactory } from './timeSelectors'
import {
    ELEMENT_HEIGHT,
    ELEMENT_WIDTH,
    MOBILE_BOTTOM_HEADER_HEIGHT,
    MOBILE_TOP_HEADER_HEIGHT,
    SIDEBAR_HEIGHT,
    SIDEBAR_WIDTH,
    TITLE_STYLE,
} from './config'
import {
    clickedNavigationButton,
    clickedNavigationButtonStyles,
} from './navigationButton'

const memoryInstance = SourceMemory.getInstance()

const HEADER_TITLE_STYLE = {
    ...TITLE_STYLE,
    paddingLeft: '0px',
    textAlign: 'center',
    justifyContent: 'center',
}

function buildFilters(
    globalState: GlobalState,
    localState: LocalState,
    traitSearch: TraitSearch
): PonychartElement[] {
    return memoryInstance
        .getFilters(globalState.twbIndexes)
        .map(
            (f) =>
                new FilterSelector(
                    f.id,
                    f.alias,
                    globalState,
                    localState,
                    traitSearch
                )
        )
}

function buildTimeSelector(
    globalState: GlobalState,
    localState: LocalState,
    traitSearch: TraitSearch
): PonychartElement[] {
    return new TimeSelectorFactory(globalState, traitSearch).build(localState)
}

function buildTimeIndication(
    globalState: GlobalState,
    styledState: LocalState,
    traitSearch: TraitSearch
): PonychartElement {
    const withColors =
        traitSearch.getTraitStringValue(TraitId.WITH_COLORS) === Bool.TRUE
    return new TimeIndication(
        globalState,
        withColors
            ? styledState
                  .copy()
                  .addAttribute(TraitId.COLOR, globalState.colors.background)
            : styledState,
        traitSearch
    )
}

/**
 * This method builds a header with a centered design
 * @param state: State
 * @param twbIndexes?: number[] The twbIndexes within a single page (for filters)
 * @returns
 */
function buildCenterHeader(
    globalState: GlobalState,
    traitSearch: TraitSearch
): PonychartElement {
    const filters = memoryInstance.getFilters(globalState.twbIndexes)
    const headerHeight = traitSearch.getTraitNumberRequiredValue(
        TraitId.HEADER_HEIGHT,
        0
    )
    const height = headerHeight

    const denseMode =
        traitSearch.getTraitStringValue(TraitId.DENSIFY_HEADER_FILTERS) ===
            Bool.TRUE && filters.length > 0

    const styledState = getStyledState(globalState, traitSearch)
    const styledFlexState = styledState.copy().setIsFlex(true)
    const localState = new LocalState()
    const flexState = localState.copy().setIsFlex(true)
    const innerWidth = _innerHeaderWidth(globalState, traitSearch)
    const columnCount = guessAppropriateColumnCount(innerWidth)

    // const border = opts?.sourceOptions?.border

    const rows = [
        new RowContainer(globalState, flexState, {
            children: [
                new ColumnContainer(globalState, localState),
                new RowContainer(
                    globalState,
                    styledState
                        .copy()
                        .addStyles(HEADER_TITLE_STYLE)
                        .addStyles({
                            fontSize: nToS(height / 6),
                            fontFamily: memoryInstance.fontFamily,
                        }),
                    {
                        content: globalState.pageTitle,
                        fontRatio: 0.5,
                    }
                ),
                new ColumnContainer(globalState, localState),
            ],
        }),
        new HeaderRow(
            globalState,
            styledFlexState,
            fill(
                globalState,
                denseMode
                    ? [
                          ...buildTimeSelector(
                              globalState,
                              flexState,
                              traitSearch
                          ),
                          ...buildFilters(globalState, flexState, traitSearch),
                      ]
                    : buildTimeSelector(globalState, flexState, traitSearch),
                FillMode.CENTER,
                columnCount,
                ColumnContainer
            )
        ),
    ]

    if (filters.length && !denseMode)
        rows.push(
            new HeaderRow(
                globalState,
                styledFlexState,
                fill(
                    globalState,
                    buildFilters(globalState, localState, traitSearch),
                    FillMode.CENTER,
                    columnCount,
                    ColumnContainer
                )
            )
        )
    if (traitSearch.getTraitValue(TraitId.WITH_LOGO, 0) === Bool.TRUE)
        rows[0].children.splice(
            1,
            0,
            new Logo(globalState, styledState, traitSearch)
        )
    if (traitSearch.getTraitValue(TraitId.WITH_TIME_PERIOD, 0) === Bool.TRUE) {
        rows[0].children.splice(
            rows[0].children.length - 1,
            0,
            buildTimeIndication(globalState, styledState, traitSearch)
        )
    }
    const marginX = nToS(
        traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X, 0) || 0
    )
    const halfMargin = divideBy(marginX, 2)
    const headerColumn = new HeaderColumn(
        globalState,
        flexState
            .copy()
            .addStyles({
                background: globalState.colors.light_background,
                height: add(height, halfMargin),
                paddingTop: halfMargin,
                paddingBottom: '0',
                paddingLeft: marginX,
                paddingRight: marginX,
            })
            .addClasses(isBigSize(globalState.reduceSize) ? ['x-block'] : []),
        rows
    )
    SizePropagatorHelpers.propagateSizeDown(headerColumn, {
        height: headerHeight,
        width: SizePropagatorHelpers.getDefaultWidth(globalState, traitSearch),
    })
    return headerColumn.mount().adaptYPaddings([1, 2])
}

function buildDenseHeader(globalState: GlobalState, traitSearch: TraitSearch) {
    const headerHeight = traitSearch.getTraitNumberRequiredValue(
        TraitId.HEADER_HEIGHT,
        0
    )
    const height = nToS(headerHeight)

    const localState = new LocalState()

    const children: PonychartElement[] = [
        new RowContainer(
            globalState,
            localState
                .copy()
                .addStyles(HEADER_TITLE_STYLE)
                .addStyles({
                    fontSize: nToS(headerHeight / 3),
                    fontFamily: memoryInstance.fontFamily,
                }),
            { content: globalState.pageTitle, fontRatio: 0.3 }
        ),
    ]
    if (traitSearch.getTraitValue(TraitId.WITH_LOGO, 0) === Bool.TRUE)
        children.push(new Logo(globalState, localState, traitSearch))
    if (traitSearch.getTraitValue(TraitId.WITH_TIME_PERIOD, 0) === Bool.TRUE) {
        const withColors =
            traitSearch.getTraitValue(TraitId.WITH_COLORS) === Bool.TRUE
        children.push(
            new TimeIndication(
                globalState,
                withColors
                    ? localState
                          .copy()
                          .addAttribute(
                              TraitId.COLOR,
                              globalState.colors.background
                          )
                    : localState,
                traitSearch
            )
        )
    }
    const paddedState = localState.copy().adaptStylePaddingForSelector(height)
    children.push(...buildTimeSelector(globalState, paddedState, traitSearch))
    children.push(...buildFilters(globalState, paddedState, traitSearch))
    const marginX = nToS(
        traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X, 0) || 0
    )
    const halfMargin = divideBy(marginX, 2)
    const rowContainer = new RowContainer(
        globalState,
        getStyledState(globalState, traitSearch)
            .addStyles({
                height: nToS(height),
                padding: '4px',
                marginTop: halfMargin,
                marginBottom: '0',
                marginLeft: marginX,
                marginRight: marginX,
            })
            .addClasses(isBigSize(globalState.reduceSize) ? ['x-block'] : [])
            .setIsFlex(true),
        {
            children: fill(
                globalState,
                children,
                FillMode.END,
                6,
                ColumnContainer
            ),
        }
    )
    SizePropagatorHelpers.propagateSizeDown(rowContainer, {
        height: headerHeight,
        width: SizePropagatorHelpers.getDefaultWidth(globalState, traitSearch),
    })
    return rowContainer.mount()
}

function buildSimpleLeftAlignedHeader(
    globalState: GlobalState,
    traitSearch: TraitSearch
) {
    const filters = memoryInstance.getFilters(globalState.twbIndexes)
    const headerHeight = traitSearch.getTraitNumberRequiredValue(
        TraitId.HEADER_HEIGHT,
        0
    )
    const denseMode =
        traitSearch.getTraitStringValue(TraitId.DENSIFY_HEADER_FILTERS) ===
            Bool.TRUE && filters.length > 0

    const styledState = getStyledState(globalState, traitSearch).setIsFlex(true)
    const innerWidth = _innerHeaderWidth(globalState, traitSearch)
    const columnCount = guessAppropriateColumnCount(innerWidth)

    const flexState = new LocalState().setIsFlex(true)
    const rows = [
        new RowContainer(globalState, flexState, {
            children: [
                new RowContainer(
                    globalState,
                    styledState
                        .copy()
                        .addStyles(HEADER_TITLE_STYLE)
                        .addStyles({
                            fontSize: nToS(headerHeight / 3),
                            fontFamily: memoryInstance.fontFamily,
                        }),
                    { content: globalState.pageTitle, fontRatio: 0.5 }
                ),
                new ColumnContainer(globalState, flexState),
                new ColumnContainer(globalState, flexState),
            ],
        }),
        new HeaderRow(
            globalState,
            styledState,
            fill(
                globalState,
                denseMode
                    ? [
                          ...buildTimeSelector(
                              globalState,
                              flexState,
                              traitSearch
                          ),
                          ...buildFilters(globalState, flexState, traitSearch),
                      ]
                    : buildTimeSelector(globalState, flexState, traitSearch),
                FillMode.END,
                columnCount,
                ColumnContainer
            )
        ),
    ]

    if (filters.length && !denseMode)
        rows.push(
            new HeaderRow(
                globalState,
                styledState,
                fill(
                    globalState,
                    buildFilters(globalState, flexState, traitSearch),
                    FillMode.END,
                    columnCount,
                    ColumnContainer
                )
            )
        )
    if (traitSearch.getTraitValue(TraitId.WITH_TIME_PERIOD, 0) === Bool.TRUE) {
        rows[0].children.splice(
            1,
            0,
            buildTimeIndication(globalState, styledState, traitSearch)
        )
    }
    if (traitSearch.getTraitValue(TraitId.WITH_LOGO, 0) === Bool.TRUE)
        rows[0].children.splice(
            0,
            0,
            new Logo(globalState, styledState, traitSearch)
        )
    // TODO: real height or raw height?
    const height = headerHeight
    const marginX = nToS(
        traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X, 0) || 0
    )
    const halfMargin = divideBy(marginX, 2)

    const headerColumn = new HeaderColumn(
        globalState,
        flexState
            .copy()
            .addStyles({
                background: globalState.colors.light_background,
                height: add(height, halfMargin),
                paddingTop: halfMargin,
                paddingBottom: '0',
                paddingLeft: marginX,
                paddingRight: marginX,
            })
            .addClasses(isBigSize(globalState.reduceSize) ? ['x-block'] : []),
        rows
    )
    SizePropagatorHelpers.propagateSizeDown(headerColumn, {
        height: headerHeight,
        width: SizePropagatorHelpers.getDefaultWidth(globalState, traitSearch),
    })
    return headerColumn.mount().adaptYPaddings([1, 2])
}

function buildCenterVerticalHeader(
    globalState: GlobalState,
    traitSearch: TraitSearch
) {
    const memoryFilters = memoryInstance.getFilters(globalState.twbIndexes)

    const denseMode =
        traitSearch.getTraitStringValue(TraitId.DENSIFY_HEADER_FILTERS) ===
            Bool.TRUE && memoryFilters.length > 0

    const styledState = getStyledState(globalState, traitSearch).setIsFlex(true)

    const flexState = new LocalState().setIsFlex(true)

    const headerHeight = traitSearch.getTraitNumberRequiredValue(
        TraitId.HEADER_HEIGHT,
        0
    )
    const height = nToS(headerHeight)
    const numberOfElements = HeaderRow.columnHeight(
        height,
        '0',
        globalState,
        flexState,
        traitSearch
    )

    const filters = fillArrayStart(
        splitArrayIntoChunksOfLen(
            denseMode
                ? [
                      ...buildTimeSelector(globalState, flexState, traitSearch),
                      ...buildFilters(globalState, flexState, traitSearch),
                  ]
                : buildFilters(globalState, flexState, traitSearch).reverse(),
            numberOfElements
        ),
        2
    )
        .reverse()
        .map(
            (elements) =>
                new HeaderColumn(
                    globalState,
                    styledState.copy().addStyles({ padding: '5px' }),
                    fill(
                        globalState,
                        elements,
                        FillMode.END,
                        numberOfElements,
                        RowContainer
                    )
                )
        )
    const rows: PonychartElement[] = [
        new RowContainer(globalState, flexState, {
            children: [
                new ColumnContainer(globalState, flexState, {
                    children: [
                        new RowContainer(
                            globalState,
                            styledState
                                .copy()
                                .addStyles(HEADER_TITLE_STYLE)
                                .addStyles({
                                    fontSize: nToS(headerHeight / 6),
                                    fontFamily: memoryInstance.fontFamily,
                                }),
                            { content: globalState.pageTitle, fontRatio: 0.5 }
                        ),
                        new RowContainer(globalState, flexState),
                    ],
                }),
                new ColumnContainer(globalState, flexState),
            ],
        }),
        new RowContainer(globalState, flexState, {
            children: [
                ...filters,
                ...(denseMode
                    ? []
                    : [
                          new HeaderColumn(
                              globalState,
                              styledState,
                              fill(
                                  globalState,
                                  buildTimeSelector(
                                      globalState,
                                      flexState,
                                      traitSearch
                                  ),
                                  FillMode.END,
                                  numberOfElements,
                                  RowContainer
                              )
                          ),
                      ]),
            ],
        }),
    ]

    if (traitSearch.getTraitValue(TraitId.WITH_LOGO, 0) === Bool.TRUE)
        rows[0].children[0].children.splice(
            0,
            -1,
            new Logo(globalState, styledState, traitSearch)
        )
    if (traitSearch.getTraitValue(TraitId.WITH_TIME_PERIOD, 0) === Bool.TRUE) {
        rows[0].children[0].children.splice(
            2,
            1,
            buildTimeIndication(globalState, styledState, traitSearch)
        )
    }

    const marginX = nToS(
        traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X, 0) || 0
    )
    const halfMargin = divideBy(marginX, 2)

    const rowContainer = new RowContainer(
        globalState,
        flexState
            .copy()
            .addStyles({
                background: globalState.colors.light_background,
                height: add(height, halfMargin),
                paddingTop: halfMargin,
                paddingBottom: '0',
                paddingLeft: marginX,
                paddingRight: marginX,
            })
            .addClasses(isBigSize(globalState.reduceSize) ? ['x-block'] : []),
        { children: rows }
    )
    SizePropagatorHelpers.propagateSizeDown(rowContainer, {
        height: headerHeight,
        width: SizePropagatorHelpers.getDefaultWidth(globalState, traitSearch),
    })
    return rowContainer.mount()
}

function buildFilterMenu(globalState: GlobalState, traitSearch: TraitSearch) {
    const localState = new LocalState()
    const styledState = getStyledState(globalState, traitSearch)

    const filters = buildFilters(globalState, localState, traitSearch)
    const timeSelectors = buildTimeSelector(
        globalState,
        localState,
        traitSearch
    )
    const max = Math.max(filters.length, timeSelectors.length)
    const filtersColumn = new ColumnContainer(
        globalState,
        filters.length === max
            ? styledState.copy().setIsFlex(true)
            : styledState,
        { children: filters }
    )
    const timeSelectorColumn = new ColumnContainer(
        globalState,
        filters.length === max
            ? styledState.copy().setIsFlex(true)
            : styledState,
        { children: timeSelectors }
    )

    const children = filters.length
        ? [filtersColumn, timeSelectorColumn]
        : [timeSelectorColumn]

    const border = traitSearch.getTraitNumberValue(TraitId.BORDER, 0) || 0

    const width = filters.length ? 2 * ELEMENT_WIDTH : ELEMENT_WIDTH
    const height =
        ELEMENT_HEIGHT * Math.max(filters.length, timeSelectors.length)
    const rowContainer = new RowContainer(
        globalState,
        new LocalState()
            .setIsFlex(true)
            .addStyles({
                position: 'absolute',
                display: 'none',
                width: nToS(width),
                top: `${MOBILE_TOP_HEADER_HEIGHT}px`,
                margin: '0',
                padding: '0',
                right: '0',
                height: nToS(height),
                border: `${border} solid ${globalState.colors.border}`,
            })
            .addClasses(
                isBigSize(globalState.reduceSize) ? ['d-filter-container'] : []
            ),
        { children }
    )
    SizePropagatorHelpers.propagateSizeDown(rowContainer, {
        height,
        width,
    })
    return rowContainer.mount()
}

function _innerHeaderWidth(globalState: GlobalState, traitSearch: TraitSearch) {
    const marginX =
        traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X, 0) || 0
    return WINDOW_WIDTHS[globalState.deviceType] - 2 * marginX
}

function _navigationAliases(globalState: GlobalState) {
    return globalState.sidebarPayload?.aliases?.length
        ? globalState.sidebarPayload.aliases
        : [...Array(4)].map((_, i) => `Page ${i + 1}`)
}

function _navigation(
    globalState: GlobalState,
    traitSearch: TraitSearch,
    WrapperConstructor: typeof ColumnContainer | typeof RowContainer,
    wrapperState: LocalState,
    mainStyles: { [k: string]: string }
) {
    const sidebar = traitSearch.getTraitValue(TraitId.SIDEBAR, 0)
    const navigationAliases = _navigationAliases(globalState)
    const navigationIndex = globalState.sidebarPayload?.index || 0
    const sidebarDesign = traitSearch.getTraitStringRequiredValue(
        TraitId.SIDEBAR_DESIGN,
        0
    ) as SidebarDesign

    const { buttonStyles, wrapperStyles } = clickedNavigationButtonStyles(
        globalState,
        sidebarDesign,
        sidebar as any
    )

    const clickedButtonState = new LocalState().setStyles({
        ...mainStyles,
        ...buttonStyles,
    })
    const wrapperButtonState = new LocalState().setStyles({
        ...mainStyles,
        ...wrapperStyles,
    })
    const isCol = WrapperConstructor === ColumnContainer

    const children = []
    for (const i in navigationAliases) {
        if (Number(i) === navigationIndex) {
            const buttonComponent = clickedNavigationButton(
                globalState,
                wrapperButtonState,
                clickedButtonState,
                sidebarDesign,
                navigationAliases[i]
            )
            SizePropagatorHelpers.propagateSizeDown(buttonComponent, {
                width: SIDEBAR_WIDTH,
                height: SIDEBAR_HEIGHT,
            })
            children.push(buttonComponent)
        } else {
            const buttonComponent = new ButtonComponent(
                globalState,
                new LocalState()
                    .setStyles(
                        ButtonComponent.getStyles(
                            globalState.colors.sidebar_primary,
                            mainStyles.height
                        )
                    )
                    .addStyles(
                        isCol
                            ? { width: '100%', height: `${SIDEBAR_HEIGHT}px` }
                            : { width: `${SIDEBAR_WIDTH}px`, height: '100%' }
                    )
                    .addClasses(
                        ButtonComponent.listClasses(
                            globalState.reduceSize,
                            Number(i)
                        )
                    ),
                navigationAliases[i]
            )
            SizePropagatorHelpers.propagateSizeDown(buttonComponent, {
                width: SIDEBAR_WIDTH,
                height: SIDEBAR_HEIGHT,
            })
            children.push(buttonComponent)
        }
    }

    return new WrapperConstructor(globalState, wrapperState, { children })
}

function buildSidebar(globalState: GlobalState, traitSearch: TraitSearch) {
    const sidebar = traitSearch.getTraitValue(TraitId.SIDEBAR, 0)
    const hasNavigationBtns =
        (sidebar || 'none') !== 'none' && globalState.navigation

    if (!hasNavigationBtns) return

    return _navigation(
        globalState,
        traitSearch,
        ColumnContainer,
        new LocalState({
            styles: {
                height: '100%',
                width: `${SIDEBAR_WIDTH}px`,
                background: globalState.colors.sidebar_primary,
                margin: '0',
                padding: '0',
                border: '0',
            },
        }),
        {
            height: `${SIDEBAR_HEIGHT}px`,
            width: '100%',
        }
    )
}

function buildTopbar(globalState: GlobalState, traitSearch: TraitSearch) {
    const sidebar = traitSearch.getTraitStringValue(
        TraitId.SIDEBAR,
        0
    ) as SidebarType
    const hasNavigationBtns =
        (sidebar || 'none') !== 'none' && globalState.navigation
    if (!hasNavigationBtns) return

    const navigationAliases = _navigationAliases(globalState)
    const noEmptyContainer = navigationAliases.length > 6

    return _navigation(
        globalState,
        traitSearch,
        RowContainer,
        new LocalState({
            styles: {
                width: '100%',
                height: `${SIDEBAR_HEIGHT}px`,
                background: globalState.colors.sidebar_primary,
                padding: '0',
                margin: '0',
                border: '0',
            },
        }).setIsFlex(noEmptyContainer),
        {
            width: noEmptyContainer
                ? `${Math.floor(100 / navigationAliases.length)}%`
                : `${SIDEBAR_WIDTH}px`,
            height: '100%',
        }
    )
}

function buildNavigationMenu(
    globalState: GlobalState,
    traitSearch: TraitSearch
) {
    const sidebar = traitSearch.getTraitValue(TraitId.SIDEBAR, 0)
    const hasNavigationBtns =
        (sidebar || 'none') !== 'none' && globalState.navigation

    if (!hasNavigationBtns) return

    const navigationAliases = _navigationAliases(globalState)
    const border = traitSearch.getTraitValue(TraitId.BORDER, 0) || '0'

    return _navigation(
        globalState,
        traitSearch,
        ColumnContainer,
        new LocalState()
            .addStyles({
                position: 'absolute',
                display: 'none',
                width: `${SIDEBAR_WIDTH}px`,
                top: `${MOBILE_TOP_HEADER_HEIGHT}px`,
                left: '0',
                height: `${SIDEBAR_HEIGHT * navigationAliases.length}px`,
                border: `${border} solid ${globalState.colors.border}`,
            })
            .addClasses(
                isBigSize(globalState.reduceSize)
                    ? ['d-navigation-container']
                    : []
            ),
        {
            height: `${Math.floor(100 / navigationAliases.length)}%`,
            width: '100%',
        }
    )
}

export function sidebarFactory(
    globalState: GlobalState,
    traitSearch: TraitSearch
) {
    if (globalState.hasMobileHeader) return

    if (globalState.sidebarPayload?.sidebar === SidebarType.TOP)
        return buildTopbar(globalState, traitSearch)

    if (globalState.sidebarPayload?.sidebar === SidebarType.LATERAL)
        return buildSidebar(globalState, traitSearch)
}

function buildMobileHeader(
    globalState: GlobalState,
    traitSearch: TraitSearch
): PonychartElement {
    traitSearch.deviceType = DeviceType.MOBILE
    const colored =
        traitSearch.getTraitValue(TraitId.WITH_COLORS, 0) === Bool.TRUE
    const hasNavigationBtns =
        (traitSearch.getTraitValue(TraitId.SIDEBAR, 0) || 'none') !== 'none' &&
        globalState.navigation

    const localState = new LocalState()
    const flexState = localState.copy().setIsFlex(true)
    const styledState = localState
        .copy()
        .addStyles(
            PonychartElementHelpers.getStyledTemplateOptions(
                globalState,
                traitSearch,
                colored
            )
        )
        .addClasses(isBigSize(globalState.reduceSize) ? ['x-card'] : [])

    const squareLocalState = localState
        .copy()
        .addStyles({ width: `${MOBILE_TOP_HEADER_HEIGHT}px` })

    const topRow = new RowContainer(
        globalState,
        styledState
            .copy()
            .addStyles({ height: `${MOBILE_TOP_HEADER_HEIGHT}px` }),
        {
            children: [
                hasNavigationBtns
                    ? new MenuIcon(globalState, squareLocalState, traitSearch)
                    : new ColumnContainer(globalState, squareLocalState),
                new RowContainer(
                    globalState,
                    localState
                        .copy()
                        .addStyles(HEADER_TITLE_STYLE)
                        .addStyles({
                            fontSize: nToS(MOBILE_TOP_HEADER_HEIGHT / 3),
                            fontFamily: memoryInstance.fontFamily,
                            width: `calc(100% - ${multiplyBy(
                                MOBILE_TOP_HEADER_HEIGHT,
                                hasNavigationBtns ? 2 : 1
                            )} ) `,
                        }),
                    { content: globalState.pageTitle, fontRatio: 0.5 }
                ),
                new FilterIcon(globalState, squareLocalState, traitSearch),
            ],
        }
    )
    const withLogo =
        traitSearch.getTraitValue(TraitId.WITH_LOGO, 0) === Bool.TRUE
    const withTimePeriod =
        traitSearch.getTraitValue(TraitId.WITH_TIME_PERIOD, 0) === Bool.TRUE
    if (!withLogo && !withTimePeriod) return topRow

    const bottomRow = new RowContainer(
        globalState,
        flexState
            .copy()
            .addStyles({ height: `${MOBILE_BOTTOM_HEADER_HEIGHT}px` })
    )

    if (withLogo)
        bottomRow.children.push(new Logo(globalState, styledState, traitSearch))
    if (withTimePeriod) {
        bottomRow.children.push(
            buildTimeIndication(globalState, styledState, traitSearch)
        )
    }
    const navigationComponent = buildNavigationMenu(globalState, traitSearch)
    const filterComponent = buildFilterMenu(globalState, traitSearch)

    const marginX = nToS(
        traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X, 0) || 0
    )
    const halfMargin = divideBy(marginX, 2)

    const column = new ColumnContainer(
        globalState,
        localState
            .addStyles({
                position: 'relative',
                background: globalState.colors.light_background,
                height: add(
                    MOBILE_BOTTOM_HEADER_HEIGHT,
                    MOBILE_TOP_HEADER_HEIGHT,
                    halfMargin
                ),
                paddingLeft: marginX,
                paddingRight: marginX,
                paddingTop: halfMargin,
                paddingBottom: '0',
            })
            .addClasses(isBigSize(globalState.reduceSize) ? ['x-block'] : [])
            .setIsFlex(false),
        {
            children: [
                topRow,
                bottomRow,
                filterComponent,
                ...(navigationComponent ? [navigationComponent] : []),
            ],
        }
    )
    return column
}

class HeaderFactory {
    private headerDesignMap: {
        [k in HeaderDesign]: (
            globalState: GlobalState,
            traitSearch: TraitSearch
        ) => PonychartElement
    } = {
        [HeaderDesign.CENTERED]: buildCenterHeader,
        [HeaderDesign.SIMPLE]: buildSimpleLeftAlignedHeader,
        [HeaderDesign.SIMPLE_VERTICAL]: buildCenterVerticalHeader,
        [HeaderDesign.DENSE]: buildDenseHeader,
    }

    build(
        globalState: GlobalState,
        traitSearch: TraitSearch
    ): PonychartElement {
        // Important
        traitSearch.syncWith(globalState)
        if (globalState.hasMobileHeader)
            return buildMobileHeader(globalState, traitSearch)
        const design = traitSearch.getTraitValue(TraitId.HEADER_DESIGN, 0)
        if (!design) throw new Error('Could not find TraitId.HEADER_DESIGN')
        const element = this.headerDesignMap[design](globalState, traitSearch)
        return element
    }
}

export function buildHeader(
    globalState: GlobalState,
    traitSearch: TraitSearch
): PonychartElement {
    return new HeaderFactory().build(globalState, traitSearch)
}

export function buildTimeSelectorPreview(
    globalState: GlobalState,
    traitSearch: TraitSearch
) {
    traitSearch.syncWith(globalState)
    const localState = new LocalState()
    return new HeaderColumn(
        globalState,
        localState
            .copy()
            .addStyles({
                height: '150px',
                width: '100%',
                background: globalState.colors.background,
            })
            .setIsFlex(true)
            .addClasses(isBigSize(globalState.reduceSize) ? ['x-block'] : []),
        [
            new RowContainer(
                globalState,
                localState
                    .copy()
                    .addStyles({ background: globalState.colors.background })
                    .setIsFlex(true),
                {
                    children: fill(
                        globalState,
                        buildTimeSelector(globalState, localState, traitSearch),
                        FillMode.END,
                        3,
                        ColumnContainer
                    ),
                }
            ),
        ]
    )
        .mount()
        .adaptYPaddings([0])
}
