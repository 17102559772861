import { QuerySelectorTag, Trait, ChartType } from 'ponychart'

export enum RLPosition {
    RIGHT = 'right',
    LEFT = 'left',
}

export type ChartTypeMap = { [q in QuerySelectorTag]: ChartType }

export enum PageMode {
    DIMENSION = 'dimension',
    MEASURE = 'measure',
}

export enum PageStructure {
    SIMPLE = 'simple',
    KPI_CARD_2_COL = 'kpi_card_2_col',
    KPI_CARD_2_ROW = 'kpi_card_2_row',
    KPI_CARD_3_COL = 'kpi_card_3_col',
    KPI_CARD_3_ROW = 'kpi_card_3_row',
    KPI_CARD_3_TOP = 'kpi_card_3_top',
    KPI_CARD_3_DOWN = 'kpi_card_3_down',
    KPI_CARD_3_LEFT = 'kpi_card_3_left',
    KPI_CARD_3_RIGHT = 'kpi_card_3_right',
    KPI_CARD_4 = 'kpi_card_4',
    KPI_CARD_4_COL = 'kpi_card_4_col',
    KPI_CARD_4_ROW = 'kpi_card_4_row',
    KPI_CARD_4_TOP = 'kpi_card_4_top',
    KPI_CARD_4_DOWN = 'kpi_card_4_down',
    KPI_CARD_4_LEFT = 'kpi_card_4_left',
    KPI_CARD_4_RIGHT = 'kpi_card_4_right',
    UNIQUE_1 = 'unique_1',
    UNIQUE_2_ROW = 'unique_2_row',
    UNIQUE_2_COL = 'unique_2_col',
    UNIQUE_3_ROW = 'unique_3_row',
    UNIQUE_3_COL = 'unique_3_col',
    UNIQUE_3_TOP = 'unique_3_top',
    UNIQUE_3_DOWN = 'unique_3_down',
    UNIQUE_3_LEFT = 'unique_3_left',
    UNIQUE_3_RIGHT = 'unique_3_right',
    UNIQUE_4 = 'unique_4',
    UNIQUE_4_ROW = 'unique_4_row',
    UNIQUE_4_COL = 'unique_4_col',
    UNIQUE_4_TOP = 'unique_4_top',
    UNIQUE_4_DOWN = 'unique_4_down',
    UNIQUE_4_LEFT = 'unique_4_left',
    UNIQUE_4_RIGHT = 'unique_4_right',
    UNIQUE_5_ROW = 'unique_5_row',
    UNIQUE_5_COL = 'unique_5_col',
    UNIQUE_5_2_TOP = 'unique_5_2_top',
    UNIQUE_5_1_TOP = 'unique_5_1_top',
    UNIQUE_5_2_DOWN = 'unique_5_2_down',
    UNIQUE_5_1_DOWN = 'unique_5_1_down',
    UNIQUE_5_2_LEFT = 'unique_5_2_left',
    UNIQUE_5_1_LEFT = 'unique_5_1_left',
    UNIQUE_5_2_RIGHT = 'unique_5_2_right',
    UNIQUE_5_1_RIGHT = 'unique_5_1_right',
}

export enum StructureType {
    ALL = 'all',
    REPETITIVE = 'repetitive',
    UNIQUE = 'unique',
}

export const REPETITIVE_PAGE_STRUCTURE = Object.freeze(
    Object.values(PageStructure).filter((value) => !value.includes('unique_'))
) as PageStructure[]
export const UNIQUE_PAGE_STRUCTURE = Object.freeze(
    Object.values(PageStructure).filter((value) => value.includes('unique_'))
) as PageStructure[]

export const PAGE_STRUCTURES = [
    ...REPETITIVE_PAGE_STRUCTURE,
    ...UNIQUE_PAGE_STRUCTURE,
]

export const UNIQUE_PAGE_STRUCTURE_SET = new Set(UNIQUE_PAGE_STRUCTURE)

export interface PageBlock {
    id: string
    title?: string
    alias?: string
    structure: PageStructure
    twbIdx: number
    traits: Trait[]
}

export interface Page {
    id: number | string
    alias: string
    title: string
    navigation: boolean
    pristine: boolean
    pageBlocks?: PageBlock[]
    multipleCharts?: boolean
    disabled?: boolean
}
export interface ListPagePayload {
    limit?: number
    startAt?: number
    wishedCharts?: ChartType[]
    allowedCharts?: ChartType[]
    disabled?: boolean
    startingCount?: number
    measure?: string
}
