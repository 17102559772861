import { ChartType, TraitId } from 'ponychart'
import { GlobalState, TraitSearch } from '../state'
import { nToS, sToN, WINDOW_WIDTHS } from '../utils'
import { textMeasurer } from '../utils/canvas'
import type { PonychartElement } from './model'

export class SizePropagatorHelpers {
    static getInnerWidth(element: PonychartElement, width: number) {
        return SizePropagatorHelpers.getInnerSize(element, width, 'width')
    }

    static getInnerHeight(element: PonychartElement, height: number) {
        return SizePropagatorHelpers.getInnerSize(element, height, 'height')
    }

    static getInnerSize(
        element: PonychartElement,
        size: number,
        type: 'width' | 'height'
    ) {
        const isWidth = type === 'width'
        const styles = element.rawStyles
        let margin = 0
        for (const suffix of [
            '',
            '',
            ...(isWidth ? ['right', 'left'] : ['top', 'bottom']),
        ]) {
            for (const styleAttribute of ['margin', 'padding']) {
                margin += sToN(styles[styleAttribute + '-' + suffix] || '')
            }
        }
        margin += 2 * sToN(styles.border || '')
        return size - margin
    }

    static propagateSize(
        element: PonychartElement,
        size: number,
        type: 'width' | 'height'
    ): PonychartElement {
        const isWidth = type === 'width'
        if (isWidth) {
            element.width = size
        } else {
            element.height = size
        }
        if (element.children.length === 0) return element

        const innerSize = SizePropagatorHelpers.getInnerSize(
            element,
            size,
            type
        )

        const chartType = isWidth ? ChartType.ROW : ChartType.COLUMN

        if (element.componentType === chartType) {
            // If component is row, then 2 cases
            if (element.localState.isFlex) {
                // Case 1: it's a flex row, in that case,
                // it's straightforward, each child gets a share
                // of the total width
                const childWidth = innerSize / element.children.length
                for (const child of element.children)
                    SizePropagatorHelpers.propagateSize(child, childWidth, type)
            } else {
                // Case 2: it's not a flex row. In this case
                // some children will get a fixed width. If so, we just propagate this width
                // else, they get the remaining width from what has not been set up previously
                const indexes: number[] = []
                let sizeLeft = innerSize
                for (const i in element.children) {
                    const child = element.children[i]
                    const rawSize = sToN(child.localState.rawStyles[type] || '')
                    if (rawSize) {
                        SizePropagatorHelpers.propagateSize(
                            child,
                            rawSize,
                            type
                        )
                        sizeLeft -= rawSize
                    } else indexes.push(Number(i))
                }
                for (const index of indexes)
                    SizePropagatorHelpers.propagateSize(
                        element.children[index],
                        sizeLeft / indexes.length,
                        type
                    )
            }
        } else {
            for (const child of element.children)
                SizePropagatorHelpers.propagateSize(child, innerSize, type)
        }
        return element
    }

    static propagateWidth(
        element: PonychartElement,
        width: number
    ): PonychartElement {
        return SizePropagatorHelpers.propagateSize(element, width, 'width')
    }

    static propagateHeight(
        element: PonychartElement,
        height: number
    ): PonychartElement {
        return SizePropagatorHelpers.propagateSize(element, height, 'height')
    }

    static propagateSizeDown(
        element: PonychartElement,
        opts: { height: number; width: number }
    ) {
        SizePropagatorHelpers.propagateHeight(element, opts.height)
        SizePropagatorHelpers.propagateWidth(element, opts.width)
        SizePropagatorHelpers.findOptimalFontSize(element)
    }

    static getDefaultWidth(globalState: GlobalState, traitSearch: TraitSearch) {
        const marginX =
            (traitSearch.getTraitNumberValue(TraitId.PAGE_MARGIN_X, 0) || 0) * 2
        return WINDOW_WIDTHS[globalState.deviceType] - marginX
    }

    static findOptimalFontSize(element: PonychartElement) {
        if (element.blockResizePropagation) return
        for (const child of element.children)
            SizePropagatorHelpers.findOptimalFontSize(child)

        if (
            !element.text ||
            !element.width ||
            !element.height ||
            element.children.length > 0 ||
            element.text === '|'
        )
            return

        const rawStyles = element.rawStyles

        const fontSize = textMeasurer.findOptimalFontSize(
            element.text,
            element.height,
            element.width,
            rawStyles.fontWeight || 'normal',
            rawStyles.fontFamily || 'Arial',
            element.fontRatio
        )

        element.addLocalComponentStyle('fontSize', nToS(fontSize))
    }
}
