<template lang="pug">
v-card(outlined, :max-width='width')
    v-card-title 
        | {{ $t("payment_page." + (status ? status : "cancel")) }}
        v-spacer
        v-btn(icon, @click='$emit("close")')
            v-icon close
    v-card-text
        v-row(align='center', justify='center').mt-2
            .title.mr-2(v-if="success") {{ $t("thanks") }}
            svg.ft-green-tick(
                v-if='success',
                style="transform: translateY(6px)"
                xmlns='http://www.w3.org/2000/svg',
                height='60',
                width='60',
                viewbox='0 0 40 40',
                aria-hidden='true'
            ).mb-4
                circle.circle-good(
                    fill='#5bb543',
                    cx='22',
                    cy='22',
                    r='18'
                )
                path.tick-good(
                    fill='none',
                    stroke='#FFF',
                    stroke-width='5',
                    stroke-linecap='round',
                    stroke-linejoin='round',
                    stroke-miterlimit='10',
                    d='M14 27l5.917 4.917L34 17'
                )
            //- .ui-error
            svg(
                v-else,
                viewbox='0 0 40 40',
                height='100',
                width='100',
                version='1.1',
                xmlns='http://www.w3.org/2000/svg',
                xmlns:xlink='http://www.w3.org/1999/xlink'
            )
                g#Page-1(
                    stroke='none',
                    stroke-width='1',
                    fill='none',
                    fill-rule='evenodd'
                )
                    g#Group-2(transform='translate(2.000000, 2.000000)')
                        circle#Oval-2(
                            stroke='rgba(252, 191, 191, .5)',
                            stroke-width='4',
                            cx='41.5',
                            cy='41.5',
                            r='41.5'
                        )
                        circle.ui-error-circle(
                            stroke='#F74444',
                            stroke-width='4',
                            cx='41.5',
                            cy='41.5',
                            r='41.5'
                        )
                        path#Line.ui-error-line1(
                            d='M22.244224,22 L60.4279902,60.1837662',
                            stroke='#F74444',
                            stroke-width='3',
                            stroke-linecap='square'
                        )
                        path#Line.ui-error-line2(
                            d='M60.755776,21 L23.244224,59.8443492',
                            stroke='#F74444',
                            stroke-width='3',
                            stroke-linecap='square'
                        )
                
        v-row.ma-2
            span {{ $t("description." + (status ? status : "cancel")) }}
</template>

<script>
export default {
    name: 'stripeSessionResult',
    props: {
        session: {
            type: String,
            required: false,
        },
        status: {
            type: String,
            required: false,
            default: 'cancel',
        },
        width: {
            type: Number,
            default: 400
        }
    },
    data: () => ({}),
    computed: {
        success() {
            return this.status == 'success'
        },
    },
}
</script>

<style scoped>
.tick-good {
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
}
.circle-good {
    fill-opacity: 0;
    stroke: #219a00;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
}
@keyframes grow {
    60% {
        transform: scale(0.8);
        stroke-width: 4px;
        fill-opacity: 0;
    }
    100% {
        transform: scale(0.9);
        stroke-width: 8px;
        fill-opacity: 1;
        fill: #219a00;
    }
}
@keyframes draw {
    100% {
        stroke-dashoffset: 0;
    }
}
body {
    display: table;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.svg-error {
    width: 100px;
    display: block;
    margin: 0 auto 0;
}
.svg-error .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}
.svg-error circle {
    -webkit-animation: dash 4s ease-in-out;
    animation: dash 4s ease-in-out;
}
.svg-error line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 4s ease-in-out;
    animation: dash 4s ease-in-out;
}
.svg-error p {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25em;
}
.svg-error p.error {
    color: #d06079;
}
@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
.ui-error {
    width: 100px;
    height: 100px;
    margin: 40px;
}
.ui-success-circle {
    stroke-dasharray: 260.75219025px, 260.75219025px;
    stroke-dashoffset: 260.75219025px;
    transform: rotate(220deg);
    transform-origin: center center;
    stroke-linecap: round;
    animation: ani-success-circle 1s ease-in both;
}
.ui-success-path {
    stroke-dasharray: 60px 64px;
    stroke-dashoffset: 62px;
    stroke-linecap: round;
    animation: ani-success-path 0.4s 1s ease-in both;
}
@keyframes ani-success-circle {
    to {
        stroke-dashoffset: 782.25657074px;
    }
}
@keyframes ani-success-path {
    0% {
        stroke-dashoffset: 62px;
    }
    65% {
        stroke-dashoffset: -5px;
    }
    84% {
        stroke-dashoffset: 4px;
    }
    100% {
        stroke-dashoffset: -2px;
    }
}
.ui-error-circle {
    stroke-dasharray: 260.75219025px, 260.75219025px;
    stroke-dashoffset: 260.75219025px;
    animation: ani-error-circle 1.2s linear;
}
.ui-error-line1 {
    stroke-dasharray: 54px 55px;
    stroke-dashoffset: 55px;
    stroke-linecap: round;
    animation: ani-error-line 0.15s 1.2s linear both;
}
.ui-error-line2 {
    stroke-dasharray: 54px 55px;
    stroke-dashoffset: 55px;
    stroke-linecap: round;
    animation: ani-error-line 0.2s 0.9s linear both;
}
@keyframes ani-error-line {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes ani-error-circle {
    0% {
        stroke-dasharray: 0, 260.75219025px;
        stroke-dashoffset: 0;
    }
    35% {
        stroke-dasharray: 120px, 120px;
        stroke-dashoffset: -120px;
    }
    70% {
        stroke-dasharray: 0, 260.75219025px;
        stroke-dashoffset: -260.75219025px;
    }
    100% {
        stroke-dasharray: 260.75219025px, 0;
        stroke-dashoffset: -260.75219025px;
    }
}
</style>

<i18n>{
  "fr": {
    "payment_page": {
      "success": "Paiement réussi",
      "cancel": "Paiement annulé",
      "error": "Paiement échoué",
      "active": "Paiement actif"
    },
    "thanks": "Merci pour votre achat",
    "description": {
      "success": "Il est temps de créer des tableaux de bord!",
      "cancel": "Ne vous inquiétez pas, vous n'avez pas été facturé."
    },
    "continue": "Continue to create dashboards"
  },
  "es": {
    "payment_page": {
      "success": "Pago exitoso",
      "cancel": "Pago cancelado",
      "error": "Pago fallado",
      "active": "Pago activado"
    },
    "thanks": "Gracias por tu compra",
    "description": {
      "success": "¡Es hora de crear algunos dashboards!",
      "cancel": "No te preocupes, no te hemos cobrado"
    },
    "continue": "Continue to create dashboards"
  },
  "en": {
    "payment_page": {
      "success": "Payment successful",
      "cancel": "Payment cancelled",
      "error": "Payment failed",
      "active": "Payment activated"
    },
    "thanks": "Thank you for your purchase",
    "description": {
      "success": "Time to create some dashboards!",
      "cancel": "Don't worry, you haven't been charged."
    },
    "continue": "Continue to create dashboards"
  },
  "de": {
    "payment_page": {
      "success": "Die Zahlung war erfolgreich",
      "cancel": "Zahlung storniert",
      "error": "Zahlung ist fehlgeschlagen",
      "active": "Zahlung ist aktiviert"
    },
    "thanks": "Vielen Dank für Ihren Kauf.",
    "description": {
      "success": "Zeit, ein paar Dashboards zu erstellen!",
      "cancel": "Keine Sorge, Ihnen wurde nichts berechnet."
    },
    "continue": "Erstellen Sie weiterhin Dashboards"
  }
}</i18n>