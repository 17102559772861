import { SidebarType } from 'ponychart'

export interface Logo {
    url?: string
    id: string
    status: string
}

export enum TimeSelectorDesign {
    ROW = 'row',
    COLUMN = 'column',
    TWO_COLUMNS = 'two_column',
}

export interface SidebarPayload {
    sidebar: SidebarType
    index?: number
    aliases?: string[]
}

export enum SelectorId {
    START_YEAR_MONTH_SELECTOR = 'start-year-month-selector',
    END_YEAR_MONTH_SELECTOR = 'end-year-month-selector',
    END_MONTH_SELECTOR = 'end-month-selector',
    START_MONTH_SELECTOR = 'start-month-selector',
    END_YEAR_SELECTOR = 'end-year-selector',
    START_YEAR_SELECTOR = 'start-year-selector',
    START_DATE_SELECTOR = 'start-date-selector',
    END_DATE_SELECTOR = 'end-date-selector',
    MEASURE_SELECTOR = 'measure-selector',
    MEASURE_SELECTOR_2 = 'measure-selector-2',
    COMPARISON_PERIOD_SELECTOR = 'comparison-period-selector',
    DIMENSION_SELECTOR = 'dimension-selector',
    DIMENSION_SELECTOR_2 = 'dimension-selector-2',
    DATE_AGGREGATION_SELECTOR = 'date-aggregation-selector',
    TIME_PERIOD_AGGREGATION = 'time-period-aggregation',
    FILTER_SELECTOR = 'filter-selector',
    CHART_SELECTOR = 'chart-selector',
    COMPARISON_PERIOD_COUNT_LIST_SELECTOR = 'comparison-period-count-list-selector',
    COMPARISON_PERIOD_COUNT_RANGE_SELECTOR = 'comparison-period-count-range-selector',
}
