import { TraitOptionItem, Orientation } from 'ponychart'
import { ColumnContainer, RowContainer } from '@/ponychart/element'
import { translations } from '@/ponychart/i18n'
import {
    reduceObjectSize,
    dictToInlineStyle,
} from '@/ponychart/utils/functions'
import memoize from 'fast-memoize'
import type { GlobalState, LocalState } from '@/ponychart/state'
import { wrapAttributes } from '@/ponychart/svg/utils'
import { LABEL_CLASS, SELECT_STYLE } from './config'
import { memoizedInvertColor } from '../utils'

const memoizeReduceObjectSize = memoize(
    (o: { style: { [k: string]: string }; reduceSize: number }) => {
        const style = dictToInlineStyle(reduceObjectSize(o.style, o.reduceSize))
        if (!style) return ''
        else return style
    }
)

export function datePickerWrapper(
    id: string,
    title: string,
    opts: {
        reduceSize?: number
        label?: string
        attributes?: { [k: string]: string }
        isDark?: boolean
    } = {}
): string {
    // return ''
    const label = opts.label || ''
    const reduceSize = opts.reduceSize || 1
    const background = opts.isDark ? '#424242' : '#ffffff'
    const color = memoizedInvertColor(background)

    const labelStyle = memoizeReduceObjectSize({
        style: {
            'text-overflow': 'ellipsis',
            width: '80px',
            overflow: 'hidden',
            'font-size': '10px',
            'line-height': '10px',
            'white-space': 'nowrap',
            color,
        },
        reduceSize,
    })
    const inputStyle = memoizeReduceObjectSize({
        style: {
            ...SELECT_STYLE,
            'text-align': 'left',
            color,
            background,
        },
        reduceSize,
    })
    const attributes = {
        id,
        title,
        class: 'da-flex da-container da-col',
        style: 'justify-content: end',
        ...(opts?.attributes || {}),
    }
    const content = `<label style="${labelStyle}" class="${LABEL_CLASS}"><span>${label}</span></label>
    <input style="${inputStyle}" type="date" class="da-select-input ${LABEL_CLASS}">`
    return wrapAttributes('div', content, attributes)
}

const ADDITIONAL_STYLE: {
    input: { [k: string]: string }
    select: { [k: string]: string }
} = {
    input: {},
    select: {
        'background-position':
            'calc(100% - 9px ) 50%, calc(100% - 5px ) 50%, calc(100% - 18px ) 0',
        'background-size': '4px 3px , 4px 3px , 1px 100%',
    },
}

const ADDITIONAL_FOCUSED_STYLE: {
    input: { [k: string]: string }
    select: { [k: string]: string }
} = {
    input: {},
    select: {
        'background-position':
            'calc(100% - 9px ) 50% , calc(100% - 5px ) 50% , calc(100% - 18px ) 0',
        'background-size': '4px 3px , 4px 3px , 1px 100%',
    },
}

const TAG_CLASSES: { input: string; select: string } = {
    input: 'da-select-input',
    select: 'da-select-element da-select-input',
}

const DARK_TAG_CLASSES: { input: string; select: string } = {
    input: 'da-select-input-dark',
    select: 'da-select-input-dark da-select-element-dark',
}
const ADDITIONAL_ATTRIBUTES: { input: string; select: string } = {
    input: 'value="1" type="number" ',
    select: '',
}

const TAG_STYLE: {
    input: { [k: string]: string }
    select: { [k: string]: string }
} = {
    input: {
        'justify-content': 'end',
    },
    select: {
        'justify-content': 'end',
    },
}

export function selectWrapper(
    id: string,
    items: { id: string | number; alias: string }[],
    opts: {
        reduceSize?: number
        isDark?: boolean
        label?: string
        tag?: 'input' | 'select'
        min?: number
        max?: number
        classes?: string[]
        styles?: { [k: string]: string }
    } = {}
) {
    const reduceSize = opts?.reduceSize || 1
    const background = opts.isDark ? '#171717' : '#ffffff'
    const color = memoizedInvertColor(background)
    const tag = opts?.tag || 'select'
    const selectStyle = memoizeReduceObjectSize({
        style: {
            ...SELECT_STYLE,
            'padding-right': opts.tag !== 'input' ? '20px' : '0', // 20px is the width of the right arrow
            color,
            ...ADDITIONAL_STYLE[tag],
        },
        reduceSize,
    })
    const focusedSelectStyle = memoizeReduceObjectSize({
        style: ADDITIONAL_FOCUSED_STYLE[tag],
        reduceSize,
    })
    const totalStyle = `${selectStyle};:focus{${focusedSelectStyle}}`
    const labelStyle = memoizeReduceObjectSize({
        style: {
            'font-size': '10px',
            'line-height': '10px',
            color,
        },
        reduceSize,
    })
    const labelHtml = opts.label
        ? `<label for="standard-select" style="${labelStyle}" class="da-select-label ${LABEL_CLASS}"><span>${opts.label}</span></label>`
        : ''
    const classes = (opts.classes || []).join(' ')
    const attributes: { [k: string]: string } = {
        id,
        class: classes,
        title: opts.label || 'select',
        style:
            memoizeReduceObjectSize({
                style: { ...(opts.styles || {}), ...TAG_STYLE[tag] },
                reduceSize,
            }) + ';position: relative',
    }
    // We apply "x-..." classes as well on the select element
    // because it looks better for the backgroundColor hover effect
    const xClasses = (opts.classes || []).filter((c) => c.startsWith('x-'))
    if (opts.min !== undefined) attributes.min = opts.min.toString()
    if (opts.max !== undefined) attributes.max = opts.max.toString()
    const selectHtml = `<${tag} ${ADDITIONAL_ATTRIBUTES[tag]}class="${
        (opts.isDark ? DARK_TAG_CLASSES : TAG_CLASSES)[tag]
    } ${LABEL_CLASS} ${xClasses}" style="${totalStyle}">${items
        .map((item, i) => toOption(item, i, reduceSize))
        .join('')}</${tag}>`
    return wrapAttributes('div', labelHtml + selectHtml, attributes)
}

export function toOption(
    col: TraitOptionItem,
    i: number,
    reduceSize: number
): string {
    const style = memoizeReduceObjectSize({
        style: {
            height: '15px',
            margin: '50px 0',
            'line-height': '15px',
            'font-size': '15px',
            padding: '0',
        },
        reduceSize,
    })

    return `<option style="${style}" ${i === 0 ? 'selected ' : ''}data-value="${
        col.id
    }">${col.alias}</option>`
}

export function toOptions(arr: string[], reduceSize: number): string {
    return arr
        .map((v, i) => toOption({ alias: v, id: v }, i, reduceSize))
        .join('')
}

export function buildContainer(
    orientation: Orientation,
    isFlex: boolean,
    globalState: GlobalState,
    localState: LocalState
): RowContainer | ColumnContainer {
    const Component =
        orientation === Orientation.VERTICAL ? ColumnContainer : RowContainer
    const component = new Component(
        globalState,
        localState.copy().setIsFlex(isFlex)
    )
    return component
}

export const MONTHS = Object.keys(translations.en.months)

export function splitArrayIntoChunksOfLen<T>(arr: T[], len: number): T[][] {
    const chunks = []
    if (len === 0) len = 1
    let i = 0
    while (i < arr.length) {
        chunks.push(arr.slice(i, (i += len)))
    }
    return chunks
}

export function titleLengthToFontSize(titleLength: number) {
    if (titleLength === 0) return 1000
    const output = 800 / titleLength
    return output
}
