<template lang="pug">
v-chip.ml-8.white(
    :color='color',
    label,
    :outlined='outlined',
    :dark='!outlined',
    small
)
    v-icon(small, :left='true', :color='!outlined ? "white" : color') paid
    span(:class='!outlined && "white--text"') {{ $t(!isFree ? "paidOnly" : "free") }}
</template>

<script>
export default {
    props: {
        isFree: { type: Boolean, required: true },
        outlined: { type: Boolean, required: false, default: false },
        left: { type: Boolean, default: false },
    },
    computed: {
        color() {
            return !this.isFree ? 'primary' : 'green'
        },
    },
}
</script>

<i18n>{
  "en": {
    "paidOnly": "Paid only",
    "free": "Free"
  },
  "es": {
    "paidOnly": "Solo pagado",
    "free": "Gratis"
  },
  "fr": {
    "paidOnly": "Payant",
    "free": "Gratuit"
  }
}</i18n>