
import { GlobalMixins } from '@/mixins'
import ShowMoreBtn from '@/components/utils/ShowMoreBtn.vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
    components: {
        ShowMoreBtn,
    },
})
export default class FormatInput extends mixins(GlobalMixins) {
    @Prop({ type: String, required: true })
    readonly type!: string
    @Prop({ type: String, required: true })
    readonly id!: string
    @Prop({ type: String, required: true })
    readonly value!: string
    @Prop({ type: String, required: false })
    readonly cols!: string | undefined
    @Prop({ type: String, required: false })
    readonly label!: string | undefined
    @Prop({ type: String, required: false })
    readonly description!: string | undefined
    @Prop({ type: Boolean, required: false })
    readonly error!: boolean
    @Prop({ type: Number, required: false })
    readonly max!: number

    edit = false

    async open() {
        this.edit = true
        await this.$nextTick()
        const el = this.$refs[this.type + '-' + this.id]
        if (!el) return
        ;(el as any).focus()
    }
}
