import { Format } from '@/ponychart'
import axios from 'axios'

export default {
    getFormat: async function (formatId: string): Promise<Format> {
        const {
            data: { format },
        } = await axios(`/v1/formats/${formatId}`)
        return format
    },
    listFormats: async function (): Promise<Format[]> {
        const {
            data: { formats },
        } = await axios(`/v1/formats`)
        return formats
    },
    deleteFormat: async function (formatId: string) {
        const {
            data: { formatId: deletedFormatId },
        } = await axios.delete(`/v1/formats/${formatId}`)
        return deletedFormatId
    },
    updateOrder: async function (ids: { formatId: string | number, order: number }[]): Promise<void> {
        await axios.post("/v1/formats-order", { ids })
    },
    updateFormat: async function (formatId: string, payload: any) {
        const {
            data: { format },
        } = await axios.put(`/v1/formats/${formatId}`, payload)
        return format
    },
    createFormat: async function (payload: any) {
        const {
            data: { format },
        } = await axios.post(`/v1/formats`, payload)
        return format
    },
}
