import { DataType } from "@/ponychart/types";
import { t } from "../i18n/translate";

export enum DimensionType {
    DATETIME = 'datetime',
    DATE = 'date',
    TEXT = 'text',
    VARCHAR = 'varchar',
    INT = 'int'
}

export interface DimensionCalculationValue {
    label: string | number | undefined
    type: string
    value: string | number | undefined
}

export enum DimensionOperator {
    REPLACE = 'REPLACE',
    SPLIT = 'SPLIT',
    CONCAT = 'CONCAT',
    YEAR = 'YEAR',
    QUARTER = 'QUARTER',
    MONTH = 'MONTH',
    WEEK = 'WEEK',
    DAY_OF_WEEK = 'DAY_OF_WEEK',
    DAY_OF_MONTH = 'DAY_OF_MONTH',
    YEAR_MONTH = 'YEAR_MONTH',
    YEAR_MONTH_DAY = 'YEAR_MONTH_DAY'
}

export interface DimensionCalculation {
    operator: DimensionOperator
    values: DimensionCalculationValue[]
}

export interface Dimension {
    alias: string;
    calculations: DimensionCalculation[]
    id: string
    errors: string[]
    columnId: string
    pristine: boolean | undefined
    type: DataType
    twbIdx: number
}

export const nullDimension = (twbIdx: number) => ({
    alias: t("empty"),
    calculations: [],
    errors: [],
    id: "null",
    columnId: "",
    pristine: false,
    type: DataType.TEXT,
    twbIdx
})