import { Vue, Component } from 'vue-property-decorator'

import i18n from '@/plugins/i18n'
import { TranslateResult } from 'vue-i18n'
import { pick } from 'lodash'
import { TYPE } from 'vue-toastification'

@Component({})
class GlobalMixins extends Vue {
    get lang() {
        return this.$store?.getters?.lang || 'en'
    }
    get isLoggedIn() {
        return this.$store?.getters?.['cognito/isLoggedIn'] || false
    }
    get isAdmin() {
        return this.$store.getters.isAdmin
    }

    clearTour() {
        try {
            this.$tours['main'].finish()
        } finally {
            //
        }
    }

    showToast(
        state?:
            | {
                message?: string | TranslateResult
                code?: string
                timeout?: number
                type?: TYPE
                data?: { [k: string]: string }
            }
            | string
    ) {
        const _payload = state
            ? typeof state == 'string'
                ? { message: state, code: state }
                : state
            : {}

        if (_payload.code && i18n.te('errors.' + _payload.code)) {
            _payload.message = _payload.data
                ? i18n.t('errors.' + _payload.code, _payload.data)
                : i18n.t('errors.' + _payload.code)
        }
        if (!_payload.message)
            _payload.message = i18n.t('errors.UNKNOWN') as string
        if (!_payload.type) _payload.type = TYPE.ERROR

        this.$toast(_payload.message, pick(_payload, ['timeout', 'type']))
    }

    goTo(r: string) {
        if (r != this.$route.path) this.$router.push(r)
    }
    titlecase(str: string) {
        return str
            .replace(/_/g, ' ')
            .replace(/-/g, ' ')
            .replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
    }
    passwordInvalid(pwd: string) {
        if (
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
                pwd
            )
        ) {
            return null
        } else if (pwd.length < 8) {
            return this.$t('errors.PASSWORD_GUIDE_2')
        } else {
            return this.$t('errors.PASSWORD_GUIDE_3')
        }
    }
    emailInvalid(email: string) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !re.test(email)
    }
}

export { GlobalMixins }
